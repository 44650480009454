import Clinic from '../assets/icons/clinic.png';
import DNA from '../assets/icons/dna.png';
import Flask from '../assets/icons/flask.png';
import Gender from '../assets/icons/gender.png';
import Globe from '../assets/icons/globe.png';
import Heart from '../assets/icons/heart.png';
import Metabolite from '../assets/icons/metabolite.png';
import Protein from '../assets/icons/protein.png';
import Rat from '../assets/icons/rat.png';
import Vial from '../assets/icons/vial.png';
import PDF from '../assets/icons/pdf.png';
import Spinner from '../assets/icons/spinner.gif';
import Sync from '../assets/icons/sync.png';
import Archive from '../assets/icons/archive.png';
import InternalDataRelease from '../assets/icons/data-release-internal.png';
import ArrowRightAnimated from '../assets/icons/arrow-right-animated.svg';
import ArrowDownAnimated from '../assets/icons/arrow-down-animated.svg';
import ArrowDownAnimatedDark from '../assets/icons/arrow-down-animated-dark.svg';
import GitHub from '../assets/icons/github.svg';
import GoogleCloud from '../assets/icons/google-cloud.svg';
import GoogleDrive from '../assets/icons/google-drive.svg';

const IconSet = {
  Clinic,
  DNA,
  Flask,
  Gender,
  Globe,
  Heart,
  Metabolite,
  Protein,
  Rat,
  Vial,
  PDF,
  Spinner,
  Sync,
  Archive,
  InternalDataRelease,
  ArrowRightAnimated,
  ArrowDownAnimated,
  ArrowDownAnimatedDark,
  GitHub,
  GoogleCloud,
  GoogleDrive,
};

export default IconSet;
