const dataDownloadStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'Dataset',
  name: 'Endurance Exercise Training Young Adult Rats (6 months) Data',
  description:
    'Explore and download the first large-scale multi-omic multi-tissue dataset disseminated by the Molecular Transducers of Physical Activity Consortium (MoTrPAC) in an endurance training exercise study in young adult (6-month old) rats. Accelerate your research and discover valuable scientific insights with access to a wealth of exercise-related molecular data consisting of differential expression analysis, phenotype, and quantitative results spanning across a wide range of assays.',
  sourceOrganization:
    'Molecular Transducers of Physical Activity Consortium (MoTrPAC)',
  url: 'https://motrpac-data.org/data-download',
  keywords: [
    'transcriptomics',
    'protemoics',
    'epigenomics',
    'metablomics',
    'multiomics',
    'blood rna',
    'plasma',
    'hippocampus',
    'cortex',
    'hypothalamus',
    'gastrocnemius',
    'leg skeletal muscle',
    'vastus lateralis',
    'heart',
    'kidney',
    'adrenal',
    'colon',
    'spleen',
    'testes',
    'male gonads',
    'ovaries',
    'female gonads',
    'vena cava',
    'lung',
    'small intestine',
    'liver',
    'brown adipose',
    'ubcutaneous white adipose tissue',
    'white adipose',
    'acetylproteomics',
    'protein site acetylation',
    'chromatin accessibility',
    'ATAC-seq data',
    'multiplexed immunoassays (cytokines and hormones)',
    'metabolomics and lipidomics',
    'DNA methylation',
    'RRBS data',
    'phosphoproteomics',
    'protein site phosphorylation',
    'global proteomics',
    'protein abundance',
    'transcriptomics',
    'RNA-Seq data',
    'ubiquitylome',
    'protein site ubiquitination',
    'HSP',
    'HSP70',
    'HSP90',
    'STAT3',
    'PXN',
    'HGF',
    'AGC',
    'AKT1',
    'SRC',
    'MEF2C',
    'HOMER35',
    'pY265',
    'pY820',
    'Gja1',
    'Cx43',
    'Cdh2',
    'EGF',
    'IGF',
    'HGF',
    'HDAC4',
    'GLUT4',
    'PPARGC1A',
    'Lep',
    'Mmp2',
    'Apoc3',
    'Leptin',
    'Methallopeptitase 2',
    'Apolipoprotein C-III',
    'ERRg',
    'Dsp',
    'Mylk',
    'Desmoplakin',
    'Myosin light chain kinase',
    'Desmin',
    'Stathmin 1',
    'Nesprin 1',
    'PRKACA',
    'PU.1',
    'IRF8',
    'IRF3',
    'Btk',
    'Cd72',
    'Cd79a',
    'Cd3e',
    'Gata3',
    'Il2rg',
    'Ptpn22',
    'Zap70',
    'Cd5',
    'Card11',
    'Pntp22',
    'MHC class II',
    'RT1-Dob',
    'RT1-Db2',
    'Itgal',
    'Cxcr3',
    'Il1a',
    'Rac2',
    'Ncf1',
    'Ncf4',
    'Cybb',
    'GPI',
    'FBP2',
    'DLAT',
    'Fkbp5',
    'Agap2',
    'Crat',
    'Hsp90aa1',
    'Cx3cl1',
    'Eef2k',
    'Gpnmb',
    'Hsph1',
    'Ndufa12',
    'Snx20',
    'Sparc',
    'Tns1',
    'Tpi1',
  ],
  isAccessibleForFree: true,
  hasPart: [
    {
      '@type': 'Dataset',
      name: 'differential analysis',
      description: 'Assay-specific differential analysis and normalized data',
    },
    {
      '@type': 'Dataset',
      name: 'results and reports',
      description:
        'Assay-specific quantitative results, experiment metadata, and QA/QC reports',
    },
    {
      '@type': 'Dataset',
      name: 'metablomics data',
      description:
        'Cross-platform merged metabolomics data tables for named metabolites',
    },
    {
      '@type': 'Dataset',
      name: 'phenotype data',
      description:
        'Phenotypic data from young adult rats (6-month old) that performed the endurance exercise training.',
    },
  ],
  funder: {
    '@type': 'Organization',
    name: 'National Institute of Health (NIH) Common Fund',
  },
  includedInDataCatalog: {
    '@type': 'DataCatalog',
    name: 'nih.gov',
  },
  distribution: [
    {
      '@type': 'DataDownload',
      encodingFormat: 'CSV',
      contentUrl: 'https://motrpac-data.org/data-download',
    },
  ],
};

export default dataDownloadStructuredData;
