const tissues = [
  {
    bic_tissue_code: 'T30',
    bic_tissue_name: 'Blood RNA',
    motrpac_tissue_code: 'T30 Rat PaxGene RNA',
    tissue_name_release: 't30-blood-rna',
  },
  {
    bic_tissue_code: 'T31',
    bic_tissue_name: 'EDTA Plasma',
    motrpac_tissue_code: 'T31 Rat Plasma',
    tissue_name_release: 't31-plasma',
  },
  {
    bic_tissue_code: 'T32',
    bic_tissue_name: 'Packed Cells',
    motrpac_tissue_code: 'T32 Rat Packed Cells',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T33',
    bic_tissue_name: 'Hippocampus',
    motrpac_tissue_code: 'T33 Rat Hippocampus',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T34',
    bic_tissue_name: 'Cortex',
    motrpac_tissue_code: 'T34 Rat Cortex',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T35',
    bic_tissue_name: 'Hypothalamus',
    motrpac_tissue_code: 'T35 Rat Hypothalmus',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T36',
    bic_tissue_name: 'Gastrocnemius',
    motrpac_tissue_code: 'T36 Rat Gastrocnemius',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T37',
    bic_tissue_name: 'Vastus Lateralis',
    motrpac_tissue_code: 'T37 Rat Vastus Lateralis',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T38',
    bic_tissue_name: 'Tibia',
    motrpac_tissue_code: 'T38 Rat Tibia',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T39',
    bic_tissue_name: 'Heart',
    motrpac_tissue_code: 'T39 Rat Heart',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T40',
    bic_tissue_name: 'Kidney',
    motrpac_tissue_code: 'T40 Rat Kidney',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T41',
    bic_tissue_name: 'Adrenals',
    motrpac_tissue_code: 'T41 Rat Adrenals',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T42',
    bic_tissue_name: 'Colon',
    motrpac_tissue_code: 'T42 Rat Colon',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T43',
    bic_tissue_name: 'Spleen',
    motrpac_tissue_code: 'T43 Rat Spleen',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T44',
    bic_tissue_name: 'Testes',
    motrpac_tissue_code: 'T44 Rat Testes',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T45',
    bic_tissue_name: 'Ovaries',
    motrpac_tissue_code: 'T45 Rat Ovaries',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T46',
    bic_tissue_name: 'Brown Adipose',
    motrpac_tissue_code: 'T46 Rat Brown Adipose',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T47',
    bic_tissue_name: 'White Adipose',
    motrpac_tissue_code: 'T47 Rat White Adipose',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T48',
    bic_tissue_name: 'Aorta',
    motrpac_tissue_code: 'T48 Rat Aorta',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T49',
    bic_tissue_name: 'Lung',
    motrpac_tissue_code: 'T49 Rat Lung',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T50',
    bic_tissue_name: 'Small Intestine',
    motrpac_tissue_code: 'T50 Rat Small Intestine',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T51',
    bic_tissue_name: 'Liver',
    motrpac_tissue_code: 'T51 Rat Liver',
    tissue_name_release: null,
  },
  {
    bic_tissue_code: 'T52',
    bic_tissue_name: 'Hippocampus Powder',
    motrpac_tissue_code: 'T52 Rat Hippocampus Powder',
    tissue_name_release: 't52-hippocampus',
  },
  {
    bic_tissue_code: 'T53',
    bic_tissue_name: 'Cortex Powder',
    motrpac_tissue_code: 'T53 Rat Cortex Powder',
    tissue_name_release: 't53-cortex',
  },
  {
    bic_tissue_code: 'T54',
    bic_tissue_name: 'Hypothalamus Powder',
    motrpac_tissue_code: 'T54 Rat Hypothalamus Powder',
    tissue_name_release: 't54-hypothalamus',
  },
  {
    bic_tissue_code: 'T55',
    bic_tissue_name: 'Gastrocnemius Powder',
    motrpac_tissue_code: 'T55 Rat Gastrocnemius Powder',
    tissue_name_release: 't55-gastrocnemius',
  },
  {
    bic_tissue_code: 'T56',
    bic_tissue_name: 'Vastus Lateralis Powder',
    motrpac_tissue_code: 'T56 Rat Vastus Lateralis Powder',
    tissue_name_release: 't56-vastus-lateralis',
  },
  {
    bic_tissue_code: 'T57',
    bic_tissue_name: 'Tibia Powder',
    motrpac_tissue_code: 'T57 Rat Tibia Powder',
    tissue_name_release: 't57-tibia',
  },
  {
    bic_tissue_code: 'T58',
    bic_tissue_name: 'Heart Powder',
    motrpac_tissue_code: 'T58 Rat Heart Powder',
    tissue_name_release: 't58-heart',
  },
  {
    bic_tissue_code: 'T59',
    bic_tissue_name: 'Kidney Powder',
    motrpac_tissue_code: 'T59 Rat Kidney Powder',
    tissue_name_release: 't59-kidney',
  },
  {
    bic_tissue_code: 'T60',
    bic_tissue_name: 'Adrenal Powder',
    motrpac_tissue_code: 'T60 Rat Adrenal Powder',
    tissue_name_release: 't60-adrenal',
  },
  {
    bic_tissue_code: 'T61',
    bic_tissue_name: 'Colon Powder',
    motrpac_tissue_code: 'T61 Rat Colon Powder',
    tissue_name_release: 't61-colon',
  },
  {
    bic_tissue_code: 'T62',
    bic_tissue_name: 'Spleen Powder',
    motrpac_tissue_code: 'T62 Rat Spleen Powder',
    tissue_name_release: 't62-spleen',
  },
  {
    bic_tissue_code: 'T63',
    bic_tissue_name: 'Testes Powder',
    motrpac_tissue_code: 'T63 Rat Testes Powder',
    tissue_name_release: 't63-testes',
  },
  {
    bic_tissue_code: 'T64',
    bic_tissue_name: 'Ovaries Powder',
    motrpac_tissue_code: 'T64 Rat Ovaries Powder',
    tissue_name_release: 't64-ovaries',
  },
  {
    bic_tissue_code: 'T65',
    bic_tissue_name: 'Aorta Powder',
    motrpac_tissue_code: 'T65 Rat Aorta Powder',
    tissue_name_release: 't65-aorta',
  },
  {
    bic_tissue_code: 'T66',
    bic_tissue_name: 'Lung Powder',
    motrpac_tissue_code: 'T66 Rat Lung Powder',
    tissue_name_release: 't66-lung',
  },
  {
    bic_tissue_code: 'T67',
    bic_tissue_name: 'Small Intestine Powder',
    motrpac_tissue_code: 'T67 Rat Small Intestine Powder',
    tissue_name_release: 't67-small-intestine',
  },
  {
    bic_tissue_code: 'T68',
    bic_tissue_name: 'Liver Powder',
    motrpac_tissue_code: 'T68 Rat Liver Powder',
    tissue_name_release: 't68-liver',
  },
  {
    bic_tissue_code: 'T69',
    bic_tissue_name: 'Brown Adipose Powder',
    motrpac_tissue_code: 'T69 Rat Brown Adipose Powder',
    tissue_name_release: 't69-brown-adipose',
  },
  {
    bic_tissue_code: 'T70',
    bic_tissue_name: 'White Adipose Powder',
    motrpac_tissue_code: 'T70 Rat White Adipose Powder',
    tissue_name_release: 't70-white-adipose',
  },
  {
    bic_tissue_code: 'T99',
    bic_tissue_name: 'Vena Cava Powder',
    motrpac_tissue_code: 'T99 Rat Vena Cava Powder',
    tissue_name_release: 't99-vena-cava',
  },
];

export default tissues;
