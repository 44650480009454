const assayList = {
  pass1b_06: [
    'Acetyl Proteomics',
    'ATAC-seq',
    'Global Proteomics',
    'Immunoassay',
    'Phosphoproteomics',
    'Protein Ubiquitination',
    'RNA-seq',
    'RRBS',
    'Targeted 3-Hydroxyisobutyric Acid (3-HIB)',
    'Targeted Acyl-CoA',
    'Targeted Acylcarnitines',
    'Targeted Amines',
    'Targeted Amino Acids',
    'Targeted Beta-Aminoisobutyric Acid',
    'Targeted Ceramide',
    'Targeted Conventional',
    'Targeted Ethanolamides',
    'Targeted Keto Acids',
    'Targeted Nucleotides',
    'Targeted Organic Acids',
    'Targeted Oxylipins',
    'Targeted Sphingomyelin',
    'Targeted Tricarboxylic Acid Cycle',
    'Untargeted HILIC-Positive',
    'Untargeted Ion-Pair Negative',
    'Untargeted Lipidomics Reversed-Phase Negative',
    'Untargeted Lipidomics Reversed-Phase Positive',
    'Untargeted Reversed-Phase Negative',
    'Untargeted Reversed-Phase Positive',
  ],
  pass1a_06: [
    'Acetyl Proteomics',
    'ATAC-seq',
    'Global Proteomics',
    'Phosphoproteomics',
    'Protein Ubiquitination',
    'RNA-seq',
    'RRBS',
    'Targeted 3-Hydroxyisobutyric Acid (3-HIB)',
    'Targeted Acyl-CoA',
    'Targeted Acylcarnitines',
    'Targeted Amino Acids',
    'Targeted Beta-Aminoisobutyric Acid',
    'Targeted Ceramide',
    'Targeted Conventional',
    'Targeted Keto Acids',
    'Targeted Nucleotides',
    'Targeted Organic Acids',
    'Targeted Oxylipins',
    'Targeted Sphingomyelin',
    'Untargeted HILIC-Positive',
    'Untargeted Ion-Pair Negative',
    'Untargeted Lipidomics Reversed-Phase Negative',
    'Untargeted Lipidomics Reversed-Phase Positive',
    'Untargeted Reversed-Phase Negative',
    'Untargeted Reversed-Phase Positive',
  ],
  human_sed_adu: [
    'ATAC-seq',
    'Global Proteomics',
    'Immunoassay for corticosteroids',
    'Immunoassay for glucagon and related metabolic hormones',
    'Immunoassay for insulin and related metabolic hormones',
    'MethylCap-seq',
    'Phosphoproteomics',
    'Proteomics Olink',
    'RNA-seq',
    'Targeted Acyl-CoA',
    'Targeted Amines',
    'Targeted Conventional',
    'Targeted Keto Acids',
    'Targeted Nucleotides',
    'Targeted Oxylipins',
    'Targeted Tricarboxylic Acid Cycle',
    'Untargeted HILIC-Positive',
    'Untargeted Ion-Pair Negative',
    'Untargeted Lipidomics Reversed-Phase Negative',
    'Untargeted Lipidomics Reversed-Phase Positive',
    'Untargeted Reversed-Phase Negative',
    'Untargeted Reversed-Phase Positive',
  ],
};

export default assayList;
