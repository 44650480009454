export const genes = [
{value: '1700066b19rik', id: '1700066B19RIK'},
{value: '1700092m07rik', id: '1700092M07RIK'},
{value: '3110082j24rik', id: '3110082J24RIK'},
{value: '4930444p10rik', id: '4930444P10RIK'},
{value: '4933400a11rik', id: '4933400A11RIK'},
{value: '4933403o08rik', id: '4933403O08RIK'},
{value: '5_8s_rrna', id: '5_8S_RRNA'},
{value: '5s_rrna', id: '5S_RRNA'},
{value: '7sk', id: '7SK'},
{value: 'a1bg', id: 'A1BG'},
{value: 'a1cf', id: 'A1CF'},
{value: 'a2m', id: 'A2M'},
{value: 'a2ml1', id: 'A2ML1'},
{value: 'a3galt2', id: 'A3GALT2'},
{value: 'a4galt', id: 'A4GALT'},
{value: 'a4gnt', id: 'A4GNT'},
{value: 'aa926063', id: 'AA926063'},
{value: 'aaas', id: 'AAAS'},
{value: 'aabr07000046.1', id: 'AABR07000046.1'},
{value: 'aabr07000137.1', id: 'AABR07000137.1'},
{value: 'aabr07000145.1', id: 'AABR07000145.1'},
{value: 'aabr07000147.1', id: 'AABR07000147.1'},
{value: 'aabr07000156.1', id: 'AABR07000156.1'},
{value: 'aabr07000158.1', id: 'AABR07000158.1'},
{value: 'aabr07000158.2', id: 'AABR07000158.2'},
{value: 'aabr07000159.1', id: 'AABR07000159.1'},
{value: 'aabr07000159.3', id: 'AABR07000159.3'},
{value: 'aabr07000159.4', id: 'AABR07000159.4'},
{value: 'aabr07000173.1', id: 'AABR07000173.1'},
{value: 'aabr07000200.1', id: 'AABR07000200.1'},
{value: 'aabr07000220.1', id: 'AABR07000220.1'},
{value: 'aabr07000222.1', id: 'AABR07000222.1'},
{value: 'aabr07000239.1', id: 'AABR07000239.1'},
{value: 'aabr07000247.1', id: 'AABR07000247.1'},
{value: 'aabr07000248.1', id: 'AABR07000248.1'},
{value: 'aabr07000257.1', id: 'AABR07000257.1'},
{value: 'aabr07000261.1', id: 'AABR07000261.1'},
{value: 'aabr07000276.1', id: 'AABR07000276.1'},
{value: 'aabr07000292.1', id: 'AABR07000292.1'},
{value: 'aabr07000304.1', id: 'AABR07000304.1'},
{value: 'aabr07000325.1', id: 'AABR07000325.1'},
{value: 'aabr07000332.1', id: 'AABR07000332.1'},
{value: 'aabr07000356.1', id: 'AABR07000356.1'},
{value: 'aabr07000382.1', id: 'AABR07000382.1'},
{value: 'aabr07000385.1', id: 'AABR07000385.1'},
{value: 'aabr07000385.2', id: 'AABR07000385.2'},
{value: 'aabr07000392.1', id: 'AABR07000392.1'},
{value: 'aabr07000398.1', id: 'AABR07000398.1'},
{value: 'aabr07000398.2', id: 'AABR07000398.2'},
{value: 'aabr07000402.1', id: 'AABR07000402.1'},
{value: 'aabr07000404.1', id: 'AABR07000404.1'},
{value: 'aabr07000411.1', id: 'AABR07000411.1'},
{value: 'aabr07000433.1', id: 'AABR07000433.1'},
{value: 'aabr07000436.1', id: 'AABR07000436.1'},
{value: 'aabr07000451.1', id: 'AABR07000451.1'},
{value: 'aabr07000452.1', id: 'AABR07000452.1'},
{value: 'aabr07000458.1', id: 'AABR07000458.1'},
{value: 'aabr07000484.1', id: 'AABR07000484.1'},
{value: 'aabr07000493.1', id: 'AABR07000493.1'},
{value: 'aabr07000497.1', id: 'AABR07000497.1'},
{value: 'aabr07000521.1', id: 'AABR07000521.1'},
{value: 'aabr07000523.1', id: 'AABR07000523.1'},
{value: 'aabr07000533.1', id: 'AABR07000533.1'},
{value: 'aabr07000534.1', id: 'AABR07000534.1'},
{value: 'aabr07000544.1', id: 'AABR07000544.1'},
{value: 'aabr07000574.1', id: 'AABR07000574.1'},
{value: 'aabr07000581.1', id: 'AABR07000581.1'},
{value: 'aabr07000583.1', id: 'AABR07000583.1'},
{value: 'aabr07000595.1', id: 'AABR07000595.1'},
{value: 'aabr07000629.1', id: 'AABR07000629.1'},
{value: 'aabr07000629.2', id: 'AABR07000629.2'},
{value: 'aabr07000629.3', id: 'AABR07000629.3'},
{value: 'aabr07000639.1', id: 'AABR07000639.1'},
{value: 'aabr07000658.1', id: 'AABR07000658.1'},
{value: 'aabr07000714.1', id: 'AABR07000714.1'},
{value: 'aabr07000714.2', id: 'AABR07000714.2'},
{value: 'aabr07000723.1', id: 'AABR07000723.1'},
{value: 'aabr07000725.1', id: 'AABR07000725.1'},
{value: 'aabr07000733.1', id: 'AABR07000733.1'},
{value: 'aabr07000738.1', id: 'AABR07000738.1'},
{value: 'aabr07000765.2', id: 'AABR07000765.2'},
{value: 'aabr07000765.3', id: 'AABR07000765.3'},
{value: 'aabr07000900.1', id: 'AABR07000900.1'},
{value: 'aabr07000902.1', id: 'AABR07000902.1'},
{value: 'aabr07000968.1', id: 'AABR07000968.1'},
{value: 'aabr07000968.2', id: 'AABR07000968.2'},
{value: 'aabr07000968.3', id: 'AABR07000968.3'},
{value: 'aabr07000978.1', id: 'AABR07000978.1'},
{value: 'aabr07000986.1', id: 'AABR07000986.1'},
{value: 'aabr07000989.1', id: 'AABR07000989.1'},
{value: 'aabr07000990.1', id: 'AABR07000990.1'},
{value: 'aabr07001001.1', id: 'AABR07001001.1'},
{value: 'aabr07001001.2', id: 'AABR07001001.2'},
{value: 'aabr07001003.1', id: 'AABR07001003.1'},
{value: 'aabr07001006.1', id: 'AABR07001006.1'},
{value: 'aabr07001010.1', id: 'AABR07001010.1'},
{value: 'aabr07001018.1', id: 'AABR07001018.1'},
{value: 'aabr07001018.2', id: 'AABR07001018.2'},
{value: 'aabr07001018.3', id: 'AABR07001018.3'},
{value: 'aabr07001018.4', id: 'AABR07001018.4'},
{value: 'aabr07001018.5', id: 'AABR07001018.5'},
{value: 'aabr07001019.1', id: 'AABR07001019.1'},
{value: 'aabr07001025.1', id: 'AABR07001025.1'},
{value: 'aabr07001035.1', id: 'AABR07001035.1'},
{value: 'aabr07001037.1', id: 'AABR07001037.1'},
{value: 'aabr07001048.1', id: 'AABR07001048.1'},
{value: 'aabr07001054.1', id: 'AABR07001054.1'},
{value: 'aabr07001054.2', id: 'AABR07001054.2'},
{value: 'aabr07001057.1', id: 'AABR07001057.1'},
{value: 'aabr07001060.1', id: 'AABR07001060.1'},
{value: 'aabr07001061.1', id: 'AABR07001061.1'},
{value: 'aabr07001064.1', id: 'AABR07001064.1'},
{value: 'aabr07001068.1', id: 'AABR07001068.1'},
{value: 'aabr07001099.2', id: 'AABR07001099.2'},
{value: 'aabr07001100.1', id: 'AABR07001100.1'},
{value: 'aabr07001151.1', id: 'AABR07001151.1'},
{value: 'aabr07001160.1', id: 'AABR07001160.1'},
{value: 'aabr07001207.1', id: 'AABR07001207.1'},
{value: 'aabr07001210.1', id: 'AABR07001210.1'},
{value: 'aabr07001233.1', id: 'AABR07001233.1'},
{value: 'aabr07001348.1', id: 'AABR07001348.1'},
{value: 'aabr07001358.1', id: 'AABR07001358.1'},
{value: 'aabr07001379.1', id: 'AABR07001379.1'},
{value: 'aabr07001379.2', id: 'AABR07001379.2'},
{value: 'aabr07001382.2', id: 'AABR07001382.2'},
{value: 'aabr07001389.1', id: 'AABR07001389.1'},
{value: 'aabr07001392.1', id: 'AABR07001392.1'},
{value: 'aabr07001408.1', id: 'AABR07001408.1'},
{value: 'aabr07001416.1', id: 'AABR07001416.1'},
{value: 'aabr07001416.2', id: 'AABR07001416.2'},
{value: 'aabr07001416.3', id: 'AABR07001416.3'},
{value: 'aabr07001432.1', id: 'AABR07001432.1'},
{value: 'aabr07001432.2', id: 'AABR07001432.2'},
{value: 'aabr07001433.1', id: 'AABR07001433.1'},
{value: 'aabr07001435.1', id: 'AABR07001435.1'},
{value: 'aabr07001444.1', id: 'AABR07001444.1'},
{value: 'aabr07001448.1', id: 'AABR07001448.1'},
{value: 'aabr07001452.1', id: 'AABR07001452.1'},
{value: 'aabr07001455.1', id: 'AABR07001455.1'},
{value: 'aabr07001477.1', id: 'AABR07001477.1'},
{value: 'aabr07001493.1', id: 'AABR07001493.1'},
{value: 'aabr07001497.1', id: 'AABR07001497.1'},
{value: 'aabr07001497.2', id: 'AABR07001497.2'},
{value: 'aabr07001512.1', id: 'AABR07001512.1'},
{value: 'aabr07001516.1', id: 'AABR07001516.1'},
{value: 'aabr07001518.1', id: 'AABR07001518.1'},
{value: 'aabr07001519.1', id: 'AABR07001519.1'},
{value: 'aabr07001555.1', id: 'AABR07001555.1'},
{value: 'aabr07001561.1', id: 'AABR07001561.1'},
{value: 'aabr07001573.1', id: 'AABR07001573.1'},
{value: 'aabr07001573.2', id: 'AABR07001573.2'},
{value: 'aabr07001591.1', id: 'AABR07001591.1'},
{value: 'aabr07001592.1', id: 'AABR07001592.1'},
{value: 'aabr07001592.2', id: 'AABR07001592.2'},
{value: 'aabr07001599.1', id: 'AABR07001599.1'},
{value: 'aabr07001610.1', id: 'AABR07001610.1'},
{value: 'aabr07001623.1', id: 'AABR07001623.1'},
{value: 'aabr07001634.1', id: 'AABR07001634.1'},
{value: 'aabr07001640.1', id: 'AABR07001640.1'},
{value: 'aabr07001662.1', id: 'AABR07001662.1'},
{value: 'aabr07001699.1', id: 'AABR07001699.1'},
{value: 'aabr07001700.1', id: 'AABR07001700.1'},
{value: 'aabr07001734.1', id: 'AABR07001734.1'},
{value: 'aabr07001762.1', id: 'AABR07001762.1'},
{value: 'aabr07001764.1', id: 'AABR07001764.1'},
{value: 'aabr07001780.1', id: 'AABR07001780.1'},
{value: 'aabr07001799.1', id: 'AABR07001799.1'},
{value: 'aabr07001807.1', id: 'AABR07001807.1'},
{value: 'aabr07001807.2', id: 'AABR07001807.2'},
{value: 'aabr07001880.1', id: 'AABR07001880.1'},
{value: 'aabr07001888.1', id: 'AABR07001888.1'},
{value: 'aabr07001896.1', id: 'AABR07001896.1'},
{value: 'aabr07001902.1', id: 'AABR07001902.1'},
{value: 'aabr07001905.1', id: 'AABR07001905.1'},
{value: 'aabr07001910.1', id: 'AABR07001910.1'},
{value: 'aabr07001923.1', id: 'AABR07001923.1'},
{value: 'aabr07001926.1', id: 'AABR07001926.1'},
{value: 'aabr07001926.2', id: 'AABR07001926.2'},
{value: 'aabr07001926.3', id: 'AABR07001926.3'},
{value: 'aabr07001929.1', id: 'AABR07001929.1'},
{value: 'aabr07001942.1', id: 'AABR07001942.1'},
{value: 'aabr07002010.1', id: 'AABR07002010.1'},
{value: 'aabr07002044.1', id: 'AABR07002044.1'},
{value: 'aabr07002065.1', id: 'AABR07002065.1'},
{value: 'aabr07002065.2', id: 'AABR07002065.2'},
{value: 'aabr07002066.2', id: 'AABR07002066.2'},
{value: 'aabr07002068.1', id: 'AABR07002068.1'},
{value: 'aabr07002093.1', id: 'AABR07002093.1'},
{value: 'aabr07002093.2', id: 'AABR07002093.2'},
{value: 'aabr07002140.2', id: 'AABR07002140.2'},
{value: 'aabr07002181.1', id: 'AABR07002181.1'},
{value: 'aabr07002209.1', id: 'AABR07002209.1'},
{value: 'aabr07002241.1', id: 'AABR07002241.1'},
{value: 'aabr07002247.1', id: 'AABR07002247.1'},
{value: 'aabr07002258.1', id: 'AABR07002258.1'},
{value: 'aabr07002262.1', id: 'AABR07002262.1'},
{value: 'aabr07002262.2', id: 'AABR07002262.2'},
{value: 'aabr07002337.1', id: 'AABR07002337.1'},
{value: 'aabr07002351.1', id: 'AABR07002351.1'},
{value: 'aabr07002353.1', id: 'AABR07002353.1'},
{value: 'aabr07002353.2', id: 'AABR07002353.2'},
{value: 'aabr07002382.1', id: 'AABR07002382.1'},
{value: 'aabr07002384.1', id: 'AABR07002384.1'},
{value: 'aabr07002418.1', id: 'AABR07002418.1'},
{value: 'aabr07002467.1', id: 'AABR07002467.1'},
{value: 'aabr07002473.1', id: 'AABR07002473.1'},
{value: 'aabr07002493.1', id: 'AABR07002493.1'},
{value: 'aabr07002505.1', id: 'AABR07002505.1'},
{value: 'aabr07002546.1', id: 'AABR07002546.1'},
{value: 'aabr07002564.1', id: 'AABR07002564.1'},
{value: 'aabr07002622.1', id: 'AABR07002622.1'},
{value: 'aabr07002623.1', id: 'AABR07002623.1'},
{value: 'aabr07002627.1', id: 'AABR07002627.1'},
{value: 'aabr07002644.1', id: 'AABR07002644.1'},
{value: 'aabr07002659.1', id: 'AABR07002659.1'},
{value: 'aabr07002674.1', id: 'AABR07002674.1'},
{value: 'aabr07002677.1', id: 'AABR07002677.1'},
{value: 'aabr07002677.2', id: 'AABR07002677.2'},
{value: 'aabr07002680.2', id: 'AABR07002680.2'},
{value: 'aabr07002682.1', id: 'AABR07002682.1'},
{value: 'aabr07002683.1', id: 'AABR07002683.1'},
{value: 'aabr07002689.1', id: 'AABR07002689.1'},
{value: 'aabr07002711.1', id: 'AABR07002711.1'},
{value: 'aabr07002768.1', id: 'AABR07002768.1'},
{value: 'aabr07002774.1', id: 'AABR07002774.1'},
{value: 'aabr07002774.3', id: 'AABR07002774.3'},
{value: 'aabr07002774.4', id: 'AABR07002774.4'},
{value: 'aabr07002775.1', id: 'AABR07002775.1'},
{value: 'aabr07002779.1', id: 'AABR07002779.1'},
{value: 'aabr07002782.1', id: 'AABR07002782.1'},
{value: 'aabr07002783.1', id: 'AABR07002783.1'},
{value: 'aabr07002784.1', id: 'AABR07002784.1'},
{value: 'aabr07002784.2', id: 'AABR07002784.2'},
{value: 'aabr07002788.1', id: 'AABR07002788.1'},
{value: 'aabr07002792.1', id: 'AABR07002792.1'},
{value: 'aabr07002792.2', id: 'AABR07002792.2'},
{value: 'aabr07002805.1', id: 'AABR07002805.1'},
{value: 'aabr07002835.1', id: 'AABR07002835.1'},
{value: 'aabr07002845.1', id: 'AABR07002845.1'},
{value: 'aabr07002848.1', id: 'AABR07002848.1'},
{value: 'aabr07002848.2', id: 'AABR07002848.2'},
{value: 'aabr07002854.1', id: 'AABR07002854.1'},
{value: 'aabr07002868.1', id: 'AABR07002868.1'},
{value: 'aabr07002868.2', id: 'AABR07002868.2'},
{value: 'aabr07002870.1', id: 'AABR07002870.1'},
{value: 'aabr07002870.2', id: 'AABR07002870.2'},
{value: 'aabr07002875.1', id: 'AABR07002875.1'},
{value: 'aabr07002882.1', id: 'AABR07002882.1'},
{value: 'aabr07002888.2', id: 'AABR07002888.2'},
{value: 'aabr07002893.1', id: 'AABR07002893.1'},
{value: 'aabr07002896.1', id: 'AABR07002896.1'},
{value: 'aabr07002898.1', id: 'AABR07002898.1'},
{value: 'aabr07002902.1', id: 'AABR07002902.1'},
{value: 'aabr07002906.1', id: 'AABR07002906.1'},
{value: 'aabr07002908.1', id: 'AABR07002908.1'},
{value: 'aabr07002923.1', id: 'AABR07002923.1'},
{value: 'aabr07002928.1', id: 'AABR07002928.1'},
{value: 'aabr07002938.1', id: 'AABR07002938.1'},
{value: 'aabr07002945.1', id: 'AABR07002945.1'},
{value: 'aabr07002945.2', id: 'AABR07002945.2'},
{value: 'aabr07002966.1', id: 'AABR07002966.1'},
{value: 'aabr07002967.1', id: 'AABR07002967.1'},
{value: 'aabr07002967.2', id: 'AABR07002967.2'},
{value: 'aabr07002969.1', id: 'AABR07002969.1'},
{value: 'aabr07002969.2', id: 'AABR07002969.2'},
{value: 'aabr07002969.3', id: 'AABR07002969.3'},
{value: 'aabr07002973.1', id: 'AABR07002973.1'},
{value: 'aabr07002997.1', id: 'AABR07002997.1'},
{value: 'aabr07002997.2', id: 'AABR07002997.2'},
{value: 'aabr07003001.1', id: 'AABR07003001.1'},
{value: 'aabr07003001.2', id: 'AABR07003001.2'},
{value: 'aabr07003007.1', id: 'AABR07003007.1'},
{value: 'aabr07003024.1', id: 'AABR07003024.1'},
{value: 'aabr07003030.1', id: 'AABR07003030.1'},
{value: 'aabr07003030.2', id: 'AABR07003030.2'},
{value: 'aabr07003040.1', id: 'AABR07003040.1'},
{value: 'aabr07003049.1', id: 'AABR07003049.1'},
{value: 'aabr07003049.2', id: 'AABR07003049.2'},
{value: 'aabr07003051.1', id: 'AABR07003051.1'},
{value: 'aabr07003056.1', id: 'AABR07003056.1'},
{value: 'aabr07003056.2', id: 'AABR07003056.2'},
{value: 'aabr07003056.3', id: 'AABR07003056.3'},
{value: 'aabr07003102.1', id: 'AABR07003102.1'},
{value: 'aabr07003167.1', id: 'AABR07003167.1'},
{value: 'aabr07003167.2', id: 'AABR07003167.2'},
{value: 'aabr07003167.3', id: 'AABR07003167.3'},
{value: 'aabr07003173.1', id: 'AABR07003173.1'},
{value: 'aabr07003176.1', id: 'AABR07003176.1'},
{value: 'aabr07003186.1', id: 'AABR07003186.1'},
{value: 'aabr07003186.2', id: 'AABR07003186.2'},
{value: 'aabr07003187.1', id: 'AABR07003187.1'},
{value: 'aabr07003190.1', id: 'AABR07003190.1'},
{value: 'aabr07003233.1', id: 'AABR07003233.1'},
{value: 'aabr07003235.2', id: 'AABR07003235.2'},
{value: 'aabr07003241.1', id: 'AABR07003241.1'},
{value: 'aabr07003241.3', id: 'AABR07003241.3'},
{value: 'aabr07003244.1', id: 'AABR07003244.1'},
{value: 'aabr07003250.1', id: 'AABR07003250.1'},
{value: 'aabr07003250.6', id: 'AABR07003250.6'},
{value: 'aabr07003273.1', id: 'AABR07003273.1'},
{value: 'aabr07003274.1', id: 'AABR07003274.1'},
{value: 'aabr07003291.1', id: 'AABR07003291.1'},
{value: 'aabr07003302.1', id: 'AABR07003302.1'},
{value: 'aabr07003304.1', id: 'AABR07003304.1'},
{value: 'aabr07003304.2', id: 'AABR07003304.2'},
{value: 'aabr07003310.1', id: 'AABR07003310.1'},
{value: 'aabr07003310.2', id: 'AABR07003310.2'},
{value: 'aabr07003310.3', id: 'AABR07003310.3'},
{value: 'aabr07003344.1', id: 'AABR07003344.1'},
{value: 'aabr07003349.1', id: 'AABR07003349.1'},
{value: 'aabr07003354.1', id: 'AABR07003354.1'},
{value: 'aabr07003380.1', id: 'AABR07003380.1'},
{value: 'aabr07003390.1', id: 'AABR07003390.1'},
{value: 'aabr07003392.1', id: 'AABR07003392.1'},
{value: 'aabr07003398.1', id: 'AABR07003398.1'},
{value: 'aabr07003418.1', id: 'AABR07003418.1'},
{value: 'aabr07003433.1', id: 'AABR07003433.1'},
{value: 'aabr07003449.1', id: 'AABR07003449.1'},
{value: 'aabr07003463.1', id: 'AABR07003463.1'},
{value: 'aabr07003466.1', id: 'AABR07003466.1'},
{value: 'aabr07003468.1', id: 'AABR07003468.1'},
{value: 'aabr07003492.1', id: 'AABR07003492.1'},
{value: 'aabr07003492.2', id: 'AABR07003492.2'},
{value: 'aabr07003495.1', id: 'AABR07003495.1'},
{value: 'aabr07003500.2', id: 'AABR07003500.2'},
{value: 'aabr07003504.1', id: 'AABR07003504.1'},
{value: 'aabr07003510.1', id: 'AABR07003510.1'},
{value: 'aabr07003537.1', id: 'AABR07003537.1'},
{value: 'aabr07003553.1', id: 'AABR07003553.1'},
{value: 'aabr07003563.1', id: 'AABR07003563.1'},
{value: 'aabr07003610.1', id: 'AABR07003610.1'},
{value: 'aabr07003650.1', id: 'AABR07003650.1'},
{value: 'aabr07003699.1', id: 'AABR07003699.1'},
{value: 'aabr07003713.1', id: 'AABR07003713.1'},
{value: 'aabr07003751.1', id: 'AABR07003751.1'},
{value: 'aabr07003786.1', id: 'AABR07003786.1'},
{value: 'aabr07003833.1', id: 'AABR07003833.1'},
{value: 'aabr07003835.1', id: 'AABR07003835.1'},
{value: 'aabr07003841.1', id: 'AABR07003841.1'},
{value: 'aabr07003933.1', id: 'AABR07003933.1'},
{value: 'aabr07003935.1', id: 'AABR07003935.1'},
{value: 'aabr07003935.10', id: 'AABR07003935.10'},
{value: 'aabr07003935.11', id: 'AABR07003935.11'},
{value: 'aabr07003935.12', id: 'AABR07003935.12'},
{value: 'aabr07003935.13', id: 'AABR07003935.13'},
{value: 'aabr07003935.14', id: 'AABR07003935.14'},
{value: 'aabr07003935.15', id: 'AABR07003935.15'},
{value: 'aabr07003935.2', id: 'AABR07003935.2'},
{value: 'aabr07003935.3', id: 'AABR07003935.3'},
{value: 'aabr07003935.4', id: 'AABR07003935.4'},
{value: 'aabr07003935.5', id: 'AABR07003935.5'},
{value: 'aabr07003935.6', id: 'AABR07003935.6'},
{value: 'aabr07003935.7', id: 'AABR07003935.7'},
{value: 'aabr07003935.8', id: 'AABR07003935.8'},
{value: 'aabr07003935.9', id: 'AABR07003935.9'},
{value: 'aabr07003944.1', id: 'AABR07003944.1'},
{value: 'aabr07004018.1', id: 'AABR07004018.1'},
{value: 'aabr07004021.1', id: 'AABR07004021.1'},
{value: 'aabr07004022.1', id: 'AABR07004022.1'},
{value: 'aabr07004025.1', id: 'AABR07004025.1'},
{value: 'aabr07004033.1', id: 'AABR07004033.1'},
{value: 'aabr07004061.1', id: 'AABR07004061.1'},
{value: 'aabr07004085.1', id: 'AABR07004085.1'},
{value: 'aabr07004090.1', id: 'AABR07004090.1'},
{value: 'aabr07004100.1', id: 'AABR07004100.1'},
{value: 'aabr07004101.1', id: 'AABR07004101.1'},
{value: 'aabr07004101.2', id: 'AABR07004101.2'},
{value: 'aabr07004112.1', id: 'AABR07004112.1'},
{value: 'aabr07004130.1', id: 'AABR07004130.1'},
{value: 'aabr07004134.1', id: 'AABR07004134.1'},
{value: 'aabr07004136.1', id: 'AABR07004136.1'},
{value: 'aabr07004183.1', id: 'AABR07004183.1'},
{value: 'aabr07004189.1', id: 'AABR07004189.1'},
{value: 'aabr07004190.1', id: 'AABR07004190.1'},
{value: 'aabr07004221.1', id: 'AABR07004221.1'},
{value: 'aabr07004221.2', id: 'AABR07004221.2'},
{value: 'aabr07004227.1', id: 'AABR07004227.1'},
{value: 'aabr07004227.2', id: 'AABR07004227.2'},
{value: 'aabr07004228.1', id: 'AABR07004228.1'},
{value: 'aabr07004229.1', id: 'AABR07004229.1'},
{value: 'aabr07004232.1', id: 'AABR07004232.1'},
{value: 'aabr07004232.2', id: 'AABR07004232.2'},
{value: 'aabr07004241.1', id: 'AABR07004241.1'},
{value: 'aabr07004241.2', id: 'AABR07004241.2'},
{value: 'aabr07004244.1', id: 'AABR07004244.1'},
{value: 'aabr07004254.1', id: 'AABR07004254.1'},
{value: 'aabr07004258.1', id: 'AABR07004258.1'},
{value: 'aabr07004259.1', id: 'AABR07004259.1'},
{value: 'aabr07004263.1', id: 'AABR07004263.1'},
{value: 'aabr07004269.1', id: 'AABR07004269.1'},
{value: 'aabr07004269.2', id: 'AABR07004269.2'},
{value: 'aabr07004269.3', id: 'AABR07004269.3'},
{value: 'aabr07004269.4', id: 'AABR07004269.4'},
{value: 'aabr07004269.5', id: 'AABR07004269.5'},
{value: 'aabr07004269.6', id: 'AABR07004269.6'},
{value: 'aabr07004272.1', id: 'AABR07004272.1'},
{value: 'aabr07004292.1', id: 'AABR07004292.1'},
{value: 'aabr07004297.1', id: 'AABR07004297.1'},
{value: 'aabr07004304.1', id: 'AABR07004304.1'},
{value: 'aabr07004305.1', id: 'AABR07004305.1'},
{value: 'aabr07004305.2', id: 'AABR07004305.2'},
{value: 'aabr07004364.1', id: 'AABR07004364.1'},
{value: 'aabr07004397.1', id: 'AABR07004397.1'},
{value: 'aabr07004397.2', id: 'AABR07004397.2'},
{value: 'aabr07004404.1', id: 'AABR07004404.1'},
{value: 'aabr07004428.1', id: 'AABR07004428.1'},
{value: 'aabr07004482.1', id: 'AABR07004482.1'},
{value: 'aabr07004482.2', id: 'AABR07004482.2'},
{value: 'aabr07004495.1', id: 'AABR07004495.1'},
{value: 'aabr07004497.1', id: 'AABR07004497.1'},
{value: 'aabr07004530.1', id: 'AABR07004530.1'},
{value: 'aabr07004539.1', id: 'AABR07004539.1'},
{value: 'aabr07004549.1', id: 'AABR07004549.1'},
{value: 'aabr07004560.1', id: 'AABR07004560.1'},
{value: 'aabr07004572.1', id: 'AABR07004572.1'},
{value: 'aabr07004684.1', id: 'AABR07004684.1'},
{value: 'aabr07004703.1', id: 'AABR07004703.1'},
{value: 'aabr07004733.1', id: 'AABR07004733.1'},
{value: 'aabr07004733.2', id: 'AABR07004733.2'},
{value: 'aabr07004743.1', id: 'AABR07004743.1'},
{value: 'aabr07004746.1', id: 'AABR07004746.1'},
{value: 'aabr07004746.2', id: 'AABR07004746.2'},
{value: 'aabr07004756.1', id: 'AABR07004756.1'},
{value: 'aabr07004761.1', id: 'AABR07004761.1'},
{value: 'aabr07004765.1', id: 'AABR07004765.1'},
{value: 'aabr07004776.1', id: 'AABR07004776.1'},
{value: 'aabr07004776.2', id: 'AABR07004776.2'},
{value: 'aabr07004783.1', id: 'AABR07004783.1'},
{value: 'aabr07004799.1', id: 'AABR07004799.1'},
{value: 'aabr07004803.1', id: 'AABR07004803.1'},
{value: 'aabr07004812.1', id: 'AABR07004812.1'},
{value: 'aabr07004812.2', id: 'AABR07004812.2'},
{value: 'aabr07004823.1', id: 'AABR07004823.1'},
{value: 'aabr07004833.1', id: 'AABR07004833.1'},
{value: 'aabr07004852.1', id: 'AABR07004852.1'},
{value: 'aabr07004868.1', id: 'AABR07004868.1'},
{value: 'aabr07004868.2', id: 'AABR07004868.2'},
{value: 'aabr07004876.1', id: 'AABR07004876.1'},
{value: 'aabr07004876.2', id: 'AABR07004876.2'},
{value: 'aabr07004877.1', id: 'AABR07004877.1'},
{value: 'aabr07004881.1', id: 'AABR07004881.1'},
{value: 'aabr07004888.1', id: 'AABR07004888.1'},
{value: 'aabr07004894.1', id: 'AABR07004894.1'},
{value: 'aabr07004912.2', id: 'AABR07004912.2'},
{value: 'aabr07004947.1', id: 'AABR07004947.1'},
{value: 'aabr07004949.1', id: 'AABR07004949.1'},
{value: 'aabr07004960.1', id: 'AABR07004960.1'},
{value: 'aabr07004966.1', id: 'AABR07004966.1'},
{value: 'aabr07004971.1', id: 'AABR07004971.1'},
{value: 'aabr07004975.1', id: 'AABR07004975.1'},
{value: 'aabr07004992.1', id: 'AABR07004992.1'},
{value: 'aabr07004993.1', id: 'AABR07004993.1'},
{value: 'aabr07005004.2', id: 'AABR07005004.2'},
{value: 'aabr07005027.1', id: 'AABR07005027.1'},
{value: 'aabr07005028.1', id: 'AABR07005028.1'},
{value: 'aabr07005028.2', id: 'AABR07005028.2'},
{value: 'aabr07005031.1', id: 'AABR07005031.1'},
{value: 'aabr07005031.2', id: 'AABR07005031.2'},
{value: 'aabr07005031.3', id: 'AABR07005031.3'},
{value: 'aabr07005032.1', id: 'AABR07005032.1'},
{value: 'aabr07005055.1', id: 'AABR07005055.1'},
{value: 'aabr07005416.1', id: 'AABR07005416.1'},
{value: 'aabr07005447.1', id: 'AABR07005447.1'},
{value: 'aabr07005506.1', id: 'AABR07005506.1'},
{value: 'aabr07005508.1', id: 'AABR07005508.1'},
{value: 'aabr07005533.1', id: 'AABR07005533.1'},
{value: 'aabr07005535.1', id: 'AABR07005535.1'},
{value: 'aabr07005543.1', id: 'AABR07005543.1'},
{value: 'aabr07005563.1', id: 'AABR07005563.1'},
{value: 'aabr07005564.1', id: 'AABR07005564.1'},
{value: 'aabr07005565.1', id: 'AABR07005565.1'},
{value: 'aabr07005569.1', id: 'AABR07005569.1'},
{value: 'aabr07005572.1', id: 'AABR07005572.1'},
{value: 'aabr07005572.2', id: 'AABR07005572.2'},
{value: 'aabr07005573.1', id: 'AABR07005573.1'},
{value: 'aabr07005579.1', id: 'AABR07005579.1'},
{value: 'aabr07005581.1', id: 'AABR07005581.1'},
{value: 'aabr07005588.1', id: 'AABR07005588.1'},
{value: 'aabr07005593.1', id: 'AABR07005593.1'},
{value: 'aabr07005596.1', id: 'AABR07005596.1'},
{value: 'aabr07005597.1', id: 'AABR07005597.1'},
{value: 'aabr07005618.1', id: 'AABR07005618.1'},
{value: 'aabr07005633.1', id: 'AABR07005633.1'},
{value: 'aabr07005664.1', id: 'AABR07005664.1'},
{value: 'aabr07005667.1', id: 'AABR07005667.1'},
{value: 'aabr07005692.1', id: 'AABR07005692.1'},
{value: 'aabr07005711.2', id: 'AABR07005711.2'},
{value: 'aabr07005723.1', id: 'AABR07005723.1'},
{value: 'aabr07005752.1', id: 'AABR07005752.1'},
{value: 'aabr07005758.1', id: 'AABR07005758.1'},
{value: 'aabr07005758.2', id: 'AABR07005758.2'},
{value: 'aabr07005775.1', id: 'AABR07005775.1'},
{value: 'aabr07005778.1', id: 'AABR07005778.1'},
{value: 'aabr07005779.1', id: 'AABR07005779.1'},
{value: 'aabr07005779.2', id: 'AABR07005779.2'},
{value: 'aabr07005779.3', id: 'AABR07005779.3'},
{value: 'aabr07005779.4', id: 'AABR07005779.4'},
{value: 'aabr07005779.5', id: 'AABR07005779.5'},
{value: 'aabr07005790.1', id: 'AABR07005790.1'},
{value: 'aabr07005790.2', id: 'AABR07005790.2'},
{value: 'aabr07005794.1', id: 'AABR07005794.1'},
{value: 'aabr07005801.1', id: 'AABR07005801.1'},
{value: 'aabr07005806.1', id: 'AABR07005806.1'},
{value: 'aabr07005808.1', id: 'AABR07005808.1'},
{value: 'aabr07005821.1', id: 'AABR07005821.1'},
{value: 'aabr07005828.1', id: 'AABR07005828.1'},
{value: 'aabr07005837.1', id: 'AABR07005837.1'},
{value: 'aabr07005837.2', id: 'AABR07005837.2'},
{value: 'aabr07005838.1', id: 'AABR07005838.1'},
{value: 'aabr07005838.2', id: 'AABR07005838.2'},
{value: 'aabr07005844.1', id: 'AABR07005844.1'},
{value: 'aabr07005871.1', id: 'AABR07005871.1'},
{value: 'aabr07005886.1', id: 'AABR07005886.1'},
{value: 'aabr07005888.1', id: 'AABR07005888.1'},
{value: 'aabr07005888.2', id: 'AABR07005888.2'},
{value: 'aabr07005937.1', id: 'AABR07005937.1'},
{value: 'aabr07005939.1', id: 'AABR07005939.1'},
{value: 'aabr07005949.1', id: 'AABR07005949.1'},
{value: 'aabr07005950.1', id: 'AABR07005950.1'},
{value: 'aabr07005955.1', id: 'AABR07005955.1'},
{value: 'aabr07005961.1', id: 'AABR07005961.1'},
{value: 'aabr07005965.1', id: 'AABR07005965.1'},
{value: 'aabr07005977.1', id: 'AABR07005977.1'},
{value: 'aabr07005977.2', id: 'AABR07005977.2'},
{value: 'aabr07005977.3', id: 'AABR07005977.3'},
{value: 'aabr07005983.1', id: 'AABR07005983.1'},
{value: 'aabr07005985.1', id: 'AABR07005985.1'},
{value: 'aabr07005985.3', id: 'AABR07005985.3'},
{value: 'aabr07006025.1', id: 'AABR07006025.1'},
{value: 'aabr07006025.3', id: 'AABR07006025.3'},
{value: 'aabr07006030.1', id: 'AABR07006030.1'},
{value: 'aabr07006032.1', id: 'AABR07006032.1'},
{value: 'aabr07006033.1', id: 'AABR07006033.1'},
{value: 'aabr07006038.1', id: 'AABR07006038.1'},
{value: 'aabr07006049.1', id: 'AABR07006049.1'},
{value: 'aabr07006076.1', id: 'AABR07006076.1'},
{value: 'aabr07006081.1', id: 'AABR07006081.1'},
{value: 'aabr07006090.1', id: 'AABR07006090.1'},
{value: 'aabr07006093.1', id: 'AABR07006093.1'},
{value: 'aabr07006097.1', id: 'AABR07006097.1'},
{value: 'aabr07006111.1', id: 'AABR07006111.1'},
{value: 'aabr07006120.1', id: 'AABR07006120.1'},
{value: 'aabr07006142.1', id: 'AABR07006142.1'},
{value: 'aabr07006160.1', id: 'AABR07006160.1'},
{value: 'aabr07006197.1', id: 'AABR07006197.1'},
{value: 'aabr07006232.1', id: 'AABR07006232.1'},
{value: 'aabr07006242.1', id: 'AABR07006242.1'},
{value: 'aabr07006255.1', id: 'AABR07006255.1'},
{value: 'aabr07006258.1', id: 'AABR07006258.1'},
{value: 'aabr07006258.2', id: 'AABR07006258.2'},
{value: 'aabr07006259.1', id: 'AABR07006259.1'},
{value: 'aabr07006264.1', id: 'AABR07006264.1'},
{value: 'aabr07006269.1', id: 'AABR07006269.1'},
{value: 'aabr07006275.1', id: 'AABR07006275.1'},
{value: 'aabr07006278.1', id: 'AABR07006278.1'},
{value: 'aabr07006278.2', id: 'AABR07006278.2'},
{value: 'aabr07006283.1', id: 'AABR07006283.1'},
{value: 'aabr07006292.1', id: 'AABR07006292.1'},
{value: 'aabr07006311.1', id: 'AABR07006311.1'},
{value: 'aabr07006327.1', id: 'AABR07006327.1'},
{value: 'aabr07006328.2', id: 'AABR07006328.2'},
{value: 'aabr07006333.1', id: 'AABR07006333.1'},
{value: 'aabr07006367.1', id: 'AABR07006367.1'},
{value: 'aabr07006389.1', id: 'AABR07006389.1'},
{value: 'aabr07006428.1', id: 'AABR07006428.1'},
{value: 'aabr07006436.1', id: 'AABR07006436.1'},
{value: 'aabr07006451.1', id: 'AABR07006451.1'},
{value: 'aabr07006451.2', id: 'AABR07006451.2'},
{value: 'aabr07006453.1', id: 'AABR07006453.1'},
{value: 'aabr07006458.1', id: 'AABR07006458.1'},
{value: 'aabr07006474.1', id: 'AABR07006474.1'},
{value: 'aabr07006475.1', id: 'AABR07006475.1'},
{value: 'aabr07006502.1', id: 'AABR07006502.1'},
{value: 'aabr07006504.1', id: 'AABR07006504.1'},
{value: 'aabr07006536.1', id: 'AABR07006536.1'},
{value: 'aabr07006536.2', id: 'AABR07006536.2'},
{value: 'aabr07006536.3', id: 'AABR07006536.3'},
{value: 'aabr07006538.1', id: 'AABR07006538.1'},
{value: 'aabr07006540.1', id: 'AABR07006540.1'},
{value: 'aabr07006542.1', id: 'AABR07006542.1'},
{value: 'aabr07006542.2', id: 'AABR07006542.2'},
{value: 'aabr07006544.1', id: 'AABR07006544.1'},
{value: 'aabr07006559.1', id: 'AABR07006559.1'},
{value: 'aabr07006566.1', id: 'AABR07006566.1'},
{value: 'aabr07006576.1', id: 'AABR07006576.1'},
{value: 'aabr07006585.1', id: 'AABR07006585.1'},
{value: 'aabr07006591.1', id: 'AABR07006591.1'},
{value: 'aabr07006625.1', id: 'AABR07006625.1'},
{value: 'aabr07006627.1', id: 'AABR07006627.1'},
{value: 'aabr07006627.2', id: 'AABR07006627.2'},
{value: 'aabr07006627.3', id: 'AABR07006627.3'},
{value: 'aabr07006649.1', id: 'AABR07006649.1'},
{value: 'aabr07006654.1', id: 'AABR07006654.1'},
{value: 'aabr07006654.2', id: 'AABR07006654.2'},
{value: 'aabr07006656.1', id: 'AABR07006656.1'},
{value: 'aabr07006656.2', id: 'AABR07006656.2'},
{value: 'aabr07006656.3', id: 'AABR07006656.3'},
{value: 'aabr07006657.1', id: 'AABR07006657.1'},
{value: 'aabr07006672.1', id: 'AABR07006672.1'},
{value: 'aabr07006673.1', id: 'AABR07006673.1'},
{value: 'aabr07006685.1', id: 'AABR07006685.1'},
{value: 'aabr07006688.1', id: 'AABR07006688.1'},
{value: 'aabr07006688.2', id: 'AABR07006688.2'},
{value: 'aabr07006689.1', id: 'AABR07006689.1'},
{value: 'aabr07006691.1', id: 'AABR07006691.1'},
{value: 'aabr07006693.1', id: 'AABR07006693.1'},
{value: 'aabr07006713.1', id: 'AABR07006713.1'},
{value: 'aabr07006718.1', id: 'AABR07006718.1'},
{value: 'aabr07006724.1', id: 'AABR07006724.1'},
{value: 'aabr07006727.1', id: 'AABR07006727.1'},
{value: 'aabr07006736.1', id: 'AABR07006736.1'},
{value: 'aabr07006740.1', id: 'AABR07006740.1'},
{value: 'aabr07006742.1', id: 'AABR07006742.1'},
{value: 'aabr07006752.1', id: 'AABR07006752.1'},
{value: 'aabr07006763.1', id: 'AABR07006763.1'},
{value: 'aabr07006774.1', id: 'AABR07006774.1'},
{value: 'aabr07006823.1', id: 'AABR07006823.1'},
{value: 'aabr07006854.1', id: 'AABR07006854.1'},
{value: 'aabr07006854.2', id: 'AABR07006854.2'},
{value: 'aabr07006860.1', id: 'AABR07006860.1'},
{value: 'aabr07006866.1', id: 'AABR07006866.1'},
{value: 'aabr07006871.1', id: 'AABR07006871.1'},
{value: 'aabr07006888.1', id: 'AABR07006888.1'},
{value: 'aabr07006889.1', id: 'AABR07006889.1'},
{value: 'aabr07006894.1', id: 'AABR07006894.1'},
{value: 'aabr07006904.1', id: 'AABR07006904.1'},
{value: 'aabr07006911.1', id: 'AABR07006911.1'},
{value: 'aabr07006953.1', id: 'AABR07006953.1'},
{value: 'aabr07006957.1', id: 'AABR07006957.1'},
{value: 'aabr07006978.1', id: 'AABR07006978.1'},
{value: 'aabr07006978.2', id: 'AABR07006978.2'},
{value: 'aabr07006993.1', id: 'AABR07006993.1'},
{value: 'aabr07006999.1', id: 'AABR07006999.1'},
{value: 'aabr07007017.1', id: 'AABR07007017.1'},
{value: 'aabr07007022.1', id: 'AABR07007022.1'},
{value: 'aabr07007023.1', id: 'AABR07007023.1'},
{value: 'aabr07007026.1', id: 'AABR07007026.1'},
{value: 'aabr07007032.1', id: 'AABR07007032.1'},
{value: 'aabr07007035.1', id: 'AABR07007035.1'},
{value: 'aabr07007055.1', id: 'AABR07007055.1'},
{value: 'aabr07007064.1', id: 'AABR07007064.1'},
{value: 'aabr07007065.1', id: 'AABR07007065.1'},
{value: 'aabr07007068.1', id: 'AABR07007068.1'},
{value: 'aabr07007078.1', id: 'AABR07007078.1'},
{value: 'aabr07007078.2', id: 'AABR07007078.2'},
{value: 'aabr07007086.1', id: 'AABR07007086.1'},
{value: 'aabr07007088.1', id: 'AABR07007088.1'},
{value: 'aabr07007088.2', id: 'AABR07007088.2'},
{value: 'aabr07007092.1', id: 'AABR07007092.1'},
{value: 'aabr07007093.1', id: 'AABR07007093.1'},
{value: 'aabr07007095.1', id: 'AABR07007095.1'},
{value: 'aabr07007121.1', id: 'AABR07007121.1'},
{value: 'aabr07007130.2', id: 'AABR07007130.2'},
{value: 'aabr07007134.1', id: 'AABR07007134.1'},
{value: 'aabr07007146.1', id: 'AABR07007146.1'},
{value: 'aabr07007362.1', id: 'AABR07007362.1'},
{value: 'aabr07007385.1', id: 'AABR07007385.1'},
{value: 'aabr07007391.1', id: 'AABR07007391.1'},
{value: 'aabr07007399.1', id: 'AABR07007399.1'},
{value: 'aabr07007409.1', id: 'AABR07007409.1'},
{value: 'aabr07007517.1', id: 'AABR07007517.1'},
{value: 'aabr07007544.1', id: 'AABR07007544.1'},
{value: 'aabr07007566.1', id: 'AABR07007566.1'},
{value: 'aabr07007584.1', id: 'AABR07007584.1'},
{value: 'aabr07007584.2', id: 'AABR07007584.2'},
{value: 'aabr07007584.3', id: 'AABR07007584.3'},
{value: 'aabr07007592.1', id: 'AABR07007592.1'},
{value: 'aabr07007642.1', id: 'AABR07007642.1'},
{value: 'aabr07007645.1', id: 'AABR07007645.1'},
{value: 'aabr07007663.1', id: 'AABR07007663.1'},
{value: 'aabr07007675.1', id: 'AABR07007675.1'},
{value: 'aabr07007675.2', id: 'AABR07007675.2'},
{value: 'aabr07007676.1', id: 'AABR07007676.1'},
{value: 'aabr07007689.1', id: 'AABR07007689.1'},
{value: 'aabr07007690.1', id: 'AABR07007690.1'},
{value: 'aabr07007693.1', id: 'AABR07007693.1'},
{value: 'aabr07007710.1', id: 'AABR07007710.1'},
{value: 'aabr07007715.1', id: 'AABR07007715.1'},
{value: 'aabr07007717.1', id: 'AABR07007717.1'},
{value: 'aabr07007717.2', id: 'AABR07007717.2'},
{value: 'aabr07007717.3', id: 'AABR07007717.3'},
{value: 'aabr07007723.1', id: 'AABR07007723.1'},
{value: 'aabr07007730.1', id: 'AABR07007730.1'},
{value: 'aabr07007743.1', id: 'AABR07007743.1'},
{value: 'aabr07007744.1', id: 'AABR07007744.1'},
{value: 'aabr07007744.2', id: 'AABR07007744.2'},
{value: 'aabr07007745.1', id: 'AABR07007745.1'},
{value: 'aabr07007758.1', id: 'AABR07007758.1'},
{value: 'aabr07007765.1', id: 'AABR07007765.1'},
{value: 'aabr07007769.1', id: 'AABR07007769.1'},
{value: 'aabr07007775.1', id: 'AABR07007775.1'},
{value: 'aabr07007789.1', id: 'AABR07007789.1'},
{value: 'aabr07007793.1', id: 'AABR07007793.1'},
{value: 'aabr07007793.2', id: 'AABR07007793.2'},
{value: 'aabr07007798.1', id: 'AABR07007798.1'},
{value: 'aabr07007799.1', id: 'AABR07007799.1'},
{value: 'aabr07007802.1', id: 'AABR07007802.1'},
{value: 'aabr07007833.1', id: 'AABR07007833.1'},
{value: 'aabr07007833.2', id: 'AABR07007833.2'},
{value: 'aabr07007837.1', id: 'AABR07007837.1'},
{value: 'aabr07007839.1', id: 'AABR07007839.1'},
{value: 'aabr07007841.1', id: 'AABR07007841.1'},
{value: 'aabr07007853.1', id: 'AABR07007853.1'},
{value: 'aabr07007853.2', id: 'AABR07007853.2'},
{value: 'aabr07007863.1', id: 'AABR07007863.1'},
{value: 'aabr07007874.1', id: 'AABR07007874.1'},
{value: 'aabr07007875.1', id: 'AABR07007875.1'},
{value: 'aabr07007878.1', id: 'AABR07007878.1'},
{value: 'aabr07007879.1', id: 'AABR07007879.1'},
{value: 'aabr07007883.1', id: 'AABR07007883.1'},
{value: 'aabr07007896.1', id: 'AABR07007896.1'},
{value: 'aabr07007905.1', id: 'AABR07007905.1'},
{value: 'aabr07007928.1', id: 'AABR07007928.1'},
{value: 'aabr07007931.1', id: 'AABR07007931.1'},
{value: 'aabr07007942.1', id: 'AABR07007942.1'},
{value: 'aabr07007978.1', id: 'AABR07007978.1'},
{value: 'aabr07007980.1', id: 'AABR07007980.1'},
{value: 'aabr07007980.2', id: 'AABR07007980.2'},
{value: 'aabr07007987.1', id: 'AABR07007987.1'},
{value: 'aabr07007996.1', id: 'AABR07007996.1'},
{value: 'aabr07007997.1', id: 'AABR07007997.1'},
{value: 'aabr07008013.1', id: 'AABR07008013.1'},
{value: 'aabr07008014.1', id: 'AABR07008014.1'},
{value: 'aabr07008016.1', id: 'AABR07008016.1'},
{value: 'aabr07008025.1', id: 'AABR07008025.1'},
{value: 'aabr07008030.1', id: 'AABR07008030.1'},
{value: 'aabr07008066.1', id: 'AABR07008066.1'},
{value: 'aabr07008066.2', id: 'AABR07008066.2'},
{value: 'aabr07008074.1', id: 'AABR07008074.1'},
{value: 'aabr07008099.1', id: 'AABR07008099.1'},
{value: 'aabr07008099.2', id: 'AABR07008099.2'},
{value: 'aabr07008103.1', id: 'AABR07008103.1'},
{value: 'aabr07008107.1', id: 'AABR07008107.1'},
{value: 'aabr07008108.1', id: 'AABR07008108.1'},
{value: 'aabr07008118.1', id: 'AABR07008118.1'},
{value: 'aabr07008123.1', id: 'AABR07008123.1'},
{value: 'aabr07008126.1', id: 'AABR07008126.1'},
{value: 'aabr07008142.1', id: 'AABR07008142.1'},
{value: 'aabr07008142.2', id: 'AABR07008142.2'},
{value: 'aabr07008152.1', id: 'AABR07008152.1'},
{value: 'aabr07008242.1', id: 'AABR07008242.1'},
{value: 'aabr07008255.1', id: 'AABR07008255.1'},
{value: 'aabr07008256.1', id: 'AABR07008256.1'},
{value: 'aabr07008256.2', id: 'AABR07008256.2'},
{value: 'aabr07008259.1', id: 'AABR07008259.1'},
{value: 'aabr07008272.1', id: 'AABR07008272.1'},
{value: 'aabr07008285.1', id: 'AABR07008285.1'},
{value: 'aabr07008287.1', id: 'AABR07008287.1'},
{value: 'aabr07008287.2', id: 'AABR07008287.2'},
{value: 'aabr07008293.1', id: 'AABR07008293.1'},
{value: 'aabr07008293.2', id: 'AABR07008293.2'},
{value: 'aabr07008293.3', id: 'AABR07008293.3'},
{value: 'aabr07008293.4', id: 'AABR07008293.4'},
{value: 'aabr07008298.1', id: 'AABR07008298.1'},
{value: 'aabr07008298.2', id: 'AABR07008298.2'},
{value: 'aabr07008300.1', id: 'AABR07008300.1'},
{value: 'aabr07008309.1', id: 'AABR07008309.1'},
{value: 'aabr07008319.1', id: 'AABR07008319.1'},
{value: 'aabr07008337.1', id: 'AABR07008337.1'},
{value: 'aabr07008341.1', id: 'AABR07008341.1'},
{value: 'aabr07008350.1', id: 'AABR07008350.1'},
{value: 'aabr07008361.1', id: 'AABR07008361.1'},
{value: 'aabr07008370.1', id: 'AABR07008370.1'},
{value: 'aabr07008372.1', id: 'AABR07008372.1'},
{value: 'aabr07008386.1', id: 'AABR07008386.1'},
{value: 'aabr07008390.1', id: 'AABR07008390.1'},
{value: 'aabr07008399.1', id: 'AABR07008399.1'},
{value: 'aabr07008420.1', id: 'AABR07008420.1'},
{value: 'aabr07008421.1', id: 'AABR07008421.1'},
{value: 'aabr07008424.1', id: 'AABR07008424.1'},
{value: 'aabr07008440.1', id: 'AABR07008440.1'},
{value: 'aabr07008485.1', id: 'AABR07008485.1'},
{value: 'aabr07008489.1', id: 'AABR07008489.1'},
{value: 'aabr07008491.1', id: 'AABR07008491.1'},
{value: 'aabr07008530.1', id: 'AABR07008530.1'},
{value: 'aabr07008568.1', id: 'AABR07008568.1'},
{value: 'aabr07008594.1', id: 'AABR07008594.1'},
{value: 'aabr07008597.1', id: 'AABR07008597.1'},
{value: 'aabr07008608.1', id: 'AABR07008608.1'},
{value: 'aabr07008655.1', id: 'AABR07008655.1'},
{value: 'aabr07008661.1', id: 'AABR07008661.1'},
{value: 'aabr07008670.1', id: 'AABR07008670.1'},
{value: 'aabr07008681.1', id: 'AABR07008681.1'},
{value: 'aabr07008715.1', id: 'AABR07008715.1'},
{value: 'aabr07008724.1', id: 'AABR07008724.1'},
{value: 'aabr07008724.2', id: 'AABR07008724.2'},
{value: 'aabr07008756.1', id: 'AABR07008756.1'},
{value: 'aabr07008788.1', id: 'AABR07008788.1'},
{value: 'aabr07008805.1', id: 'AABR07008805.1'},
{value: 'aabr07008844.1', id: 'AABR07008844.1'},
{value: 'aabr07008878.1', id: 'AABR07008878.1'},
{value: 'aabr07008890.1', id: 'AABR07008890.1'},
{value: 'aabr07008898.1', id: 'AABR07008898.1'},
{value: 'aabr07008904.1', id: 'AABR07008904.1'},
{value: 'aabr07008911.1', id: 'AABR07008911.1'},
{value: 'aabr07008916.1', id: 'AABR07008916.1'},
{value: 'aabr07008940.1', id: 'AABR07008940.1'},
{value: 'aabr07008948.1', id: 'AABR07008948.1'},
{value: 'aabr07008970.1', id: 'AABR07008970.1'},
{value: 'aabr07008991.1', id: 'AABR07008991.1'},
{value: 'aabr07009002.1', id: 'AABR07009002.1'},
{value: 'aabr07009013.1', id: 'AABR07009013.1'},
{value: 'aabr07009028.1', id: 'AABR07009028.1'},
{value: 'aabr07009031.2', id: 'AABR07009031.2'},
{value: 'aabr07009034.1', id: 'AABR07009034.1'},
{value: 'aabr07009039.1', id: 'AABR07009039.1'},
{value: 'aabr07009052.1', id: 'AABR07009052.1'},
{value: 'aabr07009105.1', id: 'AABR07009105.1'},
{value: 'aabr07009154.1', id: 'AABR07009154.1'},
{value: 'aabr07009161.1', id: 'AABR07009161.1'},
{value: 'aabr07009218.1', id: 'AABR07009218.1'},
{value: 'aabr07009221.1', id: 'AABR07009221.1'},
{value: 'aabr07009224.1', id: 'AABR07009224.1'},
{value: 'aabr07009260.1', id: 'AABR07009260.1'},
{value: 'aabr07009260.2', id: 'AABR07009260.2'},
{value: 'aabr07009325.1', id: 'AABR07009325.1'},
{value: 'aabr07009338.1', id: 'AABR07009338.1'},
{value: 'aabr07009351.1', id: 'AABR07009351.1'},
{value: 'aabr07009357.1', id: 'AABR07009357.1'},
{value: 'aabr07009357.2', id: 'AABR07009357.2'},
{value: 'aabr07009373.1', id: 'AABR07009373.1'},
{value: 'aabr07009373.2', id: 'AABR07009373.2'},
{value: 'aabr07009377.1', id: 'AABR07009377.1'},
{value: 'aabr07009379.1', id: 'AABR07009379.1'},
{value: 'aabr07009384.1', id: 'AABR07009384.1'},
{value: 'aabr07009395.1', id: 'AABR07009395.1'},
{value: 'aabr07009402.1', id: 'AABR07009402.1'},
{value: 'aabr07009419.1', id: 'AABR07009419.1'},
{value: 'aabr07009428.1', id: 'AABR07009428.1'},
{value: 'aabr07009457.1', id: 'AABR07009457.1'},
{value: 'aabr07009482.1', id: 'AABR07009482.1'},
{value: 'aabr07009521.1', id: 'AABR07009521.1'},
{value: 'aabr07009532.1', id: 'AABR07009532.1'},
{value: 'aabr07009533.1', id: 'AABR07009533.1'},
{value: 'aabr07009536.1', id: 'AABR07009536.1'},
{value: 'aabr07009538.1', id: 'AABR07009538.1'},
{value: 'aabr07009542.1', id: 'AABR07009542.1'},
{value: 'aabr07009572.1', id: 'AABR07009572.1'},
{value: 'aabr07009599.1', id: 'AABR07009599.1'},
{value: 'aabr07009599.2', id: 'AABR07009599.2'},
{value: 'aabr07009600.1', id: 'AABR07009600.1'},
{value: 'aabr07009600.2', id: 'AABR07009600.2'},
{value: 'aabr07009634.1', id: 'AABR07009634.1'},
{value: 'aabr07009634.2', id: 'AABR07009634.2'},
{value: 'aabr07009638.1', id: 'AABR07009638.1'},
{value: 'aabr07009638.2', id: 'AABR07009638.2'},
{value: 'aabr07009639.1', id: 'AABR07009639.1'},
{value: 'aabr07009669.1', id: 'AABR07009669.1'},
{value: 'aabr07009715.1', id: 'AABR07009715.1'},
{value: 'aabr07009743.1', id: 'AABR07009743.1'},
{value: 'aabr07009770.1', id: 'AABR07009770.1'},
{value: 'aabr07009775.1', id: 'AABR07009775.1'},
{value: 'aabr07009800.1', id: 'AABR07009800.1'},
{value: 'aabr07009824.1', id: 'AABR07009824.1'},
{value: 'aabr07009834.1', id: 'AABR07009834.1'},
{value: 'aabr07009910.1', id: 'AABR07009910.1'},
{value: 'aabr07009919.1', id: 'AABR07009919.1'},
{value: 'aabr07009927.1', id: 'AABR07009927.1'},
{value: 'aabr07009931.1', id: 'AABR07009931.1'},
{value: 'aabr07009964.1', id: 'AABR07009964.1'},
{value: 'aabr07009965.1', id: 'AABR07009965.1'},
{value: 'aabr07009978.1', id: 'AABR07009978.1'},
{value: 'aabr07009984.1', id: 'AABR07009984.1'},
{value: 'aabr07009986.1', id: 'AABR07009986.1'},
{value: 'aabr07009991.1', id: 'AABR07009991.1'},
{value: 'aabr07010022.1', id: 'AABR07010022.1'},
{value: 'aabr07010022.2', id: 'AABR07010022.2'},
{value: 'aabr07010030.1', id: 'AABR07010030.1'},
{value: 'aabr07010030.2', id: 'AABR07010030.2'},
{value: 'aabr07010031.1', id: 'AABR07010031.1'},
{value: 'aabr07010033.1', id: 'AABR07010033.1'},
{value: 'aabr07010033.2', id: 'AABR07010033.2'},
{value: 'aabr07010040.1', id: 'AABR07010040.1'},
{value: 'aabr07010040.2', id: 'AABR07010040.2'},
{value: 'aabr07010040.3', id: 'AABR07010040.3'},
{value: 'aabr07010041.1', id: 'AABR07010041.1'},
{value: 'aabr07010061.1', id: 'AABR07010061.1'},
{value: 'aabr07010085.1', id: 'AABR07010085.1'},
{value: 'aabr07010085.2', id: 'AABR07010085.2'},
{value: 'aabr07010107.1', id: 'AABR07010107.1'},
{value: 'aabr07010107.2', id: 'AABR07010107.2'},
{value: 'aabr07010118.1', id: 'AABR07010118.1'},
{value: 'aabr07010121.1', id: 'AABR07010121.1'},
{value: 'aabr07010136.1', id: 'AABR07010136.1'},
{value: 'aabr07010150.1', id: 'AABR07010150.1'},
{value: 'aabr07010153.1', id: 'AABR07010153.1'},
{value: 'aabr07010155.1', id: 'AABR07010155.1'},
{value: 'aabr07010160.1', id: 'AABR07010160.1'},
{value: 'aabr07010180.1', id: 'AABR07010180.1'},
{value: 'aabr07010181.1', id: 'AABR07010181.1'},
{value: 'aabr07010183.1', id: 'AABR07010183.1'},
{value: 'aabr07010231.1', id: 'AABR07010231.1'},
{value: 'aabr07010302.1', id: 'AABR07010302.1'},
{value: 'aabr07010306.1', id: 'AABR07010306.1'},
{value: 'aabr07010330.1', id: 'AABR07010330.1'},
{value: 'aabr07010330.2', id: 'AABR07010330.2'},
{value: 'aabr07010332.1', id: 'AABR07010332.1'},
{value: 'aabr07010333.1', id: 'AABR07010333.1'},
{value: 'aabr07010342.1', id: 'AABR07010342.1'},
{value: 'aabr07010358.1', id: 'AABR07010358.1'},
{value: 'aabr07010370.1', id: 'AABR07010370.1'},
{value: 'aabr07010385.1', id: 'AABR07010385.1'},
{value: 'aabr07010421.1', id: 'AABR07010421.1'},
{value: 'aabr07010468.1', id: 'AABR07010468.1'},
{value: 'aabr07010500.1', id: 'AABR07010500.1'},
{value: 'aabr07010502.1', id: 'AABR07010502.1'},
{value: 'aabr07010524.1', id: 'AABR07010524.1'},
{value: 'aabr07010550.1', id: 'AABR07010550.1'},
{value: 'aabr07010550.2', id: 'AABR07010550.2'},
{value: 'aabr07010554.1', id: 'AABR07010554.1'},
{value: 'aabr07010563.1', id: 'AABR07010563.1'},
{value: 'aabr07010570.1', id: 'AABR07010570.1'},
{value: 'aabr07010575.1', id: 'AABR07010575.1'},
{value: 'aabr07010583.1', id: 'AABR07010583.1'},
{value: 'aabr07010585.1', id: 'AABR07010585.1'},
{value: 'aabr07010587.1', id: 'AABR07010587.1'},
{value: 'aabr07010589.1', id: 'AABR07010589.1'},
{value: 'aabr07010592.1', id: 'AABR07010592.1'},
{value: 'aabr07010592.2', id: 'AABR07010592.2'},
{value: 'aabr07010601.1', id: 'AABR07010601.1'},
{value: 'aabr07010601.2', id: 'AABR07010601.2'},
{value: 'aabr07010609.1', id: 'AABR07010609.1'},
{value: 'aabr07010609.2', id: 'AABR07010609.2'},
{value: 'aabr07010620.1', id: 'AABR07010620.1'},
{value: 'aabr07010633.1', id: 'AABR07010633.1'},
{value: 'aabr07010640.1', id: 'AABR07010640.1'},
{value: 'aabr07010649.2', id: 'AABR07010649.2'},
{value: 'aabr07010660.1', id: 'AABR07010660.1'},
{value: 'aabr07010672.1', id: 'AABR07010672.1'},
{value: 'aabr07010675.1', id: 'AABR07010675.1'},
{value: 'aabr07010682.1', id: 'AABR07010682.1'},
{value: 'aabr07010705.1', id: 'AABR07010705.1'},
{value: 'aabr07010706.1', id: 'AABR07010706.1'},
{value: 'aabr07010710.1', id: 'AABR07010710.1'},
{value: 'aabr07010717.1', id: 'AABR07010717.1'},
{value: 'aabr07010718.1', id: 'AABR07010718.1'},
{value: 'aabr07010747.1', id: 'AABR07010747.1'},
{value: 'aabr07010760.1', id: 'AABR07010760.1'},
{value: 'aabr07010763.1', id: 'AABR07010763.1'},
{value: 'aabr07010763.2', id: 'AABR07010763.2'},
{value: 'aabr07010777.1', id: 'AABR07010777.1'},
{value: 'aabr07010787.1', id: 'AABR07010787.1'},
{value: 'aabr07010840.1', id: 'AABR07010840.1'},
{value: 'aabr07010862.1', id: 'AABR07010862.1'},
{value: 'aabr07010873.1', id: 'AABR07010873.1'},
{value: 'aabr07010878.1', id: 'AABR07010878.1'},
{value: 'aabr07010894.1', id: 'AABR07010894.1'},
{value: 'aabr07010907.1', id: 'AABR07010907.1'},
{value: 'aabr07010919.1', id: 'AABR07010919.1'},
{value: 'aabr07010935.1', id: 'AABR07010935.1'},
{value: 'aabr07010940.1', id: 'AABR07010940.1'},
{value: 'aabr07010940.2', id: 'AABR07010940.2'},
{value: 'aabr07010944.1', id: 'AABR07010944.1'},
{value: 'aabr07010985.1', id: 'AABR07010985.1'},
{value: 'aabr07010986.1', id: 'AABR07010986.1'},
{value: 'aabr07011013.1', id: 'AABR07011013.1'},
{value: 'aabr07011031.1', id: 'AABR07011031.1'},
{value: 'aabr07011057.1', id: 'AABR07011057.1'},
{value: 'aabr07011057.2', id: 'AABR07011057.2'},
{value: 'aabr07011062.1', id: 'AABR07011062.1'},
{value: 'aabr07011084.1', id: 'AABR07011084.1'},
{value: 'aabr07011084.2', id: 'AABR07011084.2'},
{value: 'aabr07011085.1', id: 'AABR07011085.1'},
{value: 'aabr07011088.1', id: 'AABR07011088.1'},
{value: 'aabr07011090.1', id: 'AABR07011090.1'},
{value: 'aabr07011117.1', id: 'AABR07011117.1'},
{value: 'aabr07011127.1', id: 'AABR07011127.1'},
{value: 'aabr07011145.1', id: 'AABR07011145.1'},
{value: 'aabr07011152.1', id: 'AABR07011152.1'},
{value: 'aabr07011278.1', id: 'AABR07011278.1'},
{value: 'aabr07011434.1', id: 'AABR07011434.1'},
{value: 'aabr07011500.1', id: 'AABR07011500.1'},
{value: 'aabr07011512.1', id: 'AABR07011512.1'},
{value: 'aabr07011545.1', id: 'AABR07011545.1'},
{value: 'aabr07011624.1', id: 'AABR07011624.1'},
{value: 'aabr07011629.1', id: 'AABR07011629.1'},
{value: 'aabr07011672.1', id: 'AABR07011672.1'},
{value: 'aabr07011679.1', id: 'AABR07011679.1'},
{value: 'aabr07011682.1', id: 'AABR07011682.1'},
{value: 'aabr07011697.1', id: 'AABR07011697.1'},
{value: 'aabr07011698.1', id: 'AABR07011698.1'},
{value: 'aabr07011700.1', id: 'AABR07011700.1'},
{value: 'aabr07011705.1', id: 'AABR07011705.1'},
{value: 'aabr07011708.1', id: 'AABR07011708.1'},
{value: 'aabr07011712.1', id: 'AABR07011712.1'},
{value: 'aabr07011733.1', id: 'AABR07011733.1'},
{value: 'aabr07011744.1', id: 'AABR07011744.1'},
{value: 'aabr07011746.1', id: 'AABR07011746.1'},
{value: 'aabr07011808.1', id: 'AABR07011808.1'},
{value: 'aabr07011841.1', id: 'AABR07011841.1'},
{value: 'aabr07011857.1', id: 'AABR07011857.1'},
{value: 'aabr07011857.2', id: 'AABR07011857.2'},
{value: 'aabr07011861.1', id: 'AABR07011861.1'},
{value: 'aabr07011864.1', id: 'AABR07011864.1'},
{value: 'aabr07011888.1', id: 'AABR07011888.1'},
{value: 'aabr07011908.1', id: 'AABR07011908.1'},
{value: 'aabr07011912.1', id: 'AABR07011912.1'},
{value: 'aabr07011934.1', id: 'AABR07011934.1'},
{value: 'aabr07011945.1', id: 'AABR07011945.1'},
{value: 'aabr07011951.1', id: 'AABR07011951.1'},
{value: 'aabr07011954.1', id: 'AABR07011954.1'},
{value: 'aabr07011956.1', id: 'AABR07011956.1'},
{value: 'aabr07011977.1', id: 'AABR07011977.1'},
{value: 'aabr07011991.1', id: 'AABR07011991.1'},
{value: 'aabr07011994.1', id: 'AABR07011994.1'},
{value: 'aabr07011996.1', id: 'AABR07011996.1'},
{value: 'aabr07011997.1', id: 'AABR07011997.1'},
{value: 'aabr07012012.1', id: 'AABR07012012.1'},
{value: 'aabr07012023.1', id: 'AABR07012023.1'},
{value: 'aabr07012030.1', id: 'AABR07012030.1'},
{value: 'aabr07012039.1', id: 'AABR07012039.1'},
{value: 'aabr07012040.1', id: 'AABR07012040.1'},
{value: 'aabr07012044.1', id: 'AABR07012044.1'},
{value: 'aabr07012051.1', id: 'AABR07012051.1'},
{value: 'aabr07012051.2', id: 'AABR07012051.2'},
{value: 'aabr07012053.1', id: 'AABR07012053.1'},
{value: 'aabr07012053.2', id: 'AABR07012053.2'},
{value: 'aabr07012054.1', id: 'AABR07012054.1'},
{value: 'aabr07012058.1', id: 'AABR07012058.1'},
{value: 'aabr07012058.2', id: 'AABR07012058.2'},
{value: 'aabr07012061.1', id: 'AABR07012061.1'},
{value: 'aabr07012061.2', id: 'AABR07012061.2'},
{value: 'aabr07012065.1', id: 'AABR07012065.1'},
{value: 'aabr07012065.2', id: 'AABR07012065.2'},
{value: 'aabr07012065.3', id: 'AABR07012065.3'},
{value: 'aabr07012072.1', id: 'AABR07012072.1'},
{value: 'aabr07012072.2', id: 'AABR07012072.2'},
{value: 'aabr07012081.1', id: 'AABR07012081.1'},
{value: 'aabr07012092.1', id: 'AABR07012092.1'},
{value: 'aabr07012092.2', id: 'AABR07012092.2'},
{value: 'aabr07012095.2', id: 'AABR07012095.2'},
{value: 'aabr07012097.1', id: 'AABR07012097.1'},
{value: 'aabr07012100.1', id: 'AABR07012100.1'},
{value: 'aabr07012100.2', id: 'AABR07012100.2'},
{value: 'aabr07012111.1', id: 'AABR07012111.1'},
{value: 'aabr07012115.1', id: 'AABR07012115.1'},
{value: 'aabr07012128.1', id: 'AABR07012128.1'},
{value: 'aabr07012131.1', id: 'AABR07012131.1'},
{value: 'aabr07012133.1', id: 'AABR07012133.1'},
{value: 'aabr07012133.2', id: 'AABR07012133.2'},
{value: 'aabr07012174.1', id: 'AABR07012174.1'},
{value: 'aabr07012195.1', id: 'AABR07012195.1'},
{value: 'aabr07012269.1', id: 'AABR07012269.1'},
{value: 'aabr07012276.1', id: 'AABR07012276.1'},
{value: 'aabr07012291.1', id: 'AABR07012291.1'},
{value: 'aabr07012291.2', id: 'AABR07012291.2'},
{value: 'aabr07012299.1', id: 'AABR07012299.1'},
{value: 'aabr07012302.1', id: 'AABR07012302.1'},
{value: 'aabr07012310.1', id: 'AABR07012310.1'},
{value: 'aabr07012312.1', id: 'AABR07012312.1'},
{value: 'aabr07012313.1', id: 'AABR07012313.1'},
{value: 'aabr07012314.1', id: 'AABR07012314.1'},
{value: 'aabr07012317.1', id: 'AABR07012317.1'},
{value: 'aabr07012317.2', id: 'AABR07012317.2'},
{value: 'aabr07012318.1', id: 'AABR07012318.1'},
{value: 'aabr07012323.1', id: 'AABR07012323.1'},
{value: 'aabr07012329.1', id: 'AABR07012329.1'},
{value: 'aabr07012384.1', id: 'AABR07012384.1'},
{value: 'aabr07012426.1', id: 'AABR07012426.1'},
{value: 'aabr07012475.1', id: 'AABR07012475.1'},
{value: 'aabr07012492.1', id: 'AABR07012492.1'},
{value: 'aabr07012575.1', id: 'AABR07012575.1'},
{value: 'aabr07012582.1', id: 'AABR07012582.1'},
{value: 'aabr07012582.2', id: 'AABR07012582.2'},
{value: 'aabr07012583.1', id: 'AABR07012583.1'},
{value: 'aabr07012583.2', id: 'AABR07012583.2'},
{value: 'aabr07012587.1', id: 'AABR07012587.1'},
{value: 'aabr07012588.1', id: 'AABR07012588.1'},
{value: 'aabr07012592.1', id: 'AABR07012592.1'},
{value: 'aabr07012613.1', id: 'AABR07012613.1'},
{value: 'aabr07012616.1', id: 'AABR07012616.1'},
{value: 'aabr07012635.1', id: 'AABR07012635.1'},
{value: 'aabr07012640.1', id: 'AABR07012640.1'},
{value: 'aabr07012640.2', id: 'AABR07012640.2'},
{value: 'aabr07012658.1', id: 'AABR07012658.1'},
{value: 'aabr07012702.1', id: 'AABR07012702.1'},
{value: 'aabr07012705.1', id: 'AABR07012705.1'},
{value: 'aabr07012711.1', id: 'AABR07012711.1'},
{value: 'aabr07012728.2', id: 'AABR07012728.2'},
{value: 'aabr07012752.1', id: 'AABR07012752.1'},
{value: 'aabr07012754.1', id: 'AABR07012754.1'},
{value: 'aabr07012757.1', id: 'AABR07012757.1'},
{value: 'aabr07012762.1', id: 'AABR07012762.1'},
{value: 'aabr07012762.2', id: 'AABR07012762.2'},
{value: 'aabr07012763.1', id: 'AABR07012763.1'},
{value: 'aabr07012774.1', id: 'AABR07012774.1'},
{value: 'aabr07012774.2', id: 'AABR07012774.2'},
{value: 'aabr07012775.1', id: 'AABR07012775.1'},
{value: 'aabr07012777.1', id: 'AABR07012777.1'},
{value: 'aabr07012779.1', id: 'AABR07012779.1'},
{value: 'aabr07012785.1', id: 'AABR07012785.1'},
{value: 'aabr07012785.2', id: 'AABR07012785.2'},
{value: 'aabr07012786.1', id: 'AABR07012786.1'},
{value: 'aabr07012788.1', id: 'AABR07012788.1'},
{value: 'aabr07012795.1', id: 'AABR07012795.1'},
{value: 'aabr07012826.1', id: 'AABR07012826.1'},
{value: 'aabr07012843.1', id: 'AABR07012843.1'},
{value: 'aabr07012856.1', id: 'AABR07012856.1'},
{value: 'aabr07012868.1', id: 'AABR07012868.1'},
{value: 'aabr07012884.1', id: 'AABR07012884.1'},
{value: 'aabr07012926.1', id: 'AABR07012926.1'},
{value: 'aabr07012943.1', id: 'AABR07012943.1'},
{value: 'aabr07012991.1', id: 'AABR07012991.1'},
{value: 'aabr07012994.1', id: 'AABR07012994.1'},
{value: 'aabr07013007.1', id: 'AABR07013007.1'},
{value: 'aabr07013019.1', id: 'AABR07013019.1'},
{value: 'aabr07013023.1', id: 'AABR07013023.1'},
{value: 'aabr07013057.1', id: 'AABR07013057.1'},
{value: 'aabr07013065.1', id: 'AABR07013065.1'},
{value: 'aabr07013070.1', id: 'AABR07013070.1'},
{value: 'aabr07013073.1', id: 'AABR07013073.1'},
{value: 'aabr07013073.2', id: 'AABR07013073.2'},
{value: 'aabr07013073.3', id: 'AABR07013073.3'},
{value: 'aabr07013073.4', id: 'AABR07013073.4'},
{value: 'aabr07013085.1', id: 'AABR07013085.1'},
{value: 'aabr07013086.1', id: 'AABR07013086.1'},
{value: 'aabr07013095.1', id: 'AABR07013095.1'},
{value: 'aabr07013111.1', id: 'AABR07013111.1'},
{value: 'aabr07013116.1', id: 'AABR07013116.1'},
{value: 'aabr07013123.1', id: 'AABR07013123.1'},
{value: 'aabr07013135.1', id: 'AABR07013135.1'},
{value: 'aabr07013140.1', id: 'AABR07013140.1'},
{value: 'aabr07013147.1', id: 'AABR07013147.1'},
{value: 'aabr07013154.2', id: 'AABR07013154.2'},
{value: 'aabr07013157.1', id: 'AABR07013157.1'},
{value: 'aabr07013157.2', id: 'AABR07013157.2'},
{value: 'aabr07013165.1', id: 'AABR07013165.1'},
{value: 'aabr07013167.1', id: 'AABR07013167.1'},
{value: 'aabr07013200.1', id: 'AABR07013200.1'},
{value: 'aabr07013202.1', id: 'AABR07013202.1'},
{value: 'aabr07013207.1', id: 'AABR07013207.1'},
{value: 'aabr07013208.1', id: 'AABR07013208.1'},
{value: 'aabr07013217.1', id: 'AABR07013217.1'},
{value: 'aabr07013237.1', id: 'AABR07013237.1'},
{value: 'aabr07013255.1', id: 'AABR07013255.1'},
{value: 'aabr07013288.2', id: 'AABR07013288.2'},
{value: 'aabr07013288.4', id: 'AABR07013288.4'},
{value: 'aabr07013302.1', id: 'AABR07013302.1'},
{value: 'aabr07013304.1', id: 'AABR07013304.1'},
{value: 'aabr07013314.1', id: 'AABR07013314.1'},
{value: 'aabr07013323.1', id: 'AABR07013323.1'},
{value: 'aabr07013324.1', id: 'AABR07013324.1'},
{value: 'aabr07013365.1', id: 'AABR07013365.1'},
{value: 'aabr07013388.1', id: 'AABR07013388.1'},
{value: 'aabr07013410.1', id: 'AABR07013410.1'},
{value: 'aabr07013410.2', id: 'AABR07013410.2'},
{value: 'aabr07013410.3', id: 'AABR07013410.3'},
{value: 'aabr07013410.4', id: 'AABR07013410.4'},
{value: 'aabr07013412.1', id: 'AABR07013412.1'},
{value: 'aabr07013425.1', id: 'AABR07013425.1'},
{value: 'aabr07013435.1', id: 'AABR07013435.1'},
{value: 'aabr07013439.1', id: 'AABR07013439.1'},
{value: 'aabr07013453.1', id: 'AABR07013453.1'},
{value: 'aabr07013477.1', id: 'AABR07013477.1'},
{value: 'aabr07013488.2', id: 'AABR07013488.2'},
{value: 'aabr07013539.1', id: 'AABR07013539.1'},
{value: 'aabr07013550.1', id: 'AABR07013550.1'},
{value: 'aabr07013558.1', id: 'AABR07013558.1'},
{value: 'aabr07013566.1', id: 'AABR07013566.1'},
{value: 'aabr07013583.1', id: 'AABR07013583.1'},
{value: 'aabr07013586.1', id: 'AABR07013586.1'},
{value: 'aabr07013591.1', id: 'AABR07013591.1'},
{value: 'aabr07013639.1', id: 'AABR07013639.1'},
{value: 'aabr07013653.1', id: 'AABR07013653.1'},
{value: 'aabr07013654.1', id: 'AABR07013654.1'},
{value: 'aabr07013672.1', id: 'AABR07013672.1'},
{value: 'aabr07013676.1', id: 'AABR07013676.1'},
{value: 'aabr07013676.2', id: 'AABR07013676.2'},
{value: 'aabr07013686.1', id: 'AABR07013686.1'},
{value: 'aabr07013689.1', id: 'AABR07013689.1'},
{value: 'aabr07013698.1', id: 'AABR07013698.1'},
{value: 'aabr07013701.1', id: 'AABR07013701.1'},
{value: 'aabr07013718.1', id: 'AABR07013718.1'},
{value: 'aabr07013724.1', id: 'AABR07013724.1'},
{value: 'aabr07013743.1', id: 'AABR07013743.1'},
{value: 'aabr07013746.1', id: 'AABR07013746.1'},
{value: 'aabr07013747.1', id: 'AABR07013747.1'},
{value: 'aabr07013747.2', id: 'AABR07013747.2'},
{value: 'aabr07013750.1', id: 'AABR07013750.1'},
{value: 'aabr07013762.1', id: 'AABR07013762.1'},
{value: 'aabr07013762.2', id: 'AABR07013762.2'},
{value: 'aabr07013764.1', id: 'AABR07013764.1'},
{value: 'aabr07013764.2', id: 'AABR07013764.2'},
{value: 'aabr07013764.3', id: 'AABR07013764.3'},
{value: 'aabr07013776.1', id: 'AABR07013776.1'},
{value: 'aabr07013784.1', id: 'AABR07013784.1'},
{value: 'aabr07013788.1', id: 'AABR07013788.1'},
{value: 'aabr07013798.1', id: 'AABR07013798.1'},
{value: 'aabr07013801.1', id: 'AABR07013801.1'},
{value: 'aabr07013802.1', id: 'AABR07013802.1'},
{value: 'aabr07013841.1', id: 'AABR07013841.1'},
{value: 'aabr07013843.1', id: 'AABR07013843.1'},
{value: 'aabr07013856.1', id: 'AABR07013856.1'},
{value: 'aabr07013859.1', id: 'AABR07013859.1'},
{value: 'aabr07013907.1', id: 'AABR07013907.1'},
{value: 'aabr07013911.1', id: 'AABR07013911.1'},
{value: 'aabr07013914.1', id: 'AABR07013914.1'},
{value: 'aabr07013922.1', id: 'AABR07013922.1'},
{value: 'aabr07013931.1', id: 'AABR07013931.1'},
{value: 'aabr07013931.2', id: 'AABR07013931.2'},
{value: 'aabr07013931.3', id: 'AABR07013931.3'},
{value: 'aabr07013945.1', id: 'AABR07013945.1'},
{value: 'aabr07014016.1', id: 'AABR07014016.1'},
{value: 'aabr07014021.1', id: 'AABR07014021.1'},
{value: 'aabr07014089.1', id: 'AABR07014089.1'},
{value: 'aabr07014114.1', id: 'AABR07014114.1'},
{value: 'aabr07014118.1', id: 'AABR07014118.1'},
{value: 'aabr07014119.1', id: 'AABR07014119.1'},
{value: 'aabr07014125.1', id: 'AABR07014125.1'},
{value: 'aabr07014125.2', id: 'AABR07014125.2'},
{value: 'aabr07014132.1', id: 'AABR07014132.1'},
{value: 'aabr07014158.1', id: 'AABR07014158.1'},
{value: 'aabr07014161.1', id: 'AABR07014161.1'},
{value: 'aabr07014161.2', id: 'AABR07014161.2'},
{value: 'aabr07014177.1', id: 'AABR07014177.1'},
{value: 'aabr07014205.1', id: 'AABR07014205.1'},
{value: 'aabr07014218.1', id: 'AABR07014218.1'},
{value: 'aabr07014239.1', id: 'AABR07014239.1'},
{value: 'aabr07014241.1', id: 'AABR07014241.1'},
{value: 'aabr07014242.1', id: 'AABR07014242.1'},
{value: 'aabr07014272.1', id: 'AABR07014272.1'},
{value: 'aabr07014275.1', id: 'AABR07014275.1'},
{value: 'aabr07014289.1', id: 'AABR07014289.1'},
{value: 'aabr07014290.1', id: 'AABR07014290.1'},
{value: 'aabr07014298.1', id: 'AABR07014298.1'},
{value: 'aabr07014301.1', id: 'AABR07014301.1'},
{value: 'aabr07014306.1', id: 'AABR07014306.1'},
{value: 'aabr07014306.2', id: 'AABR07014306.2'},
{value: 'aabr07014311.1', id: 'AABR07014311.1'},
{value: 'aabr07014313.1', id: 'AABR07014313.1'},
{value: 'aabr07014317.1', id: 'AABR07014317.1'},
{value: 'aabr07014317.2', id: 'AABR07014317.2'},
{value: 'aabr07014323.1', id: 'AABR07014323.1'},
{value: 'aabr07014328.1', id: 'AABR07014328.1'},
{value: 'aabr07014342.1', id: 'AABR07014342.1'},
{value: 'aabr07014350.1', id: 'AABR07014350.1'},
{value: 'aabr07014355.1', id: 'AABR07014355.1'},
{value: 'aabr07014384.1', id: 'AABR07014384.1'},
{value: 'aabr07014387.1', id: 'AABR07014387.1'},
{value: 'aabr07014424.1', id: 'AABR07014424.1'},
{value: 'aabr07014487.1', id: 'AABR07014487.1'},
{value: 'aabr07014499.1', id: 'AABR07014499.1'},
{value: 'aabr07014512.1', id: 'AABR07014512.1'},
{value: 'aabr07014512.2', id: 'AABR07014512.2'},
{value: 'aabr07014514.1', id: 'AABR07014514.1'},
{value: 'aabr07014524.1', id: 'AABR07014524.1'},
{value: 'aabr07014534.2', id: 'AABR07014534.2'},
{value: 'aabr07014547.1', id: 'AABR07014547.1'},
{value: 'aabr07014550.1', id: 'AABR07014550.1'},
{value: 'aabr07014552.1', id: 'AABR07014552.1'},
{value: 'aabr07014552.2', id: 'AABR07014552.2'},
{value: 'aabr07014573.1', id: 'AABR07014573.1'},
{value: 'aabr07014573.2', id: 'AABR07014573.2'},
{value: 'aabr07014621.1', id: 'AABR07014621.1'},
{value: 'aabr07014630.1', id: 'AABR07014630.1'},
{value: 'aabr07014638.1', id: 'AABR07014638.1'},
{value: 'aabr07014649.1', id: 'AABR07014649.1'},
{value: 'aabr07014676.1', id: 'AABR07014676.1'},
{value: 'aabr07014688.1', id: 'AABR07014688.1'},
{value: 'aabr07014690.1', id: 'AABR07014690.1'},
{value: 'aabr07014703.1', id: 'AABR07014703.1'},
{value: 'aabr07014722.1', id: 'AABR07014722.1'},
{value: 'aabr07014739.1', id: 'AABR07014739.1'},
{value: 'aabr07014745.1', id: 'AABR07014745.1'},
{value: 'aabr07014756.1', id: 'AABR07014756.1'},
{value: 'aabr07014758.1', id: 'AABR07014758.1'},
{value: 'aabr07014760.1', id: 'AABR07014760.1'},
{value: 'aabr07014769.1', id: 'AABR07014769.1'},
{value: 'aabr07014778.1', id: 'AABR07014778.1'},
{value: 'aabr07014804.1', id: 'AABR07014804.1'},
{value: 'aabr07014820.1', id: 'AABR07014820.1'},
{value: 'aabr07014822.1', id: 'AABR07014822.1'},
{value: 'aabr07014823.1', id: 'AABR07014823.1'},
{value: 'aabr07014836.1', id: 'AABR07014836.1'},
{value: 'aabr07014855.1', id: 'AABR07014855.1'},
{value: 'aabr07014875.1', id: 'AABR07014875.1'},
{value: 'aabr07014881.1', id: 'AABR07014881.1'},
{value: 'aabr07014882.1', id: 'AABR07014882.1'},
{value: 'aabr07014885.1', id: 'AABR07014885.1'},
{value: 'aabr07014897.1', id: 'AABR07014897.1'},
{value: 'aabr07014897.2', id: 'AABR07014897.2'},
{value: 'aabr07014914.1', id: 'AABR07014914.1'},
{value: 'aabr07014925.1', id: 'AABR07014925.1'},
{value: 'aabr07014960.1', id: 'AABR07014960.1'},
{value: 'aabr07014962.1', id: 'AABR07014962.1'},
{value: 'aabr07014974.1', id: 'AABR07014974.1'},
{value: 'aabr07014980.1', id: 'AABR07014980.1'},
{value: 'aabr07014983.1', id: 'AABR07014983.1'},
{value: 'aabr07014983.2', id: 'AABR07014983.2'},
{value: 'aabr07014987.1', id: 'AABR07014987.1'},
{value: 'aabr07014992.1', id: 'AABR07014992.1'},
{value: 'aabr07014996.1', id: 'AABR07014996.1'},
{value: 'aabr07015002.1', id: 'AABR07015002.1'},
{value: 'aabr07015003.1', id: 'AABR07015003.1'},
{value: 'aabr07015005.1', id: 'AABR07015005.1'},
{value: 'aabr07015006.2', id: 'AABR07015006.2'},
{value: 'aabr07015007.1', id: 'AABR07015007.1'},
{value: 'aabr07015015.1', id: 'AABR07015015.1'},
{value: 'aabr07015015.2', id: 'AABR07015015.2'},
{value: 'aabr07015029.1', id: 'AABR07015029.1'},
{value: 'aabr07015033.1', id: 'AABR07015033.1'},
{value: 'aabr07015040.1', id: 'AABR07015040.1'},
{value: 'aabr07015042.1', id: 'AABR07015042.1'},
{value: 'aabr07015055.1', id: 'AABR07015055.1'},
{value: 'aabr07015056.1', id: 'AABR07015056.1'},
{value: 'aabr07015057.1', id: 'AABR07015057.1'},
{value: 'aabr07015064.1', id: 'AABR07015064.1'},
{value: 'aabr07015065.1', id: 'AABR07015065.1'},
{value: 'aabr07015066.1', id: 'AABR07015066.1'},
{value: 'aabr07015067.1', id: 'AABR07015067.1'},
{value: 'aabr07015076.1', id: 'AABR07015076.1'},
{value: 'aabr07015078.1', id: 'AABR07015078.1'},
{value: 'aabr07015078.2', id: 'AABR07015078.2'},
{value: 'aabr07015079.1', id: 'AABR07015079.1'},
{value: 'aabr07015080.2', id: 'AABR07015080.2'},
{value: 'aabr07015081.2', id: 'AABR07015081.2'},
{value: 'aabr07015087.1', id: 'AABR07015087.1'},
{value: 'aabr07015112.1', id: 'AABR07015112.1'},
{value: 'aabr07015165.1', id: 'AABR07015165.1'},
{value: 'aabr07015180.1', id: 'AABR07015180.1'},
{value: 'aabr07015320.1', id: 'AABR07015320.1'},
{value: 'aabr07015338.1', id: 'AABR07015338.1'},
{value: 'aabr07015346.1', id: 'AABR07015346.1'},
{value: 'aabr07015358.1', id: 'AABR07015358.1'},
{value: 'aabr07015394.1', id: 'AABR07015394.1'},
{value: 'aabr07015399.1', id: 'AABR07015399.1'},
{value: 'aabr07015422.1', id: 'AABR07015422.1'},
{value: 'aabr07015430.1', id: 'AABR07015430.1'},
{value: 'aabr07015467.1', id: 'AABR07015467.1'},
{value: 'aabr07015468.1', id: 'AABR07015468.1'},
{value: 'aabr07015476.1', id: 'AABR07015476.1'},
{value: 'aabr07015500.1', id: 'AABR07015500.1'},
{value: 'aabr07015507.1', id: 'AABR07015507.1'},
{value: 'aabr07015510.1', id: 'AABR07015510.1'},
{value: 'aabr07015517.1', id: 'AABR07015517.1'},
{value: 'aabr07015517.2', id: 'AABR07015517.2'},
{value: 'aabr07015517.3', id: 'AABR07015517.3'},
{value: 'aabr07015525.1', id: 'AABR07015525.1'},
{value: 'aabr07015529.1', id: 'AABR07015529.1'},
{value: 'aabr07015531.1', id: 'AABR07015531.1'},
{value: 'aabr07015547.1', id: 'AABR07015547.1'},
{value: 'aabr07015547.2', id: 'AABR07015547.2'},
{value: 'aabr07015548.1', id: 'AABR07015548.1'},
{value: 'aabr07015555.1', id: 'AABR07015555.1'},
{value: 'aabr07015556.1', id: 'AABR07015556.1'},
{value: 'aabr07015559.1', id: 'AABR07015559.1'},
{value: 'aabr07015582.2', id: 'AABR07015582.2'},
{value: 'aabr07015596.1', id: 'AABR07015596.1'},
{value: 'aabr07015602.1', id: 'AABR07015602.1'},
{value: 'aabr07015604.1', id: 'AABR07015604.1'},
{value: 'aabr07015608.1', id: 'AABR07015608.1'},
{value: 'aabr07015639.1', id: 'AABR07015639.1'},
{value: 'aabr07015647.1', id: 'AABR07015647.1'},
{value: 'aabr07015647.2', id: 'AABR07015647.2'},
{value: 'aabr07015648.1', id: 'AABR07015648.1'},
{value: 'aabr07015652.1', id: 'AABR07015652.1'},
{value: 'aabr07015654.1', id: 'AABR07015654.1'},
{value: 'aabr07015674.1', id: 'AABR07015674.1'},
{value: 'aabr07015687.1', id: 'AABR07015687.1'},
{value: 'aabr07015705.1', id: 'AABR07015705.1'},
{value: 'aabr07015719.1', id: 'AABR07015719.1'},
{value: 'aabr07015737.1', id: 'AABR07015737.1'},
{value: 'aabr07015743.1', id: 'AABR07015743.1'},
{value: 'aabr07015759.1', id: 'AABR07015759.1'},
{value: 'aabr07015761.1', id: 'AABR07015761.1'},
{value: 'aabr07015781.1', id: 'AABR07015781.1'},
{value: 'aabr07015783.1', id: 'AABR07015783.1'},
{value: 'aabr07015784.1', id: 'AABR07015784.1'},
{value: 'aabr07015791.1', id: 'AABR07015791.1'},
{value: 'aabr07015800.1', id: 'AABR07015800.1'},
{value: 'aabr07015800.2', id: 'AABR07015800.2'},
{value: 'aabr07015803.1', id: 'AABR07015803.1'},
{value: 'aabr07015812.1', id: 'AABR07015812.1'},
{value: 'aabr07015812.2', id: 'AABR07015812.2'},
{value: 'aabr07015814.1', id: 'AABR07015814.1'},
{value: 'aabr07015831.1', id: 'AABR07015831.1'},
{value: 'aabr07015834.1', id: 'AABR07015834.1'},
{value: 'aabr07015838.2', id: 'AABR07015838.2'},
{value: 'aabr07015855.1', id: 'AABR07015855.1'},
{value: 'aabr07015881.1', id: 'AABR07015881.1'},
{value: 'aabr07015889.1', id: 'AABR07015889.1'},
{value: 'aabr07015889.2', id: 'AABR07015889.2'},
{value: 'aabr07015903.1', id: 'AABR07015903.1'},
{value: 'aabr07015917.1', id: 'AABR07015917.1'},
{value: 'aabr07015941.1', id: 'AABR07015941.1'},
{value: 'aabr07015942.1', id: 'AABR07015942.1'},
{value: 'aabr07015966.1', id: 'AABR07015966.1'},
{value: 'aabr07015994.1', id: 'AABR07015994.1'},
{value: 'aabr07016051.1', id: 'AABR07016051.1'},
{value: 'aabr07016056.1', id: 'AABR07016056.1'},
{value: 'aabr07016058.1', id: 'AABR07016058.1'},
{value: 'aabr07016141.1', id: 'AABR07016141.1'},
{value: 'aabr07016166.1', id: 'AABR07016166.1'},
{value: 'aabr07016310.1', id: 'AABR07016310.1'},
{value: 'aabr07016332.1', id: 'AABR07016332.1'},
{value: 'aabr07016363.1', id: 'AABR07016363.1'},
{value: 'aabr07016365.1', id: 'AABR07016365.1'},
{value: 'aabr07016412.1', id: 'AABR07016412.1'},
{value: 'aabr07016418.1', id: 'AABR07016418.1'},
{value: 'aabr07016428.1', id: 'AABR07016428.1'},
{value: 'aabr07016545.1', id: 'AABR07016545.1'},
{value: 'aabr07016556.1', id: 'AABR07016556.1'},
{value: 'aabr07016556.2', id: 'AABR07016556.2'},
{value: 'aabr07016556.3', id: 'AABR07016556.3'},
{value: 'aabr07016558.1', id: 'AABR07016558.1'},
{value: 'aabr07016558.2', id: 'AABR07016558.2'},
{value: 'aabr07016559.1', id: 'AABR07016559.1'},
{value: 'aabr07016566.1', id: 'AABR07016566.1'},
{value: 'aabr07016572.1', id: 'AABR07016572.1'},
{value: 'aabr07016573.1', id: 'AABR07016573.1'},
{value: 'aabr07016578.1', id: 'AABR07016578.1'},
{value: 'aabr07016578.2', id: 'AABR07016578.2'},
{value: 'aabr07016578.3', id: 'AABR07016578.3'},
{value: 'aabr07016586.1', id: 'AABR07016586.1'},
{value: 'aabr07016589.1', id: 'AABR07016589.1'},
{value: 'aabr07016592.1', id: 'AABR07016592.1'},
{value: 'aabr07016592.2', id: 'AABR07016592.2'},
{value: 'aabr07016612.1', id: 'AABR07016612.1'},
{value: 'aabr07016619.1', id: 'AABR07016619.1'},
{value: 'aabr07016621.1', id: 'AABR07016621.1'},
{value: 'aabr07016623.1', id: 'AABR07016623.1'},
{value: 'aabr07016628.1', id: 'AABR07016628.1'},
{value: 'aabr07016633.1', id: 'AABR07016633.1'},
{value: 'aabr07016640.1', id: 'AABR07016640.1'},
{value: 'aabr07016640.2', id: 'AABR07016640.2'},
{value: 'aabr07016640.3', id: 'AABR07016640.3'},
{value: 'aabr07016650.1', id: 'AABR07016650.1'},
{value: 'aabr07016672.1', id: 'AABR07016672.1'},
{value: 'aabr07016674.1', id: 'AABR07016674.1'},
{value: 'aabr07016692.1', id: 'AABR07016692.1'},
{value: 'aabr07016703.1', id: 'AABR07016703.1'},
{value: 'aabr07016703.2', id: 'AABR07016703.2'},
{value: 'aabr07016721.1', id: 'AABR07016721.1'},
{value: 'aabr07016722.1', id: 'AABR07016722.1'},
{value: 'aabr07016731.1', id: 'AABR07016731.1'},
{value: 'aabr07016731.2', id: 'AABR07016731.2'},
{value: 'aabr07016752.1', id: 'AABR07016752.1'},
{value: 'aabr07016769.1', id: 'AABR07016769.1'},
{value: 'aabr07016779.1', id: 'AABR07016779.1'},
{value: 'aabr07016805.1', id: 'AABR07016805.1'},
{value: 'aabr07016841.1', id: 'AABR07016841.1'},
{value: 'aabr07016841.2', id: 'AABR07016841.2'},
{value: 'aabr07016845.1', id: 'AABR07016845.1'},
{value: 'aabr07016856.1', id: 'AABR07016856.1'},
{value: 'aabr07016873.1', id: 'AABR07016873.1'},
{value: 'aabr07016919.1', id: 'AABR07016919.1'},
{value: 'aabr07016934.1', id: 'AABR07016934.1'},
{value: 'aabr07016947.1', id: 'AABR07016947.1'},
{value: 'aabr07016947.2', id: 'AABR07016947.2'},
{value: 'aabr07016950.1', id: 'AABR07016950.1'},
{value: 'aabr07016950.2', id: 'AABR07016950.2'},
{value: 'aabr07016975.1', id: 'AABR07016975.1'},
{value: 'aabr07016976.1', id: 'AABR07016976.1'},
{value: 'aabr07016992.1', id: 'AABR07016992.1'},
{value: 'aabr07017006.1', id: 'AABR07017006.1'},
{value: 'aabr07017038.1', id: 'AABR07017038.1'},
{value: 'aabr07017045.1', id: 'AABR07017045.1'},
{value: 'aabr07017046.1', id: 'AABR07017046.1'},
{value: 'aabr07017103.1', id: 'AABR07017103.1'},
{value: 'aabr07017104.1', id: 'AABR07017104.1'},
{value: 'aabr07017104.2', id: 'AABR07017104.2'},
{value: 'aabr07017110.1', id: 'AABR07017110.1'},
{value: 'aabr07017113.1', id: 'AABR07017113.1'},
{value: 'aabr07017124.1', id: 'AABR07017124.1'},
{value: 'aabr07017140.1', id: 'AABR07017140.1'},
{value: 'aabr07017141.1', id: 'AABR07017141.1'},
{value: 'aabr07017145.2', id: 'AABR07017145.2'},
{value: 'aabr07017156.1', id: 'AABR07017156.1'},
{value: 'aabr07017159.1', id: 'AABR07017159.1'},
{value: 'aabr07017159.2', id: 'AABR07017159.2'},
{value: 'aabr07017169.1', id: 'AABR07017169.1'},
{value: 'aabr07017173.1', id: 'AABR07017173.1'},
{value: 'aabr07017176.1', id: 'AABR07017176.1'},
{value: 'aabr07017189.1', id: 'AABR07017189.1'},
{value: 'aabr07017197.1', id: 'AABR07017197.1'},
{value: 'aabr07017198.1', id: 'AABR07017198.1'},
{value: 'aabr07017198.2', id: 'AABR07017198.2'},
{value: 'aabr07017200.1', id: 'AABR07017200.1'},
{value: 'aabr07017203.1', id: 'AABR07017203.1'},
{value: 'aabr07017208.1', id: 'AABR07017208.1'},
{value: 'aabr07017208.2', id: 'AABR07017208.2'},
{value: 'aabr07017227.1', id: 'AABR07017227.1'},
{value: 'aabr07017236.1', id: 'AABR07017236.1'},
{value: 'aabr07017236.2', id: 'AABR07017236.2'},
{value: 'aabr07017237.2', id: 'AABR07017237.2'},
{value: 'aabr07017237.3', id: 'AABR07017237.3'},
{value: 'aabr07017241.1', id: 'AABR07017241.1'},
{value: 'aabr07017250.1', id: 'AABR07017250.1'},
{value: 'aabr07017259.1', id: 'AABR07017259.1'},
{value: 'aabr07017266.1', id: 'AABR07017266.1'},
{value: 'aabr07017268.1', id: 'AABR07017268.1'},
{value: 'aabr07017269.1', id: 'AABR07017269.1'},
{value: 'aabr07017270.1', id: 'AABR07017270.1'},
{value: 'aabr07017296.1', id: 'AABR07017296.1'},
{value: 'aabr07017308.1', id: 'AABR07017308.1'},
{value: 'aabr07017491.1', id: 'AABR07017491.1'},
{value: 'aabr07017506.1', id: 'AABR07017506.1'},
{value: 'aabr07017511.1', id: 'AABR07017511.1'},
{value: 'aabr07017512.1', id: 'AABR07017512.1'},
{value: 'aabr07017513.1', id: 'AABR07017513.1'},
{value: 'aabr07017527.1', id: 'AABR07017527.1'},
{value: 'aabr07017528.1', id: 'AABR07017528.1'},
{value: 'aabr07017540.1', id: 'AABR07017540.1'},
{value: 'aabr07017576.1', id: 'AABR07017576.1'},
{value: 'aabr07017597.1', id: 'AABR07017597.1'},
{value: 'aabr07017599.1', id: 'AABR07017599.1'},
{value: 'aabr07017604.1', id: 'AABR07017604.1'},
{value: 'aabr07017617.2', id: 'AABR07017617.2'},
{value: 'aabr07017624.2', id: 'AABR07017624.2'},
{value: 'aabr07017626.1', id: 'AABR07017626.1'},
{value: 'aabr07017635.2', id: 'AABR07017635.2'},
{value: 'aabr07017639.1', id: 'AABR07017639.1'},
{value: 'aabr07017639.2', id: 'AABR07017639.2'},
{value: 'aabr07017647.1', id: 'AABR07017647.1'},
{value: 'aabr07017649.2', id: 'AABR07017649.2'},
{value: 'aabr07017662.1', id: 'AABR07017662.1'},
{value: 'aabr07017662.2', id: 'AABR07017662.2'},
{value: 'aabr07017676.1', id: 'AABR07017676.1'},
{value: 'aabr07017676.2', id: 'AABR07017676.2'},
{value: 'aabr07017681.1', id: 'AABR07017681.1'},
{value: 'aabr07017693.2', id: 'AABR07017693.2'},
{value: 'aabr07017701.1', id: 'AABR07017701.1'},
{value: 'aabr07017707.1', id: 'AABR07017707.1'},
{value: 'aabr07017716.1', id: 'AABR07017716.1'},
{value: 'aabr07017737.1', id: 'AABR07017737.1'},
{value: 'aabr07017745.3', id: 'AABR07017745.3'},
{value: 'aabr07017745.4', id: 'AABR07017745.4'},
{value: 'aabr07017763.2', id: 'AABR07017763.2'},
{value: 'aabr07017768.5', id: 'AABR07017768.5'},
{value: 'aabr07017770.1', id: 'AABR07017770.1'},
{value: 'aabr07017773.1', id: 'AABR07017773.1'},
{value: 'aabr07017781.1', id: 'AABR07017781.1'},
{value: 'aabr07017783.4', id: 'AABR07017783.4'},
{value: 'aabr07017786.1', id: 'AABR07017786.1'},
{value: 'aabr07017789.1', id: 'AABR07017789.1'},
{value: 'aabr07017798.3', id: 'AABR07017798.3'},
{value: 'aabr07017809.1', id: 'AABR07017809.1'},
{value: 'aabr07017812.1', id: 'AABR07017812.1'},
{value: 'aabr07017814.1', id: 'AABR07017814.1'},
{value: 'aabr07017815.1', id: 'AABR07017815.1'},
{value: 'aabr07017824.1', id: 'AABR07017824.1'},
{value: 'aabr07017824.2', id: 'AABR07017824.2'},
{value: 'aabr07017825.1', id: 'AABR07017825.1'},
{value: 'aabr07017825.2', id: 'AABR07017825.2'},
{value: 'aabr07017825.5', id: 'AABR07017825.5'},
{value: 'aabr07017825.7', id: 'AABR07017825.7'},
{value: 'aabr07017830.1', id: 'AABR07017830.1'},
{value: 'aabr07017831.1', id: 'AABR07017831.1'},
{value: 'aabr07017838.2', id: 'AABR07017838.2'},
{value: 'aabr07017841.1', id: 'AABR07017841.1'},
{value: 'aabr07017849.1', id: 'AABR07017849.1'},
{value: 'aabr07017855.1', id: 'AABR07017855.1'},
{value: 'aabr07017868.1', id: 'AABR07017868.1'},
{value: 'aabr07017868.2', id: 'AABR07017868.2'},
{value: 'aabr07017868.4', id: 'AABR07017868.4'},
{value: 'aabr07017870.2', id: 'AABR07017870.2'},
{value: 'aabr07017872.1', id: 'AABR07017872.1'},
{value: 'aabr07017874.1', id: 'AABR07017874.1'},
{value: 'aabr07017875.1', id: 'AABR07017875.1'},
{value: 'aabr07017901.1', id: 'AABR07017901.1'},
{value: 'aabr07017902.1', id: 'AABR07017902.1'},
{value: 'aabr07017902.3', id: 'AABR07017902.3'},
{value: 'aabr07017912.1', id: 'AABR07017912.1'},
{value: 'aabr07017917.1', id: 'AABR07017917.1'},
{value: 'aabr07017936.1', id: 'AABR07017936.1'},
{value: 'aabr07017982.1', id: 'AABR07017982.1'},
{value: 'aabr07017990.1', id: 'AABR07017990.1'},
{value: 'aabr07017999.1', id: 'AABR07017999.1'},
{value: 'aabr07018016.1', id: 'AABR07018016.1'},
{value: 'aabr07018028.2', id: 'AABR07018028.2'},
{value: 'aabr07018038.1', id: 'AABR07018038.1'},
{value: 'aabr07018038.3', id: 'AABR07018038.3'},
{value: 'aabr07018048.2', id: 'AABR07018048.2'},
{value: 'aabr07018050.1', id: 'AABR07018050.1'},
{value: 'aabr07018050.2', id: 'AABR07018050.2'},
{value: 'aabr07018053.1', id: 'AABR07018053.1'},
{value: 'aabr07018058.1', id: 'AABR07018058.1'},
{value: 'aabr07018062.1', id: 'AABR07018062.1'},
{value: 'aabr07018062.2', id: 'AABR07018062.2'},
{value: 'aabr07018064.1', id: 'AABR07018064.1'},
{value: 'aabr07018087.1', id: 'AABR07018087.1'},
{value: 'aabr07018100.1', id: 'AABR07018100.1'},
{value: 'aabr07018115.1', id: 'AABR07018115.1'},
{value: 'aabr07018115.2', id: 'AABR07018115.2'},
{value: 'aabr07018115.3', id: 'AABR07018115.3'},
{value: 'aabr07018116.1', id: 'AABR07018116.1'},
{value: 'aabr07018119.1', id: 'AABR07018119.1'},
{value: 'aabr07018119.2', id: 'AABR07018119.2'},
{value: 'aabr07018124.1', id: 'AABR07018124.1'},
{value: 'aabr07018124.2', id: 'AABR07018124.2'},
{value: 'aabr07018128.2', id: 'AABR07018128.2'},
{value: 'aabr07018132.1', id: 'AABR07018132.1'},
{value: 'aabr07018155.1', id: 'AABR07018155.1'},
{value: 'aabr07018163.1', id: 'AABR07018163.1'},
{value: 'aabr07018164.1', id: 'AABR07018164.1'},
{value: 'aabr07018167.1', id: 'AABR07018167.1'},
{value: 'aabr07018170.1', id: 'AABR07018170.1'},
{value: 'aabr07018183.1', id: 'AABR07018183.1'},
{value: 'aabr07018191.1', id: 'AABR07018191.1'},
{value: 'aabr07018197.1', id: 'AABR07018197.1'},
{value: 'aabr07018198.1', id: 'AABR07018198.1'},
{value: 'aabr07018198.2', id: 'AABR07018198.2'},
{value: 'aabr07018218.1', id: 'AABR07018218.1'},
{value: 'aabr07018226.1', id: 'AABR07018226.1'},
{value: 'aabr07018226.2', id: 'AABR07018226.2'},
{value: 'aabr07018244.1', id: 'AABR07018244.1'},
{value: 'aabr07018244.2', id: 'AABR07018244.2'},
{value: 'aabr07018262.2', id: 'AABR07018262.2'},
{value: 'aabr07018269.1', id: 'AABR07018269.1'},
{value: 'aabr07018269.2', id: 'AABR07018269.2'},
{value: 'aabr07018272.1', id: 'AABR07018272.1'},
{value: 'aabr07018273.1', id: 'AABR07018273.1'},
{value: 'aabr07018273.2', id: 'AABR07018273.2'},
{value: 'aabr07018290.1', id: 'AABR07018290.1'},
{value: 'aabr07018321.1', id: 'AABR07018321.1'},
{value: 'aabr07018321.2', id: 'AABR07018321.2'},
{value: 'aabr07018323.1', id: 'AABR07018323.1'},
{value: 'aabr07018326.1', id: 'AABR07018326.1'},
{value: 'aabr07018331.1', id: 'AABR07018331.1'},
{value: 'aabr07018331.2', id: 'AABR07018331.2'},
{value: 'aabr07018342.1', id: 'AABR07018342.1'},
{value: 'aabr07018373.1', id: 'AABR07018373.1'},
{value: 'aabr07018390.1', id: 'AABR07018390.1'},
{value: 'aabr07018416.1', id: 'AABR07018416.1'},
{value: 'aabr07018434.1', id: 'AABR07018434.1'},
{value: 'aabr07018434.2', id: 'AABR07018434.2'},
{value: 'aabr07018437.1', id: 'AABR07018437.1'},
{value: 'aabr07018438.1', id: 'AABR07018438.1'},
{value: 'aabr07018447.1', id: 'AABR07018447.1'},
{value: 'aabr07018456.1', id: 'AABR07018456.1'},
{value: 'aabr07018457.1', id: 'AABR07018457.1'},
{value: 'aabr07018459.1', id: 'AABR07018459.1'},
{value: 'aabr07018476.1', id: 'AABR07018476.1'},
{value: 'aabr07018495.1', id: 'AABR07018495.1'},
{value: 'aabr07018495.2', id: 'AABR07018495.2'},
{value: 'aabr07018496.1', id: 'AABR07018496.1'},
{value: 'aabr07018503.1', id: 'AABR07018503.1'},
{value: 'aabr07018503.2', id: 'AABR07018503.2'},
{value: 'aabr07018504.1', id: 'AABR07018504.1'},
{value: 'aabr07018533.1', id: 'AABR07018533.1'},
{value: 'aabr07018556.1', id: 'AABR07018556.1'},
{value: 'aabr07018574.1', id: 'AABR07018574.1'},
{value: 'aabr07018627.1', id: 'AABR07018627.1'},
{value: 'aabr07018632.1', id: 'AABR07018632.1'},
{value: 'aabr07018646.1', id: 'AABR07018646.1'},
{value: 'aabr07018690.1', id: 'AABR07018690.1'},
{value: 'aabr07018764.1', id: 'AABR07018764.1'},
{value: 'aabr07018789.1', id: 'AABR07018789.1'},
{value: 'aabr07018792.1', id: 'AABR07018792.1'},
{value: 'aabr07018806.1', id: 'AABR07018806.1'},
{value: 'aabr07018806.2', id: 'AABR07018806.2'},
{value: 'aabr07018807.1', id: 'AABR07018807.1'},
{value: 'aabr07018819.1', id: 'AABR07018819.1'},
{value: 'aabr07018837.1', id: 'AABR07018837.1'},
{value: 'aabr07018851.1', id: 'AABR07018851.1'},
{value: 'aabr07018857.1', id: 'AABR07018857.1'},
{value: 'aabr07018924.1', id: 'AABR07018924.1'},
{value: 'aabr07018965.1', id: 'AABR07018965.1'},
{value: 'aabr07018976.1', id: 'AABR07018976.1'},
{value: 'aabr07019019.1', id: 'AABR07019019.1'},
{value: 'aabr07019020.1', id: 'AABR07019020.1'},
{value: 'aabr07019031.1', id: 'AABR07019031.1'},
{value: 'aabr07019055.1', id: 'AABR07019055.1'},
{value: 'aabr07019061.1', id: 'AABR07019061.1'},
{value: 'aabr07019083.1', id: 'AABR07019083.1'},
{value: 'aabr07019083.2', id: 'AABR07019083.2'},
{value: 'aabr07019085.1', id: 'AABR07019085.1'},
{value: 'aabr07019086.1', id: 'AABR07019086.1'},
{value: 'aabr07019088.1', id: 'AABR07019088.1'},
{value: 'aabr07019091.1', id: 'AABR07019091.1'},
{value: 'aabr07019116.2', id: 'AABR07019116.2'},
{value: 'aabr07019140.1', id: 'AABR07019140.1'},
{value: 'aabr07019143.1', id: 'AABR07019143.1'},
{value: 'aabr07019209.1', id: 'AABR07019209.1'},
{value: 'aabr07019209.2', id: 'AABR07019209.2'},
{value: 'aabr07019224.1', id: 'AABR07019224.1'},
{value: 'aabr07019230.1', id: 'AABR07019230.1'},
{value: 'aabr07019243.1', id: 'AABR07019243.1'},
{value: 'aabr07019253.1', id: 'AABR07019253.1'},
{value: 'aabr07019254.1', id: 'AABR07019254.1'},
{value: 'aabr07019254.2', id: 'AABR07019254.2'},
{value: 'aabr07019269.1', id: 'AABR07019269.1'},
{value: 'aabr07019269.2', id: 'AABR07019269.2'},
{value: 'aabr07019286.1', id: 'AABR07019286.1'},
{value: 'aabr07019334.1', id: 'AABR07019334.1'},
{value: 'aabr07019340.1', id: 'AABR07019340.1'},
{value: 'aabr07019341.1', id: 'AABR07019341.1'},
{value: 'aabr07019341.2', id: 'AABR07019341.2'},
{value: 'aabr07019383.2', id: 'AABR07019383.2'},
{value: 'aabr07019387.1', id: 'AABR07019387.1'},
{value: 'aabr07019387.2', id: 'AABR07019387.2'},
{value: 'aabr07019387.3', id: 'AABR07019387.3'},
{value: 'aabr07019388.1', id: 'AABR07019388.1'},
{value: 'aabr07019392.1', id: 'AABR07019392.1'},
{value: 'aabr07019399.1', id: 'AABR07019399.1'},
{value: 'aabr07019403.1', id: 'AABR07019403.1'},
{value: 'aabr07019412.1', id: 'AABR07019412.1'},
{value: 'aabr07019432.1', id: 'AABR07019432.1'},
{value: 'aabr07019435.1', id: 'AABR07019435.1'},
{value: 'aabr07019437.1', id: 'AABR07019437.1'},
{value: 'aabr07019437.2', id: 'AABR07019437.2'},
{value: 'aabr07019437.3', id: 'AABR07019437.3'},
{value: 'aabr07019437.4', id: 'AABR07019437.4'},
{value: 'aabr07019437.5', id: 'AABR07019437.5'},
{value: 'aabr07019437.6', id: 'AABR07019437.6'},
{value: 'aabr07019437.7', id: 'AABR07019437.7'},
{value: 'aabr07019442.1', id: 'AABR07019442.1'},
{value: 'aabr07019442.2', id: 'AABR07019442.2'},
{value: 'aabr07019443.1', id: 'AABR07019443.1'},
{value: 'aabr07019449.1', id: 'AABR07019449.1'},
{value: 'aabr07019511.1', id: 'AABR07019511.1'},
{value: 'aabr07019512.1', id: 'AABR07019512.1'},
{value: 'aabr07019512.2', id: 'AABR07019512.2'},
{value: 'aabr07019572.1', id: 'AABR07019572.1'},
{value: 'aabr07019572.2', id: 'AABR07019572.2'},
{value: 'aabr07019736.1', id: 'AABR07019736.1'},
{value: 'aabr07019744.1', id: 'AABR07019744.1'},
{value: 'aabr07019774.1', id: 'AABR07019774.1'},
{value: 'aabr07019802.1', id: 'AABR07019802.1'},
{value: 'aabr07019814.1', id: 'AABR07019814.1'},
{value: 'aabr07019870.1', id: 'AABR07019870.1'},
{value: 'aabr07019903.2', id: 'AABR07019903.2'},
{value: 'aabr07019911.1', id: 'AABR07019911.1'},
{value: 'aabr07019963.1', id: 'AABR07019963.1'},
{value: 'aabr07020058.1', id: 'AABR07020058.1'},
{value: 'aabr07020125.1', id: 'AABR07020125.1'},
{value: 'aabr07020325.1', id: 'AABR07020325.1'},
{value: 'aabr07020367.1', id: 'AABR07020367.1'},
{value: 'aabr07020404.1', id: 'AABR07020404.1'},
{value: 'aabr07020485.1', id: 'AABR07020485.1'},
{value: 'aabr07020537.1', id: 'AABR07020537.1'},
{value: 'aabr07020548.1', id: 'AABR07020548.1'},
{value: 'aabr07020550.1', id: 'AABR07020550.1'},
{value: 'aabr07020651.1', id: 'AABR07020651.1'},
{value: 'aabr07020663.1', id: 'AABR07020663.1'},
{value: 'aabr07020696.1', id: 'AABR07020696.1'},
{value: 'aabr07020703.1', id: 'AABR07020703.1'},
{value: 'aabr07020711.1', id: 'AABR07020711.1'},
{value: 'aabr07020736.1', id: 'AABR07020736.1'},
{value: 'aabr07020744.1', id: 'AABR07020744.1'},
{value: 'aabr07020774.1', id: 'AABR07020774.1'},
{value: 'aabr07020786.1', id: 'AABR07020786.1'},
{value: 'aabr07020815.1', id: 'AABR07020815.1'},
{value: 'aabr07020835.1', id: 'AABR07020835.1'},
{value: 'aabr07020859.1', id: 'AABR07020859.1'},
{value: 'aabr07020879.1', id: 'AABR07020879.1'},
{value: 'aabr07020880.1', id: 'AABR07020880.1'},
{value: 'aabr07020898.1', id: 'AABR07020898.1'},
{value: 'aabr07020905.1', id: 'AABR07020905.1'},
{value: 'aabr07020952.1', id: 'AABR07020952.1'},
{value: 'aabr07020979.1', id: 'AABR07020979.1'},
{value: 'aabr07020983.1', id: 'AABR07020983.1'},
{value: 'aabr07020987.1', id: 'AABR07020987.1'},
{value: 'aabr07020990.1', id: 'AABR07020990.1'},
{value: 'aabr07020994.1', id: 'AABR07020994.1'},
{value: 'aabr07020999.1', id: 'AABR07020999.1'},
{value: 'aabr07021008.1', id: 'AABR07021008.1'},
{value: 'aabr07021018.1', id: 'AABR07021018.1'},
{value: 'aabr07021018.2', id: 'AABR07021018.2'},
{value: 'aabr07021022.1', id: 'AABR07021022.1'},
{value: 'aabr07021022.2', id: 'AABR07021022.2'},
{value: 'aabr07021023.1', id: 'AABR07021023.1'},
{value: 'aabr07021039.1', id: 'AABR07021039.1'},
{value: 'aabr07021054.1', id: 'AABR07021054.1'},
{value: 'aabr07021066.1', id: 'AABR07021066.1'},
{value: 'aabr07021080.1', id: 'AABR07021080.1'},
{value: 'aabr07021086.1', id: 'AABR07021086.1'},
{value: 'aabr07021199.1', id: 'AABR07021199.1'},
{value: 'aabr07021204.1', id: 'AABR07021204.1'},
{value: 'aabr07021213.1', id: 'AABR07021213.1'},
{value: 'aabr07021220.1', id: 'AABR07021220.1'},
{value: 'aabr07021221.1', id: 'AABR07021221.1'},
{value: 'aabr07021239.1', id: 'AABR07021239.1'},
{value: 'aabr07021293.1', id: 'AABR07021293.1'},
{value: 'aabr07021306.1', id: 'AABR07021306.1'},
{value: 'aabr07021307.1', id: 'AABR07021307.1'},
{value: 'aabr07021316.1', id: 'AABR07021316.1'},
{value: 'aabr07021337.1', id: 'AABR07021337.1'},
{value: 'aabr07021355.1', id: 'AABR07021355.1'},
{value: 'aabr07021355.2', id: 'AABR07021355.2'},
{value: 'aabr07021357.1', id: 'AABR07021357.1'},
{value: 'aabr07021384.1', id: 'AABR07021384.1'},
{value: 'aabr07021391.1', id: 'AABR07021391.1'},
{value: 'aabr07021392.1', id: 'AABR07021392.1'},
{value: 'aabr07021400.1', id: 'AABR07021400.1'},
{value: 'aabr07021401.1', id: 'AABR07021401.1'},
{value: 'aabr07021401.2', id: 'AABR07021401.2'},
{value: 'aabr07021405.1', id: 'AABR07021405.1'},
{value: 'aabr07021405.2', id: 'AABR07021405.2'},
{value: 'aabr07021405.3', id: 'AABR07021405.3'},
{value: 'aabr07021411.1', id: 'AABR07021411.1'},
{value: 'aabr07021411.2', id: 'AABR07021411.2'},
{value: 'aabr07021419.1', id: 'AABR07021419.1'},
{value: 'aabr07021424.1', id: 'AABR07021424.1'},
{value: 'aabr07021430.1', id: 'AABR07021430.1'},
{value: 'aabr07021433.1', id: 'AABR07021433.1'},
{value: 'aabr07021433.2', id: 'AABR07021433.2'},
{value: 'aabr07021453.1', id: 'AABR07021453.1'},
{value: 'aabr07021456.1', id: 'AABR07021456.1'},
{value: 'aabr07021461.1', id: 'AABR07021461.1'},
{value: 'aabr07021463.1', id: 'AABR07021463.1'},
{value: 'aabr07021465.1', id: 'AABR07021465.1'},
{value: 'aabr07021465.2', id: 'AABR07021465.2'},
{value: 'aabr07021492.1', id: 'AABR07021492.1'},
{value: 'aabr07021494.1', id: 'AABR07021494.1'},
{value: 'aabr07021503.1', id: 'AABR07021503.1'},
{value: 'aabr07021503.3', id: 'AABR07021503.3'},
{value: 'aabr07021508.1', id: 'AABR07021508.1'},
{value: 'aabr07021508.2', id: 'AABR07021508.2'},
{value: 'aabr07021508.3', id: 'AABR07021508.3'},
{value: 'aabr07021530.1', id: 'AABR07021530.1'},
{value: 'aabr07021536.1', id: 'AABR07021536.1'},
{value: 'aabr07021544.2', id: 'AABR07021544.2'},
{value: 'aabr07021545.1', id: 'AABR07021545.1'},
{value: 'aabr07021546.1', id: 'AABR07021546.1'},
{value: 'aabr07021547.1', id: 'AABR07021547.1'},
{value: 'aabr07021548.1', id: 'AABR07021548.1'},
{value: 'aabr07021565.1', id: 'AABR07021565.1'},
{value: 'aabr07021571.1', id: 'AABR07021571.1'},
{value: 'aabr07021572.1', id: 'AABR07021572.1'},
{value: 'aabr07021573.1', id: 'AABR07021573.1'},
{value: 'aabr07021573.2', id: 'AABR07021573.2'},
{value: 'aabr07021573.3', id: 'AABR07021573.3'},
{value: 'aabr07021574.1', id: 'AABR07021574.1'},
{value: 'aabr07021579.1', id: 'AABR07021579.1'},
{value: 'aabr07021584.1', id: 'AABR07021584.1'},
{value: 'aabr07021586.1', id: 'AABR07021586.1'},
{value: 'aabr07021589.1', id: 'AABR07021589.1'},
{value: 'aabr07021590.1', id: 'AABR07021590.1'},
{value: 'aabr07021591.1', id: 'AABR07021591.1'},
{value: 'aabr07021596.1', id: 'AABR07021596.1'},
{value: 'aabr07021596.2', id: 'AABR07021596.2'},
{value: 'aabr07021596.3', id: 'AABR07021596.3'},
{value: 'aabr07021596.4', id: 'AABR07021596.4'},
{value: 'aabr07021603.1', id: 'AABR07021603.1'},
{value: 'aabr07021610.2', id: 'AABR07021610.2'},
{value: 'aabr07021612.1', id: 'AABR07021612.1'},
{value: 'aabr07021612.2', id: 'AABR07021612.2'},
{value: 'aabr07021614.1', id: 'AABR07021614.1'},
{value: 'aabr07021635.1', id: 'AABR07021635.1'},
{value: 'aabr07021635.2', id: 'AABR07021635.2'},
{value: 'aabr07021635.3', id: 'AABR07021635.3'},
{value: 'aabr07021636.1', id: 'AABR07021636.1'},
{value: 'aabr07021666.1', id: 'AABR07021666.1'},
{value: 'aabr07021666.2', id: 'AABR07021666.2'},
{value: 'aabr07021687.1', id: 'AABR07021687.1'},
{value: 'aabr07021688.1', id: 'AABR07021688.1'},
{value: 'aabr07021691.1', id: 'AABR07021691.1'},
{value: 'aabr07021691.2', id: 'AABR07021691.2'},
{value: 'aabr07021704.1', id: 'AABR07021704.1'},
{value: 'aabr07021706.1', id: 'AABR07021706.1'},
{value: 'aabr07021714.1', id: 'AABR07021714.1'},
{value: 'aabr07021717.1', id: 'AABR07021717.1'},
{value: 'aabr07021730.1', id: 'AABR07021730.1'},
{value: 'aabr07021734.1', id: 'AABR07021734.1'},
{value: 'aabr07021759.1', id: 'AABR07021759.1'},
{value: 'aabr07021760.1', id: 'AABR07021760.1'},
{value: 'aabr07021762.1', id: 'AABR07021762.1'},
{value: 'aabr07021762.2', id: 'AABR07021762.2'},
{value: 'aabr07021799.1', id: 'AABR07021799.1'},
{value: 'aabr07021812.1', id: 'AABR07021812.1'},
{value: 'aabr07021823.2', id: 'AABR07021823.2'},
{value: 'aabr07021840.1', id: 'AABR07021840.1'},
{value: 'aabr07021853.1', id: 'AABR07021853.1'},
{value: 'aabr07021861.1', id: 'AABR07021861.1'},
{value: 'aabr07021863.1', id: 'AABR07021863.1'},
{value: 'aabr07021871.1', id: 'AABR07021871.1'},
{value: 'aabr07021888.1', id: 'AABR07021888.1'},
{value: 'aabr07021930.1', id: 'AABR07021930.1'},
{value: 'aabr07021945.1', id: 'AABR07021945.1'},
{value: 'aabr07021946.1', id: 'AABR07021946.1'},
{value: 'aabr07021946.2', id: 'AABR07021946.2'},
{value: 'aabr07021955.1', id: 'AABR07021955.1'},
{value: 'aabr07021972.1', id: 'AABR07021972.1'},
{value: 'aabr07021972.2', id: 'AABR07021972.2'},
{value: 'aabr07021987.1', id: 'AABR07021987.1'},
{value: 'aabr07021996.1', id: 'AABR07021996.1'},
{value: 'aabr07021998.1', id: 'AABR07021998.1'},
{value: 'aabr07022014.1', id: 'AABR07022014.1'},
{value: 'aabr07022022.1', id: 'AABR07022022.1'},
{value: 'aabr07022023.1', id: 'AABR07022023.1'},
{value: 'aabr07022037.1', id: 'AABR07022037.1'},
{value: 'aabr07022039.1', id: 'AABR07022039.1'},
{value: 'aabr07022046.1', id: 'AABR07022046.1'},
{value: 'aabr07022053.1', id: 'AABR07022053.1'},
{value: 'aabr07022055.1', id: 'AABR07022055.1'},
{value: 'aabr07022056.1', id: 'AABR07022056.1'},
{value: 'aabr07022057.1', id: 'AABR07022057.1'},
{value: 'aabr07022061.1', id: 'AABR07022061.1'},
{value: 'aabr07022064.1', id: 'AABR07022064.1'},
{value: 'aabr07022072.1', id: 'AABR07022072.1'},
{value: 'aabr07022072.2', id: 'AABR07022072.2'},
{value: 'aabr07022098.1', id: 'AABR07022098.1'},
{value: 'aabr07022104.1', id: 'AABR07022104.1'},
{value: 'aabr07022108.1', id: 'AABR07022108.1'},
{value: 'aabr07022113.1', id: 'AABR07022113.1'},
{value: 'aabr07022134.1', id: 'AABR07022134.1'},
{value: 'aabr07022144.1', id: 'AABR07022144.1'},
{value: 'aabr07022157.1', id: 'AABR07022157.1'},
{value: 'aabr07022162.1', id: 'AABR07022162.1'},
{value: 'aabr07022162.2', id: 'AABR07022162.2'},
{value: 'aabr07022168.1', id: 'AABR07022168.1'},
{value: 'aabr07022202.1', id: 'AABR07022202.1'},
{value: 'aabr07022202.2', id: 'AABR07022202.2'},
{value: 'aabr07024439.1', id: 'AABR07024439.1'},
{value: 'aabr07024441.1', id: 'AABR07024441.1'},
{value: 'aabr07024442.1', id: 'AABR07024442.1'},
{value: 'aabr07024444.1', id: 'AABR07024444.1'},
{value: 'aabr07024457.1', id: 'AABR07024457.1'},
{value: 'aabr07024463.1', id: 'AABR07024463.1'},
{value: 'aabr07024468.1', id: 'AABR07024468.1'},
{value: 'aabr07024473.2', id: 'AABR07024473.2'},
{value: 'aabr07024491.1', id: 'AABR07024491.1'},
{value: 'aabr07024498.1', id: 'AABR07024498.1'},
{value: 'aabr07024500.1', id: 'AABR07024500.1'},
{value: 'aabr07024534.1', id: 'AABR07024534.1'},
{value: 'aabr07024542.1', id: 'AABR07024542.1'},
{value: 'aabr07024546.1', id: 'AABR07024546.1'},
{value: 'aabr07024580.1', id: 'AABR07024580.1'},
{value: 'aabr07024580.2', id: 'AABR07024580.2'},
{value: 'aabr07024593.1', id: 'AABR07024593.1'},
{value: 'aabr07024593.2', id: 'AABR07024593.2'},
{value: 'aabr07024597.1', id: 'AABR07024597.1'},
{value: 'aabr07024616.1', id: 'AABR07024616.1'},
{value: 'aabr07024624.1', id: 'AABR07024624.1'},
{value: 'aabr07024637.1', id: 'AABR07024637.1'},
{value: 'aabr07024641.1', id: 'AABR07024641.1'},
{value: 'aabr07024648.1', id: 'AABR07024648.1'},
{value: 'aabr07024648.2', id: 'AABR07024648.2'},
{value: 'aabr07024652.1', id: 'AABR07024652.1'},
{value: 'aabr07024653.1', id: 'AABR07024653.1'},
{value: 'aabr07024656.1', id: 'AABR07024656.1'},
{value: 'aabr07024669.1', id: 'AABR07024669.1'},
{value: 'aabr07024679.1', id: 'AABR07024679.1'},
{value: 'aabr07024682.1', id: 'AABR07024682.1'},
{value: 'aabr07024700.1', id: 'AABR07024700.1'},
{value: 'aabr07024718.1', id: 'AABR07024718.1'},
{value: 'aabr07024721.1', id: 'AABR07024721.1'},
{value: 'aabr07024722.1', id: 'AABR07024722.1'},
{value: 'aabr07024724.1', id: 'AABR07024724.1'},
{value: 'aabr07024734.2', id: 'AABR07024734.2'},
{value: 'aabr07024735.1', id: 'AABR07024735.1'},
{value: 'aabr07024737.1', id: 'AABR07024737.1'},
{value: 'aabr07024742.1', id: 'AABR07024742.1'},
{value: 'aabr07024746.1', id: 'AABR07024746.1'},
{value: 'aabr07024757.1', id: 'AABR07024757.1'},
{value: 'aabr07024786.1', id: 'AABR07024786.1'},
{value: 'aabr07024790.1', id: 'AABR07024790.1'},
{value: 'aabr07024795.1', id: 'AABR07024795.1'},
{value: 'aabr07024799.1', id: 'AABR07024799.1'},
{value: 'aabr07024799.2', id: 'AABR07024799.2'},
{value: 'aabr07024799.3', id: 'AABR07024799.3'},
{value: 'aabr07024802.1', id: 'AABR07024802.1'},
{value: 'aabr07024814.1', id: 'AABR07024814.1'},
{value: 'aabr07024814.2', id: 'AABR07024814.2'},
{value: 'aabr07024825.1', id: 'AABR07024825.1'},
{value: 'aabr07024825.2', id: 'AABR07024825.2'},
{value: 'aabr07024833.1', id: 'AABR07024833.1'},
{value: 'aabr07024856.1', id: 'AABR07024856.1'},
{value: 'aabr07024869.1', id: 'AABR07024869.1'},
{value: 'aabr07024869.2', id: 'AABR07024869.2'},
{value: 'aabr07024870.1', id: 'AABR07024870.1'},
{value: 'aabr07024875.1', id: 'AABR07024875.1'},
{value: 'aabr07024885.1', id: 'AABR07024885.1'},
{value: 'aabr07024907.1', id: 'AABR07024907.1'},
{value: 'aabr07024908.1', id: 'AABR07024908.1'},
{value: 'aabr07024951.1', id: 'AABR07024951.1'},
{value: 'aabr07024954.1', id: 'AABR07024954.1'},
{value: 'aabr07024955.1', id: 'AABR07024955.1'},
{value: 'aabr07024964.1', id: 'AABR07024964.1'},
{value: 'aabr07024972.1', id: 'AABR07024972.1'},
{value: 'aabr07024975.1', id: 'AABR07024975.1'},
{value: 'aabr07024982.1', id: 'AABR07024982.1'},
{value: 'aabr07024991.1', id: 'AABR07024991.1'},
{value: 'aabr07024995.1', id: 'AABR07024995.1'},
{value: 'aabr07024996.1', id: 'AABR07024996.1'},
{value: 'aabr07024996.2', id: 'AABR07024996.2'},
{value: 'aabr07024999.1', id: 'AABR07024999.1'},
{value: 'aabr07025010.1', id: 'AABR07025010.1'},
{value: 'aabr07025023.1', id: 'AABR07025023.1'},
{value: 'aabr07025048.1', id: 'AABR07025048.1'},
{value: 'aabr07025051.1', id: 'AABR07025051.1'},
{value: 'aabr07025051.2', id: 'AABR07025051.2'},
{value: 'aabr07025051.3', id: 'AABR07025051.3'},
{value: 'aabr07025065.1', id: 'AABR07025065.1'},
{value: 'aabr07025074.1', id: 'AABR07025074.1'},
{value: 'aabr07025089.1', id: 'AABR07025089.1'},
{value: 'aabr07025140.1', id: 'AABR07025140.1'},
{value: 'aabr07025152.1', id: 'AABR07025152.1'},
{value: 'aabr07025178.1', id: 'AABR07025178.1'},
{value: 'aabr07025263.1', id: 'AABR07025263.1'},
{value: 'aabr07025272.1', id: 'AABR07025272.1'},
{value: 'aabr07025284.1', id: 'AABR07025284.1'},
{value: 'aabr07025288.1', id: 'AABR07025288.1'},
{value: 'aabr07025295.1', id: 'AABR07025295.1'},
{value: 'aabr07025299.1', id: 'AABR07025299.1'},
{value: 'aabr07025303.1', id: 'AABR07025303.1'},
{value: 'aabr07025316.1', id: 'AABR07025316.1'},
{value: 'aabr07025328.1', id: 'AABR07025328.1'},
{value: 'aabr07025345.1', id: 'AABR07025345.1'},
{value: 'aabr07025348.1', id: 'AABR07025348.1'},
{value: 'aabr07025350.1', id: 'AABR07025350.1'},
{value: 'aabr07025350.2', id: 'AABR07025350.2'},
{value: 'aabr07025358.1', id: 'AABR07025358.1'},
{value: 'aabr07025360.1', id: 'AABR07025360.1'},
{value: 'aabr07025366.1', id: 'AABR07025366.1'},
{value: 'aabr07025385.1', id: 'AABR07025385.1'},
{value: 'aabr07025386.1', id: 'AABR07025386.1'},
{value: 'aabr07025387.1', id: 'AABR07025387.1'},
{value: 'aabr07025408.1', id: 'AABR07025408.1'},
{value: 'aabr07025476.1', id: 'AABR07025476.1'},
{value: 'aabr07025481.1', id: 'AABR07025481.1'},
{value: 'aabr07025527.1', id: 'AABR07025527.1'},
{value: 'aabr07025568.1', id: 'AABR07025568.1'},
{value: 'aabr07025594.1', id: 'AABR07025594.1'},
{value: 'aabr07025594.2', id: 'AABR07025594.2'},
{value: 'aabr07025617.1', id: 'AABR07025617.1'},
{value: 'aabr07025662.1', id: 'AABR07025662.1'},
{value: 'aabr07025663.1', id: 'AABR07025663.1'},
{value: 'aabr07025673.1', id: 'AABR07025673.1'},
{value: 'aabr07025689.1', id: 'AABR07025689.1'},
{value: 'aabr07025689.2', id: 'AABR07025689.2'},
{value: 'aabr07025698.1', id: 'AABR07025698.1'},
{value: 'aabr07025701.1', id: 'AABR07025701.1'},
{value: 'aabr07025709.1', id: 'AABR07025709.1'},
{value: 'aabr07025743.1', id: 'AABR07025743.1'},
{value: 'aabr07025745.1', id: 'AABR07025745.1'},
{value: 'aabr07025757.1', id: 'AABR07025757.1'},
{value: 'aabr07025762.1', id: 'AABR07025762.1'},
{value: 'aabr07025774.1', id: 'AABR07025774.1'},
{value: 'aabr07025787.1', id: 'AABR07025787.1'},
{value: 'aabr07025799.1', id: 'AABR07025799.1'},
{value: 'aabr07025818.1', id: 'AABR07025818.1'},
{value: 'aabr07025818.2', id: 'AABR07025818.2'},
{value: 'aabr07025819.1', id: 'AABR07025819.1'},
{value: 'aabr07025825.1', id: 'AABR07025825.1'},
{value: 'aabr07025896.1', id: 'AABR07025896.1'},
{value: 'aabr07025900.1', id: 'AABR07025900.1'},
{value: 'aabr07025928.1', id: 'AABR07025928.1'},
{value: 'aabr07025937.1', id: 'AABR07025937.1'},
{value: 'aabr07025953.1', id: 'AABR07025953.1'},
{value: 'aabr07025957.1', id: 'AABR07025957.1'},
{value: 'aabr07025958.1', id: 'AABR07025958.1'},
{value: 'aabr07025973.1', id: 'AABR07025973.1'},
{value: 'aabr07025986.1', id: 'AABR07025986.1'},
{value: 'aabr07025986.2', id: 'AABR07025986.2'},
{value: 'aabr07025999.1', id: 'AABR07025999.1'},
{value: 'aabr07025999.2', id: 'AABR07025999.2'},
{value: 'aabr07026002.1', id: 'AABR07026002.1'},
{value: 'aabr07026002.2', id: 'AABR07026002.2'},
{value: 'aabr07026002.3', id: 'AABR07026002.3'},
{value: 'aabr07026002.4', id: 'AABR07026002.4'},
{value: 'aabr07026012.1', id: 'AABR07026012.1'},
{value: 'aabr07026012.2', id: 'AABR07026012.2'},
{value: 'aabr07026012.4', id: 'AABR07026012.4'},
{value: 'aabr07026021.1', id: 'AABR07026021.1'},
{value: 'aabr07026032.1', id: 'AABR07026032.1'},
{value: 'aabr07026032.2', id: 'AABR07026032.2'},
{value: 'aabr07026032.3', id: 'AABR07026032.3'},
{value: 'aabr07026037.1', id: 'AABR07026037.1'},
{value: 'aabr07026044.1', id: 'AABR07026044.1'},
{value: 'aabr07026048.1', id: 'AABR07026048.1'},
{value: 'aabr07026048.2', id: 'AABR07026048.2'},
{value: 'aabr07026049.1', id: 'AABR07026049.1'},
{value: 'aabr07026059.1', id: 'AABR07026059.1'},
{value: 'aabr07026063.1', id: 'AABR07026063.1'},
{value: 'aabr07026063.2', id: 'AABR07026063.2'},
{value: 'aabr07026112.2', id: 'AABR07026112.2'},
{value: 'aabr07026114.1', id: 'AABR07026114.1'},
{value: 'aabr07026120.1', id: 'AABR07026120.1'},
{value: 'aabr07026125.1', id: 'AABR07026125.1'},
{value: 'aabr07026137.1', id: 'AABR07026137.1'},
{value: 'aabr07026137.2', id: 'AABR07026137.2'},
{value: 'aabr07026143.1', id: 'AABR07026143.1'},
{value: 'aabr07026144.1', id: 'AABR07026144.1'},
{value: 'aabr07026151.1', id: 'AABR07026151.1'},
{value: 'aabr07026199.1', id: 'AABR07026199.1'},
{value: 'aabr07026226.1', id: 'AABR07026226.1'},
{value: 'aabr07026233.1', id: 'AABR07026233.1'},
{value: 'aabr07026236.1', id: 'AABR07026236.1'},
{value: 'aabr07026240.1', id: 'AABR07026240.1'},
{value: 'aabr07026240.2', id: 'AABR07026240.2'},
{value: 'aabr07026241.1', id: 'AABR07026241.1'},
{value: 'aabr07026246.1', id: 'AABR07026246.1'},
{value: 'aabr07026281.1', id: 'AABR07026281.1'},
{value: 'aabr07026291.1', id: 'AABR07026291.1'},
{value: 'aabr07026294.1', id: 'AABR07026294.1'},
{value: 'aabr07026300.1', id: 'AABR07026300.1'},
{value: 'aabr07026305.1', id: 'AABR07026305.1'},
{value: 'aabr07026311.1', id: 'AABR07026311.1'},
{value: 'aabr07026317.1', id: 'AABR07026317.1'},
{value: 'aabr07026339.1', id: 'AABR07026339.1'},
{value: 'aabr07026342.1', id: 'AABR07026342.1'},
{value: 'aabr07026361.1', id: 'AABR07026361.1'},
{value: 'aabr07026361.2', id: 'AABR07026361.2'},
{value: 'aabr07026361.3', id: 'AABR07026361.3'},
{value: 'aabr07026377.1', id: 'AABR07026377.1'},
{value: 'aabr07026399.1', id: 'AABR07026399.1'},
{value: 'aabr07026430.1', id: 'AABR07026430.1'},
{value: 'aabr07026441.1', id: 'AABR07026441.1'},
{value: 'aabr07026441.2', id: 'AABR07026441.2'},
{value: 'aabr07026473.1', id: 'AABR07026473.1'},
{value: 'aabr07026483.1', id: 'AABR07026483.1'},
{value: 'aabr07026492.1', id: 'AABR07026492.1'},
{value: 'aabr07026499.1', id: 'AABR07026499.1'},
{value: 'aabr07026499.2', id: 'AABR07026499.2'},
{value: 'aabr07026499.3', id: 'AABR07026499.3'},
{value: 'aabr07026503.2', id: 'AABR07026503.2'},
{value: 'aabr07026503.3', id: 'AABR07026503.3'},
{value: 'aabr07026509.1', id: 'AABR07026509.1'},
{value: 'aabr07026517.1', id: 'AABR07026517.1'},
{value: 'aabr07026519.1', id: 'AABR07026519.1'},
{value: 'aabr07026534.1', id: 'AABR07026534.1'},
{value: 'aabr07026534.3', id: 'AABR07026534.3'},
{value: 'aabr07026536.1', id: 'AABR07026536.1'},
{value: 'aabr07026537.1', id: 'AABR07026537.1'},
{value: 'aabr07026539.2', id: 'AABR07026539.2'},
{value: 'aabr07026544.1', id: 'AABR07026544.1'},
{value: 'aabr07026546.1', id: 'AABR07026546.1'},
{value: 'aabr07026557.1', id: 'AABR07026557.1'},
{value: 'aabr07026557.2', id: 'AABR07026557.2'},
{value: 'aabr07026565.1', id: 'AABR07026565.1'},
{value: 'aabr07026565.2', id: 'AABR07026565.2'},
{value: 'aabr07026576.1', id: 'AABR07026576.1'},
{value: 'aabr07026576.2', id: 'AABR07026576.2'},
{value: 'aabr07026579.1', id: 'AABR07026579.1'},
{value: 'aabr07026596.1', id: 'AABR07026596.1'},
{value: 'aabr07026596.2', id: 'AABR07026596.2'},
{value: 'aabr07026596.3', id: 'AABR07026596.3'},
{value: 'aabr07026612.1', id: 'AABR07026612.1'},
{value: 'aabr07026614.1', id: 'AABR07026614.1'},
{value: 'aabr07026623.1', id: 'AABR07026623.1'},
{value: 'aabr07026641.1', id: 'AABR07026641.1'},
{value: 'aabr07026663.1', id: 'AABR07026663.1'},
{value: 'aabr07026668.1', id: 'AABR07026668.1'},
{value: 'aabr07026704.1', id: 'AABR07026704.1'},
{value: 'aabr07026777.1', id: 'AABR07026777.1'},
{value: 'aabr07026778.1', id: 'AABR07026778.1'},
{value: 'aabr07026797.1', id: 'AABR07026797.1'},
{value: 'aabr07026803.1', id: 'AABR07026803.1'},
{value: 'aabr07026805.1', id: 'AABR07026805.1'},
{value: 'aabr07026805.2', id: 'AABR07026805.2'},
{value: 'aabr07026842.1', id: 'AABR07026842.1'},
{value: 'aabr07026893.1', id: 'AABR07026893.1'},
{value: 'aabr07026912.1', id: 'AABR07026912.1'},
{value: 'aabr07026912.2', id: 'AABR07026912.2'},
{value: 'aabr07026915.1', id: 'AABR07026915.1'},
{value: 'aabr07026921.1', id: 'AABR07026921.1'},
{value: 'aabr07026924.1', id: 'AABR07026924.1'},
{value: 'aabr07026929.1', id: 'AABR07026929.1'},
{value: 'aabr07026930.1', id: 'AABR07026930.1'},
{value: 'aabr07026936.1', id: 'AABR07026936.1'},
{value: 'aabr07026936.2', id: 'AABR07026936.2'},
{value: 'aabr07026936.3', id: 'AABR07026936.3'},
{value: 'aabr07026936.4', id: 'AABR07026936.4'},
{value: 'aabr07026938.1', id: 'AABR07026938.1'},
{value: 'aabr07026971.1', id: 'AABR07026971.1'},
{value: 'aabr07026972.1', id: 'AABR07026972.1'},
{value: 'aabr07026975.1', id: 'AABR07026975.1'},
{value: 'aabr07026984.1', id: 'AABR07026984.1'},
{value: 'aabr07026984.2', id: 'AABR07026984.2'},
{value: 'aabr07026984.3', id: 'AABR07026984.3'},
{value: 'aabr07026986.1', id: 'AABR07026986.1'},
{value: 'aabr07026989.1', id: 'AABR07026989.1'},
{value: 'aabr07026991.1', id: 'AABR07026991.1'},
{value: 'aabr07026997.1', id: 'AABR07026997.1'},
{value: 'aabr07026997.2', id: 'AABR07026997.2'},
{value: 'aabr07027007.1', id: 'AABR07027007.1'},
{value: 'aabr07027009.1', id: 'AABR07027009.1'},
{value: 'aabr07027010.1', id: 'AABR07027010.1'},
{value: 'aabr07027010.2', id: 'AABR07027010.2'},
{value: 'aabr07027015.1', id: 'AABR07027015.1'},
{value: 'aabr07027022.1', id: 'AABR07027022.1'},
{value: 'aabr07027022.2', id: 'AABR07027022.2'},
{value: 'aabr07027028.1', id: 'AABR07027028.1'},
{value: 'aabr07027029.1', id: 'AABR07027029.1'},
{value: 'aabr07027030.1', id: 'AABR07027030.1'},
{value: 'aabr07027039.1', id: 'AABR07027039.1'},
{value: 'aabr07027051.1', id: 'AABR07027051.1'},
{value: 'aabr07027069.1', id: 'AABR07027069.1'},
{value: 'aabr07027072.1', id: 'AABR07027072.1'},
{value: 'aabr07027080.1', id: 'AABR07027080.1'},
{value: 'aabr07027088.1', id: 'AABR07027088.1'},
{value: 'aabr07027096.1', id: 'AABR07027096.1'},
{value: 'aabr07027098.1', id: 'AABR07027098.1'},
{value: 'aabr07027116.1', id: 'AABR07027116.1'},
{value: 'aabr07027137.1', id: 'AABR07027137.1'},
{value: 'aabr07027141.1', id: 'AABR07027141.1'},
{value: 'aabr07027150.1', id: 'AABR07027150.1'},
{value: 'aabr07027150.2', id: 'AABR07027150.2'},
{value: 'aabr07027152.1', id: 'AABR07027152.1'},
{value: 'aabr07027155.1', id: 'AABR07027155.1'},
{value: 'aabr07027157.1', id: 'AABR07027157.1'},
{value: 'aabr07027190.1', id: 'AABR07027190.1'},
{value: 'aabr07027190.2', id: 'AABR07027190.2'},
{value: 'aabr07027212.1', id: 'AABR07027212.1'},
{value: 'aabr07027225.1', id: 'AABR07027225.1'},
{value: 'aabr07027235.1', id: 'AABR07027235.1'},
{value: 'aabr07027235.2', id: 'AABR07027235.2'},
{value: 'aabr07027240.1', id: 'AABR07027240.1'},
{value: 'aabr07027244.1', id: 'AABR07027244.1'},
{value: 'aabr07027254.1', id: 'AABR07027254.1'},
{value: 'aabr07027258.1', id: 'AABR07027258.1'},
{value: 'aabr07027263.1', id: 'AABR07027263.1'},
{value: 'aabr07027267.1', id: 'AABR07027267.1'},
{value: 'aabr07027268.1', id: 'AABR07027268.1'},
{value: 'aabr07027270.1', id: 'AABR07027270.1'},
{value: 'aabr07027272.1', id: 'AABR07027272.1'},
{value: 'aabr07027274.1', id: 'AABR07027274.1'},
{value: 'aabr07027283.1', id: 'AABR07027283.1'},
{value: 'aabr07027287.1', id: 'AABR07027287.1'},
{value: 'aabr07027306.1', id: 'AABR07027306.1'},
{value: 'aabr07027309.1', id: 'AABR07027309.1'},
{value: 'aabr07027321.1', id: 'AABR07027321.1'},
{value: 'aabr07027324.1', id: 'AABR07027324.1'},
{value: 'aabr07027339.1', id: 'AABR07027339.1'},
{value: 'aabr07027347.1', id: 'AABR07027347.1'},
{value: 'aabr07027368.1', id: 'AABR07027368.1'},
{value: 'aabr07027371.1', id: 'AABR07027371.1'},
{value: 'aabr07027377.1', id: 'AABR07027377.1'},
{value: 'aabr07027381.1', id: 'AABR07027381.1'},
{value: 'aabr07027387.1', id: 'AABR07027387.1'},
{value: 'aabr07027390.1', id: 'AABR07027390.1'},
{value: 'aabr07027390.2', id: 'AABR07027390.2'},
{value: 'aabr07027390.3', id: 'AABR07027390.3'},
{value: 'aabr07027394.1', id: 'AABR07027394.1'},
{value: 'aabr07027394.2', id: 'AABR07027394.2'},
{value: 'aabr07027394.3', id: 'AABR07027394.3'},
{value: 'aabr07027407.1', id: 'AABR07027407.1'},
{value: 'aabr07027409.1', id: 'AABR07027409.1'},
{value: 'aabr07027419.1', id: 'AABR07027419.1'},
{value: 'aabr07027431.1', id: 'AABR07027431.1'},
{value: 'aabr07027447.1', id: 'AABR07027447.1'},
{value: 'aabr07027450.1', id: 'AABR07027450.1'},
{value: 'aabr07027450.2', id: 'AABR07027450.2'},
{value: 'aabr07027451.1', id: 'AABR07027451.1'},
{value: 'aabr07027451.2', id: 'AABR07027451.2'},
{value: 'aabr07027476.1', id: 'AABR07027476.1'},
{value: 'aabr07027478.1', id: 'AABR07027478.1'},
{value: 'aabr07027478.2', id: 'AABR07027478.2'},
{value: 'aabr07027478.3', id: 'AABR07027478.3'},
{value: 'aabr07027494.1', id: 'AABR07027494.1'},
{value: 'aabr07027501.1', id: 'AABR07027501.1'},
{value: 'aabr07027501.2', id: 'AABR07027501.2'},
{value: 'aabr07027526.1', id: 'AABR07027526.1'},
{value: 'aabr07027532.1', id: 'AABR07027532.1'},
{value: 'aabr07027533.1', id: 'AABR07027533.1'},
{value: 'aabr07027555.1', id: 'AABR07027555.1'},
{value: 'aabr07027569.1', id: 'AABR07027569.1'},
{value: 'aabr07027569.2', id: 'AABR07027569.2'},
{value: 'aabr07027569.3', id: 'AABR07027569.3'},
{value: 'aabr07027575.1', id: 'AABR07027575.1'},
{value: 'aabr07027581.1', id: 'AABR07027581.1'},
{value: 'aabr07027613.1', id: 'AABR07027613.1'},
{value: 'aabr07027633.1', id: 'AABR07027633.1'},
{value: 'aabr07027692.1', id: 'AABR07027692.1'},
{value: 'aabr07027693.1', id: 'AABR07027693.1'},
{value: 'aabr07027718.1', id: 'AABR07027718.1'},
{value: 'aabr07027722.1', id: 'AABR07027722.1'},
{value: 'aabr07027730.1', id: 'AABR07027730.1'},
{value: 'aabr07027731.1', id: 'AABR07027731.1'},
{value: 'aabr07027736.1', id: 'AABR07027736.1'},
{value: 'aabr07027744.1', id: 'AABR07027744.1'},
{value: 'aabr07027750.1', id: 'AABR07027750.1'},
{value: 'aabr07027752.2', id: 'AABR07027752.2'},
{value: 'aabr07027753.3', id: 'AABR07027753.3'},
{value: 'aabr07027754.1', id: 'AABR07027754.1'},
{value: 'aabr07027758.1', id: 'AABR07027758.1'},
{value: 'aabr07027799.1', id: 'AABR07027799.1'},
{value: 'aabr07027809.1', id: 'AABR07027809.1'},
{value: 'aabr07027810.1', id: 'AABR07027810.1'},
{value: 'aabr07027810.2', id: 'AABR07027810.2'},
{value: 'aabr07027810.3', id: 'AABR07027810.3'},
{value: 'aabr07027810.4', id: 'AABR07027810.4'},
{value: 'aabr07027811.1', id: 'AABR07027811.1'},
{value: 'aabr07027811.2', id: 'AABR07027811.2'},
{value: 'aabr07027811.3', id: 'AABR07027811.3'},
{value: 'aabr07027819.1', id: 'AABR07027819.1'},
{value: 'aabr07027819.2', id: 'AABR07027819.2'},
{value: 'aabr07027847.1', id: 'AABR07027847.1'},
{value: 'aabr07027854.1', id: 'AABR07027854.1'},
{value: 'aabr07027866.1', id: 'AABR07027866.1'},
{value: 'aabr07027870.2', id: 'AABR07027870.2'},
{value: 'aabr07027872.1', id: 'AABR07027872.1'},
{value: 'aabr07027878.1', id: 'AABR07027878.1'},
{value: 'aabr07027906.1', id: 'AABR07027906.1'},
{value: 'aabr07027910.1', id: 'AABR07027910.1'},
{value: 'aabr07027919.1', id: 'AABR07027919.1'},
{value: 'aabr07027925.1', id: 'AABR07027925.1'},
{value: 'aabr07027925.2', id: 'AABR07027925.2'},
{value: 'aabr07027961.1', id: 'AABR07027961.1'},
{value: 'aabr07027962.1', id: 'AABR07027962.1'},
{value: 'aabr07027979.1', id: 'AABR07027979.1'},
{value: 'aabr07028027.1', id: 'AABR07028027.1'},
{value: 'aabr07028039.1', id: 'AABR07028039.1'},
{value: 'aabr07028049.1', id: 'AABR07028049.1'},
{value: 'aabr07028055.1', id: 'AABR07028055.1'},
{value: 'aabr07028069.1', id: 'AABR07028069.1'},
{value: 'aabr07028076.1', id: 'AABR07028076.1'},
{value: 'aabr07028092.1', id: 'AABR07028092.1'},
{value: 'aabr07028094.1', id: 'AABR07028094.1'},
{value: 'aabr07028113.1', id: 'AABR07028113.1'},
{value: 'aabr07028137.1', id: 'AABR07028137.1'},
{value: 'aabr07028137.2', id: 'AABR07028137.2'},
{value: 'aabr07028157.1', id: 'AABR07028157.1'},
{value: 'aabr07028172.1', id: 'AABR07028172.1'},
{value: 'aabr07028183.1', id: 'AABR07028183.1'},
{value: 'aabr07028185.1', id: 'AABR07028185.1'},
{value: 'aabr07028186.1', id: 'AABR07028186.1'},
{value: 'aabr07028200.1', id: 'AABR07028200.1'},
{value: 'aabr07028213.1', id: 'AABR07028213.1'},
{value: 'aabr07028218.1', id: 'AABR07028218.1'},
{value: 'aabr07028227.1', id: 'AABR07028227.1'},
{value: 'aabr07028237.1', id: 'AABR07028237.1'},
{value: 'aabr07028258.1', id: 'AABR07028258.1'},
{value: 'aabr07028262.1', id: 'AABR07028262.1'},
{value: 'aabr07028266.1', id: 'AABR07028266.1'},
{value: 'aabr07028281.1', id: 'AABR07028281.1'},
{value: 'aabr07028349.1', id: 'AABR07028349.1'},
{value: 'aabr07028381.1', id: 'AABR07028381.1'},
{value: 'aabr07028394.1', id: 'AABR07028394.1'},
{value: 'aabr07028414.1', id: 'AABR07028414.1'},
{value: 'aabr07028446.1', id: 'AABR07028446.1'},
{value: 'aabr07028465.1', id: 'AABR07028465.1'},
{value: 'aabr07028469.1', id: 'AABR07028469.1'},
{value: 'aabr07028469.2', id: 'AABR07028469.2'},
{value: 'aabr07028477.1', id: 'AABR07028477.1'},
{value: 'aabr07028479.1', id: 'AABR07028479.1'},
{value: 'aabr07028479.2', id: 'AABR07028479.2'},
{value: 'aabr07028479.3', id: 'AABR07028479.3'},
{value: 'aabr07028480.10', id: 'AABR07028480.10'},
{value: 'aabr07028488.1', id: 'AABR07028488.1'},
{value: 'aabr07028491.1', id: 'AABR07028491.1'},
{value: 'aabr07028492.7', id: 'AABR07028492.7'},
{value: 'aabr07028499.1', id: 'AABR07028499.1'},
{value: 'aabr07028511.1', id: 'AABR07028511.1'},
{value: 'aabr07028513.1', id: 'AABR07028513.1'},
{value: 'aabr07028513.2', id: 'AABR07028513.2'},
{value: 'aabr07028523.1', id: 'AABR07028523.1'},
{value: 'aabr07028525.1', id: 'AABR07028525.1'},
{value: 'aabr07028530.1', id: 'AABR07028530.1'},
{value: 'aabr07028530.2', id: 'AABR07028530.2'},
{value: 'aabr07028530.3', id: 'AABR07028530.3'},
{value: 'aabr07028534.1', id: 'AABR07028534.1'},
{value: 'aabr07028535.1', id: 'AABR07028535.1'},
{value: 'aabr07028558.1', id: 'AABR07028558.1'},
{value: 'aabr07028568.1', id: 'AABR07028568.1'},
{value: 'aabr07028568.2', id: 'AABR07028568.2'},
{value: 'aabr07028615.1', id: 'AABR07028615.1'},
{value: 'aabr07028615.2', id: 'AABR07028615.2'},
{value: 'aabr07028622.1', id: 'AABR07028622.1'},
{value: 'aabr07028665.1', id: 'AABR07028665.1'},
{value: 'aabr07028668.1', id: 'AABR07028668.1'},
{value: 'aabr07028669.1', id: 'AABR07028669.1'},
{value: 'aabr07028691.1', id: 'AABR07028691.1'},
{value: 'aabr07028691.2', id: 'AABR07028691.2'},
{value: 'aabr07028748.1', id: 'AABR07028748.1'},
{value: 'aabr07028749.1', id: 'AABR07028749.1'},
{value: 'aabr07028749.2', id: 'AABR07028749.2'},
{value: 'aabr07028765.1', id: 'AABR07028765.1'},
{value: 'aabr07028765.2', id: 'AABR07028765.2'},
{value: 'aabr07028766.1', id: 'AABR07028766.1'},
{value: 'aabr07028768.1', id: 'AABR07028768.1'},
{value: 'aabr07028769.1', id: 'AABR07028769.1'},
{value: 'aabr07028776.1', id: 'AABR07028776.1'},
{value: 'aabr07028784.1', id: 'AABR07028784.1'},
{value: 'aabr07028792.1', id: 'AABR07028792.1'},
{value: 'aabr07028793.1', id: 'AABR07028793.1'},
{value: 'aabr07028795.1', id: 'AABR07028795.1'},
{value: 'aabr07028795.2', id: 'AABR07028795.2'},
{value: 'aabr07028797.1', id: 'AABR07028797.1'},
{value: 'aabr07028805.1', id: 'AABR07028805.1'},
{value: 'aabr07028813.1', id: 'AABR07028813.1'},
{value: 'aabr07028816.1', id: 'AABR07028816.1'},
{value: 'aabr07028834.1', id: 'AABR07028834.1'},
{value: 'aabr07028839.2', id: 'AABR07028839.2'},
{value: 'aabr07028859.1', id: 'AABR07028859.1'},
{value: 'aabr07028872.1', id: 'AABR07028872.1'},
{value: 'aabr07028874.1', id: 'AABR07028874.1'},
{value: 'aabr07028874.2', id: 'AABR07028874.2'},
{value: 'aabr07028887.1', id: 'AABR07028887.1'},
{value: 'aabr07028902.1', id: 'AABR07028902.1'},
{value: 'aabr07028908.1', id: 'AABR07028908.1'},
{value: 'aabr07028908.2', id: 'AABR07028908.2'},
{value: 'aabr07028917.1', id: 'AABR07028917.1'},
{value: 'aabr07028945.1', id: 'AABR07028945.1'},
{value: 'aabr07028970.1', id: 'AABR07028970.1'},
{value: 'aabr07028970.2', id: 'AABR07028970.2'},
{value: 'aabr07028979.1', id: 'AABR07028979.1'},
{value: 'aabr07028984.1', id: 'AABR07028984.1'},
{value: 'aabr07028989.1', id: 'AABR07028989.1'},
{value: 'aabr07028995.1', id: 'AABR07028995.1'},
{value: 'aabr07028995.2', id: 'AABR07028995.2'},
{value: 'aabr07028997.1', id: 'AABR07028997.1'},
{value: 'aabr07028998.1', id: 'AABR07028998.1'},
{value: 'aabr07029002.1', id: 'AABR07029002.1'},
{value: 'aabr07029002.3', id: 'AABR07029002.3'},
{value: 'aabr07029006.1', id: 'AABR07029006.1'},
{value: 'aabr07029023.1', id: 'AABR07029023.1'},
{value: 'aabr07029092.1', id: 'AABR07029092.1'},
{value: 'aabr07029107.1', id: 'AABR07029107.1'},
{value: 'aabr07029111.1', id: 'AABR07029111.1'},
{value: 'aabr07029111.2', id: 'AABR07029111.2'},
{value: 'aabr07029161.1', id: 'AABR07029161.1'},
{value: 'aabr07029164.1', id: 'AABR07029164.1'},
{value: 'aabr07029172.1', id: 'AABR07029172.1'},
{value: 'aabr07029172.2', id: 'AABR07029172.2'},
{value: 'aabr07029195.1', id: 'AABR07029195.1'},
{value: 'aabr07029198.1', id: 'AABR07029198.1'},
{value: 'aabr07029202.1', id: 'AABR07029202.1'},
{value: 'aabr07029217.2', id: 'AABR07029217.2'},
{value: 'aabr07029217.3', id: 'AABR07029217.3'},
{value: 'aabr07029217.4', id: 'AABR07029217.4'},
{value: 'aabr07029218.1', id: 'AABR07029218.1'},
{value: 'aabr07029220.1', id: 'AABR07029220.1'},
{value: 'aabr07029220.2', id: 'AABR07029220.2'},
{value: 'aabr07029221.1', id: 'AABR07029221.1'},
{value: 'aabr07029260.1', id: 'AABR07029260.1'},
{value: 'aabr07029269.1', id: 'AABR07029269.1'},
{value: 'aabr07029272.1', id: 'AABR07029272.1'},
{value: 'aabr07029272.2', id: 'AABR07029272.2'},
{value: 'aabr07029275.1', id: 'AABR07029275.1'},
{value: 'aabr07029275.2', id: 'AABR07029275.2'},
{value: 'aabr07029310.1', id: 'AABR07029310.1'},
{value: 'aabr07029351.1', id: 'AABR07029351.1'},
{value: 'aabr07029391.1', id: 'AABR07029391.1'},
{value: 'aabr07029410.1', id: 'AABR07029410.1'},
{value: 'aabr07029417.1', id: 'AABR07029417.1'},
{value: 'aabr07029424.1', id: 'AABR07029424.1'},
{value: 'aabr07029442.1', id: 'AABR07029442.1'},
{value: 'aabr07029451.1', id: 'AABR07029451.1'},
{value: 'aabr07029452.1', id: 'AABR07029452.1'},
{value: 'aabr07029457.1', id: 'AABR07029457.1'},
{value: 'aabr07029467.1', id: 'AABR07029467.1'},
{value: 'aabr07029467.2', id: 'AABR07029467.2'},
{value: 'aabr07029470.1', id: 'AABR07029470.1'},
{value: 'aabr07029470.2', id: 'AABR07029470.2'},
{value: 'aabr07029489.1', id: 'AABR07029489.1'},
{value: 'aabr07029490.1', id: 'AABR07029490.1'},
{value: 'aabr07029504.1', id: 'AABR07029504.1'},
{value: 'aabr07029526.1', id: 'AABR07029526.1'},
{value: 'aabr07029535.1', id: 'AABR07029535.1'},
{value: 'aabr07029557.1', id: 'AABR07029557.1'},
{value: 'aabr07029559.1', id: 'AABR07029559.1'},
{value: 'aabr07029563.1', id: 'AABR07029563.1'},
{value: 'aabr07029573.1', id: 'AABR07029573.1'},
{value: 'aabr07029573.2', id: 'AABR07029573.2'},
{value: 'aabr07029573.3', id: 'AABR07029573.3'},
{value: 'aabr07029581.1', id: 'AABR07029581.1'},
{value: 'aabr07029591.1', id: 'AABR07029591.1'},
{value: 'aabr07029596.1', id: 'AABR07029596.1'},
{value: 'aabr07029605.1', id: 'AABR07029605.1'},
{value: 'aabr07029608.1', id: 'AABR07029608.1'},
{value: 'aabr07029613.1', id: 'AABR07029613.1'},
{value: 'aabr07029613.2', id: 'AABR07029613.2'},
{value: 'aabr07029617.1', id: 'AABR07029617.1'},
{value: 'aabr07029634.1', id: 'AABR07029634.1'},
{value: 'aabr07029634.2', id: 'AABR07029634.2'},
{value: 'aabr07029634.3', id: 'AABR07029634.3'},
{value: 'aabr07029634.4', id: 'AABR07029634.4'},
{value: 'aabr07029636.1', id: 'AABR07029636.1'},
{value: 'aabr07029651.1', id: 'AABR07029651.1'},
{value: 'aabr07029664.1', id: 'AABR07029664.1'},
{value: 'aabr07029675.1', id: 'AABR07029675.1'},
{value: 'aabr07029732.1', id: 'AABR07029732.1'},
{value: 'aabr07029741.1', id: 'AABR07029741.1'},
{value: 'aabr07029741.3', id: 'AABR07029741.3'},
{value: 'aabr07029742.1', id: 'AABR07029742.1'},
{value: 'aabr07029742.2', id: 'AABR07029742.2'},
{value: 'aabr07029768.1', id: 'AABR07029768.1'},
{value: 'aabr07029768.2', id: 'AABR07029768.2'},
{value: 'aabr07029769.1', id: 'AABR07029769.1'},
{value: 'aabr07029803.1', id: 'AABR07029803.1'},
{value: 'aabr07029809.1', id: 'AABR07029809.1'},
{value: 'aabr07029830.1', id: 'AABR07029830.1'},
{value: 'aabr07029831.1', id: 'AABR07029831.1'},
{value: 'aabr07029836.1', id: 'AABR07029836.1'},
{value: 'aabr07029842.1', id: 'AABR07029842.1'},
{value: 'aabr07029847.1', id: 'AABR07029847.1'},
{value: 'aabr07029862.1', id: 'AABR07029862.1'},
{value: 'aabr07029862.2', id: 'AABR07029862.2'},
{value: 'aabr07029863.2', id: 'AABR07029863.2'},
{value: 'aabr07029863.3', id: 'AABR07029863.3'},
{value: 'aabr07029863.4', id: 'AABR07029863.4'},
{value: 'aabr07029866.1', id: 'AABR07029866.1'},
{value: 'aabr07029878.1', id: 'AABR07029878.1'},
{value: 'aabr07029890.1', id: 'AABR07029890.1'},
{value: 'aabr07029890.2', id: 'AABR07029890.2'},
{value: 'aabr07029895.1', id: 'AABR07029895.1'},
{value: 'aabr07029901.1', id: 'AABR07029901.1'},
{value: 'aabr07029907.2', id: 'AABR07029907.2'},
{value: 'aabr07029918.1', id: 'AABR07029918.1'},
{value: 'aabr07029925.1', id: 'AABR07029925.1'},
{value: 'aabr07029933.1', id: 'AABR07029933.1'},
{value: 'aabr07029938.1', id: 'AABR07029938.1'},
{value: 'aabr07029955.1', id: 'AABR07029955.1'},
{value: 'aabr07029958.1', id: 'AABR07029958.1'},
{value: 'aabr07029958.2', id: 'AABR07029958.2'},
{value: 'aabr07029970.1', id: 'AABR07029970.1'},
{value: 'aabr07029989.1', id: 'AABR07029989.1'},
{value: 'aabr07030019.1', id: 'AABR07030019.1'},
{value: 'aabr07030024.1', id: 'AABR07030024.1'},
{value: 'aabr07030029.1', id: 'AABR07030029.1'},
{value: 'aabr07030034.1', id: 'AABR07030034.1'},
{value: 'aabr07030039.1', id: 'AABR07030039.1'},
{value: 'aabr07030040.1', id: 'AABR07030040.1'},
{value: 'aabr07030050.1', id: 'AABR07030050.1'},
{value: 'aabr07030053.1', id: 'AABR07030053.1'},
{value: 'aabr07030072.1', id: 'AABR07030072.1'},
{value: 'aabr07030085.1', id: 'AABR07030085.1'},
{value: 'aabr07030085.2', id: 'AABR07030085.2'},
{value: 'aabr07030086.1', id: 'AABR07030086.1'},
{value: 'aabr07030086.2', id: 'AABR07030086.2'},
{value: 'aabr07030091.1', id: 'AABR07030091.1'},
{value: 'aabr07030094.1', id: 'AABR07030094.1'},
{value: 'aabr07030108.1', id: 'AABR07030108.1'},
{value: 'aabr07030108.2', id: 'AABR07030108.2'},
{value: 'aabr07030122.1', id: 'AABR07030122.1'},
{value: 'aabr07030133.1', id: 'AABR07030133.1'},
{value: 'aabr07030143.1', id: 'AABR07030143.1'},
{value: 'aabr07030145.1', id: 'AABR07030145.1'},
{value: 'aabr07030147.1', id: 'AABR07030147.1'},
{value: 'aabr07030156.1', id: 'AABR07030156.1'},
{value: 'aabr07030156.2', id: 'AABR07030156.2'},
{value: 'aabr07030162.1', id: 'AABR07030162.1'},
{value: 'aabr07030180.1', id: 'AABR07030180.1'},
{value: 'aabr07030184.1', id: 'AABR07030184.1'},
{value: 'aabr07030184.4', id: 'AABR07030184.4'},
{value: 'aabr07030200.1', id: 'AABR07030200.1'},
{value: 'aabr07030218.1', id: 'AABR07030218.1'},
{value: 'aabr07030221.1', id: 'AABR07030221.1'},
{value: 'aabr07030235.1', id: 'AABR07030235.1'},
{value: 'aabr07030257.1', id: 'AABR07030257.1'},
{value: 'aabr07030259.1', id: 'AABR07030259.1'},
{value: 'aabr07030259.2', id: 'AABR07030259.2'},
{value: 'aabr07030263.1', id: 'AABR07030263.1'},
{value: 'aabr07030265.1', id: 'AABR07030265.1'},
{value: 'aabr07030265.2', id: 'AABR07030265.2'},
{value: 'aabr07030334.1', id: 'AABR07030334.1'},
{value: 'aabr07030335.1', id: 'AABR07030335.1'},
{value: 'aabr07030351.1', id: 'AABR07030351.1'},
{value: 'aabr07030359.1', id: 'AABR07030359.1'},
{value: 'aabr07030363.1', id: 'AABR07030363.1'},
{value: 'aabr07030366.1', id: 'AABR07030366.1'},
{value: 'aabr07030375.1', id: 'AABR07030375.1'},
{value: 'aabr07030375.2', id: 'AABR07030375.2'},
{value: 'aabr07030378.1', id: 'AABR07030378.1'},
{value: 'aabr07030383.1', id: 'AABR07030383.1'},
{value: 'aabr07030386.2', id: 'AABR07030386.2'},
{value: 'aabr07030395.1', id: 'AABR07030395.1'},
{value: 'aabr07030423.1', id: 'AABR07030423.1'},
{value: 'aabr07030425.1', id: 'AABR07030425.1'},
{value: 'aabr07030435.1', id: 'AABR07030435.1'},
{value: 'aabr07030443.1', id: 'AABR07030443.1'},
{value: 'aabr07030450.1', id: 'AABR07030450.1'},
{value: 'aabr07030464.2', id: 'AABR07030464.2'},
{value: 'aabr07030469.1', id: 'AABR07030469.1'},
{value: 'aabr07030469.2', id: 'AABR07030469.2'},
{value: 'aabr07030469.3', id: 'AABR07030469.3'},
{value: 'aabr07030473.1', id: 'AABR07030473.1'},
{value: 'aabr07030482.1', id: 'AABR07030482.1'},
{value: 'aabr07030494.1', id: 'AABR07030494.1'},
{value: 'aabr07030498.1', id: 'AABR07030498.1'},
{value: 'aabr07030499.1', id: 'AABR07030499.1'},
{value: 'aabr07030501.1', id: 'AABR07030501.1'},
{value: 'aabr07030514.1', id: 'AABR07030514.1'},
{value: 'aabr07030514.2', id: 'AABR07030514.2'},
{value: 'aabr07030524.1', id: 'AABR07030524.1'},
{value: 'aabr07030527.1', id: 'AABR07030527.1'},
{value: 'aabr07030527.2', id: 'AABR07030527.2'},
{value: 'aabr07030528.1', id: 'AABR07030528.1'},
{value: 'aabr07030529.1', id: 'AABR07030529.1'},
{value: 'aabr07030543.1', id: 'AABR07030543.1'},
{value: 'aabr07030544.1', id: 'AABR07030544.1'},
{value: 'aabr07030563.1', id: 'AABR07030563.1'},
{value: 'aabr07030566.1', id: 'AABR07030566.1'},
{value: 'aabr07030566.2', id: 'AABR07030566.2'},
{value: 'aabr07030568.1', id: 'AABR07030568.1'},
{value: 'aabr07030568.2', id: 'AABR07030568.2'},
{value: 'aabr07030568.3', id: 'AABR07030568.3'},
{value: 'aabr07030568.4', id: 'AABR07030568.4'},
{value: 'aabr07030568.5', id: 'AABR07030568.5'},
{value: 'aabr07030568.6', id: 'AABR07030568.6'},
{value: 'aabr07030568.7', id: 'AABR07030568.7'},
{value: 'aabr07030568.8', id: 'AABR07030568.8'},
{value: 'aabr07030593.1', id: 'AABR07030593.1'},
{value: 'aabr07030598.1', id: 'AABR07030598.1'},
{value: 'aabr07030599.1', id: 'AABR07030599.1'},
{value: 'aabr07030603.1', id: 'AABR07030603.1'},
{value: 'aabr07030603.2', id: 'AABR07030603.2'},
{value: 'aabr07030607.1', id: 'AABR07030607.1'},
{value: 'aabr07030630.1', id: 'AABR07030630.1'},
{value: 'aabr07030642.1', id: 'AABR07030642.1'},
{value: 'aabr07030647.1', id: 'AABR07030647.1'},
{value: 'aabr07030657.1', id: 'AABR07030657.1'},
{value: 'aabr07030660.1', id: 'AABR07030660.1'},
{value: 'aabr07030674.1', id: 'AABR07030674.1'},
{value: 'aabr07030690.1', id: 'AABR07030690.1'},
{value: 'aabr07030690.2', id: 'AABR07030690.2'},
{value: 'aabr07030699.1', id: 'AABR07030699.1'},
{value: 'aabr07030706.1', id: 'AABR07030706.1'},
{value: 'aabr07030706.2', id: 'AABR07030706.2'},
{value: 'aabr07030714.1', id: 'AABR07030714.1'},
{value: 'aabr07030729.1', id: 'AABR07030729.1'},
{value: 'aabr07030739.1', id: 'AABR07030739.1'},
{value: 'aabr07030762.1', id: 'AABR07030762.1'},
{value: 'aabr07030771.1', id: 'AABR07030771.1'},
{value: 'aabr07030773.1', id: 'AABR07030773.1'},
{value: 'aabr07030779.1', id: 'AABR07030779.1'},
{value: 'aabr07030781.1', id: 'AABR07030781.1'},
{value: 'aabr07030791.1', id: 'AABR07030791.1'},
{value: 'aabr07030793.1', id: 'AABR07030793.1'},
{value: 'aabr07030793.2', id: 'AABR07030793.2'},
{value: 'aabr07030796.1', id: 'AABR07030796.1'},
{value: 'aabr07030823.1', id: 'AABR07030823.1'},
{value: 'aabr07030834.1', id: 'AABR07030834.1'},
{value: 'aabr07030841.1', id: 'AABR07030841.1'},
{value: 'aabr07030854.1', id: 'AABR07030854.1'},
{value: 'aabr07030861.1', id: 'AABR07030861.1'},
{value: 'aabr07030880.1', id: 'AABR07030880.1'},
{value: 'aabr07030890.1', id: 'AABR07030890.1'},
{value: 'aabr07030891.1', id: 'AABR07030891.1'},
{value: 'aabr07030894.1', id: 'AABR07030894.1'},
{value: 'aabr07030901.1', id: 'AABR07030901.1'},
{value: 'aabr07030903.1', id: 'AABR07030903.1'},
{value: 'aabr07030910.1', id: 'AABR07030910.1'},
{value: 'aabr07030911.1', id: 'AABR07030911.1'},
{value: 'aabr07030911.2', id: 'AABR07030911.2'},
{value: 'aabr07030914.1', id: 'AABR07030914.1'},
{value: 'aabr07030919.1', id: 'AABR07030919.1'},
{value: 'aabr07030921.1', id: 'AABR07030921.1'},
{value: 'aabr07030926.1', id: 'AABR07030926.1'},
{value: 'aabr07030946.1', id: 'AABR07030946.1'},
{value: 'aabr07030951.1', id: 'AABR07030951.1'},
{value: 'aabr07030977.1', id: 'AABR07030977.1'},
{value: 'aabr07030995.1', id: 'AABR07030995.1'},
{value: 'aabr07031019.1', id: 'AABR07031019.1'},
{value: 'aabr07031089.1', id: 'AABR07031089.1'},
{value: 'aabr07031138.1', id: 'AABR07031138.1'},
{value: 'aabr07031150.1', id: 'AABR07031150.1'},
{value: 'aabr07031154.1', id: 'AABR07031154.1'},
{value: 'aabr07031158.1', id: 'AABR07031158.1'},
{value: 'aabr07031158.2', id: 'AABR07031158.2'},
{value: 'aabr07031164.1', id: 'AABR07031164.1'},
{value: 'aabr07031165.1', id: 'AABR07031165.1'},
{value: 'aabr07031166.1', id: 'AABR07031166.1'},
{value: 'aabr07031168.1', id: 'AABR07031168.1'},
{value: 'aabr07031182.1', id: 'AABR07031182.1'},
{value: 'aabr07031184.1', id: 'AABR07031184.1'},
{value: 'aabr07031184.2', id: 'AABR07031184.2'},
{value: 'aabr07031221.1', id: 'AABR07031221.1'},
{value: 'aabr07031234.1', id: 'AABR07031234.1'},
{value: 'aabr07031239.1', id: 'AABR07031239.1'},
{value: 'aabr07031247.1', id: 'AABR07031247.1'},
{value: 'aabr07031251.1', id: 'AABR07031251.1'},
{value: 'aabr07031259.1', id: 'AABR07031259.1'},
{value: 'aabr07031272.1', id: 'AABR07031272.1'},
{value: 'aabr07031278.1', id: 'AABR07031278.1'},
{value: 'aabr07031296.1', id: 'AABR07031296.1'},
{value: 'aabr07031328.1', id: 'AABR07031328.1'},
{value: 'aabr07031347.1', id: 'AABR07031347.1'},
{value: 'aabr07031360.1', id: 'AABR07031360.1'},
{value: 'aabr07031380.1', id: 'AABR07031380.1'},
{value: 'aabr07031388.1', id: 'AABR07031388.1'},
{value: 'aabr07031402.1', id: 'AABR07031402.1'},
{value: 'aabr07031404.1', id: 'AABR07031404.1'},
{value: 'aabr07031405.1', id: 'AABR07031405.1'},
{value: 'aabr07031419.1', id: 'AABR07031419.1'},
{value: 'aabr07031445.1', id: 'AABR07031445.1'},
{value: 'aabr07031465.1', id: 'AABR07031465.1'},
{value: 'aabr07031480.1', id: 'AABR07031480.1'},
{value: 'aabr07031489.1', id: 'AABR07031489.1'},
{value: 'aabr07031491.1', id: 'AABR07031491.1'},
{value: 'aabr07031491.2', id: 'AABR07031491.2'},
{value: 'aabr07031494.1', id: 'AABR07031494.1'},
{value: 'aabr07031505.1', id: 'AABR07031505.1'},
{value: 'aabr07031518.1', id: 'AABR07031518.1'},
{value: 'aabr07031521.1', id: 'AABR07031521.1'},
{value: 'aabr07031521.2', id: 'AABR07031521.2'},
{value: 'aabr07031526.1', id: 'AABR07031526.1'},
{value: 'aabr07031533.1', id: 'AABR07031533.1'},
{value: 'aabr07031533.2', id: 'AABR07031533.2'},
{value: 'aabr07031533.3', id: 'AABR07031533.3'},
{value: 'aabr07031537.1', id: 'AABR07031537.1'},
{value: 'aabr07031538.1', id: 'AABR07031538.1'},
{value: 'aabr07031551.1', id: 'AABR07031551.1'},
{value: 'aabr07031574.1', id: 'AABR07031574.1'},
{value: 'aabr07031588.1', id: 'AABR07031588.1'},
{value: 'aabr07031590.1', id: 'AABR07031590.1'},
{value: 'aabr07031604.1', id: 'AABR07031604.1'},
{value: 'aabr07031611.1', id: 'AABR07031611.1'},
{value: 'aabr07031612.1', id: 'AABR07031612.1'},
{value: 'aabr07031634.1', id: 'AABR07031634.1'},
{value: 'aabr07031665.1', id: 'AABR07031665.1'},
{value: 'aabr07031666.1', id: 'AABR07031666.1'},
{value: 'aabr07031674.1', id: 'AABR07031674.1'},
{value: 'aabr07031674.2', id: 'AABR07031674.2'},
{value: 'aabr07031674.3', id: 'AABR07031674.3'},
{value: 'aabr07031674.4', id: 'AABR07031674.4'},
{value: 'aabr07031674.5', id: 'AABR07031674.5'},
{value: 'aabr07031674.6', id: 'AABR07031674.6'},
{value: 'aabr07031674.7', id: 'AABR07031674.7'},
{value: 'aabr07031675.1', id: 'AABR07031675.1'},
{value: 'aabr07031689.1', id: 'AABR07031689.1'},
{value: 'aabr07031691.1', id: 'AABR07031691.1'},
{value: 'aabr07031703.1', id: 'AABR07031703.1'},
{value: 'aabr07031724.1', id: 'AABR07031724.1'},
{value: 'aabr07031734.2', id: 'AABR07031734.2'},
{value: 'aabr07031734.9', id: 'AABR07031734.9'},
{value: 'aabr07031738.1', id: 'AABR07031738.1'},
{value: 'aabr07031740.1', id: 'AABR07031740.1'},
{value: 'aabr07031756.1', id: 'AABR07031756.1'},
{value: 'aabr07031756.2', id: 'AABR07031756.2'},
{value: 'aabr07031759.1', id: 'AABR07031759.1'},
{value: 'aabr07031762.1', id: 'AABR07031762.1'},
{value: 'aabr07031767.1', id: 'AABR07031767.1'},
{value: 'aabr07031768.1', id: 'AABR07031768.1'},
{value: 'aabr07031780.1', id: 'AABR07031780.1'},
{value: 'aabr07031787.1', id: 'AABR07031787.1'},
{value: 'aabr07031788.1', id: 'AABR07031788.1'},
{value: 'aabr07031931.1', id: 'AABR07031931.1'},
{value: 'aabr07031932.1', id: 'AABR07031932.1'},
{value: 'aabr07031937.1', id: 'AABR07031937.1'},
{value: 'aabr07031947.1', id: 'AABR07031947.1'},
{value: 'aabr07031947.2', id: 'AABR07031947.2'},
{value: 'aabr07031949.1', id: 'AABR07031949.1'},
{value: 'aabr07031952.1', id: 'AABR07031952.1'},
{value: 'aabr07031963.1', id: 'AABR07031963.1'},
{value: 'aabr07031972.1', id: 'AABR07031972.1'},
{value: 'aabr07031979.1', id: 'AABR07031979.1'},
{value: 'aabr07031983.1', id: 'AABR07031983.1'},
{value: 'aabr07031989.1', id: 'AABR07031989.1'},
{value: 'aabr07032057.1', id: 'AABR07032057.1'},
{value: 'aabr07032095.1', id: 'AABR07032095.1'},
{value: 'aabr07032097.1', id: 'AABR07032097.1'},
{value: 'aabr07032116.1', id: 'AABR07032116.1'},
{value: 'aabr07032119.1', id: 'AABR07032119.1'},
{value: 'aabr07032123.1', id: 'AABR07032123.1'},
{value: 'aabr07032123.2', id: 'AABR07032123.2'},
{value: 'aabr07032135.1', id: 'AABR07032135.1'},
{value: 'aabr07032141.1', id: 'AABR07032141.1'},
{value: 'aabr07032148.1', id: 'AABR07032148.1'},
{value: 'aabr07032155.1', id: 'AABR07032155.1'},
{value: 'aabr07032161.1', id: 'AABR07032161.1'},
{value: 'aabr07032161.2', id: 'AABR07032161.2'},
{value: 'aabr07032162.1', id: 'AABR07032162.1'},
{value: 'aabr07032171.1', id: 'AABR07032171.1'},
{value: 'aabr07032202.1', id: 'AABR07032202.1'},
{value: 'aabr07032229.1', id: 'AABR07032229.1'},
{value: 'aabr07032232.1', id: 'AABR07032232.1'},
{value: 'aabr07032255.1', id: 'AABR07032255.1'},
{value: 'aabr07032255.2', id: 'AABR07032255.2'},
{value: 'aabr07032261.1', id: 'AABR07032261.1'},
{value: 'aabr07032261.2', id: 'AABR07032261.2'},
{value: 'aabr07032261.3', id: 'AABR07032261.3'},
{value: 'aabr07032261.4', id: 'AABR07032261.4'},
{value: 'aabr07032265.1', id: 'AABR07032265.1'},
{value: 'aabr07032267.1', id: 'AABR07032267.1'},
{value: 'aabr07032277.1', id: 'AABR07032277.1'},
{value: 'aabr07032277.2', id: 'AABR07032277.2'},
{value: 'aabr07032277.3', id: 'AABR07032277.3'},
{value: 'aabr07032289.1', id: 'AABR07032289.1'},
{value: 'aabr07032317.1', id: 'AABR07032317.1'},
{value: 'aabr07032320.1', id: 'AABR07032320.1'},
{value: 'aabr07032327.1', id: 'AABR07032327.1'},
{value: 'aabr07032328.1', id: 'AABR07032328.1'},
{value: 'aabr07032343.1', id: 'AABR07032343.1'},
{value: 'aabr07032348.1', id: 'AABR07032348.1'},
{value: 'aabr07032348.2', id: 'AABR07032348.2'},
{value: 'aabr07032361.1', id: 'AABR07032361.1'},
{value: 'aabr07032373.1', id: 'AABR07032373.1'},
{value: 'aabr07032385.2', id: 'AABR07032385.2'},
{value: 'aabr07032392.1', id: 'AABR07032392.1'},
{value: 'aabr07032393.1', id: 'AABR07032393.1'},
{value: 'aabr07032407.1', id: 'AABR07032407.1'},
{value: 'aabr07032413.1', id: 'AABR07032413.1'},
{value: 'aabr07032417.1', id: 'AABR07032417.1'},
{value: 'aabr07032422.1', id: 'AABR07032422.1'},
{value: 'aabr07032439.1', id: 'AABR07032439.1'},
{value: 'aabr07032441.1', id: 'AABR07032441.1'},
{value: 'aabr07032453.1', id: 'AABR07032453.1'},
{value: 'aabr07032457.1', id: 'AABR07032457.1'},
{value: 'aabr07032469.1', id: 'AABR07032469.1'},
{value: 'aabr07032480.1', id: 'AABR07032480.1'},
{value: 'aabr07032496.1', id: 'AABR07032496.1'},
{value: 'aabr07032503.1', id: 'AABR07032503.1'},
{value: 'aabr07032518.1', id: 'AABR07032518.1'},
{value: 'aabr07032520.1', id: 'AABR07032520.1'},
{value: 'aabr07032520.2', id: 'AABR07032520.2'},
{value: 'aabr07032520.3', id: 'AABR07032520.3'},
{value: 'aabr07032520.4', id: 'AABR07032520.4'},
{value: 'aabr07032523.1', id: 'AABR07032523.1'},
{value: 'aabr07032525.1', id: 'AABR07032525.1'},
{value: 'aabr07032532.1', id: 'AABR07032532.1'},
{value: 'aabr07032532.2', id: 'AABR07032532.2'},
{value: 'aabr07032563.1', id: 'AABR07032563.1'},
{value: 'aabr07032563.2', id: 'AABR07032563.2'},
{value: 'aabr07032582.1', id: 'AABR07032582.1'},
{value: 'aabr07032590.1', id: 'AABR07032590.1'},
{value: 'aabr07032593.1', id: 'AABR07032593.1'},
{value: 'aabr07032593.2', id: 'AABR07032593.2'},
{value: 'aabr07032600.1', id: 'AABR07032600.1'},
{value: 'aabr07032621.1', id: 'AABR07032621.1'},
{value: 'aabr07032622.1', id: 'AABR07032622.1'},
{value: 'aabr07032623.1', id: 'AABR07032623.1'},
{value: 'aabr07032640.1', id: 'AABR07032640.1'},
{value: 'aabr07032642.1', id: 'AABR07032642.1'},
{value: 'aabr07032643.1', id: 'AABR07032643.1'},
{value: 'aabr07032688.1', id: 'AABR07032688.1'},
{value: 'aabr07032724.1', id: 'AABR07032724.1'},
{value: 'aabr07032724.2', id: 'AABR07032724.2'},
{value: 'aabr07032724.3', id: 'AABR07032724.3'},
{value: 'aabr07032747.1', id: 'AABR07032747.1'},
{value: 'aabr07032747.2', id: 'AABR07032747.2'},
{value: 'aabr07032748.1', id: 'AABR07032748.1'},
{value: 'aabr07032750.1', id: 'AABR07032750.1'},
{value: 'aabr07032751.1', id: 'AABR07032751.1'},
{value: 'aabr07032758.1', id: 'AABR07032758.1'},
{value: 'aabr07032758.2', id: 'AABR07032758.2'},
{value: 'aabr07032759.1', id: 'AABR07032759.1'},
{value: 'aabr07032786.1', id: 'AABR07032786.1'},
{value: 'aabr07032787.1', id: 'AABR07032787.1'},
{value: 'aabr07032806.1', id: 'AABR07032806.1'},
{value: 'aabr07032816.1', id: 'AABR07032816.1'},
{value: 'aabr07032821.2', id: 'AABR07032821.2'},
{value: 'aabr07032834.1', id: 'AABR07032834.1'},
{value: 'aabr07032843.1', id: 'AABR07032843.1'},
{value: 'aabr07032851.1', id: 'AABR07032851.1'},
{value: 'aabr07032856.1', id: 'AABR07032856.1'},
{value: 'aabr07032862.1', id: 'AABR07032862.1'},
{value: 'aabr07032888.1', id: 'AABR07032888.1'},
{value: 'aabr07032904.1', id: 'AABR07032904.1'},
{value: 'aabr07033020.1', id: 'AABR07033020.1'},
{value: 'aabr07033023.1', id: 'AABR07033023.1'},
{value: 'aabr07033027.1', id: 'AABR07033027.1'},
{value: 'aabr07033031.1', id: 'AABR07033031.1'},
{value: 'aabr07033045.1', id: 'AABR07033045.1'},
{value: 'aabr07033047.1', id: 'AABR07033047.1'},
{value: 'aabr07033056.1', id: 'AABR07033056.1'},
{value: 'aabr07033090.1', id: 'AABR07033090.1'},
{value: 'aabr07033145.1', id: 'AABR07033145.1'},
{value: 'aabr07033162.1', id: 'AABR07033162.1'},
{value: 'aabr07033184.1', id: 'AABR07033184.1'},
{value: 'aabr07033206.1', id: 'AABR07033206.1'},
{value: 'aabr07033229.1', id: 'AABR07033229.1'},
{value: 'aabr07033234.1', id: 'AABR07033234.1'},
{value: 'aabr07033234.2', id: 'AABR07033234.2'},
{value: 'aabr07033236.1', id: 'AABR07033236.1'},
{value: 'aabr07033249.1', id: 'AABR07033249.1'},
{value: 'aabr07033270.1', id: 'AABR07033270.1'},
{value: 'aabr07033271.1', id: 'AABR07033271.1'},
{value: 'aabr07033274.1', id: 'AABR07033274.1'},
{value: 'aabr07033283.1', id: 'AABR07033283.1'},
{value: 'aabr07033285.1', id: 'AABR07033285.1'},
{value: 'aabr07033287.1', id: 'AABR07033287.1'},
{value: 'aabr07033292.1', id: 'AABR07033292.1'},
{value: 'aabr07033301.1', id: 'AABR07033301.1'},
{value: 'aabr07033303.1', id: 'AABR07033303.1'},
{value: 'aabr07033318.1', id: 'AABR07033318.1'},
{value: 'aabr07033324.1', id: 'AABR07033324.1'},
{value: 'aabr07033357.1', id: 'AABR07033357.1'},
{value: 'aabr07033366.1', id: 'AABR07033366.1'},
{value: 'aabr07033404.1', id: 'AABR07033404.1'},
{value: 'aabr07033416.1', id: 'AABR07033416.1'},
{value: 'aabr07033465.1', id: 'AABR07033465.1'},
{value: 'aabr07033470.1', id: 'AABR07033470.1'},
{value: 'aabr07033484.1', id: 'AABR07033484.1'},
{value: 'aabr07033492.1', id: 'AABR07033492.1'},
{value: 'aabr07033524.1', id: 'AABR07033524.1'},
{value: 'aabr07033525.1', id: 'AABR07033525.1'},
{value: 'aabr07033537.1', id: 'AABR07033537.1'},
{value: 'aabr07033540.1', id: 'AABR07033540.1'},
{value: 'aabr07033552.1', id: 'AABR07033552.1'},
{value: 'aabr07033564.1', id: 'AABR07033564.1'},
{value: 'aabr07033570.1', id: 'AABR07033570.1'},
{value: 'aabr07033579.1', id: 'AABR07033579.1'},
{value: 'aabr07033579.2', id: 'AABR07033579.2'},
{value: 'aabr07033579.3', id: 'AABR07033579.3'},
{value: 'aabr07033580.1', id: 'AABR07033580.1'},
{value: 'aabr07033590.1', id: 'AABR07033590.1'},
{value: 'aabr07033607.1', id: 'AABR07033607.1'},
{value: 'aabr07033607.2', id: 'AABR07033607.2'},
{value: 'aabr07033612.1', id: 'AABR07033612.1'},
{value: 'aabr07033645.1', id: 'AABR07033645.1'},
{value: 'aabr07033653.1', id: 'AABR07033653.1'},
{value: 'aabr07033653.2', id: 'AABR07033653.2'},
{value: 'aabr07033653.3', id: 'AABR07033653.3'},
{value: 'aabr07033653.4', id: 'AABR07033653.4'},
{value: 'aabr07033666.1', id: 'AABR07033666.1'},
{value: 'aabr07033688.1', id: 'AABR07033688.1'},
{value: 'aabr07033697.1', id: 'AABR07033697.1'},
{value: 'aabr07033697.2', id: 'AABR07033697.2'},
{value: 'aabr07033720.1', id: 'AABR07033720.1'},
{value: 'aabr07033730.1', id: 'AABR07033730.1'},
{value: 'aabr07033745.1', id: 'AABR07033745.1'},
{value: 'aabr07033745.2', id: 'AABR07033745.2'},
{value: 'aabr07033757.1', id: 'AABR07033757.1'},
{value: 'aabr07033759.1', id: 'AABR07033759.1'},
{value: 'aabr07033819.1', id: 'AABR07033819.1'},
{value: 'aabr07033829.1', id: 'AABR07033829.1'},
{value: 'aabr07033836.1', id: 'AABR07033836.1'},
{value: 'aabr07033850.1', id: 'AABR07033850.1'},
{value: 'aabr07033850.2', id: 'AABR07033850.2'},
{value: 'aabr07033851.1', id: 'AABR07033851.1'},
{value: 'aabr07033851.2', id: 'AABR07033851.2'},
{value: 'aabr07033854.1', id: 'AABR07033854.1'},
{value: 'aabr07033856.1', id: 'AABR07033856.1'},
{value: 'aabr07033859.1', id: 'AABR07033859.1'},
{value: 'aabr07033867.1', id: 'AABR07033867.1'},
{value: 'aabr07033867.2', id: 'AABR07033867.2'},
{value: 'aabr07033869.1', id: 'AABR07033869.1'},
{value: 'aabr07033882.1', id: 'AABR07033882.1'},
{value: 'aabr07033887.1', id: 'AABR07033887.1'},
{value: 'aabr07033911.1', id: 'AABR07033911.1'},
{value: 'aabr07033920.1', id: 'AABR07033920.1'},
{value: 'aabr07033921.1', id: 'AABR07033921.1'},
{value: 'aabr07033925.1', id: 'AABR07033925.1'},
{value: 'aabr07033925.2', id: 'AABR07033925.2'},
{value: 'aabr07033943.1', id: 'AABR07033943.1'},
{value: 'aabr07033987.1', id: 'AABR07033987.1'},
{value: 'aabr07034008.1', id: 'AABR07034008.1'},
{value: 'aabr07034038.1', id: 'AABR07034038.1'},
{value: 'aabr07034038.2', id: 'AABR07034038.2'},
{value: 'aabr07034058.1', id: 'AABR07034058.1'},
{value: 'aabr07034059.1', id: 'AABR07034059.1'},
{value: 'aabr07034073.1', id: 'AABR07034073.1'},
{value: 'aabr07034098.1', id: 'AABR07034098.1'},
{value: 'aabr07034100.1', id: 'AABR07034100.1'},
{value: 'aabr07034111.1', id: 'AABR07034111.1'},
{value: 'aabr07034125.1', id: 'AABR07034125.1'},
{value: 'aabr07034131.1', id: 'AABR07034131.1'},
{value: 'aabr07034183.1', id: 'AABR07034183.1'},
{value: 'aabr07034208.1', id: 'AABR07034208.1'},
{value: 'aabr07034239.1', id: 'AABR07034239.1'},
{value: 'aabr07034249.1', id: 'AABR07034249.1'},
{value: 'aabr07034262.1', id: 'AABR07034262.1'},
{value: 'aabr07034263.1', id: 'AABR07034263.1'},
{value: 'aabr07034293.1', id: 'AABR07034293.1'},
{value: 'aabr07034293.2', id: 'AABR07034293.2'},
{value: 'aabr07034303.1', id: 'AABR07034303.1'},
{value: 'aabr07034310.1', id: 'AABR07034310.1'},
{value: 'aabr07034315.1', id: 'AABR07034315.1'},
{value: 'aabr07034324.1', id: 'AABR07034324.1'},
{value: 'aabr07034328.1', id: 'AABR07034328.1'},
{value: 'aabr07034329.1', id: 'AABR07034329.1'},
{value: 'aabr07034362.1', id: 'AABR07034362.1'},
{value: 'aabr07034362.2', id: 'AABR07034362.2'},
{value: 'aabr07034393.1', id: 'AABR07034393.1'},
{value: 'aabr07034393.2', id: 'AABR07034393.2'},
{value: 'aabr07034396.1', id: 'AABR07034396.1'},
{value: 'aabr07034404.1', id: 'AABR07034404.1'},
{value: 'aabr07034438.1', id: 'AABR07034438.1'},
{value: 'aabr07034445.1', id: 'AABR07034445.1'},
{value: 'aabr07034455.1', id: 'AABR07034455.1'},
{value: 'aabr07034455.2', id: 'AABR07034455.2'},
{value: 'aabr07034456.1', id: 'AABR07034456.1'},
{value: 'aabr07034457.1', id: 'AABR07034457.1'},
{value: 'aabr07034463.1', id: 'AABR07034463.1'},
{value: 'aabr07034464.1', id: 'AABR07034464.1'},
{value: 'aabr07034502.1', id: 'AABR07034502.1'},
{value: 'aabr07034502.2', id: 'AABR07034502.2'},
{value: 'aabr07034544.1', id: 'AABR07034544.1'},
{value: 'aabr07034550.1', id: 'AABR07034550.1'},
{value: 'aabr07034573.1', id: 'AABR07034573.1'},
{value: 'aabr07034573.2', id: 'AABR07034573.2'},
{value: 'aabr07034573.3', id: 'AABR07034573.3'},
{value: 'aabr07034574.1', id: 'AABR07034574.1'},
{value: 'aabr07034586.1', id: 'AABR07034586.1'},
{value: 'aabr07034592.1', id: 'AABR07034592.1'},
{value: 'aabr07034598.1', id: 'AABR07034598.1'},
{value: 'aabr07034600.1', id: 'AABR07034600.1'},
{value: 'aabr07034610.1', id: 'AABR07034610.1'},
{value: 'aabr07034632.1', id: 'AABR07034632.1'},
{value: 'aabr07034636.1', id: 'AABR07034636.1'},
{value: 'aabr07034639.1', id: 'AABR07034639.1'},
{value: 'aabr07034641.1', id: 'AABR07034641.1'},
{value: 'aabr07034648.1', id: 'AABR07034648.1'},
{value: 'aabr07034657.1', id: 'AABR07034657.1'},
{value: 'aabr07034669.1', id: 'AABR07034669.1'},
{value: 'aabr07034669.2', id: 'AABR07034669.2'},
{value: 'aabr07034673.1', id: 'AABR07034673.1'},
{value: 'aabr07034679.1', id: 'AABR07034679.1'},
{value: 'aabr07034706.1', id: 'AABR07034706.1'},
{value: 'aabr07034718.1', id: 'AABR07034718.1'},
{value: 'aabr07034719.1', id: 'AABR07034719.1'},
{value: 'aabr07034729.1', id: 'AABR07034729.1'},
{value: 'aabr07034729.2', id: 'AABR07034729.2'},
{value: 'aabr07034730.1', id: 'AABR07034730.1'},
{value: 'aabr07034730.2', id: 'AABR07034730.2'},
{value: 'aabr07034730.3', id: 'AABR07034730.3'},
{value: 'aabr07034732.1', id: 'AABR07034732.1'},
{value: 'aabr07034736.1', id: 'AABR07034736.1'},
{value: 'aabr07034739.1', id: 'AABR07034739.1'},
{value: 'aabr07034739.2', id: 'AABR07034739.2'},
{value: 'aabr07034742.1', id: 'AABR07034742.1'},
{value: 'aabr07034745.1', id: 'AABR07034745.1'},
{value: 'aabr07034748.1', id: 'AABR07034748.1'},
{value: 'aabr07034750.1', id: 'AABR07034750.1'},
{value: 'aabr07034751.1', id: 'AABR07034751.1'},
{value: 'aabr07034756.1', id: 'AABR07034756.1'},
{value: 'aabr07034767.1', id: 'AABR07034767.1'},
{value: 'aabr07034833.1', id: 'AABR07034833.1'},
{value: 'aabr07034833.2', id: 'AABR07034833.2'},
{value: 'aabr07034923.1', id: 'AABR07034923.1'},
{value: 'aabr07034926.1', id: 'AABR07034926.1'},
{value: 'aabr07034928.1', id: 'AABR07034928.1'},
{value: 'aabr07034940.2', id: 'AABR07034940.2'},
{value: 'aabr07034956.1', id: 'AABR07034956.1'},
{value: 'aabr07034964.1', id: 'AABR07034964.1'},
{value: 'aabr07034980.1', id: 'AABR07034980.1'},
{value: 'aabr07034986.1', id: 'AABR07034986.1'},
{value: 'aabr07034991.1', id: 'AABR07034991.1'},
{value: 'aabr07034992.1', id: 'AABR07034992.1'},
{value: 'aabr07035005.1', id: 'AABR07035005.1'},
{value: 'aabr07035008.1', id: 'AABR07035008.1'},
{value: 'aabr07035008.2', id: 'AABR07035008.2'},
{value: 'aabr07035008.3', id: 'AABR07035008.3'},
{value: 'aabr07035012.1', id: 'AABR07035012.1'},
{value: 'aabr07035064.1', id: 'AABR07035064.1'},
{value: 'aabr07035083.1', id: 'AABR07035083.1'},
{value: 'aabr07035089.1', id: 'AABR07035089.1'},
{value: 'aabr07035091.1', id: 'AABR07035091.1'},
{value: 'aabr07035098.1', id: 'AABR07035098.1'},
{value: 'aabr07035175.1', id: 'AABR07035175.1'},
{value: 'aabr07035182.1', id: 'AABR07035182.1'},
{value: 'aabr07035185.1', id: 'AABR07035185.1'},
{value: 'aabr07035190.1', id: 'AABR07035190.1'},
{value: 'aabr07035193.1', id: 'AABR07035193.1'},
{value: 'aabr07035194.1', id: 'AABR07035194.1'},
{value: 'aabr07035203.1', id: 'AABR07035203.1'},
{value: 'aabr07035213.1', id: 'AABR07035213.1'},
{value: 'aabr07035218.1', id: 'AABR07035218.1'},
{value: 'aabr07035218.2', id: 'AABR07035218.2'},
{value: 'aabr07035224.1', id: 'AABR07035224.1'},
{value: 'aabr07035260.1', id: 'AABR07035260.1'},
{value: 'aabr07035267.1', id: 'AABR07035267.1'},
{value: 'aabr07035273.1', id: 'AABR07035273.1'},
{value: 'aabr07035307.1', id: 'AABR07035307.1'},
{value: 'aabr07035309.1', id: 'AABR07035309.1'},
{value: 'aabr07035317.1', id: 'AABR07035317.1'},
{value: 'aabr07035317.2', id: 'AABR07035317.2'},
{value: 'aabr07035338.1', id: 'AABR07035338.1'},
{value: 'aabr07035339.1', id: 'AABR07035339.1'},
{value: 'aabr07035343.1', id: 'AABR07035343.1'},
{value: 'aabr07035344.1', id: 'AABR07035344.1'},
{value: 'aabr07035345.1', id: 'AABR07035345.1'},
{value: 'aabr07035348.1', id: 'AABR07035348.1'},
{value: 'aabr07035357.2', id: 'AABR07035357.2'},
{value: 'aabr07035361.1', id: 'AABR07035361.1'},
{value: 'aabr07035366.1', id: 'AABR07035366.1'},
{value: 'aabr07035368.1', id: 'AABR07035368.1'},
{value: 'aabr07035368.2', id: 'AABR07035368.2'},
{value: 'aabr07035374.1', id: 'AABR07035374.1'},
{value: 'aabr07035375.2', id: 'AABR07035375.2'},
{value: 'aabr07035380.1', id: 'AABR07035380.1'},
{value: 'aabr07035380.2', id: 'AABR07035380.2'},
{value: 'aabr07035382.1', id: 'AABR07035382.1'},
{value: 'aabr07035383.1', id: 'AABR07035383.1'},
{value: 'aabr07035383.2', id: 'AABR07035383.2'},
{value: 'aabr07035394.1', id: 'AABR07035394.1'},
{value: 'aabr07035394.2', id: 'AABR07035394.2'},
{value: 'aabr07035412.1', id: 'AABR07035412.1'},
{value: 'aabr07035412.2', id: 'AABR07035412.2'},
{value: 'aabr07035427.1', id: 'AABR07035427.1'},
{value: 'aabr07035428.1', id: 'AABR07035428.1'},
{value: 'aabr07035428.2', id: 'AABR07035428.2'},
{value: 'aabr07035428.3', id: 'AABR07035428.3'},
{value: 'aabr07035456.1', id: 'AABR07035456.1'},
{value: 'aabr07035461.1', id: 'AABR07035461.1'},
{value: 'aabr07035465.1', id: 'AABR07035465.1'},
{value: 'aabr07035471.1', id: 'AABR07035471.1'},
{value: 'aabr07035475.1', id: 'AABR07035475.1'},
{value: 'aabr07035478.1', id: 'AABR07035478.1'},
{value: 'aabr07035479.1', id: 'AABR07035479.1'},
{value: 'aabr07035486.1', id: 'AABR07035486.1'},
{value: 'aabr07035501.1', id: 'AABR07035501.1'},
{value: 'aabr07035503.2', id: 'AABR07035503.2'},
{value: 'aabr07035506.1', id: 'AABR07035506.1'},
{value: 'aabr07035541.2', id: 'AABR07035541.2'},
{value: 'aabr07035541.3', id: 'AABR07035541.3'},
{value: 'aabr07035561.1', id: 'AABR07035561.1'},
{value: 'aabr07035561.2', id: 'AABR07035561.2'},
{value: 'aabr07035561.3', id: 'AABR07035561.3'},
{value: 'aabr07035622.1', id: 'AABR07035622.1'},
{value: 'aabr07035650.1', id: 'AABR07035650.1'},
{value: 'aabr07035650.2', id: 'AABR07035650.2'},
{value: 'aabr07035717.1', id: 'AABR07035717.1'},
{value: 'aabr07035725.1', id: 'AABR07035725.1'},
{value: 'aabr07035778.1', id: 'AABR07035778.1'},
{value: 'aabr07035778.2', id: 'AABR07035778.2'},
{value: 'aabr07035779.1', id: 'AABR07035779.1'},
{value: 'aabr07035779.2', id: 'AABR07035779.2'},
{value: 'aabr07035780.1', id: 'AABR07035780.1'},
{value: 'aabr07035780.2', id: 'AABR07035780.2'},
{value: 'aabr07035780.3', id: 'AABR07035780.3'},
{value: 'aabr07035780.4', id: 'AABR07035780.4'},
{value: 'aabr07035780.5', id: 'AABR07035780.5'},
{value: 'aabr07035780.6', id: 'AABR07035780.6'},
{value: 'aabr07035782.1', id: 'AABR07035782.1'},
{value: 'aabr07035787.1', id: 'AABR07035787.1'},
{value: 'aabr07035790.2', id: 'AABR07035790.2'},
{value: 'aabr07035791.1', id: 'AABR07035791.1'},
{value: 'aabr07035796.1', id: 'AABR07035796.1'},
{value: 'aabr07035802.1', id: 'AABR07035802.1'},
{value: 'aabr07035813.1', id: 'AABR07035813.1'},
{value: 'aabr07035818.1', id: 'AABR07035818.1'},
{value: 'aabr07035832.1', id: 'AABR07035832.1'},
{value: 'aabr07035835.1', id: 'AABR07035835.1'},
{value: 'aabr07035839.1', id: 'AABR07035839.1'},
{value: 'aabr07035864.1', id: 'AABR07035864.1'},
{value: 'aabr07035868.1', id: 'AABR07035868.1'},
{value: 'aabr07035888.1', id: 'AABR07035888.1'},
{value: 'aabr07035926.1', id: 'AABR07035926.1'},
{value: 'aabr07035926.2', id: 'AABR07035926.2'},
{value: 'aabr07035955.1', id: 'AABR07035955.1'},
{value: 'aabr07036007.1', id: 'AABR07036007.1'},
{value: 'aabr07036010.3', id: 'AABR07036010.3'},
{value: 'aabr07036010.4', id: 'AABR07036010.4'},
{value: 'aabr07036011.1', id: 'AABR07036011.1'},
{value: 'aabr07036016.1', id: 'AABR07036016.1'},
{value: 'aabr07036022.1', id: 'AABR07036022.1'},
{value: 'aabr07036024.1', id: 'AABR07036024.1'},
{value: 'aabr07036024.2', id: 'AABR07036024.2'},
{value: 'aabr07036066.1', id: 'AABR07036066.1'},
{value: 'aabr07036067.1', id: 'AABR07036067.1'},
{value: 'aabr07036074.1', id: 'AABR07036074.1'},
{value: 'aabr07036080.1', id: 'AABR07036080.1'},
{value: 'aabr07036080.2', id: 'AABR07036080.2'},
{value: 'aabr07036084.1', id: 'AABR07036084.1'},
{value: 'aabr07036089.1', id: 'AABR07036089.1'},
{value: 'aabr07036101.1', id: 'AABR07036101.1'},
{value: 'aabr07036119.1', id: 'AABR07036119.1'},
{value: 'aabr07036167.1', id: 'AABR07036167.1'},
{value: 'aabr07036187.1', id: 'AABR07036187.1'},
{value: 'aabr07036301.1', id: 'AABR07036301.1'},
{value: 'aabr07036302.2', id: 'AABR07036302.2'},
{value: 'aabr07036304.1', id: 'AABR07036304.1'},
{value: 'aabr07036312.1', id: 'AABR07036312.1'},
{value: 'aabr07036318.1', id: 'AABR07036318.1'},
{value: 'aabr07036324.1', id: 'AABR07036324.1'},
{value: 'aabr07036331.1', id: 'AABR07036331.1'},
{value: 'aabr07036336.1', id: 'AABR07036336.1'},
{value: 'aabr07036336.2', id: 'AABR07036336.2'},
{value: 'aabr07036374.1', id: 'AABR07036374.1'},
{value: 'aabr07036375.1', id: 'AABR07036375.1'},
{value: 'aabr07036376.1', id: 'AABR07036376.1'},
{value: 'aabr07036391.1', id: 'AABR07036391.1'},
{value: 'aabr07036406.1', id: 'AABR07036406.1'},
{value: 'aabr07036406.2', id: 'AABR07036406.2'},
{value: 'aabr07036407.1', id: 'AABR07036407.1'},
{value: 'aabr07036407.2', id: 'AABR07036407.2'},
{value: 'aabr07036411.1', id: 'AABR07036411.1'},
{value: 'aabr07036413.1', id: 'AABR07036413.1'},
{value: 'aabr07036417.1', id: 'AABR07036417.1'},
{value: 'aabr07036426.1', id: 'AABR07036426.1'},
{value: 'aabr07036435.1', id: 'AABR07036435.1'},
{value: 'aabr07036436.1', id: 'AABR07036436.1'},
{value: 'aabr07036442.1', id: 'AABR07036442.1'},
{value: 'aabr07036452.1', id: 'AABR07036452.1'},
{value: 'aabr07036463.1', id: 'AABR07036463.1'},
{value: 'aabr07036505.1', id: 'AABR07036505.1'},
{value: 'aabr07036507.1', id: 'AABR07036507.1'},
{value: 'aabr07036509.1', id: 'AABR07036509.1'},
{value: 'aabr07036514.1', id: 'AABR07036514.1'},
{value: 'aabr07036526.1', id: 'AABR07036526.1'},
{value: 'aabr07036531.1', id: 'AABR07036531.1'},
{value: 'aabr07036532.1', id: 'AABR07036532.1'},
{value: 'aabr07036542.1', id: 'AABR07036542.1'},
{value: 'aabr07036550.1', id: 'AABR07036550.1'},
{value: 'aabr07036556.1', id: 'AABR07036556.1'},
{value: 'aabr07036567.1', id: 'AABR07036567.1'},
{value: 'aabr07036623.1', id: 'AABR07036623.1'},
{value: 'aabr07036626.1', id: 'AABR07036626.1'},
{value: 'aabr07036627.1', id: 'AABR07036627.1'},
{value: 'aabr07036637.1', id: 'AABR07036637.1'},
{value: 'aabr07036638.1', id: 'AABR07036638.1'},
{value: 'aabr07036640.1', id: 'AABR07036640.1'},
{value: 'aabr07036648.1', id: 'AABR07036648.1'},
{value: 'aabr07036649.1', id: 'AABR07036649.1'},
{value: 'aabr07036679.1', id: 'AABR07036679.1'},
{value: 'aabr07036698.1', id: 'AABR07036698.1'},
{value: 'aabr07036711.1', id: 'AABR07036711.1'},
{value: 'aabr07036723.1', id: 'AABR07036723.1'},
{value: 'aabr07036746.1', id: 'AABR07036746.1'},
{value: 'aabr07036780.1', id: 'AABR07036780.1'},
{value: 'aabr07036855.1', id: 'AABR07036855.1'},
{value: 'aabr07036920.1', id: 'AABR07036920.1'},
{value: 'aabr07036964.1', id: 'AABR07036964.1'},
{value: 'aabr07036972.1', id: 'AABR07036972.1'},
{value: 'aabr07036974.1', id: 'AABR07036974.1'},
{value: 'aabr07037054.1', id: 'AABR07037054.1'},
{value: 'aabr07037058.1', id: 'AABR07037058.1'},
{value: 'aabr07037073.1', id: 'AABR07037073.1'},
{value: 'aabr07037073.2', id: 'AABR07037073.2'},
{value: 'aabr07037104.1', id: 'AABR07037104.1'},
{value: 'aabr07037112.1', id: 'AABR07037112.1'},
{value: 'aabr07037151.1', id: 'AABR07037151.1'},
{value: 'aabr07037161.1', id: 'AABR07037161.1'},
{value: 'aabr07037176.1', id: 'AABR07037176.1'},
{value: 'aabr07037203.1', id: 'AABR07037203.1'},
{value: 'aabr07037234.1', id: 'AABR07037234.1'},
{value: 'aabr07037243.1', id: 'AABR07037243.1'},
{value: 'aabr07037270.1', id: 'AABR07037270.1'},
{value: 'aabr07037302.1', id: 'AABR07037302.1'},
{value: 'aabr07037307.1', id: 'AABR07037307.1'},
{value: 'aabr07037343.1', id: 'AABR07037343.1'},
{value: 'aabr07037347.1', id: 'AABR07037347.1'},
{value: 'aabr07037395.1', id: 'AABR07037395.1'},
{value: 'aabr07037395.2', id: 'AABR07037395.2'},
{value: 'aabr07037406.1', id: 'AABR07037406.1'},
{value: 'aabr07037407.1', id: 'AABR07037407.1'},
{value: 'aabr07037451.1', id: 'AABR07037451.1'},
{value: 'aabr07037453.1', id: 'AABR07037453.1'},
{value: 'aabr07037465.1', id: 'AABR07037465.1'},
{value: 'aabr07037477.1', id: 'AABR07037477.1'},
{value: 'aabr07037477.2', id: 'AABR07037477.2'},
{value: 'aabr07037489.1', id: 'AABR07037489.1'},
{value: 'aabr07037510.1', id: 'AABR07037510.1'},
{value: 'aabr07037510.2', id: 'AABR07037510.2'},
{value: 'aabr07037520.1', id: 'AABR07037520.1'},
{value: 'aabr07037523.1', id: 'AABR07037523.1'},
{value: 'aabr07037523.2', id: 'AABR07037523.2'},
{value: 'aabr07037528.1', id: 'AABR07037528.1'},
{value: 'aabr07037545.1', id: 'AABR07037545.1'},
{value: 'aabr07037553.1', id: 'AABR07037553.1'},
{value: 'aabr07037590.1', id: 'AABR07037590.1'},
{value: 'aabr07037607.1', id: 'AABR07037607.1'},
{value: 'aabr07037608.1', id: 'AABR07037608.1'},
{value: 'aabr07037645.1', id: 'AABR07037645.1'},
{value: 'aabr07037698.1', id: 'AABR07037698.1'},
{value: 'aabr07037715.1', id: 'AABR07037715.1'},
{value: 'aabr07037738.1', id: 'AABR07037738.1'},
{value: 'aabr07037798.1', id: 'AABR07037798.1'},
{value: 'aabr07037800.1', id: 'AABR07037800.1'},
{value: 'aabr07037847.1', id: 'AABR07037847.1'},
{value: 'aabr07037850.1', id: 'AABR07037850.1'},
{value: 'aabr07037854.1', id: 'AABR07037854.1'},
{value: 'aabr07037854.2', id: 'AABR07037854.2'},
{value: 'aabr07037878.1', id: 'AABR07037878.1'},
{value: 'aabr07037878.2', id: 'AABR07037878.2'},
{value: 'aabr07037896.1', id: 'AABR07037896.1'},
{value: 'aabr07037897.1', id: 'AABR07037897.1'},
{value: 'aabr07037899.1', id: 'AABR07037899.1'},
{value: 'aabr07037923.1', id: 'AABR07037923.1'},
{value: 'aabr07037925.1', id: 'AABR07037925.1'},
{value: 'aabr07037927.1', id: 'AABR07037927.1'},
{value: 'aabr07037943.1', id: 'AABR07037943.1'},
{value: 'aabr07037960.1', id: 'AABR07037960.1'},
{value: 'aabr07037995.1', id: 'AABR07037995.1'},
{value: 'aabr07037998.1', id: 'AABR07037998.1'},
{value: 'aabr07038019.1', id: 'AABR07038019.1'},
{value: 'aabr07038019.2', id: 'AABR07038019.2'},
{value: 'aabr07038021.1', id: 'AABR07038021.1'},
{value: 'aabr07038029.1', id: 'AABR07038029.1'},
{value: 'aabr07038029.2', id: 'AABR07038029.2'},
{value: 'aabr07038114.1', id: 'AABR07038114.1'},
{value: 'aabr07038174.1', id: 'AABR07038174.1'},
{value: 'aabr07038219.1', id: 'AABR07038219.1'},
{value: 'aabr07038250.1', id: 'AABR07038250.1'},
{value: 'aabr07038269.1', id: 'AABR07038269.1'},
{value: 'aabr07038284.1', id: 'AABR07038284.1'},
{value: 'aabr07038308.1', id: 'AABR07038308.1'},
{value: 'aabr07038349.1', id: 'AABR07038349.1'},
{value: 'aabr07038355.1', id: 'AABR07038355.1'},
{value: 'aabr07038355.2', id: 'AABR07038355.2'},
{value: 'aabr07038464.1', id: 'AABR07038464.1'},
{value: 'aabr07038477.1', id: 'AABR07038477.1'},
{value: 'aabr07038505.1', id: 'AABR07038505.1'},
{value: 'aabr07038552.1', id: 'AABR07038552.1'},
{value: 'aabr07038552.2', id: 'AABR07038552.2'},
{value: 'aabr07038553.1', id: 'AABR07038553.1'},
{value: 'aabr07038554.1', id: 'AABR07038554.1'},
{value: 'aabr07038556.2', id: 'AABR07038556.2'},
{value: 'aabr07038561.1', id: 'AABR07038561.1'},
{value: 'aabr07038561.2', id: 'AABR07038561.2'},
{value: 'aabr07038567.1', id: 'AABR07038567.1'},
{value: 'aabr07038578.1', id: 'AABR07038578.1'},
{value: 'aabr07038624.1', id: 'AABR07038624.1'},
{value: 'aabr07038625.1', id: 'AABR07038625.1'},
{value: 'aabr07038645.1', id: 'AABR07038645.1'},
{value: 'aabr07038645.2', id: 'AABR07038645.2'},
{value: 'aabr07038657.1', id: 'AABR07038657.1'},
{value: 'aabr07038661.2', id: 'AABR07038661.2'},
{value: 'aabr07038666.1', id: 'AABR07038666.1'},
{value: 'aabr07038676.1', id: 'AABR07038676.1'},
{value: 'aabr07038678.1', id: 'AABR07038678.1'},
{value: 'aabr07038688.1', id: 'AABR07038688.1'},
{value: 'aabr07038688.2', id: 'AABR07038688.2'},
{value: 'aabr07038690.1', id: 'AABR07038690.1'},
{value: 'aabr07038690.2', id: 'AABR07038690.2'},
{value: 'aabr07038690.3', id: 'AABR07038690.3'},
{value: 'aabr07038691.1', id: 'AABR07038691.1'},
{value: 'aabr07038691.2', id: 'AABR07038691.2'},
{value: 'aabr07038691.3', id: 'AABR07038691.3'},
{value: 'aabr07038702.1', id: 'AABR07038702.1'},
{value: 'aabr07038703.1', id: 'AABR07038703.1'},
{value: 'aabr07038712.1', id: 'AABR07038712.1'},
{value: 'aabr07038714.1', id: 'AABR07038714.1'},
{value: 'aabr07038714.2', id: 'AABR07038714.2'},
{value: 'aabr07038729.1', id: 'AABR07038729.1'},
{value: 'aabr07038752.1', id: 'AABR07038752.1'},
{value: 'aabr07038761.1', id: 'AABR07038761.1'},
{value: 'aabr07038768.1', id: 'AABR07038768.1'},
{value: 'aabr07038786.1', id: 'AABR07038786.1'},
{value: 'aabr07038788.1', id: 'AABR07038788.1'},
{value: 'aabr07038798.1', id: 'AABR07038798.1'},
{value: 'aabr07038799.1', id: 'AABR07038799.1'},
{value: 'aabr07038801.1', id: 'AABR07038801.1'},
{value: 'aabr07038802.2', id: 'AABR07038802.2'},
{value: 'aabr07038829.1', id: 'AABR07038829.1'},
{value: 'aabr07038831.1', id: 'AABR07038831.1'},
{value: 'aabr07038832.1', id: 'AABR07038832.1'},
{value: 'aabr07038837.1', id: 'AABR07038837.1'},
{value: 'aabr07038842.2', id: 'AABR07038842.2'},
{value: 'aabr07038849.1', id: 'AABR07038849.1'},
{value: 'aabr07038849.2', id: 'AABR07038849.2'},
{value: 'aabr07038850.1', id: 'AABR07038850.1'},
{value: 'aabr07038853.1', id: 'AABR07038853.1'},
{value: 'aabr07038856.1', id: 'AABR07038856.1'},
{value: 'aabr07038858.1', id: 'AABR07038858.1'},
{value: 'aabr07038861.1', id: 'AABR07038861.1'},
{value: 'aabr07038863.2', id: 'AABR07038863.2'},
{value: 'aabr07038870.1', id: 'AABR07038870.1'},
{value: 'aabr07038870.2', id: 'AABR07038870.2'},
{value: 'aabr07038871.1', id: 'AABR07038871.1'},
{value: 'aabr07038873.1', id: 'AABR07038873.1'},
{value: 'aabr07038879.1', id: 'AABR07038879.1'},
{value: 'aabr07038880.1', id: 'AABR07038880.1'},
{value: 'aabr07038880.2', id: 'AABR07038880.2'},
{value: 'aabr07038881.1', id: 'AABR07038881.1'},
{value: 'aabr07038886.1', id: 'AABR07038886.1'},
{value: 'aabr07038890.1', id: 'AABR07038890.1'},
{value: 'aabr07038894.1', id: 'AABR07038894.1'},
{value: 'aabr07038895.1', id: 'AABR07038895.1'},
{value: 'aabr07038895.2', id: 'AABR07038895.2'},
{value: 'aabr07038902.1', id: 'AABR07038902.1'},
{value: 'aabr07038902.2', id: 'AABR07038902.2'},
{value: 'aabr07038913.1', id: 'AABR07038913.1'},
{value: 'aabr07038920.1', id: 'AABR07038920.1'},
{value: 'aabr07038926.1', id: 'AABR07038926.1'},
{value: 'aabr07038929.1', id: 'AABR07038929.1'},
{value: 'aabr07038929.2', id: 'AABR07038929.2'},
{value: 'aabr07038929.3', id: 'AABR07038929.3'},
{value: 'aabr07038929.4', id: 'AABR07038929.4'},
{value: 'aabr07038929.5', id: 'AABR07038929.5'},
{value: 'aabr07038930.1', id: 'AABR07038930.1'},
{value: 'aabr07038938.1', id: 'AABR07038938.1'},
{value: 'aabr07038939.1', id: 'AABR07038939.1'},
{value: 'aabr07038948.1', id: 'AABR07038948.1'},
{value: 'aabr07038957.1', id: 'AABR07038957.1'},
{value: 'aabr07038969.1', id: 'AABR07038969.1'},
{value: 'aabr07038971.1', id: 'AABR07038971.1'},
{value: 'aabr07038972.1', id: 'AABR07038972.1'},
{value: 'aabr07038979.1', id: 'AABR07038979.1'},
{value: 'aabr07038983.1', id: 'AABR07038983.1'},
{value: 'aabr07039000.1', id: 'AABR07039000.1'},
{value: 'aabr07039012.1', id: 'AABR07039012.1'},
{value: 'aabr07039027.1', id: 'AABR07039027.1'},
{value: 'aabr07039029.1', id: 'AABR07039029.1'},
{value: 'aabr07039029.2', id: 'AABR07039029.2'},
{value: 'aabr07039033.1', id: 'AABR07039033.1'},
{value: 'aabr07039037.1', id: 'AABR07039037.1'},
{value: 'aabr07039057.1', id: 'AABR07039057.1'},
{value: 'aabr07039058.1', id: 'AABR07039058.1'},
{value: 'aabr07039069.1', id: 'AABR07039069.1'},
{value: 'aabr07039070.1', id: 'AABR07039070.1'},
{value: 'aabr07039075.1', id: 'AABR07039075.1'},
{value: 'aabr07039075.2', id: 'AABR07039075.2'},
{value: 'aabr07039092.1', id: 'AABR07039092.1'},
{value: 'aabr07039094.1', id: 'AABR07039094.1'},
{value: 'aabr07039097.1', id: 'AABR07039097.1'},
{value: 'aabr07039104.1', id: 'AABR07039104.1'},
{value: 'aabr07039104.2', id: 'AABR07039104.2'},
{value: 'aabr07039112.1', id: 'AABR07039112.1'},
{value: 'aabr07039116.1', id: 'AABR07039116.1'},
{value: 'aabr07039118.1', id: 'AABR07039118.1'},
{value: 'aabr07039133.1', id: 'AABR07039133.1'},
{value: 'aabr07039133.2', id: 'AABR07039133.2'},
{value: 'aabr07039133.3', id: 'AABR07039133.3'},
{value: 'aabr07039139.1', id: 'AABR07039139.1'},
{value: 'aabr07039139.2', id: 'AABR07039139.2'},
{value: 'aabr07039140.1', id: 'AABR07039140.1'},
{value: 'aabr07039146.1', id: 'AABR07039146.1'},
{value: 'aabr07039146.2', id: 'AABR07039146.2'},
{value: 'aabr07039147.2', id: 'AABR07039147.2'},
{value: 'aabr07039153.2', id: 'AABR07039153.2'},
{value: 'aabr07039184.1', id: 'AABR07039184.1'},
{value: 'aabr07039195.1', id: 'AABR07039195.1'},
{value: 'aabr07039198.1', id: 'AABR07039198.1'},
{value: 'aabr07039199.1', id: 'AABR07039199.1'},
{value: 'aabr07039202.1', id: 'AABR07039202.1'},
{value: 'aabr07039203.1', id: 'AABR07039203.1'},
{value: 'aabr07039203.2', id: 'AABR07039203.2'},
{value: 'aabr07039206.1', id: 'AABR07039206.1'},
{value: 'aabr07039210.1', id: 'AABR07039210.1'},
{value: 'aabr07039210.2', id: 'AABR07039210.2'},
{value: 'aabr07039210.3', id: 'AABR07039210.3'},
{value: 'aabr07039214.1', id: 'AABR07039214.1'},
{value: 'aabr07039214.2', id: 'AABR07039214.2'},
{value: 'aabr07039218.1', id: 'AABR07039218.1'},
{value: 'aabr07039223.1', id: 'AABR07039223.1'},
{value: 'aabr07039228.1', id: 'AABR07039228.1'},
{value: 'aabr07039229.1', id: 'AABR07039229.1'},
{value: 'aabr07039229.2', id: 'AABR07039229.2'},
{value: 'aabr07039234.1', id: 'AABR07039234.1'},
{value: 'aabr07039236.1', id: 'AABR07039236.1'},
{value: 'aabr07039245.1', id: 'AABR07039245.1'},
{value: 'aabr07039256.1', id: 'AABR07039256.1'},
{value: 'aabr07039264.1', id: 'AABR07039264.1'},
{value: 'aabr07039292.1', id: 'AABR07039292.1'},
{value: 'aabr07039303.2', id: 'AABR07039303.2'},
{value: 'aabr07039303.3', id: 'AABR07039303.3'},
{value: 'aabr07039303.5', id: 'AABR07039303.5'},
{value: 'aabr07039303.6', id: 'AABR07039303.6'},
{value: 'aabr07039303.7', id: 'AABR07039303.7'},
{value: 'aabr07039304.1', id: 'AABR07039304.1'},
{value: 'aabr07039307.1', id: 'AABR07039307.1'},
{value: 'aabr07039313.1', id: 'AABR07039313.1'},
{value: 'aabr07039316.1', id: 'AABR07039316.1'},
{value: 'aabr07039325.1', id: 'AABR07039325.1'},
{value: 'aabr07039328.1', id: 'AABR07039328.1'},
{value: 'aabr07039328.2', id: 'AABR07039328.2'},
{value: 'aabr07039328.3', id: 'AABR07039328.3'},
{value: 'aabr07039329.1', id: 'AABR07039329.1'},
{value: 'aabr07039334.1', id: 'AABR07039334.1'},
{value: 'aabr07039334.2', id: 'AABR07039334.2'},
{value: 'aabr07039335.1', id: 'AABR07039335.1'},
{value: 'aabr07039336.1', id: 'AABR07039336.1'},
{value: 'aabr07039336.2', id: 'AABR07039336.2'},
{value: 'aabr07039336.3', id: 'AABR07039336.3'},
{value: 'aabr07039336.4', id: 'AABR07039336.4'},
{value: 'aabr07039338.1', id: 'AABR07039338.1'},
{value: 'aabr07039342.1', id: 'AABR07039342.1'},
{value: 'aabr07039342.2', id: 'AABR07039342.2'},
{value: 'aabr07039344.1', id: 'AABR07039344.1'},
{value: 'aabr07039354.1', id: 'AABR07039354.1'},
{value: 'aabr07039356.1', id: 'AABR07039356.1'},
{value: 'aabr07039356.2', id: 'AABR07039356.2'},
{value: 'aabr07039377.1', id: 'AABR07039377.1'},
{value: 'aabr07039377.2', id: 'AABR07039377.2'},
{value: 'aabr07039378.2', id: 'AABR07039378.2'},
{value: 'aabr07039438.1', id: 'AABR07039438.1'},
{value: 'aabr07039438.2', id: 'AABR07039438.2'},
{value: 'aabr07039446.1', id: 'AABR07039446.1'},
{value: 'aabr07039446.2', id: 'AABR07039446.2'},
{value: 'aabr07039447.1', id: 'AABR07039447.1'},
{value: 'aabr07039452.1', id: 'AABR07039452.1'},
{value: 'aabr07039461.1', id: 'AABR07039461.1'},
{value: 'aabr07039470.2', id: 'AABR07039470.2'},
{value: 'aabr07039471.1', id: 'AABR07039471.1'},
{value: 'aabr07039474.1', id: 'AABR07039474.1'},
{value: 'aabr07039474.2', id: 'AABR07039474.2'},
{value: 'aabr07039483.1', id: 'AABR07039483.1'},
{value: 'aabr07039498.1', id: 'AABR07039498.1'},
{value: 'aabr07039506.1', id: 'AABR07039506.1'},
{value: 'aabr07039541.1', id: 'AABR07039541.1'},
{value: 'aabr07039648.1', id: 'AABR07039648.1'},
{value: 'aabr07039651.1', id: 'AABR07039651.1'},
{value: 'aabr07039694.1', id: 'AABR07039694.1'},
{value: 'aabr07039697.1', id: 'AABR07039697.1'},
{value: 'aabr07039736.1', id: 'AABR07039736.1'},
{value: 'aabr07039773.1', id: 'AABR07039773.1'},
{value: 'aabr07039778.1', id: 'AABR07039778.1'},
{value: 'aabr07039799.1', id: 'AABR07039799.1'},
{value: 'aabr07039889.1', id: 'AABR07039889.1'},
{value: 'aabr07039936.1', id: 'AABR07039936.1'},
{value: 'aabr07039950.1', id: 'AABR07039950.1'},
{value: 'aabr07040095.1', id: 'AABR07040095.1'},
{value: 'aabr07040127.1', id: 'AABR07040127.1'},
{value: 'aabr07040249.1', id: 'AABR07040249.1'},
{value: 'aabr07040250.1', id: 'AABR07040250.1'},
{value: 'aabr07040251.1', id: 'AABR07040251.1'},
{value: 'aabr07040252.1', id: 'AABR07040252.1'},
{value: 'aabr07040266.1', id: 'AABR07040266.1'},
{value: 'aabr07040284.1', id: 'AABR07040284.1'},
{value: 'aabr07040288.1', id: 'AABR07040288.1'},
{value: 'aabr07040332.1', id: 'AABR07040332.1'},
{value: 'aabr07040387.1', id: 'AABR07040387.1'},
{value: 'aabr07040412.1', id: 'AABR07040412.1'},
{value: 'aabr07040430.1', id: 'AABR07040430.1'},
{value: 'aabr07040444.1', id: 'AABR07040444.1'},
{value: 'aabr07040480.1', id: 'AABR07040480.1'},
{value: 'aabr07040481.1', id: 'AABR07040481.1'},
{value: 'aabr07040494.1', id: 'AABR07040494.1'},
{value: 'aabr07040517.1', id: 'AABR07040517.1'},
{value: 'aabr07040565.1', id: 'AABR07040565.1'},
{value: 'aabr07040621.1', id: 'AABR07040621.1'},
{value: 'aabr07040622.1', id: 'AABR07040622.1'},
{value: 'aabr07040624.1', id: 'AABR07040624.1'},
{value: 'aabr07040629.1', id: 'AABR07040629.1'},
{value: 'aabr07040686.1', id: 'AABR07040686.1'},
{value: 'aabr07040695.1', id: 'AABR07040695.1'},
{value: 'aabr07040730.1', id: 'AABR07040730.1'},
{value: 'aabr07040766.1', id: 'AABR07040766.1'},
{value: 'aabr07040770.1', id: 'AABR07040770.1'},
{value: 'aabr07040780.1', id: 'AABR07040780.1'},
{value: 'aabr07040782.1', id: 'AABR07040782.1'},
{value: 'aabr07040789.1', id: 'AABR07040789.1'},
{value: 'aabr07040792.1', id: 'AABR07040792.1'},
{value: 'aabr07040797.1', id: 'AABR07040797.1'},
{value: 'aabr07040839.1', id: 'AABR07040839.1'},
{value: 'aabr07040839.2', id: 'AABR07040839.2'},
{value: 'aabr07040840.1', id: 'AABR07040840.1'},
{value: 'aabr07040847.1', id: 'AABR07040847.1'},
{value: 'aabr07040855.1', id: 'AABR07040855.1'},
{value: 'aabr07040864.1', id: 'AABR07040864.1'},
{value: 'aabr07040887.1', id: 'AABR07040887.1'},
{value: 'aabr07040892.1', id: 'AABR07040892.1'},
{value: 'aabr07040900.1', id: 'AABR07040900.1'},
{value: 'aabr07040906.1', id: 'AABR07040906.1'},
{value: 'aabr07040908.1', id: 'AABR07040908.1'},
{value: 'aabr07040918.1', id: 'AABR07040918.1'},
{value: 'aabr07040936.1', id: 'AABR07040936.1'},
{value: 'aabr07040938.1', id: 'AABR07040938.1'},
{value: 'aabr07040944.1', id: 'AABR07040944.1'},
{value: 'aabr07040947.1', id: 'AABR07040947.1'},
{value: 'aabr07040951.1', id: 'AABR07040951.1'},
{value: 'aabr07040953.1', id: 'AABR07040953.1'},
{value: 'aabr07040959.1', id: 'AABR07040959.1'},
{value: 'aabr07040963.1', id: 'AABR07040963.1'},
{value: 'aabr07041019.1', id: 'AABR07041019.1'},
{value: 'aabr07041039.1', id: 'AABR07041039.1'},
{value: 'aabr07041066.1', id: 'AABR07041066.1'},
{value: 'aabr07041078.1', id: 'AABR07041078.1'},
{value: 'aabr07041089.1', id: 'AABR07041089.1'},
{value: 'aabr07041096.1', id: 'AABR07041096.1'},
{value: 'aabr07041096.2', id: 'AABR07041096.2'},
{value: 'aabr07041109.1', id: 'AABR07041109.1'},
{value: 'aabr07041140.1', id: 'AABR07041140.1'},
{value: 'aabr07041141.1', id: 'AABR07041141.1'},
{value: 'aabr07041179.1', id: 'AABR07041179.1'},
{value: 'aabr07041206.1', id: 'AABR07041206.1'},
{value: 'aabr07041234.1', id: 'AABR07041234.1'},
{value: 'aabr07041237.1', id: 'AABR07041237.1'},
{value: 'aabr07041239.2', id: 'AABR07041239.2'},
{value: 'aabr07041246.1', id: 'AABR07041246.1'},
{value: 'aabr07041247.1', id: 'AABR07041247.1'},
{value: 'aabr07041247.2', id: 'AABR07041247.2'},
{value: 'aabr07041247.3', id: 'AABR07041247.3'},
{value: 'aabr07041247.4', id: 'AABR07041247.4'},
{value: 'aabr07041282.1', id: 'AABR07041282.1'},
{value: 'aabr07041297.1', id: 'AABR07041297.1'},
{value: 'aabr07041316.1', id: 'AABR07041316.1'},
{value: 'aabr07041361.1', id: 'AABR07041361.1'},
{value: 'aabr07041374.1', id: 'AABR07041374.1'},
{value: 'aabr07041403.1', id: 'AABR07041403.1'},
{value: 'aabr07041411.1', id: 'AABR07041411.1'},
{value: 'aabr07041418.1', id: 'AABR07041418.1'},
{value: 'aabr07041451.1', id: 'AABR07041451.1'},
{value: 'aabr07041453.1', id: 'AABR07041453.1'},
{value: 'aabr07041481.1', id: 'AABR07041481.1'},
{value: 'aabr07041514.1', id: 'AABR07041514.1'},
{value: 'aabr07041532.1', id: 'AABR07041532.1'},
{value: 'aabr07041586.1', id: 'AABR07041586.1'},
{value: 'aabr07041586.2', id: 'AABR07041586.2'},
{value: 'aabr07041600.1', id: 'AABR07041600.1'},
{value: 'aabr07041601.1', id: 'AABR07041601.1'},
{value: 'aabr07041604.1', id: 'AABR07041604.1'},
{value: 'aabr07041627.1', id: 'AABR07041627.1'},
{value: 'aabr07041632.1', id: 'AABR07041632.1'},
{value: 'aabr07041709.1', id: 'AABR07041709.1'},
{value: 'aabr07041724.1', id: 'AABR07041724.1'},
{value: 'aabr07041724.2', id: 'AABR07041724.2'},
{value: 'aabr07041725.1', id: 'AABR07041725.1'},
{value: 'aabr07041731.1', id: 'AABR07041731.1'},
{value: 'aabr07041771.1', id: 'AABR07041771.1'},
{value: 'aabr07041772.1', id: 'AABR07041772.1'},
{value: 'aabr07041778.1', id: 'AABR07041778.1'},
{value: 'aabr07041778.4', id: 'AABR07041778.4'},
{value: 'aabr07041779.1', id: 'AABR07041779.1'},
{value: 'aabr07041794.1', id: 'AABR07041794.1'},
{value: 'aabr07041963.1', id: 'AABR07041963.1'},
{value: 'aabr07041972.1', id: 'AABR07041972.1'},
{value: 'aabr07041997.1', id: 'AABR07041997.1'},
{value: 'aabr07042012.1', id: 'AABR07042012.1'},
{value: 'aabr07042039.1', id: 'AABR07042039.1'},
{value: 'aabr07042071.1', id: 'AABR07042071.1'},
{value: 'aabr07042077.1', id: 'AABR07042077.1'},
{value: 'aabr07042226.1', id: 'AABR07042226.1'},
{value: 'aabr07042292.1', id: 'AABR07042292.1'},
{value: 'aabr07042293.1', id: 'AABR07042293.1'},
{value: 'aabr07042293.2', id: 'AABR07042293.2'},
{value: 'aabr07042301.1', id: 'AABR07042301.1'},
{value: 'aabr07042321.1', id: 'AABR07042321.1'},
{value: 'aabr07042323.1', id: 'AABR07042323.1'},
{value: 'aabr07042326.1', id: 'AABR07042326.1'},
{value: 'aabr07042326.3', id: 'AABR07042326.3'},
{value: 'aabr07042385.1', id: 'AABR07042385.1'},
{value: 'aabr07042388.1', id: 'AABR07042388.1'},
{value: 'aabr07042392.1', id: 'AABR07042392.1'},
{value: 'aabr07042403.1', id: 'AABR07042403.1'},
{value: 'aabr07042407.1', id: 'AABR07042407.1'},
{value: 'aabr07042418.1', id: 'AABR07042418.1'},
{value: 'aabr07042440.1', id: 'AABR07042440.1'},
{value: 'aabr07042440.2', id: 'AABR07042440.2'},
{value: 'aabr07042440.4', id: 'AABR07042440.4'},
{value: 'aabr07042454.1', id: 'AABR07042454.1'},
{value: 'aabr07042457.1', id: 'AABR07042457.1'},
{value: 'aabr07042465.1', id: 'AABR07042465.1'},
{value: 'aabr07042471.1', id: 'AABR07042471.1'},
{value: 'aabr07042496.1', id: 'AABR07042496.1'},
{value: 'aabr07042514.1', id: 'AABR07042514.1'},
{value: 'aabr07042514.3', id: 'AABR07042514.3'},
{value: 'aabr07042541.1', id: 'AABR07042541.1'},
{value: 'aabr07042542.1', id: 'AABR07042542.1'},
{value: 'aabr07042557.1', id: 'AABR07042557.1'},
{value: 'aabr07042565.1', id: 'AABR07042565.1'},
{value: 'aabr07042571.1', id: 'AABR07042571.1'},
{value: 'aabr07042607.1', id: 'AABR07042607.1'},
{value: 'aabr07042609.1', id: 'AABR07042609.1'},
{value: 'aabr07042611.1', id: 'AABR07042611.1'},
{value: 'aabr07042623.1', id: 'AABR07042623.1'},
{value: 'aabr07042629.1', id: 'AABR07042629.1'},
{value: 'aabr07042652.1', id: 'AABR07042652.1'},
{value: 'aabr07042654.1', id: 'AABR07042654.1'},
{value: 'aabr07042662.1', id: 'AABR07042662.1'},
{value: 'aabr07042668.1', id: 'AABR07042668.1'},
{value: 'aabr07042669.1', id: 'AABR07042669.1'},
{value: 'aabr07042687.1', id: 'AABR07042687.1'},
{value: 'aabr07042690.1', id: 'AABR07042690.1'},
{value: 'aabr07042699.1', id: 'AABR07042699.1'},
{value: 'aabr07042731.1', id: 'AABR07042731.1'},
{value: 'aabr07042733.1', id: 'AABR07042733.1'},
{value: 'aabr07042733.2', id: 'AABR07042733.2'},
{value: 'aabr07042734.1', id: 'AABR07042734.1'},
{value: 'aabr07042780.1', id: 'AABR07042780.1'},
{value: 'aabr07042781.1', id: 'AABR07042781.1'},
{value: 'aabr07042781.2', id: 'AABR07042781.2'},
{value: 'aabr07042789.1', id: 'AABR07042789.1'},
{value: 'aabr07042792.1', id: 'AABR07042792.1'},
{value: 'aabr07042794.1', id: 'AABR07042794.1'},
{value: 'aabr07042802.1', id: 'AABR07042802.1'},
{value: 'aabr07042821.1', id: 'AABR07042821.1'},
{value: 'aabr07042825.1', id: 'AABR07042825.1'},
{value: 'aabr07042840.1', id: 'AABR07042840.1'},
{value: 'aabr07042859.1', id: 'AABR07042859.1'},
{value: 'aabr07042874.1', id: 'AABR07042874.1'},
{value: 'aabr07042875.1', id: 'AABR07042875.1'},
{value: 'aabr07042891.1', id: 'AABR07042891.1'},
{value: 'aabr07042891.2', id: 'AABR07042891.2'},
{value: 'aabr07042892.1', id: 'AABR07042892.1'},
{value: 'aabr07042903.1', id: 'AABR07042903.1'},
{value: 'aabr07042903.2', id: 'AABR07042903.2'},
{value: 'aabr07042911.1', id: 'AABR07042911.1'},
{value: 'aabr07042915.1', id: 'AABR07042915.1'},
{value: 'aabr07042936.1', id: 'AABR07042936.1'},
{value: 'aabr07042936.2', id: 'AABR07042936.2'},
{value: 'aabr07042937.1', id: 'AABR07042937.1'},
{value: 'aabr07042989.1', id: 'AABR07042989.1'},
{value: 'aabr07042990.1', id: 'AABR07042990.1'},
{value: 'aabr07042999.1', id: 'AABR07042999.1'},
{value: 'aabr07043001.1', id: 'AABR07043001.1'},
{value: 'aabr07043001.2', id: 'AABR07043001.2'},
{value: 'aabr07043012.1', id: 'AABR07043012.1'},
{value: 'aabr07043013.1', id: 'AABR07043013.1'},
{value: 'aabr07043028.1', id: 'AABR07043028.1'},
{value: 'aabr07043030.1', id: 'AABR07043030.1'},
{value: 'aabr07043030.2', id: 'AABR07043030.2'},
{value: 'aabr07043031.1', id: 'AABR07043031.1'},
{value: 'aabr07043031.2', id: 'AABR07043031.2'},
{value: 'aabr07043031.3', id: 'AABR07043031.3'},
{value: 'aabr07043031.4', id: 'AABR07043031.4'},
{value: 'aabr07043035.1', id: 'AABR07043035.1'},
{value: 'aabr07043039.1', id: 'AABR07043039.1'},
{value: 'aabr07043040.1', id: 'AABR07043040.1'},
{value: 'aabr07043071.1', id: 'AABR07043071.1'},
{value: 'aabr07043075.1', id: 'AABR07043075.1'},
{value: 'aabr07043085.1', id: 'AABR07043085.1'},
{value: 'aabr07043098.1', id: 'AABR07043098.1'},
{value: 'aabr07043098.2', id: 'AABR07043098.2'},
{value: 'aabr07043100.1', id: 'AABR07043100.1'},
{value: 'aabr07043101.1', id: 'AABR07043101.1'},
{value: 'aabr07043103.1', id: 'AABR07043103.1'},
{value: 'aabr07043106.1', id: 'AABR07043106.1'},
{value: 'aabr07043108.1', id: 'AABR07043108.1'},
{value: 'aabr07043114.1', id: 'AABR07043114.1'},
{value: 'aabr07043115.1', id: 'AABR07043115.1'},
{value: 'aabr07043121.1', id: 'AABR07043121.1'},
{value: 'aabr07043133.1', id: 'AABR07043133.1'},
{value: 'aabr07043141.1', id: 'AABR07043141.1'},
{value: 'aabr07043155.1', id: 'AABR07043155.1'},
{value: 'aabr07043167.1', id: 'AABR07043167.1'},
{value: 'aabr07043167.2', id: 'AABR07043167.2'},
{value: 'aabr07043169.1', id: 'AABR07043169.1'},
{value: 'aabr07043190.1', id: 'AABR07043190.1'},
{value: 'aabr07043200.1', id: 'AABR07043200.1'},
{value: 'aabr07043247.1', id: 'AABR07043247.1'},
{value: 'aabr07043248.1', id: 'AABR07043248.1'},
{value: 'aabr07043249.1', id: 'AABR07043249.1'},
{value: 'aabr07043271.1', id: 'AABR07043271.1'},
{value: 'aabr07043276.1', id: 'AABR07043276.1'},
{value: 'aabr07043279.1', id: 'AABR07043279.1'},
{value: 'aabr07043287.1', id: 'AABR07043287.1'},
{value: 'aabr07043288.1', id: 'AABR07043288.1'},
{value: 'aabr07043347.1', id: 'AABR07043347.1'},
{value: 'aabr07043354.1', id: 'AABR07043354.1'},
{value: 'aabr07043358.1', id: 'AABR07043358.1'},
{value: 'aabr07043365.1', id: 'AABR07043365.1'},
{value: 'aabr07043389.1', id: 'AABR07043389.1'},
{value: 'aabr07043395.1', id: 'AABR07043395.1'},
{value: 'aabr07043407.1', id: 'AABR07043407.1'},
{value: 'aabr07043429.1', id: 'AABR07043429.1'},
{value: 'aabr07043429.2', id: 'AABR07043429.2'},
{value: 'aabr07043445.1', id: 'AABR07043445.1'},
{value: 'aabr07043449.1', id: 'AABR07043449.1'},
{value: 'aabr07043453.1', id: 'AABR07043453.1'},
{value: 'aabr07043454.1', id: 'AABR07043454.1'},
{value: 'aabr07043456.1', id: 'AABR07043456.1'},
{value: 'aabr07043510.1', id: 'AABR07043510.1'},
{value: 'aabr07043514.1', id: 'AABR07043514.1'},
{value: 'aabr07043523.1', id: 'AABR07043523.1'},
{value: 'aabr07043525.1', id: 'AABR07043525.1'},
{value: 'aabr07043557.1', id: 'AABR07043557.1'},
{value: 'aabr07043564.1', id: 'AABR07043564.1'},
{value: 'aabr07043573.1', id: 'AABR07043573.1'},
{value: 'aabr07043598.1', id: 'AABR07043598.1'},
{value: 'aabr07043601.1', id: 'AABR07043601.1'},
{value: 'aabr07043601.2', id: 'AABR07043601.2'},
{value: 'aabr07043601.4', id: 'AABR07043601.4'},
{value: 'aabr07043615.1', id: 'AABR07043615.1'},
{value: 'aabr07043625.1', id: 'AABR07043625.1'},
{value: 'aabr07043626.1', id: 'AABR07043626.1'},
{value: 'aabr07043642.1', id: 'AABR07043642.1'},
{value: 'aabr07043645.1', id: 'AABR07043645.1'},
{value: 'aabr07043652.1', id: 'AABR07043652.1'},
{value: 'aabr07043658.1', id: 'AABR07043658.1'},
{value: 'aabr07043667.1', id: 'AABR07043667.1'},
{value: 'aabr07043676.1', id: 'AABR07043676.1'},
{value: 'aabr07043684.1', id: 'AABR07043684.1'},
{value: 'aabr07043696.1', id: 'AABR07043696.1'},
{value: 'aabr07043698.1', id: 'AABR07043698.1'},
{value: 'aabr07043701.1', id: 'AABR07043701.1'},
{value: 'aabr07043702.1', id: 'AABR07043702.1'},
{value: 'aabr07043711.1', id: 'AABR07043711.1'},
{value: 'aabr07043711.2', id: 'AABR07043711.2'},
{value: 'aabr07043720.1', id: 'AABR07043720.1'},
{value: 'aabr07043728.1', id: 'AABR07043728.1'},
{value: 'aabr07043748.1', id: 'AABR07043748.1'},
{value: 'aabr07043748.2', id: 'AABR07043748.2'},
{value: 'aabr07043772.1', id: 'AABR07043772.1'},
{value: 'aabr07043775.1', id: 'AABR07043775.1'},
{value: 'aabr07043776.1', id: 'AABR07043776.1'},
{value: 'aabr07043816.1', id: 'AABR07043816.1'},
{value: 'aabr07043818.1', id: 'AABR07043818.1'},
{value: 'aabr07043822.1', id: 'AABR07043822.1'},
{value: 'aabr07043823.1', id: 'AABR07043823.1'},
{value: 'aabr07043823.2', id: 'AABR07043823.2'},
{value: 'aabr07043825.1', id: 'AABR07043825.1'},
{value: 'aabr07043829.1', id: 'AABR07043829.1'},
{value: 'aabr07043844.1', id: 'AABR07043844.1'},
{value: 'aabr07043861.1', id: 'AABR07043861.1'},
{value: 'aabr07043875.1', id: 'AABR07043875.1'},
{value: 'aabr07043877.1', id: 'AABR07043877.1'},
{value: 'aabr07043877.2', id: 'AABR07043877.2'},
{value: 'aabr07043877.3', id: 'AABR07043877.3'},
{value: 'aabr07043883.1', id: 'AABR07043883.1'},
{value: 'aabr07043887.1', id: 'AABR07043887.1'},
{value: 'aabr07043892.1', id: 'AABR07043892.1'},
{value: 'aabr07043897.1', id: 'AABR07043897.1'},
{value: 'aabr07043928.1', id: 'AABR07043928.1'},
{value: 'aabr07043928.2', id: 'AABR07043928.2'},
{value: 'aabr07043929.1', id: 'AABR07043929.1'},
{value: 'aabr07043940.1', id: 'AABR07043940.1'},
{value: 'aabr07043951.1', id: 'AABR07043951.1'},
{value: 'aabr07043977.1', id: 'AABR07043977.1'},
{value: 'aabr07043986.2', id: 'AABR07043986.2'},
{value: 'aabr07043987.1', id: 'AABR07043987.1'},
{value: 'aabr07044001.2', id: 'AABR07044001.2'},
{value: 'aabr07044001.3', id: 'AABR07044001.3'},
{value: 'aabr07044001.4', id: 'AABR07044001.4'},
{value: 'aabr07044009.1', id: 'AABR07044009.1'},
{value: 'aabr07044011.1', id: 'AABR07044011.1'},
{value: 'aabr07044014.1', id: 'AABR07044014.1'},
{value: 'aabr07044017.1', id: 'AABR07044017.1'},
{value: 'aabr07044018.1', id: 'AABR07044018.1'},
{value: 'aabr07044036.1', id: 'AABR07044036.1'},
{value: 'aabr07044049.1', id: 'AABR07044049.1'},
{value: 'aabr07044054.1', id: 'AABR07044054.1'},
{value: 'aabr07044063.1', id: 'AABR07044063.1'},
{value: 'aabr07044063.2', id: 'AABR07044063.2'},
{value: 'aabr07044065.1', id: 'AABR07044065.1'},
{value: 'aabr07044075.1', id: 'AABR07044075.1'},
{value: 'aabr07044079.1', id: 'AABR07044079.1'},
{value: 'aabr07044080.2', id: 'AABR07044080.2'},
{value: 'aabr07044081.1', id: 'AABR07044081.1'},
{value: 'aabr07044081.2', id: 'AABR07044081.2'},
{value: 'aabr07044088.1', id: 'AABR07044088.1'},
{value: 'aabr07044106.1', id: 'AABR07044106.1'},
{value: 'aabr07044111.1', id: 'AABR07044111.1'},
{value: 'aabr07044123.1', id: 'AABR07044123.1'},
{value: 'aabr07044139.1', id: 'AABR07044139.1'},
{value: 'aabr07044148.1', id: 'AABR07044148.1'},
{value: 'aabr07044167.1', id: 'AABR07044167.1'},
{value: 'aabr07044171.2', id: 'AABR07044171.2'},
{value: 'aabr07044173.1', id: 'AABR07044173.1'},
{value: 'aabr07044183.1', id: 'AABR07044183.1'},
{value: 'aabr07044199.1', id: 'AABR07044199.1'},
{value: 'aabr07044273.1', id: 'AABR07044273.1'},
{value: 'aabr07044273.2', id: 'AABR07044273.2'},
{value: 'aabr07044291.1', id: 'AABR07044291.1'},
{value: 'aabr07044293.1', id: 'AABR07044293.1'},
{value: 'aabr07044297.1', id: 'AABR07044297.1'},
{value: 'aabr07044297.2', id: 'AABR07044297.2'},
{value: 'aabr07044301.1', id: 'AABR07044301.1'},
{value: 'aabr07044302.1', id: 'AABR07044302.1'},
{value: 'aabr07044308.1', id: 'AABR07044308.1'},
{value: 'aabr07044308.2', id: 'AABR07044308.2'},
{value: 'aabr07044308.3', id: 'AABR07044308.3'},
{value: 'aabr07044308.4', id: 'AABR07044308.4'},
{value: 'aabr07044311.1', id: 'AABR07044311.1'},
{value: 'aabr07044322.1', id: 'AABR07044322.1'},
{value: 'aabr07044338.1', id: 'AABR07044338.1'},
{value: 'aabr07044339.1', id: 'AABR07044339.1'},
{value: 'aabr07044339.2', id: 'AABR07044339.2'},
{value: 'aabr07044346.1', id: 'AABR07044346.1'},
{value: 'aabr07044349.1', id: 'AABR07044349.1'},
{value: 'aabr07044354.1', id: 'AABR07044354.1'},
{value: 'aabr07044359.1', id: 'AABR07044359.1'},
{value: 'aabr07044360.1', id: 'AABR07044360.1'},
{value: 'aabr07044361.1', id: 'AABR07044361.1'},
{value: 'aabr07044362.1', id: 'AABR07044362.1'},
{value: 'aabr07044362.3', id: 'AABR07044362.3'},
{value: 'aabr07044362.4', id: 'AABR07044362.4'},
{value: 'aabr07044362.6', id: 'AABR07044362.6'},
{value: 'aabr07044362.7', id: 'AABR07044362.7'},
{value: 'aabr07044365.1', id: 'AABR07044365.1'},
{value: 'aabr07044366.1', id: 'AABR07044366.1'},
{value: 'aabr07044367.1', id: 'AABR07044367.1'},
{value: 'aabr07044370.1', id: 'AABR07044370.1'},
{value: 'aabr07044373.1', id: 'AABR07044373.1'},
{value: 'aabr07044375.1', id: 'AABR07044375.1'},
{value: 'aabr07044375.2', id: 'AABR07044375.2'},
{value: 'aabr07044383.1', id: 'AABR07044383.1'},
{value: 'aabr07044388.1', id: 'AABR07044388.1'},
{value: 'aabr07044388.2', id: 'AABR07044388.2'},
{value: 'aabr07044388.3', id: 'AABR07044388.3'},
{value: 'aabr07044388.4', id: 'AABR07044388.4'},
{value: 'aabr07044388.5', id: 'AABR07044388.5'},
{value: 'aabr07044388.6', id: 'AABR07044388.6'},
{value: 'aabr07044389.1', id: 'AABR07044389.1'},
{value: 'aabr07044397.1', id: 'AABR07044397.1'},
{value: 'aabr07044404.1', id: 'AABR07044404.1'},
{value: 'aabr07044405.1', id: 'AABR07044405.1'},
{value: 'aabr07044407.1', id: 'AABR07044407.1'},
{value: 'aabr07044408.1', id: 'AABR07044408.1'},
{value: 'aabr07044408.3', id: 'AABR07044408.3'},
{value: 'aabr07044414.1', id: 'AABR07044414.1'},
{value: 'aabr07044415.1', id: 'AABR07044415.1'},
{value: 'aabr07044416.1', id: 'AABR07044416.1'},
{value: 'aabr07044416.2', id: 'AABR07044416.2'},
{value: 'aabr07044418.1', id: 'AABR07044418.1'},
{value: 'aabr07044418.2', id: 'AABR07044418.2'},
{value: 'aabr07044420.1', id: 'AABR07044420.1'},
{value: 'aabr07044420.2', id: 'AABR07044420.2'},
{value: 'aabr07044421.1', id: 'AABR07044421.1'},
{value: 'aabr07044427.1', id: 'AABR07044427.1'},
{value: 'aabr07044429.1', id: 'AABR07044429.1'},
{value: 'aabr07044441.1', id: 'AABR07044441.1'},
{value: 'aabr07044444.1', id: 'AABR07044444.1'},
{value: 'aabr07044447.1', id: 'AABR07044447.1'},
{value: 'aabr07044447.2', id: 'AABR07044447.2'},
{value: 'aabr07044454.1', id: 'AABR07044454.1'},
{value: 'aabr07044460.1', id: 'AABR07044460.1'},
{value: 'aabr07044460.2', id: 'AABR07044460.2'},
{value: 'aabr07044468.1', id: 'AABR07044468.1'},
{value: 'aabr07044470.1', id: 'AABR07044470.1'},
{value: 'aabr07044473.1', id: 'AABR07044473.1'},
{value: 'aabr07044492.1', id: 'AABR07044492.1'},
{value: 'aabr07044495.1', id: 'AABR07044495.1'},
{value: 'aabr07044509.1', id: 'AABR07044509.1'},
{value: 'aabr07044520.1', id: 'AABR07044520.1'},
{value: 'aabr07044535.1', id: 'AABR07044535.1'},
{value: 'aabr07044551.1', id: 'AABR07044551.1'},
{value: 'aabr07044555.1', id: 'AABR07044555.1'},
{value: 'aabr07044562.1', id: 'AABR07044562.1'},
{value: 'aabr07044569.1', id: 'AABR07044569.1'},
{value: 'aabr07044570.2', id: 'AABR07044570.2'},
{value: 'aabr07044574.1', id: 'AABR07044574.1'},
{value: 'aabr07044581.1', id: 'AABR07044581.1'},
{value: 'aabr07044583.1', id: 'AABR07044583.1'},
{value: 'aabr07044583.2', id: 'AABR07044583.2'},
{value: 'aabr07044593.1', id: 'AABR07044593.1'},
{value: 'aabr07044593.2', id: 'AABR07044593.2'},
{value: 'aabr07044596.1', id: 'AABR07044596.1'},
{value: 'aabr07044631.1', id: 'AABR07044631.1'},
{value: 'aabr07044631.2', id: 'AABR07044631.2'},
{value: 'aabr07044635.1', id: 'AABR07044635.1'},
{value: 'aabr07044635.2', id: 'AABR07044635.2'},
{value: 'aabr07044652.1', id: 'AABR07044652.1'},
{value: 'aabr07044655.1', id: 'AABR07044655.1'},
{value: 'aabr07044668.1', id: 'AABR07044668.1'},
{value: 'aabr07044685.1', id: 'AABR07044685.1'},
{value: 'aabr07044703.1', id: 'AABR07044703.1'},
{value: 'aabr07044712.1', id: 'AABR07044712.1'},
{value: 'aabr07044714.1', id: 'AABR07044714.1'},
{value: 'aabr07044717.1', id: 'AABR07044717.1'},
{value: 'aabr07044724.1', id: 'AABR07044724.1'},
{value: 'aabr07044752.1', id: 'AABR07044752.1'},
{value: 'aabr07044754.1', id: 'AABR07044754.1'},
{value: 'aabr07044759.1', id: 'AABR07044759.1'},
{value: 'aabr07044765.1', id: 'AABR07044765.1'},
{value: 'aabr07044767.1', id: 'AABR07044767.1'},
{value: 'aabr07044783.1', id: 'AABR07044783.1'},
{value: 'aabr07044790.1', id: 'AABR07044790.1'},
{value: 'aabr07044794.1', id: 'AABR07044794.1'},
{value: 'aabr07044796.1', id: 'AABR07044796.1'},
{value: 'aabr07044799.1', id: 'AABR07044799.1'},
{value: 'aabr07044804.1', id: 'AABR07044804.1'},
{value: 'aabr07044805.1', id: 'AABR07044805.1'},
{value: 'aabr07044805.2', id: 'AABR07044805.2'},
{value: 'aabr07044820.1', id: 'AABR07044820.1'},
{value: 'aabr07044820.2', id: 'AABR07044820.2'},
{value: 'aabr07044824.1', id: 'AABR07044824.1'},
{value: 'aabr07044825.1', id: 'AABR07044825.1'},
{value: 'aabr07044836.1', id: 'AABR07044836.1'},
{value: 'aabr07044837.1', id: 'AABR07044837.1'},
{value: 'aabr07044837.2', id: 'AABR07044837.2'},
{value: 'aabr07044838.1', id: 'AABR07044838.1'},
{value: 'aabr07044841.1', id: 'AABR07044841.1'},
{value: 'aabr07044850.1', id: 'AABR07044850.1'},
{value: 'aabr07044850.2', id: 'AABR07044850.2'},
{value: 'aabr07044875.1', id: 'AABR07044875.1'},
{value: 'aabr07044900.1', id: 'AABR07044900.1'},
{value: 'aabr07044903.1', id: 'AABR07044903.1'},
{value: 'aabr07044940.1', id: 'AABR07044940.1'},
{value: 'aabr07044952.1', id: 'AABR07044952.1'},
{value: 'aabr07044952.2', id: 'AABR07044952.2'},
{value: 'aabr07044959.1', id: 'AABR07044959.1'},
{value: 'aabr07044977.1', id: 'AABR07044977.1'},
{value: 'aabr07044977.2', id: 'AABR07044977.2'},
{value: 'aabr07044980.1', id: 'AABR07044980.1'},
{value: 'aabr07044988.1', id: 'AABR07044988.1'},
{value: 'aabr07044988.2', id: 'AABR07044988.2'},
{value: 'aabr07044988.3', id: 'AABR07044988.3'},
{value: 'aabr07045015.1', id: 'AABR07045015.1'},
{value: 'aabr07045032.1', id: 'AABR07045032.1'},
{value: 'aabr07045047.1', id: 'AABR07045047.1'},
{value: 'aabr07045048.1', id: 'AABR07045048.1'},
{value: 'aabr07045058.1', id: 'AABR07045058.1'},
{value: 'aabr07045071.1', id: 'AABR07045071.1'},
{value: 'aabr07045086.1', id: 'AABR07045086.1'},
{value: 'aabr07045105.1', id: 'AABR07045105.1'},
{value: 'aabr07045140.1', id: 'AABR07045140.1'},
{value: 'aabr07045163.1', id: 'AABR07045163.1'},
{value: 'aabr07045217.1', id: 'AABR07045217.1'},
{value: 'aabr07045251.1', id: 'AABR07045251.1'},
{value: 'aabr07045262.1', id: 'AABR07045262.1'},
{value: 'aabr07045274.1', id: 'AABR07045274.1'},
{value: 'aabr07045297.1', id: 'AABR07045297.1'},
{value: 'aabr07045303.1', id: 'AABR07045303.1'},
{value: 'aabr07045307.1', id: 'AABR07045307.1'},
{value: 'aabr07045313.1', id: 'AABR07045313.1'},
{value: 'aabr07045318.1', id: 'AABR07045318.1'},
{value: 'aabr07045319.1', id: 'AABR07045319.1'},
{value: 'aabr07045321.1', id: 'AABR07045321.1'},
{value: 'aabr07045321.2', id: 'AABR07045321.2'},
{value: 'aabr07045333.1', id: 'AABR07045333.1'},
{value: 'aabr07045334.1', id: 'AABR07045334.1'},
{value: 'aabr07045337.1', id: 'AABR07045337.1'},
{value: 'aabr07045339.1', id: 'AABR07045339.1'},
{value: 'aabr07045341.1', id: 'AABR07045341.1'},
{value: 'aabr07045350.1', id: 'AABR07045350.1'},
{value: 'aabr07045370.1', id: 'AABR07045370.1'},
{value: 'aabr07045373.1', id: 'AABR07045373.1'},
{value: 'aabr07045391.2', id: 'AABR07045391.2'},
{value: 'aabr07045399.1', id: 'AABR07045399.1'},
{value: 'aabr07045400.1', id: 'AABR07045400.1'},
{value: 'aabr07045403.1', id: 'AABR07045403.1'},
{value: 'aabr07045405.1', id: 'AABR07045405.1'},
{value: 'aabr07045411.1', id: 'AABR07045411.1'},
{value: 'aabr07045416.1', id: 'AABR07045416.1'},
{value: 'aabr07045427.1', id: 'AABR07045427.1'},
{value: 'aabr07045431.1', id: 'AABR07045431.1'},
{value: 'aabr07045454.1', id: 'AABR07045454.1'},
{value: 'aabr07045454.2', id: 'AABR07045454.2'},
{value: 'aabr07045462.1', id: 'AABR07045462.1'},
{value: 'aabr07045483.1', id: 'AABR07045483.1'},
{value: 'aabr07045487.1', id: 'AABR07045487.1'},
{value: 'aabr07045510.1', id: 'AABR07045510.1'},
{value: 'aabr07045523.2', id: 'AABR07045523.2'},
{value: 'aabr07045535.1', id: 'AABR07045535.1'},
{value: 'aabr07045570.1', id: 'AABR07045570.1'},
{value: 'aabr07045621.1', id: 'AABR07045621.1'},
{value: 'aabr07045632.1', id: 'AABR07045632.1'},
{value: 'aabr07045658.1', id: 'AABR07045658.1'},
{value: 'aabr07045678.1', id: 'AABR07045678.1'},
{value: 'aabr07045680.1', id: 'AABR07045680.1'},
{value: 'aabr07046628.1', id: 'AABR07046628.1'},
{value: 'aabr07046635.1', id: 'AABR07046635.1'},
{value: 'aabr07046657.1', id: 'AABR07046657.1'},
{value: 'aabr07046669.1', id: 'AABR07046669.1'},
{value: 'aabr07046669.2', id: 'AABR07046669.2'},
{value: 'aabr07046699.1', id: 'AABR07046699.1'},
{value: 'aabr07046707.1', id: 'AABR07046707.1'},
{value: 'aabr07046713.2', id: 'AABR07046713.2'},
{value: 'aabr07046713.3', id: 'AABR07046713.3'},
{value: 'aabr07046725.1', id: 'AABR07046725.1'},
{value: 'aabr07046727.1', id: 'AABR07046727.1'},
{value: 'aabr07046731.1', id: 'AABR07046731.1'},
{value: 'aabr07046732.1', id: 'AABR07046732.1'},
{value: 'aabr07046732.2', id: 'AABR07046732.2'},
{value: 'aabr07046738.1', id: 'AABR07046738.1'},
{value: 'aabr07046738.2', id: 'AABR07046738.2'},
{value: 'aabr07046745.1', id: 'AABR07046745.1'},
{value: 'aabr07046762.1', id: 'AABR07046762.1'},
{value: 'aabr07046763.1', id: 'AABR07046763.1'},
{value: 'aabr07046765.1', id: 'AABR07046765.1'},
{value: 'aabr07046778.1', id: 'AABR07046778.1'},
{value: 'aabr07046779.1', id: 'AABR07046779.1'},
{value: 'aabr07046793.1', id: 'AABR07046793.1'},
{value: 'aabr07046804.1', id: 'AABR07046804.1'},
{value: 'aabr07046819.1', id: 'AABR07046819.1'},
{value: 'aabr07046830.1', id: 'AABR07046830.1'},
{value: 'aabr07046866.1', id: 'AABR07046866.1'},
{value: 'aabr07046956.1', id: 'AABR07046956.1'},
{value: 'aabr07046961.1', id: 'AABR07046961.1'},
{value: 'aabr07046970.1', id: 'AABR07046970.1'},
{value: 'aabr07047008.1', id: 'AABR07047008.1'},
{value: 'aabr07047036.1', id: 'AABR07047036.1'},
{value: 'aabr07047041.1', id: 'AABR07047041.1'},
{value: 'aabr07047044.1', id: 'AABR07047044.1'},
{value: 'aabr07047044.2', id: 'AABR07047044.2'},
{value: 'aabr07047050.1', id: 'AABR07047050.1'},
{value: 'aabr07047054.2', id: 'AABR07047054.2'},
{value: 'aabr07047054.3', id: 'AABR07047054.3'},
{value: 'aabr07047062.1', id: 'AABR07047062.1'},
{value: 'aabr07047089.1', id: 'AABR07047089.1'},
{value: 'aabr07047103.1', id: 'AABR07047103.1'},
{value: 'aabr07047134.1', id: 'AABR07047134.1'},
{value: 'aabr07047174.1', id: 'AABR07047174.1'},
{value: 'aabr07047174.2', id: 'AABR07047174.2'},
{value: 'aabr07047174.3', id: 'AABR07047174.3'},
{value: 'aabr07047189.1', id: 'AABR07047189.1'},
{value: 'aabr07047191.1', id: 'AABR07047191.1'},
{value: 'aabr07047194.1', id: 'AABR07047194.1'},
{value: 'aabr07047194.2', id: 'AABR07047194.2'},
{value: 'aabr07047212.1', id: 'AABR07047212.1'},
{value: 'aabr07047213.1', id: 'AABR07047213.1'},
{value: 'aabr07047219.1', id: 'AABR07047219.1'},
{value: 'aabr07047221.1', id: 'AABR07047221.1'},
{value: 'aabr07047232.1', id: 'AABR07047232.1'},
{value: 'aabr07047237.1', id: 'AABR07047237.1'},
{value: 'aabr07047249.1', id: 'AABR07047249.1'},
{value: 'aabr07047256.1', id: 'AABR07047256.1'},
{value: 'aabr07047262.1', id: 'AABR07047262.1'},
{value: 'aabr07047293.1', id: 'AABR07047293.1'},
{value: 'aabr07047309.1', id: 'AABR07047309.1'},
{value: 'aabr07047309.2', id: 'AABR07047309.2'},
{value: 'aabr07047309.3', id: 'AABR07047309.3'},
{value: 'aabr07047316.1', id: 'AABR07047316.1'},
{value: 'aabr07047323.1', id: 'AABR07047323.1'},
{value: 'aabr07047323.2', id: 'AABR07047323.2'},
{value: 'aabr07047331.1', id: 'AABR07047331.1'},
{value: 'aabr07047360.1', id: 'AABR07047360.1'},
{value: 'aabr07047389.1', id: 'AABR07047389.1'},
{value: 'aabr07047498.1', id: 'AABR07047498.1'},
{value: 'aabr07047522.1', id: 'AABR07047522.1'},
{value: 'aabr07047528.1', id: 'AABR07047528.1'},
{value: 'aabr07047531.1', id: 'AABR07047531.1'},
{value: 'aabr07047531.2', id: 'AABR07047531.2'},
{value: 'aabr07047576.1', id: 'AABR07047576.1'},
{value: 'aabr07047580.1', id: 'AABR07047580.1'},
{value: 'aabr07047586.1', id: 'AABR07047586.1'},
{value: 'aabr07047594.1', id: 'AABR07047594.1'},
{value: 'aabr07047598.1', id: 'AABR07047598.1'},
{value: 'aabr07047604.1', id: 'AABR07047604.1'},
{value: 'aabr07047606.1', id: 'AABR07047606.1'},
{value: 'aabr07047633.1', id: 'AABR07047633.1'},
{value: 'aabr07047688.1', id: 'AABR07047688.1'},
{value: 'aabr07047693.1', id: 'AABR07047693.1'},
{value: 'aabr07047694.1', id: 'AABR07047694.1'},
{value: 'aabr07047714.1', id: 'AABR07047714.1'},
{value: 'aabr07047744.1', id: 'AABR07047744.1'},
{value: 'aabr07047744.2', id: 'AABR07047744.2'},
{value: 'aabr07047750.1', id: 'AABR07047750.1'},
{value: 'aabr07047750.2', id: 'AABR07047750.2'},
{value: 'aabr07047761.1', id: 'AABR07047761.1'},
{value: 'aabr07047771.1', id: 'AABR07047771.1'},
{value: 'aabr07047799.1', id: 'AABR07047799.1'},
{value: 'aabr07047809.1', id: 'AABR07047809.1'},
{value: 'aabr07047823.1', id: 'AABR07047823.1'},
{value: 'aabr07047833.1', id: 'AABR07047833.1'},
{value: 'aabr07047835.1', id: 'AABR07047835.1'},
{value: 'aabr07047840.1', id: 'AABR07047840.1'},
{value: 'aabr07047843.1', id: 'AABR07047843.1'},
{value: 'aabr07047844.1', id: 'AABR07047844.1'},
{value: 'aabr07047872.1', id: 'AABR07047872.1'},
{value: 'aabr07047899.1', id: 'AABR07047899.1'},
{value: 'aabr07047991.1', id: 'AABR07047991.1'},
{value: 'aabr07048006.1', id: 'AABR07048006.1'},
{value: 'aabr07048010.1', id: 'AABR07048010.1'},
{value: 'aabr07048012.1', id: 'AABR07048012.1'},
{value: 'aabr07048013.1', id: 'AABR07048013.1'},
{value: 'aabr07048031.1', id: 'AABR07048031.1'},
{value: 'aabr07048040.1', id: 'AABR07048040.1'},
{value: 'aabr07048046.1', id: 'AABR07048046.1'},
{value: 'aabr07048059.1', id: 'AABR07048059.1'},
{value: 'aabr07048064.1', id: 'AABR07048064.1'},
{value: 'aabr07048075.1', id: 'AABR07048075.1'},
{value: 'aabr07048075.2', id: 'AABR07048075.2'},
{value: 'aabr07048128.1', id: 'AABR07048128.1'},
{value: 'aabr07048139.1', id: 'AABR07048139.1'},
{value: 'aabr07048174.1', id: 'AABR07048174.1'},
{value: 'aabr07048176.1', id: 'AABR07048176.1'},
{value: 'aabr07048203.1', id: 'AABR07048203.1'},
{value: 'aabr07048211.1', id: 'AABR07048211.1'},
{value: 'aabr07048221.1', id: 'AABR07048221.1'},
{value: 'aabr07048228.1', id: 'AABR07048228.1'},
{value: 'aabr07048231.1', id: 'AABR07048231.1'},
{value: 'aabr07048249.1', id: 'AABR07048249.1'},
{value: 'aabr07048253.1', id: 'AABR07048253.1'},
{value: 'aabr07048269.1', id: 'AABR07048269.1'},
{value: 'aabr07048271.1', id: 'AABR07048271.1'},
{value: 'aabr07048280.1', id: 'AABR07048280.1'},
{value: 'aabr07048281.1', id: 'AABR07048281.1'},
{value: 'aabr07048303.1', id: 'AABR07048303.1'},
{value: 'aabr07048303.2', id: 'AABR07048303.2'},
{value: 'aabr07048303.3', id: 'AABR07048303.3'},
{value: 'aabr07048305.1', id: 'AABR07048305.1'},
{value: 'aabr07048308.1', id: 'AABR07048308.1'},
{value: 'aabr07048308.2', id: 'AABR07048308.2'},
{value: 'aabr07048308.3', id: 'AABR07048308.3'},
{value: 'aabr07048311.1', id: 'AABR07048311.1'},
{value: 'aabr07048312.1', id: 'AABR07048312.1'},
{value: 'aabr07048312.2', id: 'AABR07048312.2'},
{value: 'aabr07048321.1', id: 'AABR07048321.1'},
{value: 'aabr07048321.2', id: 'AABR07048321.2'},
{value: 'aabr07048322.1', id: 'AABR07048322.1'},
{value: 'aabr07048340.1', id: 'AABR07048340.1'},
{value: 'aabr07048379.1', id: 'AABR07048379.1'},
{value: 'aabr07048387.1', id: 'AABR07048387.1'},
{value: 'aabr07048397.1', id: 'AABR07048397.1'},
{value: 'aabr07048397.2', id: 'AABR07048397.2'},
{value: 'aabr07048401.1', id: 'AABR07048401.1'},
{value: 'aabr07048419.3', id: 'AABR07048419.3'},
{value: 'aabr07048420.1', id: 'AABR07048420.1'},
{value: 'aabr07048420.6', id: 'AABR07048420.6'},
{value: 'aabr07048421.1', id: 'AABR07048421.1'},
{value: 'aabr07048427.1', id: 'AABR07048427.1'},
{value: 'aabr07048427.3', id: 'AABR07048427.3'},
{value: 'aabr07048435.1', id: 'AABR07048435.1'},
{value: 'aabr07048436.1', id: 'AABR07048436.1'},
{value: 'aabr07048439.1', id: 'AABR07048439.1'},
{value: 'aabr07048463.1', id: 'AABR07048463.1'},
{value: 'aabr07048463.2', id: 'AABR07048463.2'},
{value: 'aabr07048463.3', id: 'AABR07048463.3'},
{value: 'aabr07048473.1', id: 'AABR07048473.1'},
{value: 'aabr07048475.1', id: 'AABR07048475.1'},
{value: 'aabr07048478.1', id: 'AABR07048478.1'},
{value: 'aabr07048482.1', id: 'AABR07048482.1'},
{value: 'aabr07048483.1', id: 'AABR07048483.1'},
{value: 'aabr07048485.1', id: 'AABR07048485.1'},
{value: 'aabr07048486.1', id: 'AABR07048486.1'},
{value: 'aabr07048487.1', id: 'AABR07048487.1'},
{value: 'aabr07048487.2', id: 'AABR07048487.2'},
{value: 'aabr07048510.1', id: 'AABR07048510.1'},
{value: 'aabr07048521.1', id: 'AABR07048521.1'},
{value: 'aabr07048553.1', id: 'AABR07048553.1'},
{value: 'aabr07048571.1', id: 'AABR07048571.1'},
{value: 'aabr07048600.1', id: 'AABR07048600.1'},
{value: 'aabr07048624.1', id: 'AABR07048624.1'},
{value: 'aabr07048636.1', id: 'AABR07048636.1'},
{value: 'aabr07048637.1', id: 'AABR07048637.1'},
{value: 'aabr07048648.1', id: 'AABR07048648.1'},
{value: 'aabr07048653.1', id: 'AABR07048653.1'},
{value: 'aabr07048689.1', id: 'AABR07048689.1'},
{value: 'aabr07048698.1', id: 'AABR07048698.1'},
{value: 'aabr07048726.1', id: 'AABR07048726.1'},
{value: 'aabr07048735.1', id: 'AABR07048735.1'},
{value: 'aabr07048762.1', id: 'AABR07048762.1'},
{value: 'aabr07048770.1', id: 'AABR07048770.1'},
{value: 'aabr07048785.1', id: 'AABR07048785.1'},
{value: 'aabr07048791.1', id: 'AABR07048791.1'},
{value: 'aabr07048800.1', id: 'AABR07048800.1'},
{value: 'aabr07048856.1', id: 'AABR07048856.1'},
{value: 'aabr07048878.1', id: 'AABR07048878.1'},
{value: 'aabr07048878.2', id: 'AABR07048878.2'},
{value: 'aabr07048892.1', id: 'AABR07048892.1'},
{value: 'aabr07048899.1', id: 'AABR07048899.1'},
{value: 'aabr07048900.1', id: 'AABR07048900.1'},
{value: 'aabr07048939.1', id: 'AABR07048939.1'},
{value: 'aabr07048957.1', id: 'AABR07048957.1'},
{value: 'aabr07048966.1', id: 'AABR07048966.1'},
{value: 'aabr07048977.1', id: 'AABR07048977.1'},
{value: 'aabr07048992.1', id: 'AABR07048992.1'},
{value: 'aabr07048992.2', id: 'AABR07048992.2'},
{value: 'aabr07049002.1', id: 'AABR07049002.1'},
{value: 'aabr07049032.1', id: 'AABR07049032.1'},
{value: 'aabr07049033.1', id: 'AABR07049033.1'},
{value: 'aabr07049033.2', id: 'AABR07049033.2'},
{value: 'aabr07049035.1', id: 'AABR07049035.1'},
{value: 'aabr07049037.1', id: 'AABR07049037.1'},
{value: 'aabr07049038.1', id: 'AABR07049038.1'},
{value: 'aabr07049038.2', id: 'AABR07049038.2'},
{value: 'aabr07049040.1', id: 'AABR07049040.1'},
{value: 'aabr07049043.1', id: 'AABR07049043.1'},
{value: 'aabr07049060.1', id: 'AABR07049060.1'},
{value: 'aabr07049061.1', id: 'AABR07049061.1'},
{value: 'aabr07049064.1', id: 'AABR07049064.1'},
{value: 'aabr07049074.1', id: 'AABR07049074.1'},
{value: 'aabr07049079.1', id: 'AABR07049079.1'},
{value: 'aabr07049085.1', id: 'AABR07049085.1'},
{value: 'aabr07049111.1', id: 'AABR07049111.1'},
{value: 'aabr07049111.2', id: 'AABR07049111.2'},
{value: 'aabr07049117.1', id: 'AABR07049117.1'},
{value: 'aabr07049134.1', id: 'AABR07049134.1'},
{value: 'aabr07049134.2', id: 'AABR07049134.2'},
{value: 'aabr07049134.3', id: 'AABR07049134.3'},
{value: 'aabr07049134.4', id: 'AABR07049134.4'},
{value: 'aabr07049149.1', id: 'AABR07049149.1'},
{value: 'aabr07049152.1', id: 'AABR07049152.1'},
{value: 'aabr07049156.1', id: 'AABR07049156.1'},
{value: 'aabr07049184.1', id: 'AABR07049184.1'},
{value: 'aabr07049190.1', id: 'AABR07049190.1'},
{value: 'aabr07049196.1', id: 'AABR07049196.1'},
{value: 'aabr07049223.1', id: 'AABR07049223.1'},
{value: 'aabr07049228.1', id: 'AABR07049228.1'},
{value: 'aabr07049257.1', id: 'AABR07049257.1'},
{value: 'aabr07049282.1', id: 'AABR07049282.1'},
{value: 'aabr07049288.1', id: 'AABR07049288.1'},
{value: 'aabr07049292.1', id: 'AABR07049292.1'},
{value: 'aabr07049299.1', id: 'AABR07049299.1'},
{value: 'aabr07049302.1', id: 'AABR07049302.1'},
{value: 'aabr07049320.1', id: 'AABR07049320.1'},
{value: 'aabr07049320.2', id: 'AABR07049320.2'},
{value: 'aabr07049320.3', id: 'AABR07049320.3'},
{value: 'aabr07049326.1', id: 'AABR07049326.1'},
{value: 'aabr07049329.1', id: 'AABR07049329.1'},
{value: 'aabr07049336.1', id: 'AABR07049336.1'},
{value: 'aabr07049347.1', id: 'AABR07049347.1'},
{value: 'aabr07049348.1', id: 'AABR07049348.1'},
{value: 'aabr07049350.1', id: 'AABR07049350.1'},
{value: 'aabr07049353.1', id: 'AABR07049353.1'},
{value: 'aabr07049353.2', id: 'AABR07049353.2'},
{value: 'aabr07049385.1', id: 'AABR07049385.1'},
{value: 'aabr07049386.1', id: 'AABR07049386.1'},
{value: 'aabr07049389.1', id: 'AABR07049389.1'},
{value: 'aabr07049401.1', id: 'AABR07049401.1'},
{value: 'aabr07049405.1', id: 'AABR07049405.1'},
{value: 'aabr07049408.1', id: 'AABR07049408.1'},
{value: 'aabr07049437.1', id: 'AABR07049437.1'},
{value: 'aabr07049455.1', id: 'AABR07049455.1'},
{value: 'aabr07049466.1', id: 'AABR07049466.1'},
{value: 'aabr07049474.1', id: 'AABR07049474.1'},
{value: 'aabr07049491.1', id: 'AABR07049491.1'},
{value: 'aabr07049497.1', id: 'AABR07049497.1'},
{value: 'aabr07049499.1', id: 'AABR07049499.1'},
{value: 'aabr07049503.1', id: 'AABR07049503.1'},
{value: 'aabr07049508.1', id: 'AABR07049508.1'},
{value: 'aabr07049514.1', id: 'AABR07049514.1'},
{value: 'aabr07049516.1', id: 'AABR07049516.1'},
{value: 'aabr07049520.1', id: 'AABR07049520.1'},
{value: 'aabr07049524.1', id: 'AABR07049524.1'},
{value: 'aabr07049535.1', id: 'AABR07049535.1'},
{value: 'aabr07049535.2', id: 'AABR07049535.2'},
{value: 'aabr07049539.1', id: 'AABR07049539.1'},
{value: 'aabr07049541.1', id: 'AABR07049541.1'},
{value: 'aabr07049547.1', id: 'AABR07049547.1'},
{value: 'aabr07049550.1', id: 'AABR07049550.1'},
{value: 'aabr07049554.1', id: 'AABR07049554.1'},
{value: 'aabr07049554.2', id: 'AABR07049554.2'},
{value: 'aabr07049569.1', id: 'AABR07049569.1'},
{value: 'aabr07049574.1', id: 'AABR07049574.1'},
{value: 'aabr07049578.1', id: 'AABR07049578.1'},
{value: 'aabr07049579.1', id: 'AABR07049579.1'},
{value: 'aabr07049579.2', id: 'AABR07049579.2'},
{value: 'aabr07049648.1', id: 'AABR07049648.1'},
{value: 'aabr07049669.1', id: 'AABR07049669.1'},
{value: 'aabr07049681.1', id: 'AABR07049681.1'},
{value: 'aabr07049688.1', id: 'AABR07049688.1'},
{value: 'aabr07049695.1', id: 'AABR07049695.1'},
{value: 'aabr07049695.2', id: 'AABR07049695.2'},
{value: 'aabr07049695.3', id: 'AABR07049695.3'},
{value: 'aabr07049695.4', id: 'AABR07049695.4'},
{value: 'aabr07049701.1', id: 'AABR07049701.1'},
{value: 'aabr07049722.1', id: 'AABR07049722.1'},
{value: 'aabr07049728.1', id: 'AABR07049728.1'},
{value: 'aabr07049760.2', id: 'AABR07049760.2'},
{value: 'aabr07049768.1', id: 'AABR07049768.1'},
{value: 'aabr07049769.1', id: 'AABR07049769.1'},
{value: 'aabr07049769.2', id: 'AABR07049769.2'},
{value: 'aabr07049773.1', id: 'AABR07049773.1'},
{value: 'aabr07049792.1', id: 'AABR07049792.1'},
{value: 'aabr07049799.1', id: 'AABR07049799.1'},
{value: 'aabr07049801.1', id: 'AABR07049801.1'},
{value: 'aabr07049815.1', id: 'AABR07049815.1'},
{value: 'aabr07049821.2', id: 'AABR07049821.2'},
{value: 'aabr07049862.2', id: 'AABR07049862.2'},
{value: 'aabr07049866.1', id: 'AABR07049866.1'},
{value: 'aabr07049884.1', id: 'AABR07049884.1'},
{value: 'aabr07049886.1', id: 'AABR07049886.1'},
{value: 'aabr07049886.2', id: 'AABR07049886.2'},
{value: 'aabr07049886.3', id: 'AABR07049886.3'},
{value: 'aabr07049890.1', id: 'AABR07049890.1'},
{value: 'aabr07049892.1', id: 'AABR07049892.1'},
{value: 'aabr07049903.1', id: 'AABR07049903.1'},
{value: 'aabr07049918.1', id: 'AABR07049918.1'},
{value: 'aabr07049918.2', id: 'AABR07049918.2'},
{value: 'aabr07049948.1', id: 'AABR07049948.1'},
{value: 'aabr07049960.1', id: 'AABR07049960.1'},
{value: 'aabr07049960.2', id: 'AABR07049960.2'},
{value: 'aabr07049962.1', id: 'AABR07049962.1'},
{value: 'aabr07050006.1', id: 'AABR07050006.1'},
{value: 'aabr07050017.1', id: 'AABR07050017.1'},
{value: 'aabr07050017.2', id: 'AABR07050017.2'},
{value: 'aabr07050041.1', id: 'AABR07050041.1'},
{value: 'aabr07050041.2', id: 'AABR07050041.2'},
{value: 'aabr07050044.1', id: 'AABR07050044.1'},
{value: 'aabr07050052.1', id: 'AABR07050052.1'},
{value: 'aabr07050056.1', id: 'AABR07050056.1'},
{value: 'aabr07050057.1', id: 'AABR07050057.1'},
{value: 'aabr07050061.1', id: 'AABR07050061.1'},
{value: 'aabr07050116.1', id: 'AABR07050116.1'},
{value: 'aabr07050134.1', id: 'AABR07050134.1'},
{value: 'aabr07050135.1', id: 'AABR07050135.1'},
{value: 'aabr07050146.1', id: 'AABR07050146.1'},
{value: 'aabr07050149.1', id: 'AABR07050149.1'},
{value: 'aabr07050156.1', id: 'AABR07050156.1'},
{value: 'aabr07050161.1', id: 'AABR07050161.1'},
{value: 'aabr07050176.1', id: 'AABR07050176.1'},
{value: 'aabr07050212.1', id: 'AABR07050212.1'},
{value: 'aabr07050222.1', id: 'AABR07050222.1'},
{value: 'aabr07050225.1', id: 'AABR07050225.1'},
{value: 'aabr07050253.1', id: 'AABR07050253.1'},
{value: 'aabr07050265.1', id: 'AABR07050265.1'},
{value: 'aabr07050274.1', id: 'AABR07050274.1'},
{value: 'aabr07050283.1', id: 'AABR07050283.1'},
{value: 'aabr07050283.2', id: 'AABR07050283.2'},
{value: 'aabr07050288.1', id: 'AABR07050288.1'},
{value: 'aabr07050298.1', id: 'AABR07050298.1'},
{value: 'aabr07050304.1', id: 'AABR07050304.1'},
{value: 'aabr07050317.1', id: 'AABR07050317.1'},
{value: 'aabr07050321.1', id: 'AABR07050321.1'},
{value: 'aabr07050321.2', id: 'AABR07050321.2'},
{value: 'aabr07050336.1', id: 'AABR07050336.1'},
{value: 'aabr07050344.1', id: 'AABR07050344.1'},
{value: 'aabr07050354.1', id: 'AABR07050354.1'},
{value: 'aabr07050391.1', id: 'AABR07050391.1'},
{value: 'aabr07050393.1', id: 'AABR07050393.1'},
{value: 'aabr07050415.1', id: 'AABR07050415.1'},
{value: 'aabr07050423.1', id: 'AABR07050423.1'},
{value: 'aabr07050445.1', id: 'AABR07050445.1'},
{value: 'aabr07050457.1', id: 'AABR07050457.1'},
{value: 'aabr07050466.1', id: 'AABR07050466.1'},
{value: 'aabr07050482.1', id: 'AABR07050482.1'},
{value: 'aabr07050484.1', id: 'AABR07050484.1'},
{value: 'aabr07050545.2', id: 'AABR07050545.2'},
{value: 'aabr07050548.1', id: 'AABR07050548.1'},
{value: 'aabr07050561.1', id: 'AABR07050561.1'},
{value: 'aabr07050593.1', id: 'AABR07050593.1'},
{value: 'aabr07050600.1', id: 'AABR07050600.1'},
{value: 'aabr07050614.1', id: 'AABR07050614.1'},
{value: 'aabr07050637.1', id: 'AABR07050637.1'},
{value: 'aabr07050646.1', id: 'AABR07050646.1'},
{value: 'aabr07050648.1', id: 'AABR07050648.1'},
{value: 'aabr07050652.1', id: 'AABR07050652.1'},
{value: 'aabr07051177.1', id: 'AABR07051177.1'},
{value: 'aabr07051190.1', id: 'AABR07051190.1'},
{value: 'aabr07051205.1', id: 'AABR07051205.1'},
{value: 'aabr07051207.1', id: 'AABR07051207.1'},
{value: 'aabr07051215.1', id: 'AABR07051215.1'},
{value: 'aabr07051218.1', id: 'AABR07051218.1'},
{value: 'aabr07051219.1', id: 'AABR07051219.1'},
{value: 'aabr07051238.1', id: 'AABR07051238.1'},
{value: 'aabr07051240.1', id: 'AABR07051240.1'},
{value: 'aabr07051241.1', id: 'AABR07051241.1'},
{value: 'aabr07051248.1', id: 'AABR07051248.1'},
{value: 'aabr07051250.1', id: 'AABR07051250.1'},
{value: 'aabr07051250.2', id: 'AABR07051250.2'},
{value: 'aabr07051251.1', id: 'AABR07051251.1'},
{value: 'aabr07051255.1', id: 'AABR07051255.1'},
{value: 'aabr07051260.1', id: 'AABR07051260.1'},
{value: 'aabr07051266.1', id: 'AABR07051266.1'},
{value: 'aabr07051310.1', id: 'AABR07051310.1'},
{value: 'aabr07051312.1', id: 'AABR07051312.1'},
{value: 'aabr07051324.1', id: 'AABR07051324.1'},
{value: 'aabr07051325.1', id: 'AABR07051325.1'},
{value: 'aabr07051326.1', id: 'AABR07051326.1'},
{value: 'aabr07051348.1', id: 'AABR07051348.1'},
{value: 'aabr07051348.2', id: 'AABR07051348.2'},
{value: 'aabr07051370.1', id: 'AABR07051370.1'},
{value: 'aabr07051374.1', id: 'AABR07051374.1'},
{value: 'aabr07051376.1', id: 'AABR07051376.1'},
{value: 'aabr07051376.2', id: 'AABR07051376.2'},
{value: 'aabr07051395.1', id: 'AABR07051395.1'},
{value: 'aabr07051399.1', id: 'AABR07051399.1'},
{value: 'aabr07051403.1', id: 'AABR07051403.1'},
{value: 'aabr07051404.1', id: 'AABR07051404.1'},
{value: 'aabr07051418.1', id: 'AABR07051418.1'},
{value: 'aabr07051426.1', id: 'AABR07051426.1'},
{value: 'aabr07051431.1', id: 'AABR07051431.1'},
{value: 'aabr07051438.1', id: 'AABR07051438.1'},
{value: 'aabr07051450.1', id: 'AABR07051450.1'},
{value: 'aabr07051451.1', id: 'AABR07051451.1'},
{value: 'aabr07051462.1', id: 'AABR07051462.1'},
{value: 'aabr07051485.1', id: 'AABR07051485.1'},
{value: 'aabr07051501.1', id: 'AABR07051501.1'},
{value: 'aabr07051511.1', id: 'AABR07051511.1'},
{value: 'aabr07051515.1', id: 'AABR07051515.1'},
{value: 'aabr07051515.2', id: 'AABR07051515.2'},
{value: 'aabr07051532.1', id: 'AABR07051532.1'},
{value: 'aabr07051532.2', id: 'AABR07051532.2'},
{value: 'aabr07051533.1', id: 'AABR07051533.1'},
{value: 'aabr07051533.2', id: 'AABR07051533.2'},
{value: 'aabr07051534.1', id: 'AABR07051534.1'},
{value: 'aabr07051535.1', id: 'AABR07051535.1'},
{value: 'aabr07051548.1', id: 'AABR07051548.1'},
{value: 'aabr07051548.2', id: 'AABR07051548.2'},
{value: 'aabr07051550.1', id: 'AABR07051550.1'},
{value: 'aabr07051551.1', id: 'AABR07051551.1'},
{value: 'aabr07051551.2', id: 'AABR07051551.2'},
{value: 'aabr07051555.1', id: 'AABR07051555.1'},
{value: 'aabr07051556.1', id: 'AABR07051556.1'},
{value: 'aabr07051562.1', id: 'AABR07051562.1'},
{value: 'aabr07051564.1', id: 'AABR07051564.1'},
{value: 'aabr07051565.1', id: 'AABR07051565.1'},
{value: 'aabr07051567.1', id: 'AABR07051567.1'},
{value: 'aabr07051578.1', id: 'AABR07051578.1'},
{value: 'aabr07051583.1', id: 'AABR07051583.1'},
{value: 'aabr07051592.1', id: 'AABR07051592.1'},
{value: 'aabr07051592.2', id: 'AABR07051592.2'},
{value: 'aabr07051611.1', id: 'AABR07051611.1'},
{value: 'aabr07051626.1', id: 'AABR07051626.1'},
{value: 'aabr07051626.2', id: 'AABR07051626.2'},
{value: 'aabr07051639.1', id: 'AABR07051639.1'},
{value: 'aabr07051642.1', id: 'AABR07051642.1'},
{value: 'aabr07051646.1', id: 'AABR07051646.1'},
{value: 'aabr07051652.1', id: 'AABR07051652.1'},
{value: 'aabr07051658.1', id: 'AABR07051658.1'},
{value: 'aabr07051659.1', id: 'AABR07051659.1'},
{value: 'aabr07051665.1', id: 'AABR07051665.1'},
{value: 'aabr07051666.1', id: 'AABR07051666.1'},
{value: 'aabr07051670.1', id: 'AABR07051670.1'},
{value: 'aabr07051671.1', id: 'AABR07051671.1'},
{value: 'aabr07051673.1', id: 'AABR07051673.1'},
{value: 'aabr07051675.1', id: 'AABR07051675.1'},
{value: 'aabr07051678.1', id: 'AABR07051678.1'},
{value: 'aabr07051680.1', id: 'AABR07051680.1'},
{value: 'aabr07051684.1', id: 'AABR07051684.1'},
{value: 'aabr07051689.1', id: 'AABR07051689.1'},
{value: 'aabr07051692.1', id: 'AABR07051692.1'},
{value: 'aabr07051700.1', id: 'AABR07051700.1'},
{value: 'aabr07051701.1', id: 'AABR07051701.1'},
{value: 'aabr07051707.1', id: 'AABR07051707.1'},
{value: 'aabr07051708.1', id: 'AABR07051708.1'},
{value: 'aabr07051715.1', id: 'AABR07051715.1'},
{value: 'aabr07051716.1', id: 'AABR07051716.1'},
{value: 'aabr07051716.2', id: 'AABR07051716.2'},
{value: 'aabr07051718.1', id: 'AABR07051718.1'},
{value: 'aabr07051726.1', id: 'AABR07051726.1'},
{value: 'aabr07051730.1', id: 'AABR07051730.1'},
{value: 'aabr07051731.1', id: 'AABR07051731.1'},
{value: 'aabr07051733.1', id: 'AABR07051733.1'},
{value: 'aabr07051733.2', id: 'AABR07051733.2'},
{value: 'aabr07051733.3', id: 'AABR07051733.3'},
{value: 'aabr07051735.1', id: 'AABR07051735.1'},
{value: 'aabr07051741.1', id: 'AABR07051741.1'},
{value: 'aabr07051746.1', id: 'AABR07051746.1'},
{value: 'aabr07051787.1', id: 'AABR07051787.1'},
{value: 'aabr07051791.1', id: 'AABR07051791.1'},
{value: 'aabr07051793.1', id: 'AABR07051793.1'},
{value: 'aabr07051796.1', id: 'AABR07051796.1'},
{value: 'aabr07051804.1', id: 'AABR07051804.1'},
{value: 'aabr07051808.1', id: 'AABR07051808.1'},
{value: 'aabr07051813.1', id: 'AABR07051813.1'},
{value: 'aabr07051827.1', id: 'AABR07051827.1'},
{value: 'aabr07051831.1', id: 'AABR07051831.1'},
{value: 'aabr07051878.1', id: 'AABR07051878.1'},
{value: 'aabr07051879.1', id: 'AABR07051879.1'},
{value: 'aabr07051882.1', id: 'AABR07051882.1'},
{value: 'aabr07051882.2', id: 'AABR07051882.2'},
{value: 'aabr07051892.1', id: 'AABR07051892.1'},
{value: 'aabr07051908.1', id: 'AABR07051908.1'},
{value: 'aabr07051939.1', id: 'AABR07051939.1'},
{value: 'aabr07051947.1', id: 'AABR07051947.1'},
{value: 'aabr07051952.1', id: 'AABR07051952.1'},
{value: 'aabr07051954.1', id: 'AABR07051954.1'},
{value: 'aabr07051957.1', id: 'AABR07051957.1'},
{value: 'aabr07051957.2', id: 'AABR07051957.2'},
{value: 'aabr07051982.1', id: 'AABR07051982.1'},
{value: 'aabr07051982.2', id: 'AABR07051982.2'},
{value: 'aabr07051984.1', id: 'AABR07051984.1'},
{value: 'aabr07051986.1', id: 'AABR07051986.1'},
{value: 'aabr07051996.1', id: 'AABR07051996.1'},
{value: 'aabr07052048.1', id: 'AABR07052048.1'},
{value: 'aabr07052048.2', id: 'AABR07052048.2'},
{value: 'aabr07052064.1', id: 'AABR07052064.1'},
{value: 'aabr07052065.1', id: 'AABR07052065.1'},
{value: 'aabr07052084.1', id: 'AABR07052084.1'},
{value: 'aabr07052085.2', id: 'AABR07052085.2'},
{value: 'aabr07052085.3', id: 'AABR07052085.3'},
{value: 'aabr07052091.1', id: 'AABR07052091.1'},
{value: 'aabr07052125.1', id: 'AABR07052125.1'},
{value: 'aabr07052130.1', id: 'AABR07052130.1'},
{value: 'aabr07052148.1', id: 'AABR07052148.1'},
{value: 'aabr07052195.1', id: 'AABR07052195.1'},
{value: 'aabr07052198.1', id: 'AABR07052198.1'},
{value: 'aabr07052199.1', id: 'AABR07052199.1'},
{value: 'aabr07052247.1', id: 'AABR07052247.1'},
{value: 'aabr07052263.1', id: 'AABR07052263.1'},
{value: 'aabr07052296.1', id: 'AABR07052296.1'},
{value: 'aabr07052310.1', id: 'AABR07052310.1'},
{value: 'aabr07052322.1', id: 'AABR07052322.1'},
{value: 'aabr07052324.1', id: 'AABR07052324.1'},
{value: 'aabr07052327.1', id: 'AABR07052327.1'},
{value: 'aabr07052347.1', id: 'AABR07052347.1'},
{value: 'aabr07052387.1', id: 'AABR07052387.1'},
{value: 'aabr07052389.1', id: 'AABR07052389.1'},
{value: 'aabr07052390.1', id: 'AABR07052390.1'},
{value: 'aabr07052402.1', id: 'AABR07052402.1'},
{value: 'aabr07052405.1', id: 'AABR07052405.1'},
{value: 'aabr07052430.1', id: 'AABR07052430.1'},
{value: 'aabr07052431.1', id: 'AABR07052431.1'},
{value: 'aabr07052432.1', id: 'AABR07052432.1'},
{value: 'aabr07052441.1', id: 'AABR07052441.1'},
{value: 'aabr07052443.1', id: 'AABR07052443.1'},
{value: 'aabr07052452.1', id: 'AABR07052452.1'},
{value: 'aabr07052458.1', id: 'AABR07052458.1'},
{value: 'aabr07052465.1', id: 'AABR07052465.1'},
{value: 'aabr07052486.1', id: 'AABR07052486.1'},
{value: 'aabr07052498.1', id: 'AABR07052498.1'},
{value: 'aabr07052500.1', id: 'AABR07052500.1'},
{value: 'aabr07052502.1', id: 'AABR07052502.1'},
{value: 'aabr07052504.1', id: 'AABR07052504.1'},
{value: 'aabr07052508.1', id: 'AABR07052508.1'},
{value: 'aabr07052509.1', id: 'AABR07052509.1'},
{value: 'aabr07052511.1', id: 'AABR07052511.1'},
{value: 'aabr07052519.1', id: 'AABR07052519.1'},
{value: 'aabr07052521.1', id: 'AABR07052521.1'},
{value: 'aabr07052523.1', id: 'AABR07052523.1'},
{value: 'aabr07052523.2', id: 'AABR07052523.2'},
{value: 'aabr07052525.1', id: 'AABR07052525.1'},
{value: 'aabr07052529.1', id: 'AABR07052529.1'},
{value: 'aabr07052541.1', id: 'AABR07052541.1'},
{value: 'aabr07052549.1', id: 'AABR07052549.1'},
{value: 'aabr07052550.1', id: 'AABR07052550.1'},
{value: 'aabr07052550.2', id: 'AABR07052550.2'},
{value: 'aabr07052550.3', id: 'AABR07052550.3'},
{value: 'aabr07052554.1', id: 'AABR07052554.1'},
{value: 'aabr07052557.1', id: 'AABR07052557.1'},
{value: 'aabr07052559.2', id: 'AABR07052559.2'},
{value: 'aabr07052568.1', id: 'AABR07052568.1'},
{value: 'aabr07052573.1', id: 'AABR07052573.1'},
{value: 'aabr07052585.1', id: 'AABR07052585.1'},
{value: 'aabr07052585.2', id: 'AABR07052585.2'},
{value: 'aabr07052585.3', id: 'AABR07052585.3'},
{value: 'aabr07052587.1', id: 'AABR07052587.1'},
{value: 'aabr07052587.2', id: 'AABR07052587.2'},
{value: 'aabr07052587.3', id: 'AABR07052587.3'},
{value: 'aabr07052587.4', id: 'AABR07052587.4'},
{value: 'aabr07052588.1', id: 'AABR07052588.1'},
{value: 'aabr07052589.1', id: 'AABR07052589.1'},
{value: 'aabr07052598.1', id: 'AABR07052598.1'},
{value: 'aabr07052608.1', id: 'AABR07052608.1'},
{value: 'aabr07052610.1', id: 'AABR07052610.1'},
{value: 'aabr07052611.1', id: 'AABR07052611.1'},
{value: 'aabr07052611.2', id: 'AABR07052611.2'},
{value: 'aabr07052613.1', id: 'AABR07052613.1'},
{value: 'aabr07052639.1', id: 'AABR07052639.1'},
{value: 'aabr07052643.1', id: 'AABR07052643.1'},
{value: 'aabr07052664.1', id: 'AABR07052664.1'},
{value: 'aabr07052666.1', id: 'AABR07052666.1'},
{value: 'aabr07052686.1', id: 'AABR07052686.1'},
{value: 'aabr07052689.1', id: 'AABR07052689.1'},
{value: 'aabr07052692.1', id: 'AABR07052692.1'},
{value: 'aabr07052697.1', id: 'AABR07052697.1'},
{value: 'aabr07052726.1', id: 'AABR07052726.1'},
{value: 'aabr07052729.1', id: 'AABR07052729.1'},
{value: 'aabr07052730.1', id: 'AABR07052730.1'},
{value: 'aabr07052730.2', id: 'AABR07052730.2'},
{value: 'aabr07052750.1', id: 'AABR07052750.1'},
{value: 'aabr07052750.2', id: 'AABR07052750.2'},
{value: 'aabr07052755.1', id: 'AABR07052755.1'},
{value: 'aabr07052755.2', id: 'AABR07052755.2'},
{value: 'aabr07052762.1', id: 'AABR07052762.1'},
{value: 'aabr07052768.1', id: 'AABR07052768.1'},
{value: 'aabr07052780.1', id: 'AABR07052780.1'},
{value: 'aabr07052780.2', id: 'AABR07052780.2'},
{value: 'aabr07052788.1', id: 'AABR07052788.1'},
{value: 'aabr07052795.1', id: 'AABR07052795.1'},
{value: 'aabr07052795.2', id: 'AABR07052795.2'},
{value: 'aabr07052795.3', id: 'AABR07052795.3'},
{value: 'aabr07052816.1', id: 'AABR07052816.1'},
{value: 'aabr07052831.1', id: 'AABR07052831.1'},
{value: 'aabr07052844.1', id: 'AABR07052844.1'},
{value: 'aabr07052846.1', id: 'AABR07052846.1'},
{value: 'aabr07052848.1', id: 'AABR07052848.1'},
{value: 'aabr07052859.1', id: 'AABR07052859.1'},
{value: 'aabr07052890.1', id: 'AABR07052890.1'},
{value: 'aabr07052892.1', id: 'AABR07052892.1'},
{value: 'aabr07052894.1', id: 'AABR07052894.1'},
{value: 'aabr07052894.2', id: 'AABR07052894.2'},
{value: 'aabr07052895.1', id: 'AABR07052895.1'},
{value: 'aabr07052895.2', id: 'AABR07052895.2'},
{value: 'aabr07052897.1', id: 'AABR07052897.1'},
{value: 'aabr07052897.2', id: 'AABR07052897.2'},
{value: 'aabr07052897.3', id: 'AABR07052897.3'},
{value: 'aabr07052903.1', id: 'AABR07052903.1'},
{value: 'aabr07052904.1', id: 'AABR07052904.1'},
{value: 'aabr07052904.2', id: 'AABR07052904.2'},
{value: 'aabr07052915.1', id: 'AABR07052915.1'},
{value: 'aabr07052923.1', id: 'AABR07052923.1'},
{value: 'aabr07052926.1', id: 'AABR07052926.1'},
{value: 'aabr07052966.1', id: 'AABR07052966.1'},
{value: 'aabr07053065.1', id: 'AABR07053065.1'},
{value: 'aabr07053091.1', id: 'AABR07053091.1'},
{value: 'aabr07053134.1', id: 'AABR07053134.1'},
{value: 'aabr07053136.1', id: 'AABR07053136.1'},
{value: 'aabr07053152.1', id: 'AABR07053152.1'},
{value: 'aabr07053159.1', id: 'AABR07053159.1'},
{value: 'aabr07053166.1', id: 'AABR07053166.1'},
{value: 'aabr07053169.1', id: 'AABR07053169.1'},
{value: 'aabr07053172.1', id: 'AABR07053172.1'},
{value: 'aabr07053179.1', id: 'AABR07053179.1'},
{value: 'aabr07053180.1', id: 'AABR07053180.1'},
{value: 'aabr07053185.1', id: 'AABR07053185.1'},
{value: 'aabr07053188.1', id: 'AABR07053188.1'},
{value: 'aabr07053198.1', id: 'AABR07053198.1'},
{value: 'aabr07053236.1', id: 'AABR07053236.1'},
{value: 'aabr07053283.1', id: 'AABR07053283.1'},
{value: 'aabr07053293.1', id: 'AABR07053293.1'},
{value: 'aabr07053303.1', id: 'AABR07053303.1'},
{value: 'aabr07053304.1', id: 'AABR07053304.1'},
{value: 'aabr07053402.1', id: 'AABR07053402.1'},
{value: 'aabr07053412.1', id: 'AABR07053412.1'},
{value: 'aabr07053431.1', id: 'AABR07053431.1'},
{value: 'aabr07053435.1', id: 'AABR07053435.1'},
{value: 'aabr07053437.1', id: 'AABR07053437.1'},
{value: 'aabr07053470.1', id: 'AABR07053470.1'},
{value: 'aabr07053471.1', id: 'AABR07053471.1'},
{value: 'aabr07053472.1', id: 'AABR07053472.1'},
{value: 'aabr07053479.1', id: 'AABR07053479.1'},
{value: 'aabr07053480.1', id: 'AABR07053480.1'},
{value: 'aabr07053481.1', id: 'AABR07053481.1'},
{value: 'aabr07053483.1', id: 'AABR07053483.1'},
{value: 'aabr07053483.2', id: 'AABR07053483.2'},
{value: 'aabr07053495.1', id: 'AABR07053495.1'},
{value: 'aabr07053500.1', id: 'AABR07053500.1'},
{value: 'aabr07053500.2', id: 'AABR07053500.2'},
{value: 'aabr07053509.2', id: 'AABR07053509.2'},
{value: 'aabr07053516.1', id: 'AABR07053516.1'},
{value: 'aabr07053516.2', id: 'AABR07053516.2'},
{value: 'aabr07053596.1', id: 'AABR07053596.1'},
{value: 'aabr07053613.1', id: 'AABR07053613.1'},
{value: 'aabr07053613.2', id: 'AABR07053613.2'},
{value: 'aabr07053635.1', id: 'AABR07053635.1'},
{value: 'aabr07053635.2', id: 'AABR07053635.2'},
{value: 'aabr07053667.1', id: 'AABR07053667.1'},
{value: 'aabr07053681.1', id: 'AABR07053681.1'},
{value: 'aabr07053681.2', id: 'AABR07053681.2'},
{value: 'aabr07053681.3', id: 'AABR07053681.3'},
{value: 'aabr07053681.4', id: 'AABR07053681.4'},
{value: 'aabr07053707.1', id: 'AABR07053707.1'},
{value: 'aabr07053710.1', id: 'AABR07053710.1'},
{value: 'aabr07053710.2', id: 'AABR07053710.2'},
{value: 'aabr07053713.1', id: 'AABR07053713.1'},
{value: 'aabr07053716.1', id: 'AABR07053716.1'},
{value: 'aabr07053717.1', id: 'AABR07053717.1'},
{value: 'aabr07053717.2', id: 'AABR07053717.2'},
{value: 'aabr07053723.1', id: 'AABR07053723.1'},
{value: 'aabr07053734.1', id: 'AABR07053734.1'},
{value: 'aabr07053734.2', id: 'AABR07053734.2'},
{value: 'aabr07053736.1', id: 'AABR07053736.1'},
{value: 'aabr07053741.1', id: 'AABR07053741.1'},
{value: 'aabr07053749.1', id: 'AABR07053749.1'},
{value: 'aabr07053749.2', id: 'AABR07053749.2'},
{value: 'aabr07053767.1', id: 'AABR07053767.1'},
{value: 'aabr07053771.1', id: 'AABR07053771.1'},
{value: 'aabr07053782.1', id: 'AABR07053782.1'},
{value: 'aabr07053787.1', id: 'AABR07053787.1'},
{value: 'aabr07053787.2', id: 'AABR07053787.2'},
{value: 'aabr07053812.1', id: 'AABR07053812.1'},
{value: 'aabr07053814.1', id: 'AABR07053814.1'},
{value: 'aabr07053830.1', id: 'AABR07053830.1'},
{value: 'aabr07053837.1', id: 'AABR07053837.1'},
{value: 'aabr07053847.1', id: 'AABR07053847.1'},
{value: 'aabr07053849.1', id: 'AABR07053849.1'},
{value: 'aabr07053849.2', id: 'AABR07053849.2'},
{value: 'aabr07053849.3', id: 'AABR07053849.3'},
{value: 'aabr07053850.1', id: 'AABR07053850.1'},
{value: 'aabr07053854.1', id: 'AABR07053854.1'},
{value: 'aabr07053866.1', id: 'AABR07053866.1'},
{value: 'aabr07053870.1', id: 'AABR07053870.1'},
{value: 'aabr07053879.1', id: 'AABR07053879.1'},
{value: 'aabr07053881.1', id: 'AABR07053881.1'},
{value: 'aabr07053884.1', id: 'AABR07053884.1'},
{value: 'aabr07053885.1', id: 'AABR07053885.1'},
{value: 'aabr07053886.1', id: 'AABR07053886.1'},
{value: 'aabr07053924.1', id: 'AABR07053924.1'},
{value: 'aabr07053953.1', id: 'AABR07053953.1'},
{value: 'aabr07053968.1', id: 'AABR07053968.1'},
{value: 'aabr07054000.1', id: 'AABR07054000.1'},
{value: 'aabr07054025.1', id: 'AABR07054025.1'},
{value: 'aabr07054025.2', id: 'AABR07054025.2'},
{value: 'aabr07054027.1', id: 'AABR07054027.1'},
{value: 'aabr07054062.1', id: 'AABR07054062.1'},
{value: 'aabr07054063.1', id: 'AABR07054063.1'},
{value: 'aabr07054091.1', id: 'AABR07054091.1'},
{value: 'aabr07054096.1', id: 'AABR07054096.1'},
{value: 'aabr07054117.1', id: 'AABR07054117.1'},
{value: 'aabr07054119.1', id: 'AABR07054119.1'},
{value: 'aabr07054120.1', id: 'AABR07054120.1'},
{value: 'aabr07054126.1', id: 'AABR07054126.1'},
{value: 'aabr07054129.1', id: 'AABR07054129.1'},
{value: 'aabr07054147.1', id: 'AABR07054147.1'},
{value: 'aabr07054186.1', id: 'AABR07054186.1'},
{value: 'aabr07054189.1', id: 'AABR07054189.1'},
{value: 'aabr07054232.1', id: 'AABR07054232.1'},
{value: 'aabr07054247.1', id: 'AABR07054247.1'},
{value: 'aabr07054262.1', id: 'AABR07054262.1'},
{value: 'aabr07054264.1', id: 'AABR07054264.1'},
{value: 'aabr07054266.1', id: 'AABR07054266.1'},
{value: 'aabr07054272.1', id: 'AABR07054272.1'},
{value: 'aabr07054281.1', id: 'AABR07054281.1'},
{value: 'aabr07054286.1', id: 'AABR07054286.1'},
{value: 'aabr07054292.1', id: 'AABR07054292.1'},
{value: 'aabr07054295.1', id: 'AABR07054295.1'},
{value: 'aabr07054303.1', id: 'AABR07054303.1'},
{value: 'aabr07054315.1', id: 'AABR07054315.1'},
{value: 'aabr07054319.2', id: 'AABR07054319.2'},
{value: 'aabr07054329.1', id: 'AABR07054329.1'},
{value: 'aabr07054341.1', id: 'AABR07054341.1'},
{value: 'aabr07054341.2', id: 'AABR07054341.2'},
{value: 'aabr07054352.1', id: 'AABR07054352.1'},
{value: 'aabr07054352.2', id: 'AABR07054352.2'},
{value: 'aabr07054352.3', id: 'AABR07054352.3'},
{value: 'aabr07054361.1', id: 'AABR07054361.1'},
{value: 'aabr07054362.1', id: 'AABR07054362.1'},
{value: 'aabr07054367.1', id: 'AABR07054367.1'},
{value: 'aabr07054368.1', id: 'AABR07054368.1'},
{value: 'aabr07054395.1', id: 'AABR07054395.1'},
{value: 'aabr07054399.1', id: 'AABR07054399.1'},
{value: 'aabr07054401.1', id: 'AABR07054401.1'},
{value: 'aabr07054422.1', id: 'AABR07054422.1'},
{value: 'aabr07054445.1', id: 'AABR07054445.1'},
{value: 'aabr07054456.1', id: 'AABR07054456.1'},
{value: 'aabr07054456.2', id: 'AABR07054456.2'},
{value: 'aabr07054460.1', id: 'AABR07054460.1'},
{value: 'aabr07054460.2', id: 'AABR07054460.2'},
{value: 'aabr07054460.3', id: 'AABR07054460.3'},
{value: 'aabr07054460.4', id: 'AABR07054460.4'},
{value: 'aabr07054469.1', id: 'AABR07054469.1'},
{value: 'aabr07054473.1', id: 'AABR07054473.1'},
{value: 'aabr07054481.1', id: 'AABR07054481.1'},
{value: 'aabr07054482.1', id: 'AABR07054482.1'},
{value: 'aabr07054487.1', id: 'AABR07054487.1'},
{value: 'aabr07054488.1', id: 'AABR07054488.1'},
{value: 'aabr07054490.1', id: 'AABR07054490.1'},
{value: 'aabr07054547.1', id: 'AABR07054547.1'},
{value: 'aabr07054551.1', id: 'AABR07054551.1'},
{value: 'aabr07054554.1', id: 'AABR07054554.1'},
{value: 'aabr07054562.1', id: 'AABR07054562.1'},
{value: 'aabr07054562.2', id: 'AABR07054562.2'},
{value: 'aabr07054564.1', id: 'AABR07054564.1'},
{value: 'aabr07054565.1', id: 'AABR07054565.1'},
{value: 'aabr07054578.1', id: 'AABR07054578.1'},
{value: 'aabr07054583.1', id: 'AABR07054583.1'},
{value: 'aabr07054586.1', id: 'AABR07054586.1'},
{value: 'aabr07054597.1', id: 'AABR07054597.1'},
{value: 'aabr07054608.1', id: 'AABR07054608.1'},
{value: 'aabr07054609.1', id: 'AABR07054609.1'},
{value: 'aabr07054614.1', id: 'AABR07054614.1'},
{value: 'aabr07054619.1', id: 'AABR07054619.1'},
{value: 'aabr07054655.1', id: 'AABR07054655.1'},
{value: 'aabr07054662.1', id: 'AABR07054662.1'},
{value: 'aabr07054662.2', id: 'AABR07054662.2'},
{value: 'aabr07054680.1', id: 'AABR07054680.1'},
{value: 'aabr07054705.1', id: 'AABR07054705.1'},
{value: 'aabr07054705.2', id: 'AABR07054705.2'},
{value: 'aabr07054708.1', id: 'AABR07054708.1'},
{value: 'aabr07054716.1', id: 'AABR07054716.1'},
{value: 'aabr07054716.2', id: 'AABR07054716.2'},
{value: 'aabr07054718.1', id: 'AABR07054718.1'},
{value: 'aabr07054721.1', id: 'AABR07054721.1'},
{value: 'aabr07054723.1', id: 'AABR07054723.1'},
{value: 'aabr07054752.1', id: 'AABR07054752.1'},
{value: 'aabr07054752.2', id: 'AABR07054752.2'},
{value: 'aabr07054753.1', id: 'AABR07054753.1'},
{value: 'aabr07054755.1', id: 'AABR07054755.1'},
{value: 'aabr07054801.1', id: 'AABR07054801.1'},
{value: 'aabr07054809.1', id: 'AABR07054809.1'},
{value: 'aabr07054818.1', id: 'AABR07054818.1'},
{value: 'aabr07054822.1', id: 'AABR07054822.1'},
{value: 'aabr07054832.1', id: 'AABR07054832.1'},
{value: 'aabr07054837.1', id: 'AABR07054837.1'},
{value: 'aabr07054845.1', id: 'AABR07054845.1'},
{value: 'aabr07054852.1', id: 'AABR07054852.1'},
{value: 'aabr07054855.1', id: 'AABR07054855.1'},
{value: 'aabr07054887.1', id: 'AABR07054887.1'},
{value: 'aabr07054895.1', id: 'AABR07054895.1'},
{value: 'aabr07054897.1', id: 'AABR07054897.1'},
{value: 'aabr07054945.1', id: 'AABR07054945.1'},
{value: 'aabr07054948.1', id: 'AABR07054948.1'},
{value: 'aabr07054979.1', id: 'AABR07054979.1'},
{value: 'aabr07054983.1', id: 'AABR07054983.1'},
{value: 'aabr07054991.1', id: 'AABR07054991.1'},
{value: 'aabr07055146.1', id: 'AABR07055146.1'},
{value: 'aabr07055154.1', id: 'AABR07055154.1'},
{value: 'aabr07055162.1', id: 'AABR07055162.1'},
{value: 'aabr07055191.1', id: 'AABR07055191.1'},
{value: 'aabr07055280.1', id: 'AABR07055280.1'},
{value: 'aabr07055288.1', id: 'AABR07055288.1'},
{value: 'aabr07055309.1', id: 'AABR07055309.1'},
{value: 'aabr07055353.1', id: 'AABR07055353.1'},
{value: 'aabr07055356.1', id: 'AABR07055356.1'},
{value: 'aabr07055413.1', id: 'AABR07055413.1'},
{value: 'aabr07055492.1', id: 'AABR07055492.1'},
{value: 'aabr07055492.2', id: 'AABR07055492.2'},
{value: 'aabr07055527.1', id: 'AABR07055527.1'},
{value: 'aabr07055548.1', id: 'AABR07055548.1'},
{value: 'aabr07055562.1', id: 'AABR07055562.1'},
{value: 'aabr07055631.1', id: 'AABR07055631.1'},
{value: 'aabr07055661.1', id: 'AABR07055661.1'},
{value: 'aabr07055668.1', id: 'AABR07055668.1'},
{value: 'aabr07055673.1', id: 'AABR07055673.1'},
{value: 'aabr07055682.1', id: 'AABR07055682.1'},
{value: 'aabr07055700.1', id: 'AABR07055700.1'},
{value: 'aabr07055717.1', id: 'AABR07055717.1'},
{value: 'aabr07055718.1', id: 'AABR07055718.1'},
{value: 'aabr07055726.1', id: 'AABR07055726.1'},
{value: 'aabr07055728.1', id: 'AABR07055728.1'},
{value: 'aabr07055733.1', id: 'AABR07055733.1'},
{value: 'aabr07055737.1', id: 'AABR07055737.1'},
{value: 'aabr07055738.1', id: 'AABR07055738.1'},
{value: 'aabr07055747.1', id: 'AABR07055747.1'},
{value: 'aabr07055767.1', id: 'AABR07055767.1'},
{value: 'aabr07055776.1', id: 'AABR07055776.1'},
{value: 'aabr07055788.1', id: 'AABR07055788.1'},
{value: 'aabr07055789.1', id: 'AABR07055789.1'},
{value: 'aabr07055792.1', id: 'AABR07055792.1'},
{value: 'aabr07055801.1', id: 'AABR07055801.1'},
{value: 'aabr07055805.1', id: 'AABR07055805.1'},
{value: 'aabr07055812.1', id: 'AABR07055812.1'},
{value: 'aabr07055826.1', id: 'AABR07055826.1'},
{value: 'aabr07055827.1', id: 'AABR07055827.1'},
{value: 'aabr07055844.1', id: 'AABR07055844.1'},
{value: 'aabr07055846.1', id: 'AABR07055846.1'},
{value: 'aabr07055861.1', id: 'AABR07055861.1'},
{value: 'aabr07055864.2', id: 'AABR07055864.2'},
{value: 'aabr07055866.1', id: 'AABR07055866.1'},
{value: 'aabr07055875.1', id: 'AABR07055875.1'},
{value: 'aabr07055878.1', id: 'AABR07055878.1'},
{value: 'aabr07055885.1', id: 'AABR07055885.1'},
{value: 'aabr07055917.1', id: 'AABR07055917.1'},
{value: 'aabr07055919.1', id: 'AABR07055919.1'},
{value: 'aabr07055919.2', id: 'AABR07055919.2'},
{value: 'aabr07055943.1', id: 'AABR07055943.1'},
{value: 'aabr07055992.1', id: 'AABR07055992.1'},
{value: 'aabr07056013.1', id: 'AABR07056013.1'},
{value: 'aabr07056013.2', id: 'AABR07056013.2'},
{value: 'aabr07056014.1', id: 'AABR07056014.1'},
{value: 'aabr07056026.1', id: 'AABR07056026.1'},
{value: 'aabr07056103.1', id: 'AABR07056103.1'},
{value: 'aabr07056131.1', id: 'AABR07056131.1'},
{value: 'aabr07056156.1', id: 'AABR07056156.1'},
{value: 'aabr07056183.1', id: 'AABR07056183.1'},
{value: 'aabr07056200.1', id: 'AABR07056200.1'},
{value: 'aabr07056330.1', id: 'AABR07056330.1'},
{value: 'aabr07056346.1', id: 'AABR07056346.1'},
{value: 'aabr07056369.1', id: 'AABR07056369.1'},
{value: 'aabr07056374.1', id: 'AABR07056374.1'},
{value: 'aabr07056390.1', id: 'AABR07056390.1'},
{value: 'aabr07056390.2', id: 'AABR07056390.2'},
{value: 'aabr07056415.1', id: 'AABR07056415.1'},
{value: 'aabr07056415.2', id: 'AABR07056415.2'},
{value: 'aabr07056422.1', id: 'AABR07056422.1'},
{value: 'aabr07056423.1', id: 'AABR07056423.1'},
{value: 'aabr07056425.1', id: 'AABR07056425.1'},
{value: 'aabr07056438.1', id: 'AABR07056438.1'},
{value: 'aabr07056442.1', id: 'AABR07056442.1'},
{value: 'aabr07056464.1', id: 'AABR07056464.1'},
{value: 'aabr07056465.1', id: 'AABR07056465.1'},
{value: 'aabr07056470.1', id: 'AABR07056470.1'},
{value: 'aabr07056495.1', id: 'AABR07056495.1'},
{value: 'aabr07056503.1', id: 'AABR07056503.1'},
{value: 'aabr07056509.1', id: 'AABR07056509.1'},
{value: 'aabr07056510.1', id: 'AABR07056510.1'},
{value: 'aabr07056515.1', id: 'AABR07056515.1'},
{value: 'aabr07056517.1', id: 'AABR07056517.1'},
{value: 'aabr07056526.1', id: 'AABR07056526.1'},
{value: 'aabr07056556.1', id: 'AABR07056556.1'},
{value: 'aabr07056576.1', id: 'AABR07056576.1'},
{value: 'aabr07056605.1', id: 'AABR07056605.1'},
{value: 'aabr07056608.1', id: 'AABR07056608.1'},
{value: 'aabr07056611.1', id: 'AABR07056611.1'},
{value: 'aabr07056613.1', id: 'AABR07056613.1'},
{value: 'aabr07056613.2', id: 'AABR07056613.2'},
{value: 'aabr07056614.1', id: 'AABR07056614.1'},
{value: 'aabr07056616.1', id: 'AABR07056616.1'},
{value: 'aabr07056624.1', id: 'AABR07056624.1'},
{value: 'aabr07056632.1', id: 'AABR07056632.1'},
{value: 'aabr07056633.1', id: 'AABR07056633.1'},
{value: 'aabr07056636.1', id: 'AABR07056636.1'},
{value: 'aabr07056637.1', id: 'AABR07056637.1'},
{value: 'aabr07056638.1', id: 'AABR07056638.1'},
{value: 'aabr07056643.2', id: 'AABR07056643.2'},
{value: 'aabr07056664.1', id: 'AABR07056664.1'},
{value: 'aabr07056668.1', id: 'AABR07056668.1'},
{value: 'aabr07056678.1', id: 'AABR07056678.1'},
{value: 'aabr07056680.1', id: 'AABR07056680.1'},
{value: 'aabr07056683.1', id: 'AABR07056683.1'},
{value: 'aabr07056686.1', id: 'AABR07056686.1'},
{value: 'aabr07056688.1', id: 'AABR07056688.1'},
{value: 'aabr07056691.1', id: 'AABR07056691.1'},
{value: 'aabr07056705.1', id: 'AABR07056705.1'},
{value: 'aabr07056710.1', id: 'AABR07056710.1'},
{value: 'aabr07056730.1', id: 'AABR07056730.1'},
{value: 'aabr07056732.1', id: 'AABR07056732.1'},
{value: 'aabr07056767.1', id: 'AABR07056767.1'},
{value: 'aabr07056769.1', id: 'AABR07056769.1'},
{value: 'aabr07056771.1', id: 'AABR07056771.1'},
{value: 'aabr07056791.1', id: 'AABR07056791.1'},
{value: 'aabr07056802.1', id: 'AABR07056802.1'},
{value: 'aabr07056811.1', id: 'AABR07056811.1'},
{value: 'aabr07056817.1', id: 'AABR07056817.1'},
{value: 'aabr07056845.1', id: 'AABR07056845.1'},
{value: 'aabr07056858.1', id: 'AABR07056858.1'},
{value: 'aabr07056882.1', id: 'AABR07056882.1'},
{value: 'aabr07056884.1', id: 'AABR07056884.1'},
{value: 'aabr07056887.1', id: 'AABR07056887.1'},
{value: 'aabr07056919.1', id: 'AABR07056919.1'},
{value: 'aabr07056953.1', id: 'AABR07056953.1'},
{value: 'aabr07056962.1', id: 'AABR07056962.1'},
{value: 'aabr07056984.1', id: 'AABR07056984.1'},
{value: 'aabr07056998.1', id: 'AABR07056998.1'},
{value: 'aabr07057079.1', id: 'AABR07057079.1'},
{value: 'aabr07057108.1', id: 'AABR07057108.1'},
{value: 'aabr07057116.1', id: 'AABR07057116.1'},
{value: 'aabr07057146.1', id: 'AABR07057146.1'},
{value: 'aabr07057161.1', id: 'AABR07057161.1'},
{value: 'aabr07057163.1', id: 'AABR07057163.1'},
{value: 'aabr07057171.1', id: 'AABR07057171.1'},
{value: 'aabr07057176.1', id: 'AABR07057176.1'},
{value: 'aabr07057196.1', id: 'AABR07057196.1'},
{value: 'aabr07057217.1', id: 'AABR07057217.1'},
{value: 'aabr07057219.1', id: 'AABR07057219.1'},
{value: 'aabr07057231.1', id: 'AABR07057231.1'},
{value: 'aabr07057233.1', id: 'AABR07057233.1'},
{value: 'aabr07057233.2', id: 'AABR07057233.2'},
{value: 'aabr07057235.1', id: 'AABR07057235.1'},
{value: 'aabr07057237.1', id: 'AABR07057237.1'},
{value: 'aabr07057237.2', id: 'AABR07057237.2'},
{value: 'aabr07057247.1', id: 'AABR07057247.1'},
{value: 'aabr07057263.1', id: 'AABR07057263.1'},
{value: 'aabr07057264.1', id: 'AABR07057264.1'},
{value: 'aabr07057282.1', id: 'AABR07057282.1'},
{value: 'aabr07057304.1', id: 'AABR07057304.1'},
{value: 'aabr07057304.2', id: 'AABR07057304.2'},
{value: 'aabr07057322.1', id: 'AABR07057322.1'},
{value: 'aabr07057325.1', id: 'AABR07057325.1'},
{value: 'aabr07057333.1', id: 'AABR07057333.1'},
{value: 'aabr07057347.1', id: 'AABR07057347.1'},
{value: 'aabr07057353.1', id: 'AABR07057353.1'},
{value: 'aabr07057353.2', id: 'AABR07057353.2'},
{value: 'aabr07057371.1', id: 'AABR07057371.1'},
{value: 'aabr07057377.1', id: 'AABR07057377.1'},
{value: 'aabr07057381.1', id: 'AABR07057381.1'},
{value: 'aabr07057382.1', id: 'AABR07057382.1'},
{value: 'aabr07057385.1', id: 'AABR07057385.1'},
{value: 'aabr07057390.1', id: 'AABR07057390.1'},
{value: 'aabr07057412.1', id: 'AABR07057412.1'},
{value: 'aabr07057415.1', id: 'AABR07057415.1'},
{value: 'aabr07057415.2', id: 'AABR07057415.2'},
{value: 'aabr07057415.3', id: 'AABR07057415.3'},
{value: 'aabr07057421.2', id: 'AABR07057421.2'},
{value: 'aabr07057421.3', id: 'AABR07057421.3'},
{value: 'aabr07057423.1', id: 'AABR07057423.1'},
{value: 'aabr07057436.1', id: 'AABR07057436.1'},
{value: 'aabr07057436.2', id: 'AABR07057436.2'},
{value: 'aabr07057438.1', id: 'AABR07057438.1'},
{value: 'aabr07057442.1', id: 'AABR07057442.1'},
{value: 'aabr07057443.2', id: 'AABR07057443.2'},
{value: 'aabr07057460.1', id: 'AABR07057460.1'},
{value: 'aabr07057495.1', id: 'AABR07057495.1'},
{value: 'aabr07057508.1', id: 'AABR07057508.1'},
{value: 'aabr07057510.1', id: 'AABR07057510.1'},
{value: 'aabr07057510.2', id: 'AABR07057510.2'},
{value: 'aabr07057510.3', id: 'AABR07057510.3'},
{value: 'aabr07057511.1', id: 'AABR07057511.1'},
{value: 'aabr07057530.1', id: 'AABR07057530.1'},
{value: 'aabr07057579.1', id: 'AABR07057579.1'},
{value: 'aabr07057586.1', id: 'AABR07057586.1'},
{value: 'aabr07057586.2', id: 'AABR07057586.2'},
{value: 'aabr07057590.1', id: 'AABR07057590.1'},
{value: 'aabr07057610.1', id: 'AABR07057610.1'},
{value: 'aabr07057611.1', id: 'AABR07057611.1'},
{value: 'aabr07057614.1', id: 'AABR07057614.1'},
{value: 'aabr07057620.1', id: 'AABR07057620.1'},
{value: 'aabr07057621.1', id: 'AABR07057621.1'},
{value: 'aabr07057628.1', id: 'AABR07057628.1'},
{value: 'aabr07057633.1', id: 'AABR07057633.1'},
{value: 'aabr07057640.1', id: 'AABR07057640.1'},
{value: 'aabr07057644.1', id: 'AABR07057644.1'},
{value: 'aabr07057648.1', id: 'AABR07057648.1'},
{value: 'aabr07057675.1', id: 'AABR07057675.1'},
{value: 'aabr07057678.1', id: 'AABR07057678.1'},
{value: 'aabr07057683.1', id: 'AABR07057683.1'},
{value: 'aabr07057700.1', id: 'AABR07057700.1'},
{value: 'aabr07057700.2', id: 'AABR07057700.2'},
{value: 'aabr07057765.2', id: 'AABR07057765.2'},
{value: 'aabr07057787.1', id: 'AABR07057787.1'},
{value: 'aabr07057798.1', id: 'AABR07057798.1'},
{value: 'aabr07057804.1', id: 'AABR07057804.1'},
{value: 'aabr07057844.2', id: 'AABR07057844.2'},
{value: 'aabr07057848.1', id: 'AABR07057848.1'},
{value: 'aabr07057872.1', id: 'AABR07057872.1'},
{value: 'aabr07057877.1', id: 'AABR07057877.1'},
{value: 'aabr07057885.1', id: 'AABR07057885.1'},
{value: 'aabr07057885.2', id: 'AABR07057885.2'},
{value: 'aabr07057906.1', id: 'AABR07057906.1'},
{value: 'aabr07057907.1', id: 'AABR07057907.1'},
{value: 'aabr07057946.1', id: 'AABR07057946.1'},
{value: 'aabr07057961.1', id: 'AABR07057961.1'},
{value: 'aabr07057990.1', id: 'AABR07057990.1'},
{value: 'aabr07057997.1', id: 'AABR07057997.1'},
{value: 'aabr07058000.1', id: 'AABR07058000.1'},
{value: 'aabr07058011.1', id: 'AABR07058011.1'},
{value: 'aabr07058017.1', id: 'AABR07058017.1'},
{value: 'aabr07058017.2', id: 'AABR07058017.2'},
{value: 'aabr07058021.1', id: 'AABR07058021.1'},
{value: 'aabr07058091.2', id: 'AABR07058091.2'},
{value: 'aabr07058102.1', id: 'AABR07058102.1'},
{value: 'aabr07058116.1', id: 'AABR07058116.1'},
{value: 'aabr07058124.2', id: 'AABR07058124.2'},
{value: 'aabr07058124.3', id: 'AABR07058124.3'},
{value: 'aabr07058124.4', id: 'AABR07058124.4'},
{value: 'aabr07058124.5', id: 'AABR07058124.5'},
{value: 'aabr07058133.1', id: 'AABR07058133.1'},
{value: 'aabr07058134.1', id: 'AABR07058134.1'},
{value: 'aabr07058138.1', id: 'AABR07058138.1'},
{value: 'aabr07058141.1', id: 'AABR07058141.1'},
{value: 'aabr07058157.1', id: 'AABR07058157.1'},
{value: 'aabr07058158.1', id: 'AABR07058158.1'},
{value: 'aabr07058166.1', id: 'AABR07058166.1'},
{value: 'aabr07058167.1', id: 'AABR07058167.1'},
{value: 'aabr07058169.1', id: 'AABR07058169.1'},
{value: 'aabr07058169.2', id: 'AABR07058169.2'},
{value: 'aabr07058170.1', id: 'AABR07058170.1'},
{value: 'aabr07058173.1', id: 'AABR07058173.1'},
{value: 'aabr07058173.2', id: 'AABR07058173.2'},
{value: 'aabr07058174.1', id: 'AABR07058174.1'},
{value: 'aabr07058192.1', id: 'AABR07058192.1'},
{value: 'aabr07058210.1', id: 'AABR07058210.1'},
{value: 'aabr07058233.1', id: 'AABR07058233.1'},
{value: 'aabr07058233.2', id: 'AABR07058233.2'},
{value: 'aabr07058240.1', id: 'AABR07058240.1'},
{value: 'aabr07058261.1', id: 'AABR07058261.1'},
{value: 'aabr07058263.1', id: 'AABR07058263.1'},
{value: 'aabr07058280.1', id: 'AABR07058280.1'},
{value: 'aabr07058280.2', id: 'AABR07058280.2'},
{value: 'aabr07058284.1', id: 'AABR07058284.1'},
{value: 'aabr07058287.1', id: 'AABR07058287.1'},
{value: 'aabr07058291.1', id: 'AABR07058291.1'},
{value: 'aabr07058298.1', id: 'AABR07058298.1'},
{value: 'aabr07058320.1', id: 'AABR07058320.1'},
{value: 'aabr07058329.1', id: 'AABR07058329.1'},
{value: 'aabr07058332.1', id: 'AABR07058332.1'},
{value: 'aabr07058360.2', id: 'AABR07058360.2'},
{value: 'aabr07058360.3', id: 'AABR07058360.3'},
{value: 'aabr07058366.1', id: 'AABR07058366.1'},
{value: 'aabr07058383.1', id: 'AABR07058383.1'},
{value: 'aabr07058383.2', id: 'AABR07058383.2'},
{value: 'aabr07058406.1', id: 'AABR07058406.1'},
{value: 'aabr07058410.2', id: 'AABR07058410.2'},
{value: 'aabr07058410.3', id: 'AABR07058410.3'},
{value: 'aabr07058412.1', id: 'AABR07058412.1'},
{value: 'aabr07058412.2', id: 'AABR07058412.2'},
{value: 'aabr07058412.3', id: 'AABR07058412.3'},
{value: 'aabr07058413.1', id: 'AABR07058413.1'},
{value: 'aabr07058420.1', id: 'AABR07058420.1'},
{value: 'aabr07058422.1', id: 'AABR07058422.1'},
{value: 'aabr07058423.3', id: 'AABR07058423.3'},
{value: 'aabr07058423.4', id: 'AABR07058423.4'},
{value: 'aabr07058423.5', id: 'AABR07058423.5'},
{value: 'aabr07058441.1', id: 'AABR07058441.1'},
{value: 'aabr07058450.1', id: 'AABR07058450.1'},
{value: 'aabr07058458.1', id: 'AABR07058458.1'},
{value: 'aabr07058464.1', id: 'AABR07058464.1'},
{value: 'aabr07058479.1', id: 'AABR07058479.1'},
{value: 'aabr07058483.1', id: 'AABR07058483.1'},
{value: 'aabr07058484.1', id: 'AABR07058484.1'},
{value: 'aabr07058498.1', id: 'AABR07058498.1'},
{value: 'aabr07058498.2', id: 'AABR07058498.2'},
{value: 'aabr07058501.1', id: 'AABR07058501.1'},
{value: 'aabr07058511.1', id: 'AABR07058511.1'},
{value: 'aabr07058511.2', id: 'AABR07058511.2'},
{value: 'aabr07058514.1', id: 'AABR07058514.1'},
{value: 'aabr07058514.2', id: 'AABR07058514.2'},
{value: 'aabr07058517.1', id: 'AABR07058517.1'},
{value: 'aabr07058519.1', id: 'AABR07058519.1'},
{value: 'aabr07058532.1', id: 'AABR07058532.1'},
{value: 'aabr07058534.1', id: 'AABR07058534.1'},
{value: 'aabr07058542.1', id: 'AABR07058542.1'},
{value: 'aabr07058554.1', id: 'AABR07058554.1'},
{value: 'aabr07058578.1', id: 'AABR07058578.1'},
{value: 'aabr07058578.2', id: 'AABR07058578.2'},
{value: 'aabr07058608.1', id: 'AABR07058608.1'},
{value: 'aabr07058615.1', id: 'AABR07058615.1'},
{value: 'aabr07058618.1', id: 'AABR07058618.1'},
{value: 'aabr07058633.1', id: 'AABR07058633.1'},
{value: 'aabr07058646.1', id: 'AABR07058646.1'},
{value: 'aabr07058647.1', id: 'AABR07058647.1'},
{value: 'aabr07058656.1', id: 'AABR07058656.1'},
{value: 'aabr07058658.1', id: 'AABR07058658.1'},
{value: 'aabr07058672.1', id: 'AABR07058672.1'},
{value: 'aabr07058678.1', id: 'AABR07058678.1'},
{value: 'aabr07058699.1', id: 'AABR07058699.1'},
{value: 'aabr07058699.2', id: 'AABR07058699.2'},
{value: 'aabr07058706.1', id: 'AABR07058706.1'},
{value: 'aabr07058711.1', id: 'AABR07058711.1'},
{value: 'aabr07058723.1', id: 'AABR07058723.1'},
{value: 'aabr07058724.1', id: 'AABR07058724.1'},
{value: 'aabr07058729.1', id: 'AABR07058729.1'},
{value: 'aabr07058745.1', id: 'AABR07058745.1'},
{value: 'aabr07058758.1', id: 'AABR07058758.1'},
{value: 'aabr07058773.1', id: 'AABR07058773.1'},
{value: 'aabr07058785.1', id: 'AABR07058785.1'},
{value: 'aabr07058786.1', id: 'AABR07058786.1'},
{value: 'aabr07058788.1', id: 'AABR07058788.1'},
{value: 'aabr07058788.2', id: 'AABR07058788.2'},
{value: 'aabr07058788.3', id: 'AABR07058788.3'},
{value: 'aabr07058788.4', id: 'AABR07058788.4'},
{value: 'aabr07058789.1', id: 'AABR07058789.1'},
{value: 'aabr07058795.1', id: 'AABR07058795.1'},
{value: 'aabr07058804.1', id: 'AABR07058804.1'},
{value: 'aabr07058805.1', id: 'AABR07058805.1'},
{value: 'aabr07058815.1', id: 'AABR07058815.1'},
{value: 'aabr07058816.1', id: 'AABR07058816.1'},
{value: 'aabr07058826.1', id: 'AABR07058826.1'},
{value: 'aabr07058831.1', id: 'AABR07058831.1'},
{value: 'aabr07058838.1', id: 'AABR07058838.1'},
{value: 'aabr07058884.1', id: 'AABR07058884.1'},
{value: 'aabr07058884.2', id: 'AABR07058884.2'},
{value: 'aabr07058886.1', id: 'AABR07058886.1'},
{value: 'aabr07058907.1', id: 'AABR07058907.1'},
{value: 'aabr07058907.2', id: 'AABR07058907.2'},
{value: 'aabr07058914.1', id: 'AABR07058914.1'},
{value: 'aabr07058918.1', id: 'AABR07058918.1'},
{value: 'aabr07058925.1', id: 'AABR07058925.1'},
{value: 'aabr07058930.1', id: 'AABR07058930.1'},
{value: 'aabr07058934.1', id: 'AABR07058934.1'},
{value: 'aabr07058934.2', id: 'AABR07058934.2'},
{value: 'aabr07058935.1', id: 'AABR07058935.1'},
{value: 'aabr07058936.1', id: 'AABR07058936.1'},
{value: 'aabr07058937.1', id: 'AABR07058937.1'},
{value: 'aabr07058954.1', id: 'AABR07058954.1'},
{value: 'aabr07058955.1', id: 'AABR07058955.1'},
{value: 'aabr07058955.2', id: 'AABR07058955.2'},
{value: 'aabr07058976.1', id: 'AABR07058976.1'},
{value: 'aabr07058985.1', id: 'AABR07058985.1'},
{value: 'aabr07058985.2', id: 'AABR07058985.2'},
{value: 'aabr07058993.1', id: 'AABR07058993.1'},
{value: 'aabr07059002.1', id: 'AABR07059002.1'},
{value: 'aabr07059003.1', id: 'AABR07059003.1'},
{value: 'aabr07059009.1', id: 'AABR07059009.1'},
{value: 'aabr07059009.2', id: 'AABR07059009.2'},
{value: 'aabr07059024.1', id: 'AABR07059024.1'},
{value: 'aabr07059046.1', id: 'AABR07059046.1'},
{value: 'aabr07059067.1', id: 'AABR07059067.1'},
{value: 'aabr07059072.1', id: 'AABR07059072.1'},
{value: 'aabr07059140.1', id: 'AABR07059140.1'},
{value: 'aabr07059142.1', id: 'AABR07059142.1'},
{value: 'aabr07059150.1', id: 'AABR07059150.1'},
{value: 'aabr07059153.1', id: 'AABR07059153.1'},
{value: 'aabr07059158.1', id: 'AABR07059158.1'},
{value: 'aabr07059161.1', id: 'AABR07059161.1'},
{value: 'aabr07059162.1', id: 'AABR07059162.1'},
{value: 'aabr07059168.1', id: 'AABR07059168.1'},
{value: 'aabr07059168.2', id: 'AABR07059168.2'},
{value: 'aabr07059171.1', id: 'AABR07059171.1'},
{value: 'aabr07059190.1', id: 'AABR07059190.1'},
{value: 'aabr07059193.1', id: 'AABR07059193.1'},
{value: 'aabr07059198.1', id: 'AABR07059198.1'},
{value: 'aabr07059198.2', id: 'AABR07059198.2'},
{value: 'aabr07059201.1', id: 'AABR07059201.1'},
{value: 'aabr07059215.1', id: 'AABR07059215.1'},
{value: 'aabr07059220.1', id: 'AABR07059220.1'},
{value: 'aabr07059227.1', id: 'AABR07059227.1'},
{value: 'aabr07059228.1', id: 'AABR07059228.1'},
{value: 'aabr07059232.1', id: 'AABR07059232.1'},
{value: 'aabr07059239.1', id: 'AABR07059239.1'},
{value: 'aabr07059243.1', id: 'AABR07059243.1'},
{value: 'aabr07059249.1', id: 'AABR07059249.1'},
{value: 'aabr07059258.1', id: 'AABR07059258.1'},
{value: 'aabr07059272.1', id: 'AABR07059272.1'},
{value: 'aabr07059295.1', id: 'AABR07059295.1'},
{value: 'aabr07059308.1', id: 'AABR07059308.1'},
{value: 'aabr07059322.1', id: 'AABR07059322.1'},
{value: 'aabr07059372.1', id: 'AABR07059372.1'},
{value: 'aabr07059372.2', id: 'AABR07059372.2'},
{value: 'aabr07059379.1', id: 'AABR07059379.1'},
{value: 'aabr07059380.1', id: 'AABR07059380.1'},
{value: 'aabr07059381.2', id: 'AABR07059381.2'},
{value: 'aabr07059399.1', id: 'AABR07059399.1'},
{value: 'aabr07059438.1', id: 'AABR07059438.1'},
{value: 'aabr07059449.1', id: 'AABR07059449.1'},
{value: 'aabr07059478.1', id: 'AABR07059478.1'},
{value: 'aabr07059495.1', id: 'AABR07059495.1'},
{value: 'aabr07059498.1', id: 'AABR07059498.1'},
{value: 'aabr07059499.1', id: 'AABR07059499.1'},
{value: 'aabr07059527.1', id: 'AABR07059527.1'},
{value: 'aabr07059552.1', id: 'AABR07059552.1'},
{value: 'aabr07059563.1', id: 'AABR07059563.1'},
{value: 'aabr07059622.1', id: 'AABR07059622.1'},
{value: 'aabr07059632.1', id: 'AABR07059632.1'},
{value: 'aabr07059632.2', id: 'AABR07059632.2'},
{value: 'aabr07059632.3', id: 'AABR07059632.3'},
{value: 'aabr07059663.1', id: 'AABR07059663.1'},
{value: 'aabr07059675.1', id: 'AABR07059675.1'},
{value: 'aabr07059679.1', id: 'AABR07059679.1'},
{value: 'aabr07059723.1', id: 'AABR07059723.1'},
{value: 'aabr07059757.1', id: 'AABR07059757.1'},
{value: 'aabr07059764.1', id: 'AABR07059764.1'},
{value: 'aabr07059778.1', id: 'AABR07059778.1'},
{value: 'aabr07059780.1', id: 'AABR07059780.1'},
{value: 'aabr07059807.1', id: 'AABR07059807.1'},
{value: 'aabr07059807.2', id: 'AABR07059807.2'},
{value: 'aabr07059838.1', id: 'AABR07059838.1'},
{value: 'aabr07059850.1', id: 'AABR07059850.1'},
{value: 'aabr07059869.1', id: 'AABR07059869.1'},
{value: 'aabr07059875.1', id: 'AABR07059875.1'},
{value: 'aabr07059875.2', id: 'AABR07059875.2'},
{value: 'aabr07059875.3', id: 'AABR07059875.3'},
{value: 'aabr07059875.4', id: 'AABR07059875.4'},
{value: 'aabr07059876.1', id: 'AABR07059876.1'},
{value: 'aabr07059877.1', id: 'AABR07059877.1'},
{value: 'aabr07059882.1', id: 'AABR07059882.1'},
{value: 'aabr07059891.1', id: 'AABR07059891.1'},
{value: 'aabr07059900.1', id: 'AABR07059900.1'},
{value: 'aabr07059900.2', id: 'AABR07059900.2'},
{value: 'aabr07059925.1', id: 'AABR07059925.1'},
{value: 'aabr07059968.1', id: 'AABR07059968.1'},
{value: 'aabr07059974.1', id: 'AABR07059974.1'},
{value: 'aabr07059991.1', id: 'AABR07059991.1'},
{value: 'aabr07060055.1', id: 'AABR07060055.1'},
{value: 'aabr07060062.1', id: 'AABR07060062.1'},
{value: 'aabr07060071.1', id: 'AABR07060071.1'},
{value: 'aabr07060084.1', id: 'AABR07060084.1'},
{value: 'aabr07060090.1', id: 'AABR07060090.1'},
{value: 'aabr07060092.1', id: 'AABR07060092.1'},
{value: 'aabr07060101.1', id: 'AABR07060101.1'},
{value: 'aabr07060119.1', id: 'AABR07060119.1'},
{value: 'aabr07060133.1', id: 'AABR07060133.1'},
{value: 'aabr07060145.1', id: 'AABR07060145.1'},
{value: 'aabr07060151.1', id: 'AABR07060151.1'},
{value: 'aabr07060153.1', id: 'AABR07060153.1'},
{value: 'aabr07060154.1', id: 'AABR07060154.1'},
{value: 'aabr07060156.1', id: 'AABR07060156.1'},
{value: 'aabr07060165.1', id: 'AABR07060165.1'},
{value: 'aabr07060222.1', id: 'AABR07060222.1'},
{value: 'aabr07060233.1', id: 'AABR07060233.1'},
{value: 'aabr07060235.1', id: 'AABR07060235.1'},
{value: 'aabr07060236.1', id: 'AABR07060236.1'},
{value: 'aabr07060261.1', id: 'AABR07060261.1'},
{value: 'aabr07060275.1', id: 'AABR07060275.1'},
{value: 'aabr07060291.1', id: 'AABR07060291.1'},
{value: 'aabr07060293.1', id: 'AABR07060293.1'},
{value: 'aabr07060318.1', id: 'AABR07060318.1'},
{value: 'aabr07060341.1', id: 'AABR07060341.1'},
{value: 'aabr07060341.2', id: 'AABR07060341.2'},
{value: 'aabr07060341.3', id: 'AABR07060341.3'},
{value: 'aabr07060350.1', id: 'AABR07060350.1'},
{value: 'aabr07060352.1', id: 'AABR07060352.1'},
{value: 'aabr07060352.2', id: 'AABR07060352.2'},
{value: 'aabr07060353.2', id: 'AABR07060353.2'},
{value: 'aabr07060364.1', id: 'AABR07060364.1'},
{value: 'aabr07060379.1', id: 'AABR07060379.1'},
{value: 'aabr07060394.1', id: 'AABR07060394.1'},
{value: 'aabr07060394.2', id: 'AABR07060394.2'},
{value: 'aabr07060408.1', id: 'AABR07060408.1'},
{value: 'aabr07060473.1', id: 'AABR07060473.1'},
{value: 'aabr07060487.1', id: 'AABR07060487.1'},
{value: 'aabr07060519.1', id: 'AABR07060519.1'},
{value: 'aabr07060539.1', id: 'AABR07060539.1'},
{value: 'aabr07060552.1', id: 'AABR07060552.1'},
{value: 'aabr07060560.1', id: 'AABR07060560.1'},
{value: 'aabr07060560.2', id: 'AABR07060560.2'},
{value: 'aabr07060560.3', id: 'AABR07060560.3'},
{value: 'aabr07060575.1', id: 'AABR07060575.1'},
{value: 'aabr07060577.1', id: 'AABR07060577.1'},
{value: 'aabr07060585.2', id: 'AABR07060585.2'},
{value: 'aabr07060588.1', id: 'AABR07060588.1'},
{value: 'aabr07060588.2', id: 'AABR07060588.2'},
{value: 'aabr07060591.1', id: 'AABR07060591.1'},
{value: 'aabr07060593.2', id: 'AABR07060593.2'},
{value: 'aabr07060595.1', id: 'AABR07060595.1'},
{value: 'aabr07060610.1', id: 'AABR07060610.1'},
{value: 'aabr07060620.1', id: 'AABR07060620.1'},
{value: 'aabr07060620.2', id: 'AABR07060620.2'},
{value: 'aabr07060628.1', id: 'AABR07060628.1'},
{value: 'aabr07060632.1', id: 'AABR07060632.1'},
{value: 'aabr07060676.1', id: 'AABR07060676.1'},
{value: 'aabr07060678.1', id: 'AABR07060678.1'},
{value: 'aabr07060680.1', id: 'AABR07060680.1'},
{value: 'aabr07060694.1', id: 'AABR07060694.1'},
{value: 'aabr07060700.1', id: 'AABR07060700.1'},
{value: 'aabr07060726.1', id: 'AABR07060726.1'},
{value: 'aabr07060743.1', id: 'AABR07060743.1'},
{value: 'aabr07060769.1', id: 'AABR07060769.1'},
{value: 'aabr07060778.1', id: 'AABR07060778.1'},
{value: 'aabr07060784.1', id: 'AABR07060784.1'},
{value: 'aabr07060786.1', id: 'AABR07060786.1'},
{value: 'aabr07060788.1', id: 'AABR07060788.1'},
{value: 'aabr07060792.1', id: 'AABR07060792.1'},
{value: 'aabr07060792.3', id: 'AABR07060792.3'},
{value: 'aabr07060795.1', id: 'AABR07060795.1'},
{value: 'aabr07060797.1', id: 'AABR07060797.1'},
{value: 'aabr07060816.1', id: 'AABR07060816.1'},
{value: 'aabr07060833.1', id: 'AABR07060833.1'},
{value: 'aabr07060833.2', id: 'AABR07060833.2'},
{value: 'aabr07060862.1', id: 'AABR07060862.1'},
{value: 'aabr07060872.1', id: 'AABR07060872.1'},
{value: 'aabr07060886.1', id: 'AABR07060886.1'},
{value: 'aabr07060890.1', id: 'AABR07060890.1'},
{value: 'aabr07060915.1', id: 'AABR07060915.1'},
{value: 'aabr07060920.1', id: 'AABR07060920.1'},
{value: 'aabr07060930.1', id: 'AABR07060930.1'},
{value: 'aabr07060940.1', id: 'AABR07060940.1'},
{value: 'aabr07060944.1', id: 'AABR07060944.1'},
{value: 'aabr07060952.1', id: 'AABR07060952.1'},
{value: 'aabr07060953.1', id: 'AABR07060953.1'},
{value: 'aabr07060957.1', id: 'AABR07060957.1'},
{value: 'aabr07060963.1', id: 'AABR07060963.1'},
{value: 'aabr07060963.2', id: 'AABR07060963.2'},
{value: 'aabr07060963.3', id: 'AABR07060963.3'},
{value: 'aabr07060963.4', id: 'AABR07060963.4'},
{value: 'aabr07060971.1', id: 'AABR07060971.1'},
{value: 'aabr07060979.1', id: 'AABR07060979.1'},
{value: 'aabr07060979.2', id: 'AABR07060979.2'},
{value: 'aabr07060980.1', id: 'AABR07060980.1'},
{value: 'aabr07060992.1', id: 'AABR07060992.1'},
{value: 'aabr07060992.2', id: 'AABR07060992.2'},
{value: 'aabr07060994.1', id: 'AABR07060994.1'},
{value: 'aabr07060995.1', id: 'AABR07060995.1'},
{value: 'aabr07060996.1', id: 'AABR07060996.1'},
{value: 'aabr07061001.1', id: 'AABR07061001.1'},
{value: 'aabr07061005.2', id: 'AABR07061005.2'},
{value: 'aabr07061014.1', id: 'AABR07061014.1'},
{value: 'aabr07061022.1', id: 'AABR07061022.1'},
{value: 'aabr07061022.2', id: 'AABR07061022.2'},
{value: 'aabr07061022.4', id: 'AABR07061022.4'},
{value: 'aabr07061022.5', id: 'AABR07061022.5'},
{value: 'aabr07061030.1', id: 'AABR07061030.1'},
{value: 'aabr07061036.1', id: 'AABR07061036.1'},
{value: 'aabr07061044.1', id: 'AABR07061044.1'},
{value: 'aabr07061048.1', id: 'AABR07061048.1'},
{value: 'aabr07061052.1', id: 'AABR07061052.1'},
{value: 'aabr07061057.1', id: 'AABR07061057.1'},
{value: 'aabr07061057.2', id: 'AABR07061057.2'},
{value: 'aabr07061057.3', id: 'AABR07061057.3'},
{value: 'aabr07061068.1', id: 'AABR07061068.1'},
{value: 'aabr07061068.2', id: 'AABR07061068.2'},
{value: 'aabr07061072.1', id: 'AABR07061072.1'},
{value: 'aabr07061077.1', id: 'AABR07061077.1'},
{value: 'aabr07061078.1', id: 'AABR07061078.1'},
{value: 'aabr07061087.1', id: 'AABR07061087.1'},
{value: 'aabr07061096.1', id: 'AABR07061096.1'},
{value: 'aabr07061108.1', id: 'AABR07061108.1'},
{value: 'aabr07061113.1', id: 'AABR07061113.1'},
{value: 'aabr07061131.1', id: 'AABR07061131.1'},
{value: 'aabr07061131.2', id: 'AABR07061131.2'},
{value: 'aabr07061134.1', id: 'AABR07061134.1'},
{value: 'aabr07061136.1', id: 'AABR07061136.1'},
{value: 'aabr07061139.1', id: 'AABR07061139.1'},
{value: 'aabr07061152.1', id: 'AABR07061152.1'},
{value: 'aabr07061155.1', id: 'AABR07061155.1'},
{value: 'aabr07061158.1', id: 'AABR07061158.1'},
{value: 'aabr07061178.1', id: 'AABR07061178.1'},
{value: 'aabr07061187.1', id: 'AABR07061187.1'},
{value: 'aabr07061240.1', id: 'AABR07061240.1'},
{value: 'aabr07061246.1', id: 'AABR07061246.1'},
{value: 'aabr07061249.1', id: 'AABR07061249.1'},
{value: 'aabr07061251.1', id: 'AABR07061251.1'},
{value: 'aabr07061254.1', id: 'AABR07061254.1'},
{value: 'aabr07061260.1', id: 'AABR07061260.1'},
{value: 'aabr07061261.1', id: 'AABR07061261.1'},
{value: 'aabr07061261.2', id: 'AABR07061261.2'},
{value: 'aabr07061261.3', id: 'AABR07061261.3'},
{value: 'aabr07061279.1', id: 'AABR07061279.1'},
{value: 'aabr07061286.1', id: 'AABR07061286.1'},
{value: 'aabr07061310.1', id: 'AABR07061310.1'},
{value: 'aabr07061313.1', id: 'AABR07061313.1'},
{value: 'aabr07061328.2', id: 'AABR07061328.2'},
{value: 'aabr07061333.1', id: 'AABR07061333.1'},
{value: 'aabr07061333.2', id: 'AABR07061333.2'},
{value: 'aabr07061333.3', id: 'AABR07061333.3'},
{value: 'aabr07061335.1', id: 'AABR07061335.1'},
{value: 'aabr07061336.1', id: 'AABR07061336.1'},
{value: 'aabr07061382.1', id: 'AABR07061382.1'},
{value: 'aabr07061382.2', id: 'AABR07061382.2'},
{value: 'aabr07061382.3', id: 'AABR07061382.3'},
{value: 'aabr07061383.1', id: 'AABR07061383.1'},
{value: 'aabr07061385.1', id: 'AABR07061385.1'},
{value: 'aabr07061385.2', id: 'AABR07061385.2'},
{value: 'aabr07061390.1', id: 'AABR07061390.1'},
{value: 'aabr07061390.2', id: 'AABR07061390.2'},
{value: 'aabr07061400.1', id: 'AABR07061400.1'},
{value: 'aabr07061400.2', id: 'AABR07061400.2'},
{value: 'aabr07061408.1', id: 'AABR07061408.1'},
{value: 'aabr07061412.1', id: 'AABR07061412.1'},
{value: 'aabr07061448.1', id: 'AABR07061448.1'},
{value: 'aabr07061448.3', id: 'AABR07061448.3'},
{value: 'aabr07061451.1', id: 'AABR07061451.1'},
{value: 'aabr07061453.1', id: 'AABR07061453.1'},
{value: 'aabr07061453.2', id: 'AABR07061453.2'},
{value: 'aabr07061455.1', id: 'AABR07061455.1'},
{value: 'aabr07061462.1', id: 'AABR07061462.1'},
{value: 'aabr07061462.2', id: 'AABR07061462.2'},
{value: 'aabr07061465.1', id: 'AABR07061465.1'},
{value: 'aabr07061507.1', id: 'AABR07061507.1'},
{value: 'aabr07061513.1', id: 'AABR07061513.1'},
{value: 'aabr07061530.1', id: 'AABR07061530.1'},
{value: 'aabr07061532.1', id: 'AABR07061532.1'},
{value: 'aabr07061532.2', id: 'AABR07061532.2'},
{value: 'aabr07061533.1', id: 'AABR07061533.1'},
{value: 'aabr07061535.1', id: 'AABR07061535.1'},
{value: 'aabr07061541.1', id: 'AABR07061541.1'},
{value: 'aabr07061542.1', id: 'AABR07061542.1'},
{value: 'aabr07061556.1', id: 'AABR07061556.1'},
{value: 'aabr07061573.1', id: 'AABR07061573.1'},
{value: 'aabr07061577.1', id: 'AABR07061577.1'},
{value: 'aabr07061586.1', id: 'AABR07061586.1'},
{value: 'aabr07061594.1', id: 'AABR07061594.1'},
{value: 'aabr07061609.1', id: 'AABR07061609.1'},
{value: 'aabr07061614.1', id: 'AABR07061614.1'},
{value: 'aabr07061614.2', id: 'AABR07061614.2'},
{value: 'aabr07061614.3', id: 'AABR07061614.3'},
{value: 'aabr07061630.1', id: 'AABR07061630.1'},
{value: 'aabr07061652.1', id: 'AABR07061652.1'},
{value: 'aabr07061688.1', id: 'AABR07061688.1'},
{value: 'aabr07061707.1', id: 'AABR07061707.1'},
{value: 'aabr07061755.1', id: 'AABR07061755.1'},
{value: 'aabr07061780.1', id: 'AABR07061780.1'},
{value: 'aabr07061801.2', id: 'AABR07061801.2'},
{value: 'aabr07061807.1', id: 'AABR07061807.1'},
{value: 'aabr07061807.2', id: 'AABR07061807.2'},
{value: 'aabr07061820.1', id: 'AABR07061820.1'},
{value: 'aabr07061825.1', id: 'AABR07061825.1'},
{value: 'aabr07061825.2', id: 'AABR07061825.2'},
{value: 'aabr07061834.1', id: 'AABR07061834.1'},
{value: 'aabr07061839.1', id: 'AABR07061839.1'},
{value: 'aabr07061842.2', id: 'AABR07061842.2'},
{value: 'aabr07061847.1', id: 'AABR07061847.1'},
{value: 'aabr07061848.1', id: 'AABR07061848.1'},
{value: 'aabr07061860.1', id: 'AABR07061860.1'},
{value: 'aabr07061868.1', id: 'AABR07061868.1'},
{value: 'aabr07061871.1', id: 'AABR07061871.1'},
{value: 'aabr07061871.2', id: 'AABR07061871.2'},
{value: 'aabr07061874.1', id: 'AABR07061874.1'},
{value: 'aabr07061899.1', id: 'AABR07061899.1'},
{value: 'aabr07061902.1', id: 'AABR07061902.1'},
{value: 'aabr07061902.2', id: 'AABR07061902.2'},
{value: 'aabr07061950.1', id: 'AABR07061950.1'},
{value: 'aabr07061962.1', id: 'AABR07061962.1'},
{value: 'aabr07061964.1', id: 'AABR07061964.1'},
{value: 'aabr07061979.1', id: 'AABR07061979.1'},
{value: 'aabr07062020.1', id: 'AABR07062020.1'},
{value: 'aabr07062023.1', id: 'AABR07062023.1'},
{value: 'aabr07062026.1', id: 'AABR07062026.1'},
{value: 'aabr07062026.2', id: 'AABR07062026.2'},
{value: 'aabr07062033.1', id: 'AABR07062033.1'},
{value: 'aabr07062046.1', id: 'AABR07062046.1'},
{value: 'aabr07062061.1', id: 'AABR07062061.1'},
{value: 'aabr07062068.1', id: 'AABR07062068.1'},
{value: 'aabr07062069.1', id: 'AABR07062069.1'},
{value: 'aabr07062069.2', id: 'AABR07062069.2'},
{value: 'aabr07062102.1', id: 'AABR07062102.1'},
{value: 'aabr07062108.1', id: 'AABR07062108.1'},
{value: 'aabr07062109.1', id: 'AABR07062109.1'},
{value: 'aabr07062111.1', id: 'AABR07062111.1'},
{value: 'aabr07062111.2', id: 'AABR07062111.2'},
{value: 'aabr07062112.1', id: 'AABR07062112.1'},
{value: 'aabr07062136.1', id: 'AABR07062136.1'},
{value: 'aabr07062138.1', id: 'AABR07062138.1'},
{value: 'aabr07062138.2', id: 'AABR07062138.2'},
{value: 'aabr07062154.1', id: 'AABR07062154.1'},
{value: 'aabr07062154.2', id: 'AABR07062154.2'},
{value: 'aabr07062157.1', id: 'AABR07062157.1'},
{value: 'aabr07062170.1', id: 'AABR07062170.1'},
{value: 'aabr07062181.1', id: 'AABR07062181.1'},
{value: 'aabr07062225.1', id: 'AABR07062225.1'},
{value: 'aabr07062226.1', id: 'AABR07062226.1'},
{value: 'aabr07062238.1', id: 'AABR07062238.1'},
{value: 'aabr07062266.1', id: 'AABR07062266.1'},
{value: 'aabr07062298.1', id: 'AABR07062298.1'},
{value: 'aabr07062298.2', id: 'AABR07062298.2'},
{value: 'aabr07062314.1', id: 'AABR07062314.1'},
{value: 'aabr07062323.1', id: 'AABR07062323.1'},
{value: 'aabr07062329.1', id: 'AABR07062329.1'},
{value: 'aabr07062333.1', id: 'AABR07062333.1'},
{value: 'aabr07062344.1', id: 'AABR07062344.1'},
{value: 'aabr07062344.2', id: 'AABR07062344.2'},
{value: 'aabr07062350.1', id: 'AABR07062350.1'},
{value: 'aabr07062363.1', id: 'AABR07062363.1'},
{value: 'aabr07062367.1', id: 'AABR07062367.1'},
{value: 'aabr07062380.1', id: 'AABR07062380.1'},
{value: 'aabr07062395.1', id: 'AABR07062395.1'},
{value: 'aabr07062395.2', id: 'AABR07062395.2'},
{value: 'aabr07062400.1', id: 'AABR07062400.1'},
{value: 'aabr07062404.1', id: 'AABR07062404.1'},
{value: 'aabr07062404.2', id: 'AABR07062404.2'},
{value: 'aabr07062404.3', id: 'AABR07062404.3'},
{value: 'aabr07062404.4', id: 'AABR07062404.4'},
{value: 'aabr07062430.1', id: 'AABR07062430.1'},
{value: 'aabr07062438.1', id: 'AABR07062438.1'},
{value: 'aabr07062453.1', id: 'AABR07062453.1'},
{value: 'aabr07062454.1', id: 'AABR07062454.1'},
{value: 'aabr07062466.1', id: 'AABR07062466.1'},
{value: 'aabr07062477.1', id: 'AABR07062477.1'},
{value: 'aabr07062477.2', id: 'AABR07062477.2'},
{value: 'aabr07062477.3', id: 'AABR07062477.3'},
{value: 'aabr07062492.1', id: 'AABR07062492.1'},
{value: 'aabr07062511.1', id: 'AABR07062511.1'},
{value: 'aabr07062512.1', id: 'AABR07062512.1'},
{value: 'aabr07062513.1', id: 'AABR07062513.1'},
{value: 'aabr07062513.2', id: 'AABR07062513.2'},
{value: 'aabr07062528.1', id: 'AABR07062528.1'},
{value: 'aabr07062535.1', id: 'AABR07062535.1'},
{value: 'aabr07062535.2', id: 'AABR07062535.2'},
{value: 'aabr07062539.1', id: 'AABR07062539.1'},
{value: 'aabr07062539.2', id: 'AABR07062539.2'},
{value: 'aabr07062539.3', id: 'AABR07062539.3'},
{value: 'aabr07062539.4', id: 'AABR07062539.4'},
{value: 'aabr07062544.1', id: 'AABR07062544.1'},
{value: 'aabr07062570.1', id: 'AABR07062570.1'},
{value: 'aabr07062599.1', id: 'AABR07062599.1'},
{value: 'aabr07062599.2', id: 'AABR07062599.2'},
{value: 'aabr07062615.1', id: 'AABR07062615.1'},
{value: 'aabr07062664.1', id: 'AABR07062664.1'},
{value: 'aabr07062670.1', id: 'AABR07062670.1'},
{value: 'aabr07062670.2', id: 'AABR07062670.2'},
{value: 'aabr07062672.1', id: 'AABR07062672.1'},
{value: 'aabr07062694.1', id: 'AABR07062694.1'},
{value: 'aabr07062694.2', id: 'AABR07062694.2'},
{value: 'aabr07062708.1', id: 'AABR07062708.1'},
{value: 'aabr07062730.1', id: 'AABR07062730.1'},
{value: 'aabr07062751.1', id: 'AABR07062751.1'},
{value: 'aabr07062753.1', id: 'AABR07062753.1'},
{value: 'aabr07062756.1', id: 'AABR07062756.1'},
{value: 'aabr07062758.1', id: 'AABR07062758.1'},
{value: 'aabr07062766.1', id: 'AABR07062766.1'},
{value: 'aabr07062769.1', id: 'AABR07062769.1'},
{value: 'aabr07062775.1', id: 'AABR07062775.1'},
{value: 'aabr07062791.1', id: 'AABR07062791.1'},
{value: 'aabr07062799.1', id: 'AABR07062799.1'},
{value: 'aabr07062799.2', id: 'AABR07062799.2'},
{value: 'aabr07062800.1', id: 'AABR07062800.1'},
{value: 'aabr07062812.1', id: 'AABR07062812.1'},
{value: 'aabr07062823.1', id: 'AABR07062823.1'},
{value: 'aabr07062833.1', id: 'AABR07062833.1'},
{value: 'aabr07062833.2', id: 'AABR07062833.2'},
{value: 'aabr07062833.3', id: 'AABR07062833.3'},
{value: 'aabr07062858.1', id: 'AABR07062858.1'},
{value: 'aabr07062859.1', id: 'AABR07062859.1'},
{value: 'aabr07062860.1', id: 'AABR07062860.1'},
{value: 'aabr07062885.1', id: 'AABR07062885.1'},
{value: 'aabr07062897.1', id: 'AABR07062897.1'},
{value: 'aabr07062898.1', id: 'AABR07062898.1'},
{value: 'aabr07062904.1', id: 'AABR07062904.1'},
{value: 'aabr07062905.1', id: 'AABR07062905.1'},
{value: 'aabr07062912.1', id: 'AABR07062912.1'},
{value: 'aabr07062915.1', id: 'AABR07062915.1'},
{value: 'aabr07062915.2', id: 'AABR07062915.2'},
{value: 'aabr07062915.3', id: 'AABR07062915.3'},
{value: 'aabr07062920.1', id: 'AABR07062920.1'},
{value: 'aabr07062924.1', id: 'AABR07062924.1'},
{value: 'aabr07062925.1', id: 'AABR07062925.1'},
{value: 'aabr07063082.1', id: 'AABR07063082.1'},
{value: 'aabr07063090.1', id: 'AABR07063090.1'},
{value: 'aabr07063126.1', id: 'AABR07063126.1'},
{value: 'aabr07063167.1', id: 'AABR07063167.1'},
{value: 'aabr07063197.1', id: 'AABR07063197.1'},
{value: 'aabr07063197.2', id: 'AABR07063197.2'},
{value: 'aabr07063248.1', id: 'AABR07063248.1'},
{value: 'aabr07063250.1', id: 'AABR07063250.1'},
{value: 'aabr07063250.2', id: 'AABR07063250.2'},
{value: 'aabr07063255.1', id: 'AABR07063255.1'},
{value: 'aabr07063272.1', id: 'AABR07063272.1'},
{value: 'aabr07063274.1', id: 'AABR07063274.1'},
{value: 'aabr07063276.1', id: 'AABR07063276.1'},
{value: 'aabr07063279.1', id: 'AABR07063279.1'},
{value: 'aabr07063281.1', id: 'AABR07063281.1'},
{value: 'aabr07063282.1', id: 'AABR07063282.1'},
{value: 'aabr07063286.1', id: 'AABR07063286.1'},
{value: 'aabr07063287.1', id: 'AABR07063287.1'},
{value: 'aabr07063288.1', id: 'AABR07063288.1'},
{value: 'aabr07063290.1', id: 'AABR07063290.1'},
{value: 'aabr07063323.1', id: 'AABR07063323.1'},
{value: 'aabr07063346.1', id: 'AABR07063346.1'},
{value: 'aabr07063350.1', id: 'AABR07063350.1'},
{value: 'aabr07063424.1', id: 'AABR07063424.1'},
{value: 'aabr07063425.1', id: 'AABR07063425.1'},
{value: 'aabr07063425.2', id: 'AABR07063425.2'},
{value: 'aabr07063425.3', id: 'AABR07063425.3'},
{value: 'aabr07063459.1', id: 'AABR07063459.1'},
{value: 'aabr07063462.1', id: 'AABR07063462.1'},
{value: 'aabr07063507.1', id: 'AABR07063507.1'},
{value: 'aabr07063508.1', id: 'AABR07063508.1'},
{value: 'aabr07063509.1', id: 'AABR07063509.1'},
{value: 'aabr07063511.1', id: 'AABR07063511.1'},
{value: 'aabr07063511.2', id: 'AABR07063511.2'},
{value: 'aabr07063546.1', id: 'AABR07063546.1'},
{value: 'aabr07063553.1', id: 'AABR07063553.1'},
{value: 'aabr07063558.1', id: 'AABR07063558.1'},
{value: 'aabr07063565.1', id: 'AABR07063565.1'},
{value: 'aabr07063575.1', id: 'AABR07063575.1'},
{value: 'aabr07063581.1', id: 'AABR07063581.1'},
{value: 'aabr07063581.2', id: 'AABR07063581.2'},
{value: 'aabr07063599.1', id: 'AABR07063599.1'},
{value: 'aabr07063601.1', id: 'AABR07063601.1'},
{value: 'aabr07063601.2', id: 'AABR07063601.2'},
{value: 'aabr07063621.1', id: 'AABR07063621.1'},
{value: 'aabr07063632.1', id: 'AABR07063632.1'},
{value: 'aabr07063635.1', id: 'AABR07063635.1'},
{value: 'aabr07063636.1', id: 'AABR07063636.1'},
{value: 'aabr07063640.1', id: 'AABR07063640.1'},
{value: 'aabr07063640.2', id: 'AABR07063640.2'},
{value: 'aabr07063649.1', id: 'AABR07063649.1'},
{value: 'aabr07063649.2', id: 'AABR07063649.2'},
{value: 'aabr07063649.3', id: 'AABR07063649.3'},
{value: 'aabr07063652.1', id: 'AABR07063652.1'},
{value: 'aabr07063654.1', id: 'AABR07063654.1'},
{value: 'aabr07063654.2', id: 'AABR07063654.2'},
{value: 'aabr07063654.3', id: 'AABR07063654.3'},
{value: 'aabr07063672.1', id: 'AABR07063672.1'},
{value: 'aabr07063674.1', id: 'AABR07063674.1'},
{value: 'aabr07063674.2', id: 'AABR07063674.2'},
{value: 'aabr07063682.1', id: 'AABR07063682.1'},
{value: 'aabr07063703.1', id: 'AABR07063703.1'},
{value: 'aabr07063704.1', id: 'AABR07063704.1'},
{value: 'aabr07063724.1', id: 'AABR07063724.1'},
{value: 'aabr07063740.2', id: 'AABR07063740.2'},
{value: 'aabr07063741.1', id: 'AABR07063741.1'},
{value: 'aabr07063742.1', id: 'AABR07063742.1'},
{value: 'aabr07063743.1', id: 'AABR07063743.1'},
{value: 'aabr07063743.2', id: 'AABR07063743.2'},
{value: 'aabr07063746.1', id: 'AABR07063746.1'},
{value: 'aabr07063751.1', id: 'AABR07063751.1'},
{value: 'aabr07063755.1', id: 'AABR07063755.1'},
{value: 'aabr07063760.1', id: 'AABR07063760.1'},
{value: 'aabr07063767.1', id: 'AABR07063767.1'},
{value: 'aabr07063771.1', id: 'AABR07063771.1'},
{value: 'aabr07063777.1', id: 'AABR07063777.1'},
{value: 'aabr07063802.1', id: 'AABR07063802.1'},
{value: 'aabr07063804.1', id: 'AABR07063804.1'},
{value: 'aabr07063808.1', id: 'AABR07063808.1'},
{value: 'aabr07063808.3', id: 'AABR07063808.3'},
{value: 'aabr07063811.1', id: 'AABR07063811.1'},
{value: 'aabr07063812.1', id: 'AABR07063812.1'},
{value: 'aabr07063829.1', id: 'AABR07063829.1'},
{value: 'aabr07063847.1', id: 'AABR07063847.1'},
{value: 'aabr07063852.1', id: 'AABR07063852.1'},
{value: 'aabr07063852.2', id: 'AABR07063852.2'},
{value: 'aabr07063860.1', id: 'AABR07063860.1'},
{value: 'aabr07063863.1', id: 'AABR07063863.1'},
{value: 'aabr07063873.1', id: 'AABR07063873.1'},
{value: 'aabr07063893.1', id: 'AABR07063893.1'},
{value: 'aabr07063901.1', id: 'AABR07063901.1'},
{value: 'aabr07063901.2', id: 'AABR07063901.2'},
{value: 'aabr07063929.1', id: 'AABR07063929.1'},
{value: 'aabr07063929.2', id: 'AABR07063929.2'},
{value: 'aabr07063940.1', id: 'AABR07063940.1'},
{value: 'aabr07063959.1', id: 'AABR07063959.1'},
{value: 'aabr07063975.1', id: 'AABR07063975.1'},
{value: 'aabr07063991.1', id: 'AABR07063991.1'},
{value: 'aabr07063995.1', id: 'AABR07063995.1'},
{value: 'aabr07064000.1', id: 'AABR07064000.1'},
{value: 'aabr07064011.1', id: 'AABR07064011.1'},
{value: 'aabr07064011.2', id: 'AABR07064011.2'},
{value: 'aabr07064031.1', id: 'AABR07064031.1'},
{value: 'aabr07064048.1', id: 'AABR07064048.1'},
{value: 'aabr07064048.2', id: 'AABR07064048.2'},
{value: 'aabr07064061.1', id: 'AABR07064061.1'},
{value: 'aabr07064099.1', id: 'AABR07064099.1'},
{value: 'aabr07064111.1', id: 'AABR07064111.1'},
{value: 'aabr07064122.1', id: 'AABR07064122.1'},
{value: 'aabr07064131.1', id: 'AABR07064131.1'},
{value: 'aabr07064132.1', id: 'AABR07064132.1'},
{value: 'aabr07064139.1', id: 'AABR07064139.1'},
{value: 'aabr07064172.1', id: 'AABR07064172.1'},
{value: 'aabr07064213.1', id: 'AABR07064213.1'},
{value: 'aabr07064218.1', id: 'AABR07064218.1'},
{value: 'aabr07064224.1', id: 'AABR07064224.1'},
{value: 'aabr07064227.1', id: 'AABR07064227.1'},
{value: 'aabr07064228.1', id: 'AABR07064228.1'},
{value: 'aabr07064231.1', id: 'AABR07064231.1'},
{value: 'aabr07064232.1', id: 'AABR07064232.1'},
{value: 'aabr07064253.1', id: 'AABR07064253.1'},
{value: 'aabr07064257.1', id: 'AABR07064257.1'},
{value: 'aabr07064264.1', id: 'AABR07064264.1'},
{value: 'aabr07064299.1', id: 'AABR07064299.1'},
{value: 'aabr07064304.1', id: 'AABR07064304.1'},
{value: 'aabr07064305.1', id: 'AABR07064305.1'},
{value: 'aabr07064312.1', id: 'AABR07064312.1'},
{value: 'aabr07064349.1', id: 'AABR07064349.1'},
{value: 'aabr07064349.2', id: 'AABR07064349.2'},
{value: 'aabr07064368.1', id: 'AABR07064368.1'},
{value: 'aabr07064373.1', id: 'AABR07064373.1'},
{value: 'aabr07064373.2', id: 'AABR07064373.2'},
{value: 'aabr07064386.2', id: 'AABR07064386.2'},
{value: 'aabr07064392.1', id: 'AABR07064392.1'},
{value: 'aabr07064415.1', id: 'AABR07064415.1'},
{value: 'aabr07064418.1', id: 'AABR07064418.1'},
{value: 'aabr07064468.1', id: 'AABR07064468.1'},
{value: 'aabr07064502.1', id: 'AABR07064502.1'},
{value: 'aabr07064503.1', id: 'AABR07064503.1'},
{value: 'aabr07064578.1', id: 'AABR07064578.1'},
{value: 'aabr07064584.1', id: 'AABR07064584.1'},
{value: 'aabr07064590.1', id: 'AABR07064590.1'},
{value: 'aabr07064613.2', id: 'AABR07064613.2'},
{value: 'aabr07064616.1', id: 'AABR07064616.1'},
{value: 'aabr07064618.1', id: 'AABR07064618.1'},
{value: 'aabr07064622.1', id: 'AABR07064622.1'},
{value: 'aabr07064622.2', id: 'AABR07064622.2'},
{value: 'aabr07064631.1', id: 'AABR07064631.1'},
{value: 'aabr07064635.1', id: 'AABR07064635.1'},
{value: 'aabr07064645.1', id: 'AABR07064645.1'},
{value: 'aabr07064650.1', id: 'AABR07064650.1'},
{value: 'aabr07064667.1', id: 'AABR07064667.1'},
{value: 'aabr07064681.1', id: 'AABR07064681.1'},
{value: 'aabr07064690.1', id: 'AABR07064690.1'},
{value: 'aabr07064697.1', id: 'AABR07064697.1'},
{value: 'aabr07064702.1', id: 'AABR07064702.1'},
{value: 'aabr07064711.1', id: 'AABR07064711.1'},
{value: 'aabr07064716.1', id: 'AABR07064716.1'},
{value: 'aabr07064716.3', id: 'AABR07064716.3'},
{value: 'aabr07064719.1', id: 'AABR07064719.1'},
{value: 'aabr07064719.2', id: 'AABR07064719.2'},
{value: 'aabr07064724.1', id: 'AABR07064724.1'},
{value: 'aabr07064724.3', id: 'AABR07064724.3'},
{value: 'aabr07064727.1', id: 'AABR07064727.1'},
{value: 'aabr07064747.1', id: 'AABR07064747.1'},
{value: 'aabr07064753.1', id: 'AABR07064753.1'},
{value: 'aabr07064755.1', id: 'AABR07064755.1'},
{value: 'aabr07064804.1', id: 'AABR07064804.1'},
{value: 'aabr07064810.1', id: 'AABR07064810.1'},
{value: 'aabr07064811.1', id: 'AABR07064811.1'},
{value: 'aabr07064811.2', id: 'AABR07064811.2'},
{value: 'aabr07064829.1', id: 'AABR07064829.1'},
{value: 'aabr07064835.1', id: 'AABR07064835.1'},
{value: 'aabr07064840.1', id: 'AABR07064840.1'},
{value: 'aabr07064867.1', id: 'AABR07064867.1'},
{value: 'aabr07064867.2', id: 'AABR07064867.2'},
{value: 'aabr07064873.1', id: 'AABR07064873.1'},
{value: 'aabr07064878.1', id: 'AABR07064878.1'},
{value: 'aabr07064897.1', id: 'AABR07064897.1'},
{value: 'aabr07064910.1', id: 'AABR07064910.1'},
{value: 'aabr07064918.1', id: 'AABR07064918.1'},
{value: 'aabr07064922.1', id: 'AABR07064922.1'},
{value: 'aabr07064932.1', id: 'AABR07064932.1'},
{value: 'aabr07064980.1', id: 'AABR07064980.1'},
{value: 'aabr07064983.1', id: 'AABR07064983.1'},
{value: 'aabr07064983.2', id: 'AABR07064983.2'},
{value: 'aabr07064992.1', id: 'AABR07064992.1'},
{value: 'aabr07064998.1', id: 'AABR07064998.1'},
{value: 'aabr07064998.2', id: 'AABR07064998.2'},
{value: 'aabr07064999.1', id: 'AABR07064999.1'},
{value: 'aabr07064999.2', id: 'AABR07064999.2'},
{value: 'aabr07065010.1', id: 'AABR07065010.1'},
{value: 'aabr07065012.1', id: 'AABR07065012.1'},
{value: 'aabr07065013.1', id: 'AABR07065013.1'},
{value: 'aabr07065031.1', id: 'AABR07065031.1'},
{value: 'aabr07065042.1', id: 'AABR07065042.1'},
{value: 'aabr07065043.1', id: 'AABR07065043.1'},
{value: 'aabr07065044.1', id: 'AABR07065044.1'},
{value: 'aabr07065049.1', id: 'AABR07065049.1'},
{value: 'aabr07065049.2', id: 'AABR07065049.2'},
{value: 'aabr07065050.1', id: 'AABR07065050.1'},
{value: 'aabr07065078.1', id: 'AABR07065078.1'},
{value: 'aabr07065083.1', id: 'AABR07065083.1'},
{value: 'aabr07065091.1', id: 'AABR07065091.1'},
{value: 'aabr07065113.1', id: 'AABR07065113.1'},
{value: 'aabr07065113.2', id: 'AABR07065113.2'},
{value: 'aabr07065114.1', id: 'AABR07065114.1'},
{value: 'aabr07065115.1', id: 'AABR07065115.1'},
{value: 'aabr07065118.1', id: 'AABR07065118.1'},
{value: 'aabr07065124.1', id: 'AABR07065124.1'},
{value: 'aabr07065124.2', id: 'AABR07065124.2'},
{value: 'aabr07065127.1', id: 'AABR07065127.1'},
{value: 'aabr07065139.1', id: 'AABR07065139.1'},
{value: 'aabr07065139.2', id: 'AABR07065139.2'},
{value: 'aabr07065154.1', id: 'AABR07065154.1'},
{value: 'aabr07065156.1', id: 'AABR07065156.1'},
{value: 'aabr07065160.1', id: 'AABR07065160.1'},
{value: 'aabr07065173.1', id: 'AABR07065173.1'},
{value: 'aabr07065175.1', id: 'AABR07065175.1'},
{value: 'aabr07065175.2', id: 'AABR07065175.2'},
{value: 'aabr07065190.1', id: 'AABR07065190.1'},
{value: 'aabr07065190.2', id: 'AABR07065190.2'},
{value: 'aabr07065217.1', id: 'AABR07065217.1'},
{value: 'aabr07065217.2', id: 'AABR07065217.2'},
{value: 'aabr07065250.1', id: 'AABR07065250.1'},
{value: 'aabr07065257.1', id: 'AABR07065257.1'},
{value: 'aabr07065259.1', id: 'AABR07065259.1'},
{value: 'aabr07065259.2', id: 'AABR07065259.2'},
{value: 'aabr07065261.1', id: 'AABR07065261.1'},
{value: 'aabr07065265.1', id: 'AABR07065265.1'},
{value: 'aabr07065265.2', id: 'AABR07065265.2'},
{value: 'aabr07065274.1', id: 'AABR07065274.1'},
{value: 'aabr07065276.1', id: 'AABR07065276.1'},
{value: 'aabr07065281.1', id: 'AABR07065281.1'},
{value: 'aabr07065282.1', id: 'AABR07065282.1'},
{value: 'aabr07065291.1', id: 'AABR07065291.1'},
{value: 'aabr07065298.1', id: 'AABR07065298.1'},
{value: 'aabr07065312.1', id: 'AABR07065312.1'},
{value: 'aabr07065334.1', id: 'AABR07065334.1'},
{value: 'aabr07065343.1', id: 'AABR07065343.1'},
{value: 'aabr07065348.1', id: 'AABR07065348.1'},
{value: 'aabr07065348.2', id: 'AABR07065348.2'},
{value: 'aabr07065349.1', id: 'AABR07065349.1'},
{value: 'aabr07065353.1', id: 'AABR07065353.1'},
{value: 'aabr07065362.1', id: 'AABR07065362.1'},
{value: 'aabr07065363.1', id: 'AABR07065363.1'},
{value: 'aabr07065364.1', id: 'AABR07065364.1'},
{value: 'aabr07065406.2', id: 'AABR07065406.2'},
{value: 'aabr07065423.1', id: 'AABR07065423.1'},
{value: 'aabr07065424.1', id: 'AABR07065424.1'},
{value: 'aabr07065429.1', id: 'AABR07065429.1'},
{value: 'aabr07065429.2', id: 'AABR07065429.2'},
{value: 'aabr07065438.2', id: 'AABR07065438.2'},
{value: 'aabr07065455.1', id: 'AABR07065455.1'},
{value: 'aabr07065460.2', id: 'AABR07065460.2'},
{value: 'aabr07065465.1', id: 'AABR07065465.1'},
{value: 'aabr07065466.1', id: 'AABR07065466.1'},
{value: 'aabr07065476.1', id: 'AABR07065476.1'},
{value: 'aabr07065476.2', id: 'AABR07065476.2'},
{value: 'aabr07065485.1', id: 'AABR07065485.1'},
{value: 'aabr07065486.1', id: 'AABR07065486.1'},
{value: 'aabr07065498.1', id: 'AABR07065498.1'},
{value: 'aabr07065504.1', id: 'AABR07065504.1'},
{value: 'aabr07065530.1', id: 'AABR07065530.1'},
{value: 'aabr07065531.1', id: 'AABR07065531.1'},
{value: 'aabr07065531.12', id: 'AABR07065531.12'},
{value: 'aabr07065531.13', id: 'AABR07065531.13'},
{value: 'aabr07065531.15', id: 'AABR07065531.15'},
{value: 'aabr07065531.16', id: 'AABR07065531.16'},
{value: 'aabr07065531.19', id: 'AABR07065531.19'},
{value: 'aabr07065531.2', id: 'AABR07065531.2'},
{value: 'aabr07065531.20', id: 'AABR07065531.20'},
{value: 'aabr07065531.22', id: 'AABR07065531.22'},
{value: 'aabr07065531.25', id: 'AABR07065531.25'},
{value: 'aabr07065531.26', id: 'AABR07065531.26'},
{value: 'aabr07065531.28', id: 'AABR07065531.28'},
{value: 'aabr07065531.3', id: 'AABR07065531.3'},
{value: 'aabr07065531.30', id: 'AABR07065531.30'},
{value: 'aabr07065531.34', id: 'AABR07065531.34'},
{value: 'aabr07065531.4', id: 'AABR07065531.4'},
{value: 'aabr07065531.6', id: 'AABR07065531.6'},
{value: 'aabr07065531.7', id: 'AABR07065531.7'},
{value: 'aabr07065531.8', id: 'AABR07065531.8'},
{value: 'aabr07065532.1', id: 'AABR07065532.1'},
{value: 'aabr07065532.3', id: 'AABR07065532.3'},
{value: 'aabr07065532.6', id: 'AABR07065532.6'},
{value: 'aabr07065532.8', id: 'AABR07065532.8'},
{value: 'aabr07065532.9', id: 'AABR07065532.9'},
{value: 'aabr07065566.1', id: 'AABR07065566.1'},
{value: 'aabr07065589.1', id: 'AABR07065589.1'},
{value: 'aabr07065590.1', id: 'AABR07065590.1'},
{value: 'aabr07065593.1', id: 'AABR07065593.1'},
{value: 'aabr07065593.2', id: 'AABR07065593.2'},
{value: 'aabr07065598.1', id: 'AABR07065598.1'},
{value: 'aabr07065600.1', id: 'AABR07065600.1'},
{value: 'aabr07065601.1', id: 'AABR07065601.1'},
{value: 'aabr07065602.2', id: 'AABR07065602.2'},
{value: 'aabr07065617.1', id: 'AABR07065617.1'},
{value: 'aabr07065640.1', id: 'AABR07065640.1'},
{value: 'aabr07065643.1', id: 'AABR07065643.1'},
{value: 'aabr07065645.1', id: 'AABR07065645.1'},
{value: 'aabr07065645.2', id: 'AABR07065645.2'},
{value: 'aabr07065651.1', id: 'AABR07065651.1'},
{value: 'aabr07065651.2', id: 'AABR07065651.2'},
{value: 'aabr07065651.3', id: 'AABR07065651.3'},
{value: 'aabr07065651.4', id: 'AABR07065651.4'},
{value: 'aabr07065651.5', id: 'AABR07065651.5'},
{value: 'aabr07065651.6', id: 'AABR07065651.6'},
{value: 'aabr07065651.7', id: 'AABR07065651.7'},
{value: 'aabr07065651.8', id: 'AABR07065651.8'},
{value: 'aabr07065656.1', id: 'AABR07065656.1'},
{value: 'aabr07065656.10', id: 'AABR07065656.10'},
{value: 'aabr07065656.2', id: 'AABR07065656.2'},
{value: 'aabr07065656.3', id: 'AABR07065656.3'},
{value: 'aabr07065656.4', id: 'AABR07065656.4'},
{value: 'aabr07065656.5', id: 'AABR07065656.5'},
{value: 'aabr07065656.6', id: 'AABR07065656.6'},
{value: 'aabr07065656.7', id: 'AABR07065656.7'},
{value: 'aabr07065656.8', id: 'AABR07065656.8'},
{value: 'aabr07065656.9', id: 'AABR07065656.9'},
{value: 'aabr07065673.1', id: 'AABR07065673.1'},
{value: 'aabr07065676.1', id: 'AABR07065676.1'},
{value: 'aabr07065680.1', id: 'AABR07065680.1'},
{value: 'aabr07065684.1', id: 'AABR07065684.1'},
{value: 'aabr07065688.1', id: 'AABR07065688.1'},
{value: 'aabr07065693.1', id: 'AABR07065693.1'},
{value: 'aabr07065693.2', id: 'AABR07065693.2'},
{value: 'aabr07065693.3', id: 'AABR07065693.3'},
{value: 'aabr07065699.1', id: 'AABR07065699.1'},
{value: 'aabr07065699.2', id: 'AABR07065699.2'},
{value: 'aabr07065699.3', id: 'AABR07065699.3'},
{value: 'aabr07065699.4', id: 'AABR07065699.4'},
{value: 'aabr07065699.5', id: 'AABR07065699.5'},
{value: 'aabr07065705.1', id: 'AABR07065705.1'},
{value: 'aabr07065705.2', id: 'AABR07065705.2'},
{value: 'aabr07065705.3', id: 'AABR07065705.3'},
{value: 'aabr07065705.4', id: 'AABR07065705.4'},
{value: 'aabr07065705.5', id: 'AABR07065705.5'},
{value: 'aabr07065705.6', id: 'AABR07065705.6'},
{value: 'aabr07065714.1', id: 'AABR07065714.1'},
{value: 'aabr07065737.1', id: 'AABR07065737.1'},
{value: 'aabr07065740.1', id: 'AABR07065740.1'},
{value: 'aabr07065750.1', id: 'AABR07065750.1'},
{value: 'aabr07065750.2', id: 'AABR07065750.2'},
{value: 'aabr07065750.3', id: 'AABR07065750.3'},
{value: 'aabr07065753.1', id: 'AABR07065753.1'},
{value: 'aabr07065766.1', id: 'AABR07065766.1'},
{value: 'aabr07065768.1', id: 'AABR07065768.1'},
{value: 'aabr07065768.2', id: 'AABR07065768.2'},
{value: 'aabr07065768.3', id: 'AABR07065768.3'},
{value: 'aabr07065772.1', id: 'AABR07065772.1'},
{value: 'aabr07065772.2', id: 'AABR07065772.2'},
{value: 'aabr07065772.3', id: 'AABR07065772.3'},
{value: 'aabr07065772.4', id: 'AABR07065772.4'},
{value: 'aabr07065773.1', id: 'AABR07065773.1'},
{value: 'aabr07065774.1', id: 'AABR07065774.1'},
{value: 'aabr07065776.1', id: 'AABR07065776.1'},
{value: 'aabr07065776.2', id: 'AABR07065776.2'},
{value: 'aabr07065776.3', id: 'AABR07065776.3'},
{value: 'aabr07065778.1', id: 'AABR07065778.1'},
{value: 'aabr07065778.2', id: 'AABR07065778.2'},
{value: 'aabr07065778.3', id: 'AABR07065778.3'},
{value: 'aabr07065780.1', id: 'AABR07065780.1'},
{value: 'aabr07065781.1', id: 'AABR07065781.1'},
{value: 'aabr07065782.1', id: 'AABR07065782.1'},
{value: 'aabr07065789.1', id: 'AABR07065789.1'},
{value: 'aabr07065789.2', id: 'AABR07065789.2'},
{value: 'aabr07065789.3', id: 'AABR07065789.3'},
{value: 'aabr07065790.1', id: 'AABR07065790.1'},
{value: 'aabr07065792.1', id: 'AABR07065792.1'},
{value: 'aabr07065792.2', id: 'AABR07065792.2'},
{value: 'aabr07065794.1', id: 'AABR07065794.1'},
{value: 'aabr07065798.1', id: 'AABR07065798.1'},
{value: 'aabr07065802.1', id: 'AABR07065802.1'},
{value: 'aabr07065804.1', id: 'AABR07065804.1'},
{value: 'aabr07065808.1', id: 'AABR07065808.1'},
{value: 'aabr07065811.1', id: 'AABR07065811.1'},
{value: 'aabr07065812.1', id: 'AABR07065812.1'},
{value: 'aabr07065812.2', id: 'AABR07065812.2'},
{value: 'aabr07065813.1', id: 'AABR07065813.1'},
{value: 'aabr07065814.1', id: 'AABR07065814.1'},
{value: 'aabr07065814.2', id: 'AABR07065814.2'},
{value: 'aabr07065814.3', id: 'AABR07065814.3'},
{value: 'aabr07065814.4', id: 'AABR07065814.4'},
{value: 'aabr07065814.5', id: 'AABR07065814.5'},
{value: 'aabr07065814.6', id: 'AABR07065814.6'},
{value: 'aabr07065814.7', id: 'AABR07065814.7'},
{value: 'aabr07065815.1', id: 'AABR07065815.1'},
{value: 'aabr07065815.2', id: 'AABR07065815.2'},
{value: 'aabr07065815.3', id: 'AABR07065815.3'},
{value: 'aabr07065818.1', id: 'AABR07065818.1'},
{value: 'aabr07065823.1', id: 'AABR07065823.1'},
{value: 'aabr07065823.2', id: 'AABR07065823.2'},
{value: 'aabr07065823.3', id: 'AABR07065823.3'},
{value: 'aabr07065827.1', id: 'AABR07065827.1'},
{value: 'aabr07065834.1', id: 'AABR07065834.1'},
{value: 'aabr07065837.1', id: 'AABR07065837.1'},
{value: 'aabr07065839.1', id: 'AABR07065839.1'},
{value: 'aabr07065844.1', id: 'AABR07065844.1'},
{value: 'aabr07065877.1', id: 'AABR07065877.1'},
{value: 'aabr07065883.1', id: 'AABR07065883.1'},
{value: 'aabr07065883.2', id: 'AABR07065883.2'},
{value: 'aabr07065886.1', id: 'AABR07065886.1'},
{value: 'aabr07065889.1', id: 'AABR07065889.1'},
{value: 'aabr07065889.2', id: 'AABR07065889.2'},
{value: 'aabr07065895.1', id: 'AABR07065895.1'},
{value: 'aabr07065897.1', id: 'AABR07065897.1'},
{value: 'aabr07065897.2', id: 'AABR07065897.2'},
{value: 'aabr07065925.1', id: 'AABR07065925.1'},
{value: 'aabr07065933.1', id: 'AABR07065933.1'},
{value: 'aabr07066020.1', id: 'AABR07066020.1'},
{value: 'aabr07066059.1', id: 'AABR07066059.1'},
{value: 'aabr07066060.1', id: 'AABR07066060.1'},
{value: 'aabr07066096.1', id: 'AABR07066096.1'},
{value: 'aabr07066096.2', id: 'AABR07066096.2'},
{value: 'aabr07066109.1', id: 'AABR07066109.1'},
{value: 'aabr07066114.1', id: 'AABR07066114.1'},
{value: 'aabr07066114.2', id: 'AABR07066114.2'},
{value: 'aabr07066135.1', id: 'AABR07066135.1'},
{value: 'aabr07066144.1', id: 'AABR07066144.1'},
{value: 'aabr07066180.1', id: 'AABR07066180.1'},
{value: 'aabr07066188.1', id: 'AABR07066188.1'},
{value: 'aabr07066201.1', id: 'AABR07066201.1'},
{value: 'aabr07066339.1', id: 'AABR07066339.1'},
{value: 'aabr07066339.2', id: 'AABR07066339.2'},
{value: 'aabr07066348.1', id: 'AABR07066348.1'},
{value: 'aabr07066379.1', id: 'AABR07066379.1'},
{value: 'aabr07066416.1', id: 'AABR07066416.1'},
{value: 'aabr07066416.2', id: 'AABR07066416.2'},
{value: 'aabr07066435.1', id: 'AABR07066435.1'},
{value: 'aabr07066436.1', id: 'AABR07066436.1'},
{value: 'aabr07066440.1', id: 'AABR07066440.1'},
{value: 'aabr07066470.1', id: 'AABR07066470.1'},
{value: 'aabr07066510.1', id: 'AABR07066510.1'},
{value: 'aabr07066516.1', id: 'AABR07066516.1'},
{value: 'aabr07066527.1', id: 'AABR07066527.1'},
{value: 'aabr07066529.1', id: 'AABR07066529.1'},
{value: 'aabr07066529.2', id: 'AABR07066529.2'},
{value: 'aabr07066529.3', id: 'AABR07066529.3'},
{value: 'aabr07066532.1', id: 'AABR07066532.1'},
{value: 'aabr07066534.2', id: 'AABR07066534.2'},
{value: 'aabr07066567.1', id: 'AABR07066567.1'},
{value: 'aabr07066648.1', id: 'AABR07066648.1'},
{value: 'aabr07066677.1', id: 'AABR07066677.1'},
{value: 'aabr07066690.1', id: 'AABR07066690.1'},
{value: 'aabr07066693.1', id: 'AABR07066693.1'},
{value: 'aabr07066700.1', id: 'AABR07066700.1'},
{value: 'aabr07066735.1', id: 'AABR07066735.1'},
{value: 'aabr07066736.1', id: 'AABR07066736.1'},
{value: 'aabr07066739.1', id: 'AABR07066739.1'},
{value: 'aabr07066748.1', id: 'AABR07066748.1'},
{value: 'aabr07066782.1', id: 'AABR07066782.1'},
{value: 'aabr07066782.2', id: 'AABR07066782.2'},
{value: 'aabr07066792.1', id: 'AABR07066792.1'},
{value: 'aabr07066798.1', id: 'AABR07066798.1'},
{value: 'aabr07066800.1', id: 'AABR07066800.1'},
{value: 'aabr07066818.1', id: 'AABR07066818.1'},
{value: 'aabr07066820.1', id: 'AABR07066820.1'},
{value: 'aabr07066826.1', id: 'AABR07066826.1'},
{value: 'aabr07066828.1', id: 'AABR07066828.1'},
{value: 'aabr07066828.2', id: 'AABR07066828.2'},
{value: 'aabr07066829.1', id: 'AABR07066829.1'},
{value: 'aabr07066829.2', id: 'AABR07066829.2'},
{value: 'aabr07066832.1', id: 'AABR07066832.1'},
{value: 'aabr07066838.1', id: 'AABR07066838.1'},
{value: 'aabr07066841.1', id: 'AABR07066841.1'},
{value: 'aabr07066841.2', id: 'AABR07066841.2'},
{value: 'aabr07066861.1', id: 'AABR07066861.1'},
{value: 'aabr07066870.1', id: 'AABR07066870.1'},
{value: 'aabr07066871.1', id: 'AABR07066871.1'},
{value: 'aabr07066871.2', id: 'AABR07066871.2'},
{value: 'aabr07066871.3', id: 'AABR07066871.3'},
{value: 'aabr07066875.1', id: 'AABR07066875.1'},
{value: 'aabr07066944.1', id: 'AABR07066944.1'},
{value: 'aabr07066946.1', id: 'AABR07066946.1'},
{value: 'aabr07066946.2', id: 'AABR07066946.2'},
{value: 'aabr07066957.1', id: 'AABR07066957.1'},
{value: 'aabr07066965.1', id: 'AABR07066965.1'},
{value: 'aabr07066987.1', id: 'AABR07066987.1'},
{value: 'aabr07066994.1', id: 'AABR07066994.1'},
{value: 'aabr07067006.1', id: 'AABR07067006.1'},
{value: 'aabr07067023.1', id: 'AABR07067023.1'},
{value: 'aabr07067024.1', id: 'AABR07067024.1'},
{value: 'aabr07067031.1', id: 'AABR07067031.1'},
{value: 'aabr07067031.2', id: 'AABR07067031.2'},
{value: 'aabr07067032.1', id: 'AABR07067032.1'},
{value: 'aabr07067036.1', id: 'AABR07067036.1'},
{value: 'aabr07067042.1', id: 'AABR07067042.1'},
{value: 'aabr07067042.3', id: 'AABR07067042.3'},
{value: 'aabr07067076.1', id: 'AABR07067076.1'},
{value: 'aabr07067080.1', id: 'AABR07067080.1'},
{value: 'aabr07067080.2', id: 'AABR07067080.2'},
{value: 'aabr07067082.1', id: 'AABR07067082.1'},
{value: 'aabr07067099.1', id: 'AABR07067099.1'},
{value: 'aabr07067138.1', id: 'AABR07067138.1'},
{value: 'aabr07067148.1', id: 'AABR07067148.1'},
{value: 'aabr07067183.1', id: 'AABR07067183.1'},
{value: 'aabr07067197.1', id: 'AABR07067197.1'},
{value: 'aabr07067198.1', id: 'AABR07067198.1'},
{value: 'aabr07067198.2', id: 'AABR07067198.2'},
{value: 'aabr07067210.1', id: 'AABR07067210.1'},
{value: 'aabr07067233.1', id: 'AABR07067233.1'},
{value: 'aabr07067233.2', id: 'AABR07067233.2'},
{value: 'aabr07067234.1', id: 'AABR07067234.1'},
{value: 'aabr07067246.1', id: 'AABR07067246.1'},
{value: 'aabr07067247.1', id: 'AABR07067247.1'},
{value: 'aabr07067267.1', id: 'AABR07067267.1'},
{value: 'aabr07067274.1', id: 'AABR07067274.1'},
{value: 'aabr07067291.1', id: 'AABR07067291.1'},
{value: 'aabr07067300.1', id: 'AABR07067300.1'},
{value: 'aabr07067310.1', id: 'AABR07067310.1'},
{value: 'aabr07067310.2', id: 'AABR07067310.2'},
{value: 'aabr07067339.1', id: 'AABR07067339.1'},
{value: 'aabr07067344.1', id: 'AABR07067344.1'},
{value: 'aabr07067349.1', id: 'AABR07067349.1'},
{value: 'aabr07067351.1', id: 'AABR07067351.1'},
{value: 'aabr07067355.1', id: 'AABR07067355.1'},
{value: 'aabr07067370.2', id: 'AABR07067370.2'},
{value: 'aabr07067378.1', id: 'AABR07067378.1'},
{value: 'aabr07067387.1', id: 'AABR07067387.1'},
{value: 'aabr07067388.1', id: 'AABR07067388.1'},
{value: 'aabr07067388.2', id: 'AABR07067388.2'},
{value: 'aabr07067401.2', id: 'AABR07067401.2'},
{value: 'aabr07067401.3', id: 'AABR07067401.3'},
{value: 'aabr07067402.1', id: 'AABR07067402.1'},
{value: 'aabr07067403.1', id: 'AABR07067403.1'},
{value: 'aabr07067404.1', id: 'AABR07067404.1'},
{value: 'aabr07067405.1', id: 'AABR07067405.1'},
{value: 'aabr07067441.1', id: 'AABR07067441.1'},
{value: 'aabr07067441.2', id: 'AABR07067441.2'},
{value: 'aabr07067441.3', id: 'AABR07067441.3'},
{value: 'aabr07067444.1', id: 'AABR07067444.1'},
{value: 'aabr07067452.1', id: 'AABR07067452.1'},
{value: 'aabr07067456.1', id: 'AABR07067456.1'},
{value: 'aabr07067459.1', id: 'AABR07067459.1'},
{value: 'aabr07067462.1', id: 'AABR07067462.1'},
{value: 'aabr07067469.1', id: 'AABR07067469.1'},
{value: 'aabr07067471.1', id: 'AABR07067471.1'},
{value: 'aabr07067471.2', id: 'AABR07067471.2'},
{value: 'aabr07067491.1', id: 'AABR07067491.1'},
{value: 'aabr07067491.2', id: 'AABR07067491.2'},
{value: 'aabr07067499.1', id: 'AABR07067499.1'},
{value: 'aabr07067504.1', id: 'AABR07067504.1'},
{value: 'aabr07067506.1', id: 'AABR07067506.1'},
{value: 'aabr07067506.2', id: 'AABR07067506.2'},
{value: 'aabr07067507.1', id: 'AABR07067507.1'},
{value: 'aabr07067507.2', id: 'AABR07067507.2'},
{value: 'aabr07067508.1', id: 'AABR07067508.1'},
{value: 'aabr07067520.1', id: 'AABR07067520.1'},
{value: 'aabr07067526.1', id: 'AABR07067526.1'},
{value: 'aabr07067556.1', id: 'AABR07067556.1'},
{value: 'aabr07067583.1', id: 'AABR07067583.1'},
{value: 'aabr07067594.1', id: 'AABR07067594.1'},
{value: 'aabr07067600.1', id: 'AABR07067600.1'},
{value: 'aabr07067649.1', id: 'AABR07067649.1'},
{value: 'aabr07067657.1', id: 'AABR07067657.1'},
{value: 'aabr07067717.1', id: 'AABR07067717.1'},
{value: 'aabr07067721.1', id: 'AABR07067721.1'},
{value: 'aabr07067742.1', id: 'AABR07067742.1'},
{value: 'aabr07067743.1', id: 'AABR07067743.1'},
{value: 'aabr07067749.2', id: 'AABR07067749.2'},
{value: 'aabr07067759.1', id: 'AABR07067759.1'},
{value: 'aabr07067762.1', id: 'AABR07067762.1'},
{value: 'aabr07067767.1', id: 'AABR07067767.1'},
{value: 'aabr07067767.2', id: 'AABR07067767.2'},
{value: 'aabr07067775.1', id: 'AABR07067775.1'},
{value: 'aabr07067775.2', id: 'AABR07067775.2'},
{value: 'aabr07067788.1', id: 'AABR07067788.1'},
{value: 'aabr07067791.1', id: 'AABR07067791.1'},
{value: 'aabr07067810.2', id: 'AABR07067810.2'},
{value: 'aabr07067812.1', id: 'AABR07067812.1'},
{value: 'aabr07067813.1', id: 'AABR07067813.1'},
{value: 'aabr07067814.1', id: 'AABR07067814.1'},
{value: 'aabr07067829.1', id: 'AABR07067829.1'},
{value: 'aabr07067851.1', id: 'AABR07067851.1'},
{value: 'aabr07067852.1', id: 'AABR07067852.1'},
{value: 'aabr07067855.1', id: 'AABR07067855.1'},
{value: 'aabr07067871.1', id: 'AABR07067871.1'},
{value: 'aabr07067871.2', id: 'AABR07067871.2'},
{value: 'aabr07067882.1', id: 'AABR07067882.1'},
{value: 'aabr07067885.1', id: 'AABR07067885.1'},
{value: 'aabr07067950.1', id: 'AABR07067950.1'},
{value: 'aabr07067965.1', id: 'AABR07067965.1'},
{value: 'aabr07067976.1', id: 'AABR07067976.1'},
{value: 'aabr07068008.1', id: 'AABR07068008.1'},
{value: 'aabr07068018.2', id: 'AABR07068018.2'},
{value: 'aabr07068030.1', id: 'AABR07068030.1'},
{value: 'aabr07068035.1', id: 'AABR07068035.1'},
{value: 'aabr07068042.1', id: 'AABR07068042.1'},
{value: 'aabr07068043.1', id: 'AABR07068043.1'},
{value: 'aabr07068044.1', id: 'AABR07068044.1'},
{value: 'aabr07068044.2', id: 'AABR07068044.2'},
{value: 'aabr07068046.1', id: 'AABR07068046.1'},
{value: 'aabr07068053.1', id: 'AABR07068053.1'},
{value: 'aabr07068060.1', id: 'AABR07068060.1'},
{value: 'aabr07068066.1', id: 'AABR07068066.1'},
{value: 'aabr07068066.2', id: 'AABR07068066.2'},
{value: 'aabr07068066.3', id: 'AABR07068066.3'},
{value: 'aabr07068071.1', id: 'AABR07068071.1'},
{value: 'aabr07068074.1', id: 'AABR07068074.1'},
{value: 'aabr07068084.1', id: 'AABR07068084.1'},
{value: 'aabr07068084.2', id: 'AABR07068084.2'},
{value: 'aabr07068085.1', id: 'AABR07068085.1'},
{value: 'aabr07068085.2', id: 'AABR07068085.2'},
{value: 'aabr07068085.3', id: 'AABR07068085.3'},
{value: 'aabr07068085.4', id: 'AABR07068085.4'},
{value: 'aabr07068086.1', id: 'AABR07068086.1'},
{value: 'aabr07068094.1', id: 'AABR07068094.1'},
{value: 'aabr07068094.2', id: 'AABR07068094.2'},
{value: 'aabr07068107.1', id: 'AABR07068107.1'},
{value: 'aabr07068138.1', id: 'AABR07068138.1'},
{value: 'aabr07068150.1', id: 'AABR07068150.1'},
{value: 'aabr07068152.1', id: 'AABR07068152.1'},
{value: 'aabr07068154.1', id: 'AABR07068154.1'},
{value: 'aabr07068162.1', id: 'AABR07068162.1'},
{value: 'aabr07068163.1', id: 'AABR07068163.1'},
{value: 'aabr07068179.1', id: 'AABR07068179.1'},
{value: 'aabr07068182.1', id: 'AABR07068182.1'},
{value: 'aabr07068198.1', id: 'AABR07068198.1'},
{value: 'aabr07068198.2', id: 'AABR07068198.2'},
{value: 'aabr07068198.3', id: 'AABR07068198.3'},
{value: 'aabr07068203.1', id: 'AABR07068203.1'},
{value: 'aabr07068214.1', id: 'AABR07068214.1'},
{value: 'aabr07068221.1', id: 'AABR07068221.1'},
{value: 'aabr07068222.1', id: 'AABR07068222.1'},
{value: 'aabr07068248.2', id: 'AABR07068248.2'},
{value: 'aabr07068249.1', id: 'AABR07068249.1'},
{value: 'aabr07068253.1', id: 'AABR07068253.1'},
{value: 'aabr07068274.1', id: 'AABR07068274.1'},
{value: 'aabr07068285.1', id: 'AABR07068285.1'},
{value: 'aabr07068306.1', id: 'AABR07068306.1'},
{value: 'aabr07068308.1', id: 'AABR07068308.1'},
{value: 'aabr07068316.2', id: 'AABR07068316.2'},
{value: 'aabr07068327.1', id: 'AABR07068327.1'},
{value: 'aabr07068327.2', id: 'AABR07068327.2'},
{value: 'aabr07068327.3', id: 'AABR07068327.3'},
{value: 'aabr07068341.1', id: 'AABR07068341.1'},
{value: 'aabr07068350.1', id: 'AABR07068350.1'},
{value: 'aabr07068351.1', id: 'AABR07068351.1'},
{value: 'aabr07068355.1', id: 'AABR07068355.1'},
{value: 'aabr07068360.1', id: 'AABR07068360.1'},
{value: 'aabr07068386.1', id: 'AABR07068386.1'},
{value: 'aabr07068400.1', id: 'AABR07068400.1'},
{value: 'aabr07068416.1', id: 'AABR07068416.1'},
{value: 'aabr07068417.1', id: 'AABR07068417.1'},
{value: 'aabr07068417.2', id: 'AABR07068417.2'},
{value: 'aabr07068420.1', id: 'AABR07068420.1'},
{value: 'aabr07068447.1', id: 'AABR07068447.1'},
{value: 'aabr07068455.1', id: 'AABR07068455.1'},
{value: 'aabr07068535.1', id: 'AABR07068535.1'},
{value: 'aabr07068536.1', id: 'AABR07068536.1'},
{value: 'aabr07068554.1', id: 'AABR07068554.1'},
{value: 'aabr07068555.1', id: 'AABR07068555.1'},
{value: 'aabr07068587.1', id: 'AABR07068587.1'},
{value: 'aabr07068590.1', id: 'AABR07068590.1'},
{value: 'aabr07068593.2', id: 'AABR07068593.2'},
{value: 'aabr07068612.1', id: 'AABR07068612.1'},
{value: 'aabr07068612.2', id: 'AABR07068612.2'},
{value: 'aabr07068614.1', id: 'AABR07068614.1'},
{value: 'aabr07068625.1', id: 'AABR07068625.1'},
{value: 'aabr07068636.1', id: 'AABR07068636.1'},
{value: 'aabr07068638.1', id: 'AABR07068638.1'},
{value: 'aabr07068650.1', id: 'AABR07068650.1'},
{value: 'aabr07068657.1', id: 'AABR07068657.1'},
{value: 'aabr07068674.1', id: 'AABR07068674.1'},
{value: 'aabr07068694.1', id: 'AABR07068694.1'},
{value: 'aabr07068705.1', id: 'AABR07068705.1'},
{value: 'aabr07068709.1', id: 'AABR07068709.1'},
{value: 'aabr07068711.1', id: 'AABR07068711.1'},
{value: 'aabr07068712.1', id: 'AABR07068712.1'},
{value: 'aabr07068712.2', id: 'AABR07068712.2'},
{value: 'aabr07068728.1', id: 'AABR07068728.1'},
{value: 'aabr07068736.1', id: 'AABR07068736.1'},
{value: 'aabr07068755.1', id: 'AABR07068755.1'},
{value: 'aabr07068760.1', id: 'AABR07068760.1'},
{value: 'aabr07068799.1', id: 'AABR07068799.1'},
{value: 'aabr07068810.1', id: 'AABR07068810.1'},
{value: 'aabr07068810.2', id: 'AABR07068810.2'},
{value: 'aabr07068812.1', id: 'AABR07068812.1'},
{value: 'aabr07068852.1', id: 'AABR07068852.1'},
{value: 'aabr07068905.1', id: 'AABR07068905.1'},
{value: 'aabr07068932.1', id: 'AABR07068932.1'},
{value: 'aabr07068949.1', id: 'AABR07068949.1'},
{value: 'aabr07068955.1', id: 'AABR07068955.1'},
{value: 'aabr07068975.1', id: 'AABR07068975.1'},
{value: 'aabr07068980.1', id: 'AABR07068980.1'},
{value: 'aabr07068997.1', id: 'AABR07068997.1'},
{value: 'aabr07069008.1', id: 'AABR07069008.1'},
{value: 'aabr07069008.2', id: 'AABR07069008.2'},
{value: 'aabr07069008.3', id: 'AABR07069008.3'},
{value: 'aabr07069011.1', id: 'AABR07069011.1'},
{value: 'aabr07069066.1', id: 'AABR07069066.1'},
{value: 'aabr07069067.1', id: 'AABR07069067.1'},
{value: 'aabr07069100.1', id: 'AABR07069100.1'},
{value: 'aabr07069128.1', id: 'AABR07069128.1'},
{value: 'aabr07069186.1', id: 'AABR07069186.1'},
{value: 'aabr07069197.1', id: 'AABR07069197.1'},
{value: 'aabr07069205.1', id: 'AABR07069205.1'},
{value: 'aabr07069211.1', id: 'AABR07069211.1'},
{value: 'aabr07069213.1', id: 'AABR07069213.1'},
{value: 'aabr07069218.1', id: 'AABR07069218.1'},
{value: 'aabr07069218.2', id: 'AABR07069218.2'},
{value: 'aabr07069219.1', id: 'AABR07069219.1'},
{value: 'aabr07069227.1', id: 'AABR07069227.1'},
{value: 'aabr07069230.1', id: 'AABR07069230.1'},
{value: 'aabr07069235.1', id: 'AABR07069235.1'},
{value: 'aabr07069238.1', id: 'AABR07069238.1'},
{value: 'aabr07069282.1', id: 'AABR07069282.1'},
{value: 'aabr07069301.1', id: 'AABR07069301.1'},
{value: 'aabr07069336.1', id: 'AABR07069336.1'},
{value: 'aabr07069336.2', id: 'AABR07069336.2'},
{value: 'aabr07069360.1', id: 'AABR07069360.1'},
{value: 'aabr07069371.1', id: 'AABR07069371.1'},
{value: 'aabr07069371.2', id: 'AABR07069371.2'},
{value: 'aabr07069398.1', id: 'AABR07069398.1'},
{value: 'aabr07069401.1', id: 'AABR07069401.1'},
{value: 'aabr07069433.1', id: 'AABR07069433.1'},
{value: 'aabr07069454.1', id: 'AABR07069454.1'},
{value: 'aabr07069466.1', id: 'AABR07069466.1'},
{value: 'aabr07069490.1', id: 'AABR07069490.1'},
{value: 'aabr07069490.2', id: 'AABR07069490.2'},
{value: 'aabr07069506.1', id: 'AABR07069506.1'},
{value: 'aabr07069524.1', id: 'AABR07069524.1'},
{value: 'aabr07069528.1', id: 'AABR07069528.1'},
{value: 'aabr07069585.1', id: 'AABR07069585.1'},
{value: 'aabr07069587.1', id: 'AABR07069587.1'},
{value: 'aabr07069587.2', id: 'AABR07069587.2'},
{value: 'aabr07069588.1', id: 'AABR07069588.1'},
{value: 'aabr07069611.1', id: 'AABR07069611.1'},
{value: 'aabr07069670.1', id: 'AABR07069670.1'},
{value: 'aabr07069728.1', id: 'AABR07069728.1'},
{value: 'aabr07069733.1', id: 'AABR07069733.1'},
{value: 'aabr07069791.1', id: 'AABR07069791.1'},
{value: 'aabr07069791.2', id: 'AABR07069791.2'},
{value: 'aabr07069791.3', id: 'AABR07069791.3'},
{value: 'aabr07069792.1', id: 'AABR07069792.1'},
{value: 'aabr07069792.2', id: 'AABR07069792.2'},
{value: 'aabr07069816.1', id: 'AABR07069816.1'},
{value: 'aabr07069821.1', id: 'AABR07069821.1'},
{value: 'aabr07069837.1', id: 'AABR07069837.1'},
{value: 'aabr07069840.1', id: 'AABR07069840.1'},
{value: 'aabr07069878.1', id: 'AABR07069878.1'},
{value: 'aabr07069913.1', id: 'AABR07069913.1'},
{value: 'aabr07069968.1', id: 'AABR07069968.1'},
{value: 'aabr07069969.2', id: 'AABR07069969.2'},
{value: 'aabr07069987.1', id: 'AABR07069987.1'},
{value: 'aabr07069992.1', id: 'AABR07069992.1'},
{value: 'aabr07070043.1', id: 'AABR07070043.1'},
{value: 'aabr07070043.2', id: 'AABR07070043.2'},
{value: 'aabr07070046.1', id: 'AABR07070046.1'},
{value: 'aabr07070069.1', id: 'AABR07070069.1'},
{value: 'aabr07070077.1', id: 'AABR07070077.1'},
{value: 'aabr07070078.1', id: 'AABR07070078.1'},
{value: 'aabr07070085.1', id: 'AABR07070085.1'},
{value: 'aabr07070087.1', id: 'AABR07070087.1'},
{value: 'aabr07070098.1', id: 'AABR07070098.1'},
{value: 'aabr07070099.1', id: 'AABR07070099.1'},
{value: 'aabr07070117.1', id: 'AABR07070117.1'},
{value: 'aabr07070122.1', id: 'AABR07070122.1'},
{value: 'aabr07070129.1', id: 'AABR07070129.1'},
{value: 'aabr07070131.1', id: 'AABR07070131.1'},
{value: 'aabr07070131.2', id: 'AABR07070131.2'},
{value: 'aabr07070151.1', id: 'AABR07070151.1'},
{value: 'aabr07070161.1', id: 'AABR07070161.1'},
{value: 'aabr07070161.2', id: 'AABR07070161.2'},
{value: 'aabr07070161.3', id: 'AABR07070161.3'},
{value: 'aabr07070161.4', id: 'AABR07070161.4'},
{value: 'aabr07070161.5', id: 'AABR07070161.5'},
{value: 'aabr07070161.6', id: 'AABR07070161.6'},
{value: 'aabr07070173.1', id: 'AABR07070173.1'},
{value: 'aabr07070185.1', id: 'AABR07070185.1'},
{value: 'aabr07070189.1', id: 'AABR07070189.1'},
{value: 'aabr07070198.1', id: 'AABR07070198.1'},
{value: 'aabr07070207.1', id: 'AABR07070207.1'},
{value: 'aabr07070221.1', id: 'AABR07070221.1'},
{value: 'aabr07070225.1', id: 'AABR07070225.1'},
{value: 'aabr07070238.1', id: 'AABR07070238.1'},
{value: 'aabr07070238.2', id: 'AABR07070238.2'},
{value: 'aabr07070238.3', id: 'AABR07070238.3'},
{value: 'aabr07070246.1', id: 'AABR07070246.1'},
{value: 'aabr07070265.1', id: 'AABR07070265.1'},
{value: 'aabr07070270.1', id: 'AABR07070270.1'},
{value: 'aabr07070277.1', id: 'AABR07070277.1'},
{value: 'aabr07070277.2', id: 'AABR07070277.2'},
{value: 'aabr07070278.1', id: 'AABR07070278.1'},
{value: 'aabr07070295.1', id: 'AABR07070295.1'},
{value: 'aabr07070307.1', id: 'AABR07070307.1'},
{value: 'aabr07070312.1', id: 'AABR07070312.1'},
{value: 'aabr07070314.1', id: 'AABR07070314.1'},
{value: 'aabr07070330.1', id: 'AABR07070330.1'},
{value: 'aabr07070331.1', id: 'AABR07070331.1'},
{value: 'aabr07070335.1', id: 'AABR07070335.1'},
{value: 'aabr07070341.1', id: 'AABR07070341.1'},
{value: 'aabr07070341.2', id: 'AABR07070341.2'},
{value: 'aabr07070341.3', id: 'AABR07070341.3'},
{value: 'aabr07070345.1', id: 'AABR07070345.1'},
{value: 'aabr07070355.1', id: 'AABR07070355.1'},
{value: 'aabr07070357.1', id: 'AABR07070357.1'},
{value: 'aabr07070416.1', id: 'AABR07070416.1'},
{value: 'aabr07070441.1', id: 'AABR07070441.1'},
{value: 'aabr07070456.1', id: 'AABR07070456.1'},
{value: 'aabr07070486.1', id: 'AABR07070486.1'},
{value: 'aabr07070505.5', id: 'AABR07070505.5'},
{value: 'aabr07070507.1', id: 'AABR07070507.1'},
{value: 'aabr07070518.1', id: 'AABR07070518.1'},
{value: 'aabr07070527.1', id: 'AABR07070527.1'},
{value: 'aabr07070532.1', id: 'AABR07070532.1'},
{value: 'aabr07070559.1', id: 'AABR07070559.1'},
{value: 'aabr07070578.1', id: 'AABR07070578.1'},
{value: 'aabr07070580.1', id: 'AABR07070580.1'},
{value: 'aabr07070581.1', id: 'AABR07070581.1'},
{value: 'aabr07070587.1', id: 'AABR07070587.1'},
{value: 'aabr07070594.1', id: 'AABR07070594.1'},
{value: 'aabr07070599.1', id: 'AABR07070599.1'},
{value: 'aabr07070603.1', id: 'AABR07070603.1'},
{value: 'aabr07070609.1', id: 'AABR07070609.1'},
{value: 'aabr07070635.1', id: 'AABR07070635.1'},
{value: 'aabr07070669.1', id: 'AABR07070669.1'},
{value: 'aabr07070679.1', id: 'AABR07070679.1'},
{value: 'aabr07070689.2', id: 'AABR07070689.2'},
{value: 'aabr07070698.1', id: 'AABR07070698.1'},
{value: 'aabr07070704.1', id: 'AABR07070704.1'},
{value: 'aabr07070714.1', id: 'AABR07070714.1'},
{value: 'aabr07070714.2', id: 'AABR07070714.2'},
{value: 'aabr07070720.1', id: 'AABR07070720.1'},
{value: 'aabr07070773.1', id: 'AABR07070773.1'},
{value: 'aabr07070774.1', id: 'AABR07070774.1'},
{value: 'aabr07070775.1', id: 'AABR07070775.1'},
{value: 'aabr07070796.1', id: 'AABR07070796.1'},
{value: 'aabr07070799.1', id: 'AABR07070799.1'},
{value: 'aabr07070801.1', id: 'AABR07070801.1'},
{value: 'aabr07070801.2', id: 'AABR07070801.2'},
{value: 'aabr07070802.1', id: 'AABR07070802.1'},
{value: 'aabr07070816.1', id: 'AABR07070816.1'},
{value: 'aabr07070816.2', id: 'AABR07070816.2'},
{value: 'aabr07070816.3', id: 'AABR07070816.3'},
{value: 'aabr07070824.1', id: 'AABR07070824.1'},
{value: 'aabr07070834.1', id: 'AABR07070834.1'},
{value: 'aabr07070850.1', id: 'AABR07070850.1'},
{value: 'aabr07070851.1', id: 'AABR07070851.1'},
{value: 'aabr07070858.1', id: 'AABR07070858.1'},
{value: 'aabr07070872.1', id: 'AABR07070872.1'},
{value: 'aabr07070873.1', id: 'AABR07070873.1'},
{value: 'aabr07070887.1', id: 'AABR07070887.1'},
{value: 'aabr07070892.1', id: 'AABR07070892.1'},
{value: 'aabr07070901.1', id: 'AABR07070901.1'},
{value: 'aabr07070912.1', id: 'AABR07070912.1'},
{value: 'aabr07070913.1', id: 'AABR07070913.1'},
{value: 'aabr07070926.1', id: 'AABR07070926.1'},
{value: 'aabr07070952.1', id: 'AABR07070952.1'},
{value: 'aabr07070957.1', id: 'AABR07070957.1'},
{value: 'aabr07070973.1', id: 'AABR07070973.1'},
{value: 'aabr07070982.1', id: 'AABR07070982.1'},
{value: 'aabr07070982.2', id: 'AABR07070982.2'},
{value: 'aabr07070988.1', id: 'AABR07070988.1'},
{value: 'aabr07070999.1', id: 'AABR07070999.1'},
{value: 'aabr07071000.1', id: 'AABR07071000.1'},
{value: 'aabr07071007.1', id: 'AABR07071007.1'},
{value: 'aabr07071045.1', id: 'AABR07071045.1'},
{value: 'aabr07071101.1', id: 'AABR07071101.1'},
{value: 'aabr07071101.2', id: 'AABR07071101.2'},
{value: 'aabr07071117.1', id: 'AABR07071117.1'},
{value: 'aabr07071167.1', id: 'AABR07071167.1'},
{value: 'aabr07071187.1', id: 'AABR07071187.1'},
{value: 'aabr07071195.1', id: 'AABR07071195.1'},
{value: 'aabr07071198.1', id: 'AABR07071198.1'},
{value: 'aabr07071199.1', id: 'AABR07071199.1'},
{value: 'aabr07071199.2', id: 'AABR07071199.2'},
{value: 'aabr07071208.1', id: 'AABR07071208.1'},
{value: 'aabr07071208.2', id: 'AABR07071208.2'},
{value: 'aabr07071210.1', id: 'AABR07071210.1'},
{value: 'aabr07071210.2', id: 'AABR07071210.2'},
{value: 'aabr07071223.1', id: 'AABR07071223.1'},
{value: 'aabr07071223.2', id: 'AABR07071223.2'},
{value: 'aabr07071228.1', id: 'AABR07071228.1'},
{value: 'aabr07071243.1', id: 'AABR07071243.1'},
{value: 'aabr07071244.1', id: 'AABR07071244.1'},
{value: 'aabr07071245.1', id: 'AABR07071245.1'},
{value: 'aabr07071287.1', id: 'AABR07071287.1'},
{value: 'aabr07071293.1', id: 'AABR07071293.1'},
{value: 'aabr07071294.1', id: 'AABR07071294.1'},
{value: 'aabr07071294.2', id: 'AABR07071294.2'},
{value: 'aabr07071299.1', id: 'AABR07071299.1'},
{value: 'aabr07071328.1', id: 'AABR07071328.1'},
{value: 'aabr07071332.2', id: 'AABR07071332.2'},
{value: 'aabr07071333.1', id: 'AABR07071333.1'},
{value: 'aabr07071335.1', id: 'AABR07071335.1'},
{value: 'aabr07071358.1', id: 'AABR07071358.1'},
{value: 'aabr07071368.1', id: 'AABR07071368.1'},
{value: 'aabr07071374.1', id: 'AABR07071374.1'},
{value: 'aabr07071383.1', id: 'AABR07071383.1'},
{value: 'aabr07071385.1', id: 'AABR07071385.1'},
{value: 'aabr07071385.2', id: 'AABR07071385.2'},
{value: 'aabr07071392.1', id: 'AABR07071392.1'},
{value: 'aabr07071393.1', id: 'AABR07071393.1'},
{value: 'aabr07071395.1', id: 'AABR07071395.1'},
{value: 'aabr07071402.1', id: 'AABR07071402.1'},
{value: 'aabr07071440.1', id: 'AABR07071440.1'},
{value: 'aabr07071445.1', id: 'AABR07071445.1'},
{value: 'aabr07071482.1', id: 'AABR07071482.1'},
{value: 'aabr07071482.2', id: 'AABR07071482.2'},
{value: 'aabr07071487.1', id: 'AABR07071487.1'},
{value: 'aabr07071500.1', id: 'AABR07071500.1'},
{value: 'aabr07071512.1', id: 'AABR07071512.1'},
{value: 'aabr07071534.1', id: 'AABR07071534.1'},
{value: 'aabr07071549.1', id: 'AABR07071549.1'},
{value: 'aabr07071551.1', id: 'AABR07071551.1'},
{value: 'aabr07071551.2', id: 'AABR07071551.2'},
{value: 'aabr07071552.1', id: 'AABR07071552.1'},
{value: 'aabr07071567.2', id: 'AABR07071567.2'},
{value: 'aabr07071589.1', id: 'AABR07071589.1'},
{value: 'aabr07071598.1', id: 'AABR07071598.1'},
{value: 'aabr07071619.1', id: 'AABR07071619.1'},
{value: 'aabr07071620.1', id: 'AABR07071620.1'},
{value: 'aabr07071622.1', id: 'AABR07071622.1'},
{value: 'aabr07071659.1', id: 'AABR07071659.1'},
{value: 'aabr07071659.2', id: 'AABR07071659.2'},
{value: 'aabr07071659.3', id: 'AABR07071659.3'},
{value: 'aabr07071662.1', id: 'AABR07071662.1'},
{value: 'aabr07071697.1', id: 'AABR07071697.1'},
{value: 'aabr07071701.1', id: 'AABR07071701.1'},
{value: 'aabr07071731.1', id: 'AABR07071731.1'},
{value: 'aabr07071739.1', id: 'AABR07071739.1'},
{value: 'aabr07071742.1', id: 'AABR07071742.1'},
{value: 'aabr07071742.2', id: 'AABR07071742.2'},
{value: 'aabr07071745.1', id: 'AABR07071745.1'},
{value: 'aabr07071746.1', id: 'AABR07071746.1'},
{value: 'aabr07071746.2', id: 'AABR07071746.2'},
{value: 'aabr07071747.1', id: 'AABR07071747.1'},
{value: 'aabr07071748.1', id: 'AABR07071748.1'},
{value: 'aabr07071753.1', id: 'AABR07071753.1'},
{value: 'aabr07071762.1', id: 'AABR07071762.1'},
{value: 'aabr07071765.1', id: 'AABR07071765.1'},
{value: 'aabr07071765.2', id: 'AABR07071765.2'},
{value: 'aabr07071768.1', id: 'AABR07071768.1'},
{value: 'aabr07071779.1', id: 'AABR07071779.1'},
{value: 'aabr07071779.2', id: 'AABR07071779.2'},
{value: 'aabr07071813.1', id: 'AABR07071813.1'},
{value: 'aabr07071814.1', id: 'AABR07071814.1'},
{value: 'aabr07071817.1', id: 'AABR07071817.1'},
{value: 'aabr07071818.1', id: 'AABR07071818.1'},
{value: 'aabr07071819.1', id: 'AABR07071819.1'},
{value: 'aabr07071821.1', id: 'AABR07071821.1'},
{value: 'aabr07071838.1', id: 'AABR07071838.1'},
{value: 'aabr07071848.1', id: 'AABR07071848.1'},
{value: 'aabr07071851.1', id: 'AABR07071851.1'},
{value: 'aabr07071851.2', id: 'AABR07071851.2'},
{value: 'aabr07071871.1', id: 'AABR07071871.1'},
{value: 'aabr07071876.1', id: 'AABR07071876.1'},
{value: 'aabr07071886.1', id: 'AABR07071886.1'},
{value: 'aabr07071891.1', id: 'AABR07071891.1'},
{value: 'aabr07071891.2', id: 'AABR07071891.2'},
{value: 'aabr07071891.3', id: 'AABR07071891.3'},
{value: 'aabr07071898.1', id: 'AABR07071898.1'},
{value: 'aabr07071904.1', id: 'AABR07071904.1'},
{value: 'aabr07071904.2', id: 'AABR07071904.2'},
{value: 'aabr07071905.2', id: 'AABR07071905.2'},
{value: 'aabr07071935.1', id: 'AABR07071935.1'},
{value: 'aabr07071959.1', id: 'AABR07071959.1'},
{value: 'aabr07071966.1', id: 'AABR07071966.1'},
{value: 'aabr07071969.1', id: 'AABR07071969.1'},
{value: 'aabr07071986.1', id: 'AABR07071986.1'},
{value: 'aabr07071993.1', id: 'AABR07071993.1'},
{value: 'aabr07072002.1', id: 'AABR07072002.1'},
{value: 'aabr07072025.1', id: 'AABR07072025.1'},
{value: 'aabr07072028.1', id: 'AABR07072028.1'},
{value: 'aabr07072030.1', id: 'AABR07072030.1'},
{value: 'aabr07072030.2', id: 'AABR07072030.2'},
{value: 'aabr07072045.1', id: 'AABR07072045.1'},
{value: 'aabr07072054.1', id: 'AABR07072054.1'},
{value: 'aabr07072058.1', id: 'AABR07072058.1'},
{value: 'aabr07072065.1', id: 'AABR07072065.1'},
{value: 'aabr07072078.1', id: 'AABR07072078.1'},
{value: 'aabr07072078.2', id: 'AABR07072078.2'},
{value: 'aabr07072096.1', id: 'AABR07072096.1'},
{value: 'aabr07072106.1', id: 'AABR07072106.1'},
{value: 'aabr07072108.1', id: 'AABR07072108.1'},
{value: 'aabr07072112.1', id: 'AABR07072112.1'},
{value: 'aabr07072118.1', id: 'AABR07072118.1'},
{value: 'aabr07072127.1', id: 'AABR07072127.1'},
{value: 'aabr07072133.1', id: 'AABR07072133.1'},
{value: 'aabr07072145.1', id: 'AABR07072145.1'},
{value: 'aabr07072152.1', id: 'AABR07072152.1'},
{value: 'aabr07072158.1', id: 'AABR07072158.1'},
{value: 'aabr07072169.1', id: 'AABR07072169.1'},
{value: 'aabr07072181.1', id: 'AABR07072181.1'},
{value: 'aabr07072181.2', id: 'AABR07072181.2'},
{value: 'aabr07072181.3', id: 'AABR07072181.3'},
{value: 'aabr07072184.1', id: 'AABR07072184.1'},
{value: 'aabr07072184.2', id: 'AABR07072184.2'},
{value: 'aabr07072184.3', id: 'AABR07072184.3'},
{value: 'aabr07072200.1', id: 'AABR07072200.1'},
{value: 'aabr07072207.1', id: 'AABR07072207.1'},
{value: 'aabr07072236.1', id: 'AABR07072236.1'},
{value: 'aabr07072260.1', id: 'AABR07072260.1'},
{value: 'aabr07072261.1', id: 'AABR07072261.1'},
{value: 'aabr07072262.1', id: 'AABR07072262.1'},
{value: 'aabr07072264.4', id: 'AABR07072264.4'},
{value: 'aabr07072264.5', id: 'AABR07072264.5'},
{value: 'aabr07072266.1', id: 'AABR07072266.1'},
{value: 'aabr07072272.1', id: 'AABR07072272.1'},
{value: 'aabr07072272.2', id: 'AABR07072272.2'},
{value: 'aabr07072283.1', id: 'AABR07072283.1'},
{value: 'aabr07072283.2', id: 'AABR07072283.2'},
{value: 'aabr07072325.1', id: 'AABR07072325.1'},
{value: 'aabr07072339.2', id: 'AABR07072339.2'},
{value: 'aabr07072340.1', id: 'AABR07072340.1'},
{value: 'aabr07072356.1', id: 'AABR07072356.1'},
{value: 'aabr07072371.1', id: 'AABR07072371.1'},
{value: 'aabr07072374.1', id: 'AABR07072374.1'},
{value: 'aabr07072374.2', id: 'AABR07072374.2'},
{value: 'aabr07072400.1', id: 'AABR07072400.1'},
{value: 'aabr07072400.2', id: 'AABR07072400.2'},
{value: 'aabr07072400.3', id: 'AABR07072400.3'},
{value: 'aabr07072409.1', id: 'AABR07072409.1'},
{value: 'aabr07072414.1', id: 'AABR07072414.1'},
{value: 'aabr07072416.1', id: 'AABR07072416.1'},
{value: 'aabr07072440.1', id: 'AABR07072440.1'},
{value: 'aabr07072449.2', id: 'AABR07072449.2'},
{value: 'aabr07072452.1', id: 'AABR07072452.1'},
{value: 'aabr07072463.1', id: 'AABR07072463.1'},
{value: 'aabr07072470.1', id: 'AABR07072470.1'},
{value: 'aabr07072499.1', id: 'AABR07072499.1'},
{value: 'aabr07072511.1', id: 'AABR07072511.1'},
{value: 'aabr07072515.1', id: 'AABR07072515.1'},
{value: 'aabr07072528.1', id: 'AABR07072528.1'},
{value: 'aabr07072528.2', id: 'AABR07072528.2'},
{value: 'aabr07072528.3', id: 'AABR07072528.3'},
{value: 'aabr07072528.4', id: 'AABR07072528.4'},
{value: 'aabr07072539.1', id: 'AABR07072539.1'},
{value: 'aabr07072539.4', id: 'AABR07072539.4'},
{value: 'aabr07072539.5', id: 'AABR07072539.5'},
{value: 'aabr07072539.6', id: 'AABR07072539.6'},
{value: 'aabr07072558.1', id: 'AABR07072558.1'},
{value: 'aabr07072559.2', id: 'AABR07072559.2'},
{value: 'aabr07072562.1', id: 'AABR07072562.1'},
{value: 'aabr07072580.1', id: 'AABR07072580.1'},
{value: 'aabr07072591.1', id: 'AABR07072591.1'},
{value: 'aabr07072593.1', id: 'AABR07072593.1'},
{value: 'aabr07072602.1', id: 'AABR07072602.1'},
{value: 'aabr07072639.1', id: 'AABR07072639.1'},
{value: 'aabr07072639.2', id: 'AABR07072639.2'},
{value: 'aabr07072646.1', id: 'AABR07072646.1'},
{value: 'aabr07072664.1', id: 'AABR07072664.1'},
{value: 'aabr07072667.1', id: 'AABR07072667.1'},
{value: 'aabr07072668.1', id: 'AABR07072668.1'},
{value: 'aabr07072671.2', id: 'AABR07072671.2'},
{value: 'aabr07072676.2', id: 'AABR07072676.2'},
{value: 'aabr07072676.3', id: 'AABR07072676.3'},
{value: 'aabr07072704.1', id: 'AABR07072704.1'},
{value: 'aabr07072748.1', id: 'AABR07072748.1'},
{value: 'aabr07072748.2', id: 'AABR07072748.2'},
{value: 'aabr07072758.1', id: 'AABR07072758.1'},
{value: 'aabr07072759.2', id: 'AABR07072759.2'},
{value: 'aabr07072759.3', id: 'AABR07072759.3'},
{value: 'aabr07072761.1', id: 'AABR07072761.1'},
{value: 'aabr07072770.1', id: 'AABR07072770.1'},
{value: 'aabr07072773.1', id: 'AABR07072773.1'},
{value: 'aabr07072775.1', id: 'AABR07072775.1'},
{value: 'aabr07072805.1', id: 'AABR07072805.1'},
{value: 'aabr07072807.1', id: 'AABR07072807.1'},
{value: 'aabr07072809.1', id: 'AABR07072809.1'},
{value: 'aabr07072810.1', id: 'AABR07072810.1'},
{value: 'aabr07072818.1', id: 'AABR07072818.1'},
{value: 'aabr07072826.1', id: 'AABR07072826.1'},
{value: 'aabr07072830.1', id: 'AABR07072830.1'},
{value: 'aabr07072837.1', id: 'AABR07072837.1'},
{value: 'aabr07072841.1', id: 'AABR07072841.1'},
{value: 'aabr07072853.1', id: 'AABR07072853.1'},
{value: 'aabr07072853.2', id: 'AABR07072853.2'},
{value: 'aabr07072853.3', id: 'AABR07072853.3'},
{value: 'aabr07072853.4', id: 'AABR07072853.4'},
{value: 'aabr07072853.5', id: 'AABR07072853.5'},
{value: 'aabr07072872.1', id: 'AABR07072872.1'},
{value: 'aabr07072876.1', id: 'AABR07072876.1'},
{value: 'aabr07072876.2', id: 'AABR07072876.2'},
{value: 'aabr07072881.1', id: 'AABR07072881.1'},
{value: 'aabr07072894.1', id: 'AABR07072894.1'},
{value: 'aabr07072897.1', id: 'AABR07072897.1'},
{value: 'aabr07072916.1', id: 'AABR07072916.1'},
{value: 'aabr07072933.1', id: 'AABR07072933.1'},
{value: 'aabr07072936.1', id: 'AABR07072936.1'},
{value: 'aabr07072945.1', id: 'AABR07072945.1'},
{value: 'aabr07072951.1', id: 'AABR07072951.1'},
{value: 'aabr07072963.1', id: 'AABR07072963.1'},
{value: 'aabr07072979.1', id: 'AABR07072979.1'},
{value: 'aabr07072984.1', id: 'AABR07072984.1'},
{value: 'aabr07073013.1', id: 'AABR07073013.1'},
{value: 'aabr07073014.1', id: 'AABR07073014.1'},
{value: 'aabr07073021.1', id: 'AABR07073021.1'},
{value: 'aabr07073021.2', id: 'AABR07073021.2'},
{value: 'aabr07073024.1', id: 'AABR07073024.1'},
{value: 'aabr07073038.2', id: 'AABR07073038.2'},
{value: 'aabr07073038.3', id: 'AABR07073038.3'},
{value: 'aabr07073059.1', id: 'AABR07073059.1'},
{value: 'aabr07073073.1', id: 'AABR07073073.1'},
{value: 'aabr07073110.1', id: 'AABR07073110.1'},
{value: 'aabr07073144.1', id: 'AABR07073144.1'},
{value: 'aabr07073146.1', id: 'AABR07073146.1'},
{value: 'aabr07073181.1', id: 'AABR07073181.1'},
{value: 'aabr07073181.2', id: 'AABR07073181.2'},
{value: 'aabr07073186.2', id: 'AABR07073186.2'},
{value: 'aabr07073245.1', id: 'AABR07073245.1'},
{value: 'aabr07073271.1', id: 'AABR07073271.1'},
{value: 'aabr07073295.1', id: 'AABR07073295.1'},
{value: 'aabr07073350.1', id: 'AABR07073350.1'},
{value: 'aabr07073350.2', id: 'AABR07073350.2'},
{value: 'aabr07073351.1', id: 'AABR07073351.1'},
{value: 'aabr07073360.1', id: 'AABR07073360.1'},
{value: 'aabr07073367.1', id: 'AABR07073367.1'},
{value: 'aabr07073381.1', id: 'AABR07073381.1'},
{value: 'aabr07073391.1', id: 'AABR07073391.1'},
{value: 'aabr07073392.1', id: 'AABR07073392.1'},
{value: 'aabr07073400.1', id: 'AABR07073400.1'},
{value: 'aabr07073420.1', id: 'AABR07073420.1'},
{value: 'aabr07073453.1', id: 'AABR07073453.1'},
{value: 'aabr07073455.1', id: 'AABR07073455.1'},
{value: 'aabr07073466.1', id: 'AABR07073466.1'},
{value: 'aabr07073489.1', id: 'AABR07073489.1'},
{value: 'aabr07073515.1', id: 'AABR07073515.1'},
{value: 'aabr07073516.1', id: 'AABR07073516.1'},
{value: 'aabr07073531.1', id: 'AABR07073531.1'},
{value: 'aabr07073536.1', id: 'AABR07073536.1'},
{value: 'aabr07073539.1', id: 'AABR07073539.1'},
{value: 'aabr07073541.1', id: 'AABR07073541.1'},
{value: 'aacs', id: 'AACS'},
{value: 'aadac', id: 'AADAC'},
{value: 'aadacl2', id: 'AADACL2'},
{value: 'aadacl3', id: 'AADACL3'},
{value: 'aadacl4', id: 'AADACL4'},
{value: 'aadat', id: 'AADAT'},
{value: 'aagab', id: 'AAGAB'},
{value: 'aak1', id: 'AAK1'},
{value: 'aamdc', id: 'AAMDC'},
{value: 'aamp', id: 'AAMP'},
{value: 'aanat', id: 'AANAT'},
{value: 'aar2', id: 'AAR2'},
{value: 'aard', id: 'AARD'},
{value: 'aars1', id: 'AARS1'},
{value: 'aars2', id: 'AARS2'},
{value: 'aarsd1', id: 'AARSD1'},
{value: 'aasdh', id: 'AASDH'},
{value: 'aasdhppt', id: 'AASDHPPT'},
{value: 'aass', id: 'AASS'},
{value: 'aatf', id: 'AATF'},
{value: 'aatk', id: 'AATK'},
{value: 'abat', id: 'ABAT'},
{value: 'abca1', id: 'ABCA1'},
{value: 'abca12', id: 'ABCA12'},
{value: 'abca13', id: 'ABCA13'},
{value: 'abca14', id: 'ABCA14'},
{value: 'abca15', id: 'ABCA15'},
{value: 'abca16', id: 'ABCA16'},
{value: 'abca17', id: 'ABCA17'},
{value: 'abca2', id: 'ABCA2'},
{value: 'abca3', id: 'ABCA3'},
{value: 'abca4', id: 'ABCA4'},
{value: 'abca5', id: 'ABCA5'},
{value: 'abca6', id: 'ABCA6'},
{value: 'abca7', id: 'ABCA7'},
{value: 'abca8', id: 'ABCA8'},
{value: 'abca8a', id: 'ABCA8A'},
{value: 'abca9', id: 'ABCA9'},
{value: 'abcb10', id: 'ABCB10'},
{value: 'abcb11', id: 'ABCB11'},
{value: 'abcb1a', id: 'ABCB1A'},
{value: 'abcb1b', id: 'ABCB1B'},
{value: 'abcb4', id: 'ABCB4'},
{value: 'abcb5', id: 'ABCB5'},
{value: 'abcb6', id: 'ABCB6'},
{value: 'abcb7', id: 'ABCB7'},
{value: 'abcb8', id: 'ABCB8'},
{value: 'abcb9', id: 'ABCB9'},
{value: 'abcc1', id: 'ABCC1'},
{value: 'abcc10', id: 'ABCC10'},
{value: 'abcc12', id: 'ABCC12'},
{value: 'abcc2', id: 'ABCC2'},
{value: 'abcc3', id: 'ABCC3'},
{value: 'abcc4', id: 'ABCC4'},
{value: 'abcc5', id: 'ABCC5'},
{value: 'abcc6', id: 'ABCC6'},
{value: 'abcc8', id: 'ABCC8'},
{value: 'abcc9', id: 'ABCC9'},
{value: 'abcd1', id: 'ABCD1'},
{value: 'abcd2', id: 'ABCD2'},
{value: 'abcd3', id: 'ABCD3'},
{value: 'abcd4', id: 'ABCD4'},
{value: 'abce1', id: 'ABCE1'},
{value: 'abcf1', id: 'ABCF1'},
{value: 'abcf2', id: 'ABCF2'},
{value: 'abcf3', id: 'ABCF3'},
{value: 'abcg1', id: 'ABCG1'},
{value: 'abcg2', id: 'ABCG2'},
{value: 'abcg3', id: 'ABCG3'},
{value: 'abcg3l1', id: 'ABCG3L1'},
{value: 'abcg3l2', id: 'ABCG3L2'},
{value: 'abcg3l3', id: 'ABCG3L3'},
{value: 'abcg3l4', id: 'ABCG3L4'},
{value: 'abcg4', id: 'ABCG4'},
{value: 'abcg5', id: 'ABCG5'},
{value: 'abcg8', id: 'ABCG8'},
{value: 'abhd1', id: 'ABHD1'},
{value: 'abhd10', id: 'ABHD10'},
{value: 'abhd11', id: 'ABHD11'},
{value: 'abhd12', id: 'ABHD12'},
{value: 'abhd12b', id: 'ABHD12B'},
{value: 'abhd13', id: 'ABHD13'},
{value: 'abhd14a', id: 'ABHD14A'},
{value: 'abhd14b', id: 'ABHD14B'},
{value: 'abhd15', id: 'ABHD15'},
{value: 'abhd16a', id: 'ABHD16A'},
{value: 'abhd16b', id: 'ABHD16B'},
{value: 'abhd17a', id: 'ABHD17A'},
{value: 'abhd17b', id: 'ABHD17B'},
{value: 'abhd17c', id: 'ABHD17C'},
{value: 'abhd18', id: 'ABHD18'},
{value: 'abhd2', id: 'ABHD2'},
{value: 'abhd3', id: 'ABHD3'},
{value: 'abhd4', id: 'ABHD4'},
{value: 'abhd5', id: 'ABHD5'},
{value: 'abhd6', id: 'ABHD6'},
{value: 'abhd8', id: 'ABHD8'},
{value: 'abi1', id: 'ABI1'},
{value: 'abi2', id: 'ABI2'},
{value: 'abi3', id: 'ABI3'},
{value: 'abi3bp', id: 'ABI3BP'},
{value: 'abitram', id: 'ABITRAM'},
{value: 'abl1', id: 'ABL1'},
{value: 'abl2', id: 'ABL2'},
{value: 'ablim1', id: 'ABLIM1'},
{value: 'ablim2', id: 'ABLIM2'},
{value: 'ablim3', id: 'ABLIM3'},
{value: 'abo', id: 'ABO'},
{value: 'abo2', id: 'ABO2'},
{value: 'abo3', id: 'ABO3'},
{value: 'abr', id: 'ABR'},
{value: 'abra', id: 'ABRA'},
{value: 'abracl', id: 'ABRACL'},
{value: 'abraxas1', id: 'ABRAXAS1'},
{value: 'abraxas2', id: 'ABRAXAS2'},
{value: 'abt1', id: 'ABT1'},
{value: 'abtb1', id: 'ABTB1'},
{value: 'abtb2', id: 'ABTB2'},
{value: 'ac080157.1', id: 'AC080157.1'},
{value: 'ac091481.1', id: 'AC091481.1'},
{value: 'ac091481.2', id: 'AC091481.2'},
{value: 'ac091481.3', id: 'AC091481.3'},
{value: 'ac093960.1', id: 'AC093960.1'},
{value: 'ac093995.1', id: 'AC093995.1'},
{value: 'ac093995.2', id: 'AC093995.2'},
{value: 'ac093995.3', id: 'AC093995.3'},
{value: 'ac094001.1', id: 'AC094001.1'},
{value: 'ac094055.1', id: 'AC094055.1'},
{value: 'ac094067.1', id: 'AC094067.1'},
{value: 'ac094212.1', id: 'AC094212.1'},
{value: 'ac094217.1', id: 'AC094217.1'},
{value: 'ac094217.2', id: 'AC094217.2'},
{value: 'ac094221.1', id: 'AC094221.1'},
{value: 'ac094221.3', id: 'AC094221.3'},
{value: 'ac094221.4', id: 'AC094221.4'},
{value: 'ac094236.1', id: 'AC094236.1'},
{value: 'ac094241.1', id: 'AC094241.1'},
{value: 'ac094246.2', id: 'AC094246.2'},
{value: 'ac094253.1', id: 'AC094253.1'},
{value: 'ac094348.2', id: 'AC094348.2'},
{value: 'ac094348.3', id: 'AC094348.3'},
{value: 'ac094348.4', id: 'AC094348.4'},
{value: 'ac094348.6', id: 'AC094348.6'},
{value: 'ac094516.1', id: 'AC094516.1'},
{value: 'ac094527.1', id: 'AC094527.1'},
{value: 'ac094636.1', id: 'AC094636.1'},
{value: 'ac094643.2', id: 'AC094643.2'},
{value: 'ac094643.3', id: 'AC094643.3'},
{value: 'ac094647.1', id: 'AC094647.1'},
{value: 'ac094647.2', id: 'AC094647.2'},
{value: 'ac094738.1', id: 'AC094738.1'},
{value: 'ac094784.1', id: 'AC094784.1'},
{value: 'ac094784.2', id: 'AC094784.2'},
{value: 'ac094811.1', id: 'AC094811.1'},
{value: 'ac095078.1', id: 'AC095078.1'},
{value: 'ac095078.2', id: 'AC095078.2'},
{value: 'ac095267.1', id: 'AC095267.1'},
{value: 'ac095267.2', id: 'AC095267.2'},
{value: 'ac095278.1', id: 'AC095278.1'},
{value: 'ac095289.1', id: 'AC095289.1'},
{value: 'ac095390.2', id: 'AC095390.2'},
{value: 'ac095443.1', id: 'AC095443.1'},
{value: 'ac095563.1', id: 'AC095563.1'},
{value: 'ac095563.2', id: 'AC095563.2'},
{value: 'ac095678.1', id: 'AC095678.1'},
{value: 'ac095678.2', id: 'AC095678.2'},
{value: 'ac095693.1', id: 'AC095693.1'},
{value: 'ac095695.1', id: 'AC095695.1'},
{value: 'ac095825.1', id: 'AC095825.1'},
{value: 'ac095845.1', id: 'AC095845.1'},
{value: 'ac095852.1', id: 'AC095852.1'},
{value: 'ac095852.2', id: 'AC095852.2'},
{value: 'ac095937.1', id: 'AC095937.1'},
{value: 'ac095947.1', id: 'AC095947.1'},
{value: 'ac095947.3', id: 'AC095947.3'},
{value: 'ac095947.4', id: 'AC095947.4'},
{value: 'ac095985.1', id: 'AC095985.1'},
{value: 'ac096003.1', id: 'AC096003.1'},
{value: 'ac096024.1', id: 'AC096024.1'},
{value: 'ac096030.1', id: 'AC096030.1'},
{value: 'ac096030.2', id: 'AC096030.2'},
{value: 'ac096030.3', id: 'AC096030.3'},
{value: 'ac096035.1', id: 'AC096035.1'},
{value: 'ac096051.2', id: 'AC096051.2'},
{value: 'ac096072.1', id: 'AC096072.1'},
{value: 'ac096201.1', id: 'AC096201.1'},
{value: 'ac096226.1', id: 'AC096226.1'},
{value: 'ac096239.1', id: 'AC096239.1'},
{value: 'ac096239.2', id: 'AC096239.2'},
{value: 'ac096239.3', id: 'AC096239.3'},
{value: 'ac096301.1', id: 'AC096301.1'},
{value: 'ac096311.1', id: 'AC096311.1'},
{value: 'ac096317.1', id: 'AC096317.1'},
{value: 'ac096317.2', id: 'AC096317.2'},
{value: 'ac096317.3', id: 'AC096317.3'},
{value: 'ac096317.4', id: 'AC096317.4'},
{value: 'ac096317.5', id: 'AC096317.5'},
{value: 'ac096330.1', id: 'AC096330.1'},
{value: 'ac096353.1', id: 'AC096353.1'},
{value: 'ac096370.1', id: 'AC096370.1'},
{value: 'ac096404.1', id: 'AC096404.1'},
{value: 'ac096430.1', id: 'AC096430.1'},
{value: 'ac096430.2', id: 'AC096430.2'},
{value: 'ac096454.1', id: 'AC096454.1'},
{value: 'ac096468.1', id: 'AC096468.1'},
{value: 'ac096473.3', id: 'AC096473.3'},
{value: 'ac096600.1', id: 'AC096600.1'},
{value: 'ac096601.1', id: 'AC096601.1'},
{value: 'ac096601.2', id: 'AC096601.2'},
{value: 'ac096792.1', id: 'AC096792.1'},
{value: 'ac096792.2', id: 'AC096792.2'},
{value: 'ac096804.1', id: 'AC096804.1'},
{value: 'ac096809.1', id: 'AC096809.1'},
{value: 'ac096895.1', id: 'AC096895.1'},
{value: 'ac096895.2', id: 'AC096895.2'},
{value: 'ac096938.1', id: 'AC096938.1'},
{value: 'ac097023.1', id: 'AC097023.1'},
{value: 'ac097037.1', id: 'AC097037.1'},
{value: 'ac097037.2', id: 'AC097037.2'},
{value: 'ac097037.3', id: 'AC097037.3'},
{value: 'ac097038.1', id: 'AC097038.1'},
{value: 'ac097129.1', id: 'AC097129.1'},
{value: 'ac097153.1', id: 'AC097153.1'},
{value: 'ac097153.2', id: 'AC097153.2'},
{value: 'ac097183.1', id: 'AC097183.1'},
{value: 'ac097183.2', id: 'AC097183.2'},
{value: 'ac097312.1', id: 'AC097312.1'},
{value: 'ac097575.1', id: 'AC097575.1'},
{value: 'ac097575.2', id: 'AC097575.2'},
{value: 'ac097752.1', id: 'AC097752.1'},
{value: 'ac097762.1', id: 'AC097762.1'},
{value: 'ac097784.1', id: 'AC097784.1'},
{value: 'ac097791.1', id: 'AC097791.1'},
{value: 'ac097791.2', id: 'AC097791.2'},
{value: 'ac097791.3', id: 'AC097791.3'},
{value: 'ac097845.1', id: 'AC097845.1'},
{value: 'ac097845.2', id: 'AC097845.2'},
{value: 'ac097890.1', id: 'AC097890.1'},
{value: 'ac097890.2', id: 'AC097890.2'},
{value: 'ac097890.3', id: 'AC097890.3'},
{value: 'ac097901.1', id: 'AC097901.1'},
{value: 'ac097939.1', id: 'AC097939.1'},
{value: 'ac097940.1', id: 'AC097940.1'},
{value: 'ac098008.1', id: 'AC098008.1'},
{value: 'ac098008.2', id: 'AC098008.2'},
{value: 'ac098008.3', id: 'AC098008.3'},
{value: 'ac098008.5', id: 'AC098008.5'},
{value: 'ac098022.1', id: 'AC098022.1'},
{value: 'ac098022.2', id: 'AC098022.2'},
{value: 'ac098064.1', id: 'AC098064.1'},
{value: 'ac098125.1', id: 'AC098125.1'},
{value: 'ac098125.2', id: 'AC098125.2'},
{value: 'ac098125.3', id: 'AC098125.3'},
{value: 'ac098157.1', id: 'AC098157.1'},
{value: 'ac098160.1', id: 'AC098160.1'},
{value: 'ac098180.1', id: 'AC098180.1'},
{value: 'ac098180.2', id: 'AC098180.2'},
{value: 'ac098189.1', id: 'AC098189.1'},
{value: 'ac098190.1', id: 'AC098190.1'},
{value: 'ac098284.1', id: 'AC098284.1'},
{value: 'ac098423.1', id: 'AC098423.1'},
{value: 'ac098459.1', id: 'AC098459.1'},
{value: 'ac098459.2', id: 'AC098459.2'},
{value: 'ac098459.3', id: 'AC098459.3'},
{value: 'ac098462.1', id: 'AC098462.1'},
{value: 'ac098547.2', id: 'AC098547.2'},
{value: 'ac098563.1', id: 'AC098563.1'},
{value: 'ac098622.1', id: 'AC098622.1'},
{value: 'ac098622.2', id: 'AC098622.2'},
{value: 'ac098750.1', id: 'AC098750.1'},
{value: 'ac098750.2', id: 'AC098750.2'},
{value: 'ac098750.3', id: 'AC098750.3'},
{value: 'ac098763.1', id: 'AC098763.1'},
{value: 'ac098929.1', id: 'AC098929.1'},
{value: 'ac098942.1', id: 'AC098942.1'},
{value: 'ac098959.2', id: 'AC098959.2'},
{value: 'ac098981.1', id: 'AC098981.1'},
{value: 'ac099089.1', id: 'AC099089.1'},
{value: 'ac099104.1', id: 'AC099104.1'},
{value: 'ac099108.1', id: 'AC099108.1'},
{value: 'ac099137.1', id: 'AC099137.1'},
{value: 'ac099137.2', id: 'AC099137.2'},
{value: 'ac099150.1', id: 'AC099150.1'},
{value: 'ac099183.2', id: 'AC099183.2'},
{value: 'ac099183.4', id: 'AC099183.4'},
{value: 'ac099288.1', id: 'AC099288.1'},
{value: 'ac099294.1', id: 'AC099294.1'},
{value: 'ac099302.1', id: 'AC099302.1'},
{value: 'ac099304.1', id: 'AC099304.1'},
{value: 'ac099360.1', id: 'AC099360.1'},
{value: 'ac099384.2', id: 'AC099384.2'},
{value: 'ac099444.1', id: 'AC099444.1'},
{value: 'ac099444.3', id: 'AC099444.3'},
{value: 'ac099449.1', id: 'AC099449.1'},
{value: 'ac099450.1', id: 'AC099450.1'},
{value: 'ac099453.1', id: 'AC099453.1'},
{value: 'ac099453.2', id: 'AC099453.2'},
{value: 'ac102976.1', id: 'AC102976.1'},
{value: 'ac103012.1', id: 'AC103012.1'},
{value: 'ac103018.1', id: 'AC103018.1'},
{value: 'ac103024.1', id: 'AC103024.1'},
{value: 'ac103024.2', id: 'AC103024.2'},
{value: 'ac103056.1', id: 'AC103056.1'},
{value: 'ac103090.1', id: 'AC103090.1'},
{value: 'ac103101.1', id: 'AC103101.1'},
{value: 'ac103129.1', id: 'AC103129.1'},
{value: 'ac103129.2', id: 'AC103129.2'},
{value: 'ac103129.3', id: 'AC103129.3'},
{value: 'ac103129.5', id: 'AC103129.5'},
{value: 'ac103179.1', id: 'AC103179.1'},
{value: 'ac103179.2', id: 'AC103179.2'},
{value: 'ac103221.1', id: 'AC103221.1'},
{value: 'ac103270.1', id: 'AC103270.1'},
{value: 'ac103292.1', id: 'AC103292.1'},
{value: 'ac103335.1', id: 'AC103335.1'},
{value: 'ac103453.1', id: 'AC103453.1'},
{value: 'ac103535.1', id: 'AC103535.1'},
{value: 'ac103535.2', id: 'AC103535.2'},
{value: 'ac103574.1', id: 'AC103574.1'},
{value: 'ac104053.1', id: 'AC104053.1'},
{value: 'ac104314.1', id: 'AC104314.1'},
{value: 'ac105485.1', id: 'AC105485.1'},
{value: 'ac105485.2', id: 'AC105485.2'},
{value: 'ac105515.1', id: 'AC105515.1'},
{value: 'ac105531.1', id: 'AC105531.1'},
{value: 'ac105577.1', id: 'AC105577.1'},
{value: 'ac105604.1', id: 'AC105604.1'},
{value: 'ac105604.2', id: 'AC105604.2'},
{value: 'ac105624.1', id: 'AC105624.1'},
{value: 'ac105628.1', id: 'AC105628.1'},
{value: 'ac105631.1', id: 'AC105631.1'},
{value: 'ac105645.1', id: 'AC105645.1'},
{value: 'ac105645.3', id: 'AC105645.3'},
{value: 'ac105645.5', id: 'AC105645.5'},
{value: 'ac105648.3', id: 'AC105648.3'},
{value: 'ac105648.5', id: 'AC105648.5'},
{value: 'ac105662.1', id: 'AC105662.1'},
{value: 'ac105662.2', id: 'AC105662.2'},
{value: 'ac105662.3', id: 'AC105662.3'},
{value: 'ac105804.1', id: 'AC105804.1'},
{value: 'ac105815.1', id: 'AC105815.1'},
{value: 'ac105830.1', id: 'AC105830.1'},
{value: 'ac106191.1', id: 'AC106191.1'},
{value: 'ac106225.1', id: 'AC106225.1'},
{value: 'ac106292.1', id: 'AC106292.1'},
{value: 'ac106292.2', id: 'AC106292.2'},
{value: 'ac106438.1', id: 'AC106438.1'},
{value: 'ac106505.1', id: 'AC106505.1'},
{value: 'ac106605.1', id: 'AC106605.1'},
{value: 'ac106606.1', id: 'AC106606.1'},
{value: 'ac106648.1', id: 'AC106648.1'},
{value: 'ac106663.1', id: 'AC106663.1'},
{value: 'ac106663.3', id: 'AC106663.3'},
{value: 'ac106663.4', id: 'AC106663.4'},
{value: 'ac106681.1', id: 'AC106681.1'},
{value: 'ac106702.1', id: 'AC106702.1'},
{value: 'ac106909.1', id: 'AC106909.1'},
{value: 'ac106933.1', id: 'AC106933.1'},
{value: 'ac106933.2', id: 'AC106933.2'},
{value: 'ac106947.1', id: 'AC106947.1'},
{value: 'ac106947.2', id: 'AC106947.2'},
{value: 'ac107088.1', id: 'AC107088.1'},
{value: 'ac107096.1', id: 'AC107096.1'},
{value: 'ac107096.2', id: 'AC107096.2'},
{value: 'ac107153.1', id: 'AC107153.1'},
{value: 'ac107153.2', id: 'AC107153.2'},
{value: 'ac107174.1', id: 'AC107174.1'},
{value: 'ac107174.2', id: 'AC107174.2'},
{value: 'ac107190.1', id: 'AC107190.1'},
{value: 'ac107267.1', id: 'AC107267.1'},
{value: 'ac107280.1', id: 'AC107280.1'},
{value: 'ac107331.1', id: 'AC107331.1'},
{value: 'ac107331.2', id: 'AC107331.2'},
{value: 'ac107446.1', id: 'AC107446.1'},
{value: 'ac107446.2', id: 'AC107446.2'},
{value: 'ac107446.3', id: 'AC107446.3'},
{value: 'ac107447.1', id: 'AC107447.1'},
{value: 'ac107505.1', id: 'AC107505.1'},
{value: 'ac107505.2', id: 'AC107505.2'},
{value: 'ac107527.1', id: 'AC107527.1'},
{value: 'ac107531.1', id: 'AC107531.1'},
{value: 'ac107531.2', id: 'AC107531.2'},
{value: 'ac107531.3', id: 'AC107531.3'},
{value: 'ac107580.1', id: 'AC107580.1'},
{value: 'ac107597.1', id: 'AC107597.1'},
{value: 'ac107597.2', id: 'AC107597.2'},
{value: 'ac108261.1', id: 'AC108261.1'},
{value: 'ac108288.1', id: 'AC108288.1'},
{value: 'ac108312.1', id: 'AC108312.1'},
{value: 'ac108312.2', id: 'AC108312.2'},
{value: 'ac108351.1', id: 'AC108351.1'},
{value: 'ac108351.2', id: 'AC108351.2'},
{value: 'ac108351.3', id: 'AC108351.3'},
{value: 'ac108351.4', id: 'AC108351.4'},
{value: 'ac108546.1', id: 'AC108546.1'},
{value: 'ac108572.2', id: 'AC108572.2'},
{value: 'ac108572.3', id: 'AC108572.3'},
{value: 'ac108572.4', id: 'AC108572.4'},
{value: 'ac108572.6', id: 'AC108572.6'},
{value: 'ac108574.1', id: 'AC108574.1'},
{value: 'ac108578.1', id: 'AC108578.1'},
{value: 'ac108583.2', id: 'AC108583.2'},
{value: 'ac108588.1', id: 'AC108588.1'},
{value: 'ac108595.1', id: 'AC108595.1'},
{value: 'ac108616.1', id: 'AC108616.1'},
{value: 'ac108616.2', id: 'AC108616.2'},
{value: 'ac108631.1', id: 'AC108631.1'},
{value: 'ac108631.2', id: 'AC108631.2'},
{value: 'ac108635.1', id: 'AC108635.1'},
{value: 'ac109023.1', id: 'AC109023.1'},
{value: 'ac109037.1', id: 'AC109037.1'},
{value: 'ac109048.1', id: 'AC109048.1'},
{value: 'ac109096.1', id: 'AC109096.1'},
{value: 'ac109100.1', id: 'AC109100.1'},
{value: 'ac109391.1', id: 'AC109391.1'},
{value: 'ac109427.1', id: 'AC109427.1'},
{value: 'ac109542.1', id: 'AC109542.1'},
{value: 'ac109542.3', id: 'AC109542.3'},
{value: 'ac109542.4', id: 'AC109542.4'},
{value: 'ac109660.1', id: 'AC109660.1'},
{value: 'ac109707.1', id: 'AC109707.1'},
{value: 'ac109737.1', id: 'AC109737.1'},
{value: 'ac109741.1', id: 'AC109741.1'},
{value: 'ac109837.1', id: 'AC109837.1'},
{value: 'ac109844.1', id: 'AC109844.1'},
{value: 'ac109844.2', id: 'AC109844.2'},
{value: 'ac109877.1', id: 'AC109877.1'},
{value: 'ac109886.1', id: 'AC109886.1'},
{value: 'ac109891.1', id: 'AC109891.1'},
{value: 'ac109901.1', id: 'AC109901.1'},
{value: 'ac109901.2', id: 'AC109901.2'},
{value: 'ac109942.1', id: 'AC109942.1'},
{value: 'ac109942.2', id: 'AC109942.2'},
{value: 'ac110102.1', id: 'AC110102.1'},
{value: 'ac110102.2', id: 'AC110102.2'},
{value: 'ac110306.2', id: 'AC110306.2'},
{value: 'ac110306.3', id: 'AC110306.3'},
{value: 'ac110315.1', id: 'AC110315.1'},
{value: 'ac110351.1', id: 'AC110351.1'},
{value: 'ac110351.2', id: 'AC110351.2'},
{value: 'ac110367.1', id: 'AC110367.1'},
{value: 'ac110403.1', id: 'AC110403.1'},
{value: 'ac110474.1', id: 'AC110474.1'},
{value: 'ac110488.1', id: 'AC110488.1'},
{value: 'ac110682.1', id: 'AC110682.1'},
{value: 'ac110709.1', id: 'AC110709.1'},
{value: 'ac110709.2', id: 'AC110709.2'},
{value: 'ac110837.1', id: 'AC110837.1'},
{value: 'ac110837.2', id: 'AC110837.2'},
{value: 'ac110846.1', id: 'AC110846.1'},
{value: 'ac110851.1', id: 'AC110851.1'},
{value: 'ac110862.1', id: 'AC110862.1'},
{value: 'ac110981.1', id: 'AC110981.1'},
{value: 'ac110981.3', id: 'AC110981.3'},
{value: 'ac111231.1', id: 'AC111231.1'},
{value: 'ac111231.2', id: 'AC111231.2'},
{value: 'ac111231.3', id: 'AC111231.3'},
{value: 'ac111257.1', id: 'AC111257.1'},
{value: 'ac111287.1', id: 'AC111287.1'},
{value: 'ac111292.1', id: 'AC111292.1'},
{value: 'ac111292.3', id: 'AC111292.3'},
{value: 'ac111292.4', id: 'AC111292.4'},
{value: 'ac111292.6', id: 'AC111292.6'},
{value: 'ac111292.7', id: 'AC111292.7'},
{value: 'ac111315.1', id: 'AC111315.1'},
{value: 'ac111319.1', id: 'AC111319.1'},
{value: 'ac111319.2', id: 'AC111319.2'},
{value: 'ac111319.3', id: 'AC111319.3'},
{value: 'ac111369.1', id: 'AC111369.1'},
{value: 'ac111413.1', id: 'AC111413.1'},
{value: 'ac111421.1', id: 'AC111421.1'},
{value: 'ac111428.1', id: 'AC111428.1'},
{value: 'ac111428.2', id: 'AC111428.2'},
{value: 'ac111575.1', id: 'AC111575.1'},
{value: 'ac111632.1', id: 'AC111632.1'},
{value: 'ac111632.2', id: 'AC111632.2'},
{value: 'ac111647.1', id: 'AC111647.1'},
{value: 'ac111653.1', id: 'AC111653.1'},
{value: 'ac111678.1', id: 'AC111678.1'},
{value: 'ac111678.2', id: 'AC111678.2'},
{value: 'ac111687.1', id: 'AC111687.1'},
{value: 'ac111734.1', id: 'AC111734.1'},
{value: 'ac111734.2', id: 'AC111734.2'},
{value: 'ac111781.1', id: 'AC111781.1'},
{value: 'ac111804.2', id: 'AC111804.2'},
{value: 'ac111831.1', id: 'AC111831.1'},
{value: 'ac111831.2', id: 'AC111831.2'},
{value: 'ac111838.1', id: 'AC111838.1'},
{value: 'ac111847.1', id: 'AC111847.1'},
{value: 'ac111867.1', id: 'AC111867.1'},
{value: 'ac111867.2', id: 'AC111867.2'},
{value: 'ac111885.1', id: 'AC111885.1'},
{value: 'ac111927.1', id: 'AC111927.1'},
{value: 'ac111943.1', id: 'AC111943.1'},
{value: 'ac111943.2', id: 'AC111943.2'},
{value: 'ac112001.1', id: 'AC112001.1'},
{value: 'ac112030.1', id: 'AC112030.1'},
{value: 'ac112328.1', id: 'AC112328.1'},
{value: 'ac112350.1', id: 'AC112350.1'},
{value: 'ac112355.1', id: 'AC112355.1'},
{value: 'ac112445.1', id: 'AC112445.1'},
{value: 'ac112531.1', id: 'AC112531.1'},
{value: 'ac112531.2', id: 'AC112531.2'},
{value: 'ac112534.1', id: 'AC112534.1'},
{value: 'ac112534.2', id: 'AC112534.2'},
{value: 'ac112534.3', id: 'AC112534.3'},
{value: 'ac112554.1', id: 'AC112554.1'},
{value: 'ac112557.1', id: 'AC112557.1'},
{value: 'ac112568.1', id: 'AC112568.1'},
{value: 'ac112568.2', id: 'AC112568.2'},
{value: 'ac112568.3', id: 'AC112568.3'},
{value: 'ac112568.4', id: 'AC112568.4'},
{value: 'ac112568.5', id: 'AC112568.5'},
{value: 'ac112568.6', id: 'AC112568.6'},
{value: 'ac112624.1', id: 'AC112624.1'},
{value: 'ac112739.1', id: 'AC112739.1'},
{value: 'ac112866.1', id: 'AC112866.1'},
{value: 'ac113621.2', id: 'AC113621.2'},
{value: 'ac113635.1', id: 'AC113635.1'},
{value: 'ac113635.2', id: 'AC113635.2'},
{value: 'ac113710.1', id: 'AC113710.1'},
{value: 'ac113710.3', id: 'AC113710.3'},
{value: 'ac113710.5', id: 'AC113710.5'},
{value: 'ac113756.3', id: 'AC113756.3'},
{value: 'ac113771.1', id: 'AC113771.1'},
{value: 'ac113773.1', id: 'AC113773.1'},
{value: 'ac113773.2', id: 'AC113773.2'},
{value: 'ac113785.1', id: 'AC113785.1'},
{value: 'ac113785.2', id: 'AC113785.2'},
{value: 'ac113785.3', id: 'AC113785.3'},
{value: 'ac113785.4', id: 'AC113785.4'},
{value: 'ac113785.5', id: 'AC113785.5'},
{value: 'ac113837.1', id: 'AC113837.1'},
{value: 'ac113837.9', id: 'AC113837.9'},
{value: 'ac113858.1', id: 'AC113858.1'},
{value: 'ac113858.2', id: 'AC113858.2'},
{value: 'ac113901.1', id: 'AC113901.1'},
{value: 'ac113910.1', id: 'AC113910.1'},
{value: 'ac113910.2', id: 'AC113910.2'},
{value: 'ac113925.1', id: 'AC113925.1'},
{value: 'ac114017.1', id: 'AC114017.1'},
{value: 'ac114070.1', id: 'AC114070.1'},
{value: 'ac114070.2', id: 'AC114070.2'},
{value: 'ac114093.1', id: 'AC114093.1'},
{value: 'ac114096.2', id: 'AC114096.2'},
{value: 'ac114111.1', id: 'AC114111.1'},
{value: 'ac114117.1', id: 'AC114117.1'},
{value: 'ac114198.1', id: 'AC114198.1'},
{value: 'ac114204.1', id: 'AC114204.1'},
{value: 'ac114233.1', id: 'AC114233.1'},
{value: 'ac114233.2', id: 'AC114233.2'},
{value: 'ac114252.1', id: 'AC114252.1'},
{value: 'ac114363.1', id: 'AC114363.1'},
{value: 'ac114389.1', id: 'AC114389.1'},
{value: 'ac114389.2', id: 'AC114389.2'},
{value: 'ac114391.1', id: 'AC114391.1'},
{value: 'ac114446.1', id: 'AC114446.1'},
{value: 'ac114452.1', id: 'AC114452.1'},
{value: 'ac114452.3', id: 'AC114452.3'},
{value: 'ac114460.1', id: 'AC114460.1'},
{value: 'ac114460.2', id: 'AC114460.2'},
{value: 'ac114502.1', id: 'AC114502.1'},
{value: 'ac114696.1', id: 'AC114696.1'},
{value: 'ac114845.1', id: 'AC114845.1'},
{value: 'ac114845.2', id: 'AC114845.2'},
{value: 'ac114866.1', id: 'AC114866.1'},
{value: 'ac115159.1', id: 'AC115159.1'},
{value: 'ac115159.2', id: 'AC115159.2'},
{value: 'ac115181.1', id: 'AC115181.1'},
{value: 'ac115202.2', id: 'AC115202.2'},
{value: 'ac115202.3', id: 'AC115202.3'},
{value: 'ac115214.1', id: 'AC115214.1'},
{value: 'ac115255.1', id: 'AC115255.1'},
{value: 'ac115255.2', id: 'AC115255.2'},
{value: 'ac115277.1', id: 'AC115277.1'},
{value: 'ac115277.2', id: 'AC115277.2'},
{value: 'ac115322.1', id: 'AC115322.1'},
{value: 'ac115369.1', id: 'AC115369.1'},
{value: 'ac115371.1', id: 'AC115371.1'},
{value: 'ac115371.2', id: 'AC115371.2'},
{value: 'ac115371.3', id: 'AC115371.3'},
{value: 'ac115378.1', id: 'AC115378.1'},
{value: 'ac115384.2', id: 'AC115384.2'},
{value: 'ac115401.1', id: 'AC115401.1'},
{value: 'ac115420.1', id: 'AC115420.1'},
{value: 'ac115420.2', id: 'AC115420.2'},
{value: 'ac115420.3', id: 'AC115420.3'},
{value: 'ac115420.4', id: 'AC115420.4'},
{value: 'ac115509.1', id: 'AC115509.1'},
{value: 'ac115670.1', id: 'AC115670.1'},
{value: 'ac115670.2', id: 'AC115670.2'},
{value: 'ac116071.1', id: 'AC116071.1'},
{value: 'ac116183.1', id: 'AC116183.1'},
{value: 'ac116220.2', id: 'AC116220.2'},
{value: 'ac116220.3', id: 'AC116220.3'},
{value: 'ac116236.1', id: 'AC116236.1'},
{value: 'ac116236.2', id: 'AC116236.2'},
{value: 'ac116283.1', id: 'AC116283.1'},
{value: 'ac116288.1', id: 'AC116288.1'},
{value: 'ac117058.1', id: 'AC117058.1'},
{value: 'ac117065.1', id: 'AC117065.1'},
{value: 'ac117065.2', id: 'AC117065.2'},
{value: 'ac117091.1', id: 'AC117091.1'},
{value: 'ac117101.1', id: 'AC117101.1'},
{value: 'ac117101.2', id: 'AC117101.2'},
{value: 'ac117118.1', id: 'AC117118.1'},
{value: 'ac117122.1', id: 'AC117122.1'},
{value: 'ac117299.1', id: 'AC117299.1'},
{value: 'ac117330.1', id: 'AC117330.1'},
{value: 'ac117869.1', id: 'AC117869.1'},
{value: 'ac117889.1', id: 'AC117889.1'},
{value: 'ac117889.2', id: 'AC117889.2'},
{value: 'ac117905.2', id: 'AC117905.2'},
{value: 'ac117919.1', id: 'AC117919.1'},
{value: 'ac117925.2', id: 'AC117925.2'},
{value: 'ac117971.1', id: 'AC117971.1'},
{value: 'ac117971.2', id: 'AC117971.2'},
{value: 'ac118069.1', id: 'AC118069.1'},
{value: 'ac118094.1', id: 'AC118094.1'},
{value: 'ac118115.1', id: 'AC118115.1'},
{value: 'ac118127.1', id: 'AC118127.1'},
{value: 'ac118165.1', id: 'AC118165.1'},
{value: 'ac118350.1', id: 'AC118350.1'},
{value: 'ac118414.2', id: 'AC118414.2'},
{value: 'ac118419.1', id: 'AC118419.1'},
{value: 'ac118419.2', id: 'AC118419.2'},
{value: 'ac118427.1', id: 'AC118427.1'},
{value: 'ac118434.1', id: 'AC118434.1'},
{value: 'ac118434.2', id: 'AC118434.2'},
{value: 'ac118434.3', id: 'AC118434.3'},
{value: 'ac118490.1', id: 'AC118490.1'},
{value: 'ac118496.1', id: 'AC118496.1'},
{value: 'ac118772.1', id: 'AC118772.1'},
{value: 'ac118772.2', id: 'AC118772.2'},
{value: 'ac118772.3', id: 'AC118772.3'},
{value: 'ac118802.1', id: 'AC118802.1'},
{value: 'ac118806.1', id: 'AC118806.1'},
{value: 'ac118833.1', id: 'AC118833.1'},
{value: 'ac118841.1', id: 'AC118841.1'},
{value: 'ac118858.1', id: 'AC118858.1'},
{value: 'ac118858.2', id: 'AC118858.2'},
{value: 'ac118957.1', id: 'AC118957.1'},
{value: 'ac118963.1', id: 'AC118963.1'},
{value: 'ac118963.2', id: 'AC118963.2'},
{value: 'ac119000.1', id: 'AC119000.1'},
{value: 'ac119007.1', id: 'AC119007.1'},
{value: 'ac119007.2', id: 'AC119007.2'},
{value: 'ac119007.3', id: 'AC119007.3'},
{value: 'ac119007.4', id: 'AC119007.4'},
{value: 'ac119007.5', id: 'AC119007.5'},
{value: 'ac119015.1', id: 'AC119015.1'},
{value: 'ac119015.2', id: 'AC119015.2'},
{value: 'ac119015.3', id: 'AC119015.3'},
{value: 'ac119015.4', id: 'AC119015.4'},
{value: 'ac119111.1', id: 'AC119111.1'},
{value: 'ac119116.1', id: 'AC119116.1'},
{value: 'ac119318.1', id: 'AC119318.1'},
{value: 'ac119332.1', id: 'AC119332.1'},
{value: 'ac119336.1', id: 'AC119336.1'},
{value: 'ac119356.1', id: 'AC119356.1'},
{value: 'ac119356.2', id: 'AC119356.2'},
{value: 'ac119459.2', id: 'AC119459.2'},
{value: 'ac119462.1', id: 'AC119462.1'},
{value: 'ac119496.1', id: 'AC119496.1'},
{value: 'ac119556.1', id: 'AC119556.1'},
{value: 'ac119603.1', id: 'AC119603.1'},
{value: 'ac119635.1', id: 'AC119635.1'},
{value: 'ac119635.2', id: 'AC119635.2'},
{value: 'ac119691.1', id: 'AC119691.1'},
{value: 'ac119699.1', id: 'AC119699.1'},
{value: 'ac119699.2', id: 'AC119699.2'},
{value: 'ac119762.1', id: 'AC119762.1'},
{value: 'ac119762.3', id: 'AC119762.3'},
{value: 'ac119762.6', id: 'AC119762.6'},
{value: 'ac119762.7', id: 'AC119762.7'},
{value: 'ac119762.8', id: 'AC119762.8'},
{value: 'ac120066.1', id: 'AC120066.1'},
{value: 'ac120071.1', id: 'AC120071.1'},
{value: 'ac120076.1', id: 'AC120076.1'},
{value: 'ac120096.3', id: 'AC120096.3'},
{value: 'ac120237.1', id: 'AC120237.1'},
{value: 'ac120241.1', id: 'AC120241.1'},
{value: 'ac120246.2', id: 'AC120246.2'},
{value: 'ac120262.1', id: 'AC120262.1'},
{value: 'ac120277.1', id: 'AC120277.1'},
{value: 'ac120291.1', id: 'AC120291.1'},
{value: 'ac120291.2', id: 'AC120291.2'},
{value: 'ac120291.3', id: 'AC120291.3'},
{value: 'ac120291.4', id: 'AC120291.4'},
{value: 'ac120310.1', id: 'AC120310.1'},
{value: 'ac120310.2', id: 'AC120310.2'},
{value: 'ac120322.1', id: 'AC120322.1'},
{value: 'ac120448.1', id: 'AC120448.1'},
{value: 'ac120448.2', id: 'AC120448.2'},
{value: 'ac120486.1', id: 'AC120486.1'},
{value: 'ac120486.10', id: 'AC120486.10'},
{value: 'ac120486.11', id: 'AC120486.11'},
{value: 'ac120486.12', id: 'AC120486.12'},
{value: 'ac120486.3', id: 'AC120486.3'},
{value: 'ac120486.4', id: 'AC120486.4'},
{value: 'ac120486.5', id: 'AC120486.5'},
{value: 'ac120486.6', id: 'AC120486.6'},
{value: 'ac120486.7', id: 'AC120486.7'},
{value: 'ac120486.8', id: 'AC120486.8'},
{value: 'ac120486.9', id: 'AC120486.9'},
{value: 'ac120568.1', id: 'AC120568.1'},
{value: 'ac120594.1', id: 'AC120594.1'},
{value: 'ac120712.3', id: 'AC120712.3'},
{value: 'ac120718.1', id: 'AC120718.1'},
{value: 'ac120721.1', id: 'AC120721.1'},
{value: 'ac120732.1', id: 'AC120732.1'},
{value: 'ac120742.1', id: 'AC120742.1'},
{value: 'ac120750.1', id: 'AC120750.1'},
{value: 'ac120807.1', id: 'AC120807.1'},
{value: 'ac120922.1', id: 'AC120922.1'},
{value: 'ac120938.1', id: 'AC120938.1'},
{value: 'ac121031.1', id: 'AC121031.1'},
{value: 'ac121173.1', id: 'AC121173.1'},
{value: 'ac121203.1', id: 'AC121203.1'},
{value: 'ac121203.2', id: 'AC121203.2'},
{value: 'ac121203.3', id: 'AC121203.3'},
{value: 'ac121204.2', id: 'AC121204.2'},
{value: 'ac121204.3', id: 'AC121204.3'},
{value: 'ac121220.1', id: 'AC121220.1'},
{value: 'ac121413.2', id: 'AC121413.2'},
{value: 'ac121415.2', id: 'AC121415.2'},
{value: 'ac121443.1', id: 'AC121443.1'},
{value: 'ac121465.2', id: 'AC121465.2'},
{value: 'ac121639.1', id: 'AC121639.1'},
{value: 'ac121663.1', id: 'AC121663.1'},
{value: 'ac121719.1', id: 'AC121719.1'},
{value: 'ac121740.1', id: 'AC121740.1'},
{value: 'ac122568.1', id: 'AC122568.1'},
{value: 'ac122603.1', id: 'AC122603.1'},
{value: 'ac122603.2', id: 'AC122603.2'},
{value: 'ac122625.1', id: 'AC122625.1'},
{value: 'ac122630.1', id: 'AC122630.1'},
{value: 'ac122631.1', id: 'AC122631.1'},
{value: 'ac122968.1', id: 'AC122968.1'},
{value: 'ac122990.1', id: 'AC122990.1'},
{value: 'ac122999.1', id: 'AC122999.1'},
{value: 'ac123018.1', id: 'AC123018.1'},
{value: 'ac123083.1', id: 'AC123083.1'},
{value: 'ac123083.2', id: 'AC123083.2'},
{value: 'ac123095.1', id: 'AC123095.1'},
{value: 'ac123095.2', id: 'AC123095.2'},
{value: 'ac123185.1', id: 'AC123185.1'},
{value: 'ac123185.2', id: 'AC123185.2'},
{value: 'ac123185.3', id: 'AC123185.3'},
{value: 'ac123251.1', id: 'AC123251.1'},
{value: 'ac123253.1', id: 'AC123253.1'},
{value: 'ac123253.2', id: 'AC123253.2'},
{value: 'ac123293.1', id: 'AC123293.1'},
{value: 'ac123293.2', id: 'AC123293.2'},
{value: 'ac123316.3', id: 'AC123316.3'},
{value: 'ac123316.4', id: 'AC123316.4'},
{value: 'ac123346.1', id: 'AC123346.1'},
{value: 'ac123358.1', id: 'AC123358.1'},
{value: 'ac123425.1', id: 'AC123425.1'},
{value: 'ac123427.1', id: 'AC123427.1'},
{value: 'ac123494.1', id: 'AC123494.1'},
{value: 'ac123495.3', id: 'AC123495.3'},
{value: 'ac123500.1', id: 'AC123500.1'},
{value: 'ac124205.1', id: 'AC124205.1'},
{value: 'ac124205.2', id: 'AC124205.2'},
{value: 'ac124839.1', id: 'AC124839.1'},
{value: 'ac124874.1', id: 'AC124874.1'},
{value: 'ac124926.1', id: 'AC124926.1'},
{value: 'ac124926.2', id: 'AC124926.2'},
{value: 'ac125248.1', id: 'AC125248.1'},
{value: 'ac125384.1', id: 'AC125384.1'},
{value: 'ac125565.1', id: 'AC125565.1'},
{value: 'ac125620.1', id: 'AC125620.1'},
{value: 'ac125688.1', id: 'AC125688.1'},
{value: 'ac125757.1', id: 'AC125757.1'},
{value: 'ac125757.2', id: 'AC125757.2'},
{value: 'ac125757.3', id: 'AC125757.3'},
{value: 'ac125847.1', id: 'AC125847.1'},
{value: 'ac125873.1', id: 'AC125873.1'},
{value: 'ac125873.2', id: 'AC125873.2'},
{value: 'ac125920.1', id: 'AC125920.1'},
{value: 'ac125935.1', id: 'AC125935.1'},
{value: 'ac125982.1', id: 'AC125982.1'},
{value: 'ac125982.2', id: 'AC125982.2'},
{value: 'ac125991.1', id: 'AC125991.1'},
{value: 'ac126071.1', id: 'AC126071.1'},
{value: 'ac126071.2', id: 'AC126071.2'},
{value: 'ac126134.1', id: 'AC126134.1'},
{value: 'ac126148.1', id: 'AC126148.1'},
{value: 'ac126156.1', id: 'AC126156.1'},
{value: 'ac126292.2', id: 'AC126292.2'},
{value: 'ac126482.1', id: 'AC126482.1'},
{value: 'ac126482.2', id: 'AC126482.2'},
{value: 'ac126486.2', id: 'AC126486.2'},
{value: 'ac126486.4', id: 'AC126486.4'},
{value: 'ac126486.5', id: 'AC126486.5'},
{value: 'ac126530.2', id: 'AC126530.2'},
{value: 'ac126572.1', id: 'AC126572.1'},
{value: 'ac126572.3', id: 'AC126572.3'},
{value: 'ac126572.4', id: 'AC126572.4'},
{value: 'ac126572.5', id: 'AC126572.5'},
{value: 'ac126572.6', id: 'AC126572.6'},
{value: 'ac126581.1', id: 'AC126581.1'},
{value: 'ac126640.1', id: 'AC126640.1'},
{value: 'ac126701.1', id: 'AC126701.1'},
{value: 'ac126877.1', id: 'AC126877.1'},
{value: 'ac126897.1', id: 'AC126897.1'},
{value: 'ac126899.1', id: 'AC126899.1'},
{value: 'ac126957.1', id: 'AC126957.1'},
{value: 'ac126960.1', id: 'AC126960.1'},
{value: 'ac127076.1', id: 'AC127076.1'},
{value: 'ac127076.2', id: 'AC127076.2'},
{value: 'ac127084.5', id: 'AC127084.5'},
{value: 'ac127118.1', id: 'AC127118.1'},
{value: 'ac127140.1', id: 'AC127140.1'},
{value: 'ac127142.1', id: 'AC127142.1'},
{value: 'ac127189.1', id: 'AC127189.1'},
{value: 'ac127217.1', id: 'AC127217.1'},
{value: 'ac127217.2', id: 'AC127217.2'},
{value: 'ac127640.1', id: 'AC127640.1'},
{value: 'ac127756.1', id: 'AC127756.1'},
{value: 'ac127764.1', id: 'AC127764.1'},
{value: 'ac127784.1', id: 'AC127784.1'},
{value: 'ac127784.2', id: 'AC127784.2'},
{value: 'ac127784.5', id: 'AC127784.5'},
{value: 'ac127887.1', id: 'AC127887.1'},
{value: 'ac127919.1', id: 'AC127919.1'},
{value: 'ac127920.1', id: 'AC127920.1'},
{value: 'ac127935.1', id: 'AC127935.1'},
{value: 'ac127963.1', id: 'AC127963.1'},
{value: 'ac127963.2', id: 'AC127963.2'},
{value: 'ac128059.1', id: 'AC128059.1'},
{value: 'ac128059.2', id: 'AC128059.2'},
{value: 'ac128059.5', id: 'AC128059.5'},
{value: 'ac128084.1', id: 'AC128084.1'},
{value: 'ac128084.2', id: 'AC128084.2'},
{value: 'ac128114.1', id: 'AC128114.1'},
{value: 'ac128114.2', id: 'AC128114.2'},
{value: 'ac128154.1', id: 'AC128154.1'},
{value: 'ac128207.1', id: 'AC128207.1'},
{value: 'ac128207.2', id: 'AC128207.2'},
{value: 'ac128212.1', id: 'AC128212.1'},
{value: 'ac128261.1', id: 'AC128261.1'},
{value: 'ac128290.1', id: 'AC128290.1'},
{value: 'ac128293.1', id: 'AC128293.1'},
{value: 'ac128303.1', id: 'AC128303.1'},
{value: 'ac128303.2', id: 'AC128303.2'},
{value: 'ac128353.1', id: 'AC128353.1'},
{value: 'ac128394.1', id: 'AC128394.1'},
{value: 'ac128394.2', id: 'AC128394.2'},
{value: 'ac128394.3', id: 'AC128394.3'},
{value: 'ac128440.1', id: 'AC128440.1'},
{value: 'ac128476.1', id: 'AC128476.1'},
{value: 'ac128476.2', id: 'AC128476.2'},
{value: 'ac128582.1', id: 'AC128582.1'},
{value: 'ac128610.1', id: 'AC128610.1'},
{value: 'ac128637.1', id: 'AC128637.1'},
{value: 'ac128721.1', id: 'AC128721.1'},
{value: 'ac128759.1', id: 'AC128759.1'},
{value: 'ac128759.2', id: 'AC128759.2'},
{value: 'ac128786.1', id: 'AC128786.1'},
{value: 'ac128792.1', id: 'AC128792.1'},
{value: 'ac128792.2', id: 'AC128792.2'},
{value: 'ac128800.1', id: 'AC128800.1'},
{value: 'ac128836.1', id: 'AC128836.1'},
{value: 'ac128848.1', id: 'AC128848.1'},
{value: 'ac128859.1', id: 'AC128859.1'},
{value: 'ac128859.2', id: 'AC128859.2'},
{value: 'ac128859.3', id: 'AC128859.3'},
{value: 'ac128859.4', id: 'AC128859.4'},
{value: 'ac128859.5', id: 'AC128859.5'},
{value: 'ac128859.6', id: 'AC128859.6'},
{value: 'ac128900.1', id: 'AC128900.1'},
{value: 'ac128901.1', id: 'AC128901.1'},
{value: 'ac128901.2', id: 'AC128901.2'},
{value: 'ac128915.1', id: 'AC128915.1'},
{value: 'ac128917.1', id: 'AC128917.1'},
{value: 'ac128960.1', id: 'AC128960.1'},
{value: 'ac128960.2', id: 'AC128960.2'},
{value: 'ac128960.3', id: 'AC128960.3'},
{value: 'ac128962.1', id: 'AC128962.1'},
{value: 'ac128962.2', id: 'AC128962.2'},
{value: 'ac128967.1', id: 'AC128967.1'},
{value: 'ac128967.3', id: 'AC128967.3'},
{value: 'ac128986.1', id: 'AC128986.1'},
{value: 'ac129004.1', id: 'AC129004.1'},
{value: 'ac129004.2', id: 'AC129004.2'},
{value: 'ac129049.1', id: 'AC129049.1'},
{value: 'ac129049.2', id: 'AC129049.2'},
{value: 'ac129054.2', id: 'AC129054.2'},
{value: 'ac129101.1', id: 'AC129101.1'},
{value: 'ac129138.1', id: 'AC129138.1'},
{value: 'ac129357.1', id: 'AC129357.1'},
{value: 'ac129357.2', id: 'AC129357.2'},
{value: 'ac129365.1', id: 'AC129365.1'},
{value: 'ac129370.1', id: 'AC129370.1'},
{value: 'ac129370.2', id: 'AC129370.2'},
{value: 'ac129395.1', id: 'AC129395.1'},
{value: 'ac129395.2', id: 'AC129395.2'},
{value: 'ac129405.1', id: 'AC129405.1'},
{value: 'ac129405.2', id: 'AC129405.2'},
{value: 'ac129460.1', id: 'AC129460.1'},
{value: 'ac129460.2', id: 'AC129460.2'},
{value: 'ac129695.1', id: 'AC129695.1'},
{value: 'ac129753.1', id: 'AC129753.1'},
{value: 'ac129753.2', id: 'AC129753.2'},
{value: 'ac129753.3', id: 'AC129753.3'},
{value: 'ac129815.1', id: 'AC129815.1'},
{value: 'ac129824.1', id: 'AC129824.1'},
{value: 'ac129839.1', id: 'AC129839.1'},
{value: 'ac129843.1', id: 'AC129843.1'},
{value: 'ac130035.1', id: 'AC130035.1'},
{value: 'ac130035.2', id: 'AC130035.2'},
{value: 'ac130053.1', id: 'AC130053.1'},
{value: 'ac130064.1', id: 'AC130064.1'},
{value: 'ac130139.1', id: 'AC130139.1'},
{value: 'ac130139.2', id: 'AC130139.2'},
{value: 'ac130146.1', id: 'AC130146.1'},
{value: 'ac130162.1', id: 'AC130162.1'},
{value: 'ac130232.1', id: 'AC130232.1'},
{value: 'ac130232.2', id: 'AC130232.2'},
{value: 'ac130253.1', id: 'AC130253.1'},
{value: 'ac130253.2', id: 'AC130253.2'},
{value: 'ac130253.3', id: 'AC130253.3'},
{value: 'ac130391.1', id: 'AC130391.1'},
{value: 'ac130391.3', id: 'AC130391.3'},
{value: 'ac130391.4', id: 'AC130391.4'},
{value: 'ac130391.5', id: 'AC130391.5'},
{value: 'ac130585.1', id: 'AC130585.1'},
{value: 'ac130741.1', id: 'AC130741.1'},
{value: 'ac130862.1', id: 'AC130862.1'},
{value: 'ac130862.2', id: 'AC130862.2'},
{value: 'ac130862.3', id: 'AC130862.3'},
{value: 'ac130940.1', id: 'AC130940.1'},
{value: 'ac130969.1', id: 'AC130969.1'},
{value: 'ac130970.1', id: 'AC130970.1'},
{value: 'ac131024.1', id: 'AC131024.1'},
{value: 'ac131360.1', id: 'AC131360.1'},
{value: 'ac131360.2', id: 'AC131360.2'},
{value: 'ac131411.1', id: 'AC131411.1'},
{value: 'ac131411.2', id: 'AC131411.2'},
{value: 'ac131411.3', id: 'AC131411.3'},
{value: 'ac131411.4', id: 'AC131411.4'},
{value: 'ac131483.1', id: 'AC131483.1'},
{value: 'ac131521.1', id: 'AC131521.1'},
{value: 'ac131537.1', id: 'AC131537.1'},
{value: 'ac131537.3', id: 'AC131537.3'},
{value: 'ac131537.4', id: 'AC131537.4'},
{value: 'ac131806.1', id: 'AC131806.1'},
{value: 'ac131806.2', id: 'AC131806.2'},
{value: 'ac131806.3', id: 'AC131806.3'},
{value: 'ac131848.1', id: 'AC131848.1'},
{value: 'ac131874.1', id: 'AC131874.1'},
{value: 'ac132020.1', id: 'AC132020.1'},
{value: 'ac132028.1', id: 'AC132028.1'},
{value: 'ac132028.2', id: 'AC132028.2'},
{value: 'ac132057.1', id: 'AC132057.1'},
{value: 'ac132057.2', id: 'AC132057.2'},
{value: 'ac132539.1', id: 'AC132539.1'},
{value: 'ac132539.2', id: 'AC132539.2'},
{value: 'ac132539.3', id: 'AC132539.3'},
{value: 'ac132627.1', id: 'AC132627.1'},
{value: 'ac132627.3', id: 'AC132627.3'},
{value: 'ac132635.1', id: 'AC132635.1'},
{value: 'ac132671.1', id: 'AC132671.1'},
{value: 'ac132699.1', id: 'AC132699.1'},
{value: 'ac132699.2', id: 'AC132699.2'},
{value: 'ac132720.1', id: 'AC132720.1'},
{value: 'ac132740.1', id: 'AC132740.1'},
{value: 'ac132752.2', id: 'AC132752.2'},
{value: 'ac132760.1', id: 'AC132760.1'},
{value: 'ac133055.2', id: 'AC133055.2'},
{value: 'ac133265.1', id: 'AC133265.1'},
{value: 'ac133265.2', id: 'AC133265.2'},
{value: 'ac133270.1', id: 'AC133270.1'},
{value: 'ac133273.1', id: 'AC133273.1'},
{value: 'ac133294.1', id: 'AC133294.1'},
{value: 'ac133316.1', id: 'AC133316.1'},
{value: 'ac133316.2', id: 'AC133316.2'},
{value: 'ac133316.3', id: 'AC133316.3'},
{value: 'ac133383.1', id: 'AC133383.1'},
{value: 'ac133400.1', id: 'AC133400.1'},
{value: 'ac133403.1', id: 'AC133403.1'},
{value: 'ac133613.1', id: 'AC133613.1'},
{value: 'ac133613.2', id: 'AC133613.2'},
{value: 'ac133739.1', id: 'AC133739.1'},
{value: 'ac133821.1', id: 'AC133821.1'},
{value: 'ac133827.1', id: 'AC133827.1'},
{value: 'ac134009.1', id: 'AC134009.1'},
{value: 'ac134024.1', id: 'AC134024.1'},
{value: 'ac134024.3', id: 'AC134024.3'},
{value: 'ac134037.1', id: 'AC134037.1'},
{value: 'ac134056.1', id: 'AC134056.1'},
{value: 'ac134063.1', id: 'AC134063.1'},
{value: 'ac134087.1', id: 'AC134087.1'},
{value: 'ac134160.1', id: 'AC134160.1'},
{value: 'ac134204.1', id: 'AC134204.1'},
{value: 'ac134224.1', id: 'AC134224.1'},
{value: 'ac134224.2', id: 'AC134224.2'},
{value: 'ac134224.3', id: 'AC134224.3'},
{value: 'ac134632.1', id: 'AC134632.1'},
{value: 'ac134651.1', id: 'AC134651.1'},
{value: 'ac134746.1', id: 'AC134746.1'},
{value: 'ac134757.1', id: 'AC134757.1'},
{value: 'ac134759.1', id: 'AC134759.1'},
{value: 'ac134760.1', id: 'AC134760.1'},
{value: 'ac135026.1', id: 'AC135026.1'},
{value: 'ac135142.1', id: 'AC135142.1'},
{value: 'ac135265.1', id: 'AC135265.1'},
{value: 'ac135285.2', id: 'AC135285.2'},
{value: 'ac135285.3', id: 'AC135285.3'},
{value: 'ac135294.1', id: 'AC135294.1'},
{value: 'ac135298.1', id: 'AC135298.1'},
{value: 'ac135310.1', id: 'AC135310.1'},
{value: 'ac135310.2', id: 'AC135310.2'},
{value: 'ac135389.1', id: 'AC135389.1'},
{value: 'ac135409.1', id: 'AC135409.1'},
{value: 'ac135443.1', id: 'AC135443.1'},
{value: 'ac135454.1', id: 'AC135454.1'},
{value: 'ac135520.1', id: 'AC135520.1'},
{value: 'ac135645.1', id: 'AC135645.1'},
{value: 'ac135645.2', id: 'AC135645.2'},
{value: 'ac135674.1', id: 'AC135674.1'},
{value: 'ac135741.1', id: 'AC135741.1'},
{value: 'ac135757.1', id: 'AC135757.1'},
{value: 'ac135766.1', id: 'AC135766.1'},
{value: 'ac135771.1', id: 'AC135771.1'},
{value: 'ac135822.2', id: 'AC135822.2'},
{value: 'ac135822.3', id: 'AC135822.3'},
{value: 'ac135822.4', id: 'AC135822.4'},
{value: 'ac135901.1', id: 'AC135901.1'},
{value: 'ac136010.1', id: 'AC136010.1'},
{value: 'ac136013.1', id: 'AC136013.1'},
{value: 'ac136013.3', id: 'AC136013.3'},
{value: 'ac136025.1', id: 'AC136025.1'},
{value: 'ac136053.1', id: 'AC136053.1'},
{value: 'ac136161.1', id: 'AC136161.1'},
{value: 'ac136163.1', id: 'AC136163.1'},
{value: 'ac136584.1', id: 'AC136584.1'},
{value: 'ac136588.1', id: 'AC136588.1'},
{value: 'ac136588.2', id: 'AC136588.2'},
{value: 'ac136588.3', id: 'AC136588.3'},
{value: 'ac136588.4', id: 'AC136588.4'},
{value: 'ac136661.1', id: 'AC136661.1'},
{value: 'ac136847.1', id: 'AC136847.1'},
{value: 'ac136866.1', id: 'AC136866.1'},
{value: 'ac136867.1', id: 'AC136867.1'},
{value: 'ac136867.2', id: 'AC136867.2'},
{value: 'ac139391.1', id: 'AC139391.1'},
{value: 'ac139392.1', id: 'AC139392.1'},
{value: 'ac139445.1', id: 'AC139445.1'},
{value: 'ac139605.1', id: 'AC139605.1'},
{value: 'ac139608.1', id: 'AC139608.1'},
{value: 'ac139608.2', id: 'AC139608.2'},
{value: 'ac139642.2', id: 'AC139642.2'},
{value: 'ac139873.2', id: 'AC139873.2'},
{value: 'ac139873.3', id: 'AC139873.3'},
{value: 'ac140765.1', id: 'AC140765.1'},
{value: 'ac140988.1', id: 'AC140988.1'},
{value: 'ac141028.2', id: 'AC141028.2'},
{value: 'ac141028.3', id: 'AC141028.3'},
{value: 'ac141028.4', id: 'AC141028.4'},
{value: 'ac141102.1', id: 'AC141102.1'},
{value: 'ac141136.1', id: 'AC141136.1'},
{value: 'ac141145.1', id: 'AC141145.1'},
{value: 'ac141168.1', id: 'AC141168.1'},
{value: 'ac141169.2', id: 'AC141169.2'},
{value: 'ac141169.3', id: 'AC141169.3'},
{value: 'ac141169.5', id: 'AC141169.5'},
{value: 'ac141172.1', id: 'AC141172.1'},
{value: 'ac141220.1', id: 'AC141220.1'},
{value: 'ac141220.2', id: 'AC141220.2'},
{value: 'ac141220.3', id: 'AC141220.3'},
{value: 'ac141220.4', id: 'AC141220.4'},
{value: 'ac141334.1', id: 'AC141334.1'},
{value: 'ac141334.2', id: 'AC141334.2'},
{value: 'ac141334.3', id: 'AC141334.3'},
{value: 'ac141334.4', id: 'AC141334.4'},
{value: 'ac141344.1', id: 'AC141344.1'},
{value: 'ac141344.2', id: 'AC141344.2'},
{value: 'ac141377.1', id: 'AC141377.1'},
{value: 'ac141377.2', id: 'AC141377.2'},
{value: 'ac141377.3', id: 'AC141377.3'},
{value: 'ac141489.1', id: 'AC141489.1'},
{value: 'ac141516.1', id: 'AC141516.1'},
{value: 'ac141517.1', id: 'AC141517.1'},
{value: 'ac141521.1', id: 'AC141521.1'},
{value: 'ac141521.2', id: 'AC141521.2'},
{value: 'ac141521.3', id: 'AC141521.3'},
{value: 'ac141521.4', id: 'AC141521.4'},
{value: 'ac141526.1', id: 'AC141526.1'},
{value: 'ac141526.2', id: 'AC141526.2'},
{value: 'ac141541.1', id: 'AC141541.1'},
{value: 'ac141573.1', id: 'AC141573.1'},
{value: 'ac141937.1', id: 'AC141937.1'},
{value: 'ac141959.1', id: 'AC141959.1'},
{value: 'ac141961.1', id: 'AC141961.1'},
{value: 'ac141966.1', id: 'AC141966.1'},
{value: 'ac141967.1', id: 'AC141967.1'},
{value: 'ac141993.1', id: 'AC141993.1'},
{value: 'ac141997.1', id: 'AC141997.1'},
{value: 'ac142126.1', id: 'AC142126.1'},
{value: 'ac142138.1', id: 'AC142138.1'},
{value: 'ac142138.2', id: 'AC142138.2'},
{value: 'ac142154.1', id: 'AC142154.1'},
{value: 'ac142178.1', id: 'AC142178.1'},
{value: 'ac142178.2', id: 'AC142178.2'},
{value: 'ac142178.3', id: 'AC142178.3'},
{value: 'ac142180.1', id: 'AC142180.1'},
{value: 'ac142181.1', id: 'AC142181.1'},
{value: 'ac142187.1', id: 'AC142187.1'},
{value: 'ac142360.1', id: 'AC142360.1'},
{value: 'ac142421.1', id: 'AC142421.1'},
{value: 'ac142421.2', id: 'AC142421.2'},
{value: 'ac142458.1', id: 'AC142458.1'},
{value: 'ac144660.1', id: 'AC144660.1'},
{value: 'ac144660.2', id: 'AC144660.2'},
{value: 'ac144674.1', id: 'AC144674.1'},
{value: 'ac144674.2', id: 'AC144674.2'},
{value: 'ac1576', id: 'AC1576'},
{value: 'ac229945.1', id: 'AC229945.1'},
{value: 'ac229945.2', id: 'AC229945.2'},
{value: 'ac239701.1', id: 'AC239701.1'},
{value: 'ac239701.2', id: 'AC239701.2'},
{value: 'ac239701.4', id: 'AC239701.4'},
{value: 'ac239813.1', id: 'AC239813.1'},
{value: 'ac239817.3', id: 'AC239817.3'},
{value: 'ac239865.1', id: 'AC239865.1'},
{value: 'ac240408.2', id: 'AC240408.2'},
{value: 'ac241722.1', id: 'AC241722.1'},
{value: 'ac241722.2', id: 'AC241722.2'},
{value: 'ac241808.1', id: 'AC241808.1'},
{value: 'ac242055.1', id: 'AC242055.1'},
{value: 'ac242615.2', id: 'AC242615.2'},
{value: 'ac242641.1', id: 'AC242641.1'},
{value: 'ac242859.1', id: 'AC242859.1'},
{value: 'ac242859.2', id: 'AC242859.2'},
{value: 'ac242859.3', id: 'AC242859.3'},
{value: 'ac242953.1', id: 'AC242953.1'},
{value: 'ac243284.2', id: 'AC243284.2'},
{value: 'ac243833.2', id: 'AC243833.2'},
{value: 'ac246658.1', id: 'AC246658.1'},
{value: 'acaa1a', id: 'ACAA1A'},
{value: 'acaa1b', id: 'ACAA1B'},
{value: 'acaa2', id: 'ACAA2'},
{value: 'acaca', id: 'ACACA'},
{value: 'acacb', id: 'ACACB'},
{value: 'acad10', id: 'ACAD10'},
{value: 'acad11', id: 'ACAD11'},
{value: 'acad8', id: 'ACAD8'},
{value: 'acad9', id: 'ACAD9'},
{value: 'acadl', id: 'ACADL'},
{value: 'acadm', id: 'ACADM'},
{value: 'acads', id: 'ACADS'},
{value: 'acadsb', id: 'ACADSB'},
{value: 'acadvl', id: 'ACADVL'},
{value: 'acan', id: 'ACAN'},
{value: 'acap1', id: 'ACAP1'},
{value: 'acap2', id: 'ACAP2'},
{value: 'acap3', id: 'ACAP3'},
{value: 'acat1', id: 'ACAT1'},
{value: 'acat2', id: 'ACAT2'},
{value: 'acat2l1', id: 'ACAT2L1'},
{value: 'acbd3', id: 'ACBD3'},
{value: 'acbd4', id: 'ACBD4'},
{value: 'acbd5', id: 'ACBD5'},
{value: 'acbd6', id: 'ACBD6'},
{value: 'acbd7', id: 'ACBD7'},
{value: 'accs', id: 'ACCS'},
{value: 'accsl', id: 'ACCSL'},
{value: 'acd', id: 'ACD'},
{value: 'ace', id: 'ACE'},
{value: 'ace2', id: 'ACE2'},
{value: 'ace3', id: 'ACE3'},
{value: 'acer1', id: 'ACER1'},
{value: 'acer2', id: 'ACER2'},
{value: 'acer3', id: 'ACER3'},
{value: 'ache', id: 'ACHE'},
{value: 'acin1', id: 'ACIN1'},
{value: 'ackr1', id: 'ACKR1'},
{value: 'ackr2', id: 'ACKR2'},
{value: 'ackr3', id: 'ACKR3'},
{value: 'ackr4', id: 'ACKR4'},
{value: 'acly', id: 'ACLY'},
{value: 'acmsd', id: 'ACMSD'},
{value: 'acnat1', id: 'ACNAT1'},
{value: 'acnat2', id: 'ACNAT2'},
{value: 'aco1', id: 'ACO1'},
{value: 'aco2', id: 'ACO2'},
{value: 'acod1', id: 'ACOD1'},
{value: 'acot1', id: 'ACOT1'},
{value: 'acot11', id: 'ACOT11'},
{value: 'acot12', id: 'ACOT12'},
{value: 'acot13', id: 'ACOT13'},
{value: 'acot2', id: 'ACOT2'},
{value: 'acot3', id: 'ACOT3'},
{value: 'acot4', id: 'ACOT4'},
{value: 'acot5', id: 'ACOT5'},
{value: 'acot6', id: 'ACOT6'},
{value: 'acot7', id: 'ACOT7'},
{value: 'acot8', id: 'ACOT8'},
{value: 'acot9', id: 'ACOT9'},
{value: 'acox1', id: 'ACOX1'},
{value: 'acox2', id: 'ACOX2'},
{value: 'acox3', id: 'ACOX3'},
{value: 'acoxl', id: 'ACOXL'},
{value: 'acp1', id: 'ACP1'},
{value: 'acp2', id: 'ACP2'},
{value: 'acp3', id: 'ACP3'},
{value: 'acp4', id: 'ACP4'},
{value: 'acp5', id: 'ACP5'},
{value: 'acp6', id: 'ACP6'},
{value: 'acp7', id: 'ACP7'},
{value: 'acr', id: 'ACR'},
{value: 'acrbp', id: 'ACRBP'},
{value: 'acrv1', id: 'ACRV1'},
{value: 'acsbg1', id: 'ACSBG1'},
{value: 'acsbg2', id: 'ACSBG2'},
{value: 'acsf2', id: 'ACSF2'},
{value: 'acsf3', id: 'ACSF3'},
{value: 'acsl1', id: 'ACSL1'},
{value: 'acsl3', id: 'ACSL3'},
{value: 'acsl4', id: 'ACSL4'},
{value: 'acsl5', id: 'ACSL5'},
{value: 'acsl6', id: 'ACSL6'},
{value: 'acsm1', id: 'ACSM1'},
{value: 'acsm2', id: 'ACSM2'},
{value: 'acsm3', id: 'ACSM3'},
{value: 'acsm4', id: 'ACSM4'},
{value: 'acsm5', id: 'ACSM5'},
{value: 'acss1', id: 'ACSS1'},
{value: 'acss2', id: 'ACSS2'},
{value: 'acss3', id: 'ACSS3'},
{value: 'acta1', id: 'ACTA1'},
{value: 'acta2', id: 'ACTA2'},
{value: 'actb', id: 'ACTB'},
{value: 'actbl2', id: 'ACTBL2'},
{value: 'actc1', id: 'ACTC1'},
{value: 'actg1', id: 'ACTG1'},
{value: 'actg2', id: 'ACTG2'},
{value: 'actl10', id: 'ACTL10'},
{value: 'actl11', id: 'ACTL11'},
{value: 'actl6a', id: 'ACTL6A'},
{value: 'actl6b', id: 'ACTL6B'},
{value: 'actl7a', id: 'ACTL7A'},
{value: 'actl7b', id: 'ACTL7B'},
{value: 'actl9', id: 'ACTL9'},
{value: 'actl9b', id: 'ACTL9B'},
{value: 'actn1', id: 'ACTN1'},
{value: 'actn2', id: 'ACTN2'},
{value: 'actn3', id: 'ACTN3'},
{value: 'actn4', id: 'ACTN4'},
{value: 'actr10', id: 'ACTR10'},
{value: 'actr1a', id: 'ACTR1A'},
{value: 'actr1b', id: 'ACTR1B'},
{value: 'actr2', id: 'ACTR2'},
{value: 'actr3', id: 'ACTR3'},
{value: 'actr3b', id: 'ACTR3B'},
{value: 'actr5', id: 'ACTR5'},
{value: 'actr6', id: 'ACTR6'},
{value: 'actr8', id: 'ACTR8'},
{value: 'actrt1', id: 'ACTRT1'},
{value: 'actrt2', id: 'ACTRT2'},
{value: 'actrt3', id: 'ACTRT3'},
{value: 'acvr1', id: 'ACVR1'},
{value: 'acvr1b', id: 'ACVR1B'},
{value: 'acvr1c', id: 'ACVR1C'},
{value: 'acvr2a', id: 'ACVR2A'},
{value: 'acvr2b', id: 'ACVR2B'},
{value: 'acvrl1', id: 'ACVRL1'},
{value: 'acy1', id: 'ACY1'},
{value: 'acy3', id: 'ACY3'},
{value: 'acyp1', id: 'ACYP1'},
{value: 'acyp2', id: 'ACYP2'},
{value: 'ada', id: 'ADA'},
{value: 'adad1', id: 'ADAD1'},
{value: 'adad2', id: 'ADAD2'},
{value: 'adal', id: 'ADAL'},
{value: 'adam10', id: 'ADAM10'},
{value: 'adam11', id: 'ADAM11'},
{value: 'adam12', id: 'ADAM12'},
{value: 'adam15', id: 'ADAM15'},
{value: 'adam17', id: 'ADAM17'},
{value: 'adam18', id: 'ADAM18'},
{value: 'adam19', id: 'ADAM19'},
{value: 'adam1a', id: 'ADAM1A'},
{value: 'adam2', id: 'ADAM2'},
{value: 'adam21', id: 'ADAM21'},
{value: 'adam22', id: 'ADAM22'},
{value: 'adam23', id: 'ADAM23'},
{value: 'adam24', id: 'ADAM24'},
{value: 'adam28', id: 'ADAM28'},
{value: 'adam30', id: 'ADAM30'},
{value: 'adam32', id: 'ADAM32'},
{value: 'adam33', id: 'ADAM33'},
{value: 'adam34', id: 'ADAM34'},
{value: 'adam34l', id: 'ADAM34L'},
{value: 'adam3a', id: 'ADAM3A'},
{value: 'adam4', id: 'ADAM4'},
{value: 'adam5', id: 'ADAM5'},
{value: 'adam6', id: 'ADAM6'},
{value: 'adam7', id: 'ADAM7'},
{value: 'adam8', id: 'ADAM8'},
{value: 'adam9', id: 'ADAM9'},
{value: 'adamdec1', id: 'ADAMDEC1'},
{value: 'adamts1', id: 'ADAMTS1'},
{value: 'adamts10', id: 'ADAMTS10'},
{value: 'adamts12', id: 'ADAMTS12'},
{value: 'adamts13', id: 'ADAMTS13'},
{value: 'adamts14', id: 'ADAMTS14'},
{value: 'adamts15', id: 'ADAMTS15'},
{value: 'adamts16', id: 'ADAMTS16'},
{value: 'adamts17', id: 'ADAMTS17'},
{value: 'adamts18', id: 'ADAMTS18'},
{value: 'adamts19', id: 'ADAMTS19'},
{value: 'adamts2', id: 'ADAMTS2'},
{value: 'adamts20', id: 'ADAMTS20'},
{value: 'adamts3', id: 'ADAMTS3'},
{value: 'adamts4', id: 'ADAMTS4'},
{value: 'adamts5', id: 'ADAMTS5'},
{value: 'adamts6', id: 'ADAMTS6'},
{value: 'adamts7', id: 'ADAMTS7'},
{value: 'adamts8', id: 'ADAMTS8'},
{value: 'adamts9', id: 'ADAMTS9'},
{value: 'adamtsl1', id: 'ADAMTSL1'},
{value: 'adamtsl2', id: 'ADAMTSL2'},
{value: 'adamtsl3', id: 'ADAMTSL3'},
{value: 'adamtsl4', id: 'ADAMTSL4'},
{value: 'adamtsl5', id: 'ADAMTSL5'},
{value: 'adap1', id: 'ADAP1'},
{value: 'adap2', id: 'ADAP2'},
{value: 'adar', id: 'ADAR'},
{value: 'adarb1', id: 'ADARB1'},
{value: 'adarb2', id: 'ADARB2'},
{value: 'adat1', id: 'ADAT1'},
{value: 'adat2', id: 'ADAT2'},
{value: 'adat3', id: 'ADAT3'},
{value: 'adck1', id: 'ADCK1'},
{value: 'adck2', id: 'ADCK2'},
{value: 'adck5', id: 'ADCK5'},
{value: 'adcy1', id: 'ADCY1'},
{value: 'adcy10', id: 'ADCY10'},
{value: 'adcy2', id: 'ADCY2'},
{value: 'adcy3', id: 'ADCY3'},
{value: 'adcy4', id: 'ADCY4'},
{value: 'adcy5', id: 'ADCY5'},
{value: 'adcy6', id: 'ADCY6'},
{value: 'adcy7', id: 'ADCY7'},
{value: 'adcy8', id: 'ADCY8'},
{value: 'adcy9', id: 'ADCY9'},
{value: 'adcyap1', id: 'ADCYAP1'},
{value: 'adcyap1r1', id: 'ADCYAP1R1'},
{value: 'add1', id: 'ADD1'},
{value: 'add2', id: 'ADD2'},
{value: 'add3', id: 'ADD3'},
{value: 'adgb', id: 'ADGB'},
{value: 'adgra1', id: 'ADGRA1'},
{value: 'adgra2', id: 'ADGRA2'},
{value: 'adgra3', id: 'ADGRA3'},
{value: 'adgrb1', id: 'ADGRB1'},
{value: 'adgrb2', id: 'ADGRB2'},
{value: 'adgrb3', id: 'ADGRB3'},
{value: 'adgrd1', id: 'ADGRD1'},
{value: 'adgre1', id: 'ADGRE1'},
{value: 'adgre4', id: 'ADGRE4'},
{value: 'adgre5', id: 'ADGRE5'},
{value: 'adgrf1', id: 'ADGRF1'},
{value: 'adgrf2', id: 'ADGRF2'},
{value: 'adgrf3', id: 'ADGRF3'},
{value: 'adgrf4', id: 'ADGRF4'},
{value: 'adgrf5', id: 'ADGRF5'},
{value: 'adgrg1', id: 'ADGRG1'},
{value: 'adgrg2', id: 'ADGRG2'},
{value: 'adgrg3', id: 'ADGRG3'},
{value: 'adgrg4', id: 'ADGRG4'},
{value: 'adgrg5', id: 'ADGRG5'},
{value: 'adgrg6', id: 'ADGRG6'},
{value: 'adgrg7', id: 'ADGRG7'},
{value: 'adgrl1', id: 'ADGRL1'},
{value: 'adgrl2', id: 'ADGRL2'},
{value: 'adgrl3', id: 'ADGRL3'},
{value: 'adgrl4', id: 'ADGRL4'},
{value: 'adgrv1', id: 'ADGRV1'},
{value: 'adh1', id: 'ADH1'},
{value: 'adh4', id: 'ADH4'},
{value: 'adh5', id: 'ADH5'},
{value: 'adh6', id: 'ADH6'},
{value: 'adh6a', id: 'ADH6A'},
{value: 'adh7', id: 'ADH7'},
{value: 'adhfe1', id: 'ADHFE1'},
{value: 'adi1', id: 'ADI1'},
{value: 'adig', id: 'ADIG'},
{value: 'adipoq', id: 'ADIPOQ'},
{value: 'adipor1', id: 'ADIPOR1'},
{value: 'adipor2', id: 'ADIPOR2'},
{value: 'adk', id: 'ADK'},
{value: 'adm', id: 'ADM'},
{value: 'adm2', id: 'ADM2'},
{value: 'adnp', id: 'ADNP'},
{value: 'adnp2', id: 'ADNP2'},
{value: 'ado', id: 'ADO'},
{value: 'adora1', id: 'ADORA1'},
{value: 'adora2a', id: 'ADORA2A'},
{value: 'adora2b', id: 'ADORA2B'},
{value: 'adora3', id: 'ADORA3'},
{value: 'adpgk', id: 'ADPGK'},
{value: 'adprh', id: 'ADPRH'},
{value: 'adprhl1', id: 'ADPRHL1'},
{value: 'adprm', id: 'ADPRM'},
{value: 'adprs', id: 'ADPRS'},
{value: 'adra1a', id: 'ADRA1A'},
{value: 'adra1b', id: 'ADRA1B'},
{value: 'adra1d', id: 'ADRA1D'},
{value: 'adra2a', id: 'ADRA2A'},
{value: 'adra2b', id: 'ADRA2B'},
{value: 'adra2c', id: 'ADRA2C'},
{value: 'adrb1', id: 'ADRB1'},
{value: 'adrb2', id: 'ADRB2'},
{value: 'adrb3', id: 'ADRB3'},
{value: 'adrm1', id: 'ADRM1'},
{value: 'adsl', id: 'ADSL'},
{value: 'adss', id: 'ADSS'},
{value: 'adss1', id: 'ADSS1'},
{value: 'adtrp', id: 'ADTRP'},
{value: 'aebp1', id: 'AEBP1'},
{value: 'aebp2', id: 'AEBP2'},
{value: 'aen', id: 'AEN'},
{value: 'af357425', id: 'AF357425'},
{value: 'af357428', id: 'AF357428'},
{value: 'afap1', id: 'AFAP1'},
{value: 'afap1l1', id: 'AFAP1L1'},
{value: 'afap1l2', id: 'AFAP1L2'},
{value: 'afdn', id: 'AFDN'},
{value: 'aff1-as1', id: 'AFF1-AS1'},
{value: 'aff1', id: 'AFF1'},
{value: 'aff2', id: 'AFF2'},
{value: 'aff3', id: 'AFF3'},
{value: 'aff4', id: 'AFF4'},
{value: 'afg1l', id: 'AFG1L'},
{value: 'afg3l1', id: 'AFG3L1'},
{value: 'afg3l2', id: 'AFG3L2'},
{value: 'afm', id: 'AFM'},
{value: 'afmid', id: 'AFMID'},
{value: 'afp', id: 'AFP'},
{value: 'aftph', id: 'AFTPH'},
{value: 'aga', id: 'AGA'},
{value: 'agap1', id: 'AGAP1'},
{value: 'agap2', id: 'AGAP2'},
{value: 'agap3', id: 'AGAP3'},
{value: 'agbl1', id: 'AGBL1'},
{value: 'agbl2', id: 'AGBL2'},
{value: 'agbl3', id: 'AGBL3'},
{value: 'agbl4', id: 'AGBL4'},
{value: 'agbl5', id: 'AGBL5'},
{value: 'ager', id: 'AGER'},
{value: 'agfg1', id: 'AGFG1'},
{value: 'agfg2', id: 'AGFG2'},
{value: 'aggf1', id: 'AGGF1'},
{value: 'agk', id: 'AGK'},
{value: 'agl', id: 'AGL'},
{value: 'agmat', id: 'AGMAT'},
{value: 'agmo', id: 'AGMO'},
{value: 'ago1', id: 'AGO1'},
{value: 'ago2', id: 'AGO2'},
{value: 'ago3', id: 'AGO3'},
{value: 'ago4', id: 'AGO4'},
{value: 'agpat1', id: 'AGPAT1'},
{value: 'agpat2', id: 'AGPAT2'},
{value: 'agpat3', id: 'AGPAT3'},
{value: 'agpat4', id: 'AGPAT4'},
{value: 'agpat5', id: 'AGPAT5'},
{value: 'agps', id: 'AGPS'},
{value: 'agr2', id: 'AGR2'},
{value: 'agr3', id: 'AGR3'},
{value: 'agrn', id: 'AGRN'},
{value: 'agrp', id: 'AGRP'},
{value: 'agt', id: 'AGT'},
{value: 'agtpbp1', id: 'AGTPBP1'},
{value: 'agtr1a', id: 'AGTR1A'},
{value: 'agtr1b', id: 'AGTR1B'},
{value: 'agtr2', id: 'AGTR2'},
{value: 'agtrap', id: 'AGTRAP'},
{value: 'agxt', id: 'AGXT'},
{value: 'agxt2', id: 'AGXT2'},
{value: 'ahctf1', id: 'AHCTF1'},
{value: 'ahcy', id: 'AHCY'},
{value: 'ahcyl1', id: 'AHCYL1'},
{value: 'ahcyl2', id: 'AHCYL2'},
{value: 'ahdc1', id: 'AHDC1'},
{value: 'ahi1', id: 'AHI1'},
{value: 'ahnak', id: 'AHNAK'},
{value: 'ahnak2', id: 'AHNAK2'},
{value: 'ahr', id: 'AHR'},
{value: 'ahrr', id: 'AHRR'},
{value: 'ahsa1', id: 'AHSA1'},
{value: 'ahsa2', id: 'AHSA2'},
{value: 'ahsg', id: 'AHSG'},
{value: 'ahsp', id: 'AHSP'},
{value: 'aicda', id: 'AICDA'},
{value: 'aida', id: 'AIDA'},
{value: 'aif1', id: 'AIF1'},
{value: 'aif1l', id: 'AIF1L'},
{value: 'aifm1', id: 'AIFM1'},
{value: 'aifm2', id: 'AIFM2'},
{value: 'aifm3', id: 'AIFM3'},
{value: 'aig1', id: 'AIG1'},
{value: 'aim2', id: 'AIM2'},
{value: 'aimp1', id: 'AIMP1'},
{value: 'aimp2', id: 'AIMP2'},
{value: 'aip', id: 'AIP'},
{value: 'aipl1', id: 'AIPL1'},
{value: 'aire', id: 'AIRE'},
{value: 'ajap1', id: 'AJAP1'},
{value: 'ajm1', id: 'AJM1'},
{value: 'ajuba', id: 'AJUBA'},
{value: 'ak1', id: 'AK1'},
{value: 'ak2', id: 'AK2'},
{value: 'ak3', id: 'AK3'},
{value: 'ak4', id: 'AK4'},
{value: 'ak5', id: 'AK5'},
{value: 'ak6', id: 'AK6'},
{value: 'ak7', id: 'AK7'},
{value: 'ak8', id: 'AK8'},
{value: 'ak9', id: 'AK9'},
{value: 'akain1', id: 'AKAIN1'},
{value: 'akap1', id: 'AKAP1'},
{value: 'akap10', id: 'AKAP10'},
{value: 'akap11', id: 'AKAP11'},
{value: 'akap12', id: 'AKAP12'},
{value: 'akap13', id: 'AKAP13'},
{value: 'akap14', id: 'AKAP14'},
{value: 'akap17a', id: 'AKAP17A'},
{value: 'akap17b', id: 'AKAP17B'},
{value: 'akap2', id: 'AKAP2'},
{value: 'akap3', id: 'AKAP3'},
{value: 'akap4', id: 'AKAP4'},
{value: 'akap5', id: 'AKAP5'},
{value: 'akap6', id: 'AKAP6'},
{value: 'akap7', id: 'AKAP7'},
{value: 'akap8', id: 'AKAP8'},
{value: 'akap8l', id: 'AKAP8L'},
{value: 'akap9', id: 'AKAP9'},
{value: 'akip1', id: 'AKIP1'},
{value: 'akirin1', id: 'AKIRIN1'},
{value: 'akirin2', id: 'AKIRIN2'},
{value: 'akna', id: 'AKNA'},
{value: 'aknad1', id: 'AKNAD1'},
{value: 'akp3', id: 'AKP3'},
{value: 'akr1a1', id: 'AKR1A1'},
{value: 'akr1b1-ps1', id: 'AKR1B1-PS1'},
{value: 'akr1b1-ps2', id: 'AKR1B1-PS2'},
{value: 'akr1b1', id: 'AKR1B1'},
{value: 'akr1b10', id: 'AKR1B10'},
{value: 'akr1b7', id: 'AKR1B7'},
{value: 'akr1b8', id: 'AKR1B8'},
{value: 'akr1c1', id: 'AKR1C1'},
{value: 'akr1c12', id: 'AKR1C12'},
{value: 'akr1c12l1', id: 'AKR1C12L1'},
{value: 'akr1c13', id: 'AKR1C13'},
{value: 'akr1c14', id: 'AKR1C14'},
{value: 'akr1c15', id: 'AKR1C15'},
{value: 'akr1c19', id: 'AKR1C19'},
{value: 'akr1c2', id: 'AKR1C2'},
{value: 'akr1c3', id: 'AKR1C3'},
{value: 'akr1d1', id: 'AKR1D1'},
{value: 'akr1e2', id: 'AKR1E2'},
{value: 'akr7a2', id: 'AKR7A2'},
{value: 'akr7a3', id: 'AKR7A3'},
{value: 'akt1', id: 'AKT1'},
{value: 'akt1s1', id: 'AKT1S1'},
{value: 'akt2', id: 'AKT2'},
{value: 'akt3', id: 'AKT3'},
{value: 'aktip', id: 'AKTIP'},
{value: 'alad', id: 'ALAD'},
{value: 'alas1', id: 'ALAS1'},
{value: 'alas2', id: 'ALAS2'},
{value: 'alb', id: 'ALB'},
{value: 'alcam', id: 'ALCAM'},
{value: 'aldh16a1', id: 'ALDH16A1'},
{value: 'aldh18a1', id: 'ALDH18A1'},
{value: 'aldh1a1', id: 'ALDH1A1'},
{value: 'aldh1a2', id: 'ALDH1A2'},
{value: 'aldh1a3', id: 'ALDH1A3'},
{value: 'aldh1a7', id: 'ALDH1A7'},
{value: 'aldh1b1', id: 'ALDH1B1'},
{value: 'aldh1l1', id: 'ALDH1L1'},
{value: 'aldh1l2', id: 'ALDH1L2'},
{value: 'aldh2', id: 'ALDH2'},
{value: 'aldh3a1', id: 'ALDH3A1'},
{value: 'aldh3a2', id: 'ALDH3A2'},
{value: 'aldh3b1', id: 'ALDH3B1'},
{value: 'aldh3b2', id: 'ALDH3B2'},
{value: 'aldh4a1', id: 'ALDH4A1'},
{value: 'aldh5a1', id: 'ALDH5A1'},
{value: 'aldh6a1', id: 'ALDH6A1'},
{value: 'aldh7a1', id: 'ALDH7A1'},
{value: 'aldh8a1', id: 'ALDH8A1'},
{value: 'aldh9a1', id: 'ALDH9A1'},
{value: 'aldoa', id: 'ALDOA'},
{value: 'aldoart2', id: 'ALDOART2'},
{value: 'aldob', id: 'ALDOB'},
{value: 'aldoc', id: 'ALDOC'},
{value: 'alg1', id: 'ALG1'},
{value: 'alg10', id: 'ALG10'},
{value: 'alg11', id: 'ALG11'},
{value: 'alg12', id: 'ALG12'},
{value: 'alg13', id: 'ALG13'},
{value: 'alg14', id: 'ALG14'},
{value: 'alg2', id: 'ALG2'},
{value: 'alg3', id: 'ALG3'},
{value: 'alg5', id: 'ALG5'},
{value: 'alg6', id: 'ALG6'},
{value: 'alg8', id: 'ALG8'},
{value: 'alg9', id: 'ALG9'},
{value: 'alk', id: 'ALK'},
{value: 'alkal1', id: 'ALKAL1'},
{value: 'alkal2', id: 'ALKAL2'},
{value: 'alkbh1', id: 'ALKBH1'},
{value: 'alkbh2', id: 'ALKBH2'},
{value: 'alkbh3', id: 'ALKBH3'},
{value: 'alkbh4', id: 'ALKBH4'},
{value: 'alkbh5', id: 'ALKBH5'},
{value: 'alkbh6', id: 'ALKBH6'},
{value: 'alkbh7', id: 'ALKBH7'},
{value: 'alkbh8', id: 'ALKBH8'},
{value: 'allc', id: 'ALLC'},
{value: 'alms1', id: 'ALMS1'},
{value: 'alox12', id: 'ALOX12'},
{value: 'alox12b', id: 'ALOX12B'},
{value: 'alox12e', id: 'ALOX12E'},
{value: 'alox15', id: 'ALOX15'},
{value: 'alox15b', id: 'ALOX15B'},
{value: 'alox5', id: 'ALOX5'},
{value: 'alox5ap', id: 'ALOX5AP'},
{value: 'aloxe3', id: 'ALOXE3'},
{value: 'alpg', id: 'ALPG'},
{value: 'alpi', id: 'ALPI'},
{value: 'alpk1', id: 'ALPK1'},
{value: 'alpk2', id: 'ALPK2'},
{value: 'alpk3', id: 'ALPK3'},
{value: 'alpl', id: 'ALPL'},
{value: 'alpp', id: 'ALPP'},
{value: 'als2', id: 'ALS2'},
{value: 'als2cl', id: 'ALS2CL'},
{value: 'alx1', id: 'ALX1'},
{value: 'alx3', id: 'ALX3'},
{value: 'alx4', id: 'ALX4'},
{value: 'alyref', id: 'ALYREF'},
{value: 'amacr', id: 'AMACR'},
{value: 'ambn', id: 'AMBN'},
{value: 'ambp', id: 'AMBP'},
{value: 'ambra1', id: 'AMBRA1'},
{value: 'amd1', id: 'AMD1'},
{value: 'amdhd1', id: 'AMDHD1'},
{value: 'amdhd2', id: 'AMDHD2'},
{value: 'amelx', id: 'AMELX'},
{value: 'amer1', id: 'AMER1'},
{value: 'amer2', id: 'AMER2'},
{value: 'amer3', id: 'AMER3'},
{value: 'amfr', id: 'AMFR'},
{value: 'amh', id: 'AMH'},
{value: 'amhr2', id: 'AMHR2'},
{value: 'amigo1', id: 'AMIGO1'},
{value: 'amigo2', id: 'AMIGO2'},
{value: 'amigo3', id: 'AMIGO3'},
{value: 'ammecr1', id: 'AMMECR1'},
{value: 'ammecr1l', id: 'AMMECR1L'},
{value: 'amn', id: 'AMN'},
{value: 'amn1', id: 'AMN1'},
{value: 'amot', id: 'AMOT'},
{value: 'amotl1', id: 'AMOTL1'},
{value: 'amotl2', id: 'AMOTL2'},
{value: 'ampd1', id: 'AMPD1'},
{value: 'ampd2', id: 'AMPD2'},
{value: 'ampd3', id: 'AMPD3'},
{value: 'amph', id: 'AMPH'},
{value: 'amt', id: 'AMT'},
{value: 'amtn', id: 'AMTN'},
{value: 'amy1a', id: 'AMY1A'},
{value: 'amy2a3', id: 'AMY2A3'},
{value: 'amz1', id: 'AMZ1'},
{value: 'amz2', id: 'AMZ2'},
{value: 'anapc1', id: 'ANAPC1'},
{value: 'anapc10', id: 'ANAPC10'},
{value: 'anapc11', id: 'ANAPC11'},
{value: 'anapc13', id: 'ANAPC13'},
{value: 'anapc15', id: 'ANAPC15'},
{value: 'anapc16', id: 'ANAPC16'},
{value: 'anapc2', id: 'ANAPC2'},
{value: 'anapc4', id: 'ANAPC4'},
{value: 'anapc5', id: 'ANAPC5'},
{value: 'anapc7', id: 'ANAPC7'},
{value: 'andpro', id: 'ANDPRO'},
{value: 'ang', id: 'ANG'},
{value: 'ang2', id: 'ANG2'},
{value: 'angel1', id: 'ANGEL1'},
{value: 'angel2', id: 'ANGEL2'},
{value: 'angpt1', id: 'ANGPT1'},
{value: 'angpt2', id: 'ANGPT2'},
{value: 'angpt4', id: 'ANGPT4'},
{value: 'angptl1', id: 'ANGPTL1'},
{value: 'angptl2', id: 'ANGPTL2'},
{value: 'angptl3', id: 'ANGPTL3'},
{value: 'angptl4', id: 'ANGPTL4'},
{value: 'angptl6', id: 'ANGPTL6'},
{value: 'angptl7', id: 'ANGPTL7'},
{value: 'angptl8', id: 'ANGPTL8'},
{value: 'ank1', id: 'ANK1'},
{value: 'ank2', id: 'ANK2'},
{value: 'ank3', id: 'ANK3'},
{value: 'ankar', id: 'ANKAR'},
{value: 'ankdd1a', id: 'ANKDD1A'},
{value: 'ankdd1b', id: 'ANKDD1B'},
{value: 'ankef1', id: 'ANKEF1'},
{value: 'ankfn1', id: 'ANKFN1'},
{value: 'ankfy1', id: 'ANKFY1'},
{value: 'ankh', id: 'ANKH'},
{value: 'ankhd1', id: 'ANKHD1'},
{value: 'ankib1', id: 'ANKIB1'},
{value: 'ankk1', id: 'ANKK1'},
{value: 'ankle1', id: 'ANKLE1'},
{value: 'ankle2', id: 'ANKLE2'},
{value: 'ankmy1', id: 'ANKMY1'},
{value: 'ankmy2', id: 'ANKMY2'},
{value: 'ankra2', id: 'ANKRA2'},
{value: 'ankrd1', id: 'ANKRD1'},
{value: 'ankrd10', id: 'ANKRD10'},
{value: 'ankrd11', id: 'ANKRD11'},
{value: 'ankrd12', id: 'ANKRD12'},
{value: 'ankrd13a', id: 'ANKRD13A'},
{value: 'ankrd13b', id: 'ANKRD13B'},
{value: 'ankrd13c', id: 'ANKRD13C'},
{value: 'ankrd13d', id: 'ANKRD13D'},
{value: 'ankrd16', id: 'ANKRD16'},
{value: 'ankrd17', id: 'ANKRD17'},
{value: 'ankrd2', id: 'ANKRD2'},
{value: 'ankrd22', id: 'ANKRD22'},
{value: 'ankrd23', id: 'ANKRD23'},
{value: 'ankrd24', id: 'ANKRD24'},
{value: 'ankrd26', id: 'ANKRD26'},
{value: 'ankrd27', id: 'ANKRD27'},
{value: 'ankrd28', id: 'ANKRD28'},
{value: 'ankrd29', id: 'ANKRD29'},
{value: 'ankrd30a', id: 'ANKRD30A'},
{value: 'ankrd31', id: 'ANKRD31'},
{value: 'ankrd33', id: 'ANKRD33'},
{value: 'ankrd33b', id: 'ANKRD33B'},
{value: 'ankrd34a', id: 'ANKRD34A'},
{value: 'ankrd34b', id: 'ANKRD34B'},
{value: 'ankrd34c', id: 'ANKRD34C'},
{value: 'ankrd35', id: 'ANKRD35'},
{value: 'ankrd37', id: 'ANKRD37'},
{value: 'ankrd39', id: 'ANKRD39'},
{value: 'ankrd40', id: 'ANKRD40'},
{value: 'ankrd42', id: 'ANKRD42'},
{value: 'ankrd44', id: 'ANKRD44'},
{value: 'ankrd45', id: 'ANKRD45'},
{value: 'ankrd46', id: 'ANKRD46'},
{value: 'ankrd49', id: 'ANKRD49'},
{value: 'ankrd50', id: 'ANKRD50'},
{value: 'ankrd52', id: 'ANKRD52'},
{value: 'ankrd53', id: 'ANKRD53'},
{value: 'ankrd54', id: 'ANKRD54'},
{value: 'ankrd55', id: 'ANKRD55'},
{value: 'ankrd6', id: 'ANKRD6'},
{value: 'ankrd61', id: 'ANKRD61'},
{value: 'ankrd63', id: 'ANKRD63'},
{value: 'ankrd65', id: 'ANKRD65'},
{value: 'ankrd66', id: 'ANKRD66'},
{value: 'ankrd7', id: 'ANKRD7'},
{value: 'ankrd9', id: 'ANKRD9'},
{value: 'anks1a', id: 'ANKS1A'},
{value: 'anks1b', id: 'ANKS1B'},
{value: 'anks3', id: 'ANKS3'},
{value: 'anks4b', id: 'ANKS4B'},
{value: 'anks6', id: 'ANKS6'},
{value: 'ankub1', id: 'ANKUB1'},
{value: 'ankzf1', id: 'ANKZF1'},
{value: 'anln', id: 'ANLN'},
{value: 'anlnl1', id: 'ANLNL1'},
{value: 'ano1', id: 'ANO1'},
{value: 'ano10', id: 'ANO10'},
{value: 'ano2', id: 'ANO2'},
{value: 'ano3', id: 'ANO3'},
{value: 'ano4', id: 'ANO4'},
{value: 'ano5', id: 'ANO5'},
{value: 'ano6', id: 'ANO6'},
{value: 'ano7', id: 'ANO7'},
{value: 'ano8', id: 'ANO8'},
{value: 'ano9', id: 'ANO9'},
{value: 'anp32a', id: 'ANP32A'},
{value: 'anp32b', id: 'ANP32B'},
{value: 'anp32e', id: 'ANP32E'},
{value: 'anpep', id: 'ANPEP'},
{value: 'antkmt', id: 'ANTKMT'},
{value: 'antxr1', id: 'ANTXR1'},
{value: 'antxr2', id: 'ANTXR2'},
{value: 'antxrl', id: 'ANTXRL'},
{value: 'anxa1', id: 'ANXA1'},
{value: 'anxa10', id: 'ANXA10'},
{value: 'anxa11', id: 'ANXA11'},
{value: 'anxa13', id: 'ANXA13'},
{value: 'anxa2', id: 'ANXA2'},
{value: 'anxa3', id: 'ANXA3'},
{value: 'anxa4', id: 'ANXA4'},
{value: 'anxa5', id: 'ANXA5'},
{value: 'anxa6', id: 'ANXA6'},
{value: 'anxa7', id: 'ANXA7'},
{value: 'anxa8', id: 'ANXA8'},
{value: 'anxa9', id: 'ANXA9'},
{value: 'aoah', id: 'AOAH'},
{value: 'aoc1', id: 'AOC1'},
{value: 'aoc3', id: 'AOC3'},
{value: 'aopep', id: 'AOPEP'},
{value: 'aox1', id: 'AOX1'},
{value: 'aox2', id: 'AOX2'},
{value: 'aox3', id: 'AOX3'},
{value: 'aox4', id: 'AOX4'},
{value: 'ap1ar', id: 'AP1AR'},
{value: 'ap1b1', id: 'AP1B1'},
{value: 'ap1g1', id: 'AP1G1'},
{value: 'ap1g2', id: 'AP1G2'},
{value: 'ap1m1', id: 'AP1M1'},
{value: 'ap1m2', id: 'AP1M2'},
{value: 'ap1s1', id: 'AP1S1'},
{value: 'ap1s2', id: 'AP1S2'},
{value: 'ap1s3', id: 'AP1S3'},
{value: 'ap2a1', id: 'AP2A1'},
{value: 'ap2a2', id: 'AP2A2'},
{value: 'ap2b1', id: 'AP2B1'},
{value: 'ap2m1', id: 'AP2M1'},
{value: 'ap2s1', id: 'AP2S1'},
{value: 'ap3b1', id: 'AP3B1'},
{value: 'ap3b2', id: 'AP3B2'},
{value: 'ap3d1', id: 'AP3D1'},
{value: 'ap3m1', id: 'AP3M1'},
{value: 'ap3m2', id: 'AP3M2'},
{value: 'ap3s1', id: 'AP3S1'},
{value: 'ap3s2', id: 'AP3S2'},
{value: 'ap4b1', id: 'AP4B1'},
{value: 'ap4e1', id: 'AP4E1'},
{value: 'ap4m1', id: 'AP4M1'},
{value: 'ap4s1', id: 'AP4S1'},
{value: 'ap5b1', id: 'AP5B1'},
{value: 'ap5m1', id: 'AP5M1'},
{value: 'ap5s1', id: 'AP5S1'},
{value: 'ap5z1', id: 'AP5Z1'},
{value: 'apaf1', id: 'APAF1'},
{value: 'apba1', id: 'APBA1'},
{value: 'apba2', id: 'APBA2'},
{value: 'apba3', id: 'APBA3'},
{value: 'apbb1', id: 'APBB1'},
{value: 'apbb1ip', id: 'APBB1IP'},
{value: 'apbb2', id: 'APBB2'},
{value: 'apbb3', id: 'APBB3'},
{value: 'apbh', id: 'APBH'},
{value: 'apc', id: 'APC'},
{value: 'apc2', id: 'APC2'},
{value: 'apcdd1', id: 'APCDD1'},
{value: 'apcdd1l', id: 'APCDD1L'},
{value: 'apcs', id: 'APCS'},
{value: 'apeg3', id: 'APEG3'},
{value: 'apeh', id: 'APEH'},
{value: 'apex1', id: 'APEX1'},
{value: 'apex2', id: 'APEX2'},
{value: 'apex2l1', id: 'APEX2L1'},
{value: 'aph1a', id: 'APH1A'},
{value: 'aph1b', id: 'APH1B'},
{value: 'api5', id: 'API5'},
{value: 'apip', id: 'APIP'},
{value: 'aplf', id: 'APLF'},
{value: 'apln', id: 'APLN'},
{value: 'aplnr', id: 'APLNR'},
{value: 'aplp1', id: 'APLP1'},
{value: 'aplp2', id: 'APLP2'},
{value: 'apmap', id: 'APMAP'},
{value: 'apoa1', id: 'APOA1'},
{value: 'apoa2', id: 'APOA2'},
{value: 'apoa4', id: 'APOA4'},
{value: 'apoa5', id: 'APOA5'},
{value: 'apob', id: 'APOB'},
{value: 'apobec1', id: 'APOBEC1'},
{value: 'apobec2', id: 'APOBEC2'},
{value: 'apobec3', id: 'APOBEC3'},
{value: 'apobec4', id: 'APOBEC4'},
{value: 'apobr', id: 'APOBR'},
{value: 'apoc1', id: 'APOC1'},
{value: 'apoc2', id: 'APOC2'},
{value: 'apoc3', id: 'APOC3'},
{value: 'apoc4', id: 'APOC4'},
{value: 'apod', id: 'APOD'},
{value: 'apoe', id: 'APOE'},
{value: 'apof', id: 'APOF'},
{value: 'apoh', id: 'APOH'},
{value: 'apol11a', id: 'APOL11A'},
{value: 'apol2', id: 'APOL2'},
{value: 'apol3', id: 'APOL3'},
{value: 'apol9a', id: 'APOL9A'},
{value: 'apold1', id: 'APOLD1'},
{value: 'apom', id: 'APOM'},
{value: 'apon', id: 'APON'},
{value: 'apoo', id: 'APOO'},
{value: 'apool', id: 'APOOL'},
{value: 'app', id: 'APP'},
{value: 'appbp2', id: 'APPBP2'},
{value: 'appl1', id: 'APPL1'},
{value: 'appl2', id: 'APPL2'},
{value: 'aprt', id: 'APRT'},
{value: 'aptx', id: 'APTX'},
{value: 'aqp1', id: 'AQP1'},
{value: 'aqp11', id: 'AQP11'},
{value: 'aqp12a', id: 'AQP12A'},
{value: 'aqp2', id: 'AQP2'},
{value: 'aqp3', id: 'AQP3'},
{value: 'aqp4', id: 'AQP4'},
{value: 'aqp5', id: 'AQP5'},
{value: 'aqp6', id: 'AQP6'},
{value: 'aqp7', id: 'AQP7'},
{value: 'aqp8', id: 'AQP8'},
{value: 'aqp9', id: 'AQP9'},
{value: 'aqr', id: 'AQR'},
{value: 'ar', id: 'AR'},
{value: 'araf', id: 'ARAF'},
{value: 'arap1', id: 'ARAP1'},
{value: 'arap2', id: 'ARAP2'},
{value: 'arap3', id: 'ARAP3'},
{value: 'arc', id: 'ARC'},
{value: 'arcn1', id: 'ARCN1'},
{value: 'areg', id: 'AREG'},
{value: 'arel1', id: 'AREL1'},
{value: 'arf1', id: 'ARF1'},
{value: 'arf2', id: 'ARF2'},
{value: 'arf3', id: 'ARF3'},
{value: 'arf4', id: 'ARF4'},
{value: 'arf5', id: 'ARF5'},
{value: 'arf6', id: 'ARF6'},
{value: 'arfgap1', id: 'ARFGAP1'},
{value: 'arfgap2', id: 'ARFGAP2'},
{value: 'arfgap3', id: 'ARFGAP3'},
{value: 'arfgef1', id: 'ARFGEF1'},
{value: 'arfgef2', id: 'ARFGEF2'},
{value: 'arfgef3', id: 'ARFGEF3'},
{value: 'arfip1', id: 'ARFIP1'},
{value: 'arfip2', id: 'ARFIP2'},
{value: 'arfrp1', id: 'ARFRP1'},
{value: 'arg1', id: 'ARG1'},
{value: 'arg2', id: 'ARG2'},
{value: 'arglu1', id: 'ARGLU1'},
{value: 'arhgap1', id: 'ARHGAP1'},
{value: 'arhgap10', id: 'ARHGAP10'},
{value: 'arhgap11a', id: 'ARHGAP11A'},
{value: 'arhgap12', id: 'ARHGAP12'},
{value: 'arhgap15', id: 'ARHGAP15'},
{value: 'arhgap17', id: 'ARHGAP17'},
{value: 'arhgap18', id: 'ARHGAP18'},
{value: 'arhgap19', id: 'ARHGAP19'},
{value: 'arhgap20', id: 'ARHGAP20'},
{value: 'arhgap21', id: 'ARHGAP21'},
{value: 'arhgap22', id: 'ARHGAP22'},
{value: 'arhgap23', id: 'ARHGAP23'},
{value: 'arhgap24', id: 'ARHGAP24'},
{value: 'arhgap25', id: 'ARHGAP25'},
{value: 'arhgap26', id: 'ARHGAP26'},
{value: 'arhgap27', id: 'ARHGAP27'},
{value: 'arhgap28', id: 'ARHGAP28'},
{value: 'arhgap29', id: 'ARHGAP29'},
{value: 'arhgap30', id: 'ARHGAP30'},
{value: 'arhgap31', id: 'ARHGAP31'},
{value: 'arhgap32', id: 'ARHGAP32'},
{value: 'arhgap33', id: 'ARHGAP33'},
{value: 'arhgap35', id: 'ARHGAP35'},
{value: 'arhgap36', id: 'ARHGAP36'},
{value: 'arhgap39', id: 'ARHGAP39'},
{value: 'arhgap4', id: 'ARHGAP4'},
{value: 'arhgap40', id: 'ARHGAP40'},
{value: 'arhgap42', id: 'ARHGAP42'},
{value: 'arhgap44', id: 'ARHGAP44'},
{value: 'arhgap45', id: 'ARHGAP45'},
{value: 'arhgap5', id: 'ARHGAP5'},
{value: 'arhgap6', id: 'ARHGAP6'},
{value: 'arhgap8', id: 'ARHGAP8'},
{value: 'arhgap9', id: 'ARHGAP9'},
{value: 'arhgdia', id: 'ARHGDIA'},
{value: 'arhgdib', id: 'ARHGDIB'},
{value: 'arhgef1', id: 'ARHGEF1'},
{value: 'arhgef10', id: 'ARHGEF10'},
{value: 'arhgef10l', id: 'ARHGEF10L'},
{value: 'arhgef11', id: 'ARHGEF11'},
{value: 'arhgef12', id: 'ARHGEF12'},
{value: 'arhgef15', id: 'ARHGEF15'},
{value: 'arhgef16', id: 'ARHGEF16'},
{value: 'arhgef17', id: 'ARHGEF17'},
{value: 'arhgef18', id: 'ARHGEF18'},
{value: 'arhgef19', id: 'ARHGEF19'},
{value: 'arhgef2', id: 'ARHGEF2'},
{value: 'arhgef25', id: 'ARHGEF25'},
{value: 'arhgef26', id: 'ARHGEF26'},
{value: 'arhgef28', id: 'ARHGEF28'},
{value: 'arhgef3', id: 'ARHGEF3'},
{value: 'arhgef33', id: 'ARHGEF33'},
{value: 'arhgef37', id: 'ARHGEF37'},
{value: 'arhgef38', id: 'ARHGEF38'},
{value: 'arhgef39', id: 'ARHGEF39'},
{value: 'arhgef4', id: 'ARHGEF4'},
{value: 'arhgef40', id: 'ARHGEF40'},
{value: 'arhgef5', id: 'ARHGEF5'},
{value: 'arhgef6', id: 'ARHGEF6'},
{value: 'arhgef7', id: 'ARHGEF7'},
{value: 'arhgef9', id: 'ARHGEF9'},
{value: 'arid1a', id: 'ARID1A'},
{value: 'arid1b', id: 'ARID1B'},
{value: 'arid2', id: 'ARID2'},
{value: 'arid3a', id: 'ARID3A'},
{value: 'arid3b', id: 'ARID3B'},
{value: 'arid3c', id: 'ARID3C'},
{value: 'arid4a', id: 'ARID4A'},
{value: 'arid4b', id: 'ARID4B'},
{value: 'arid5a', id: 'ARID5A'},
{value: 'arid5b', id: 'ARID5B'},
{value: 'arih1', id: 'ARIH1'},
{value: 'arih2', id: 'ARIH2'},
{value: 'arl1', id: 'ARL1'},
{value: 'arl10', id: 'ARL10'},
{value: 'arl11', id: 'ARL11'},
{value: 'arl13a', id: 'ARL13A'},
{value: 'arl13b', id: 'ARL13B'},
{value: 'arl14', id: 'ARL14'},
{value: 'arl14ep', id: 'ARL14EP'},
{value: 'arl14epl', id: 'ARL14EPL'},
{value: 'arl15', id: 'ARL15'},
{value: 'arl16', id: 'ARL16'},
{value: 'arl2', id: 'ARL2'},
{value: 'arl2bp', id: 'ARL2BP'},
{value: 'arl3', id: 'ARL3'},
{value: 'arl4a', id: 'ARL4A'},
{value: 'arl4c', id: 'ARL4C'},
{value: 'arl4d', id: 'ARL4D'},
{value: 'arl5a', id: 'ARL5A'},
{value: 'arl5b', id: 'ARL5B'},
{value: 'arl5c', id: 'ARL5C'},
{value: 'arl6', id: 'ARL6'},
{value: 'arl6ip1', id: 'ARL6IP1'},
{value: 'arl6ip4', id: 'ARL6IP4'},
{value: 'arl6ip5', id: 'ARL6IP5'},
{value: 'arl6ip6', id: 'ARL6IP6'},
{value: 'arl8a', id: 'ARL8A'},
{value: 'arl8b', id: 'ARL8B'},
{value: 'arl9', id: 'ARL9'},
{value: 'armc1', id: 'ARMC1'},
{value: 'armc10', id: 'ARMC10'},
{value: 'armc12', id: 'ARMC12'},
{value: 'armc2', id: 'ARMC2'},
{value: 'armc3', id: 'ARMC3'},
{value: 'armc5', id: 'ARMC5'},
{value: 'armc6', id: 'ARMC6'},
{value: 'armc7', id: 'ARMC7'},
{value: 'armc8', id: 'ARMC8'},
{value: 'armc9', id: 'ARMC9'},
{value: 'armcx1', id: 'ARMCX1'},
{value: 'armcx2', id: 'ARMCX2'},
{value: 'armcx3', id: 'ARMCX3'},
{value: 'armcx4', id: 'ARMCX4'},
{value: 'armcx5', id: 'ARMCX5'},
{value: 'armcx6', id: 'ARMCX6'},
{value: 'armh1', id: 'ARMH1'},
{value: 'armh2', id: 'ARMH2'},
{value: 'armh3', id: 'ARMH3'},
{value: 'armh4', id: 'ARMH4'},
{value: 'armt1', id: 'ARMT1'},
{value: 'arnt', id: 'ARNT'},
{value: 'arnt2', id: 'ARNT2'},
{value: 'arntl', id: 'ARNTL'},
{value: 'arntl2', id: 'ARNTL2'},
{value: 'arpc1a', id: 'ARPC1A'},
{value: 'arpc1b', id: 'ARPC1B'},
{value: 'arpc2', id: 'ARPC2'},
{value: 'arpc3', id: 'ARPC3'},
{value: 'arpc4', id: 'ARPC4'},
{value: 'arpc5', id: 'ARPC5'},
{value: 'arpc5l', id: 'ARPC5L'},
{value: 'arpin', id: 'ARPIN'},
{value: 'arpp19', id: 'ARPP19'},
{value: 'arpp21', id: 'ARPP21'},
{value: 'arr3', id: 'ARR3'},
{value: 'arrb1', id: 'ARRB1'},
{value: 'arrb2', id: 'ARRB2'},
{value: 'arrdc1', id: 'ARRDC1'},
{value: 'arrdc2', id: 'ARRDC2'},
{value: 'arrdc3', id: 'ARRDC3'},
{value: 'arrdc4', id: 'ARRDC4'},
{value: 'arrdc5', id: 'ARRDC5'},
{value: 'arsa', id: 'ARSA'},
{value: 'arsb', id: 'ARSB'},
{value: 'arsg', id: 'ARSG'},
{value: 'arsi', id: 'ARSI'},
{value: 'arsk', id: 'ARSK'},
{value: 'arsl', id: 'ARSL'},
{value: 'art1', id: 'ART1'},
{value: 'art2b', id: 'ART2B'},
{value: 'art3', id: 'ART3'},
{value: 'art4', id: 'ART4'},
{value: 'art5', id: 'ART5'},
{value: 'artn', id: 'ARTN'},
{value: 'arv1', id: 'ARV1'},
{value: 'arvcf', id: 'ARVCF'},
{value: 'arx', id: 'ARX'},
{value: 'arxes2', id: 'ARXES2'},
{value: 'as3mt', id: 'AS3MT'},
{value: 'asah1', id: 'ASAH1'},
{value: 'asah2', id: 'ASAH2'},
{value: 'asap1', id: 'ASAP1'},
{value: 'asap2', id: 'ASAP2'},
{value: 'asap3', id: 'ASAP3'},
{value: 'asb1', id: 'ASB1'},
{value: 'asb10', id: 'ASB10'},
{value: 'asb11', id: 'ASB11'},
{value: 'asb12', id: 'ASB12'},
{value: 'asb13', id: 'ASB13'},
{value: 'asb14', id: 'ASB14'},
{value: 'asb15', id: 'ASB15'},
{value: 'asb16', id: 'ASB16'},
{value: 'asb17', id: 'ASB17'},
{value: 'asb18', id: 'ASB18'},
{value: 'asb2', id: 'ASB2'},
{value: 'asb3', id: 'ASB3'},
{value: 'asb4', id: 'ASB4'},
{value: 'asb5', id: 'ASB5'},
{value: 'asb6', id: 'ASB6'},
{value: 'asb7', id: 'ASB7'},
{value: 'asb8', id: 'ASB8'},
{value: 'asb9', id: 'ASB9'},
{value: 'ascc1', id: 'ASCC1'},
{value: 'ascc2', id: 'ASCC2'},
{value: 'ascc3', id: 'ASCC3'},
{value: 'ascl1', id: 'ASCL1'},
{value: 'ascl2', id: 'ASCL2'},
{value: 'ascl4', id: 'ASCL4'},
{value: 'ascl5', id: 'ASCL5'},
{value: 'asf1a', id: 'ASF1A'},
{value: 'asf1b', id: 'ASF1B'},
{value: 'asgr1', id: 'ASGR1'},
{value: 'asgr2', id: 'ASGR2'},
{value: 'ash1l', id: 'ASH1L'},
{value: 'ash2l', id: 'ASH2L'},
{value: 'asic1', id: 'ASIC1'},
{value: 'asic2', id: 'ASIC2'},
{value: 'asic3', id: 'ASIC3'},
{value: 'asic4', id: 'ASIC4'},
{value: 'asic5', id: 'ASIC5'},
{value: 'asip', id: 'ASIP'},
{value: 'asl', id: 'ASL'},
{value: 'asmt', id: 'ASMT'},
{value: 'asmtl', id: 'ASMTL'},
{value: 'asns', id: 'ASNS'},
{value: 'asnsd1', id: 'ASNSD1'},
{value: 'aspa', id: 'ASPA'},
{value: 'aspdh', id: 'ASPDH'},
{value: 'aspg', id: 'ASPG'},
{value: 'asph', id: 'ASPH'},
{value: 'asphd1', id: 'ASPHD1'},
{value: 'asphd2', id: 'ASPHD2'},
{value: 'aspm', id: 'ASPM'},
{value: 'aspn', id: 'ASPN'},
{value: 'asprv1', id: 'ASPRV1'},
{value: 'aspscr1', id: 'ASPSCR1'},
{value: 'asrgl1', id: 'ASRGL1'},
{value: 'ass1', id: 'ASS1'},
{value: 'aste1', id: 'ASTE1'},
{value: 'astl', id: 'ASTL'},
{value: 'astn1', id: 'ASTN1'},
{value: 'astn2', id: 'ASTN2'},
{value: 'asxl1', id: 'ASXL1'},
{value: 'asxl2', id: 'ASXL2'},
{value: 'asxl3', id: 'ASXL3'},
{value: 'asz1', id: 'ASZ1'},
{value: 'atad1', id: 'ATAD1'},
{value: 'atad2', id: 'ATAD2'},
{value: 'atad2b', id: 'ATAD2B'},
{value: 'atad3a', id: 'ATAD3A'},
{value: 'atad5', id: 'ATAD5'},
{value: 'atat1', id: 'ATAT1'},
{value: 'atcay', id: 'ATCAY'},
{value: 'ate1', id: 'ATE1'},
{value: 'atf1', id: 'ATF1'},
{value: 'atf2', id: 'ATF2'},
{value: 'atf3', id: 'ATF3'},
{value: 'atf4', id: 'ATF4'},
{value: 'atf5', id: 'ATF5'},
{value: 'atf6', id: 'ATF6'},
{value: 'atf6b', id: 'ATF6B'},
{value: 'atf7', id: 'ATF7'},
{value: 'atf7ip', id: 'ATF7IP'},
{value: 'atf7ip2', id: 'ATF7IP2'},
{value: 'atg10', id: 'ATG10'},
{value: 'atg101', id: 'ATG101'},
{value: 'atg12', id: 'ATG12'},
{value: 'atg13', id: 'ATG13'},
{value: 'atg14', id: 'ATG14'},
{value: 'atg16l1', id: 'ATG16L1'},
{value: 'atg16l2', id: 'ATG16L2'},
{value: 'atg2a', id: 'ATG2A'},
{value: 'atg2b', id: 'ATG2B'},
{value: 'atg3', id: 'ATG3'},
{value: 'atg4a', id: 'ATG4A'},
{value: 'atg4b', id: 'ATG4B'},
{value: 'atg4c', id: 'ATG4C'},
{value: 'atg4d', id: 'ATG4D'},
{value: 'atg5', id: 'ATG5'},
{value: 'atg7', id: 'ATG7'},
{value: 'atg9a', id: 'ATG9A'},
{value: 'atg9b', id: 'ATG9B'},
{value: 'atic', id: 'ATIC'},
{value: 'atl1', id: 'ATL1'},
{value: 'atl2', id: 'ATL2'},
{value: 'atl3', id: 'ATL3'},
{value: 'atm', id: 'ATM'},
{value: 'atmin', id: 'ATMIN'},
{value: 'atn1', id: 'ATN1'},
{value: 'atoh1', id: 'ATOH1'},
{value: 'atoh7', id: 'ATOH7'},
{value: 'atoh8', id: 'ATOH8'},
{value: 'atox1', id: 'ATOX1'},
{value: 'atp10a', id: 'ATP10A'},
{value: 'atp10b', id: 'ATP10B'},
{value: 'atp10d', id: 'ATP10D'},
{value: 'atp11a', id: 'ATP11A'},
{value: 'atp11b', id: 'ATP11B'},
{value: 'atp11c', id: 'ATP11C'},
{value: 'atp12a', id: 'ATP12A'},
{value: 'atp13a1', id: 'ATP13A1'},
{value: 'atp13a2', id: 'ATP13A2'},
{value: 'atp13a3', id: 'ATP13A3'},
{value: 'atp13a4', id: 'ATP13A4'},
{value: 'atp13a5', id: 'ATP13A5'},
{value: 'atp1a1', id: 'ATP1A1'},
{value: 'atp1a2', id: 'ATP1A2'},
{value: 'atp1a3', id: 'ATP1A3'},
{value: 'atp1a4', id: 'ATP1A4'},
{value: 'atp1b1', id: 'ATP1B1'},
{value: 'atp1b2', id: 'ATP1B2'},
{value: 'atp1b3', id: 'ATP1B3'},
{value: 'atp1b4', id: 'ATP1B4'},
{value: 'atp23', id: 'ATP23'},
{value: 'atp2a1', id: 'ATP2A1'},
{value: 'atp2a2', id: 'ATP2A2'},
{value: 'atp2a3', id: 'ATP2A3'},
{value: 'atp2b1', id: 'ATP2B1'},
{value: 'atp2b2', id: 'ATP2B2'},
{value: 'atp2b3', id: 'ATP2B3'},
{value: 'atp2b4', id: 'ATP2B4'},
{value: 'atp2c1', id: 'ATP2C1'},
{value: 'atp2c2', id: 'ATP2C2'},
{value: 'atp4a', id: 'ATP4A'},
{value: 'atp4b', id: 'ATP4B'},
{value: 'atp5f1a', id: 'ATP5F1A'},
{value: 'atp5f1b', id: 'ATP5F1B'},
{value: 'atp5f1c', id: 'ATP5F1C'},
{value: 'atp5f1d', id: 'ATP5F1D'},
{value: 'atp5f1e', id: 'ATP5F1E'},
{value: 'atp5hl1', id: 'ATP5HL1'},
{value: 'atp5if1', id: 'ATP5IF1'},
{value: 'atp5mc1', id: 'ATP5MC1'},
{value: 'atp5mc2', id: 'ATP5MC2'},
{value: 'atp5mc3', id: 'ATP5MC3'},
{value: 'atp5me', id: 'ATP5ME'},
{value: 'atp5mf', id: 'ATP5MF'},
{value: 'atp5mg', id: 'ATP5MG'},
{value: 'atp5mk', id: 'ATP5MK'},
{value: 'atp5pb', id: 'ATP5PB'},
{value: 'atp5pd', id: 'ATP5PD'},
{value: 'atp5pf', id: 'ATP5PF'},
{value: 'atp5po', id: 'ATP5PO'},
{value: 'atp6ap1', id: 'ATP6AP1'},
{value: 'atp6ap1l', id: 'ATP6AP1L'},
{value: 'atp6ap2', id: 'ATP6AP2'},
{value: 'atp6v0a1', id: 'ATP6V0A1'},
{value: 'atp6v0a2', id: 'ATP6V0A2'},
{value: 'atp6v0a4', id: 'ATP6V0A4'},
{value: 'atp6v0b', id: 'ATP6V0B'},
{value: 'atp6v0c', id: 'ATP6V0C'},
{value: 'atp6v0d1', id: 'ATP6V0D1'},
{value: 'atp6v0d2', id: 'ATP6V0D2'},
{value: 'atp6v0e1', id: 'ATP6V0E1'},
{value: 'atp6v0e2', id: 'ATP6V0E2'},
{value: 'atp6v1a', id: 'ATP6V1A'},
{value: 'atp6v1b1', id: 'ATP6V1B1'},
{value: 'atp6v1b2', id: 'ATP6V1B2'},
{value: 'atp6v1c1', id: 'ATP6V1C1'},
{value: 'atp6v1c2', id: 'ATP6V1C2'},
{value: 'atp6v1d', id: 'ATP6V1D'},
{value: 'atp6v1e1', id: 'ATP6V1E1'},
{value: 'atp6v1e2', id: 'ATP6V1E2'},
{value: 'atp6v1f', id: 'ATP6V1F'},
{value: 'atp6v1fnb', id: 'ATP6V1FNB'},
{value: 'atp6v1g1', id: 'ATP6V1G1'},
{value: 'atp6v1g2', id: 'ATP6V1G2'},
{value: 'atp6v1g3', id: 'ATP6V1G3'},
{value: 'atp6v1h', id: 'ATP6V1H'},
{value: 'atp7a', id: 'ATP7A'},
{value: 'atp7b', id: 'ATP7B'},
{value: 'atp8a1', id: 'ATP8A1'},
{value: 'atp8a2', id: 'ATP8A2'},
{value: 'atp8b1', id: 'ATP8B1'},
{value: 'atp8b2', id: 'ATP8B2'},
{value: 'atp8b3', id: 'ATP8B3'},
{value: 'atp8b4', id: 'ATP8B4'},
{value: 'atp8b5p', id: 'ATP8B5P'},
{value: 'atp9a', id: 'ATP9A'},
{value: 'atp9b', id: 'ATP9B'},
{value: 'atpaf1', id: 'ATPAF1'},
{value: 'atpaf2', id: 'ATPAF2'},
{value: 'atpsckmt', id: 'ATPSCKMT'},
{value: 'atr', id: 'ATR'},
{value: 'atraid', id: 'ATRAID'},
{value: 'atrip', id: 'ATRIP'},
{value: 'atrn', id: 'ATRN'},
{value: 'atrnl1', id: 'ATRNL1'},
{value: 'atrx', id: 'ATRX'},
{value: 'atxn1', id: 'ATXN1'},
{value: 'atxn10', id: 'ATXN10'},
{value: 'atxn2', id: 'ATXN2'},
{value: 'atxn2l', id: 'ATXN2L'},
{value: 'atxn3', id: 'ATXN3'},
{value: 'atxn7', id: 'ATXN7'},
{value: 'atxn7l1', id: 'ATXN7L1'},
{value: 'atxn7l2', id: 'ATXN7L2'},
{value: 'atxn7l3', id: 'ATXN7L3'},
{value: 'atxn7l3b', id: 'ATXN7L3B'},
{value: 'auh', id: 'AUH'},
{value: 'aunip', id: 'AUNIP'},
{value: 'aup1', id: 'AUP1'},
{value: 'aurka', id: 'AURKA'},
{value: 'aurkaip1', id: 'AURKAIP1'},
{value: 'aurkb', id: 'AURKB'},
{value: 'aurkc', id: 'AURKC'},
{value: 'auts2', id: 'AUTS2'},
{value: 'aven', id: 'AVEN'},
{value: 'avil', id: 'AVIL'},
{value: 'avl9', id: 'AVL9'},
{value: 'avp', id: 'AVP'},
{value: 'avpi1', id: 'AVPI1'},
{value: 'avpr1a', id: 'AVPR1A'},
{value: 'avpr1b', id: 'AVPR1B'},
{value: 'avpr2', id: 'AVPR2'},
{value: 'awat1', id: 'AWAT1'},
{value: 'awat2', id: 'AWAT2'},
{value: 'axdnd1', id: 'AXDND1'},
{value: 'axin1', id: 'AXIN1'},
{value: 'axin2', id: 'AXIN2'},
{value: 'axl', id: 'AXL'},
{value: 'ay172581.15', id: 'AY172581.15'},
{value: 'ay172581.19', id: 'AY172581.19'},
{value: 'ay172581.2', id: 'AY172581.2'},
{value: 'ay172581.24', id: 'AY172581.24'},
{value: 'ay172581.9', id: 'AY172581.9'},
{value: 'azgp1', id: 'AZGP1'},
{value: 'azi2', id: 'AZI2'},
{value: 'azin1', id: 'AZIN1'},
{value: 'azin2', id: 'AZIN2'},
{value: 'b2m', id: 'B2M'},
{value: 'b3galnt1', id: 'B3GALNT1'},
{value: 'b3galnt2', id: 'B3GALNT2'},
{value: 'b3galt1', id: 'B3GALT1'},
{value: 'b3galt2', id: 'B3GALT2'},
{value: 'b3galt4', id: 'B3GALT4'},
{value: 'b3galt5', id: 'B3GALT5'},
{value: 'b3galt6', id: 'B3GALT6'},
{value: 'b3gat1', id: 'B3GAT1'},
{value: 'b3gat2', id: 'B3GAT2'},
{value: 'b3gat3', id: 'B3GAT3'},
{value: 'b3glct', id: 'B3GLCT'},
{value: 'b3gnt2', id: 'B3GNT2'},
{value: 'b3gnt3', id: 'B3GNT3'},
{value: 'b3gnt4', id: 'B3GNT4'},
{value: 'b3gnt5', id: 'B3GNT5'},
{value: 'b3gnt6', id: 'B3GNT6'},
{value: 'b3gnt7', id: 'B3GNT7'},
{value: 'b3gnt8', id: 'B3GNT8'},
{value: 'b3gnt9', id: 'B3GNT9'},
{value: 'b3gntl1', id: 'B3GNTL1'},
{value: 'b4galnt1', id: 'B4GALNT1'},
{value: 'b4galnt2', id: 'B4GALNT2'},
{value: 'b4galnt3', id: 'B4GALNT3'},
{value: 'b4galnt4', id: 'B4GALNT4'},
{value: 'b4galt1', id: 'B4GALT1'},
{value: 'b4galt2', id: 'B4GALT2'},
{value: 'b4galt3', id: 'B4GALT3'},
{value: 'b4galt4', id: 'B4GALT4'},
{value: 'b4galt5', id: 'B4GALT5'},
{value: 'b4galt6', id: 'B4GALT6'},
{value: 'b4galt7', id: 'B4GALT7'},
{value: 'b4gat1', id: 'B4GAT1'},
{value: 'b630019k06rik', id: 'B630019K06RIK'},
{value: 'b9d1', id: 'B9D1'},
{value: 'b9d2', id: 'B9D2'},
{value: 'baalc', id: 'BAALC'},
{value: 'baat', id: 'BAAT'},
{value: 'babam1', id: 'BABAM1'},
{value: 'babam2', id: 'BABAM2'},
{value: 'bace1', id: 'BACE1'},
{value: 'bace2', id: 'BACE2'},
{value: 'bach1', id: 'BACH1'},
{value: 'bach2', id: 'BACH2'},
{value: 'bad', id: 'BAD'},
{value: 'bag1', id: 'BAG1'},
{value: 'bag2', id: 'BAG2'},
{value: 'bag3', id: 'BAG3'},
{value: 'bag4', id: 'BAG4'},
{value: 'bag5', id: 'BAG5'},
{value: 'bag6', id: 'BAG6'},
{value: 'bahcc1', id: 'BAHCC1'},
{value: 'bahd1', id: 'BAHD1'},
{value: 'baiap2', id: 'BAIAP2'},
{value: 'baiap2l1', id: 'BAIAP2L1'},
{value: 'baiap2l2', id: 'BAIAP2L2'},
{value: 'baiap3', id: 'BAIAP3'},
{value: 'bak1', id: 'BAK1'},
{value: 'bambi', id: 'BAMBI'},
{value: 'banf1', id: 'BANF1'},
{value: 'banf2', id: 'BANF2'},
{value: 'bank1', id: 'BANK1'},
{value: 'banp', id: 'BANP'},
{value: 'bap1', id: 'BAP1'},
{value: 'bard1', id: 'BARD1'},
{value: 'barhl1', id: 'BARHL1'},
{value: 'barhl2', id: 'BARHL2'},
{value: 'barx1', id: 'BARX1'},
{value: 'barx2', id: 'BARX2'},
{value: 'basp1', id: 'BASP1'},
{value: 'batf', id: 'BATF'},
{value: 'batf2', id: 'BATF2'},
{value: 'batf3', id: 'BATF3'},
{value: 'bax', id: 'BAX'},
{value: 'baz1a', id: 'BAZ1A'},
{value: 'baz1b', id: 'BAZ1B'},
{value: 'baz2a', id: 'BAZ2A'},
{value: 'baz2b', id: 'BAZ2B'},
{value: 'bbc3', id: 'BBC3'},
{value: 'bbip1', id: 'BBIP1'},
{value: 'bbln', id: 'BBLN'},
{value: 'bbof1', id: 'BBOF1'},
{value: 'bbox1', id: 'BBOX1'},
{value: 'bbs1', id: 'BBS1'},
{value: 'bbs10', id: 'BBS10'},
{value: 'bbs12', id: 'BBS12'},
{value: 'bbs2', id: 'BBS2'},
{value: 'bbs4', id: 'BBS4'},
{value: 'bbs5', id: 'BBS5'},
{value: 'bbs7', id: 'BBS7'},
{value: 'bbs9', id: 'BBS9'},
{value: 'bbx', id: 'BBX'},
{value: 'bcam', id: 'BCAM'},
{value: 'bcan', id: 'BCAN'},
{value: 'bcap29', id: 'BCAP29'},
{value: 'bcap31', id: 'BCAP31'},
{value: 'bcar1', id: 'BCAR1'},
{value: 'bcar3', id: 'BCAR3'},
{value: 'bcas1', id: 'BCAS1'},
{value: 'bcas2', id: 'BCAS2'},
{value: 'bcas3', id: 'BCAS3'},
{value: 'bcat1', id: 'BCAT1'},
{value: 'bcat2', id: 'BCAT2'},
{value: 'bccip', id: 'BCCIP'},
{value: 'bcdin3d', id: 'BCDIN3D'},
{value: 'bche', id: 'BCHE'},
{value: 'bckdha', id: 'BCKDHA'},
{value: 'bckdhb', id: 'BCKDHB'},
{value: 'bckdk', id: 'BCKDK'},
{value: 'bcl10', id: 'BCL10'},
{value: 'bcl11a', id: 'BCL11A'},
{value: 'bcl11b', id: 'BCL11B'},
{value: 'bcl2', id: 'BCL2'},
{value: 'bcl2a1', id: 'BCL2A1'},
{value: 'bcl2l1', id: 'BCL2L1'},
{value: 'bcl2l10', id: 'BCL2L10'},
{value: 'bcl2l11', id: 'BCL2L11'},
{value: 'bcl2l12', id: 'BCL2L12'},
{value: 'bcl2l13', id: 'BCL2L13'},
{value: 'bcl2l14', id: 'BCL2L14'},
{value: 'bcl2l15', id: 'BCL2L15'},
{value: 'bcl2l2', id: 'BCL2L2'},
{value: 'bcl3', id: 'BCL3'},
{value: 'bcl6', id: 'BCL6'},
{value: 'bcl6b', id: 'BCL6B'},
{value: 'bcl7a', id: 'BCL7A'},
{value: 'bcl7b', id: 'BCL7B'},
{value: 'bcl7c', id: 'BCL7C'},
{value: 'bcl9', id: 'BCL9'},
{value: 'bcl9l', id: 'BCL9L'},
{value: 'bclaf1', id: 'BCLAF1'},
{value: 'bclaf3', id: 'BCLAF3'},
{value: 'bco1', id: 'BCO1'},
{value: 'bco2', id: 'BCO2'},
{value: 'bcor', id: 'BCOR'},
{value: 'bcorl1', id: 'BCORL1'},
{value: 'bcr', id: 'BCR'},
{value: 'bcs1l', id: 'BCS1L'},
{value: 'bdh1', id: 'BDH1'},
{value: 'bdh2', id: 'BDH2'},
{value: 'bdkrb1', id: 'BDKRB1'},
{value: 'bdkrb2', id: 'BDKRB2'},
{value: 'bdnf', id: 'BDNF'},
{value: 'bdp1', id: 'BDP1'},
{value: 'bean1', id: 'BEAN1'},
{value: 'becn1', id: 'BECN1'},
{value: 'becn2', id: 'BECN2'},
{value: 'begain', id: 'BEGAIN'},
{value: 'bend3', id: 'BEND3'},
{value: 'bend4', id: 'BEND4'},
{value: 'bend5', id: 'BEND5'},
{value: 'bend6', id: 'BEND6'},
{value: 'bend7', id: 'BEND7'},
{value: 'best1', id: 'BEST1'},
{value: 'best2', id: 'BEST2'},
{value: 'best3', id: 'BEST3'},
{value: 'best4', id: 'BEST4'},
{value: 'bet1', id: 'BET1'},
{value: 'bet1l', id: 'BET1L'},
{value: 'bex1', id: 'BEX1'},
{value: 'bex2', id: 'BEX2'},
{value: 'bex3', id: 'BEX3'},
{value: 'bex4', id: 'BEX4'},
{value: 'bfar', id: 'BFAR'},
{value: 'bfsp1', id: 'BFSP1'},
{value: 'bfsp2', id: 'BFSP2'},
{value: 'bglap', id: 'BGLAP'},
{value: 'bgn', id: 'BGN'},
{value: 'bhlha15', id: 'BHLHA15'},
{value: 'bhlha9', id: 'BHLHA9'},
{value: 'bhlhb9', id: 'BHLHB9'},
{value: 'bhlhe22', id: 'BHLHE22'},
{value: 'bhlhe23', id: 'BHLHE23'},
{value: 'bhlhe40', id: 'BHLHE40'},
{value: 'bhlhe41', id: 'BHLHE41'},
{value: 'bhmt', id: 'BHMT'},
{value: 'bhmt2', id: 'BHMT2'},
{value: 'bicc1', id: 'BICC1'},
{value: 'bicd1', id: 'BICD1'},
{value: 'bicd2', id: 'BICD2'},
{value: 'bicdl1', id: 'BICDL1'},
{value: 'bicdl2', id: 'BICDL2'},
{value: 'bicra', id: 'BICRA'},
{value: 'bicral', id: 'BICRAL'},
{value: 'bid', id: 'BID'},
{value: 'bik', id: 'BIK'},
{value: 'bin1', id: 'BIN1'},
{value: 'bin2', id: 'BIN2'},
{value: 'bin2a', id: 'BIN2A'},
{value: 'bin3', id: 'BIN3'},
{value: 'birc2', id: 'BIRC2'},
{value: 'birc3', id: 'BIRC3'},
{value: 'birc5', id: 'BIRC5'},
{value: 'birc6', id: 'BIRC6'},
{value: 'birc7', id: 'BIRC7'},
{value: 'bivm', id: 'BIVM'},
{value: 'blcap', id: 'BLCAP'},
{value: 'bles03', id: 'BLES03'},
{value: 'blk', id: 'BLK'},
{value: 'blm', id: 'BLM'},
{value: 'blmh', id: 'BLMH'},
{value: 'blnk', id: 'BLNK'},
{value: 'bloc1s1', id: 'BLOC1S1'},
{value: 'bloc1s2', id: 'BLOC1S2'},
{value: 'bloc1s3', id: 'BLOC1S3'},
{value: 'bloc1s4', id: 'BLOC1S4'},
{value: 'bloc1s5', id: 'BLOC1S5'},
{value: 'bloc1s6', id: 'BLOC1S6'},
{value: 'blvra', id: 'BLVRA'},
{value: 'blvrb', id: 'BLVRB'},
{value: 'blzf1', id: 'BLZF1'},
{value: 'bmerb1', id: 'BMERB1'},
{value: 'bmf', id: 'BMF'},
{value: 'bmi1', id: 'BMI1'},
{value: 'bmp1', id: 'BMP1'},
{value: 'bmp10', id: 'BMP10'},
{value: 'bmp15', id: 'BMP15'},
{value: 'bmp2', id: 'BMP2'},
{value: 'bmp2k', id: 'BMP2K'},
{value: 'bmp3', id: 'BMP3'},
{value: 'bmp4', id: 'BMP4'},
{value: 'bmp5', id: 'BMP5'},
{value: 'bmp6', id: 'BMP6'},
{value: 'bmp7', id: 'BMP7'},
{value: 'bmp8a', id: 'BMP8A'},
{value: 'bmp8b', id: 'BMP8B'},
{value: 'bmper', id: 'BMPER'},
{value: 'bmpr1a', id: 'BMPR1A'},
{value: 'bmpr1b', id: 'BMPR1B'},
{value: 'bmpr2', id: 'BMPR2'},
{value: 'bms1', id: 'BMS1'},
{value: 'bmt2', id: 'BMT2'},
{value: 'bmx', id: 'BMX'},
{value: 'bmyc', id: 'BMYC'},
{value: 'bnc1', id: 'BNC1'},
{value: 'bnc2', id: 'BNC2'},
{value: 'bnip1', id: 'BNIP1'},
{value: 'bnip2', id: 'BNIP2'},
{value: 'bnip3', id: 'BNIP3'},
{value: 'bnip3l', id: 'BNIP3L'},
{value: 'bnip5', id: 'BNIP5'},
{value: 'bnipl', id: 'BNIPL'},
{value: 'boc', id: 'BOC'},
{value: 'bod1', id: 'BOD1'},
{value: 'bod1l1', id: 'BOD1L1'},
{value: 'bok', id: 'BOK'},
{value: 'bola1', id: 'BOLA1'},
{value: 'bola2-ps1', id: 'BOLA2-PS1'},
{value: 'bola2', id: 'BOLA2'},
{value: 'bola3', id: 'BOLA3'},
{value: 'boll', id: 'BOLL'},
{value: 'bop1', id: 'BOP1'},
{value: 'bora', id: 'BORA'},
{value: 'borcs5', id: 'BORCS5'},
{value: 'borcs6', id: 'BORCS6'},
{value: 'borcs7', id: 'BORCS7'},
{value: 'borcs8', id: 'BORCS8'},
{value: 'bpgm', id: 'BPGM'},
{value: 'bphl', id: 'BPHL'},
{value: 'bpi', id: 'BPI'},
{value: 'bpifa1', id: 'BPIFA1'},
{value: 'bpifa2', id: 'BPIFA2'},
{value: 'bpifa2f', id: 'BPIFA2F'},
{value: 'bpifa3', id: 'BPIFA3'},
{value: 'bpifa5', id: 'BPIFA5'},
{value: 'bpifa6', id: 'BPIFA6'},
{value: 'bpifb1', id: 'BPIFB1'},
{value: 'bpifb2', id: 'BPIFB2'},
{value: 'bpifb3', id: 'BPIFB3'},
{value: 'bpifb4', id: 'BPIFB4'},
{value: 'bpifb5', id: 'BPIFB5'},
{value: 'bpifb6', id: 'BPIFB6'},
{value: 'bpifc', id: 'BPIFC'},
{value: 'bpnt1', id: 'BPNT1'},
{value: 'bpnt2', id: 'BPNT2'},
{value: 'bptf', id: 'BPTF'},
{value: 'braf', id: 'BRAF'},
{value: 'brap', id: 'BRAP'},
{value: 'brat1', id: 'BRAT1'},
{value: 'brca1', id: 'BRCA1'},
{value: 'brca2', id: 'BRCA2'},
{value: 'brcc3', id: 'BRCC3'},
{value: 'brd1', id: 'BRD1'},
{value: 'brd2', id: 'BRD2'},
{value: 'brd3', id: 'BRD3'},
{value: 'brd4', id: 'BRD4'},
{value: 'brd7', id: 'BRD7'},
{value: 'brd8', id: 'BRD8'},
{value: 'brd8dc', id: 'BRD8DC'},
{value: 'brd9', id: 'BRD9'},
{value: 'brdt', id: 'BRDT'},
{value: 'brf1', id: 'BRF1'},
{value: 'brf2', id: 'BRF2'},
{value: 'bri3', id: 'BRI3'},
{value: 'bri3bp', id: 'BRI3BP'},
{value: 'bricd5', id: 'BRICD5'},
{value: 'brinp1', id: 'BRINP1'},
{value: 'brinp2', id: 'BRINP2'},
{value: 'brinp3', id: 'BRINP3'},
{value: 'brip1', id: 'BRIP1'},
{value: 'brix1', id: 'BRIX1'},
{value: 'brk1', id: 'BRK1'},
{value: 'brme1', id: 'BRME1'},
{value: 'brms1', id: 'BRMS1'},
{value: 'brms1l', id: 'BRMS1L'},
{value: 'brox', id: 'BROX'},
{value: 'brpf1', id: 'BRPF1'},
{value: 'brpf3', id: 'BRPF3'},
{value: 'brs3', id: 'BRS3'},
{value: 'brsk1', id: 'BRSK1'},
{value: 'brsk2', id: 'BRSK2'},
{value: 'brwd1', id: 'BRWD1'},
{value: 'brwd3', id: 'BRWD3'},
{value: 'bscl2', id: 'BSCL2'},
{value: 'bsdc1', id: 'BSDC1'},
{value: 'bsg', id: 'BSG'},
{value: 'bsn', id: 'BSN'},
{value: 'bsnd', id: 'BSND'},
{value: 'bsph1', id: 'BSPH1'},
{value: 'bsph2', id: 'BSPH2'},
{value: 'bspry', id: 'BSPRY'},
{value: 'bst1', id: 'BST1'},
{value: 'bst2', id: 'BST2'},
{value: 'bsx', id: 'BSX'},
{value: 'btaf1', id: 'BTAF1'},
{value: 'btbd1', id: 'BTBD1'},
{value: 'btbd10', id: 'BTBD10'},
{value: 'btbd11', id: 'BTBD11'},
{value: 'btbd16', id: 'BTBD16'},
{value: 'btbd17', id: 'BTBD17'},
{value: 'btbd18', id: 'BTBD18'},
{value: 'btbd19', id: 'BTBD19'},
{value: 'btbd2', id: 'BTBD2'},
{value: 'btbd3', id: 'BTBD3'},
{value: 'btbd6', id: 'BTBD6'},
{value: 'btbd7', id: 'BTBD7'},
{value: 'btbd8', id: 'BTBD8'},
{value: 'btbd9', id: 'BTBD9'},
{value: 'btc', id: 'BTC'},
{value: 'btd', id: 'BTD'},
{value: 'btf3', id: 'BTF3'},
{value: 'btf3l4', id: 'BTF3L4'},
{value: 'btg1', id: 'BTG1'},
{value: 'btg2', id: 'BTG2'},
{value: 'btg3', id: 'BTG3'},
{value: 'btg4', id: 'BTG4'},
{value: 'btk', id: 'BTK'},
{value: 'btla', id: 'BTLA'},
{value: 'btn1a1', id: 'BTN1A1'},
{value: 'btn2a2', id: 'BTN2A2'},
{value: 'btnl10', id: 'BTNL10'},
{value: 'btnl2', id: 'BTNL2'},
{value: 'btnl3', id: 'BTNL3'},
{value: 'btnl5', id: 'BTNL5'},
{value: 'btnl7', id: 'BTNL7'},
{value: 'btnl8', id: 'BTNL8'},
{value: 'btnl9', id: 'BTNL9'},
{value: 'btrc', id: 'BTRC'},
{value: 'bub1', id: 'BUB1'},
{value: 'bub1b', id: 'BUB1B'},
{value: 'bub3', id: 'BUB3'},
{value: 'bud13', id: 'BUD13'},
{value: 'bud23', id: 'BUD23'},
{value: 'bud31', id: 'BUD31'},
{value: 'bves', id: 'BVES'},
{value: 'bysl', id: 'BYSL'},
{value: 'bzw1', id: 'BZW1'},
{value: 'bzw2', id: 'BZW2'},
{value: 'c10h10orf95', id: 'C10H10ORF95'},
{value: 'c12h7orf61', id: 'C12H7ORF61'},
{value: 'c17h6orf52', id: 'C17H6ORF52'},
{value: 'c1d', id: 'C1D'},
{value: 'c1galt1', id: 'C1GALT1'},
{value: 'c1galt1c1', id: 'C1GALT1C1'},
{value: 'c1h9orf66', id: 'C1H9ORF66'},
{value: 'c1qa', id: 'C1QA'},
{value: 'c1qb', id: 'C1QB'},
{value: 'c1qbp', id: 'C1QBP'},
{value: 'c1qc', id: 'C1QC'},
{value: 'c1ql1', id: 'C1QL1'},
{value: 'c1ql2', id: 'C1QL2'},
{value: 'c1ql3', id: 'C1QL3'},
{value: 'c1ql4', id: 'C1QL4'},
{value: 'c1qtnf1', id: 'C1QTNF1'},
{value: 'c1qtnf12', id: 'C1QTNF12'},
{value: 'c1qtnf2', id: 'C1QTNF2'},
{value: 'c1qtnf3', id: 'C1QTNF3'},
{value: 'c1qtnf4', id: 'C1QTNF4'},
{value: 'c1qtnf5', id: 'C1QTNF5'},
{value: 'c1qtnf6', id: 'C1QTNF6'},
{value: 'c1qtnf7', id: 'C1QTNF7'},
{value: 'c1qtnf9', id: 'C1QTNF9'},
{value: 'c1r', id: 'C1R'},
{value: 'c1rl', id: 'C1RL'},
{value: 'c1s', id: 'C1S'},
{value: 'c2', id: 'C2'},
{value: 'c2cd2', id: 'C2CD2'},
{value: 'c2cd2l', id: 'C2CD2L'},
{value: 'c2cd3', id: 'C2CD3'},
{value: 'c2cd4a', id: 'C2CD4A'},
{value: 'c2cd4b', id: 'C2CD4B'},
{value: 'c2cd4c', id: 'C2CD4C'},
{value: 'c2cd4d', id: 'C2CD4D'},
{value: 'c2cd5', id: 'C2CD5'},
{value: 'c2cd6', id: 'C2CD6'},
{value: 'c3', id: 'C3'},
{value: 'c3ar1', id: 'C3AR1'},
{value: 'c4a', id: 'C4A'},
{value: 'c4b', id: 'C4B'},
{value: 'c4bpa', id: 'C4BPA'},
{value: 'c4bpb', id: 'C4BPB'},
{value: 'c5', id: 'C5'},
{value: 'c5ar1', id: 'C5AR1'},
{value: 'c5ar2', id: 'C5AR2'},
{value: 'c6', id: 'C6'},
{value: 'c7', id: 'C7'},
{value: 'c8a', id: 'C8A'},
{value: 'c8b', id: 'C8B'},
{value: 'c8g', id: 'C8G'},
{value: 'c9', id: 'C9'},
{value: 'ca11', id: 'CA11'},
{value: 'ca12', id: 'CA12'},
{value: 'ca13', id: 'CA13'},
{value: 'ca14', id: 'CA14'},
{value: 'ca3', id: 'CA3'},
{value: 'ca4', id: 'CA4'},
{value: 'ca5a', id: 'CA5A'},
{value: 'ca5b', id: 'CA5B'},
{value: 'ca6', id: 'CA6'},
{value: 'ca8', id: 'CA8'},
{value: 'ca9', id: 'CA9'},
{value: 'caap1', id: 'CAAP1'},
{value: 'cab39', id: 'CAB39'},
{value: 'cab39l', id: 'CAB39L'},
{value: 'cabcoco1', id: 'CABCOCO1'},
{value: 'cabin1', id: 'CABIN1'},
{value: 'cables1', id: 'CABLES1'},
{value: 'cables2', id: 'CABLES2'},
{value: 'cabp1', id: 'CABP1'},
{value: 'cabp2', id: 'CABP2'},
{value: 'cabp4', id: 'CABP4'},
{value: 'cabp5', id: 'CABP5'},
{value: 'cabp7', id: 'CABP7'},
{value: 'cabs1', id: 'CABS1'},
{value: 'cabyr', id: 'CABYR'},
{value: 'cacfd1', id: 'CACFD1'},
{value: 'cachd1', id: 'CACHD1'},
{value: 'cacna1a', id: 'CACNA1A'},
{value: 'cacna1b', id: 'CACNA1B'},
{value: 'cacna1c', id: 'CACNA1C'},
{value: 'cacna1d', id: 'CACNA1D'},
{value: 'cacna1e', id: 'CACNA1E'},
{value: 'cacna1f', id: 'CACNA1F'},
{value: 'cacna1g', id: 'CACNA1G'},
{value: 'cacna1h', id: 'CACNA1H'},
{value: 'cacna1i', id: 'CACNA1I'},
{value: 'cacna1s', id: 'CACNA1S'},
{value: 'cacna2d1', id: 'CACNA2D1'},
{value: 'cacna2d2', id: 'CACNA2D2'},
{value: 'cacna2d3', id: 'CACNA2D3'},
{value: 'cacna2d4', id: 'CACNA2D4'},
{value: 'cacnb1', id: 'CACNB1'},
{value: 'cacnb2', id: 'CACNB2'},
{value: 'cacnb3', id: 'CACNB3'},
{value: 'cacnb4', id: 'CACNB4'},
{value: 'cacng1', id: 'CACNG1'},
{value: 'cacng2', id: 'CACNG2'},
{value: 'cacng3', id: 'CACNG3'},
{value: 'cacng4', id: 'CACNG4'},
{value: 'cacng5', id: 'CACNG5'},
{value: 'cacng6', id: 'CACNG6'},
{value: 'cacng7', id: 'CACNG7'},
{value: 'cacng8', id: 'CACNG8'},
{value: 'cactin', id: 'CACTIN'},
{value: 'cacul1', id: 'CACUL1'},
{value: 'cacybp', id: 'CACYBP'},
{value: 'cad', id: 'CAD'},
{value: 'cadm1', id: 'CADM1'},
{value: 'cadm2', id: 'CADM2'},
{value: 'cadm3', id: 'CADM3'},
{value: 'cadm4', id: 'CADM4'},
{value: 'cadps', id: 'CADPS'},
{value: 'cadps2', id: 'CADPS2'},
{value: 'cage1', id: 'CAGE1'},
{value: 'calb1', id: 'CALB1'},
{value: 'calb2', id: 'CALB2'},
{value: 'calca', id: 'CALCA'},
{value: 'calcb', id: 'CALCB'},
{value: 'calcoco1', id: 'CALCOCO1'},
{value: 'calcr', id: 'CALCR'},
{value: 'calcrl', id: 'CALCRL'},
{value: 'cald1', id: 'CALD1'},
{value: 'calhm1', id: 'CALHM1'},
{value: 'calhm2', id: 'CALHM2'},
{value: 'calhm3', id: 'CALHM3'},
{value: 'calhm4', id: 'CALHM4'},
{value: 'calhm5', id: 'CALHM5'},
{value: 'calhm6', id: 'CALHM6'},
{value: 'calm1', id: 'CALM1'},
{value: 'calm2', id: 'CALM2'},
{value: 'calm3', id: 'CALM3'},
{value: 'calml3', id: 'CALML3'},
{value: 'calml4', id: 'CALML4'},
{value: 'calml5', id: 'CALML5'},
{value: 'caln1', id: 'CALN1'},
{value: 'calr', id: 'CALR'},
{value: 'calr3', id: 'CALR3'},
{value: 'calr4', id: 'CALR4'},
{value: 'calu', id: 'CALU'},
{value: 'caly', id: 'CALY'},
{value: 'camk1', id: 'CAMK1'},
{value: 'camk1d', id: 'CAMK1D'},
{value: 'camk1g', id: 'CAMK1G'},
{value: 'camk2a', id: 'CAMK2A'},
{value: 'camk2b', id: 'CAMK2B'},
{value: 'camk2d', id: 'CAMK2D'},
{value: 'camk2g', id: 'CAMK2G'},
{value: 'camk2n1', id: 'CAMK2N1'},
{value: 'camk2n2', id: 'CAMK2N2'},
{value: 'camk4', id: 'CAMK4'},
{value: 'camkk1', id: 'CAMKK1'},
{value: 'camkk2', id: 'CAMKK2'},
{value: 'camkmt', id: 'CAMKMT'},
{value: 'camkv', id: 'CAMKV'},
{value: 'camlg', id: 'CAMLG'},
{value: 'camp', id: 'CAMP'},
{value: 'camsap1', id: 'CAMSAP1'},
{value: 'camsap2', id: 'CAMSAP2'},
{value: 'camsap3', id: 'CAMSAP3'},
{value: 'camta1', id: 'CAMTA1'},
{value: 'camta2', id: 'CAMTA2'},
{value: 'cand1', id: 'CAND1'},
{value: 'cand2', id: 'CAND2'},
{value: 'cant1', id: 'CANT1'},
{value: 'canx', id: 'CANX'},
{value: 'cap1', id: 'CAP1'},
{value: 'cap2', id: 'CAP2'},
{value: 'capg', id: 'CAPG'},
{value: 'capn1', id: 'CAPN1'},
{value: 'capn10', id: 'CAPN10'},
{value: 'capn11', id: 'CAPN11'},
{value: 'capn12', id: 'CAPN12'},
{value: 'capn13', id: 'CAPN13'},
{value: 'capn15', id: 'CAPN15'},
{value: 'capn2', id: 'CAPN2'},
{value: 'capn3', id: 'CAPN3'},
{value: 'capn5', id: 'CAPN5'},
{value: 'capn6', id: 'CAPN6'},
{value: 'capn7', id: 'CAPN7'},
{value: 'capn8', id: 'CAPN8'},
{value: 'capn9', id: 'CAPN9'},
{value: 'capns1', id: 'CAPNS1'},
{value: 'capns2', id: 'CAPNS2'},
{value: 'caprin1', id: 'CAPRIN1'},
{value: 'caprin2', id: 'CAPRIN2'},
{value: 'caps2', id: 'CAPS2'},
{value: 'capsl', id: 'CAPSL'},
{value: 'capza1', id: 'CAPZA1'},
{value: 'capza2', id: 'CAPZA2'},
{value: 'capza3', id: 'CAPZA3'},
{value: 'capzb', id: 'CAPZB'},
{value: 'car1', id: 'CAR1'},
{value: 'car10', id: 'CAR10'},
{value: 'car15', id: 'CAR15'},
{value: 'car2', id: 'CAR2'},
{value: 'car7', id: 'CAR7'},
{value: 'card10', id: 'CARD10'},
{value: 'card11', id: 'CARD11'},
{value: 'card14', id: 'CARD14'},
{value: 'card19', id: 'CARD19'},
{value: 'card6', id: 'CARD6'},
{value: 'card9', id: 'CARD9'},
{value: 'carf', id: 'CARF'},
{value: 'carhsp1', id: 'CARHSP1'},
{value: 'carm1', id: 'CARM1'},
{value: 'carmil1', id: 'CARMIL1'},
{value: 'carmil2', id: 'CARMIL2'},
{value: 'carmil3', id: 'CARMIL3'},
{value: 'carnmt1', id: 'CARNMT1'},
{value: 'carns1', id: 'CARNS1'},
{value: 'cars', id: 'CARS'},
{value: 'cars2', id: 'CARS2'},
{value: 'cartpt', id: 'CARTPT'},
{value: 'casc3', id: 'CASC3'},
{value: 'casd1', id: 'CASD1'},
{value: 'cask', id: 'CASK'},
{value: 'caskin1', id: 'CASKIN1'},
{value: 'caskin2', id: 'CASKIN2'},
{value: 'casp1', id: 'CASP1'},
{value: 'casp12', id: 'CASP12'},
{value: 'casp14', id: 'CASP14'},
{value: 'casp2', id: 'CASP2'},
{value: 'casp3', id: 'CASP3'},
{value: 'casp4', id: 'CASP4'},
{value: 'casp6', id: 'CASP6'},
{value: 'casp7', id: 'CASP7'},
{value: 'casp8', id: 'CASP8'},
{value: 'casp8ap2', id: 'CASP8AP2'},
{value: 'casp9', id: 'CASP9'},
{value: 'casq1', id: 'CASQ1'},
{value: 'casq2', id: 'CASQ2'},
{value: 'casr', id: 'CASR'},
{value: 'cass4', id: 'CASS4'},
{value: 'cast', id: 'CAST'},
{value: 'castor1', id: 'CASTOR1'},
{value: 'castor2', id: 'CASTOR2'},
{value: 'casz1', id: 'CASZ1'},
{value: 'cat', id: 'CAT'},
{value: 'catip', id: 'CATIP'},
{value: 'catsper1', id: 'CATSPER1'},
{value: 'catsper2', id: 'CATSPER2'},
{value: 'catsper3', id: 'CATSPER3'},
{value: 'catsper4', id: 'CATSPER4'},
{value: 'catsperb', id: 'CATSPERB'},
{value: 'catsperd', id: 'CATSPERD'},
{value: 'catsperg', id: 'CATSPERG'},
{value: 'catsperz', id: 'CATSPERZ'},
{value: 'cav1', id: 'CAV1'},
{value: 'cav2', id: 'CAV2'},
{value: 'cav3', id: 'CAV3'},
{value: 'cavin1', id: 'CAVIN1'},
{value: 'cavin2', id: 'CAVIN2'},
{value: 'cavin3', id: 'CAVIN3'},
{value: 'cavin4', id: 'CAVIN4'},
{value: 'cbarp', id: 'CBARP'},
{value: 'cbfa2t2', id: 'CBFA2T2'},
{value: 'cbfa2t3', id: 'CBFA2T3'},
{value: 'cbfb', id: 'CBFB'},
{value: 'cbl', id: 'CBL'},
{value: 'cblb', id: 'CBLB'},
{value: 'cblc', id: 'CBLC'},
{value: 'cblif', id: 'CBLIF'},
{value: 'cbll1', id: 'CBLL1'},
{value: 'cbln1', id: 'CBLN1'},
{value: 'cbln2', id: 'CBLN2'},
{value: 'cbln3', id: 'CBLN3'},
{value: 'cbln4', id: 'CBLN4'},
{value: 'cbr1', id: 'CBR1'},
{value: 'cbr3', id: 'CBR3'},
{value: 'cbr4', id: 'CBR4'},
{value: 'cbs', id: 'CBS'},
{value: 'cbwd1', id: 'CBWD1'},
{value: 'cbx1', id: 'CBX1'},
{value: 'cbx2', id: 'CBX2'},
{value: 'cbx3', id: 'CBX3'},
{value: 'cbx4', id: 'CBX4'},
{value: 'cbx5', id: 'CBX5'},
{value: 'cbx6', id: 'CBX6'},
{value: 'cbx7', id: 'CBX7'},
{value: 'cbx8', id: 'CBX8'},
{value: 'cby1', id: 'CBY1'},
{value: 'cby2', id: 'CBY2'},
{value: 'cby3', id: 'CBY3'},
{value: 'cc2d1a', id: 'CC2D1A'},
{value: 'cc2d1b', id: 'CC2D1B'},
{value: 'cc2d2a', id: 'CC2D2A'},
{value: 'ccar1', id: 'CCAR1'},
{value: 'ccar2', id: 'CCAR2'},
{value: 'ccbe1', id: 'CCBE1'},
{value: 'ccdc102a', id: 'CCDC102A'},
{value: 'ccdc103', id: 'CCDC103'},
{value: 'ccdc105', id: 'CCDC105'},
{value: 'ccdc106', id: 'CCDC106'},
{value: 'ccdc107', id: 'CCDC107'},
{value: 'ccdc110', id: 'CCDC110'},
{value: 'ccdc112', id: 'CCDC112'},
{value: 'ccdc113', id: 'CCDC113'},
{value: 'ccdc115', id: 'CCDC115'},
{value: 'ccdc116', id: 'CCDC116'},
{value: 'ccdc117', id: 'CCDC117'},
{value: 'ccdc12', id: 'CCDC12'},
{value: 'ccdc120', id: 'CCDC120'},
{value: 'ccdc121', id: 'CCDC121'},
{value: 'ccdc122', id: 'CCDC122'},
{value: 'ccdc124', id: 'CCDC124'},
{value: 'ccdc125', id: 'CCDC125'},
{value: 'ccdc126', id: 'CCDC126'},
{value: 'ccdc127', id: 'CCDC127'},
{value: 'ccdc13', id: 'CCDC13'},
{value: 'ccdc134', id: 'CCDC134'},
{value: 'ccdc136', id: 'CCDC136'},
{value: 'ccdc137', id: 'CCDC137'},
{value: 'ccdc138', id: 'CCDC138'},
{value: 'ccdc14', id: 'CCDC14'},
{value: 'ccdc141', id: 'CCDC141'},
{value: 'ccdc142', id: 'CCDC142'},
{value: 'ccdc146', id: 'CCDC146'},
{value: 'ccdc148', id: 'CCDC148'},
{value: 'ccdc149', id: 'CCDC149'},
{value: 'ccdc15', id: 'CCDC15'},
{value: 'ccdc150', id: 'CCDC150'},
{value: 'ccdc152', id: 'CCDC152'},
{value: 'ccdc153', id: 'CCDC153'},
{value: 'ccdc154', id: 'CCDC154'},
{value: 'ccdc157', id: 'CCDC157'},
{value: 'ccdc158', id: 'CCDC158'},
{value: 'ccdc159', id: 'CCDC159'},
{value: 'ccdc160', id: 'CCDC160'},
{value: 'ccdc162', id: 'CCDC162'},
{value: 'ccdc163', id: 'CCDC163'},
{value: 'ccdc166', id: 'CCDC166'},
{value: 'ccdc167', id: 'CCDC167'},
{value: 'ccdc168', id: 'CCDC168'},
{value: 'ccdc169', id: 'CCDC169'},
{value: 'ccdc17', id: 'CCDC17'},
{value: 'ccdc170', id: 'CCDC170'},
{value: 'ccdc171', id: 'CCDC171'},
{value: 'ccdc172', id: 'CCDC172'},
{value: 'ccdc174', id: 'CCDC174'},
{value: 'ccdc175', id: 'CCDC175'},
{value: 'ccdc177', id: 'CCDC177'},
{value: 'ccdc178', id: 'CCDC178'},
{value: 'ccdc179', id: 'CCDC179'},
{value: 'ccdc18', id: 'CCDC18'},
{value: 'ccdc180', id: 'CCDC180'},
{value: 'ccdc181', id: 'CCDC181'},
{value: 'ccdc182', id: 'CCDC182'},
{value: 'ccdc183', id: 'CCDC183'},
{value: 'ccdc184', id: 'CCDC184'},
{value: 'ccdc185', id: 'CCDC185'},
{value: 'ccdc186', id: 'CCDC186'},
{value: 'ccdc187', id: 'CCDC187'},
{value: 'ccdc188', id: 'CCDC188'},
{value: 'ccdc190', id: 'CCDC190'},
{value: 'ccdc191', id: 'CCDC191'},
{value: 'ccdc196', id: 'CCDC196'},
{value: 'ccdc198', id: 'CCDC198'},
{value: 'ccdc200', id: 'CCDC200'},
{value: 'ccdc22', id: 'CCDC22'},
{value: 'ccdc24', id: 'CCDC24'},
{value: 'ccdc25', id: 'CCDC25'},
{value: 'ccdc27', id: 'CCDC27'},
{value: 'ccdc28a', id: 'CCDC28A'},
{value: 'ccdc28b', id: 'CCDC28B'},
{value: 'ccdc3', id: 'CCDC3'},
{value: 'ccdc30', id: 'CCDC30'},
{value: 'ccdc32', id: 'CCDC32'},
{value: 'ccdc33', id: 'CCDC33'},
{value: 'ccdc34', id: 'CCDC34'},
{value: 'ccdc38', id: 'CCDC38'},
{value: 'ccdc39', id: 'CCDC39'},
{value: 'ccdc40', id: 'CCDC40'},
{value: 'ccdc42', id: 'CCDC42'},
{value: 'ccdc43', id: 'CCDC43'},
{value: 'ccdc47', id: 'CCDC47'},
{value: 'ccdc50', id: 'CCDC50'},
{value: 'ccdc51', id: 'CCDC51'},
{value: 'ccdc54', id: 'CCDC54'},
{value: 'ccdc57', id: 'CCDC57'},
{value: 'ccdc59', id: 'CCDC59'},
{value: 'ccdc6', id: 'CCDC6'},
{value: 'ccdc60', id: 'CCDC60'},
{value: 'ccdc61', id: 'CCDC61'},
{value: 'ccdc62', id: 'CCDC62'},
{value: 'ccdc63', id: 'CCDC63'},
{value: 'ccdc65', id: 'CCDC65'},
{value: 'ccdc66', id: 'CCDC66'},
{value: 'ccdc68', id: 'CCDC68'},
{value: 'ccdc69', id: 'CCDC69'},
{value: 'ccdc7', id: 'CCDC7'},
{value: 'ccdc70', id: 'CCDC70'},
{value: 'ccdc71', id: 'CCDC71'},
{value: 'ccdc71l', id: 'CCDC71L'},
{value: 'ccdc73', id: 'CCDC73'},
{value: 'ccdc74a', id: 'CCDC74A'},
{value: 'ccdc77', id: 'CCDC77'},
{value: 'ccdc78', id: 'CCDC78'},
{value: 'ccdc8', id: 'CCDC8'},
{value: 'ccdc80', id: 'CCDC80'},
{value: 'ccdc81', id: 'CCDC81'},
{value: 'ccdc82', id: 'CCDC82'},
{value: 'ccdc83', id: 'CCDC83'},
{value: 'ccdc85a', id: 'CCDC85A'},
{value: 'ccdc85b', id: 'CCDC85B'},
{value: 'ccdc85c', id: 'CCDC85C'},
{value: 'ccdc86', id: 'CCDC86'},
{value: 'ccdc87', id: 'CCDC87'},
{value: 'ccdc88a', id: 'CCDC88A'},
{value: 'ccdc88b', id: 'CCDC88B'},
{value: 'ccdc88c', id: 'CCDC88C'},
{value: 'ccdc89', id: 'CCDC89'},
{value: 'ccdc9', id: 'CCDC9'},
{value: 'ccdc90b', id: 'CCDC90B'},
{value: 'ccdc91', id: 'CCDC91'},
{value: 'ccdc92', id: 'CCDC92'},
{value: 'ccdc92b', id: 'CCDC92B'},
{value: 'ccdc93', id: 'CCDC93'},
{value: 'ccdc96', id: 'CCDC96'},
{value: 'ccdc97', id: 'CCDC97'},
{value: 'ccdc9b', id: 'CCDC9B'},
{value: 'ccer1', id: 'CCER1'},
{value: 'ccer2', id: 'CCER2'},
{value: 'cchcr1', id: 'CCHCR1'},
{value: 'ccin', id: 'CCIN'},
{value: 'cck', id: 'CCK'},
{value: 'cckar', id: 'CCKAR'},
{value: 'cckbr', id: 'CCKBR'},
{value: 'ccl1', id: 'CCL1'},
{value: 'ccl11', id: 'CCL11'},
{value: 'ccl12', id: 'CCL12'},
{value: 'ccl17', id: 'CCL17'},
{value: 'ccl19', id: 'CCL19'},
{value: 'ccl2', id: 'CCL2'},
{value: 'ccl20', id: 'CCL20'},
{value: 'ccl21', id: 'CCL21'},
{value: 'ccl22', id: 'CCL22'},
{value: 'ccl24', id: 'CCL24'},
{value: 'ccl25', id: 'CCL25'},
{value: 'ccl26', id: 'CCL26'},
{value: 'ccl27', id: 'CCL27'},
{value: 'ccl28', id: 'CCL28'},
{value: 'ccl3', id: 'CCL3'},
{value: 'ccl4', id: 'CCL4'},
{value: 'ccl5', id: 'CCL5'},
{value: 'ccl6', id: 'CCL6'},
{value: 'ccl7', id: 'CCL7'},
{value: 'ccl9', id: 'CCL9'},
{value: 'ccm2', id: 'CCM2'},
{value: 'ccm2l', id: 'CCM2L'},
{value: 'ccn1', id: 'CCN1'},
{value: 'ccn2', id: 'CCN2'},
{value: 'ccn3', id: 'CCN3'},
{value: 'ccn4', id: 'CCN4'},
{value: 'ccn5', id: 'CCN5'},
{value: 'ccn6', id: 'CCN6'},
{value: 'ccna1', id: 'CCNA1'},
{value: 'ccna2', id: 'CCNA2'},
{value: 'ccnb1', id: 'CCNB1'},
{value: 'ccnb1ip1', id: 'CCNB1IP1'},
{value: 'ccnb3', id: 'CCNB3'},
{value: 'ccnc', id: 'CCNC'},
{value: 'ccnd1', id: 'CCND1'},
{value: 'ccnd2', id: 'CCND2'},
{value: 'ccnd3', id: 'CCND3'},
{value: 'ccndbp1', id: 'CCNDBP1'},
{value: 'ccne1', id: 'CCNE1'},
{value: 'ccne2', id: 'CCNE2'},
{value: 'ccnf', id: 'CCNF'},
{value: 'ccng1', id: 'CCNG1'},
{value: 'ccng2', id: 'CCNG2'},
{value: 'ccnh', id: 'CCNH'},
{value: 'ccni', id: 'CCNI'},
{value: 'ccnj', id: 'CCNJ'},
{value: 'ccnjl', id: 'CCNJL'},
{value: 'ccnk', id: 'CCNK'},
{value: 'ccnl1', id: 'CCNL1'},
{value: 'ccnl2', id: 'CCNL2'},
{value: 'ccno', id: 'CCNO'},
{value: 'ccnq', id: 'CCNQ'},
{value: 'ccnt1', id: 'CCNT1'},
{value: 'ccnt2', id: 'CCNT2'},
{value: 'ccny', id: 'CCNY'},
{value: 'ccnyl1', id: 'CCNYL1'},
{value: 'ccp110', id: 'CCP110'},
{value: 'ccpg1', id: 'CCPG1'},
{value: 'ccpg1os', id: 'CCPG1OS'},
{value: 'ccr1', id: 'CCR1'},
{value: 'ccr10', id: 'CCR10'},
{value: 'ccr1l1', id: 'CCR1L1'},
{value: 'ccr3', id: 'CCR3'},
{value: 'ccr4', id: 'CCR4'},
{value: 'ccr5', id: 'CCR5'},
{value: 'ccr6', id: 'CCR6'},
{value: 'ccr7', id: 'CCR7'},
{value: 'ccr8', id: 'CCR8'},
{value: 'ccr9', id: 'CCR9'},
{value: 'ccrl2', id: 'CCRL2'},
{value: 'ccs', id: 'CCS'},
{value: 'ccsap', id: 'CCSAP'},
{value: 'ccser1', id: 'CCSER1'},
{value: 'ccser2', id: 'CCSER2'},
{value: 'cct2', id: 'CCT2'},
{value: 'cct3', id: 'CCT3'},
{value: 'cct4', id: 'CCT4'},
{value: 'cct5', id: 'CCT5'},
{value: 'cct6a', id: 'CCT6A'},
{value: 'cct6b', id: 'CCT6B'},
{value: 'cct7', id: 'CCT7'},
{value: 'cct8', id: 'CCT8'},
{value: 'cct8l1', id: 'CCT8L1'},
{value: 'ccz1b', id: 'CCZ1B'},
{value: 'cd101', id: 'CD101'},
{value: 'cd109', id: 'CD109'},
{value: 'cd14', id: 'CD14'},
{value: 'cd151', id: 'CD151'},
{value: 'cd160', id: 'CD160'},
{value: 'cd163', id: 'CD163'},
{value: 'cd164', id: 'CD164'},
{value: 'cd164l2', id: 'CD164L2'},
{value: 'cd177', id: 'CD177'},
{value: 'cd180', id: 'CD180'},
{value: 'cd19', id: 'CD19'},
{value: 'cd1d1', id: 'CD1D1'},
{value: 'cd2', id: 'CD2'},
{value: 'cd200', id: 'CD200'},
{value: 'cd200r1', id: 'CD200R1'},
{value: 'cd200r1l', id: 'CD200R1L'},
{value: 'cd207', id: 'CD207'},
{value: 'cd209', id: 'CD209'},
{value: 'cd209a', id: 'CD209A'},
{value: 'cd209c', id: 'CD209C'},
{value: 'cd209e', id: 'CD209E'},
{value: 'cd209f', id: 'CD209F'},
{value: 'cd22', id: 'CD22'},
{value: 'cd226', id: 'CD226'},
{value: 'cd24', id: 'CD24'},
{value: 'cd244', id: 'CD244'},
{value: 'cd247', id: 'CD247'},
{value: 'cd248', id: 'CD248'},
{value: 'cd27', id: 'CD27'},
{value: 'cd274', id: 'CD274'},
{value: 'cd276', id: 'CD276'},
{value: 'cd28', id: 'CD28'},
{value: 'cd2ap', id: 'CD2AP'},
{value: 'cd2bp2', id: 'CD2BP2'},
{value: 'cd300a', id: 'CD300A'},
{value: 'cd300c2', id: 'CD300C2'},
{value: 'cd300e', id: 'CD300E'},
{value: 'cd300lb', id: 'CD300LB'},
{value: 'cd300le', id: 'CD300LE'},
{value: 'cd300lf', id: 'CD300LF'},
{value: 'cd300lg', id: 'CD300LG'},
{value: 'cd302', id: 'CD302'},
{value: 'cd320', id: 'CD320'},
{value: 'cd33', id: 'CD33'},
{value: 'cd34', id: 'CD34'},
{value: 'cd36', id: 'CD36'},
{value: 'cd37', id: 'CD37'},
{value: 'cd38', id: 'CD38'},
{value: 'cd3d', id: 'CD3D'},
{value: 'cd3e', id: 'CD3E'},
{value: 'cd3g', id: 'CD3G'},
{value: 'cd4', id: 'CD4'},
{value: 'cd40', id: 'CD40'},
{value: 'cd40lg', id: 'CD40LG'},
{value: 'cd44', id: 'CD44'},
{value: 'cd46', id: 'CD46'},
{value: 'cd47', id: 'CD47'},
{value: 'cd48', id: 'CD48'},
{value: 'cd5', id: 'CD5'},
{value: 'cd52', id: 'CD52'},
{value: 'cd53', id: 'CD53'},
{value: 'cd55', id: 'CD55'},
{value: 'cd59', id: 'CD59'},
{value: 'cd5l', id: 'CD5L'},
{value: 'cd6', id: 'CD6'},
{value: 'cd63', id: 'CD63'},
{value: 'cd68', id: 'CD68'},
{value: 'cd69', id: 'CD69'},
{value: 'cd7', id: 'CD7'},
{value: 'cd70', id: 'CD70'},
{value: 'cd72', id: 'CD72'},
{value: 'cd74', id: 'CD74'},
{value: 'cd79a', id: 'CD79A'},
{value: 'cd79b', id: 'CD79B'},
{value: 'cd80', id: 'CD80'},
{value: 'cd81', id: 'CD81'},
{value: 'cd82', id: 'CD82'},
{value: 'cd83', id: 'CD83'},
{value: 'cd84', id: 'CD84'},
{value: 'cd86', id: 'CD86'},
{value: 'cd8a', id: 'CD8A'},
{value: 'cd8b', id: 'CD8B'},
{value: 'cd9', id: 'CD9'},
{value: 'cd93', id: 'CD93'},
{value: 'cd96', id: 'CD96'},
{value: 'cd99', id: 'CD99'},
{value: 'cd99l2', id: 'CD99L2'},
{value: 'cda', id: 'CDA'},
{value: 'cdadc1', id: 'CDADC1'},
{value: 'cdan1', id: 'CDAN1'},
{value: 'cdc123', id: 'CDC123'},
{value: 'cdc14a', id: 'CDC14A'},
{value: 'cdc14b', id: 'CDC14B'},
{value: 'cdc16', id: 'CDC16'},
{value: 'cdc20', id: 'CDC20'},
{value: 'cdc23', id: 'CDC23'},
{value: 'cdc25a', id: 'CDC25A'},
{value: 'cdc25b', id: 'CDC25B'},
{value: 'cdc25c', id: 'CDC25C'},
{value: 'cdc26', id: 'CDC26'},
{value: 'cdc27', id: 'CDC27'},
{value: 'cdc34', id: 'CDC34'},
{value: 'cdc37', id: 'CDC37'},
{value: 'cdc37l1', id: 'CDC37L1'},
{value: 'cdc40', id: 'CDC40'},
{value: 'cdc42', id: 'CDC42'},
{value: 'cdc42bpa', id: 'CDC42BPA'},
{value: 'cdc42bpb', id: 'CDC42BPB'},
{value: 'cdc42bpg', id: 'CDC42BPG'},
{value: 'cdc42ep1', id: 'CDC42EP1'},
{value: 'cdc42ep2', id: 'CDC42EP2'},
{value: 'cdc42ep3', id: 'CDC42EP3'},
{value: 'cdc42ep4', id: 'CDC42EP4'},
{value: 'cdc42ep5', id: 'CDC42EP5'},
{value: 'cdc42se1', id: 'CDC42SE1'},
{value: 'cdc42se2', id: 'CDC42SE2'},
{value: 'cdc45', id: 'CDC45'},
{value: 'cdc5l', id: 'CDC5L'},
{value: 'cdc6', id: 'CDC6'},
{value: 'cdc7', id: 'CDC7'},
{value: 'cdc73', id: 'CDC73'},
{value: 'cdca2', id: 'CDCA2'},
{value: 'cdca3', id: 'CDCA3'},
{value: 'cdca4', id: 'CDCA4'},
{value: 'cdca5', id: 'CDCA5'},
{value: 'cdca7', id: 'CDCA7'},
{value: 'cdca7l', id: 'CDCA7L'},
{value: 'cdca8', id: 'CDCA8'},
{value: 'cdcp1', id: 'CDCP1'},
{value: 'cdcp2', id: 'CDCP2'},
{value: 'cdh1', id: 'CDH1'},
{value: 'cdh10', id: 'CDH10'},
{value: 'cdh11', id: 'CDH11'},
{value: 'cdh12', id: 'CDH12'},
{value: 'cdh13', id: 'CDH13'},
{value: 'cdh15', id: 'CDH15'},
{value: 'cdh16', id: 'CDH16'},
{value: 'cdh17', id: 'CDH17'},
{value: 'cdh18', id: 'CDH18'},
{value: 'cdh19', id: 'CDH19'},
{value: 'cdh2', id: 'CDH2'},
{value: 'cdh20', id: 'CDH20'},
{value: 'cdh22', id: 'CDH22'},
{value: 'cdh23', id: 'CDH23'},
{value: 'cdh24', id: 'CDH24'},
{value: 'cdh26', id: 'CDH26'},
{value: 'cdh3', id: 'CDH3'},
{value: 'cdh4', id: 'CDH4'},
{value: 'cdh5', id: 'CDH5'},
{value: 'cdh6', id: 'CDH6'},
{value: 'cdh7', id: 'CDH7'},
{value: 'cdh8', id: 'CDH8'},
{value: 'cdh9', id: 'CDH9'},
{value: 'cdhr1', id: 'CDHR1'},
{value: 'cdhr2', id: 'CDHR2'},
{value: 'cdhr3', id: 'CDHR3'},
{value: 'cdhr4', id: 'CDHR4'},
{value: 'cdhr5', id: 'CDHR5'},
{value: 'cdin1', id: 'CDIN1'},
{value: 'cdip1', id: 'CDIP1'},
{value: 'cdipt', id: 'CDIPT'},
{value: 'cdk1', id: 'CDK1'},
{value: 'cdk10', id: 'CDK10'},
{value: 'cdk11b', id: 'CDK11B'},
{value: 'cdk12', id: 'CDK12'},
{value: 'cdk13', id: 'CDK13'},
{value: 'cdk14', id: 'CDK14'},
{value: 'cdk15', id: 'CDK15'},
{value: 'cdk16', id: 'CDK16'},
{value: 'cdk17', id: 'CDK17'},
{value: 'cdk18', id: 'CDK18'},
{value: 'cdk19', id: 'CDK19'},
{value: 'cdk2', id: 'CDK2'},
{value: 'cdk20', id: 'CDK20'},
{value: 'cdk2ap1', id: 'CDK2AP1'},
{value: 'cdk2ap2', id: 'CDK2AP2'},
{value: 'cdk4', id: 'CDK4'},
{value: 'cdk5', id: 'CDK5'},
{value: 'cdk5r1', id: 'CDK5R1'},
{value: 'cdk5r2', id: 'CDK5R2'},
{value: 'cdk5rap1', id: 'CDK5RAP1'},
{value: 'cdk5rap2', id: 'CDK5RAP2'},
{value: 'cdk5rap3', id: 'CDK5RAP3'},
{value: 'cdk6', id: 'CDK6'},
{value: 'cdk7', id: 'CDK7'},
{value: 'cdk8', id: 'CDK8'},
{value: 'cdk9', id: 'CDK9'},
{value: 'cdkal1', id: 'CDKAL1'},
{value: 'cdkl1', id: 'CDKL1'},
{value: 'cdkl2', id: 'CDKL2'},
{value: 'cdkl3', id: 'CDKL3'},
{value: 'cdkl4', id: 'CDKL4'},
{value: 'cdkl5', id: 'CDKL5'},
{value: 'cdkn1a', id: 'CDKN1A'},
{value: 'cdkn1b', id: 'CDKN1B'},
{value: 'cdkn1c', id: 'CDKN1C'},
{value: 'cdkn2a', id: 'CDKN2A'},
{value: 'cdkn2aip', id: 'CDKN2AIP'},
{value: 'cdkn2aipnl', id: 'CDKN2AIPNL'},
{value: 'cdkn2b', id: 'CDKN2B'},
{value: 'cdkn2c', id: 'CDKN2C'},
{value: 'cdkn2d', id: 'CDKN2D'},
{value: 'cdkn3', id: 'CDKN3'},
{value: 'cdnf', id: 'CDNF'},
{value: 'cdo1', id: 'CDO1'},
{value: 'cdon', id: 'CDON'},
{value: 'cdpf1', id: 'CDPF1'},
{value: 'cdr2', id: 'CDR2'},
{value: 'cdr2l', id: 'CDR2L'},
{value: 'cdrt4', id: 'CDRT4'},
{value: 'cds1', id: 'CDS1'},
{value: 'cds2', id: 'CDS2'},
{value: 'cdsn', id: 'CDSN'},
{value: 'cdt1', id: 'CDT1'},
{value: 'cdv3', id: 'CDV3'},
{value: 'cdx1', id: 'CDX1'},
{value: 'cdx2', id: 'CDX2'},
{value: 'cdx4', id: 'CDX4'},
{value: 'cdyl', id: 'CDYL'},
{value: 'cdyl2', id: 'CDYL2'},
{value: 'ceacam1', id: 'CEACAM1'},
{value: 'ceacam11', id: 'CEACAM11'},
{value: 'ceacam12', id: 'CEACAM12'},
{value: 'ceacam16', id: 'CEACAM16'},
{value: 'ceacam18', id: 'CEACAM18'},
{value: 'ceacam19', id: 'CEACAM19'},
{value: 'ceacam20', id: 'CEACAM20'},
{value: 'ceacam3', id: 'CEACAM3'},
{value: 'ceacam4', id: 'CEACAM4'},
{value: 'ceacam6', id: 'CEACAM6'},
{value: 'ceacam9', id: 'CEACAM9'},
{value: 'cebpa', id: 'CEBPA'},
{value: 'cebpb', id: 'CEBPB'},
{value: 'cebpd', id: 'CEBPD'},
{value: 'cebpe', id: 'CEBPE'},
{value: 'cebpg', id: 'CEBPG'},
{value: 'cebpz', id: 'CEBPZ'},
{value: 'cebpzos', id: 'CEBPZOS'},
{value: 'cecr2', id: 'CECR2'},
{value: 'cel', id: 'CEL'},
{value: 'cela1', id: 'CELA1'},
{value: 'cela2a', id: 'CELA2A'},
{value: 'cela3b', id: 'CELA3B'},
{value: 'celf1', id: 'CELF1'},
{value: 'celf2', id: 'CELF2'},
{value: 'celf3', id: 'CELF3'},
{value: 'celf4', id: 'CELF4'},
{value: 'celf5', id: 'CELF5'},
{value: 'celf6', id: 'CELF6'},
{value: 'celsr1', id: 'CELSR1'},
{value: 'celsr2', id: 'CELSR2'},
{value: 'celsr3', id: 'CELSR3'},
{value: 'cemip', id: 'CEMIP'},
{value: 'cemip2', id: 'CEMIP2'},
{value: 'cenatac', id: 'CENATAC'},
{value: 'cend1', id: 'CEND1'},
{value: 'cenpa', id: 'CENPA'},
{value: 'cenpb', id: 'CENPB'},
{value: 'cenpc', id: 'CENPC'},
{value: 'cenpe', id: 'CENPE'},
{value: 'cenpf', id: 'CENPF'},
{value: 'cenph', id: 'CENPH'},
{value: 'cenpi', id: 'CENPI'},
{value: 'cenpj', id: 'CENPJ'},
{value: 'cenpk', id: 'CENPK'},
{value: 'cenpl', id: 'CENPL'},
{value: 'cenpm', id: 'CENPM'},
{value: 'cenpn', id: 'CENPN'},
{value: 'cenpo', id: 'CENPO'},
{value: 'cenpp', id: 'CENPP'},
{value: 'cenps', id: 'CENPS'},
{value: 'cenpt', id: 'CENPT'},
{value: 'cenpu', id: 'CENPU'},
{value: 'cenpv', id: 'CENPV'},
{value: 'cenpw', id: 'CENPW'},
{value: 'cep104', id: 'CEP104'},
{value: 'cep112', id: 'CEP112'},
{value: 'cep120', id: 'CEP120'},
{value: 'cep126', id: 'CEP126'},
{value: 'cep128', id: 'CEP128'},
{value: 'cep131', id: 'CEP131'},
{value: 'cep135', id: 'CEP135'},
{value: 'cep152', id: 'CEP152'},
{value: 'cep162', id: 'CEP162'},
{value: 'cep164', id: 'CEP164'},
{value: 'cep170', id: 'CEP170'},
{value: 'cep170b', id: 'CEP170B'},
{value: 'cep19', id: 'CEP19'},
{value: 'cep192', id: 'CEP192'},
{value: 'cep20', id: 'CEP20'},
{value: 'cep250', id: 'CEP250'},
{value: 'cep290', id: 'CEP290'},
{value: 'cep295', id: 'CEP295'},
{value: 'cep295nl', id: 'CEP295NL'},
{value: 'cep350', id: 'CEP350'},
{value: 'cep41', id: 'CEP41'},
{value: 'cep43', id: 'CEP43'},
{value: 'cep44', id: 'CEP44'},
{value: 'cep55', id: 'CEP55'},
{value: 'cep57', id: 'CEP57'},
{value: 'cep57l1', id: 'CEP57L1'},
{value: 'cep63', id: 'CEP63'},
{value: 'cep68', id: 'CEP68'},
{value: 'cep70', id: 'CEP70'},
{value: 'cep72', id: 'CEP72'},
{value: 'cep76', id: 'CEP76'},
{value: 'cep78', id: 'CEP78'},
{value: 'cep83', id: 'CEP83'},
{value: 'cep85', id: 'CEP85'},
{value: 'cep85l', id: 'CEP85L'},
{value: 'cep89', id: 'CEP89'},
{value: 'cep95', id: 'CEP95'},
{value: 'cep97', id: 'CEP97'},
{value: 'cept1', id: 'CEPT1'},
{value: 'cer1', id: 'CER1'},
{value: 'cercam', id: 'CERCAM'},
{value: 'cerk', id: 'CERK'},
{value: 'cerkl', id: 'CERKL'},
{value: 'cers1', id: 'CERS1'},
{value: 'cers2', id: 'CERS2'},
{value: 'cers3', id: 'CERS3'},
{value: 'cers4', id: 'CERS4'},
{value: 'cers5', id: 'CERS5'},
{value: 'cers6', id: 'CERS6'},
{value: 'cert1', id: 'CERT1'},
{value: 'ces1a', id: 'CES1A'},
{value: 'ces1c', id: 'CES1C'},
{value: 'ces1d', id: 'CES1D'},
{value: 'ces1e', id: 'CES1E'},
{value: 'ces1f', id: 'CES1F'},
{value: 'ces2', id: 'CES2'},
{value: 'ces2a', id: 'CES2A'},
{value: 'ces2c', id: 'CES2C'},
{value: 'ces2e', id: 'CES2E'},
{value: 'ces2g', id: 'CES2G'},
{value: 'ces2h', id: 'CES2H'},
{value: 'ces2i', id: 'CES2I'},
{value: 'ces2j', id: 'CES2J'},
{value: 'ces4a', id: 'CES4A'},
{value: 'ces5a', id: 'CES5A'},
{value: 'cesl1', id: 'CESL1'},
{value: 'cetn1', id: 'CETN1'},
{value: 'cetn2', id: 'CETN2'},
{value: 'cetn3', id: 'CETN3'},
{value: 'cetn4', id: 'CETN4'},
{value: 'cfap100', id: 'CFAP100'},
{value: 'cfap107', id: 'CFAP107'},
{value: 'cfap119', id: 'CFAP119'},
{value: 'cfap126', id: 'CFAP126'},
{value: 'cfap141', id: 'CFAP141'},
{value: 'cfap157', id: 'CFAP157'},
{value: 'cfap161', id: 'CFAP161'},
{value: 'cfap20', id: 'CFAP20'},
{value: 'cfap206', id: 'CFAP206'},
{value: 'cfap20dc', id: 'CFAP20DC'},
{value: 'cfap210', id: 'CFAP210'},
{value: 'cfap221', id: 'CFAP221'},
{value: 'cfap251', id: 'CFAP251'},
{value: 'cfap276', id: 'CFAP276'},
{value: 'cfap298', id: 'CFAP298'},
{value: 'cfap299', id: 'CFAP299'},
{value: 'cfap300', id: 'CFAP300'},
{value: 'cfap36', id: 'CFAP36'},
{value: 'cfap410', id: 'CFAP410'},
{value: 'cfap418', id: 'CFAP418'},
{value: 'cfap43', id: 'CFAP43'},
{value: 'cfap44', id: 'CFAP44'},
{value: 'cfap45', id: 'CFAP45'},
{value: 'cfap46', id: 'CFAP46'},
{value: 'cfap47', id: 'CFAP47'},
{value: 'cfap52', id: 'CFAP52'},
{value: 'cfap53', id: 'CFAP53'},
{value: 'cfap57', id: 'CFAP57'},
{value: 'cfap58', id: 'CFAP58'},
{value: 'cfap61', id: 'CFAP61'},
{value: 'cfap65', id: 'CFAP65'},
{value: 'cfap69', id: 'CFAP69'},
{value: 'cfap70', id: 'CFAP70'},
{value: 'cfap73', id: 'CFAP73'},
{value: 'cfap74', id: 'CFAP74'},
{value: 'cfap77', id: 'CFAP77'},
{value: 'cfap91', id: 'CFAP91'},
{value: 'cfap92', id: 'CFAP92'},
{value: 'cfap95', id: 'CFAP95'},
{value: 'cfap97', id: 'CFAP97'},
{value: 'cfap97d1', id: 'CFAP97D1'},
{value: 'cfap97d2', id: 'CFAP97D2'},
{value: 'cfap99', id: 'CFAP99'},
{value: 'cfb', id: 'CFB'},
{value: 'cfc1', id: 'CFC1'},
{value: 'cfd', id: 'CFD'},
{value: 'cfdp1', id: 'CFDP1'},
{value: 'cfh', id: 'CFH'},
{value: 'cfhr1', id: 'CFHR1'},
{value: 'cfhr2', id: 'CFHR2'},
{value: 'cfhr4', id: 'CFHR4'},
{value: 'cfi', id: 'CFI'},
{value: 'cfl1', id: 'CFL1'},
{value: 'cfl2', id: 'CFL2'},
{value: 'cflar', id: 'CFLAR'},
{value: 'cfp', id: 'CFP'},
{value: 'cftr', id: 'CFTR'},
{value: 'cga', id: 'CGA'},
{value: 'cgas', id: 'CGAS'},
{value: 'cggbp1', id: 'CGGBP1'},
{value: 'cgm4', id: 'CGM4'},
{value: 'cgn', id: 'CGN'},
{value: 'cgnl1', id: 'CGNL1'},
{value: 'cgref1', id: 'CGREF1'},
{value: 'cgrrf1', id: 'CGRRF1'},
{value: 'ch25h', id: 'CH25H'},
{value: 'chac1', id: 'CHAC1'},
{value: 'chac2', id: 'CHAC2'},
{value: 'chad', id: 'CHAD'},
{value: 'chadl', id: 'CHADL'},
{value: 'chaf1a', id: 'CHAF1A'},
{value: 'chaf1b', id: 'CHAF1B'},
{value: 'champ1', id: 'CHAMP1'},
{value: 'chat', id: 'CHAT'},
{value: 'chchd1', id: 'CHCHD1'},
{value: 'chchd10', id: 'CHCHD10'},
{value: 'chchd2', id: 'CHCHD2'},
{value: 'chchd3', id: 'CHCHD3'},
{value: 'chchd4', id: 'CHCHD4'},
{value: 'chchd5', id: 'CHCHD5'},
{value: 'chchd6', id: 'CHCHD6'},
{value: 'chchd7', id: 'CHCHD7'},
{value: 'chd1', id: 'CHD1'},
{value: 'chd1l', id: 'CHD1L'},
{value: 'chd2', id: 'CHD2'},
{value: 'chd3', id: 'CHD3'},
{value: 'chd4', id: 'CHD4'},
{value: 'chd5', id: 'CHD5'},
{value: 'chd6', id: 'CHD6'},
{value: 'chd7', id: 'CHD7'},
{value: 'chd8', id: 'CHD8'},
{value: 'chd9', id: 'CHD9'},
{value: 'chdh', id: 'CHDH'},
{value: 'chek1', id: 'CHEK1'},
{value: 'chek2', id: 'CHEK2'},
{value: 'cherp', id: 'CHERP'},
{value: 'chfr', id: 'CHFR'},
{value: 'chga', id: 'CHGA'},
{value: 'chgb', id: 'CHGB'},
{value: 'chi3l1', id: 'CHI3L1'},
{value: 'chi3l3', id: 'CHI3L3'},
{value: 'chia', id: 'CHIA'},
{value: 'chic1', id: 'CHIC1'},
{value: 'chic2', id: 'CHIC2'},
{value: 'chid1', id: 'CHID1'},
{value: 'chit1', id: 'CHIT1'},
{value: 'chka', id: 'CHKA'},
{value: 'chkb', id: 'CHKB'},
{value: 'chl1', id: 'CHL1'},
{value: 'chm', id: 'CHM'},
{value: 'chml', id: 'CHML'},
{value: 'chmp1a', id: 'CHMP1A'},
{value: 'chmp1b', id: 'CHMP1B'},
{value: 'chmp2a', id: 'CHMP2A'},
{value: 'chmp2b', id: 'CHMP2B'},
{value: 'chmp3', id: 'CHMP3'},
{value: 'chmp4b', id: 'CHMP4B'},
{value: 'chmp4bl1', id: 'CHMP4BL1'},
{value: 'chmp4c', id: 'CHMP4C'},
{value: 'chmp5', id: 'CHMP5'},
{value: 'chmp6', id: 'CHMP6'},
{value: 'chmp7', id: 'CHMP7'},
{value: 'chn1', id: 'CHN1'},
{value: 'chn2', id: 'CHN2'},
{value: 'chodl', id: 'CHODL'},
{value: 'chordc1', id: 'CHORDC1'},
{value: 'chp1', id: 'CHP1'},
{value: 'chp2', id: 'CHP2'},
{value: 'chpf', id: 'CHPF'},
{value: 'chpf2', id: 'CHPF2'},
{value: 'chpt1', id: 'CHPT1'},
{value: 'chrac1', id: 'CHRAC1'},
{value: 'chrd', id: 'CHRD'},
{value: 'chrdl1', id: 'CHRDL1'},
{value: 'chrdl2', id: 'CHRDL2'},
{value: 'chrm1', id: 'CHRM1'},
{value: 'chrm2', id: 'CHRM2'},
{value: 'chrm3', id: 'CHRM3'},
{value: 'chrm4', id: 'CHRM4'},
{value: 'chrm5', id: 'CHRM5'},
{value: 'chrna1', id: 'CHRNA1'},
{value: 'chrna10', id: 'CHRNA10'},
{value: 'chrna2', id: 'CHRNA2'},
{value: 'chrna3', id: 'CHRNA3'},
{value: 'chrna4', id: 'CHRNA4'},
{value: 'chrna5', id: 'CHRNA5'},
{value: 'chrna6', id: 'CHRNA6'},
{value: 'chrna7', id: 'CHRNA7'},
{value: 'chrna9', id: 'CHRNA9'},
{value: 'chrnb1', id: 'CHRNB1'},
{value: 'chrnb2', id: 'CHRNB2'},
{value: 'chrnb3', id: 'CHRNB3'},
{value: 'chrnb4', id: 'CHRNB4'},
{value: 'chrnd', id: 'CHRND'},
{value: 'chrne', id: 'CHRNE'},
{value: 'chst1', id: 'CHST1'},
{value: 'chst10', id: 'CHST10'},
{value: 'chst11', id: 'CHST11'},
{value: 'chst12', id: 'CHST12'},
{value: 'chst13', id: 'CHST13'},
{value: 'chst14', id: 'CHST14'},
{value: 'chst15', id: 'CHST15'},
{value: 'chst2', id: 'CHST2'},
{value: 'chst3', id: 'CHST3'},
{value: 'chst4', id: 'CHST4'},
{value: 'chst5', id: 'CHST5'},
{value: 'chst7', id: 'CHST7'},
{value: 'chst8', id: 'CHST8'},
{value: 'chst9', id: 'CHST9'},
{value: 'chsy1', id: 'CHSY1'},
{value: 'chsy3', id: 'CHSY3'},
{value: 'chtf18', id: 'CHTF18'},
{value: 'chtf8', id: 'CHTF8'},
{value: 'chtop', id: 'CHTOP'},
{value: 'chtopl1', id: 'CHTOPL1'},
{value: 'chuk', id: 'CHUK'},
{value: 'churc1', id: 'CHURC1'},
{value: 'ciao1', id: 'CIAO1'},
{value: 'ciao2a', id: 'CIAO2A'},
{value: 'ciao2b', id: 'CIAO2B'},
{value: 'ciao3', id: 'CIAO3'},
{value: 'ciapin1', id: 'CIAPIN1'},
{value: 'ciart', id: 'CIART'},
{value: 'cib1', id: 'CIB1'},
{value: 'cib2', id: 'CIB2'},
{value: 'cib3', id: 'CIB3'},
{value: 'cib4', id: 'CIB4'},
{value: 'cibar1', id: 'CIBAR1'},
{value: 'cibar2', id: 'CIBAR2'},
{value: 'cic', id: 'CIC'},
{value: 'cidea', id: 'CIDEA'},
{value: 'cideb', id: 'CIDEB'},
{value: 'cidec', id: 'CIDEC'},
{value: 'ciita', id: 'CIITA'},
{value: 'cilk1', id: 'CILK1'},
{value: 'cilp', id: 'CILP'},
{value: 'cilp2', id: 'CILP2'},
{value: 'cinp', id: 'CINP'},
{value: 'cip2a', id: 'CIP2A'},
{value: 'cipc', id: 'CIPC'},
{value: 'cir1', id: 'CIR1'},
{value: 'cirbp', id: 'CIRBP'},
{value: 'cisd1', id: 'CISD1'},
{value: 'cisd2', id: 'CISD2'},
{value: 'cisd3', id: 'CISD3'},
{value: 'cish', id: 'CISH'},
{value: 'cit', id: 'CIT'},
{value: 'cited1', id: 'CITED1'},
{value: 'cited2', id: 'CITED2'},
{value: 'cited4', id: 'CITED4'},
{value: 'ciz1', id: 'CIZ1'},
{value: 'ckap2', id: 'CKAP2'},
{value: 'ckap2l', id: 'CKAP2L'},
{value: 'ckap4', id: 'CKAP4'},
{value: 'ckap5', id: 'CKAP5'},
{value: 'ckb', id: 'CKB'},
{value: 'cklf', id: 'CKLF'},
{value: 'ckm', id: 'CKM'},
{value: 'ckmt1', id: 'CKMT1'},
{value: 'ckmt2', id: 'CKMT2'},
{value: 'cks1b', id: 'CKS1B'},
{value: 'cks1l', id: 'CKS1L'},
{value: 'cks2', id: 'CKS2'},
{value: 'clasp1', id: 'CLASP1'},
{value: 'clasp2', id: 'CLASP2'},
{value: 'clasrp', id: 'CLASRP'},
{value: 'clba1', id: 'CLBA1'},
{value: 'clca1', id: 'CLCA1'},
{value: 'clca2', id: 'CLCA2'},
{value: 'clca4', id: 'CLCA4'},
{value: 'clca4l', id: 'CLCA4L'},
{value: 'clca5', id: 'CLCA5'},
{value: 'clcc1', id: 'CLCC1'},
{value: 'clcf1', id: 'CLCF1'},
{value: 'clcn1', id: 'CLCN1'},
{value: 'clcn2', id: 'CLCN2'},
{value: 'clcn3', id: 'CLCN3'},
{value: 'clcn4', id: 'CLCN4'},
{value: 'clcn5', id: 'CLCN5'},
{value: 'clcn6', id: 'CLCN6'},
{value: 'clcn7', id: 'CLCN7'},
{value: 'clcnka', id: 'CLCNKA'},
{value: 'clcnkb', id: 'CLCNKB'},
{value: 'cldn1', id: 'CLDN1'},
{value: 'cldn10', id: 'CLDN10'},
{value: 'cldn11', id: 'CLDN11'},
{value: 'cldn12', id: 'CLDN12'},
{value: 'cldn14', id: 'CLDN14'},
{value: 'cldn15', id: 'CLDN15'},
{value: 'cldn16', id: 'CLDN16'},
{value: 'cldn17', id: 'CLDN17'},
{value: 'cldn18', id: 'CLDN18'},
{value: 'cldn19', id: 'CLDN19'},
{value: 'cldn2', id: 'CLDN2'},
{value: 'cldn20', id: 'CLDN20'},
{value: 'cldn22', id: 'CLDN22'},
{value: 'cldn23', id: 'CLDN23'},
{value: 'cldn24', id: 'CLDN24'},
{value: 'cldn25', id: 'CLDN25'},
{value: 'cldn3', id: 'CLDN3'},
{value: 'cldn34a', id: 'CLDN34A'},
{value: 'cldn34b', id: 'CLDN34B'},
{value: 'cldn34c4', id: 'CLDN34C4'},
{value: 'cldn34d', id: 'CLDN34D'},
{value: 'cldn34e', id: 'CLDN34E'},
{value: 'cldn4', id: 'CLDN4'},
{value: 'cldn5', id: 'CLDN5'},
{value: 'cldn6', id: 'CLDN6'},
{value: 'cldn7', id: 'CLDN7'},
{value: 'cldn8', id: 'CLDN8'},
{value: 'cldn9', id: 'CLDN9'},
{value: 'cldnd1', id: 'CLDND1'},
{value: 'cldnd2', id: 'CLDND2'},
{value: 'clec10a', id: 'CLEC10A'},
{value: 'clec11a', id: 'CLEC11A'},
{value: 'clec12a', id: 'CLEC12A'},
{value: 'clec12b', id: 'CLEC12B'},
{value: 'clec14a', id: 'CLEC14A'},
{value: 'clec16a', id: 'CLEC16A'},
{value: 'clec18a', id: 'CLEC18A'},
{value: 'clec1a', id: 'CLEC1A'},
{value: 'clec1b', id: 'CLEC1B'},
{value: 'clec2d', id: 'CLEC2D'},
{value: 'clec2d2', id: 'CLEC2D2'},
{value: 'clec2dl1', id: 'CLEC2DL1'},
{value: 'clec2e', id: 'CLEC2E'},
{value: 'clec2g', id: 'CLEC2G'},
{value: 'clec2l', id: 'CLEC2L'},
{value: 'clec3a', id: 'CLEC3A'},
{value: 'clec3b', id: 'CLEC3B'},
{value: 'clec4a', id: 'CLEC4A'},
{value: 'clec4a1', id: 'CLEC4A1'},
{value: 'clec4a2', id: 'CLEC4A2'},
{value: 'clec4a3', id: 'CLEC4A3'},
{value: 'clec4b2', id: 'CLEC4B2'},
{value: 'clec4d', id: 'CLEC4D'},
{value: 'clec4e', id: 'CLEC4E'},
{value: 'clec4f', id: 'CLEC4F'},
{value: 'clec4g', id: 'CLEC4G'},
{value: 'clec4m', id: 'CLEC4M'},
{value: 'clec4n', id: 'CLEC4N'},
{value: 'clec5a', id: 'CLEC5A'},
{value: 'clec7a', id: 'CLEC7A'},
{value: 'clec9a', id: 'CLEC9A'},
{value: 'clgn', id: 'CLGN'},
{value: 'clhc1', id: 'CLHC1'},
{value: 'clic1', id: 'CLIC1'},
{value: 'clic2', id: 'CLIC2'},
{value: 'clic3', id: 'CLIC3'},
{value: 'clic4', id: 'CLIC4'},
{value: 'clic5', id: 'CLIC5'},
{value: 'clic6', id: 'CLIC6'},
{value: 'clint1', id: 'CLINT1'},
{value: 'clip1', id: 'CLIP1'},
{value: 'clip2', id: 'CLIP2'},
{value: 'clip3', id: 'CLIP3'},
{value: 'clip4', id: 'CLIP4'},
{value: 'clk1', id: 'CLK1'},
{value: 'clk2', id: 'CLK2'},
{value: 'clk3', id: 'CLK3'},
{value: 'clk4', id: 'CLK4'},
{value: 'clmn', id: 'CLMN'},
{value: 'clmp', id: 'CLMP'},
{value: 'cln3', id: 'CLN3'},
{value: 'cln5', id: 'CLN5'},
{value: 'cln6', id: 'CLN6'},
{value: 'cln8', id: 'CLN8'},
{value: 'clnk', id: 'CLNK'},
{value: 'clns1a', id: 'CLNS1A'},
{value: 'clock', id: 'CLOCK'},
{value: 'clp1', id: 'CLP1'},
{value: 'clpb', id: 'CLPB'},
{value: 'clpp', id: 'CLPP'},
{value: 'clps', id: 'CLPS'},
{value: 'clpsl2', id: 'CLPSL2'},
{value: 'clptm1', id: 'CLPTM1'},
{value: 'clptm1l', id: 'CLPTM1L'},
{value: 'clpx', id: 'CLPX'},
{value: 'clrn1', id: 'CLRN1'},
{value: 'clrn2', id: 'CLRN2'},
{value: 'clrn3', id: 'CLRN3'},
{value: 'clspn', id: 'CLSPN'},
{value: 'clstn1', id: 'CLSTN1'},
{value: 'clstn2', id: 'CLSTN2'},
{value: 'clstn3', id: 'CLSTN3'},
{value: 'clta', id: 'CLTA'},
{value: 'cltb', id: 'CLTB'},
{value: 'cltc', id: 'CLTC'},
{value: 'cltrn', id: 'CLTRN'},
{value: 'clu', id: 'CLU'},
{value: 'cluap1', id: 'CLUAP1'},
{value: 'cluh', id: 'CLUH'},
{value: 'clul1', id: 'CLUL1'},
{value: 'clvs1', id: 'CLVS1'},
{value: 'clvs2', id: 'CLVS2'},
{value: 'clybl', id: 'CLYBL'},
{value: 'cma1', id: 'CMA1'},
{value: 'cmahp', id: 'CMAHP'},
{value: 'cmas', id: 'CMAS'},
{value: 'cmbl', id: 'CMBL'},
{value: 'cmc1', id: 'CMC1'},
{value: 'cmc2', id: 'CMC2'},
{value: 'cmc4', id: 'CMC4'},
{value: 'cmip', id: 'CMIP'},
{value: 'cmklr1', id: 'CMKLR1'},
{value: 'cmklr2', id: 'CMKLR2'},
{value: 'cmpk1', id: 'CMPK1'},
{value: 'cmpk2', id: 'CMPK2'},
{value: 'cmss1', id: 'CMSS1'},
{value: 'cmtm1', id: 'CMTM1'},
{value: 'cmtm2a', id: 'CMTM2A'},
{value: 'cmtm3', id: 'CMTM3'},
{value: 'cmtm4', id: 'CMTM4'},
{value: 'cmtm5', id: 'CMTM5'},
{value: 'cmtm6', id: 'CMTM6'},
{value: 'cmtm7', id: 'CMTM7'},
{value: 'cmtm8', id: 'CMTM8'},
{value: 'cmtr1', id: 'CMTR1'},
{value: 'cmtr2', id: 'CMTR2'},
{value: 'cmya5', id: 'CMYA5'},
{value: 'cnbd1', id: 'CNBD1'},
{value: 'cnbd2', id: 'CNBD2'},
{value: 'cnbp', id: 'CNBP'},
{value: 'cndp1', id: 'CNDP1'},
{value: 'cndp2', id: 'CNDP2'},
{value: 'cnep1r1', id: 'CNEP1R1'},
{value: 'cnfn', id: 'CNFN'},
{value: 'cnga1', id: 'CNGA1'},
{value: 'cnga2', id: 'CNGA2'},
{value: 'cnga3', id: 'CNGA3'},
{value: 'cnga4', id: 'CNGA4'},
{value: 'cngb1', id: 'CNGB1'},
{value: 'cngb3', id: 'CNGB3'},
{value: 'cnih1', id: 'CNIH1'},
{value: 'cnih2', id: 'CNIH2'},
{value: 'cnih3', id: 'CNIH3'},
{value: 'cnih4', id: 'CNIH4'},
{value: 'cnksr1', id: 'CNKSR1'},
{value: 'cnksr2', id: 'CNKSR2'},
{value: 'cnksr3', id: 'CNKSR3'},
{value: 'cnmd', id: 'CNMD'},
{value: 'cnn1', id: 'CNN1'},
{value: 'cnn2', id: 'CNN2'},
{value: 'cnn3', id: 'CNN3'},
{value: 'cnnm1', id: 'CNNM1'},
{value: 'cnnm2', id: 'CNNM2'},
{value: 'cnnm3', id: 'CNNM3'},
{value: 'cnnm4', id: 'CNNM4'},
{value: 'cnot1', id: 'CNOT1'},
{value: 'cnot10', id: 'CNOT10'},
{value: 'cnot11', id: 'CNOT11'},
{value: 'cnot2', id: 'CNOT2'},
{value: 'cnot3', id: 'CNOT3'},
{value: 'cnot4', id: 'CNOT4'},
{value: 'cnot6', id: 'CNOT6'},
{value: 'cnot6l', id: 'CNOT6L'},
{value: 'cnot7', id: 'CNOT7'},
{value: 'cnot8', id: 'CNOT8'},
{value: 'cnot9', id: 'CNOT9'},
{value: 'cnp', id: 'CNP'},
{value: 'cnppd1', id: 'CNPPD1'},
{value: 'cnpy1', id: 'CNPY1'},
{value: 'cnpy2', id: 'CNPY2'},
{value: 'cnpy3', id: 'CNPY3'},
{value: 'cnpy4', id: 'CNPY4'},
{value: 'cnr1', id: 'CNR1'},
{value: 'cnr2', id: 'CNR2'},
{value: 'cnrip1', id: 'CNRIP1'},
{value: 'cnst', id: 'CNST'},
{value: 'cntd1', id: 'CNTD1'},
{value: 'cntf', id: 'CNTF'},
{value: 'cntfr', id: 'CNTFR'},
{value: 'cntln', id: 'CNTLN'},
{value: 'cntn1', id: 'CNTN1'},
{value: 'cntn2', id: 'CNTN2'},
{value: 'cntn3', id: 'CNTN3'},
{value: 'cntn4', id: 'CNTN4'},
{value: 'cntn5', id: 'CNTN5'},
{value: 'cntn6', id: 'CNTN6'},
{value: 'cntnap1', id: 'CNTNAP1'},
{value: 'cntnap2', id: 'CNTNAP2'},
{value: 'cntnap4', id: 'CNTNAP4'},
{value: 'cntnap5a', id: 'CNTNAP5A'},
{value: 'cntnap5b', id: 'CNTNAP5B'},
{value: 'cntnap5c', id: 'CNTNAP5C'},
{value: 'cntrl', id: 'CNTRL'},
{value: 'cntrob', id: 'CNTROB'},
{value: 'coa3', id: 'COA3'},
{value: 'coa4', id: 'COA4'},
{value: 'coa5', id: 'COA5'},
{value: 'coa6', id: 'COA6'},
{value: 'coa7', id: 'COA7'},
{value: 'coa8', id: 'COA8'},
{value: 'coasy', id: 'COASY'},
{value: 'cobl', id: 'COBL'},
{value: 'cobll1', id: 'COBLL1'},
{value: 'coch', id: 'COCH'},
{value: 'cog1', id: 'COG1'},
{value: 'cog2', id: 'COG2'},
{value: 'cog3', id: 'COG3'},
{value: 'cog4', id: 'COG4'},
{value: 'cog5', id: 'COG5'},
{value: 'cog6', id: 'COG6'},
{value: 'cog7', id: 'COG7'},
{value: 'cog8', id: 'COG8'},
{value: 'coil', id: 'COIL'},
{value: 'col10a1', id: 'COL10A1'},
{value: 'col11a1', id: 'COL11A1'},
{value: 'col11a2', id: 'COL11A2'},
{value: 'col12a1', id: 'COL12A1'},
{value: 'col13a1', id: 'COL13A1'},
{value: 'col14a1', id: 'COL14A1'},
{value: 'col15a1', id: 'COL15A1'},
{value: 'col16a1', id: 'COL16A1'},
{value: 'col17a1', id: 'COL17A1'},
{value: 'col18a1', id: 'COL18A1'},
{value: 'col19a1', id: 'COL19A1'},
{value: 'col1a1', id: 'COL1A1'},
{value: 'col1a2', id: 'COL1A2'},
{value: 'col20a1', id: 'COL20A1'},
{value: 'col22a1', id: 'COL22A1'},
{value: 'col23a1', id: 'COL23A1'},
{value: 'col24a1', id: 'COL24A1'},
{value: 'col25a1', id: 'COL25A1'},
{value: 'col26a1', id: 'COL26A1'},
{value: 'col27a1', id: 'COL27A1'},
{value: 'col28a1', id: 'COL28A1'},
{value: 'col2a1', id: 'COL2A1'},
{value: 'col3a1', id: 'COL3A1'},
{value: 'col4a1', id: 'COL4A1'},
{value: 'col4a2', id: 'COL4A2'},
{value: 'col4a3', id: 'COL4A3'},
{value: 'col4a4', id: 'COL4A4'},
{value: 'col4a5', id: 'COL4A5'},
{value: 'col4a6', id: 'COL4A6'},
{value: 'col5a1', id: 'COL5A1'},
{value: 'col5a2', id: 'COL5A2'},
{value: 'col5a3', id: 'COL5A3'},
{value: 'col6a1', id: 'COL6A1'},
{value: 'col6a2', id: 'COL6A2'},
{value: 'col6a3', id: 'COL6A3'},
{value: 'col6a4', id: 'COL6A4'},
{value: 'col6a5', id: 'COL6A5'},
{value: 'col6a6', id: 'COL6A6'},
{value: 'col7a1', id: 'COL7A1'},
{value: 'col8a1', id: 'COL8A1'},
{value: 'col8a2', id: 'COL8A2'},
{value: 'col9a1', id: 'COL9A1'},
{value: 'col9a2', id: 'COL9A2'},
{value: 'col9a3', id: 'COL9A3'},
{value: 'colca2', id: 'COLCA2'},
{value: 'colec10', id: 'COLEC10'},
{value: 'colec11', id: 'COLEC11'},
{value: 'colec12', id: 'COLEC12'},
{value: 'colgalt1', id: 'COLGALT1'},
{value: 'colgalt2', id: 'COLGALT2'},
{value: 'colq', id: 'COLQ'},
{value: 'commd1', id: 'COMMD1'},
{value: 'commd10', id: 'COMMD10'},
{value: 'commd2', id: 'COMMD2'},
{value: 'commd3', id: 'COMMD3'},
{value: 'commd4', id: 'COMMD4'},
{value: 'commd5', id: 'COMMD5'},
{value: 'commd6', id: 'COMMD6'},
{value: 'commd7', id: 'COMMD7'},
{value: 'commd8', id: 'COMMD8'},
{value: 'commd9', id: 'COMMD9'},
{value: 'comp', id: 'COMP'},
{value: 'comt', id: 'COMT'},
{value: 'comtd1', id: 'COMTD1'},
{value: 'cop1', id: 'COP1'},
{value: 'copa', id: 'COPA'},
{value: 'copb1', id: 'COPB1'},
{value: 'copb2', id: 'COPB2'},
{value: 'cope', id: 'COPE'},
{value: 'copg1', id: 'COPG1'},
{value: 'copg2', id: 'COPG2'},
{value: 'coprs', id: 'COPRS'},
{value: 'cops2', id: 'COPS2'},
{value: 'cops3', id: 'COPS3'},
{value: 'cops4', id: 'COPS4'},
{value: 'cops5', id: 'COPS5'},
{value: 'cops6', id: 'COPS6'},
{value: 'cops7a', id: 'COPS7A'},
{value: 'cops7b', id: 'COPS7B'},
{value: 'cops8', id: 'COPS8'},
{value: 'cops9', id: 'COPS9'},
{value: 'copz1', id: 'COPZ1'},
{value: 'copz2', id: 'COPZ2'},
{value: 'coq10a', id: 'COQ10A'},
{value: 'coq10b', id: 'COQ10B'},
{value: 'coq2', id: 'COQ2'},
{value: 'coq3', id: 'COQ3'},
{value: 'coq4', id: 'COQ4'},
{value: 'coq5', id: 'COQ5'},
{value: 'coq6', id: 'COQ6'},
{value: 'coq7', id: 'COQ7'},
{value: 'coq8a', id: 'COQ8A'},
{value: 'coq8b', id: 'COQ8B'},
{value: 'coq9', id: 'COQ9'},
{value: 'corin', id: 'CORIN'},
{value: 'coro1a', id: 'CORO1A'},
{value: 'coro1b', id: 'CORO1B'},
{value: 'coro1c', id: 'CORO1C'},
{value: 'coro2a', id: 'CORO2A'},
{value: 'coro2b', id: 'CORO2B'},
{value: 'coro6', id: 'CORO6'},
{value: 'coro7', id: 'CORO7'},
{value: 'cort', id: 'CORT'},
{value: 'cotl1', id: 'COTL1'},
{value: 'cox10', id: 'COX10'},
{value: 'cox11', id: 'COX11'},
{value: 'cox14', id: 'COX14'},
{value: 'cox15', id: 'COX15'},
{value: 'cox16', id: 'COX16'},
{value: 'cox17', id: 'COX17'},
{value: 'cox18', id: 'COX18'},
{value: 'cox19', id: 'COX19'},
{value: 'cox20', id: 'COX20'},
{value: 'cox4i1', id: 'COX4I1'},
{value: 'cox4i2', id: 'COX4I2'},
{value: 'cox5a', id: 'COX5A'},
{value: 'cox5b', id: 'COX5B'},
{value: 'cox6a1', id: 'COX6A1'},
{value: 'cox6a2', id: 'COX6A2'},
{value: 'cox6b1', id: 'COX6B1'},
{value: 'cox6b2', id: 'COX6B2'},
{value: 'cox6c-ps1', id: 'COX6C-PS1'},
{value: 'cox6c', id: 'COX6C'},
{value: 'cox7a1', id: 'COX7A1'},
{value: 'cox7a2', id: 'COX7A2'},
{value: 'cox7a2l', id: 'COX7A2L'},
{value: 'cox7a2l2', id: 'COX7A2L2'},
{value: 'cox7b', id: 'COX7B'},
{value: 'cox7b2', id: 'COX7B2'},
{value: 'cox7c', id: 'COX7C'},
{value: 'cox8a', id: 'COX8A'},
{value: 'cox8b', id: 'COX8B'},
{value: 'cox8c', id: 'COX8C'},
{value: 'cp', id: 'CP'},
{value: 'cpa1', id: 'CPA1'},
{value: 'cpa2', id: 'CPA2'},
{value: 'cpa3', id: 'CPA3'},
{value: 'cpa4', id: 'CPA4'},
{value: 'cpa5', id: 'CPA5'},
{value: 'cpa6', id: 'CPA6'},
{value: 'cpamd8', id: 'CPAMD8'},
{value: 'cpb1', id: 'CPB1'},
{value: 'cpb2', id: 'CPB2'},
{value: 'cpd', id: 'CPD'},
{value: 'cpe', id: 'CPE'},
{value: 'cpeb1', id: 'CPEB1'},
{value: 'cpeb2', id: 'CPEB2'},
{value: 'cpeb3', id: 'CPEB3'},
{value: 'cpeb4', id: 'CPEB4'},
{value: 'cped1', id: 'CPED1'},
{value: 'cpg1', id: 'CPG1'},
{value: 'cphx1', id: 'CPHX1'},
{value: 'cplane1', id: 'CPLANE1'},
{value: 'cplane2', id: 'CPLANE2'},
{value: 'cplx1', id: 'CPLX1'},
{value: 'cplx2', id: 'CPLX2'},
{value: 'cplx3', id: 'CPLX3'},
{value: 'cplx4', id: 'CPLX4'},
{value: 'cpm', id: 'CPM'},
{value: 'cpn1', id: 'CPN1'},
{value: 'cpn2', id: 'CPN2'},
{value: 'cpne1', id: 'CPNE1'},
{value: 'cpne2', id: 'CPNE2'},
{value: 'cpne3', id: 'CPNE3'},
{value: 'cpne4', id: 'CPNE4'},
{value: 'cpne5', id: 'CPNE5'},
{value: 'cpne6', id: 'CPNE6'},
{value: 'cpne7', id: 'CPNE7'},
{value: 'cpne8', id: 'CPNE8'},
{value: 'cpne9', id: 'CPNE9'},
{value: 'cpox', id: 'CPOX'},
{value: 'cpped1', id: 'CPPED1'},
{value: 'cpq', id: 'CPQ'},
{value: 'cps1', id: 'CPS1'},
{value: 'cpsf1', id: 'CPSF1'},
{value: 'cpsf2', id: 'CPSF2'},
{value: 'cpsf3', id: 'CPSF3'},
{value: 'cpsf4', id: 'CPSF4'},
{value: 'cpsf6', id: 'CPSF6'},
{value: 'cpsf7', id: 'CPSF7'},
{value: 'cpt1a', id: 'CPT1A'},
{value: 'cpt1b', id: 'CPT1B'},
{value: 'cpt1c', id: 'CPT1C'},
{value: 'cpt2', id: 'CPT2'},
{value: 'cptp', id: 'CPTP'},
{value: 'cpvl', id: 'CPVL'},
{value: 'cpxcr1', id: 'CPXCR1'},
{value: 'cpxm1', id: 'CPXM1'},
{value: 'cpxm2', id: 'CPXM2'},
{value: 'cpz', id: 'CPZ'},
{value: 'cr1l', id: 'CR1L'},
{value: 'cr2', id: 'CR2'},
{value: 'crabp1', id: 'CRABP1'},
{value: 'crabp2', id: 'CRABP2'},
{value: 'cracd', id: 'CRACD'},
{value: 'cracdl', id: 'CRACDL'},
{value: 'cracr2a', id: 'CRACR2A'},
{value: 'cracr2b', id: 'CRACR2B'},
{value: 'cradd', id: 'CRADD'},
{value: 'cramp1', id: 'CRAMP1'},
{value: 'crat', id: 'CRAT'},
{value: 'crb1', id: 'CRB1'},
{value: 'crb2', id: 'CRB2'},
{value: 'crb3', id: 'CRB3'},
{value: 'crbn', id: 'CRBN'},
{value: 'crcp', id: 'CRCP'},
{value: 'crct1', id: 'CRCT1'},
{value: 'creb1', id: 'CREB1'},
{value: 'creb3', id: 'CREB3'},
{value: 'creb3l1', id: 'CREB3L1'},
{value: 'creb3l2', id: 'CREB3L2'},
{value: 'creb3l3', id: 'CREB3L3'},
{value: 'creb3l4', id: 'CREB3L4'},
{value: 'creb5', id: 'CREB5'},
{value: 'crebbp', id: 'CREBBP'},
{value: 'crebl2', id: 'CREBL2'},
{value: 'crebrf', id: 'CREBRF'},
{value: 'crebzf', id: 'CREBZF'},
{value: 'creg1', id: 'CREG1'},
{value: 'creg2', id: 'CREG2'},
{value: 'creld1', id: 'CRELD1'},
{value: 'creld2', id: 'CRELD2'},
{value: 'crem', id: 'CREM'},
{value: 'crh', id: 'CRH'},
{value: 'crhbp', id: 'CRHBP'},
{value: 'crhr1', id: 'CRHR1'},
{value: 'crhr2', id: 'CRHR2'},
{value: 'crim1', id: 'CRIM1'},
{value: 'crip1', id: 'CRIP1'},
{value: 'crip2', id: 'CRIP2'},
{value: 'crip3', id: 'CRIP3'},
{value: 'cript', id: 'CRIPT'},
{value: 'crisp1', id: 'CRISP1'},
{value: 'crisp2', id: 'CRISP2'},
{value: 'crisp3', id: 'CRISP3'},
{value: 'crispld1', id: 'CRISPLD1'},
{value: 'crispld2', id: 'CRISPLD2'},
{value: 'crk', id: 'CRK'},
{value: 'crkl', id: 'CRKL'},
{value: 'crlf1', id: 'CRLF1'},
{value: 'crlf2', id: 'CRLF2'},
{value: 'crlf3', id: 'CRLF3'},
{value: 'crls1', id: 'CRLS1'},
{value: 'crmp1', id: 'CRMP1'},
{value: 'crnkl1', id: 'CRNKL1'},
{value: 'crnn', id: 'CRNN'},
{value: 'crocc', id: 'CROCC'},
{value: 'crocc2', id: 'CROCC2'},
{value: 'crot', id: 'CROT'},
{value: 'crp', id: 'CRP'},
{value: 'crppa', id: 'CRPPA'},
{value: 'crtac1', id: 'CRTAC1'},
{value: 'crtam', id: 'CRTAM'},
{value: 'crtapl1', id: 'CRTAPL1'},
{value: 'crtc1', id: 'CRTC1'},
{value: 'crtc2', id: 'CRTC2'},
{value: 'crtc3', id: 'CRTC3'},
{value: 'crx', id: 'CRX'},
{value: 'cry1', id: 'CRY1'},
{value: 'cry2', id: 'CRY2'},
{value: 'cryaa', id: 'CRYAA'},
{value: 'cryab', id: 'CRYAB'},
{value: 'cryba1', id: 'CRYBA1'},
{value: 'cryba2', id: 'CRYBA2'},
{value: 'cryba4', id: 'CRYBA4'},
{value: 'crybb1', id: 'CRYBB1'},
{value: 'crybb2', id: 'CRYBB2'},
{value: 'crybb3', id: 'CRYBB3'},
{value: 'crybg1', id: 'CRYBG1'},
{value: 'crybg2', id: 'CRYBG2'},
{value: 'crybg3', id: 'CRYBG3'},
{value: 'cryga', id: 'CRYGA'},
{value: 'crygb', id: 'CRYGB'},
{value: 'crygc', id: 'CRYGC'},
{value: 'crygd', id: 'CRYGD'},
{value: 'cryge', id: 'CRYGE'},
{value: 'crygf', id: 'CRYGF'},
{value: 'crygn', id: 'CRYGN'},
{value: 'crygs', id: 'CRYGS'},
{value: 'cryl1', id: 'CRYL1'},
{value: 'crym', id: 'CRYM'},
{value: 'cryz', id: 'CRYZ'},
{value: 'cryzl1', id: 'CRYZL1'},
{value: 'cs', id: 'CS'},
{value: 'csad', id: 'CSAD'},
{value: 'csap1', id: 'CSAP1'},
{value: 'csdc2', id: 'CSDC2'},
{value: 'csde1', id: 'CSDE1'},
{value: 'cse1l', id: 'CSE1L'},
{value: 'csf1', id: 'CSF1'},
{value: 'csf1r', id: 'CSF1R'},
{value: 'csf2', id: 'CSF2'},
{value: 'csf2ra', id: 'CSF2RA'},
{value: 'csf2rb', id: 'CSF2RB'},
{value: 'csf3', id: 'CSF3'},
{value: 'csf3r', id: 'CSF3R'},
{value: 'csgalnact1', id: 'CSGALNACT1'},
{value: 'csgalnact2', id: 'CSGALNACT2'},
{value: 'csk', id: 'CSK'},
{value: 'csmd1', id: 'CSMD1'},
{value: 'csmd2', id: 'CSMD2'},
{value: 'csmd3', id: 'CSMD3'},
{value: 'csn1s1', id: 'CSN1S1'},
{value: 'csn1s2a', id: 'CSN1S2A'},
{value: 'csn1s2b', id: 'CSN1S2B'},
{value: 'csn2', id: 'CSN2'},
{value: 'csn3', id: 'CSN3'},
{value: 'csnk1a1', id: 'CSNK1A1'},
{value: 'csnk1d', id: 'CSNK1D'},
{value: 'csnk1e', id: 'CSNK1E'},
{value: 'csnk1g1', id: 'CSNK1G1'},
{value: 'csnk1g2', id: 'CSNK1G2'},
{value: 'csnk1g3', id: 'CSNK1G3'},
{value: 'csnk2a1', id: 'CSNK2A1'},
{value: 'csnk2a2', id: 'CSNK2A2'},
{value: 'csnk2b', id: 'CSNK2B'},
{value: 'csnka2ip', id: 'CSNKA2IP'},
{value: 'cspg4', id: 'CSPG4'},
{value: 'cspg4b', id: 'CSPG4B'},
{value: 'cspg5', id: 'CSPG5'},
{value: 'cspp1', id: 'CSPP1'},
{value: 'csprs', id: 'CSPRS'},
{value: 'csrnp1', id: 'CSRNP1'},
{value: 'csrnp2', id: 'CSRNP2'},
{value: 'csrnp3', id: 'CSRNP3'},
{value: 'csrp1', id: 'CSRP1'},
{value: 'csrp2', id: 'CSRP2'},
{value: 'csrp3', id: 'CSRP3'},
{value: 'cst11', id: 'CST11'},
{value: 'cst12', id: 'CST12'},
{value: 'cst13', id: 'CST13'},
{value: 'cst3', id: 'CST3'},
{value: 'cst5', id: 'CST5'},
{value: 'cst6', id: 'CST6'},
{value: 'cst7', id: 'CST7'},
{value: 'cst8', id: 'CST8'},
{value: 'cst9l', id: 'CST9L'},
{value: 'csta', id: 'CSTA'},
{value: 'cstb', id: 'CSTB'},
{value: 'cstf1', id: 'CSTF1'},
{value: 'cstf2', id: 'CSTF2'},
{value: 'cstf2t', id: 'CSTF2T'},
{value: 'cstf3', id: 'CSTF3'},
{value: 'cstl1', id: 'CSTL1'},
{value: 'ct45a9', id: 'CT45A9'},
{value: 'ct47b1', id: 'CT47B1'},
{value: 'ct55', id: 'CT55'},
{value: 'ctag2', id: 'CTAG2'},
{value: 'ctbp1', id: 'CTBP1'},
{value: 'ctbp2', id: 'CTBP2'},
{value: 'ctbs', id: 'CTBS'},
{value: 'ctc1', id: 'CTC1'},
{value: 'ctcf', id: 'CTCF'},
{value: 'ctcfl', id: 'CTCFL'},
{value: 'ctdnep1', id: 'CTDNEP1'},
{value: 'ctdp1', id: 'CTDP1'},
{value: 'ctdsp1', id: 'CTDSP1'},
{value: 'ctdsp2', id: 'CTDSP2'},
{value: 'ctdspl', id: 'CTDSPL'},
{value: 'ctdspl2', id: 'CTDSPL2'},
{value: 'ctf1', id: 'CTF1'},
{value: 'ctf2', id: 'CTF2'},
{value: 'cth', id: 'CTH'},
{value: 'cthrc1', id: 'CTHRC1'},
{value: 'ctif', id: 'CTIF'},
{value: 'ctla2a', id: 'CTLA2A'},
{value: 'ctla4', id: 'CTLA4'},
{value: 'ctnna1', id: 'CTNNA1'},
{value: 'ctnna2', id: 'CTNNA2'},
{value: 'ctnna3', id: 'CTNNA3'},
{value: 'ctnnal1', id: 'CTNNAL1'},
{value: 'ctnnb1', id: 'CTNNB1'},
{value: 'ctnnbip1', id: 'CTNNBIP1'},
{value: 'ctnnbl1', id: 'CTNNBL1'},
{value: 'ctnnd1', id: 'CTNND1'},
{value: 'ctnnd2', id: 'CTNND2'},
{value: 'ctns', id: 'CTNS'},
{value: 'ctps1', id: 'CTPS1'},
{value: 'ctps2', id: 'CTPS2'},
{value: 'ctr9', id: 'CTR9'},
{value: 'ctrb1', id: 'CTRB1'},
{value: 'ctrc', id: 'CTRC'},
{value: 'ctrl', id: 'CTRL'},
{value: 'cts7', id: 'CTS7'},
{value: 'cts8', id: 'CTS8'},
{value: 'ctsa', id: 'CTSA'},
{value: 'ctsb', id: 'CTSB'},
{value: 'ctsc', id: 'CTSC'},
{value: 'ctsd', id: 'CTSD'},
{value: 'ctse', id: 'CTSE'},
{value: 'ctsf', id: 'CTSF'},
{value: 'ctsg', id: 'CTSG'},
{value: 'ctsh', id: 'CTSH'},
{value: 'ctsj', id: 'CTSJ'},
{value: 'ctsk', id: 'CTSK'},
{value: 'ctsl', id: 'CTSL'},
{value: 'ctsll3', id: 'CTSLL3'},
{value: 'ctsm', id: 'CTSM'},
{value: 'ctso', id: 'CTSO'},
{value: 'ctsq', id: 'CTSQ'},
{value: 'ctsql2', id: 'CTSQL2'},
{value: 'ctsr', id: 'CTSR'},
{value: 'ctss', id: 'CTSS'},
{value: 'ctsw', id: 'CTSW'},
{value: 'ctsz', id: 'CTSZ'},
{value: 'cttn', id: 'CTTN'},
{value: 'cttnbp2', id: 'CTTNBP2'},
{value: 'cttnbp2nl', id: 'CTTNBP2NL'},
{value: 'ctu1', id: 'CTU1'},
{value: 'ctu2', id: 'CTU2'},
{value: 'ctxn1', id: 'CTXN1'},
{value: 'ctxn2', id: 'CTXN2'},
{value: 'ctxn3', id: 'CTXN3'},
{value: 'cubn', id: 'CUBN'},
{value: 'cuedc1', id: 'CUEDC1'},
{value: 'cuedc2', id: 'CUEDC2'},
{value: 'cul1', id: 'CUL1'},
{value: 'cul2', id: 'CUL2'},
{value: 'cul3', id: 'CUL3'},
{value: 'cul4a', id: 'CUL4A'},
{value: 'cul4b', id: 'CUL4B'},
{value: 'cul5', id: 'CUL5'},
{value: 'cul7', id: 'CUL7'},
{value: 'cul9', id: 'CUL9'},
{value: 'cuta', id: 'CUTA'},
{value: 'cutc', id: 'CUTC'},
{value: 'cux1', id: 'CUX1'},
{value: 'cux2', id: 'CUX2'},
{value: 'cuzd1', id: 'CUZD1'},
{value: 'cwc15', id: 'CWC15'},
{value: 'cwc22', id: 'CWC22'},
{value: 'cwc25', id: 'CWC25'},
{value: 'cwc27', id: 'CWC27'},
{value: 'cwf19l1', id: 'CWF19L1'},
{value: 'cwf19l2', id: 'CWF19L2'},
{value: 'cwh43', id: 'CWH43'},
{value: 'cx3cl1', id: 'CX3CL1'},
{value: 'cx3cr1', id: 'CX3CR1'},
{value: 'cxadr', id: 'CXADR'},
{value: 'cxadrl1', id: 'CXADRL1'},
{value: 'cxcl1', id: 'CXCL1'},
{value: 'cxcl10', id: 'CXCL10'},
{value: 'cxcl11', id: 'CXCL11'},
{value: 'cxcl12', id: 'CXCL12'},
{value: 'cxcl13', id: 'CXCL13'},
{value: 'cxcl14', id: 'CXCL14'},
{value: 'cxcl16', id: 'CXCL16'},
{value: 'cxcl17', id: 'CXCL17'},
{value: 'cxcl2', id: 'CXCL2'},
{value: 'cxcl3', id: 'CXCL3'},
{value: 'cxcl6', id: 'CXCL6'},
{value: 'cxcl9', id: 'CXCL9'},
{value: 'cxcr1', id: 'CXCR1'},
{value: 'cxcr2', id: 'CXCR2'},
{value: 'cxcr3', id: 'CXCR3'},
{value: 'cxcr4', id: 'CXCR4'},
{value: 'cxcr5', id: 'CXCR5'},
{value: 'cxcr6', id: 'CXCR6'},
{value: 'cxhxorf65', id: 'CXHXORF65'},
{value: 'cxxc1', id: 'CXXC1'},
{value: 'cxxc4', id: 'CXXC4'},
{value: 'cxxc5', id: 'CXXC5'},
{value: 'cyb561', id: 'CYB561'},
{value: 'cyb561a3', id: 'CYB561A3'},
{value: 'cyb561d1', id: 'CYB561D1'},
{value: 'cyb561d2', id: 'CYB561D2'},
{value: 'cyb5a', id: 'CYB5A'},
{value: 'cyb5b', id: 'CYB5B'},
{value: 'cyb5d1', id: 'CYB5D1'},
{value: 'cyb5d2', id: 'CYB5D2'},
{value: 'cyb5r1', id: 'CYB5R1'},
{value: 'cyb5r2', id: 'CYB5R2'},
{value: 'cyb5r3', id: 'CYB5R3'},
{value: 'cyb5r4', id: 'CYB5R4'},
{value: 'cyb5rl', id: 'CYB5RL'},
{value: 'cyba', id: 'CYBA'},
{value: 'cybb', id: 'CYBB'},
{value: 'cybc1', id: 'CYBC1'},
{value: 'cybrd1', id: 'CYBRD1'},
{value: 'cyc1', id: 'CYC1'},
{value: 'cycs', id: 'CYCS'},
{value: 'cyct', id: 'CYCT'},
{value: 'cyfip1', id: 'CYFIP1'},
{value: 'cyfip2', id: 'CYFIP2'},
{value: 'cygb', id: 'CYGB'},
{value: 'cyhr1', id: 'CYHR1'},
{value: 'cylc1', id: 'CYLC1'},
{value: 'cylc2', id: 'CYLC2'},
{value: 'cyld-ps1', id: 'CYLD-PS1'},
{value: 'cyld', id: 'CYLD'},
{value: 'cym', id: 'CYM'},
{value: 'cyp11a1', id: 'CYP11A1'},
{value: 'cyp11b1', id: 'CYP11B1'},
{value: 'cyp11b2', id: 'CYP11B2'},
{value: 'cyp11b3', id: 'CYP11B3'},
{value: 'cyp17a1', id: 'CYP17A1'},
{value: 'cyp19a1', id: 'CYP19A1'},
{value: 'cyp1a1', id: 'CYP1A1'},
{value: 'cyp1a2', id: 'CYP1A2'},
{value: 'cyp1b1', id: 'CYP1B1'},
{value: 'cyp20a1', id: 'CYP20A1'},
{value: 'cyp21a1', id: 'CYP21A1'},
{value: 'cyp24a1', id: 'CYP24A1'},
{value: 'cyp26a1', id: 'CYP26A1'},
{value: 'cyp26b1', id: 'CYP26B1'},
{value: 'cyp26c1', id: 'CYP26C1'},
{value: 'cyp27a1', id: 'CYP27A1'},
{value: 'cyp27b1', id: 'CYP27B1'},
{value: 'cyp2a1', id: 'CYP2A1'},
{value: 'cyp2a2', id: 'CYP2A2'},
{value: 'cyp2a3', id: 'CYP2A3'},
{value: 'cyp2ab1', id: 'CYP2AB1'},
{value: 'cyp2b1', id: 'CYP2B1'},
{value: 'cyp2b12', id: 'CYP2B12'},
{value: 'cyp2b15', id: 'CYP2B15'},
{value: 'cyp2b2', id: 'CYP2B2'},
{value: 'cyp2b21', id: 'CYP2B21'},
{value: 'cyp2b3', id: 'CYP2B3'},
{value: 'cyp2c11', id: 'CYP2C11'},
{value: 'cyp2c12', id: 'CYP2C12'},
{value: 'cyp2c13', id: 'CYP2C13'},
{value: 'cyp2c22', id: 'CYP2C22'},
{value: 'cyp2c23', id: 'CYP2C23'},
{value: 'cyp2c24', id: 'CYP2C24'},
{value: 'cyp2c6v1', id: 'CYP2C6V1'},
{value: 'cyp2c7', id: 'CYP2C7'},
{value: 'cyp2d1', id: 'CYP2D1'},
{value: 'cyp2d2', id: 'CYP2D2'},
{value: 'cyp2d3', id: 'CYP2D3'},
{value: 'cyp2d4', id: 'CYP2D4'},
{value: 'cyp2d5', id: 'CYP2D5'},
{value: 'cyp2e1', id: 'CYP2E1'},
{value: 'cyp2f4', id: 'CYP2F4'},
{value: 'cyp2g1', id: 'CYP2G1'},
{value: 'cyp2j10', id: 'CYP2J10'},
{value: 'cyp2j16', id: 'CYP2J16'},
{value: 'cyp2j3', id: 'CYP2J3'},
{value: 'cyp2j4', id: 'CYP2J4'},
{value: 'cyp2r1', id: 'CYP2R1'},
{value: 'cyp2s1', id: 'CYP2S1'},
{value: 'cyp2t1', id: 'CYP2T1'},
{value: 'cyp2u1', id: 'CYP2U1'},
{value: 'cyp2w1', id: 'CYP2W1'},
{value: 'cyp39a1', id: 'CYP39A1'},
{value: 'cyp3a18', id: 'CYP3A18'},
{value: 'cyp3a2', id: 'CYP3A2'},
{value: 'cyp3a23-3a1', id: 'CYP3A23-3A1'},
{value: 'cyp3a62', id: 'CYP3A62'},
{value: 'cyp3a71-ps', id: 'CYP3A71-PS'},
{value: 'cyp3a73', id: 'CYP3A73'},
{value: 'cyp3a9', id: 'CYP3A9'},
{value: 'cyp46a1', id: 'CYP46A1'},
{value: 'cyp4a1', id: 'CYP4A1'},
{value: 'cyp4a2', id: 'CYP4A2'},
{value: 'cyp4a3', id: 'CYP4A3'},
{value: 'cyp4a8', id: 'CYP4A8'},
{value: 'cyp4b1', id: 'CYP4B1'},
{value: 'cyp4f1', id: 'CYP4F1'},
{value: 'cyp4f17', id: 'CYP4F17'},
{value: 'cyp4f18', id: 'CYP4F18'},
{value: 'cyp4f37', id: 'CYP4F37'},
{value: 'cyp4f39', id: 'CYP4F39'},
{value: 'cyp4f4', id: 'CYP4F4'},
{value: 'cyp4f40', id: 'CYP4F40'},
{value: 'cyp4f5', id: 'CYP4F5'},
{value: 'cyp4f6', id: 'CYP4F6'},
{value: 'cyp4v3', id: 'CYP4V3'},
{value: 'cyp4x1', id: 'CYP4X1'},
{value: 'cyp51', id: 'CYP51'},
{value: 'cyp7a1', id: 'CYP7A1'},
{value: 'cyp7b1', id: 'CYP7B1'},
{value: 'cyp8b1', id: 'CYP8B1'},
{value: 'cyren', id: 'CYREN'},
{value: 'cyria', id: 'CYRIA'},
{value: 'cyrib', id: 'CYRIB'},
{value: 'cys1', id: 'CYS1'},
{value: 'cysltr1', id: 'CYSLTR1'},
{value: 'cysltr2', id: 'CYSLTR2'},
{value: 'cysrt1', id: 'CYSRT1'},
{value: 'cyss', id: 'CYSS'},
{value: 'cystm1', id: 'CYSTM1'},
{value: 'cyth1', id: 'CYTH1'},
{value: 'cyth2', id: 'CYTH2'},
{value: 'cyth3', id: 'CYTH3'},
{value: 'cyth4', id: 'CYTH4'},
{value: 'cytip', id: 'CYTIP'},
{value: 'cytl1', id: 'CYTL1'},
{value: 'cyyr1', id: 'CYYR1'},
{value: 'czib', id: 'CZIB'},
{value: 'd2hgdh', id: 'D2HGDH'},
{value: 'd430019h16rik', id: 'D430019H16RIK'},
{value: 'da2-19', id: 'DA2-19'},
{value: 'daam1', id: 'DAAM1'},
{value: 'daam2', id: 'DAAM2'},
{value: 'dab1', id: 'DAB1'},
{value: 'dab2', id: 'DAB2'},
{value: 'dab2ip', id: 'DAB2IP'},
{value: 'dach1', id: 'DACH1'},
{value: 'dach2', id: 'DACH2'},
{value: 'dact1', id: 'DACT1'},
{value: 'dact2', id: 'DACT2'},
{value: 'dact3', id: 'DACT3'},
{value: 'dad1', id: 'DAD1'},
{value: 'dag1', id: 'DAG1'},
{value: 'dagla', id: 'DAGLA'},
{value: 'daglb', id: 'DAGLB'},
{value: 'dalrd3', id: 'DALRD3'},
{value: 'dand5', id: 'DAND5'},
{value: 'dao', id: 'DAO'},
{value: 'dap', id: 'DAP'},
{value: 'dap3', id: 'DAP3'},
{value: 'dapk1', id: 'DAPK1'},
{value: 'dapk2', id: 'DAPK2'},
{value: 'dapk3', id: 'DAPK3'},
{value: 'dapl1', id: 'DAPL1'},
{value: 'dapp1', id: 'DAPP1'},
{value: 'dars1', id: 'DARS1'},
{value: 'dars2', id: 'DARS2'},
{value: 'daw1', id: 'DAW1'},
{value: 'daxx', id: 'DAXX'},
{value: 'dazap1', id: 'DAZAP1'},
{value: 'dazap2', id: 'DAZAP2'},
{value: 'dazl', id: 'DAZL'},
{value: 'dbf4', id: 'DBF4'},
{value: 'dbh', id: 'DBH'},
{value: 'dbi', id: 'DBI'},
{value: 'dbil5', id: 'DBIL5'},
{value: 'dbn1', id: 'DBN1'},
{value: 'dbndd1', id: 'DBNDD1'},
{value: 'dbndd2', id: 'DBNDD2'},
{value: 'dbnl', id: 'DBNL'},
{value: 'dbp', id: 'DBP'},
{value: 'dbr1', id: 'DBR1'},
{value: 'dbt', id: 'DBT'},
{value: 'dbx1', id: 'DBX1'},
{value: 'dbx2', id: 'DBX2'},
{value: 'dcaf1', id: 'DCAF1'},
{value: 'dcaf10', id: 'DCAF10'},
{value: 'dcaf11', id: 'DCAF11'},
{value: 'dcaf12', id: 'DCAF12'},
{value: 'dcaf12l1', id: 'DCAF12L1'},
{value: 'dcaf12l2', id: 'DCAF12L2'},
{value: 'dcaf13', id: 'DCAF13'},
{value: 'dcaf15', id: 'DCAF15'},
{value: 'dcaf17', id: 'DCAF17'},
{value: 'dcaf4', id: 'DCAF4'},
{value: 'dcaf5', id: 'DCAF5'},
{value: 'dcaf6', id: 'DCAF6'},
{value: 'dcaf7', id: 'DCAF7'},
{value: 'dcaf8', id: 'DCAF8'},
{value: 'dcaf8l1', id: 'DCAF8L1'},
{value: 'dcakd', id: 'DCAKD'},
{value: 'dcbld1', id: 'DCBLD1'},
{value: 'dcbld2', id: 'DCBLD2'},
{value: 'dcc', id: 'DCC'},
{value: 'dcdc1', id: 'DCDC1'},
{value: 'dcdc2', id: 'DCDC2'},
{value: 'dcdc2b', id: 'DCDC2B'},
{value: 'dcdc2c', id: 'DCDC2C'},
{value: 'dcdc5', id: 'DCDC5'},
{value: 'dchs1', id: 'DCHS1'},
{value: 'dchs2', id: 'DCHS2'},
{value: 'dck', id: 'DCK'},
{value: 'dclk1', id: 'DCLK1'},
{value: 'dclk2', id: 'DCLK2'},
{value: 'dclk3', id: 'DCLK3'},
{value: 'dclre1a', id: 'DCLRE1A'},
{value: 'dclre1b', id: 'DCLRE1B'},
{value: 'dclre1c', id: 'DCLRE1C'},
{value: 'dcm5', id: 'DCM5'},
{value: 'dcn', id: 'DCN'},
{value: 'dcp1a', id: 'DCP1A'},
{value: 'dcp1b', id: 'DCP1B'},
{value: 'dcp2', id: 'DCP2'},
{value: 'dcps', id: 'DCPS'},
{value: 'dcst1', id: 'DCST1'},
{value: 'dcst2', id: 'DCST2'},
{value: 'dcstamp', id: 'DCSTAMP'},
{value: 'dct', id: 'DCT'},
{value: 'dctd', id: 'DCTD'},
{value: 'dctn1', id: 'DCTN1'},
{value: 'dctn2', id: 'DCTN2'},
{value: 'dctn3', id: 'DCTN3'},
{value: 'dctn3l1', id: 'DCTN3L1'},
{value: 'dctn4', id: 'DCTN4'},
{value: 'dctn5', id: 'DCTN5'},
{value: 'dctn6', id: 'DCTN6'},
{value: 'dctpp1', id: 'DCTPP1'},
{value: 'dcun1d1', id: 'DCUN1D1'},
{value: 'dcun1d2', id: 'DCUN1D2'},
{value: 'dcun1d3', id: 'DCUN1D3'},
{value: 'dcun1d4', id: 'DCUN1D4'},
{value: 'dcun1d5', id: 'DCUN1D5'},
{value: 'dcx', id: 'DCX'},
{value: 'dcxr', id: 'DCXR'},
{value: 'dda1', id: 'DDA1'},
{value: 'ddah1', id: 'DDAH1'},
{value: 'ddah2', id: 'DDAH2'},
{value: 'ddb1', id: 'DDB1'},
{value: 'ddb2', id: 'DDB2'},
{value: 'ddc', id: 'DDC'},
{value: 'ddhd1', id: 'DDHD1'},
{value: 'ddhd2', id: 'DDHD2'},
{value: 'ddi1', id: 'DDI1'},
{value: 'ddi2', id: 'DDI2'},
{value: 'ddias', id: 'DDIAS'},
{value: 'ddit3', id: 'DDIT3'},
{value: 'ddit4', id: 'DDIT4'},
{value: 'ddit4l', id: 'DDIT4L'},
{value: 'ddit4l2', id: 'DDIT4L2'},
{value: 'ddn', id: 'DDN'},
{value: 'ddo', id: 'DDO'},
{value: 'ddost', id: 'DDOST'},
{value: 'ddr1', id: 'DDR1'},
{value: 'ddr2', id: 'DDR2'},
{value: 'ddrgk1', id: 'DDRGK1'},
{value: 'ddt', id: 'DDT'},
{value: 'ddx1', id: 'DDX1'},
{value: 'ddx10', id: 'DDX10'},
{value: 'ddx11', id: 'DDX11'},
{value: 'ddx17', id: 'DDX17'},
{value: 'ddx18', id: 'DDX18'},
{value: 'ddx19a', id: 'DDX19A'},
{value: 'ddx19b', id: 'DDX19B'},
{value: 'ddx20', id: 'DDX20'},
{value: 'ddx21', id: 'DDX21'},
{value: 'ddx23', id: 'DDX23'},
{value: 'ddx24', id: 'DDX24'},
{value: 'ddx25', id: 'DDX25'},
{value: 'ddx27', id: 'DDX27'},
{value: 'ddx28', id: 'DDX28'},
{value: 'ddx3', id: 'DDX3'},
{value: 'ddx31', id: 'DDX31'},
{value: 'ddx39a', id: 'DDX39A'},
{value: 'ddx39b', id: 'DDX39B'},
{value: 'ddx3x', id: 'DDX3X'},
{value: 'ddx3y', id: 'DDX3Y'},
{value: 'ddx4', id: 'DDX4'},
{value: 'ddx41', id: 'DDX41'},
{value: 'ddx42', id: 'DDX42'},
{value: 'ddx43', id: 'DDX43'},
{value: 'ddx46', id: 'DDX46'},
{value: 'ddx47', id: 'DDX47'},
{value: 'ddx49', id: 'DDX49'},
{value: 'ddx5', id: 'DDX5'},
{value: 'ddx50', id: 'DDX50'},
{value: 'ddx51', id: 'DDX51'},
{value: 'ddx52', id: 'DDX52'},
{value: 'ddx54', id: 'DDX54'},
{value: 'ddx55', id: 'DDX55'},
{value: 'ddx56', id: 'DDX56'},
{value: 'ddx58', id: 'DDX58'},
{value: 'ddx59', id: 'DDX59'},
{value: 'ddx6', id: 'DDX6'},
{value: 'ddx60', id: 'DDX60'},
{value: 'deaf1', id: 'DEAF1'},
{value: 'dear', id: 'DEAR'},
{value: 'decr1', id: 'DECR1'},
{value: 'decr2', id: 'DECR2'},
{value: 'dedd', id: 'DEDD'},
{value: 'dedd2', id: 'DEDD2'},
{value: 'def6', id: 'DEF6'},
{value: 'def8', id: 'DEF8'},
{value: 'defa10', id: 'DEFA10'},
{value: 'defa11', id: 'DEFA11'},
{value: 'defa24', id: 'DEFA24'},
{value: 'defa5', id: 'DEFA5'},
{value: 'defa6', id: 'DEFA6'},
{value: 'defa8', id: 'DEFA8'},
{value: 'defa9', id: 'DEFA9'},
{value: 'defal1', id: 'DEFAL1'},
{value: 'defb1', id: 'DEFB1'},
{value: 'defb10', id: 'DEFB10'},
{value: 'defb11', id: 'DEFB11'},
{value: 'defb12', id: 'DEFB12'},
{value: 'defb13', id: 'DEFB13'},
{value: 'defb14', id: 'DEFB14'},
{value: 'defb15', id: 'DEFB15'},
{value: 'defb17', id: 'DEFB17'},
{value: 'defb18', id: 'DEFB18'},
{value: 'defb2', id: 'DEFB2'},
{value: 'defb20', id: 'DEFB20'},
{value: 'defb21', id: 'DEFB21'},
{value: 'defb22', id: 'DEFB22'},
{value: 'defb23', id: 'DEFB23'},
{value: 'defb24', id: 'DEFB24'},
{value: 'defb25', id: 'DEFB25'},
{value: 'defb26', id: 'DEFB26'},
{value: 'defb27', id: 'DEFB27'},
{value: 'defb28', id: 'DEFB28'},
{value: 'defb29', id: 'DEFB29'},
{value: 'defb3', id: 'DEFB3'},
{value: 'defb30', id: 'DEFB30'},
{value: 'defb33', id: 'DEFB33'},
{value: 'defb36', id: 'DEFB36'},
{value: 'defb37', id: 'DEFB37'},
{value: 'defb38', id: 'DEFB38'},
{value: 'defb39', id: 'DEFB39'},
{value: 'defb4', id: 'DEFB4'},
{value: 'defb40', id: 'DEFB40'},
{value: 'defb41', id: 'DEFB41'},
{value: 'defb42', id: 'DEFB42'},
{value: 'defb43', id: 'DEFB43'},
{value: 'defb44', id: 'DEFB44'},
{value: 'defb49', id: 'DEFB49'},
{value: 'defb5', id: 'DEFB5'},
{value: 'defb50', id: 'DEFB50'},
{value: 'defb51', id: 'DEFB51'},
{value: 'defb52', id: 'DEFB52'},
{value: 'defb9', id: 'DEFB9'},
{value: 'degs1', id: 'DEGS1'},
{value: 'degs2', id: 'DEGS2'},
{value: 'dek', id: 'DEK'},
{value: 'dele1', id: 'DELE1'},
{value: 'dennd10', id: 'DENND10'},
{value: 'dennd11', id: 'DENND11'},
{value: 'dennd1a', id: 'DENND1A'},
{value: 'dennd1b', id: 'DENND1B'},
{value: 'dennd1c', id: 'DENND1C'},
{value: 'dennd2a', id: 'DENND2A'},
{value: 'dennd2b', id: 'DENND2B'},
{value: 'dennd2c', id: 'DENND2C'},
{value: 'dennd2d', id: 'DENND2D'},
{value: 'dennd3', id: 'DENND3'},
{value: 'dennd4a', id: 'DENND4A'},
{value: 'dennd4b', id: 'DENND4B'},
{value: 'dennd4c', id: 'DENND4C'},
{value: 'dennd5a', id: 'DENND5A'},
{value: 'dennd5b', id: 'DENND5B'},
{value: 'dennd6a', id: 'DENND6A'},
{value: 'dennd6b', id: 'DENND6B'},
{value: 'denr', id: 'DENR'},
{value: 'depdc1', id: 'DEPDC1'},
{value: 'depdc1b', id: 'DEPDC1B'},
{value: 'depdc5', id: 'DEPDC5'},
{value: 'depdc7', id: 'DEPDC7'},
{value: 'depp1', id: 'DEPP1'},
{value: 'deptor', id: 'DEPTOR'},
{value: 'dera', id: 'DERA'},
{value: 'derl1', id: 'DERL1'},
{value: 'derl2', id: 'DERL2'},
{value: 'derl3', id: 'DERL3'},
{value: 'des', id: 'DES'},
{value: 'desi1', id: 'DESI1'},
{value: 'desi2', id: 'DESI2'},
{value: 'det1', id: 'DET1'},
{value: 'deup1', id: 'DEUP1'},
{value: 'dexi', id: 'DEXI'},
{value: 'dffa', id: 'DFFA'},
{value: 'dffb', id: 'DFFB'},
{value: 'dgat1', id: 'DGAT1'},
{value: 'dgat2', id: 'DGAT2'},
{value: 'dgat2l6', id: 'DGAT2L6'},
{value: 'dgcr2', id: 'DGCR2'},
{value: 'dgcr6', id: 'DGCR6'},
{value: 'dgcr8', id: 'DGCR8'},
{value: 'dgka', id: 'DGKA'},
{value: 'dgkb', id: 'DGKB'},
{value: 'dgkd', id: 'DGKD'},
{value: 'dgke', id: 'DGKE'},
{value: 'dgkg', id: 'DGKG'},
{value: 'dgkh', id: 'DGKH'},
{value: 'dgki', id: 'DGKI'},
{value: 'dgkk', id: 'DGKK'},
{value: 'dgkq', id: 'DGKQ'},
{value: 'dgkz', id: 'DGKZ'},
{value: 'dglucy', id: 'DGLUCY'},
{value: 'dguok', id: 'DGUOK'},
{value: 'dhcr24', id: 'DHCR24'},
{value: 'dhcr7', id: 'DHCR7'},
{value: 'dhdds', id: 'DHDDS'},
{value: 'dhdh', id: 'DHDH'},
{value: 'dhfr', id: 'DHFR'},
{value: 'dhh', id: 'DHH'},
{value: 'dhodh', id: 'DHODH'},
{value: 'dhps', id: 'DHPS'},
{value: 'dhrs1', id: 'DHRS1'},
{value: 'dhrs11', id: 'DHRS11'},
{value: 'dhrs13', id: 'DHRS13'},
{value: 'dhrs2', id: 'DHRS2'},
{value: 'dhrs3', id: 'DHRS3'},
{value: 'dhrs4', id: 'DHRS4'},
{value: 'dhrs7', id: 'DHRS7'},
{value: 'dhrs7b', id: 'DHRS7B'},
{value: 'dhrs7c', id: 'DHRS7C'},
{value: 'dhrs7l1', id: 'DHRS7L1'},
{value: 'dhrs9', id: 'DHRS9'},
{value: 'dhrsx', id: 'DHRSX'},
{value: 'dhtkd1', id: 'DHTKD1'},
{value: 'dhx15', id: 'DHX15'},
{value: 'dhx16', id: 'DHX16'},
{value: 'dhx29', id: 'DHX29'},
{value: 'dhx30', id: 'DHX30'},
{value: 'dhx32', id: 'DHX32'},
{value: 'dhx33', id: 'DHX33'},
{value: 'dhx34', id: 'DHX34'},
{value: 'dhx35', id: 'DHX35'},
{value: 'dhx36', id: 'DHX36'},
{value: 'dhx37', id: 'DHX37'},
{value: 'dhx38', id: 'DHX38'},
{value: 'dhx40', id: 'DHX40'},
{value: 'dhx57', id: 'DHX57'},
{value: 'dhx58', id: 'DHX58'},
{value: 'dhx8', id: 'DHX8'},
{value: 'dhx9', id: 'DHX9'},
{value: 'diablo', id: 'DIABLO'},
{value: 'diaph1', id: 'DIAPH1'},
{value: 'diaph2', id: 'DIAPH2'},
{value: 'diaph3', id: 'DIAPH3'},
{value: 'dicer1', id: 'DICER1'},
{value: 'dido1', id: 'DIDO1'},
{value: 'dimt1', id: 'DIMT1'},
{value: 'dio1', id: 'DIO1'},
{value: 'dio2', id: 'DIO2'},
{value: 'dio3', id: 'DIO3'},
{value: 'dip2a', id: 'DIP2A'},
{value: 'dip2b', id: 'DIP2B'},
{value: 'dip2c', id: 'DIP2C'},
{value: 'dipk1a', id: 'DIPK1A'},
{value: 'dipk1b', id: 'DIPK1B'},
{value: 'dipk1c', id: 'DIPK1C'},
{value: 'dipk2a', id: 'DIPK2A'},
{value: 'dipk2b', id: 'DIPK2B'},
{value: 'diras1', id: 'DIRAS1'},
{value: 'diras2', id: 'DIRAS2'},
{value: 'diras3', id: 'DIRAS3'},
{value: 'dis3', id: 'DIS3'},
{value: 'dis3l', id: 'DIS3L'},
{value: 'dis3l2', id: 'DIS3L2'},
{value: 'disc1', id: 'DISC1'},
{value: 'disp1', id: 'DISP1'},
{value: 'disp2', id: 'DISP2'},
{value: 'disp3', id: 'DISP3'},
{value: 'dixdc1', id: 'DIXDC1'},
{value: 'dkc1', id: 'DKC1'},
{value: 'dkk1', id: 'DKK1'},
{value: 'dkk2', id: 'DKK2'},
{value: 'dkk3', id: 'DKK3'},
{value: 'dkk4', id: 'DKK4'},
{value: 'dkkl1', id: 'DKKL1'},
{value: 'dlat', id: 'DLAT'},
{value: 'dlc1', id: 'DLC1'},
{value: 'dld', id: 'DLD'},
{value: 'dlec1', id: 'DLEC1'},
{value: 'dleu7', id: 'DLEU7'},
{value: 'dlg1', id: 'DLG1'},
{value: 'dlg2', id: 'DLG2'},
{value: 'dlg3', id: 'DLG3'},
{value: 'dlg4', id: 'DLG4'},
{value: 'dlg5', id: 'DLG5'},
{value: 'dlgap1', id: 'DLGAP1'},
{value: 'dlgap2', id: 'DLGAP2'},
{value: 'dlgap3', id: 'DLGAP3'},
{value: 'dlgap4', id: 'DLGAP4'},
{value: 'dlgap5', id: 'DLGAP5'},
{value: 'dlk1', id: 'DLK1'},
{value: 'dlk2', id: 'DLK2'},
{value: 'dll1', id: 'DLL1'},
{value: 'dll3', id: 'DLL3'},
{value: 'dll4', id: 'DLL4'},
{value: 'dlst', id: 'DLST'},
{value: 'dlx1', id: 'DLX1'},
{value: 'dlx2', id: 'DLX2'},
{value: 'dlx3', id: 'DLX3'},
{value: 'dlx4', id: 'DLX4'},
{value: 'dlx5', id: 'DLX5'},
{value: 'dlx6', id: 'DLX6'},
{value: 'dlx6os1', id: 'DLX6OS1'},
{value: 'dmac1', id: 'DMAC1'},
{value: 'dmac2', id: 'DMAC2'},
{value: 'dmac2l', id: 'DMAC2L'},
{value: 'dmap1', id: 'DMAP1'},
{value: 'dmbt1', id: 'DMBT1'},
{value: 'dmbx1', id: 'DMBX1'},
{value: 'dmc1', id: 'DMC1'},
{value: 'dmd', id: 'DMD'},
{value: 'dmgdh', id: 'DMGDH'},
{value: 'dmkn', id: 'DMKN'},
{value: 'dmp1', id: 'DMP1'},
{value: 'dmpk', id: 'DMPK'},
{value: 'dmrt1', id: 'DMRT1'},
{value: 'dmrt2', id: 'DMRT2'},
{value: 'dmrt3', id: 'DMRT3'},
{value: 'dmrta1', id: 'DMRTA1'},
{value: 'dmrta2', id: 'DMRTA2'},
{value: 'dmrtb1', id: 'DMRTB1'},
{value: 'dmrtc1a', id: 'DMRTC1A'},
{value: 'dmrtc1b', id: 'DMRTC1B'},
{value: 'dmrtc1c1', id: 'DMRTC1C1'},
{value: 'dmrtc2', id: 'DMRTC2'},
{value: 'dmtf1', id: 'DMTF1'},
{value: 'dmtn', id: 'DMTN'},
{value: 'dmwd', id: 'DMWD'},
{value: 'dmxl1', id: 'DMXL1'},
{value: 'dmxl2', id: 'DMXL2'},
{value: 'dna2', id: 'DNA2'},
{value: 'dnaaf1', id: 'DNAAF1'},
{value: 'dnaaf10', id: 'DNAAF10'},
{value: 'dnaaf11', id: 'DNAAF11'},
{value: 'dnaaf2', id: 'DNAAF2'},
{value: 'dnaaf3', id: 'DNAAF3'},
{value: 'dnaaf4', id: 'DNAAF4'},
{value: 'dnaaf5', id: 'DNAAF5'},
{value: 'dnaaf6', id: 'DNAAF6'},
{value: 'dnaaf8', id: 'DNAAF8'},
{value: 'dnaaf9', id: 'DNAAF9'},
{value: 'dnah1', id: 'DNAH1'},
{value: 'dnah10', id: 'DNAH10'},
{value: 'dnah11', id: 'DNAH11'},
{value: 'dnah12', id: 'DNAH12'},
{value: 'dnah14', id: 'DNAH14'},
{value: 'dnah17', id: 'DNAH17'},
{value: 'dnah2', id: 'DNAH2'},
{value: 'dnah3', id: 'DNAH3'},
{value: 'dnah5', id: 'DNAH5'},
{value: 'dnah6', id: 'DNAH6'},
{value: 'dnah7', id: 'DNAH7'},
{value: 'dnah8', id: 'DNAH8'},
{value: 'dnah9', id: 'DNAH9'},
{value: 'dnai1', id: 'DNAI1'},
{value: 'dnai2', id: 'DNAI2'},
{value: 'dnai3', id: 'DNAI3'},
{value: 'dnai4', id: 'DNAI4'},
{value: 'dnai7', id: 'DNAI7'},
{value: 'dnaja1', id: 'DNAJA1'},
{value: 'dnaja2', id: 'DNAJA2'},
{value: 'dnaja3', id: 'DNAJA3'},
{value: 'dnaja4', id: 'DNAJA4'},
{value: 'dnajb1', id: 'DNAJB1'},
{value: 'dnajb11', id: 'DNAJB11'},
{value: 'dnajb12', id: 'DNAJB12'},
{value: 'dnajb13', id: 'DNAJB13'},
{value: 'dnajb14', id: 'DNAJB14'},
{value: 'dnajb2', id: 'DNAJB2'},
{value: 'dnajb3', id: 'DNAJB3'},
{value: 'dnajb4', id: 'DNAJB4'},
{value: 'dnajb5', id: 'DNAJB5'},
{value: 'dnajb6', id: 'DNAJB6'},
{value: 'dnajb7', id: 'DNAJB7'},
{value: 'dnajb8', id: 'DNAJB8'},
{value: 'dnajb9', id: 'DNAJB9'},
{value: 'dnajc1', id: 'DNAJC1'},
{value: 'dnajc10', id: 'DNAJC10'},
{value: 'dnajc11', id: 'DNAJC11'},
{value: 'dnajc12', id: 'DNAJC12'},
{value: 'dnajc13', id: 'DNAJC13'},
{value: 'dnajc14', id: 'DNAJC14'},
{value: 'dnajc15', id: 'DNAJC15'},
{value: 'dnajc16', id: 'DNAJC16'},
{value: 'dnajc17', id: 'DNAJC17'},
{value: 'dnajc18', id: 'DNAJC18'},
{value: 'dnajc19', id: 'DNAJC19'},
{value: 'dnajc2', id: 'DNAJC2'},
{value: 'dnajc21', id: 'DNAJC21'},
{value: 'dnajc22', id: 'DNAJC22'},
{value: 'dnajc24', id: 'DNAJC24'},
{value: 'dnajc25', id: 'DNAJC25'},
{value: 'dnajc27', id: 'DNAJC27'},
{value: 'dnajc28', id: 'DNAJC28'},
{value: 'dnajc3', id: 'DNAJC3'},
{value: 'dnajc30', id: 'DNAJC30'},
{value: 'dnajc4', id: 'DNAJC4'},
{value: 'dnajc5', id: 'DNAJC5'},
{value: 'dnajc5b', id: 'DNAJC5B'},
{value: 'dnajc5g', id: 'DNAJC5G'},
{value: 'dnajc6', id: 'DNAJC6'},
{value: 'dnajc7', id: 'DNAJC7'},
{value: 'dnajc8', id: 'DNAJC8'},
{value: 'dnajc9', id: 'DNAJC9'},
{value: 'dnal1', id: 'DNAL1'},
{value: 'dnal4', id: 'DNAL4'},
{value: 'dnali1', id: 'DNALI1'},
{value: 'dnase1', id: 'DNASE1'},
{value: 'dnase1l1', id: 'DNASE1L1'},
{value: 'dnase1l2', id: 'DNASE1L2'},
{value: 'dnase1l3', id: 'DNASE1L3'},
{value: 'dnase2', id: 'DNASE2'},
{value: 'dnase2b', id: 'DNASE2B'},
{value: 'dnd1', id: 'DND1'},
{value: 'dner', id: 'DNER'},
{value: 'dnhd1', id: 'DNHD1'},
{value: 'dnlz', id: 'DNLZ'},
{value: 'dnm1', id: 'DNM1'},
{value: 'dnm1l', id: 'DNM1L'},
{value: 'dnm2', id: 'DNM2'},
{value: 'dnm3', id: 'DNM3'},
{value: 'dnmbp', id: 'DNMBP'},
{value: 'dnmt1', id: 'DNMT1'},
{value: 'dnmt3a', id: 'DNMT3A'},
{value: 'dnmt3b', id: 'DNMT3B'},
{value: 'dnmt3l', id: 'DNMT3L'},
{value: 'dnpep', id: 'DNPEP'},
{value: 'dnph1', id: 'DNPH1'},
{value: 'dntt', id: 'DNTT'},
{value: 'dnttip1', id: 'DNTTIP1'},
{value: 'dnttip2', id: 'DNTTIP2'},
{value: 'doc2a', id: 'DOC2A'},
{value: 'doc2b', id: 'DOC2B'},
{value: 'doc2g', id: 'DOC2G'},
{value: 'dock1', id: 'DOCK1'},
{value: 'dock10', id: 'DOCK10'},
{value: 'dock11', id: 'DOCK11'},
{value: 'dock2', id: 'DOCK2'},
{value: 'dock3', id: 'DOCK3'},
{value: 'dock4', id: 'DOCK4'},
{value: 'dock5', id: 'DOCK5'},
{value: 'dock6', id: 'DOCK6'},
{value: 'dock7', id: 'DOCK7'},
{value: 'dock8', id: 'DOCK8'},
{value: 'dock9', id: 'DOCK9'},
{value: 'dohh', id: 'DOHH'},
{value: 'dok1', id: 'DOK1'},
{value: 'dok2', id: 'DOK2'},
{value: 'dok3', id: 'DOK3'},
{value: 'dok4', id: 'DOK4'},
{value: 'dok5', id: 'DOK5'},
{value: 'dok6', id: 'DOK6'},
{value: 'dok7', id: 'DOK7'},
{value: 'dolk', id: 'DOLK'},
{value: 'dolpp1', id: 'DOLPP1'},
{value: 'donson', id: 'DONSON'},
{value: 'dop1a', id: 'DOP1A'},
{value: 'dop1b', id: 'DOP1B'},
{value: 'dot1l', id: 'DOT1L'},
{value: 'doxl1', id: 'DOXL1'},
{value: 'doxl2', id: 'DOXL2'},
{value: 'dpagt1', id: 'DPAGT1'},
{value: 'dpcd', id: 'DPCD'},
{value: 'dpep1', id: 'DPEP1'},
{value: 'dpep2', id: 'DPEP2'},
{value: 'dpep3', id: 'DPEP3'},
{value: 'dpf1', id: 'DPF1'},
{value: 'dpf2', id: 'DPF2'},
{value: 'dpf3', id: 'DPF3'},
{value: 'dph1', id: 'DPH1'},
{value: 'dph2', id: 'DPH2'},
{value: 'dph3', id: 'DPH3'},
{value: 'dph5', id: 'DPH5'},
{value: 'dph6', id: 'DPH6'},
{value: 'dph7', id: 'DPH7'},
{value: 'dpm1', id: 'DPM1'},
{value: 'dpm2', id: 'DPM2'},
{value: 'dpm3', id: 'DPM3'},
{value: 'dpp10', id: 'DPP10'},
{value: 'dpp3', id: 'DPP3'},
{value: 'dpp4', id: 'DPP4'},
{value: 'dpp6', id: 'DPP6'},
{value: 'dpp7', id: 'DPP7'},
{value: 'dpp8', id: 'DPP8'},
{value: 'dpp9', id: 'DPP9'},
{value: 'dppa1-ps1', id: 'DPPA1-PS1'},
{value: 'dppa1', id: 'DPPA1'},
{value: 'dppa3', id: 'DPPA3'},
{value: 'dppa3l1', id: 'DPPA3L1'},
{value: 'dppa4', id: 'DPPA4'},
{value: 'dppa5', id: 'DPPA5'},
{value: 'dpt', id: 'DPT'},
{value: 'dpy19l1', id: 'DPY19L1'},
{value: 'dpy19l2', id: 'DPY19L2'},
{value: 'dpy19l3', id: 'DPY19L3'},
{value: 'dpy19l4', id: 'DPY19L4'},
{value: 'dpy30', id: 'DPY30'},
{value: 'dpyd', id: 'DPYD'},
{value: 'dpys', id: 'DPYS'},
{value: 'dpysl2', id: 'DPYSL2'},
{value: 'dpysl3', id: 'DPYSL3'},
{value: 'dpysl4', id: 'DPYSL4'},
{value: 'dpysl5', id: 'DPYSL5'},
{value: 'dq267100', id: 'DQ267100'},
{value: 'dqx1', id: 'DQX1'},
{value: 'dr1', id: 'DR1'},
{value: 'dram1', id: 'DRAM1'},
{value: 'dram2', id: 'DRAM2'},
{value: 'drap1', id: 'DRAP1'},
{value: 'draxin', id: 'DRAXIN'},
{value: 'drc1', id: 'DRC1'},
{value: 'drc3', id: 'DRC3'},
{value: 'drc7', id: 'DRC7'},
{value: 'drd1', id: 'DRD1'},
{value: 'drd2', id: 'DRD2'},
{value: 'drd3', id: 'DRD3'},
{value: 'drd4', id: 'DRD4'},
{value: 'drd5', id: 'DRD5'},
{value: 'drg1', id: 'DRG1'},
{value: 'drg2', id: 'DRG2'},
{value: 'drgx', id: 'DRGX'},
{value: 'drosha', id: 'DROSHA'},
{value: 'drp2', id: 'DRP2'},
{value: 'dsc1', id: 'DSC1'},
{value: 'dsc2', id: 'DSC2'},
{value: 'dsc3', id: 'DSC3'},
{value: 'dscam', id: 'DSCAM'},
{value: 'dscaml1', id: 'DSCAML1'},
{value: 'dscc1', id: 'DSCC1'},
{value: 'dse', id: 'DSE'},
{value: 'dsel', id: 'DSEL'},
{value: 'dsg1', id: 'DSG1'},
{value: 'dsg2', id: 'DSG2'},
{value: 'dsg3', id: 'DSG3'},
{value: 'dsg4', id: 'DSG4'},
{value: 'dsn1', id: 'DSN1'},
{value: 'dsp', id: 'DSP'},
{value: 'dspp', id: 'DSPP'},
{value: 'dst', id: 'DST'},
{value: 'dstn', id: 'DSTN'},
{value: 'dstyk', id: 'DSTYK'},
{value: 'dtd1', id: 'DTD1'},
{value: 'dtd2', id: 'DTD2'},
{value: 'dthd1', id: 'DTHD1'},
{value: 'dtl', id: 'DTL'},
{value: 'dtna', id: 'DTNA'},
{value: 'dtnb', id: 'DTNB'},
{value: 'dtnbp1', id: 'DTNBP1'},
{value: 'dtwd1', id: 'DTWD1'},
{value: 'dtwd2', id: 'DTWD2'},
{value: 'dtx1', id: 'DTX1'},
{value: 'dtx2', id: 'DTX2'},
{value: 'dtx3', id: 'DTX3'},
{value: 'dtx3l', id: 'DTX3L'},
{value: 'dtx4', id: 'DTX4'},
{value: 'dtymk', id: 'DTYMK'},
{value: 'duox1', id: 'DUOX1'},
{value: 'duox2', id: 'DUOX2'},
{value: 'duoxa1', id: 'DUOXA1'},
{value: 'duoxa2', id: 'DUOXA2'},
{value: 'dus1l', id: 'DUS1L'},
{value: 'dus2', id: 'DUS2'},
{value: 'dus3l', id: 'DUS3L'},
{value: 'dus4l', id: 'DUS4L'},
{value: 'dusp1', id: 'DUSP1'},
{value: 'dusp10', id: 'DUSP10'},
{value: 'dusp11', id: 'DUSP11'},
{value: 'dusp12', id: 'DUSP12'},
{value: 'dusp13', id: 'DUSP13'},
{value: 'dusp14', id: 'DUSP14'},
{value: 'dusp14l1', id: 'DUSP14L1'},
{value: 'dusp15', id: 'DUSP15'},
{value: 'dusp16', id: 'DUSP16'},
{value: 'dusp18', id: 'DUSP18'},
{value: 'dusp19', id: 'DUSP19'},
{value: 'dusp2', id: 'DUSP2'},
{value: 'dusp21', id: 'DUSP21'},
{value: 'dusp22', id: 'DUSP22'},
{value: 'dusp23', id: 'DUSP23'},
{value: 'dusp26', id: 'DUSP26'},
{value: 'dusp28', id: 'DUSP28'},
{value: 'dusp29', id: 'DUSP29'},
{value: 'dusp3', id: 'DUSP3'},
{value: 'dusp4', id: 'DUSP4'},
{value: 'dusp5', id: 'DUSP5'},
{value: 'dusp6', id: 'DUSP6'},
{value: 'dusp7', id: 'DUSP7'},
{value: 'dusp8', id: 'DUSP8'},
{value: 'dusp9', id: 'DUSP9'},
{value: 'dut', id: 'DUT'},
{value: 'dux4', id: 'DUX4'},
{value: 'dvl1', id: 'DVL1'},
{value: 'dvl2', id: 'DVL2'},
{value: 'dvl3', id: 'DVL3'},
{value: 'dxo', id: 'DXO'},
{value: 'dydc1', id: 'DYDC1'},
{value: 'dydc2', id: 'DYDC2'},
{value: 'dym', id: 'DYM'},
{value: 'dynap', id: 'DYNAP'},
{value: 'dync1h1', id: 'DYNC1H1'},
{value: 'dync1i1', id: 'DYNC1I1'},
{value: 'dync1i2', id: 'DYNC1I2'},
{value: 'dync1li1', id: 'DYNC1LI1'},
{value: 'dync1li2', id: 'DYNC1LI2'},
{value: 'dync2h1', id: 'DYNC2H1'},
{value: 'dync2i1', id: 'DYNC2I1'},
{value: 'dync2i2', id: 'DYNC2I2'},
{value: 'dync2li1', id: 'DYNC2LI1'},
{value: 'dynll1', id: 'DYNLL1'},
{value: 'dynll2', id: 'DYNLL2'},
{value: 'dynlrb1', id: 'DYNLRB1'},
{value: 'dynlrb2', id: 'DYNLRB2'},
{value: 'dynlt1', id: 'DYNLT1'},
{value: 'dynlt2', id: 'DYNLT2'},
{value: 'dynlt2b', id: 'DYNLT2B'},
{value: 'dynlt3', id: 'DYNLT3'},
{value: 'dynlt4', id: 'DYNLT4'},
{value: 'dynlt5', id: 'DYNLT5'},
{value: 'dyrk1a', id: 'DYRK1A'},
{value: 'dyrk1b', id: 'DYRK1B'},
{value: 'dyrk2', id: 'DYRK2'},
{value: 'dyrk3', id: 'DYRK3'},
{value: 'dyrk4', id: 'DYRK4'},
{value: 'dysf', id: 'DYSF'},
{value: 'dytn', id: 'DYTN'},
{value: 'dzank1', id: 'DZANK1'},
{value: 'dzip1-ps1', id: 'DZIP1-PS1'},
{value: 'dzip1', id: 'DZIP1'},
{value: 'dzip1l', id: 'DZIP1L'},
{value: 'dzip3', id: 'DZIP3'},
{value: 'e2f1', id: 'E2F1'},
{value: 'e2f2', id: 'E2F2'},
{value: 'e2f3', id: 'E2F3'},
{value: 'e2f4', id: 'E2F4'},
{value: 'e2f5', id: 'E2F5'},
{value: 'e2f6', id: 'E2F6'},
{value: 'e2f7', id: 'E2F7'},
{value: 'e2f8', id: 'E2F8'},
{value: 'e4f1', id: 'E4F1'},
{value: 'eaf1', id: 'EAF1'},
{value: 'eaf2', id: 'EAF2'},
{value: 'eapp', id: 'EAPP'},
{value: 'ear1', id: 'EAR1'},
{value: 'ears2', id: 'EARS2'},
{value: 'ears2l1', id: 'EARS2L1'},
{value: 'ebag9', id: 'EBAG9'},
{value: 'ebf1', id: 'EBF1'},
{value: 'ebf2', id: 'EBF2'},
{value: 'ebf3', id: 'EBF3'},
{value: 'ebf4', id: 'EBF4'},
{value: 'ebi3', id: 'EBI3'},
{value: 'ebna1bp2', id: 'EBNA1BP2'},
{value: 'ebp', id: 'EBP'},
{value: 'ebpl', id: 'EBPL'},
{value: 'ecd', id: 'ECD'},
{value: 'ece1', id: 'ECE1'},
{value: 'ece2', id: 'ECE2'},
{value: 'ecel1', id: 'ECEL1'},
{value: 'ech1', id: 'ECH1'},
{value: 'echdc1', id: 'ECHDC1'},
{value: 'echdc2', id: 'ECHDC2'},
{value: 'echdc3', id: 'ECHDC3'},
{value: 'echs1', id: 'ECHS1'},
{value: 'eci1', id: 'ECI1'},
{value: 'eci2', id: 'ECI2'},
{value: 'eci3', id: 'ECI3'},
{value: 'ecm1', id: 'ECM1'},
{value: 'ecm2', id: 'ECM2'},
{value: 'ecpas', id: 'ECPAS'},
{value: 'ecrg4', id: 'ECRG4'},
{value: 'ecscr', id: 'ECSCR'},
{value: 'ecsit', id: 'ECSIT'},
{value: 'ect2', id: 'ECT2'},
{value: 'ect2l', id: 'ECT2L'},
{value: 'eda', id: 'EDA'},
{value: 'eda2r', id: 'EDA2R'},
{value: 'edar', id: 'EDAR'},
{value: 'edaradd', id: 'EDARADD'},
{value: 'edc3', id: 'EDC3'},
{value: 'edc4', id: 'EDC4'},
{value: 'eddm3b', id: 'EDDM3B'},
{value: 'edem1', id: 'EDEM1'},
{value: 'edem2', id: 'EDEM2'},
{value: 'edem3', id: 'EDEM3'},
{value: 'edf1', id: 'EDF1'},
{value: 'edil3', id: 'EDIL3'},
{value: 'edn1', id: 'EDN1'},
{value: 'edn2', id: 'EDN2'},
{value: 'edn3', id: 'EDN3'},
{value: 'ednra', id: 'EDNRA'},
{value: 'ednrb', id: 'EDNRB'},
{value: 'edrf1', id: 'EDRF1'},
{value: 'eea1', id: 'EEA1'},
{value: 'eed', id: 'EED'},
{value: 'eef1a1', id: 'EEF1A1'},
{value: 'eef1a2', id: 'EEF1A2'},
{value: 'eef1akmt1', id: 'EEF1AKMT1'},
{value: 'eef1akmt2', id: 'EEF1AKMT2'},
{value: 'eef1akmt3', id: 'EEF1AKMT3'},
{value: 'eef1akmt4', id: 'EEF1AKMT4'},
{value: 'eef1b2', id: 'EEF1B2'},
{value: 'eef1d', id: 'EEF1D'},
{value: 'eef1e1', id: 'EEF1E1'},
{value: 'eef1g', id: 'EEF1G'},
{value: 'eef2', id: 'EEF2'},
{value: 'eef2k', id: 'EEF2K'},
{value: 'eef2kmt', id: 'EEF2KMT'},
{value: 'eefsec', id: 'EEFSEC'},
{value: 'eepd1', id: 'EEPD1'},
{value: 'efcab1', id: 'EFCAB1'},
{value: 'efcab10', id: 'EFCAB10'},
{value: 'efcab11', id: 'EFCAB11'},
{value: 'efcab12', id: 'EFCAB12'},
{value: 'efcab13', id: 'EFCAB13'},
{value: 'efcab14', id: 'EFCAB14'},
{value: 'efcab2', id: 'EFCAB2'},
{value: 'efcab3', id: 'EFCAB3'},
{value: 'efcab5', id: 'EFCAB5'},
{value: 'efcab6', id: 'EFCAB6'},
{value: 'efcab7', id: 'EFCAB7'},
{value: 'efcab8', id: 'EFCAB8'},
{value: 'efcab9', id: 'EFCAB9'},
{value: 'efcc1', id: 'EFCC1'},
{value: 'efemp1', id: 'EFEMP1'},
{value: 'efemp2', id: 'EFEMP2'},
{value: 'efhb', id: 'EFHB'},
{value: 'efhc1', id: 'EFHC1'},
{value: 'efhc2', id: 'EFHC2'},
{value: 'efhd1', id: 'EFHD1'},
{value: 'efhd2', id: 'EFHD2'},
{value: 'efl1', id: 'EFL1'},
{value: 'efna1', id: 'EFNA1'},
{value: 'efna2', id: 'EFNA2'},
{value: 'efna3', id: 'EFNA3'},
{value: 'efna4', id: 'EFNA4'},
{value: 'efna5', id: 'EFNA5'},
{value: 'efnb1', id: 'EFNB1'},
{value: 'efnb2', id: 'EFNB2'},
{value: 'efnb3', id: 'EFNB3'},
{value: 'efr3a', id: 'EFR3A'},
{value: 'efr3b', id: 'EFR3B'},
{value: 'efs', id: 'EFS'},
{value: 'eftud2', id: 'EFTUD2'},
{value: 'egf', id: 'EGF'},
{value: 'egfem1', id: 'EGFEM1'},
{value: 'egfl1', id: 'EGFL1'},
{value: 'egfl6', id: 'EGFL6'},
{value: 'egfl7', id: 'EGFL7'},
{value: 'egfl8', id: 'EGFL8'},
{value: 'egflam', id: 'EGFLAM'},
{value: 'egfr', id: 'EGFR'},
{value: 'egln1', id: 'EGLN1'},
{value: 'egln2', id: 'EGLN2'},
{value: 'egln3', id: 'EGLN3'},
{value: 'egr1', id: 'EGR1'},
{value: 'egr2', id: 'EGR2'},
{value: 'egr3', id: 'EGR3'},
{value: 'egr4', id: 'EGR4'},
{value: 'ehbp1', id: 'EHBP1'},
{value: 'ehbp1l1', id: 'EHBP1L1'},
{value: 'ehd1', id: 'EHD1'},
{value: 'ehd2', id: 'EHD2'},
{value: 'ehd3', id: 'EHD3'},
{value: 'ehd4', id: 'EHD4'},
{value: 'ehf', id: 'EHF'},
{value: 'ehhadh', id: 'EHHADH'},
{value: 'ehmt1', id: 'EHMT1'},
{value: 'ehmt2', id: 'EHMT2'},
{value: 'ei24', id: 'EI24'},
{value: 'eid1', id: 'EID1'},
{value: 'eid2', id: 'EID2'},
{value: 'eid2b', id: 'EID2B'},
{value: 'eif1', id: 'EIF1'},
{value: 'eif1a', id: 'EIF1A'},
{value: 'eif1ad', id: 'EIF1AD'},
{value: 'eif1ax', id: 'EIF1AX'},
{value: 'eif1b', id: 'EIF1B'},
{value: 'eif2a', id: 'EIF2A'},
{value: 'eif2ak1', id: 'EIF2AK1'},
{value: 'eif2ak2', id: 'EIF2AK2'},
{value: 'eif2ak3', id: 'EIF2AK3'},
{value: 'eif2ak4', id: 'EIF2AK4'},
{value: 'eif2b1', id: 'EIF2B1'},
{value: 'eif2b2', id: 'EIF2B2'},
{value: 'eif2b3', id: 'EIF2B3'},
{value: 'eif2b4', id: 'EIF2B4'},
{value: 'eif2b5', id: 'EIF2B5'},
{value: 'eif2d', id: 'EIF2D'},
{value: 'eif2s1', id: 'EIF2S1'},
{value: 'eif2s2', id: 'EIF2S2'},
{value: 'eif2s3', id: 'EIF2S3'},
{value: 'eif2s3y', id: 'EIF2S3Y'},
{value: 'eif3a', id: 'EIF3A'},
{value: 'eif3b', id: 'EIF3B'},
{value: 'eif3c', id: 'EIF3C'},
{value: 'eif3d', id: 'EIF3D'},
{value: 'eif3e', id: 'EIF3E'},
{value: 'eif3el1', id: 'EIF3EL1'},
{value: 'eif3f', id: 'EIF3F'},
{value: 'eif3g', id: 'EIF3G'},
{value: 'eif3h', id: 'EIF3H'},
{value: 'eif3i-ps1', id: 'EIF3I-PS1'},
{value: 'eif3i', id: 'EIF3I'},
{value: 'eif3j', id: 'EIF3J'},
{value: 'eif3k', id: 'EIF3K'},
{value: 'eif3l', id: 'EIF3L'},
{value: 'eif3m', id: 'EIF3M'},
{value: 'eif4a1', id: 'EIF4A1'},
{value: 'eif4a2', id: 'EIF4A2'},
{value: 'eif4a3', id: 'EIF4A3'},
{value: 'eif4b', id: 'EIF4B'},
{value: 'eif4e', id: 'EIF4E'},
{value: 'eif4e1b', id: 'EIF4E1B'},
{value: 'eif4e2', id: 'EIF4E2'},
{value: 'eif4e3', id: 'EIF4E3'},
{value: 'eif4ebp1', id: 'EIF4EBP1'},
{value: 'eif4ebp2', id: 'EIF4EBP2'},
{value: 'eif4ebp3', id: 'EIF4EBP3'},
{value: 'eif4enif1', id: 'EIF4ENIF1'},
{value: 'eif4g1', id: 'EIF4G1'},
{value: 'eif4g2', id: 'EIF4G2'},
{value: 'eif4g3', id: 'EIF4G3'},
{value: 'eif4h', id: 'EIF4H'},
{value: 'eif5', id: 'EIF5'},
{value: 'eif5a', id: 'EIF5A'},
{value: 'eif5a2', id: 'EIF5A2'},
{value: 'eif5b', id: 'EIF5B'},
{value: 'eif6-ps1', id: 'EIF6-PS1'},
{value: 'eif6', id: 'EIF6'},
{value: 'eipr1', id: 'EIPR1'},
{value: 'elac1', id: 'ELAC1'},
{value: 'elac2', id: 'ELAC2'},
{value: 'elane', id: 'ELANE'},
{value: 'elapor1', id: 'ELAPOR1'},
{value: 'elapor2', id: 'ELAPOR2'},
{value: 'elavl1', id: 'ELAVL1'},
{value: 'elavl2', id: 'ELAVL2'},
{value: 'elavl3', id: 'ELAVL3'},
{value: 'elavl4', id: 'ELAVL4'},
{value: 'elf1', id: 'ELF1'},
{value: 'elf2', id: 'ELF2'},
{value: 'elf3', id: 'ELF3'},
{value: 'elf4', id: 'ELF4'},
{value: 'elf5', id: 'ELF5'},
{value: 'elfn1', id: 'ELFN1'},
{value: 'elfn2', id: 'ELFN2'},
{value: 'elk1', id: 'ELK1'},
{value: 'elk3', id: 'ELK3'},
{value: 'elk4', id: 'ELK4'},
{value: 'ell', id: 'ELL'},
{value: 'ell2', id: 'ELL2'},
{value: 'ell3', id: 'ELL3'},
{value: 'elmo1', id: 'ELMO1'},
{value: 'elmo2', id: 'ELMO2'},
{value: 'elmo3', id: 'ELMO3'},
{value: 'elmod1', id: 'ELMOD1'},
{value: 'elmod2', id: 'ELMOD2'},
{value: 'elmod3', id: 'ELMOD3'},
{value: 'eln', id: 'ELN'},
{value: 'eloa', id: 'ELOA'},
{value: 'elob', id: 'ELOB'},
{value: 'eloc', id: 'ELOC'},
{value: 'elof1', id: 'ELOF1'},
{value: 'elovl1', id: 'ELOVL1'},
{value: 'elovl2', id: 'ELOVL2'},
{value: 'elovl3', id: 'ELOVL3'},
{value: 'elovl4', id: 'ELOVL4'},
{value: 'elovl5', id: 'ELOVL5'},
{value: 'elovl6', id: 'ELOVL6'},
{value: 'elovl7', id: 'ELOVL7'},
{value: 'elp1', id: 'ELP1'},
{value: 'elp2', id: 'ELP2'},
{value: 'elp3', id: 'ELP3'},
{value: 'elp4', id: 'ELP4'},
{value: 'elp5', id: 'ELP5'},
{value: 'elp6', id: 'ELP6'},
{value: 'emb', id: 'EMB'},
{value: 'emc1', id: 'EMC1'},
{value: 'emc10', id: 'EMC10'},
{value: 'emc2', id: 'EMC2'},
{value: 'emc3', id: 'EMC3'},
{value: 'emc4', id: 'EMC4'},
{value: 'emc6', id: 'EMC6'},
{value: 'emc7', id: 'EMC7'},
{value: 'emc8', id: 'EMC8'},
{value: 'emc9', id: 'EMC9'},
{value: 'emcn', id: 'EMCN'},
{value: 'emd', id: 'EMD'},
{value: 'eme1', id: 'EME1'},
{value: 'eme2', id: 'EME2'},
{value: 'emg1', id: 'EMG1'},
{value: 'emid1', id: 'EMID1'},
{value: 'emilin1', id: 'EMILIN1'},
{value: 'emilin2', id: 'EMILIN2'},
{value: 'emilin3', id: 'EMILIN3'},
{value: 'eml1', id: 'EML1'},
{value: 'eml2', id: 'EML2'},
{value: 'eml3', id: 'EML3'},
{value: 'eml4', id: 'EML4'},
{value: 'eml5', id: 'EML5'},
{value: 'eml6', id: 'EML6'},
{value: 'emp1', id: 'EMP1'},
{value: 'emp2', id: 'EMP2'},
{value: 'emp3', id: 'EMP3'},
{value: 'emsy', id: 'EMSY'},
{value: 'emx1', id: 'EMX1'},
{value: 'emx2', id: 'EMX2'},
{value: 'en1', id: 'EN1'},
{value: 'en2', id: 'EN2'},
{value: 'enah', id: 'ENAH'},
{value: 'enam', id: 'ENAM'},
{value: 'enc1', id: 'ENC1'},
{value: 'endod1', id: 'ENDOD1'},
{value: 'endog', id: 'ENDOG'},
{value: 'endou', id: 'ENDOU'},
{value: 'endov', id: 'ENDOV'},
{value: 'eng', id: 'ENG'},
{value: 'engase', id: 'ENGASE'},
{value: 'enho', id: 'ENHO'},
{value: 'enkd1', id: 'ENKD1'},
{value: 'enkur', id: 'ENKUR'},
{value: 'eno1', id: 'ENO1'},
{value: 'eno2', id: 'ENO2'},
{value: 'eno3', id: 'ENO3'},
{value: 'eno4', id: 'ENO4'},
{value: 'enoph1', id: 'ENOPH1'},
{value: 'enox1', id: 'ENOX1'},
{value: 'enox2', id: 'ENOX2'},
{value: 'enpep', id: 'ENPEP'},
{value: 'enpp1', id: 'ENPP1'},
{value: 'enpp2', id: 'ENPP2'},
{value: 'enpp3', id: 'ENPP3'},
{value: 'enpp4', id: 'ENPP4'},
{value: 'enpp5', id: 'ENPP5'},
{value: 'enpp6', id: 'ENPP6'},
{value: 'enpp7', id: 'ENPP7'},
{value: 'ensa', id: 'ENSA'},
{value: 'enthd1', id: 'ENTHD1'},
{value: 'entpd1', id: 'ENTPD1'},
{value: 'entpd2', id: 'ENTPD2'},
{value: 'entpd3', id: 'ENTPD3'},
{value: 'entpd4', id: 'ENTPD4'},
{value: 'entpd5', id: 'ENTPD5'},
{value: 'entpd6', id: 'ENTPD6'},
{value: 'entpd7', id: 'ENTPD7'},
{value: 'entpd8', id: 'ENTPD8'},
{value: 'entr1', id: 'ENTR1'},
{value: 'eny2', id: 'ENY2'},
{value: 'eogt', id: 'EOGT'},
{value: 'eola2', id: 'EOLA2'},
{value: 'eomes', id: 'EOMES'},
{value: 'ep300', id: 'EP300'},
{value: 'ep400', id: 'EP400'},
{value: 'epas1', id: 'EPAS1'},
{value: 'epb41', id: 'EPB41'},
{value: 'epb41l1', id: 'EPB41L1'},
{value: 'epb41l2', id: 'EPB41L2'},
{value: 'epb41l3', id: 'EPB41L3'},
{value: 'epb41l4a', id: 'EPB41L4A'},
{value: 'epb41l4b', id: 'EPB41L4B'},
{value: 'epb41l5', id: 'EPB41L5'},
{value: 'epb42', id: 'EPB42'},
{value: 'epc1', id: 'EPC1'},
{value: 'epc2', id: 'EPC2'},
{value: 'epc2l1', id: 'EPC2L1'},
{value: 'epcam', id: 'EPCAM'},
{value: 'epdr1', id: 'EPDR1'},
{value: 'epg5', id: 'EPG5'},
{value: 'epgn', id: 'EPGN'},
{value: 'epha1', id: 'EPHA1'},
{value: 'epha10', id: 'EPHA10'},
{value: 'epha2', id: 'EPHA2'},
{value: 'epha3', id: 'EPHA3'},
{value: 'epha4', id: 'EPHA4'},
{value: 'epha5', id: 'EPHA5'},
{value: 'epha6', id: 'EPHA6'},
{value: 'epha7', id: 'EPHA7'},
{value: 'epha8', id: 'EPHA8'},
{value: 'ephb1', id: 'EPHB1'},
{value: 'ephb2', id: 'EPHB2'},
{value: 'ephb3', id: 'EPHB3'},
{value: 'ephb4', id: 'EPHB4'},
{value: 'ephb6', id: 'EPHB6'},
{value: 'ephx1', id: 'EPHX1'},
{value: 'ephx2', id: 'EPHX2'},
{value: 'ephx3', id: 'EPHX3'},
{value: 'ephx4', id: 'EPHX4'},
{value: 'epm2a', id: 'EPM2A'},
{value: 'epm2aip1', id: 'EPM2AIP1'},
{value: 'epn1', id: 'EPN1'},
{value: 'epn2', id: 'EPN2'},
{value: 'epn3', id: 'EPN3'},
{value: 'epo', id: 'EPO'},
{value: 'epop', id: 'EPOP'},
{value: 'epor', id: 'EPOR'},
{value: 'eppin', id: 'EPPIN'},
{value: 'eppk1', id: 'EPPK1'},
{value: 'eprs', id: 'EPRS'},
{value: 'eps15', id: 'EPS15'},
{value: 'eps15l1', id: 'EPS15L1'},
{value: 'eps8', id: 'EPS8'},
{value: 'eps8l1', id: 'EPS8L1'},
{value: 'eps8l2', id: 'EPS8L2'},
{value: 'eps8l3', id: 'EPS8L3'},
{value: 'epsti1', id: 'EPSTI1'},
{value: 'epx', id: 'EPX'},
{value: 'epyc', id: 'EPYC'},
{value: 'eqtn', id: 'EQTN'},
{value: 'eral1', id: 'ERAL1'},
{value: 'erap1', id: 'ERAP1'},
{value: 'eras', id: 'ERAS'},
{value: 'erbb2', id: 'ERBB2'},
{value: 'erbb3', id: 'ERBB3'},
{value: 'erbb4', id: 'ERBB4'},
{value: 'erbin', id: 'ERBIN'},
{value: 'erc1', id: 'ERC1'},
{value: 'erc2', id: 'ERC2'},
{value: 'ercc1', id: 'ERCC1'},
{value: 'ercc2', id: 'ERCC2'},
{value: 'ercc3', id: 'ERCC3'},
{value: 'ercc4', id: 'ERCC4'},
{value: 'ercc4l1', id: 'ERCC4L1'},
{value: 'ercc5', id: 'ERCC5'},
{value: 'ercc6', id: 'ERCC6'},
{value: 'ercc6l', id: 'ERCC6L'},
{value: 'ercc6l2', id: 'ERCC6L2'},
{value: 'ercc8', id: 'ERCC8'},
{value: 'ereg', id: 'EREG'},
{value: 'erf', id: 'ERF'},
{value: 'erfe', id: 'ERFE'},
{value: 'erg', id: 'ERG'},
{value: 'erg28', id: 'ERG28'},
{value: 'ergic1', id: 'ERGIC1'},
{value: 'ergic2', id: 'ERGIC2'},
{value: 'ergic3', id: 'ERGIC3'},
{value: 'erh', id: 'ERH'},
{value: 'eri1', id: 'ERI1'},
{value: 'eri2', id: 'ERI2'},
{value: 'eri3', id: 'ERI3'},
{value: 'erich1', id: 'ERICH1'},
{value: 'erich2', id: 'ERICH2'},
{value: 'erich3', id: 'ERICH3'},
{value: 'erich4', id: 'ERICH4'},
{value: 'erich5', id: 'ERICH5'},
{value: 'erich6', id: 'ERICH6'},
{value: 'erich6b', id: 'ERICH6B'},
{value: 'erlec1', id: 'ERLEC1'},
{value: 'erlin1', id: 'ERLIN1'},
{value: 'erlin2', id: 'ERLIN2'},
{value: 'ermap', id: 'ERMAP'},
{value: 'ermard', id: 'ERMARD'},
{value: 'ermn', id: 'ERMN'},
{value: 'ermp1', id: 'ERMP1'},
{value: 'ern1', id: 'ERN1'},
{value: 'ern2', id: 'ERN2'},
{value: 'ero1a', id: 'ERO1A'},
{value: 'ero1b', id: 'ERO1B'},
{value: 'erp27', id: 'ERP27'},
{value: 'erp29', id: 'ERP29'},
{value: 'erp44', id: 'ERP44'},
{value: 'errfi1', id: 'ERRFI1'},
{value: 'ervfrd-1', id: 'ERVFRD-1'},
{value: 'ervv-2', id: 'ERVV-2'},
{value: 'esam', id: 'ESAM'},
{value: 'esco1', id: 'ESCO1'},
{value: 'esco2', id: 'ESCO2'},
{value: 'esd', id: 'ESD'},
{value: 'esf1', id: 'ESF1'},
{value: 'esm1', id: 'ESM1'},
{value: 'espl1', id: 'ESPL1'},
{value: 'espn', id: 'ESPN'},
{value: 'espnl', id: 'ESPNL'},
{value: 'esr1', id: 'ESR1'},
{value: 'esr2', id: 'ESR2'},
{value: 'esrp1', id: 'ESRP1'},
{value: 'esrp2', id: 'ESRP2'},
{value: 'esrra', id: 'ESRRA'},
{value: 'esrrb', id: 'ESRRB'},
{value: 'esrrg', id: 'ESRRG'},
{value: 'ess2', id: 'ESS2'},
{value: 'esyt1', id: 'ESYT1'},
{value: 'esyt2', id: 'ESYT2'},
{value: 'esyt3', id: 'ESYT3'},
{value: 'etaa1', id: 'ETAA1'},
{value: 'etaa1l1', id: 'ETAA1L1'},
{value: 'etf1', id: 'ETF1'},
{value: 'etfa', id: 'ETFA'},
{value: 'etfb', id: 'ETFB'},
{value: 'etfbkmt', id: 'ETFBKMT'},
{value: 'etfdh', id: 'ETFDH'},
{value: 'etfrf1', id: 'ETFRF1'},
{value: 'ethe1', id: 'ETHE1'},
{value: 'etl4', id: 'ETL4'},
{value: 'etnk1', id: 'ETNK1'},
{value: 'etnk2', id: 'ETNK2'},
{value: 'etnppl', id: 'ETNPPL'},
{value: 'ets1', id: 'ETS1'},
{value: 'ets2', id: 'ETS2'},
{value: 'etv1', id: 'ETV1'},
{value: 'etv2', id: 'ETV2'},
{value: 'etv3', id: 'ETV3'},
{value: 'etv3l', id: 'ETV3L'},
{value: 'etv4', id: 'ETV4'},
{value: 'etv5', id: 'ETV5'},
{value: 'etv6', id: 'ETV6'},
{value: 'eva1a', id: 'EVA1A'},
{value: 'eva1b', id: 'EVA1B'},
{value: 'eva1c', id: 'EVA1C'},
{value: 'evc', id: 'EVC'},
{value: 'evc2', id: 'EVC2'},
{value: 'evi2a', id: 'EVI2A'},
{value: 'evi2b', id: 'EVI2B'},
{value: 'evi5', id: 'EVI5'},
{value: 'evi5l', id: 'EVI5L'},
{value: 'evl', id: 'EVL'},
{value: 'evpl', id: 'EVPL'},
{value: 'evx1', id: 'EVX1'},
{value: 'evx2', id: 'EVX2'},
{value: 'ewsr1', id: 'EWSR1'},
{value: 'exd1', id: 'EXD1'},
{value: 'exd2', id: 'EXD2'},
{value: 'exo1', id: 'EXO1'},
{value: 'exo5', id: 'EXO5'},
{value: 'exoc1', id: 'EXOC1'},
{value: 'exoc1l', id: 'EXOC1L'},
{value: 'exoc2', id: 'EXOC2'},
{value: 'exoc3', id: 'EXOC3'},
{value: 'exoc3l1', id: 'EXOC3L1'},
{value: 'exoc3l2', id: 'EXOC3L2'},
{value: 'exoc3l4', id: 'EXOC3L4'},
{value: 'exoc4', id: 'EXOC4'},
{value: 'exoc5', id: 'EXOC5'},
{value: 'exoc6', id: 'EXOC6'},
{value: 'exoc6b', id: 'EXOC6B'},
{value: 'exoc7', id: 'EXOC7'},
{value: 'exoc8', id: 'EXOC8'},
{value: 'exog', id: 'EXOG'},
{value: 'exosc1', id: 'EXOSC1'},
{value: 'exosc10', id: 'EXOSC10'},
{value: 'exosc2', id: 'EXOSC2'},
{value: 'exosc3', id: 'EXOSC3'},
{value: 'exosc4', id: 'EXOSC4'},
{value: 'exosc5', id: 'EXOSC5'},
{value: 'exosc6', id: 'EXOSC6'},
{value: 'exosc7', id: 'EXOSC7'},
{value: 'exosc8', id: 'EXOSC8'},
{value: 'exosc9', id: 'EXOSC9'},
{value: 'exph5', id: 'EXPH5'},
{value: 'ext1', id: 'EXT1'},
{value: 'ext2', id: 'EXT2'},
{value: 'extl1', id: 'EXTL1'},
{value: 'extl2', id: 'EXTL2'},
{value: 'extl3', id: 'EXTL3'},
{value: 'eya1', id: 'EYA1'},
{value: 'eya2', id: 'EYA2'},
{value: 'eya3', id: 'EYA3'},
{value: 'eya4', id: 'EYA4'},
{value: 'ezh1', id: 'EZH1'},
{value: 'ezh2', id: 'EZH2'},
{value: 'ezhip', id: 'EZHIP'},
{value: 'ezr', id: 'EZR'},
{value: 'f10', id: 'F10'},
{value: 'f11', id: 'F11'},
{value: 'f11r', id: 'F11R'},
{value: 'f12', id: 'F12'},
{value: 'f13a1', id: 'F13A1'},
{value: 'f13b', id: 'F13B'},
{value: 'f2', id: 'F2'},
{value: 'f2r', id: 'F2R'},
{value: 'f2rl1', id: 'F2RL1'},
{value: 'f2rl2', id: 'F2RL2'},
{value: 'f2rl3', id: 'F2RL3'},
{value: 'f3', id: 'F3'},
{value: 'f5', id: 'F5'},
{value: 'f7', id: 'F7'},
{value: 'f8', id: 'F8'},
{value: 'f8a1', id: 'F8A1'},
{value: 'f9', id: 'F9'},
{value: 'fa2h', id: 'FA2H'},
{value: 'faah', id: 'FAAH'},
{value: 'faahl', id: 'FAAHL'},
{value: 'faap100', id: 'FAAP100'},
{value: 'faap20', id: 'FAAP20'},
{value: 'faap24', id: 'FAAP24'},
{value: 'fabp1', id: 'FABP1'},
{value: 'fabp12', id: 'FABP12'},
{value: 'fabp2', id: 'FABP2'},
{value: 'fabp3', id: 'FABP3'},
{value: 'fabp4', id: 'FABP4'},
{value: 'fabp5', id: 'FABP5'},
{value: 'fabp6', id: 'FABP6'},
{value: 'fabp7', id: 'FABP7'},
{value: 'fabp9', id: 'FABP9'},
{value: 'fadd', id: 'FADD'},
{value: 'fads1', id: 'FADS1'},
{value: 'fads2', id: 'FADS2'},
{value: 'fads2b', id: 'FADS2B'},
{value: 'fads3', id: 'FADS3'},
{value: 'fads6', id: 'FADS6'},
{value: 'faf1', id: 'FAF1'},
{value: 'faf2', id: 'FAF2'},
{value: 'fah', id: 'FAH'},
{value: 'fahd1', id: 'FAHD1'},
{value: 'fahd2a', id: 'FAHD2A'},
{value: 'faim', id: 'FAIM'},
{value: 'faim2', id: 'FAIM2'},
{value: 'fam102a', id: 'FAM102A'},
{value: 'fam102b', id: 'FAM102B'},
{value: 'fam104a', id: 'FAM104A'},
{value: 'fam104b', id: 'FAM104B'},
{value: 'fam107a', id: 'FAM107A'},
{value: 'fam107b', id: 'FAM107B'},
{value: 'fam110a', id: 'FAM110A'},
{value: 'fam110b', id: 'FAM110B'},
{value: 'fam110c', id: 'FAM110C'},
{value: 'fam110d', id: 'FAM110D'},
{value: 'fam111a', id: 'FAM111A'},
{value: 'fam114a1', id: 'FAM114A1'},
{value: 'fam114a1l1', id: 'FAM114A1L1'},
{value: 'fam115e', id: 'FAM115E'},
{value: 'fam117a', id: 'FAM117A'},
{value: 'fam117b', id: 'FAM117B'},
{value: 'fam118a', id: 'FAM118A'},
{value: 'fam118b', id: 'FAM118B'},
{value: 'fam120a', id: 'FAM120A'},
{value: 'fam120b', id: 'FAM120B'},
{value: 'fam120c', id: 'FAM120C'},
{value: 'fam124a', id: 'FAM124A'},
{value: 'fam124b', id: 'FAM124B'},
{value: 'fam126a', id: 'FAM126A'},
{value: 'fam126b', id: 'FAM126B'},
{value: 'fam131a', id: 'FAM131A'},
{value: 'fam131b', id: 'FAM131B'},
{value: 'fam131c', id: 'FAM131C'},
{value: 'fam133b', id: 'FAM133B'},
{value: 'fam135a', id: 'FAM135A'},
{value: 'fam135b', id: 'FAM135B'},
{value: 'fam136a', id: 'FAM136A'},
{value: 'fam13a', id: 'FAM13A'},
{value: 'fam13b', id: 'FAM13B'},
{value: 'fam13c', id: 'FAM13C'},
{value: 'fam149a', id: 'FAM149A'},
{value: 'fam149b1', id: 'FAM149B1'},
{value: 'fam151a', id: 'FAM151A'},
{value: 'fam151b', id: 'FAM151B'},
{value: 'fam156b', id: 'FAM156B'},
{value: 'fam161a', id: 'FAM161A'},
{value: 'fam161b', id: 'FAM161B'},
{value: 'fam162a', id: 'FAM162A'},
{value: 'fam163a', id: 'FAM163A'},
{value: 'fam163b', id: 'FAM163B'},
{value: 'fam166a', id: 'FAM166A'},
{value: 'fam166b', id: 'FAM166B'},
{value: 'fam166c', id: 'FAM166C'},
{value: 'fam167a', id: 'FAM167A'},
{value: 'fam167b', id: 'FAM167B'},
{value: 'fam168a', id: 'FAM168A'},
{value: 'fam168b', id: 'FAM168B'},
{value: 'fam169a', id: 'FAM169A'},
{value: 'fam169b', id: 'FAM169B'},
{value: 'fam170a', id: 'FAM170A'},
{value: 'fam170b', id: 'FAM170B'},
{value: 'fam171a1', id: 'FAM171A1'},
{value: 'fam171a2', id: 'FAM171A2'},
{value: 'fam171b', id: 'FAM171B'},
{value: 'fam172a', id: 'FAM172A'},
{value: 'fam174a', id: 'FAM174A'},
{value: 'fam174b', id: 'FAM174B'},
{value: 'fam174c', id: 'FAM174C'},
{value: 'fam177a1', id: 'FAM177A1'},
{value: 'fam178b', id: 'FAM178B'},
{value: 'fam180a', id: 'FAM180A'},
{value: 'fam181a', id: 'FAM181A'},
{value: 'fam181b', id: 'FAM181B'},
{value: 'fam183a', id: 'FAM183A'},
{value: 'fam184a', id: 'FAM184A'},
{value: 'fam184b', id: 'FAM184B'},
{value: 'fam185a', id: 'FAM185A'},
{value: 'fam186a', id: 'FAM186A'},
{value: 'fam186b', id: 'FAM186B'},
{value: 'fam187a', id: 'FAM187A'},
{value: 'fam187b', id: 'FAM187B'},
{value: 'fam189a1', id: 'FAM189A1'},
{value: 'fam189a2', id: 'FAM189A2'},
{value: 'fam189b', id: 'FAM189B'},
{value: 'fam193a', id: 'FAM193A'},
{value: 'fam193b', id: 'FAM193B'},
{value: 'fam199x', id: 'FAM199X'},
{value: 'fam204a', id: 'FAM204A'},
{value: 'fam205a', id: 'FAM205A'},
{value: 'fam205c', id: 'FAM205C'},
{value: 'fam209', id: 'FAM209'},
{value: 'fam20a', id: 'FAM20A'},
{value: 'fam20b', id: 'FAM20B'},
{value: 'fam20c', id: 'FAM20C'},
{value: 'fam210a', id: 'FAM210A'},
{value: 'fam210b', id: 'FAM210B'},
{value: 'fam214a', id: 'FAM214A'},
{value: 'fam214b', id: 'FAM214B'},
{value: 'fam216a', id: 'FAM216A'},
{value: 'fam216b', id: 'FAM216B'},
{value: 'fam217a', id: 'FAM217A'},
{value: 'fam217b', id: 'FAM217B'},
{value: 'fam219a', id: 'FAM219A'},
{value: 'fam219b', id: 'FAM219B'},
{value: 'fam220a', id: 'FAM220A'},
{value: 'fam221a', id: 'FAM221A'},
{value: 'fam221b', id: 'FAM221B'},
{value: 'fam222a', id: 'FAM222A'},
{value: 'fam222b', id: 'FAM222B'},
{value: 'fam227a', id: 'FAM227A'},
{value: 'fam227b', id: 'FAM227B'},
{value: 'fam228a', id: 'FAM228A'},
{value: 'fam228b', id: 'FAM228B'},
{value: 'fam229a', id: 'FAM229A'},
{value: 'fam229b', id: 'FAM229B'},
{value: 'fam234a', id: 'FAM234A'},
{value: 'fam234b', id: 'FAM234B'},
{value: 'fam237a', id: 'FAM237A'},
{value: 'fam240b', id: 'FAM240B'},
{value: 'fam241a', id: 'FAM241A'},
{value: 'fam241b', id: 'FAM241B'},
{value: 'fam243a', id: 'FAM243A'},
{value: 'fam24a', id: 'FAM24A'},
{value: 'fam25a', id: 'FAM25A'},
{value: 'fam32a', id: 'FAM32A'},
{value: 'fam3a', id: 'FAM3A'},
{value: 'fam3b', id: 'FAM3B'},
{value: 'fam3c', id: 'FAM3C'},
{value: 'fam3d', id: 'FAM3D'},
{value: 'fam43a', id: 'FAM43A'},
{value: 'fam43b', id: 'FAM43B'},
{value: 'fam47a', id: 'FAM47A'},
{value: 'fam47e', id: 'FAM47E'},
{value: 'fam48b1', id: 'FAM48B1'},
{value: 'fam50a', id: 'FAM50A'},
{value: 'fam50b', id: 'FAM50B'},
{value: 'fam53a', id: 'FAM53A'},
{value: 'fam53b', id: 'FAM53B'},
{value: 'fam53c', id: 'FAM53C'},
{value: 'fam71a', id: 'FAM71A'},
{value: 'fam71b', id: 'FAM71B'},
{value: 'fam71d', id: 'FAM71D'},
{value: 'fam71e1', id: 'FAM71E1'},
{value: 'fam71e2', id: 'FAM71E2'},
{value: 'fam71f1', id: 'FAM71F1'},
{value: 'fam71f2', id: 'FAM71F2'},
{value: 'fam72a', id: 'FAM72A'},
{value: 'fam76a', id: 'FAM76A'},
{value: 'fam76b', id: 'FAM76B'},
{value: 'fam78a', id: 'FAM78A'},
{value: 'fam78b', id: 'FAM78B'},
{value: 'fam81a', id: 'FAM81A'},
{value: 'fam81b', id: 'FAM81B'},
{value: 'fam83a', id: 'FAM83A'},
{value: 'fam83b', id: 'FAM83B'},
{value: 'fam83c', id: 'FAM83C'},
{value: 'fam83d', id: 'FAM83D'},
{value: 'fam83e', id: 'FAM83E'},
{value: 'fam83f', id: 'FAM83F'},
{value: 'fam83g', id: 'FAM83G'},
{value: 'fam83h', id: 'FAM83H'},
{value: 'fam89a', id: 'FAM89A'},
{value: 'fam89b', id: 'FAM89B'},
{value: 'fam8a1', id: 'FAM8A1'},
{value: 'fam91a1', id: 'FAM91A1'},
{value: 'fam95c', id: 'FAM95C'},
{value: 'fam98a', id: 'FAM98A'},
{value: 'fam98b', id: 'FAM98B'},
{value: 'fam98c', id: 'FAM98C'},
{value: 'fan1', id: 'FAN1'},
{value: 'fanca', id: 'FANCA'},
{value: 'fancb', id: 'FANCB'},
{value: 'fancc', id: 'FANCC'},
{value: 'fancd2', id: 'FANCD2'},
{value: 'fancd2os', id: 'FANCD2OS'},
{value: 'fance', id: 'FANCE'},
{value: 'fancf', id: 'FANCF'},
{value: 'fancg', id: 'FANCG'},
{value: 'fanci', id: 'FANCI'},
{value: 'fancl', id: 'FANCL'},
{value: 'fancm', id: 'FANCM'},
{value: 'fank1', id: 'FANK1'},
{value: 'fap', id: 'FAP'},
{value: 'far1', id: 'FAR1'},
{value: 'far2', id: 'FAR2'},
{value: 'farp1', id: 'FARP1'},
{value: 'farp2', id: 'FARP2'},
{value: 'fars2', id: 'FARS2'},
{value: 'farsa', id: 'FARSA'},
{value: 'farsb', id: 'FARSB'},
{value: 'fas', id: 'FAS'},
{value: 'faslg', id: 'FASLG'},
{value: 'fasn', id: 'FASN'},
{value: 'fastk', id: 'FASTK'},
{value: 'fastkd1', id: 'FASTKD1'},
{value: 'fastkd2', id: 'FASTKD2'},
{value: 'fastkd3', id: 'FASTKD3'},
{value: 'fastkd5', id: 'FASTKD5'},
{value: 'fat1', id: 'FAT1'},
{value: 'fat2', id: 'FAT2'},
{value: 'fat3', id: 'FAT3'},
{value: 'fat4', id: 'FAT4'},
{value: 'fau', id: 'FAU'},
{value: 'faxc', id: 'FAXC'},
{value: 'faxdc2', id: 'FAXDC2'},
{value: 'fbf1', id: 'FBF1'},
{value: 'fbh1', id: 'FBH1'},
{value: 'fbl', id: 'FBL'},
{value: 'fblim1', id: 'FBLIM1'},
{value: 'fbll1', id: 'FBLL1'},
{value: 'fbln1', id: 'FBLN1'},
{value: 'fbln2', id: 'FBLN2'},
{value: 'fbln5', id: 'FBLN5'},
{value: 'fbln7', id: 'FBLN7'},
{value: 'fbn1', id: 'FBN1'},
{value: 'fbn2', id: 'FBN2'},
{value: 'fbp1', id: 'FBP1'},
{value: 'fbp2', id: 'FBP2'},
{value: 'fbrs', id: 'FBRS'},
{value: 'fbrsl1', id: 'FBRSL1'},
{value: 'fbxl12', id: 'FBXL12'},
{value: 'fbxl13', id: 'FBXL13'},
{value: 'fbxl14', id: 'FBXL14'},
{value: 'fbxl15', id: 'FBXL15'},
{value: 'fbxl16', id: 'FBXL16'},
{value: 'fbxl17', id: 'FBXL17'},
{value: 'fbxl18', id: 'FBXL18'},
{value: 'fbxl19', id: 'FBXL19'},
{value: 'fbxl2', id: 'FBXL2'},
{value: 'fbxl20', id: 'FBXL20'},
{value: 'fbxl21', id: 'FBXL21'},
{value: 'fbxl22', id: 'FBXL22'},
{value: 'fbxl3', id: 'FBXL3'},
{value: 'fbxl4', id: 'FBXL4'},
{value: 'fbxl5', id: 'FBXL5'},
{value: 'fbxl6', id: 'FBXL6'},
{value: 'fbxl7', id: 'FBXL7'},
{value: 'fbxl8', id: 'FBXL8'},
{value: 'fbxo10', id: 'FBXO10'},
{value: 'fbxo11', id: 'FBXO11'},
{value: 'fbxo15', id: 'FBXO15'},
{value: 'fbxo16', id: 'FBXO16'},
{value: 'fbxo17', id: 'FBXO17'},
{value: 'fbxo2', id: 'FBXO2'},
{value: 'fbxo21', id: 'FBXO21'},
{value: 'fbxo22', id: 'FBXO22'},
{value: 'fbxo24', id: 'FBXO24'},
{value: 'fbxo25', id: 'FBXO25'},
{value: 'fbxo27', id: 'FBXO27'},
{value: 'fbxo28', id: 'FBXO28'},
{value: 'fbxo3', id: 'FBXO3'},
{value: 'fbxo30', id: 'FBXO30'},
{value: 'fbxo31', id: 'FBXO31'},
{value: 'fbxo32', id: 'FBXO32'},
{value: 'fbxo33', id: 'FBXO33'},
{value: 'fbxo34', id: 'FBXO34'},
{value: 'fbxo36', id: 'FBXO36'},
{value: 'fbxo38', id: 'FBXO38'},
{value: 'fbxo39', id: 'FBXO39'},
{value: 'fbxo4', id: 'FBXO4'},
{value: 'fbxo40', id: 'FBXO40'},
{value: 'fbxo41', id: 'FBXO41'},
{value: 'fbxo42', id: 'FBXO42'},
{value: 'fbxo43', id: 'FBXO43'},
{value: 'fbxo44', id: 'FBXO44'},
{value: 'fbxo45', id: 'FBXO45'},
{value: 'fbxo46', id: 'FBXO46'},
{value: 'fbxo47', id: 'FBXO47'},
{value: 'fbxo48', id: 'FBXO48'},
{value: 'fbxo5', id: 'FBXO5'},
{value: 'fbxo6', id: 'FBXO6'},
{value: 'fbxo7', id: 'FBXO7'},
{value: 'fbxo8', id: 'FBXO8'},
{value: 'fbxo9', id: 'FBXO9'},
{value: 'fbxw10', id: 'FBXW10'},
{value: 'fbxw11', id: 'FBXW11'},
{value: 'fbxw12', id: 'FBXW12'},
{value: 'fbxw17', id: 'FBXW17'},
{value: 'fbxw2', id: 'FBXW2'},
{value: 'fbxw4', id: 'FBXW4'},
{value: 'fbxw5', id: 'FBXW5'},
{value: 'fbxw7', id: 'FBXW7'},
{value: 'fbxw8', id: 'FBXW8'},
{value: 'fbxw9', id: 'FBXW9'},
{value: 'fcamr', id: 'FCAMR'},
{value: 'fcar', id: 'FCAR'},
{value: 'fcer1a', id: 'FCER1A'},
{value: 'fcer1g', id: 'FCER1G'},
{value: 'fcer2', id: 'FCER2'},
{value: 'fcf1', id: 'FCF1'},
{value: 'fcgbp', id: 'FCGBP'},
{value: 'fcgbpl1', id: 'FCGBPL1'},
{value: 'fcgr1a', id: 'FCGR1A'},
{value: 'fcgr2a', id: 'FCGR2A'},
{value: 'fcgr2b', id: 'FCGR2B'},
{value: 'fcgr3a', id: 'FCGR3A'},
{value: 'fcgrt', id: 'FCGRT'},
{value: 'fcho1', id: 'FCHO1'},
{value: 'fcho2', id: 'FCHO2'},
{value: 'fchsd1', id: 'FCHSD1'},
{value: 'fchsd2', id: 'FCHSD2'},
{value: 'fcmr', id: 'FCMR'},
{value: 'fcna', id: 'FCNA'},
{value: 'fcnb', id: 'FCNB'},
{value: 'fcrl1', id: 'FCRL1'},
{value: 'fcrl2', id: 'FCRL2'},
{value: 'fcrl5', id: 'FCRL5'},
{value: 'fcrl6', id: 'FCRL6'},
{value: 'fcrla', id: 'FCRLA'},
{value: 'fcrlb', id: 'FCRLB'},
{value: 'fcsk', id: 'FCSK'},
{value: 'fdcsp', id: 'FDCSP'},
{value: 'fdft1', id: 'FDFT1'},
{value: 'fdps', id: 'FDPS'},
{value: 'fdx1', id: 'FDX1'},
{value: 'fdx2', id: 'FDX2'},
{value: 'fdxacb1', id: 'FDXACB1'},
{value: 'fdxr', id: 'FDXR'},
{value: 'fech', id: 'FECH'},
{value: 'fem1a', id: 'FEM1A'},
{value: 'fem1b', id: 'FEM1B'},
{value: 'fem1c', id: 'FEM1C'},
{value: 'fen1', id: 'FEN1'},
{value: 'fer', id: 'FER'},
{value: 'fer1l4', id: 'FER1L4'},
{value: 'fer1l5', id: 'FER1L5'},
{value: 'fer1l6', id: 'FER1L6'},
{value: 'ferd3l', id: 'FERD3L'},
{value: 'fermt1', id: 'FERMT1'},
{value: 'fermt2', id: 'FERMT2'},
{value: 'fermt3', id: 'FERMT3'},
{value: 'fes', id: 'FES'},
{value: 'fetub', id: 'FETUB'},
{value: 'fev', id: 'FEV'},
{value: 'fez1', id: 'FEZ1'},
{value: 'fez2', id: 'FEZ2'},
{value: 'fezf1', id: 'FEZF1'},
{value: 'fezf2', id: 'FEZF2'},
{value: 'ffar1', id: 'FFAR1'},
{value: 'ffar2', id: 'FFAR2'},
{value: 'ffar3', id: 'FFAR3'},
{value: 'ffar4', id: 'FFAR4'},
{value: 'fga', id: 'FGA'},
{value: 'fgb', id: 'FGB'},
{value: 'fgd1', id: 'FGD1'},
{value: 'fgd2', id: 'FGD2'},
{value: 'fgd3', id: 'FGD3'},
{value: 'fgd4', id: 'FGD4'},
{value: 'fgd5', id: 'FGD5'},
{value: 'fgd6', id: 'FGD6'},
{value: 'fgf1', id: 'FGF1'},
{value: 'fgf10', id: 'FGF10'},
{value: 'fgf11', id: 'FGF11'},
{value: 'fgf12', id: 'FGF12'},
{value: 'fgf13', id: 'FGF13'},
{value: 'fgf14', id: 'FGF14'},
{value: 'fgf16', id: 'FGF16'},
{value: 'fgf17', id: 'FGF17'},
{value: 'fgf18', id: 'FGF18'},
{value: 'fgf19', id: 'FGF19'},
{value: 'fgf2', id: 'FGF2'},
{value: 'fgf20', id: 'FGF20'},
{value: 'fgf21', id: 'FGF21'},
{value: 'fgf22', id: 'FGF22'},
{value: 'fgf23', id: 'FGF23'},
{value: 'fgf3', id: 'FGF3'},
{value: 'fgf4', id: 'FGF4'},
{value: 'fgf5', id: 'FGF5'},
{value: 'fgf6', id: 'FGF6'},
{value: 'fgf7', id: 'FGF7'},
{value: 'fgf8', id: 'FGF8'},
{value: 'fgf9', id: 'FGF9'},
{value: 'fgfbp1', id: 'FGFBP1'},
{value: 'fgfbp3', id: 'FGFBP3'},
{value: 'fgfr1', id: 'FGFR1'},
{value: 'fgfr1op2', id: 'FGFR1OP2'},
{value: 'fgfr2', id: 'FGFR2'},
{value: 'fgfr3', id: 'FGFR3'},
{value: 'fgfr4', id: 'FGFR4'},
{value: 'fgfrl1', id: 'FGFRL1'},
{value: 'fgg', id: 'FGG'},
{value: 'fggy', id: 'FGGY'},
{value: 'fgl1', id: 'FGL1'},
{value: 'fgl2', id: 'FGL2'},
{value: 'fgr', id: 'FGR'},
{value: 'fh', id: 'FH'},
{value: 'fhad1', id: 'FHAD1'},
{value: 'fhdc1', id: 'FHDC1'},
{value: 'fhip1a', id: 'FHIP1A'},
{value: 'fhip1b', id: 'FHIP1B'},
{value: 'fhip2a', id: 'FHIP2A'},
{value: 'fhip2b', id: 'FHIP2B'},
{value: 'fhit', id: 'FHIT'},
{value: 'fhl1', id: 'FHL1'},
{value: 'fhl2', id: 'FHL2'},
{value: 'fhl3', id: 'FHL3'},
{value: 'fhl4', id: 'FHL4'},
{value: 'fhl5', id: 'FHL5'},
{value: 'fhod1', id: 'FHOD1'},
{value: 'fhod3', id: 'FHOD3'},
{value: 'fibcd1', id: 'FIBCD1'},
{value: 'fibcd1l1', id: 'FIBCD1L1'},
{value: 'fibin', id: 'FIBIN'},
{value: 'fibp', id: 'FIBP'},
{value: 'ficd', id: 'FICD'},
{value: 'fig4', id: 'FIG4'},
{value: 'figla', id: 'FIGLA'},
{value: 'fign', id: 'FIGN'},
{value: 'fignl1', id: 'FIGNL1'},
{value: 'fignl2', id: 'FIGNL2'},
{value: 'filip1', id: 'FILIP1'},
{value: 'filip1l', id: 'FILIP1L'},
{value: 'fip1l1', id: 'FIP1L1'},
{value: 'fis1', id: 'FIS1'},
{value: 'fitm1', id: 'FITM1'},
{value: 'fitm2', id: 'FITM2'},
{value: 'fiz1', id: 'FIZ1'},
{value: 'fjx1', id: 'FJX1'},
{value: 'fkbp10', id: 'FKBP10'},
{value: 'fkbp11', id: 'FKBP11'},
{value: 'fkbp14', id: 'FKBP14'},
{value: 'fkbp15', id: 'FKBP15'},
{value: 'fkbp1a', id: 'FKBP1A'},
{value: 'fkbp1b', id: 'FKBP1B'},
{value: 'fkbp2', id: 'FKBP2'},
{value: 'fkbp3', id: 'FKBP3'},
{value: 'fkbp4', id: 'FKBP4'},
{value: 'fkbp5', id: 'FKBP5'},
{value: 'fkbp6', id: 'FKBP6'},
{value: 'fkbp7', id: 'FKBP7'},
{value: 'fkbp8', id: 'FKBP8'},
{value: 'fkbp9', id: 'FKBP9'},
{value: 'fkbpl', id: 'FKBPL'},
{value: 'fkrp', id: 'FKRP'},
{value: 'fktn', id: 'FKTN'},
{value: 'flacc1', id: 'FLACC1'},
{value: 'flad1', id: 'FLAD1'},
{value: 'flcn', id: 'FLCN'},
{value: 'flg', id: 'FLG'},
{value: 'flg2', id: 'FLG2'},
{value: 'fli1', id: 'FLI1'},
{value: 'flii', id: 'FLII'},
{value: 'flna', id: 'FLNA'},
{value: 'flnb', id: 'FLNB'},
{value: 'flnc', id: 'FLNC'},
{value: 'flot1', id: 'FLOT1'},
{value: 'flot2', id: 'FLOT2'},
{value: 'flrt1', id: 'FLRT1'},
{value: 'flrt2', id: 'FLRT2'},
{value: 'flrt3', id: 'FLRT3'},
{value: 'flt1', id: 'FLT1'},
{value: 'flt3', id: 'FLT3'},
{value: 'flt3lg', id: 'FLT3LG'},
{value: 'flt4', id: 'FLT4'},
{value: 'flvcr1', id: 'FLVCR1'},
{value: 'flvcr2', id: 'FLVCR2'},
{value: 'flywch1', id: 'FLYWCH1'},
{value: 'fmc1', id: 'FMC1'},
{value: 'fmn1', id: 'FMN1'},
{value: 'fmn2', id: 'FMN2'},
{value: 'fmnl1', id: 'FMNL1'},
{value: 'fmnl2', id: 'FMNL2'},
{value: 'fmnl3', id: 'FMNL3'},
{value: 'fmo1', id: 'FMO1'},
{value: 'fmo13', id: 'FMO13'},
{value: 'fmo2', id: 'FMO2'},
{value: 'fmo3', id: 'FMO3'},
{value: 'fmo4', id: 'FMO4'},
{value: 'fmo5', id: 'FMO5'},
{value: 'fmo6', id: 'FMO6'},
{value: 'fmo9', id: 'FMO9'},
{value: 'fmod', id: 'FMOD'},
{value: 'fmr1', id: 'FMR1'},
{value: 'fmr1nb', id: 'FMR1NB'},
{value: 'fn1', id: 'FN1'},
{value: 'fn3k', id: 'FN3K'},
{value: 'fn3krp', id: 'FN3KRP'},
{value: 'fnbp1', id: 'FNBP1'},
{value: 'fnbp1l', id: 'FNBP1L'},
{value: 'fnbp4', id: 'FNBP4'},
{value: 'fnd3c2', id: 'FND3C2'},
{value: 'fndc1', id: 'FNDC1'},
{value: 'fndc10', id: 'FNDC10'},
{value: 'fndc11', id: 'FNDC11'},
{value: 'fndc3a', id: 'FNDC3A'},
{value: 'fndc3b', id: 'FNDC3B'},
{value: 'fndc3c1', id: 'FNDC3C1'},
{value: 'fndc5', id: 'FNDC5'},
{value: 'fndc7', id: 'FNDC7'},
{value: 'fndc8', id: 'FNDC8'},
{value: 'fndc9', id: 'FNDC9'},
{value: 'fnip1', id: 'FNIP1'},
{value: 'fnip2', id: 'FNIP2'},
{value: 'fnta', id: 'FNTA'},
{value: 'fntb', id: 'FNTB'},
{value: 'focad', id: 'FOCAD'},
{value: 'folh1', id: 'FOLH1'},
{value: 'folr1', id: 'FOLR1'},
{value: 'folr2', id: 'FOLR2'},
{value: 'fos', id: 'FOS'},
{value: 'fosb', id: 'FOSB'},
{value: 'fosl1', id: 'FOSL1'},
{value: 'fosl2', id: 'FOSL2'},
{value: 'foxa1', id: 'FOXA1'},
{value: 'foxa2', id: 'FOXA2'},
{value: 'foxa3', id: 'FOXA3'},
{value: 'foxb1', id: 'FOXB1'},
{value: 'foxb2', id: 'FOXB2'},
{value: 'foxc1', id: 'FOXC1'},
{value: 'foxc2', id: 'FOXC2'},
{value: 'foxd1', id: 'FOXD1'},
{value: 'foxd2', id: 'FOXD2'},
{value: 'foxd3', id: 'FOXD3'},
{value: 'foxd4', id: 'FOXD4'},
{value: 'foxe1', id: 'FOXE1'},
{value: 'foxe3', id: 'FOXE3'},
{value: 'foxf1', id: 'FOXF1'},
{value: 'foxf2', id: 'FOXF2'},
{value: 'foxg1', id: 'FOXG1'},
{value: 'foxh1', id: 'FOXH1'},
{value: 'foxi1', id: 'FOXI1'},
{value: 'foxi2', id: 'FOXI2'},
{value: 'foxi3', id: 'FOXI3'},
{value: 'foxj2', id: 'FOXJ2'},
{value: 'foxj3', id: 'FOXJ3'},
{value: 'foxk1', id: 'FOXK1'},
{value: 'foxk2', id: 'FOXK2'},
{value: 'foxl1', id: 'FOXL1'},
{value: 'foxl2', id: 'FOXL2'},
{value: 'foxl3', id: 'FOXL3'},
{value: 'foxm1', id: 'FOXM1'},
{value: 'foxn1', id: 'FOXN1'},
{value: 'foxn2', id: 'FOXN2'},
{value: 'foxn3', id: 'FOXN3'},
{value: 'foxn4', id: 'FOXN4'},
{value: 'foxo1', id: 'FOXO1'},
{value: 'foxo3', id: 'FOXO3'},
{value: 'foxo4', id: 'FOXO4'},
{value: 'foxo6', id: 'FOXO6'},
{value: 'foxp1', id: 'FOXP1'},
{value: 'foxp2', id: 'FOXP2'},
{value: 'foxp3', id: 'FOXP3'},
{value: 'foxp4', id: 'FOXP4'},
{value: 'foxq1', id: 'FOXQ1'},
{value: 'foxr1', id: 'FOXR1'},
{value: 'foxr2', id: 'FOXR2'},
{value: 'foxred1', id: 'FOXRED1'},
{value: 'foxred2', id: 'FOXRED2'},
{value: 'foxs1', id: 'FOXS1'},
{value: 'fpgs', id: 'FPGS'},
{value: 'fpgt', id: 'FPGT'},
{value: 'fpr-rs3', id: 'FPR-RS3'},
{value: 'fpr-rs4', id: 'FPR-RS4'},
{value: 'fpr-rs6', id: 'FPR-RS6'},
{value: 'fpr1', id: 'FPR1'},
{value: 'fpr2', id: 'FPR2'},
{value: 'fpr2l', id: 'FPR2L'},
{value: 'fpr3', id: 'FPR3'},
{value: 'fra10ac1', id: 'FRA10AC1'},
{value: 'fras1', id: 'FRAS1'},
{value: 'frat1', id: 'FRAT1'},
{value: 'frat2', id: 'FRAT2'},
{value: 'frem1', id: 'FREM1'},
{value: 'frem2', id: 'FREM2'},
{value: 'frem3', id: 'FREM3'},
{value: 'frg1', id: 'FRG1'},
{value: 'frg1l1', id: 'FRG1L1'},
{value: 'frg2', id: 'FRG2'},
{value: 'frk', id: 'FRK'},
{value: 'frmd3', id: 'FRMD3'},
{value: 'frmd4a', id: 'FRMD4A'},
{value: 'frmd4b', id: 'FRMD4B'},
{value: 'frmd5', id: 'FRMD5'},
{value: 'frmd6', id: 'FRMD6'},
{value: 'frmd7', id: 'FRMD7'},
{value: 'frmd8', id: 'FRMD8'},
{value: 'frmpd1', id: 'FRMPD1'},
{value: 'frmpd2', id: 'FRMPD2'},
{value: 'frmpd3', id: 'FRMPD3'},
{value: 'frmpd4', id: 'FRMPD4'},
{value: 'frrs1', id: 'FRRS1'},
{value: 'frrs1l', id: 'FRRS1L'},
{value: 'frs2', id: 'FRS2'},
{value: 'frs3', id: 'FRS3'},
{value: 'fry', id: 'FRY'},
{value: 'fryl', id: 'FRYL'},
{value: 'frzb', id: 'FRZB'},
{value: 'fsbp', id: 'FSBP'},
{value: 'fscb', id: 'FSCB'},
{value: 'fscn1', id: 'FSCN1'},
{value: 'fscn2', id: 'FSCN2'},
{value: 'fscn3', id: 'FSCN3'},
{value: 'fsd1', id: 'FSD1'},
{value: 'fsd1l', id: 'FSD1L'},
{value: 'fsd2', id: 'FSD2'},
{value: 'fshb', id: 'FSHB'},
{value: 'fshr', id: 'FSHR'},
{value: 'fsip1', id: 'FSIP1'},
{value: 'fsip2', id: 'FSIP2'},
{value: 'fst', id: 'FST'},
{value: 'fstl1', id: 'FSTL1'},
{value: 'fstl3', id: 'FSTL3'},
{value: 'fstl4', id: 'FSTL4'},
{value: 'fstl5', id: 'FSTL5'},
{value: 'ftcd', id: 'FTCD'},
{value: 'fth1', id: 'FTH1'},
{value: 'fthl17c', id: 'FTHL17C'},
{value: 'fthl17e', id: 'FTHL17E'},
{value: 'ftl1', id: 'FTL1'},
{value: 'ftl1l1', id: 'FTL1L1'},
{value: 'ftmt', id: 'FTMT'},
{value: 'fto', id: 'FTO'},
{value: 'ftsj1', id: 'FTSJ1'},
{value: 'ftsj3', id: 'FTSJ3'},
{value: 'fubp1', id: 'FUBP1'},
{value: 'fubp3', id: 'FUBP3'},
{value: 'fuca1', id: 'FUCA1'},
{value: 'fuca2', id: 'FUCA2'},
{value: 'fundc1', id: 'FUNDC1'},
{value: 'fundc2', id: 'FUNDC2'},
{value: 'fuom', id: 'FUOM'},
{value: 'furin', id: 'FURIN'},
{value: 'fus', id: 'FUS'},
{value: 'fut1', id: 'FUT1'},
{value: 'fut10', id: 'FUT10'},
{value: 'fut11', id: 'FUT11'},
{value: 'fut2', id: 'FUT2'},
{value: 'fut4', id: 'FUT4'},
{value: 'fut7', id: 'FUT7'},
{value: 'fut8', id: 'FUT8'},
{value: 'fut9', id: 'FUT9'},
{value: 'fuz', id: 'FUZ'},
{value: 'fv1', id: 'FV1'},
{value: 'fxn', id: 'FXN'},
{value: 'fxr1', id: 'FXR1'},
{value: 'fxr2', id: 'FXR2'},
{value: 'fxyd1', id: 'FXYD1'},
{value: 'fxyd2', id: 'FXYD2'},
{value: 'fxyd3', id: 'FXYD3'},
{value: 'fxyd4', id: 'FXYD4'},
{value: 'fxyd5', id: 'FXYD5'},
{value: 'fxyd6', id: 'FXYD6'},
{value: 'fxyd7', id: 'FXYD7'},
{value: 'fyb1', id: 'FYB1'},
{value: 'fyb2', id: 'FYB2'},
{value: 'fyco1', id: 'FYCO1'},
{value: 'fyn', id: 'FYN'},
{value: 'fyttd1', id: 'FYTTD1'},
{value: 'fzd1', id: 'FZD1'},
{value: 'fzd10', id: 'FZD10'},
{value: 'fzd2', id: 'FZD2'},
{value: 'fzd3', id: 'FZD3'},
{value: 'fzd4', id: 'FZD4'},
{value: 'fzd5', id: 'FZD5'},
{value: 'fzd6', id: 'FZD6'},
{value: 'fzd7', id: 'FZD7'},
{value: 'fzd8', id: 'FZD8'},
{value: 'fzd9', id: 'FZD9'},
{value: 'fzr1', id: 'FZR1'},
{value: 'g0s2', id: 'G0S2'},
{value: 'g2e3', id: 'G2E3'},
{value: 'g3bp1', id: 'G3BP1'},
{value: 'g3bp2', id: 'G3BP2'},
{value: 'g4', id: 'G4'},
{value: 'g6pc1', id: 'G6PC1'},
{value: 'g6pc3', id: 'G6PC3'},
{value: 'g6pd', id: 'G6PD'},
{value: 'gaa', id: 'GAA'},
{value: 'gab1', id: 'GAB1'},
{value: 'gab2', id: 'GAB2'},
{value: 'gabarap', id: 'GABARAP'},
{value: 'gabarapl1', id: 'GABARAPL1'},
{value: 'gabarapl2', id: 'GABARAPL2'},
{value: 'gabbr1', id: 'GABBR1'},
{value: 'gabbr2', id: 'GABBR2'},
{value: 'gabpa', id: 'GABPA'},
{value: 'gabpb1', id: 'GABPB1'},
{value: 'gabpb1l', id: 'GABPB1L'},
{value: 'gabpb2', id: 'GABPB2'},
{value: 'gabra1', id: 'GABRA1'},
{value: 'gabra2', id: 'GABRA2'},
{value: 'gabra3', id: 'GABRA3'},
{value: 'gabra4', id: 'GABRA4'},
{value: 'gabra5', id: 'GABRA5'},
{value: 'gabra6', id: 'GABRA6'},
{value: 'gabrb1', id: 'GABRB1'},
{value: 'gabrb2', id: 'GABRB2'},
{value: 'gabrb3', id: 'GABRB3'},
{value: 'gabrd', id: 'GABRD'},
{value: 'gabre', id: 'GABRE'},
{value: 'gabrg1', id: 'GABRG1'},
{value: 'gabrg2', id: 'GABRG2'},
{value: 'gabrg3', id: 'GABRG3'},
{value: 'gabrp', id: 'GABRP'},
{value: 'gabrq', id: 'GABRQ'},
{value: 'gabrr1', id: 'GABRR1'},
{value: 'gabrr2', id: 'GABRR2'},
{value: 'gabrr3', id: 'GABRR3'},
{value: 'gad1', id: 'GAD1'},
{value: 'gad2', id: 'GAD2'},
{value: 'gadd45a', id: 'GADD45A'},
{value: 'gadd45b', id: 'GADD45B'},
{value: 'gadd45g', id: 'GADD45G'},
{value: 'gadd45gip1', id: 'GADD45GIP1'},
{value: 'gadl1', id: 'GADL1'},
{value: 'gak', id: 'GAK'},
{value: 'gal', id: 'GAL'},
{value: 'gal3st1', id: 'GAL3ST1'},
{value: 'gal3st2', id: 'GAL3ST2'},
{value: 'gal3st3', id: 'GAL3ST3'},
{value: 'gal3st4', id: 'GAL3ST4'},
{value: 'galc', id: 'GALC'},
{value: 'gale', id: 'GALE'},
{value: 'galk1', id: 'GALK1'},
{value: 'galk2', id: 'GALK2'},
{value: 'galm', id: 'GALM'},
{value: 'galns', id: 'GALNS'},
{value: 'galnt1', id: 'GALNT1'},
{value: 'galnt10', id: 'GALNT10'},
{value: 'galnt11', id: 'GALNT11'},
{value: 'galnt12', id: 'GALNT12'},
{value: 'galnt13', id: 'GALNT13'},
{value: 'galnt14', id: 'GALNT14'},
{value: 'galnt15', id: 'GALNT15'},
{value: 'galnt16', id: 'GALNT16'},
{value: 'galnt17', id: 'GALNT17'},
{value: 'galnt18', id: 'GALNT18'},
{value: 'galnt2', id: 'GALNT2'},
{value: 'galnt3', id: 'GALNT3'},
{value: 'galnt4', id: 'GALNT4'},
{value: 'galnt5', id: 'GALNT5'},
{value: 'galnt6', id: 'GALNT6'},
{value: 'galnt7', id: 'GALNT7'},
{value: 'galnt9', id: 'GALNT9'},
{value: 'galntl5', id: 'GALNTL5'},
{value: 'galntl6', id: 'GALNTL6'},
{value: 'galp', id: 'GALP'},
{value: 'galr1', id: 'GALR1'},
{value: 'galr2', id: 'GALR2'},
{value: 'galr3', id: 'GALR3'},
{value: 'galt', id: 'GALT'},
{value: 'gamt', id: 'GAMT'},
{value: 'gan', id: 'GAN'},
{value: 'ganab', id: 'GANAB'},
{value: 'ganc', id: 'GANC'},
{value: 'gap43', id: 'GAP43'},
{value: 'gapdh', id: 'GAPDH'},
{value: 'gapdhs', id: 'GAPDHS'},
{value: 'gapt', id: 'GAPT'},
{value: 'gapvd1', id: 'GAPVD1'},
{value: 'gar1', id: 'GAR1'},
{value: 'garem1', id: 'GAREM1'},
{value: 'garem2', id: 'GAREM2'},
{value: 'garnl3', id: 'GARNL3'},
{value: 'garre1', id: 'GARRE1'},
{value: 'gars1', id: 'GARS1'},
{value: 'gart', id: 'GART'},
{value: 'gas1', id: 'GAS1'},
{value: 'gas2', id: 'GAS2'},
{value: 'gas2l1', id: 'GAS2L1'},
{value: 'gas2l2', id: 'GAS2L2'},
{value: 'gas2l3', id: 'GAS2L3'},
{value: 'gas6', id: 'GAS6'},
{value: 'gas7', id: 'GAS7'},
{value: 'gas8', id: 'GAS8'},
{value: 'gask1a', id: 'GASK1A'},
{value: 'gask1b', id: 'GASK1B'},
{value: 'gast', id: 'GAST'},
{value: 'gata1', id: 'GATA1'},
{value: 'gata2', id: 'GATA2'},
{value: 'gata3', id: 'GATA3'},
{value: 'gata4', id: 'GATA4'},
{value: 'gata5', id: 'GATA5'},
{value: 'gata6', id: 'GATA6'},
{value: 'gatad1', id: 'GATAD1'},
{value: 'gatad2a', id: 'GATAD2A'},
{value: 'gatad2b', id: 'GATAD2B'},
{value: 'gatb', id: 'GATB'},
{value: 'gatc', id: 'GATC'},
{value: 'gatd1', id: 'GATD1'},
{value: 'gatd3a', id: 'GATD3A'},
{value: 'gatm', id: 'GATM'},
{value: 'gba', id: 'GBA'},
{value: 'gba2', id: 'GBA2'},
{value: 'gba3', id: 'GBA3'},
{value: 'gbe1', id: 'GBE1'},
{value: 'gbf1', id: 'GBF1'},
{value: 'gbp1', id: 'GBP1'},
{value: 'gbp2', id: 'GBP2'},
{value: 'gbp3', id: 'GBP3'},
{value: 'gbp4', id: 'GBP4'},
{value: 'gbp5', id: 'GBP5'},
{value: 'gbp6', id: 'GBP6'},
{value: 'gbx1', id: 'GBX1'},
{value: 'gbx2', id: 'GBX2'},
{value: 'gc', id: 'GC'},
{value: 'gca', id: 'GCA'},
{value: 'gcat', id: 'GCAT'},
{value: 'gcc1', id: 'GCC1'},
{value: 'gcc2', id: 'GCC2'},
{value: 'gcdh', id: 'GCDH'},
{value: 'gcfc2', id: 'GCFC2'},
{value: 'gcg', id: 'GCG'},
{value: 'gcgr', id: 'GCGR'},
{value: 'gch1', id: 'GCH1'},
{value: 'gchfr', id: 'GCHFR'},
{value: 'gck', id: 'GCK'},
{value: 'gckr', id: 'GCKR'},
{value: 'gclc', id: 'GCLC'},
{value: 'gclm', id: 'GCLM'},
{value: 'gcm1', id: 'GCM1'},
{value: 'gcm2', id: 'GCM2'},
{value: 'gcn1', id: 'GCN1'},
{value: 'gcnt1', id: 'GCNT1'},
{value: 'gcnt2', id: 'GCNT2'},
{value: 'gcnt3', id: 'GCNT3'},
{value: 'gcnt4', id: 'GCNT4'},
{value: 'gcnt7', id: 'GCNT7'},
{value: 'gcsam', id: 'GCSAM'},
{value: 'gcsh', id: 'GCSH'},
{value: 'gda', id: 'GDA'},
{value: 'gdap1', id: 'GDAP1'},
{value: 'gdap1l1', id: 'GDAP1L1'},
{value: 'gdap2', id: 'GDAP2'},
{value: 'gde1', id: 'GDE1'},
{value: 'gdf1', id: 'GDF1'},
{value: 'gdf10', id: 'GDF10'},
{value: 'gdf11', id: 'GDF11'},
{value: 'gdf15', id: 'GDF15'},
{value: 'gdf2', id: 'GDF2'},
{value: 'gdf3', id: 'GDF3'},
{value: 'gdf5', id: 'GDF5'},
{value: 'gdf6', id: 'GDF6'},
{value: 'gdf7', id: 'GDF7'},
{value: 'gdf9', id: 'GDF9'},
{value: 'gdi1', id: 'GDI1'},
{value: 'gdi2', id: 'GDI2'},
{value: 'gdnf', id: 'GDNF'},
{value: 'gdpd1', id: 'GDPD1'},
{value: 'gdpd2', id: 'GDPD2'},
{value: 'gdpd3', id: 'GDPD3'},
{value: 'gdpd4', id: 'GDPD4'},
{value: 'gdpd5', id: 'GDPD5'},
{value: 'gdpgp1', id: 'GDPGP1'},
{value: 'gem', id: 'GEM'},
{value: 'gemin2', id: 'GEMIN2'},
{value: 'gemin4', id: 'GEMIN4'},
{value: 'gemin5', id: 'GEMIN5'},
{value: 'gemin6', id: 'GEMIN6'},
{value: 'gemin7', id: 'GEMIN7'},
{value: 'gemin7l1', id: 'GEMIN7L1'},
{value: 'gemin8', id: 'GEMIN8'},
{value: 'gen1', id: 'GEN1'},
{value: 'get1', id: 'GET1'},
{value: 'get3', id: 'GET3'},
{value: 'get4', id: 'GET4'},
{value: 'gfap', id: 'GFAP'},
{value: 'gfer', id: 'GFER'},
{value: 'gfi1', id: 'GFI1'},
{value: 'gfi1b', id: 'GFI1B'},
{value: 'gfm1', id: 'GFM1'},
{value: 'gfm2', id: 'GFM2'},
{value: 'gfod1', id: 'GFOD1'},
{value: 'gfod2', id: 'GFOD2'},
{value: 'gfpt1', id: 'GFPT1'},
{value: 'gfpt2', id: 'GFPT2'},
{value: 'gfra1', id: 'GFRA1'},
{value: 'gfra2', id: 'GFRA2'},
{value: 'gfra3', id: 'GFRA3'},
{value: 'gfra4', id: 'GFRA4'},
{value: 'gfral', id: 'GFRAL'},
{value: 'gfus', id: 'GFUS'},
{value: 'gfy', id: 'GFY'},
{value: 'gga1', id: 'GGA1'},
{value: 'gga2', id: 'GGA2'},
{value: 'gga3', id: 'GGA3'},
{value: 'ggact', id: 'GGACT'},
{value: 'ggct', id: 'GGCT'},
{value: 'ggcx', id: 'GGCX'},
{value: 'ggh', id: 'GGH'},
{value: 'ggn', id: 'GGN'},
{value: 'ggnbp1', id: 'GGNBP1'},
{value: 'ggnbp2', id: 'GGNBP2'},
{value: 'ggps1', id: 'GGPS1'},
{value: 'ggt1', id: 'GGT1'},
{value: 'ggt5', id: 'GGT5'},
{value: 'ggt6', id: 'GGT6'},
{value: 'ggt7', id: 'GGT7'},
{value: 'ggta1', id: 'GGTA1'},
{value: 'ggta1l1', id: 'GGTA1L1'},
{value: 'gh1', id: 'GH1'},
{value: 'ghdc', id: 'GHDC'},
{value: 'ghitm', id: 'GHITM'},
{value: 'ghr', id: 'GHR'},
{value: 'ghrh', id: 'GHRH'},
{value: 'ghrhr', id: 'GHRHR'},
{value: 'ghrl', id: 'GHRL'},
{value: 'ghsr', id: 'GHSR'},
{value: 'gid4', id: 'GID4'},
{value: 'gid8', id: 'GID8'},
{value: 'gigyf1', id: 'GIGYF1'},
{value: 'gigyf2', id: 'GIGYF2'},
{value: 'gimap1', id: 'GIMAP1'},
{value: 'gimap4', id: 'GIMAP4'},
{value: 'gimap5', id: 'GIMAP5'},
{value: 'gimap6', id: 'GIMAP6'},
{value: 'gimap7', id: 'GIMAP7'},
{value: 'gimap8', id: 'GIMAP8'},
{value: 'gimap9', id: 'GIMAP9'},
{value: 'gimd1', id: 'GIMD1'},
{value: 'gin1', id: 'GIN1'},
{value: 'ginm1', id: 'GINM1'},
{value: 'gins1', id: 'GINS1'},
{value: 'gins2', id: 'GINS2'},
{value: 'gins3', id: 'GINS3'},
{value: 'gins4', id: 'GINS4'},
{value: 'giot1', id: 'GIOT1'},
{value: 'gip', id: 'GIP'},
{value: 'gipc1', id: 'GIPC1'},
{value: 'gipc2', id: 'GIPC2'},
{value: 'gipc3', id: 'GIPC3'},
{value: 'gipr', id: 'GIPR'},
{value: 'git1', id: 'GIT1'},
{value: 'git2', id: 'GIT2'},
{value: 'gja1', id: 'GJA1'},
{value: 'gja10', id: 'GJA10'},
{value: 'gja3', id: 'GJA3'},
{value: 'gja4', id: 'GJA4'},
{value: 'gja5', id: 'GJA5'},
{value: 'gja8', id: 'GJA8'},
{value: 'gjb1', id: 'GJB1'},
{value: 'gjb2', id: 'GJB2'},
{value: 'gjb3', id: 'GJB3'},
{value: 'gjb4', id: 'GJB4'},
{value: 'gjb5', id: 'GJB5'},
{value: 'gjb6', id: 'GJB6'},
{value: 'gjc1', id: 'GJC1'},
{value: 'gjc2', id: 'GJC2'},
{value: 'gjc3', id: 'GJC3'},
{value: 'gjd2', id: 'GJD2'},
{value: 'gjd3', id: 'GJD3'},
{value: 'gjd4', id: 'GJD4'},
{value: 'gje1', id: 'GJE1'},
{value: 'gk', id: 'GK'},
{value: 'gk2', id: 'GK2'},
{value: 'gk5', id: 'GK5'},
{value: 'gkap1', id: 'GKAP1'},
{value: 'gkn1', id: 'GKN1'},
{value: 'gkn2', id: 'GKN2'},
{value: 'gkn3', id: 'GKN3'},
{value: 'gla', id: 'GLA'},
{value: 'glb1', id: 'GLB1'},
{value: 'glb1l', id: 'GLB1L'},
{value: 'glb1l2', id: 'GLB1L2'},
{value: 'glb1l3', id: 'GLB1L3'},
{value: 'glcci1', id: 'GLCCI1'},
{value: 'glce', id: 'GLCE'},
{value: 'gldc', id: 'GLDC'},
{value: 'gldn', id: 'GLDN'},
{value: 'gle1', id: 'GLE1'},
{value: 'glg1', id: 'GLG1'},
{value: 'gli1', id: 'GLI1'},
{value: 'gli2', id: 'GLI2'},
{value: 'gli3', id: 'GLI3'},
{value: 'gli4', id: 'GLI4'},
{value: 'glipr1', id: 'GLIPR1'},
{value: 'glipr1l1', id: 'GLIPR1L1'},
{value: 'glipr1l2', id: 'GLIPR1L2'},
{value: 'glipr2', id: 'GLIPR2'},
{value: 'glis1', id: 'GLIS1'},
{value: 'glis2', id: 'GLIS2'},
{value: 'glis3', id: 'GLIS3'},
{value: 'glmn', id: 'GLMN'},
{value: 'glmp', id: 'GLMP'},
{value: 'glo1', id: 'GLO1'},
{value: 'glod4', id: 'GLOD4'},
{value: 'glod5', id: 'GLOD5'},
{value: 'glp1r', id: 'GLP1R'},
{value: 'glp2r', id: 'GLP2R'},
{value: 'glra1', id: 'GLRA1'},
{value: 'glra2', id: 'GLRA2'},
{value: 'glra3', id: 'GLRA3'},
{value: 'glra4', id: 'GLRA4'},
{value: 'glrb', id: 'GLRB'},
{value: 'glrx', id: 'GLRX'},
{value: 'glrx2', id: 'GLRX2'},
{value: 'glrx3', id: 'GLRX3'},
{value: 'glrx5', id: 'GLRX5'},
{value: 'gls', id: 'GLS'},
{value: 'gls2', id: 'GLS2'},
{value: 'glt1d1', id: 'GLT1D1'},
{value: 'glt6d1', id: 'GLT6D1'},
{value: 'glt8d1', id: 'GLT8D1'},
{value: 'glt8d2', id: 'GLT8D2'},
{value: 'gltp', id: 'GLTP'},
{value: 'gltpd2', id: 'GLTPD2'},
{value: 'glud1', id: 'GLUD1'},
{value: 'glul', id: 'GLUL'},
{value: 'glyat', id: 'GLYAT'},
{value: 'glyatl1', id: 'GLYATL1'},
{value: 'glyatl2', id: 'GLYATL2'},
{value: 'glyatl3', id: 'GLYATL3'},
{value: 'glycam1', id: 'GLYCAM1'},
{value: 'glyctk', id: 'GLYCTK'},
{value: 'glyr1', id: 'GLYR1'},
{value: 'gm22009', id: 'GM22009'},
{value: 'gm22154', id: 'GM22154'},
{value: 'gm22205', id: 'GM22205'},
{value: 'gm22267', id: 'GM22267'},
{value: 'gm22268', id: 'GM22268'},
{value: 'gm22284', id: 'GM22284'},
{value: 'gm22341', id: 'GM22341'},
{value: 'gm22347', id: 'GM22347'},
{value: 'gm22371', id: 'GM22371'},
{value: 'gm22379', id: 'GM22379'},
{value: 'gm22387', id: 'GM22387'},
{value: 'gm22394', id: 'GM22394'},
{value: 'gm22433', id: 'GM22433'},
{value: 'gm22439', id: 'GM22439'},
{value: 'gm22470', id: 'GM22470'},
{value: 'gm22473', id: 'GM22473'},
{value: 'gm22486', id: 'GM22486'},
{value: 'gm22495', id: 'GM22495'},
{value: 'gm22504', id: 'GM22504'},
{value: 'gm22506', id: 'GM22506'},
{value: 'gm22520', id: 'GM22520'},
{value: 'gm22522', id: 'GM22522'},
{value: 'gm22532', id: 'GM22532'},
{value: 'gm22559', id: 'GM22559'},
{value: 'gm22592', id: 'GM22592'},
{value: 'gm22598', id: 'GM22598'},
{value: 'gm22676', id: 'GM22676'},
{value: 'gm22710', id: 'GM22710'},
{value: 'gm22728', id: 'GM22728'},
{value: 'gm22748', id: 'GM22748'},
{value: 'gm22778', id: 'GM22778'},
{value: 'gm22830', id: 'GM22830'},
{value: 'gm22858', id: 'GM22858'},
{value: 'gm22877', id: 'GM22877'},
{value: 'gm22884', id: 'GM22884'},
{value: 'gm22888', id: 'GM22888'},
{value: 'gm22892', id: 'GM22892'},
{value: 'gm22899', id: 'GM22899'},
{value: 'gm22910', id: 'GM22910'},
{value: 'gm22927', id: 'GM22927'},
{value: 'gm22934', id: 'GM22934'},
{value: 'gm22936', id: 'GM22936'},
{value: 'gm22939', id: 'GM22939'},
{value: 'gm22953', id: 'GM22953'},
{value: 'gm22986', id: 'GM22986'},
{value: 'gm22988', id: 'GM22988'},
{value: 'gm23008', id: 'GM23008'},
{value: 'gm23011', id: 'GM23011'},
{value: 'gm23033', id: 'GM23033'},
{value: 'gm23034', id: 'GM23034'},
{value: 'gm23130', id: 'GM23130'},
{value: 'gm23132', id: 'GM23132'},
{value: 'gm23134', id: 'GM23134'},
{value: 'gm23176', id: 'GM23176'},
{value: 'gm23200', id: 'GM23200'},
{value: 'gm23222', id: 'GM23222'},
{value: 'gm23280', id: 'GM23280'},
{value: 'gm23297', id: 'GM23297'},
{value: 'gm23319', id: 'GM23319'},
{value: 'gm23321', id: 'GM23321'},
{value: 'gm23328', id: 'GM23328'},
{value: 'gm23346', id: 'GM23346'},
{value: 'gm23377', id: 'GM23377'},
{value: 'gm23439', id: 'GM23439'},
{value: 'gm23454', id: 'GM23454'},
{value: 'gm23484', id: 'GM23484'},
{value: 'gm23593', id: 'GM23593'},
{value: 'gm23594', id: 'GM23594'},
{value: 'gm23595', id: 'GM23595'},
{value: 'gm23601', id: 'GM23601'},
{value: 'gm23604', id: 'GM23604'},
{value: 'gm23609', id: 'GM23609'},
{value: 'gm23639', id: 'GM23639'},
{value: 'gm23674', id: 'GM23674'},
{value: 'gm23705', id: 'GM23705'},
{value: 'gm23734', id: 'GM23734'},
{value: 'gm23745', id: 'GM23745'},
{value: 'gm23748', id: 'GM23748'},
{value: 'gm23749', id: 'GM23749'},
{value: 'gm23769', id: 'GM23769'},
{value: 'gm23816', id: 'GM23816'},
{value: 'gm23822', id: 'GM23822'},
{value: 'gm23835', id: 'GM23835'},
{value: 'gm23839', id: 'GM23839'},
{value: 'gm23877', id: 'GM23877'},
{value: 'gm23880', id: 'GM23880'},
{value: 'gm23909', id: 'GM23909'},
{value: 'gm23911', id: 'GM23911'},
{value: 'gm23985', id: 'GM23985'},
{value: 'gm24016', id: 'GM24016'},
{value: 'gm24066', id: 'GM24066'},
{value: 'gm24071', id: 'GM24071'},
{value: 'gm24079', id: 'GM24079'},
{value: 'gm24119', id: 'GM24119'},
{value: 'gm24151', id: 'GM24151'},
{value: 'gm24154', id: 'GM24154'},
{value: 'gm24212', id: 'GM24212'},
{value: 'gm24230', id: 'GM24230'},
{value: 'gm24237', id: 'GM24237'},
{value: 'gm24255', id: 'GM24255'},
{value: 'gm24257', id: 'GM24257'},
{value: 'gm24279', id: 'GM24279'},
{value: 'gm24289', id: 'GM24289'},
{value: 'gm24313', id: 'GM24313'},
{value: 'gm24337', id: 'GM24337'},
{value: 'gm24348', id: 'GM24348'},
{value: 'gm24350', id: 'GM24350'},
{value: 'gm24377', id: 'GM24377'},
{value: 'gm24395', id: 'GM24395'},
{value: 'gm24420', id: 'GM24420'},
{value: 'gm24429', id: 'GM24429'},
{value: 'gm24432', id: 'GM24432'},
{value: 'gm24434', id: 'GM24434'},
{value: 'gm24469', id: 'GM24469'},
{value: 'gm24470', id: 'GM24470'},
{value: 'gm24472', id: 'GM24472'},
{value: 'gm24507', id: 'GM24507'},
{value: 'gm24555', id: 'GM24555'},
{value: 'gm24556', id: 'GM24556'},
{value: 'gm24564', id: 'GM24564'},
{value: 'gm24578', id: 'GM24578'},
{value: 'gm24638', id: 'GM24638'},
{value: 'gm24656', id: 'GM24656'},
{value: 'gm24696', id: 'GM24696'},
{value: 'gm24709', id: 'GM24709'},
{value: 'gm24793', id: 'GM24793'},
{value: 'gm24794', id: 'GM24794'},
{value: 'gm24886', id: 'GM24886'},
{value: 'gm24896', id: 'GM24896'},
{value: 'gm24903', id: 'GM24903'},
{value: 'gm24958', id: 'GM24958'},
{value: 'gm24982', id: 'GM24982'},
{value: 'gm25094', id: 'GM25094'},
{value: 'gm25095', id: 'GM25095'},
{value: 'gm25096', id: 'GM25096'},
{value: 'gm25107', id: 'GM25107'},
{value: 'gm25167', id: 'GM25167'},
{value: 'gm25169', id: 'GM25169'},
{value: 'gm25170', id: 'GM25170'},
{value: 'gm25185', id: 'GM25185'},
{value: 'gm25244', id: 'GM25244'},
{value: 'gm25278', id: 'GM25278'},
{value: 'gm25345', id: 'GM25345'},
{value: 'gm25346', id: 'GM25346'},
{value: 'gm25395', id: 'GM25395'},
{value: 'gm25396', id: 'GM25396'},
{value: 'gm25408', id: 'GM25408'},
{value: 'gm25410', id: 'GM25410'},
{value: 'gm25417', id: 'GM25417'},
{value: 'gm25418', id: 'GM25418'},
{value: 'gm25439', id: 'GM25439'},
{value: 'gm25492', id: 'GM25492'},
{value: 'gm25501', id: 'GM25501'},
{value: 'gm25502', id: 'GM25502'},
{value: 'gm25528', id: 'GM25528'},
{value: 'gm25539', id: 'GM25539'},
{value: 'gm25541', id: 'GM25541'},
{value: 'gm25546', id: 'GM25546'},
{value: 'gm25561', id: 'GM25561'},
{value: 'gm25608', id: 'GM25608'},
{value: 'gm25611', id: 'GM25611'},
{value: 'gm25613', id: 'GM25613'},
{value: 'gm25614', id: 'GM25614'},
{value: 'gm25617', id: 'GM25617'},
{value: 'gm25624', id: 'GM25624'},
{value: 'gm25744', id: 'GM25744'},
{value: 'gm25762', id: 'GM25762'},
{value: 'gm25770', id: 'GM25770'},
{value: 'gm25773', id: 'GM25773'},
{value: 'gm25776', id: 'GM25776'},
{value: 'gm25795', id: 'GM25795'},
{value: 'gm25803', id: 'GM25803'},
{value: 'gm25832', id: 'GM25832'},
{value: 'gm25846', id: 'GM25846'},
{value: 'gm25848', id: 'GM25848'},
{value: 'gm25860', id: 'GM25860'},
{value: 'gm25876', id: 'GM25876'},
{value: 'gm25878', id: 'GM25878'},
{value: 'gm25915', id: 'GM25915'},
{value: 'gm25975', id: 'GM25975'},
{value: 'gm26007', id: 'GM26007'},
{value: 'gm26016', id: 'GM26016'},
{value: 'gm26018', id: 'GM26018'},
{value: 'gm26070', id: 'GM26070'},
{value: 'gm26077', id: 'GM26077'},
{value: 'gm26081', id: 'GM26081'},
{value: 'gm26083', id: 'GM26083'},
{value: 'gm26186', id: 'GM26186'},
{value: 'gm26205', id: 'GM26205'},
{value: 'gm26224', id: 'GM26224'},
{value: 'gm26225', id: 'GM26225'},
{value: 'gm26247', id: 'GM26247'},
{value: 'gm26288', id: 'GM26288'},
{value: 'gm26342', id: 'GM26342'},
{value: 'gm26397', id: 'GM26397'},
{value: 'gm26406', id: 'GM26406'},
{value: 'gm26416', id: 'GM26416'},
{value: 'gm26422', id: 'GM26422'},
{value: 'gm26436', id: 'GM26436'},
{value: 'gm26493', id: 'GM26493'},
{value: 'gm27348', id: 'GM27348'},
{value: 'gm27373', id: 'GM27373'},
{value: 'gm27406', id: 'GM27406'},
{value: 'gm27457', id: 'GM27457'},
{value: 'gm27733', id: 'GM27733'},
{value: 'gm27820', id: 'GM27820'},
{value: 'gm27855', id: 'GM27855'},
{value: 'gm27953', id: 'GM27953'},
{value: 'gm27976', id: 'GM27976'},
{value: 'gm27980', id: 'GM27980'},
{value: 'gm27993', id: 'GM27993'},
{value: 'gm2a', id: 'GM2A'},
{value: 'gm44351', id: 'GM44351'},
{value: 'gm44476', id: 'GM44476'},
{value: 'gm45623', id: 'GM45623'},
{value: 'gm47293', id: 'GM47293'},
{value: 'gm47305', id: 'GM47305'},
{value: 'gm50449', id: 'GM50449'},
{value: 'gm5471', id: 'GM5471'},
{value: 'gm9918', id: 'GM9918'},
{value: 'gm9999', id: 'GM9999'},
{value: 'gmcl1', id: 'GMCL1'},
{value: 'gmds', id: 'GMDS'},
{value: 'gmeb1', id: 'GMEB1'},
{value: 'gmeb2', id: 'GMEB2'},
{value: 'gmfb', id: 'GMFB'},
{value: 'gmfg', id: 'GMFG'},
{value: 'gmip', id: 'GMIP'},
{value: 'gml', id: 'GML'},
{value: 'gmnc', id: 'GMNC'},
{value: 'gmnn', id: 'GMNN'},
{value: 'gmppa', id: 'GMPPA'},
{value: 'gmppb', id: 'GMPPB'},
{value: 'gmpr', id: 'GMPR'},
{value: 'gmpr2', id: 'GMPR2'},
{value: 'gmps', id: 'GMPS'},
{value: 'gna11', id: 'GNA11'},
{value: 'gna12', id: 'GNA12'},
{value: 'gna13', id: 'GNA13'},
{value: 'gna14', id: 'GNA14'},
{value: 'gna15', id: 'GNA15'},
{value: 'gnai1', id: 'GNAI1'},
{value: 'gnai2', id: 'GNAI2'},
{value: 'gnai3', id: 'GNAI3'},
{value: 'gnal', id: 'GNAL'},
{value: 'gnao1', id: 'GNAO1'},
{value: 'gnaq', id: 'GNAQ'},
{value: 'gnas', id: 'GNAS'},
{value: 'gnat1', id: 'GNAT1'},
{value: 'gnat2', id: 'GNAT2'},
{value: 'gnat3', id: 'GNAT3'},
{value: 'gnaz', id: 'GNAZ'},
{value: 'gnb1', id: 'GNB1'},
{value: 'gnb1l', id: 'GNB1L'},
{value: 'gnb2', id: 'GNB2'},
{value: 'gnb3', id: 'GNB3'},
{value: 'gnb4', id: 'GNB4'},
{value: 'gnb5', id: 'GNB5'},
{value: 'gne', id: 'GNE'},
{value: 'gng10', id: 'GNG10'},
{value: 'gng11', id: 'GNG11'},
{value: 'gng12', id: 'GNG12'},
{value: 'gng13', id: 'GNG13'},
{value: 'gng14', id: 'GNG14'},
{value: 'gng2', id: 'GNG2'},
{value: 'gng3', id: 'GNG3'},
{value: 'gng4', id: 'GNG4'},
{value: 'gng5', id: 'GNG5'},
{value: 'gng7', id: 'GNG7'},
{value: 'gng8', id: 'GNG8'},
{value: 'gngt1', id: 'GNGT1'},
{value: 'gngt2', id: 'GNGT2'},
{value: 'gnl1', id: 'GNL1'},
{value: 'gnl2', id: 'GNL2'},
{value: 'gnl3', id: 'GNL3'},
{value: 'gnl3l', id: 'GNL3L'},
{value: 'gnmt', id: 'GNMT'},
{value: 'gnpat', id: 'GNPAT'},
{value: 'gnpda1', id: 'GNPDA1'},
{value: 'gnpda2', id: 'GNPDA2'},
{value: 'gnpnat1', id: 'GNPNAT1'},
{value: 'gnptab', id: 'GNPTAB'},
{value: 'gnptg', id: 'GNPTG'},
{value: 'gnrh1', id: 'GNRH1'},
{value: 'gnrhr', id: 'GNRHR'},
{value: 'gns', id: 'GNS'},
{value: 'golga1', id: 'GOLGA1'},
{value: 'golga2', id: 'GOLGA2'},
{value: 'golga3', id: 'GOLGA3'},
{value: 'golga4', id: 'GOLGA4'},
{value: 'golga5', id: 'GOLGA5'},
{value: 'golga7', id: 'GOLGA7'},
{value: 'golga7b', id: 'GOLGA7B'},
{value: 'golgb1', id: 'GOLGB1'},
{value: 'golim4', id: 'GOLIM4'},
{value: 'golm1', id: 'GOLM1'},
{value: 'golm2', id: 'GOLM2'},
{value: 'golph3', id: 'GOLPH3'},
{value: 'golph3l', id: 'GOLPH3L'},
{value: 'golt1a', id: 'GOLT1A'},
{value: 'golt1b', id: 'GOLT1B'},
{value: 'gon4l', id: 'GON4L'},
{value: 'gon7', id: 'GON7'},
{value: 'gopc', id: 'GOPC'},
{value: 'gorab', id: 'GORAB'},
{value: 'gorasp1', id: 'GORASP1'},
{value: 'gorasp2', id: 'GORASP2'},
{value: 'gosr1', id: 'GOSR1'},
{value: 'gosr2', id: 'GOSR2'},
{value: 'got1', id: 'GOT1'},
{value: 'got1l1', id: 'GOT1L1'},
{value: 'got2', id: 'GOT2'},
{value: 'gp1ba', id: 'GP1BA'},
{value: 'gp1bb', id: 'GP1BB'},
{value: 'gp2', id: 'GP2'},
{value: 'gp5', id: 'GP5'},
{value: 'gp6', id: 'GP6'},
{value: 'gp9', id: 'GP9'},
{value: 'gpa33', id: 'GPA33'},
{value: 'gpaa1', id: 'GPAA1'},
{value: 'gpalpp1', id: 'GPALPP1'},
{value: 'gpam', id: 'GPAM'},
{value: 'gpank1', id: 'GPANK1'},
{value: 'gpat2', id: 'GPAT2'},
{value: 'gpat3', id: 'GPAT3'},
{value: 'gpat4', id: 'GPAT4'},
{value: 'gpatch1', id: 'GPATCH1'},
{value: 'gpatch11', id: 'GPATCH11'},
{value: 'gpatch2', id: 'GPATCH2'},
{value: 'gpatch2l', id: 'GPATCH2L'},
{value: 'gpatch3', id: 'GPATCH3'},
{value: 'gpatch4', id: 'GPATCH4'},
{value: 'gpatch8', id: 'GPATCH8'},
{value: 'gpbar1', id: 'GPBAR1'},
{value: 'gpbp1', id: 'GPBP1'},
{value: 'gpbp1l1', id: 'GPBP1L1'},
{value: 'gpbp1l2', id: 'GPBP1L2'},
{value: 'gpc1', id: 'GPC1'},
{value: 'gpc2', id: 'GPC2'},
{value: 'gpc3', id: 'GPC3'},
{value: 'gpc4', id: 'GPC4'},
{value: 'gpc5', id: 'GPC5'},
{value: 'gpc6', id: 'GPC6'},
{value: 'gpcpd1', id: 'GPCPD1'},
{value: 'gpd1', id: 'GPD1'},
{value: 'gpd1l', id: 'GPD1L'},
{value: 'gpd2', id: 'GPD2'},
{value: 'gper1', id: 'GPER1'},
{value: 'gpha2', id: 'GPHA2'},
{value: 'gphb5', id: 'GPHB5'},
{value: 'gphn', id: 'GPHN'},
{value: 'gpi', id: 'GPI'},
{value: 'gpihbp1', id: 'GPIHBP1'},
{value: 'gpkow', id: 'GPKOW'},
{value: 'gpld1', id: 'GPLD1'},
{value: 'gpm6a', id: 'GPM6A'},
{value: 'gpm6b', id: 'GPM6B'},
{value: 'gpn1', id: 'GPN1'},
{value: 'gpn2', id: 'GPN2'},
{value: 'gpn3', id: 'GPN3'},
{value: 'gpnmb', id: 'GPNMB'},
{value: 'gpr101', id: 'GPR101'},
{value: 'gpr107', id: 'GPR107'},
{value: 'gpr108', id: 'GPR108'},
{value: 'gpr119', id: 'GPR119'},
{value: 'gpr12', id: 'GPR12'},
{value: 'gpr132', id: 'GPR132'},
{value: 'gpr135', id: 'GPR135'},
{value: 'gpr137', id: 'GPR137'},
{value: 'gpr137b', id: 'GPR137B'},
{value: 'gpr137c', id: 'GPR137C'},
{value: 'gpr139', id: 'GPR139'},
{value: 'gpr141', id: 'GPR141'},
{value: 'gpr142', id: 'GPR142'},
{value: 'gpr143', id: 'GPR143'},
{value: 'gpr146', id: 'GPR146'},
{value: 'gpr149', id: 'GPR149'},
{value: 'gpr15', id: 'GPR15'},
{value: 'gpr150', id: 'GPR150'},
{value: 'gpr151', id: 'GPR151'},
{value: 'gpr152', id: 'GPR152'},
{value: 'gpr153', id: 'GPR153'},
{value: 'gpr155', id: 'GPR155'},
{value: 'gpr156', id: 'GPR156'},
{value: 'gpr157', id: 'GPR157'},
{value: 'gpr158', id: 'GPR158'},
{value: 'gpr160', id: 'GPR160'},
{value: 'gpr161', id: 'GPR161'},
{value: 'gpr162', id: 'GPR162'},
{value: 'gpr165', id: 'GPR165'},
{value: 'gpr17', id: 'GPR17'},
{value: 'gpr171', id: 'GPR171'},
{value: 'gpr173', id: 'GPR173'},
{value: 'gpr174', id: 'GPR174'},
{value: 'gpr176', id: 'GPR176'},
{value: 'gpr179', id: 'GPR179'},
{value: 'gpr18', id: 'GPR18'},
{value: 'gpr180', id: 'GPR180'},
{value: 'gpr182', id: 'GPR182'},
{value: 'gpr183', id: 'GPR183'},
{value: 'gpr19', id: 'GPR19'},
{value: 'gpr20', id: 'GPR20'},
{value: 'gpr21', id: 'GPR21'},
{value: 'gpr22', id: 'GPR22'},
{value: 'gpr25', id: 'GPR25'},
{value: 'gpr26', id: 'GPR26'},
{value: 'gpr27', id: 'GPR27'},
{value: 'gpr3', id: 'GPR3'},
{value: 'gpr31', id: 'GPR31'},
{value: 'gpr33', id: 'GPR33'},
{value: 'gpr34', id: 'GPR34'},
{value: 'gpr35', id: 'GPR35'},
{value: 'gpr37', id: 'GPR37'},
{value: 'gpr37l1', id: 'GPR37L1'},
{value: 'gpr39', id: 'GPR39'},
{value: 'gpr4', id: 'GPR4'},
{value: 'gpr45', id: 'GPR45'},
{value: 'gpr50', id: 'GPR50'},
{value: 'gpr52', id: 'GPR52'},
{value: 'gpr55', id: 'GPR55'},
{value: 'gpr6', id: 'GPR6'},
{value: 'gpr61', id: 'GPR61'},
{value: 'gpr62', id: 'GPR62'},
{value: 'gpr63', id: 'GPR63'},
{value: 'gpr65', id: 'GPR65'},
{value: 'gpr68', id: 'GPR68'},
{value: 'gpr75', id: 'GPR75'},
{value: 'gpr82', id: 'GPR82'},
{value: 'gpr83', id: 'GPR83'},
{value: 'gpr84', id: 'GPR84'},
{value: 'gpr85', id: 'GPR85'},
{value: 'gpr87', id: 'GPR87'},
{value: 'gpr88', id: 'GPR88'},
{value: 'gpr89b', id: 'GPR89B'},
{value: 'gprasp1', id: 'GPRASP1'},
{value: 'gprasp2', id: 'GPRASP2'},
{value: 'gprc5a', id: 'GPRC5A'},
{value: 'gprc5b', id: 'GPRC5B'},
{value: 'gprc5c', id: 'GPRC5C'},
{value: 'gprc5d', id: 'GPRC5D'},
{value: 'gprc6a', id: 'GPRC6A'},
{value: 'gprin1', id: 'GPRIN1'},
{value: 'gprin2', id: 'GPRIN2'},
{value: 'gprin3', id: 'GPRIN3'},
{value: 'gps1', id: 'GPS1'},
{value: 'gps2', id: 'GPS2'},
{value: 'gpsm1', id: 'GPSM1'},
{value: 'gpsm2', id: 'GPSM2'},
{value: 'gpsm3', id: 'GPSM3'},
{value: 'gpt', id: 'GPT'},
{value: 'gpt2', id: 'GPT2'},
{value: 'gpx1', id: 'GPX1'},
{value: 'gpx2', id: 'GPX2'},
{value: 'gpx3', id: 'GPX3'},
{value: 'gpx4', id: 'GPX4'},
{value: 'gpx5', id: 'GPX5'},
{value: 'gpx6', id: 'GPX6'},
{value: 'gpx7', id: 'GPX7'},
{value: 'gpx8', id: 'GPX8'},
{value: 'gramd1a', id: 'GRAMD1A'},
{value: 'gramd1b', id: 'GRAMD1B'},
{value: 'gramd1c', id: 'GRAMD1C'},
{value: 'gramd2a', id: 'GRAMD2A'},
{value: 'gramd2b', id: 'GRAMD2B'},
{value: 'gramd4', id: 'GRAMD4'},
{value: 'grap', id: 'GRAP'},
{value: 'grap2', id: 'GRAP2'},
{value: 'grb10', id: 'GRB10'},
{value: 'grb14', id: 'GRB14'},
{value: 'grb2', id: 'GRB2'},
{value: 'grb7', id: 'GRB7'},
{value: 'grcc10', id: 'GRCC10'},
{value: 'greb1', id: 'GREB1'},
{value: 'greb1l', id: 'GREB1L'},
{value: 'grem1', id: 'GREM1'},
{value: 'grem2', id: 'GREM2'},
{value: 'grhl1', id: 'GRHL1'},
{value: 'grhl2', id: 'GRHL2'},
{value: 'grhl3', id: 'GRHL3'},
{value: 'grhpr', id: 'GRHPR'},
{value: 'gria1', id: 'GRIA1'},
{value: 'gria2', id: 'GRIA2'},
{value: 'gria3', id: 'GRIA3'},
{value: 'gria4', id: 'GRIA4'},
{value: 'grid1', id: 'GRID1'},
{value: 'grid2', id: 'GRID2'},
{value: 'grid2ip', id: 'GRID2IP'},
{value: 'grifin', id: 'GRIFIN'},
{value: 'grik1', id: 'GRIK1'},
{value: 'grik2', id: 'GRIK2'},
{value: 'grik3', id: 'GRIK3'},
{value: 'grik4', id: 'GRIK4'},
{value: 'grik5', id: 'GRIK5'},
{value: 'grin1', id: 'GRIN1'},
{value: 'grin2a', id: 'GRIN2A'},
{value: 'grin2b', id: 'GRIN2B'},
{value: 'grin2c', id: 'GRIN2C'},
{value: 'grin2d', id: 'GRIN2D'},
{value: 'grin3a', id: 'GRIN3A'},
{value: 'grin3b', id: 'GRIN3B'},
{value: 'grina', id: 'GRINA'},
{value: 'grip1', id: 'GRIP1'},
{value: 'grip2', id: 'GRIP2'},
{value: 'gripap1', id: 'GRIPAP1'},
{value: 'grk1', id: 'GRK1'},
{value: 'grk2', id: 'GRK2'},
{value: 'grk3', id: 'GRK3'},
{value: 'grk4', id: 'GRK4'},
{value: 'grk5', id: 'GRK5'},
{value: 'grk6', id: 'GRK6'},
{value: 'grm1', id: 'GRM1'},
{value: 'grm2', id: 'GRM2'},
{value: 'grm3', id: 'GRM3'},
{value: 'grm4', id: 'GRM4'},
{value: 'grm5', id: 'GRM5'},
{value: 'grm6', id: 'GRM6'},
{value: 'grm7', id: 'GRM7'},
{value: 'grm8', id: 'GRM8'},
{value: 'grn', id: 'GRN'},
{value: 'grp', id: 'GRP'},
{value: 'grpel1', id: 'GRPEL1'},
{value: 'grpel2', id: 'GRPEL2'},
{value: 'grpr', id: 'GRPR'},
{value: 'grsf1', id: 'GRSF1'},
{value: 'grtp1', id: 'GRTP1'},
{value: 'grwd1', id: 'GRWD1'},
{value: 'grxcr1', id: 'GRXCR1'},
{value: 'grxcr2', id: 'GRXCR2'},
{value: 'gsap', id: 'GSAP'},
{value: 'gsc', id: 'GSC'},
{value: 'gsc2', id: 'GSC2'},
{value: 'gsdma', id: 'GSDMA'},
{value: 'gsdmc', id: 'GSDMC'},
{value: 'gsdmd', id: 'GSDMD'},
{value: 'gsdme', id: 'GSDME'},
{value: 'gse1', id: 'GSE1'},
{value: 'gsg1', id: 'GSG1'},
{value: 'gsg1l', id: 'GSG1L'},
{value: 'gsk3a', id: 'GSK3A'},
{value: 'gsk3b', id: 'GSK3B'},
{value: 'gskip', id: 'GSKIP'},
{value: 'gsn', id: 'GSN'},
{value: 'gspt1', id: 'GSPT1'},
{value: 'gspt2', id: 'GSPT2'},
{value: 'gsr', id: 'GSR'},
{value: 'gss', id: 'GSS'},
{value: 'gsta1', id: 'GSTA1'},
{value: 'gsta2', id: 'GSTA2'},
{value: 'gsta3', id: 'GSTA3'},
{value: 'gsta4', id: 'GSTA4'},
{value: 'gsta5', id: 'GSTA5'},
{value: 'gsta6', id: 'GSTA6'},
{value: 'gstcd', id: 'GSTCD'},
{value: 'gstk1', id: 'GSTK1'},
{value: 'gstm1', id: 'GSTM1'},
{value: 'gstm2', id: 'GSTM2'},
{value: 'gstm3', id: 'GSTM3'},
{value: 'gstm3l1', id: 'GSTM3L1'},
{value: 'gstm4', id: 'GSTM4'},
{value: 'gstm5', id: 'GSTM5'},
{value: 'gstm6', id: 'GSTM6'},
{value: 'gstm6l', id: 'GSTM6L'},
{value: 'gstm7', id: 'GSTM7'},
{value: 'gsto1', id: 'GSTO1'},
{value: 'gsto2', id: 'GSTO2'},
{value: 'gstp1', id: 'GSTP1'},
{value: 'gstt1', id: 'GSTT1'},
{value: 'gstt2', id: 'GSTT2'},
{value: 'gstt3', id: 'GSTT3'},
{value: 'gstt4', id: 'GSTT4'},
{value: 'gstz1', id: 'GSTZ1'},
{value: 'gsx1', id: 'GSX1'},
{value: 'gsx2', id: 'GSX2'},
{value: 'gtdc1', id: 'GTDC1'},
{value: 'gtf2a1', id: 'GTF2A1'},
{value: 'gtf2a1l', id: 'GTF2A1L'},
{value: 'gtf2a2', id: 'GTF2A2'},
{value: 'gtf2b', id: 'GTF2B'},
{value: 'gtf2e1', id: 'GTF2E1'},
{value: 'gtf2e2', id: 'GTF2E2'},
{value: 'gtf2f1', id: 'GTF2F1'},
{value: 'gtf2f2', id: 'GTF2F2'},
{value: 'gtf2h1', id: 'GTF2H1'},
{value: 'gtf2h2', id: 'GTF2H2'},
{value: 'gtf2h3', id: 'GTF2H3'},
{value: 'gtf2h4', id: 'GTF2H4'},
{value: 'gtf2h5', id: 'GTF2H5'},
{value: 'gtf2i', id: 'GTF2I'},
{value: 'gtf2ird1', id: 'GTF2IRD1'},
{value: 'gtf2ird2', id: 'GTF2IRD2'},
{value: 'gtf3a', id: 'GTF3A'},
{value: 'gtf3c1', id: 'GTF3C1'},
{value: 'gtf3c2', id: 'GTF3C2'},
{value: 'gtf3c3', id: 'GTF3C3'},
{value: 'gtf3c4', id: 'GTF3C4'},
{value: 'gtf3c5', id: 'GTF3C5'},
{value: 'gtf3c6', id: 'GTF3C6'},
{value: 'gtpbp1', id: 'GTPBP1'},
{value: 'gtpbp10', id: 'GTPBP10'},
{value: 'gtpbp2', id: 'GTPBP2'},
{value: 'gtpbp3', id: 'GTPBP3'},
{value: 'gtpbp4', id: 'GTPBP4'},
{value: 'gtpbp6', id: 'GTPBP6'},
{value: 'gtpbp8', id: 'GTPBP8'},
{value: 'gtse1', id: 'GTSE1'},
{value: 'gtsf1', id: 'GTSF1'},
{value: 'gtsf2', id: 'GTSF2'},
{value: 'guca1a', id: 'GUCA1A'},
{value: 'guca1b', id: 'GUCA1B'},
{value: 'guca2a', id: 'GUCA2A'},
{value: 'guca2b', id: 'GUCA2B'},
{value: 'gucd1', id: 'GUCD1'},
{value: 'gucy1a1', id: 'GUCY1A1'},
{value: 'gucy1a2', id: 'GUCY1A2'},
{value: 'gucy1b1', id: 'GUCY1B1'},
{value: 'gucy1b2', id: 'GUCY1B2'},
{value: 'gucy2c', id: 'GUCY2C'},
{value: 'gucy2d', id: 'GUCY2D'},
{value: 'gucy2e', id: 'GUCY2E'},
{value: 'gucy2f', id: 'GUCY2F'},
{value: 'gucy2g', id: 'GUCY2G'},
{value: 'guf1', id: 'GUF1'},
{value: 'guk1', id: 'GUK1'},
{value: 'gulo', id: 'GULO'},
{value: 'gulp1', id: 'GULP1'},
{value: 'gusb', id: 'GUSB'},
{value: 'gvin1', id: 'GVIN1'},
{value: 'gvinp1', id: 'GVINP1'},
{value: 'gxylt1', id: 'GXYLT1'},
{value: 'gxylt2', id: 'GXYLT2'},
{value: 'gyg1', id: 'GYG1'},
{value: 'gykl1', id: 'GYKL1'},
{value: 'gypa', id: 'GYPA'},
{value: 'gypc', id: 'GYPC'},
{value: 'gys1', id: 'GYS1'},
{value: 'gys2', id: 'GYS2'},
{value: 'gzf1', id: 'GZF1'},
{value: 'gzma', id: 'GZMA'},
{value: 'gzmb', id: 'GZMB'},
{value: 'gzmbl1', id: 'GZMBL1'},
{value: 'gzmbl2', id: 'GZMBL2'},
{value: 'gzmbl3', id: 'GZMBL3'},
{value: 'gzmc', id: 'GZMC'},
{value: 'gzmf', id: 'GZMF'},
{value: 'gzmk', id: 'GZMK'},
{value: 'gzmm', id: 'GZMM'},
{value: 'gzmn', id: 'GZMN'},
{value: 'h1f0', id: 'H1F0'},
{value: 'h1f1', id: 'H1F1'},
{value: 'h1f10', id: 'H1F10'},
{value: 'h1f2', id: 'H1F2'},
{value: 'h1f4', id: 'H1F4'},
{value: 'h1f5', id: 'H1F5'},
{value: 'h1f6', id: 'H1F6'},
{value: 'h1f7', id: 'H1F7'},
{value: 'h1f8', id: 'H1F8'},
{value: 'h2ab3', id: 'H2AB3'},
{value: 'h2ac1', id: 'H2AC1'},
{value: 'h2ac12', id: 'H2AC12'},
{value: 'h2ac20', id: 'H2AC20'},
{value: 'h2ac21', id: 'H2AC21'},
{value: 'h2ac4', id: 'H2AC4'},
{value: 'h2aj', id: 'H2AJ'},
{value: 'h2al3', id: 'H2AL3'},
{value: 'h2ap', id: 'H2AP'},
{value: 'h2ax', id: 'H2AX'},
{value: 'h2az1', id: 'H2AZ1'},
{value: 'h2az2', id: 'H2AZ2'},
{value: 'h2bc1', id: 'H2BC1'},
{value: 'h2bc12', id: 'H2BC12'},
{value: 'h2bc9', id: 'H2BC9'},
{value: 'h2bu1', id: 'H2BU1'},
{value: 'h3c1', id: 'H3C1'},
{value: 'h3c13', id: 'H3C13'},
{value: 'h3f3a', id: 'H3F3A'},
{value: 'h3f3b', id: 'H3F3B'},
{value: 'h3f3c', id: 'H3F3C'},
{value: 'h4f3', id: 'H4F3'},
{value: 'h6pd', id: 'H6PD'},
{value: 'haao', id: 'HAAO'},
{value: 'habp2', id: 'HABP2'},
{value: 'habp4', id: 'HABP4'},
{value: 'hacd1', id: 'HACD1'},
{value: 'hacd2', id: 'HACD2'},
{value: 'hacd3', id: 'HACD3'},
{value: 'hacd4', id: 'HACD4'},
{value: 'hace1', id: 'HACE1'},
{value: 'hacl1', id: 'HACL1'},
{value: 'hadh', id: 'HADH'},
{value: 'hadha', id: 'HADHA'},
{value: 'hadhb', id: 'HADHB'},
{value: 'hagh', id: 'HAGH'},
{value: 'haghl', id: 'HAGHL'},
{value: 'hal', id: 'HAL'},
{value: 'hamp', id: 'HAMP'},
{value: 'hand1', id: 'HAND1'},
{value: 'hand2', id: 'HAND2'},
{value: 'hao1', id: 'HAO1'},
{value: 'hao2', id: 'HAO2'},
{value: 'hap1', id: 'HAP1'},
{value: 'hapln1', id: 'HAPLN1'},
{value: 'hapln2', id: 'HAPLN2'},
{value: 'hapln3', id: 'HAPLN3'},
{value: 'hapln4', id: 'HAPLN4'},
{value: 'harbi1', id: 'HARBI1'},
{value: 'hars1', id: 'HARS1'},
{value: 'hars2', id: 'HARS2'},
{value: 'has1', id: 'HAS1'},
{value: 'has2', id: 'HAS2'},
{value: 'has3', id: 'HAS3'},
{value: 'haspin', id: 'HASPIN'},
{value: 'hat1', id: 'HAT1'},
{value: 'haus1', id: 'HAUS1'},
{value: 'haus2', id: 'HAUS2'},
{value: 'haus3', id: 'HAUS3'},
{value: 'haus4', id: 'HAUS4'},
{value: 'haus5', id: 'HAUS5'},
{value: 'haus6', id: 'HAUS6'},
{value: 'haus7', id: 'HAUS7'},
{value: 'haus8', id: 'HAUS8'},
{value: 'havcr1', id: 'HAVCR1'},
{value: 'havcr2', id: 'HAVCR2'},
{value: 'hax1', id: 'HAX1'},
{value: 'hba-a1', id: 'HBA-A1'},
{value: 'hba-a2', id: 'HBA-A2'},
{value: 'hba-a3', id: 'HBA-A3'},
{value: 'hbb-b1', id: 'HBB-B1'},
{value: 'hbb-bs', id: 'HBB-BS'},
{value: 'hbb', id: 'HBB'},
{value: 'hbe1', id: 'HBE1'},
{value: 'hbe2', id: 'HBE2'},
{value: 'hbegf', id: 'HBEGF'},
{value: 'hbg1', id: 'HBG1'},
{value: 'hbp1', id: 'HBP1'},
{value: 'hbq1b', id: 'HBQ1B'},
{value: 'hbs1l', id: 'HBS1L'},
{value: 'hbz', id: 'HBZ'},
{value: 'hcar1', id: 'HCAR1'},
{value: 'hcar2', id: 'HCAR2'},
{value: 'hccs', id: 'HCCS'},
{value: 'hcfc1', id: 'HCFC1'},
{value: 'hcfc1r1', id: 'HCFC1R1'},
{value: 'hcfc2', id: 'HCFC2'},
{value: 'hck', id: 'HCK'},
{value: 'hcls1', id: 'HCLS1'},
{value: 'hcn1', id: 'HCN1'},
{value: 'hcn2', id: 'HCN2'},
{value: 'hcn3', id: 'HCN3'},
{value: 'hcn4', id: 'HCN4'},
{value: 'hcrt', id: 'HCRT'},
{value: 'hcrtr1', id: 'HCRTR1'},
{value: 'hcrtr2', id: 'HCRTR2'},
{value: 'hcst', id: 'HCST'},
{value: 'hdac1', id: 'HDAC1'},
{value: 'hdac10', id: 'HDAC10'},
{value: 'hdac11', id: 'HDAC11'},
{value: 'hdac1l', id: 'HDAC1L'},
{value: 'hdac2', id: 'HDAC2'},
{value: 'hdac3', id: 'HDAC3'},
{value: 'hdac4', id: 'HDAC4'},
{value: 'hdac5', id: 'HDAC5'},
{value: 'hdac6', id: 'HDAC6'},
{value: 'hdac7', id: 'HDAC7'},
{value: 'hdac8', id: 'HDAC8'},
{value: 'hdac9', id: 'HDAC9'},
{value: 'hdc', id: 'HDC'},
{value: 'hddc2', id: 'HDDC2'},
{value: 'hddc3', id: 'HDDC3'},
{value: 'hdgf', id: 'HDGF'},
{value: 'hdgfl1', id: 'HDGFL1'},
{value: 'hdgfl2', id: 'HDGFL2'},
{value: 'hdgfl3', id: 'HDGFL3'},
{value: 'hdhd2', id: 'HDHD2'},
{value: 'hdhd3', id: 'HDHD3'},
{value: 'hdhd5', id: 'HDHD5'},
{value: 'hdlbp', id: 'HDLBP'},
{value: 'hdx', id: 'HDX'},
{value: 'heatr1', id: 'HEATR1'},
{value: 'heatr3', id: 'HEATR3'},
{value: 'heatr4', id: 'HEATR4'},
{value: 'heatr5a', id: 'HEATR5A'},
{value: 'heatr5b', id: 'HEATR5B'},
{value: 'heatr6', id: 'HEATR6'},
{value: 'heatr9', id: 'HEATR9'},
{value: 'hebp1', id: 'HEBP1'},
{value: 'hebp2', id: 'HEBP2'},
{value: 'heca', id: 'HECA'},
{value: 'hectd1', id: 'HECTD1'},
{value: 'hectd2', id: 'HECTD2'},
{value: 'hectd3', id: 'HECTD3'},
{value: 'hectd4', id: 'HECTD4'},
{value: 'hecw1', id: 'HECW1'},
{value: 'hecw2', id: 'HECW2'},
{value: 'heg1', id: 'HEG1'},
{value: 'helb', id: 'HELB'},
{value: 'hells', id: 'HELLS'},
{value: 'helq', id: 'HELQ'},
{value: 'helt', id: 'HELT'},
{value: 'helz', id: 'HELZ'},
{value: 'helz2', id: 'HELZ2'},
{value: 'hemgn', id: 'HEMGN'},
{value: 'hemk1', id: 'HEMK1'},
{value: 'hepacam', id: 'HEPACAM'},
{value: 'hepacam2', id: 'HEPACAM2'},
{value: 'heph', id: 'HEPH'},
{value: 'hephl1', id: 'HEPHL1'},
{value: 'herc1', id: 'HERC1'},
{value: 'herc2', id: 'HERC2'},
{value: 'herc3', id: 'HERC3'},
{value: 'herc4', id: 'HERC4'},
{value: 'herc6', id: 'HERC6'},
{value: 'herpud1', id: 'HERPUD1'},
{value: 'herpud2', id: 'HERPUD2'},
{value: 'hes1', id: 'HES1'},
{value: 'hes2', id: 'HES2'},
{value: 'hes3', id: 'HES3'},
{value: 'hes5', id: 'HES5'},
{value: 'hes6', id: 'HES6'},
{value: 'hes7', id: 'HES7'},
{value: 'hesx1', id: 'HESX1'},
{value: 'hexa', id: 'HEXA'},
{value: 'hexb', id: 'HEXB'},
{value: 'hexd', id: 'HEXD'},
{value: 'hexim1', id: 'HEXIM1'},
{value: 'hexim2', id: 'HEXIM2'},
{value: 'hey1', id: 'HEY1'},
{value: 'hey2', id: 'HEY2'},
{value: 'heyl', id: 'HEYL'},
{value: 'hfe', id: 'HFE'},
{value: 'hfm1', id: 'HFM1'},
{value: 'hgd', id: 'HGD'},
{value: 'hgf', id: 'HGF'},
{value: 'hgfac', id: 'HGFAC'},
{value: 'hgh1', id: 'HGH1'},
{value: 'hgs', id: 'HGS'},
{value: 'hhat', id: 'HHAT'},
{value: 'hhatl', id: 'HHATL'},
{value: 'hhex', id: 'HHEX'},
{value: 'hhip', id: 'HHIP'},
{value: 'hhipl1', id: 'HHIPL1'},
{value: 'hhipl2', id: 'HHIPL2'},
{value: 'hhla1', id: 'HHLA1'},
{value: 'hiatl3', id: 'HIATL3'},
{value: 'hibadh', id: 'HIBADH'},
{value: 'hibch', id: 'HIBCH'},
{value: 'hic1', id: 'HIC1'},
{value: 'hic2', id: 'HIC2'},
{value: 'hid1', id: 'HID1'},
{value: 'hif1a', id: 'HIF1A'},
{value: 'hif1an', id: 'HIF1AN'},
{value: 'hif3a', id: 'HIF3A'},
{value: 'higd1a', id: 'HIGD1A'},
{value: 'higd1b', id: 'HIGD1B'},
{value: 'higd1c', id: 'HIGD1C'},
{value: 'higd2a', id: 'HIGD2A'},
{value: 'higd2al1', id: 'HIGD2AL1'},
{value: 'hikeshi', id: 'HIKESHI'},
{value: 'hilpda', id: 'HILPDA'},
{value: 'hils1', id: 'HILS1'},
{value: 'hinfp', id: 'HINFP'},
{value: 'hint1', id: 'HINT1'},
{value: 'hint2', id: 'HINT2'},
{value: 'hint3', id: 'HINT3'},
{value: 'hip1', id: 'HIP1'},
{value: 'hip1r', id: 'HIP1R'},
{value: 'hipk1', id: 'HIPK1'},
{value: 'hipk2', id: 'HIPK2'},
{value: 'hipk3', id: 'HIPK3'},
{value: 'hipk4', id: 'HIPK4'},
{value: 'hira', id: 'HIRA'},
{value: 'hirip3', id: 'HIRIP3'},
{value: 'hist1h2ac', id: 'HIST1H2AC'},
{value: 'hist1h2af', id: 'HIST1H2AF'},
{value: 'hist1h2ah', id: 'HIST1H2AH'},
{value: 'hist1h2ai', id: 'HIST1H2AI'},
{value: 'hist1h2ail1', id: 'HIST1H2AIL1'},
{value: 'hist1h2ail2', id: 'HIST1H2AIL2'},
{value: 'hist1h2an', id: 'HIST1H2AN'},
{value: 'hist1h2ao', id: 'HIST1H2AO'},
{value: 'hist1h2bc', id: 'HIST1H2BC'},
{value: 'hist1h2bd', id: 'HIST1H2BD'},
{value: 'hist1h2bg', id: 'HIST1H2BG'},
{value: 'hist1h2bl', id: 'HIST1H2BL'},
{value: 'hist1h2bo', id: 'HIST1H2BO'},
{value: 'hist1h2bq', id: 'HIST1H2BQ'},
{value: 'hist1h3b', id: 'HIST1H3B'},
{value: 'hist1h4b', id: 'HIST1H4B'},
{value: 'hist1h4m', id: 'HIST1H4M'},
{value: 'hist2h2aa2', id: 'HIST2H2AA2'},
{value: 'hist2h2aa3', id: 'HIST2H2AA3'},
{value: 'hist2h2be', id: 'HIST2H2BE'},
{value: 'hist2h3c2', id: 'HIST2H3C2'},
{value: 'hist2h4', id: 'HIST2H4'},
{value: 'hist2h4a', id: 'HIST2H4A'},
{value: 'hist3h2ba', id: 'HIST3H2BA'},
{value: 'hivep1', id: 'HIVEP1'},
{value: 'hivep2', id: 'HIVEP2'},
{value: 'hivep3', id: 'HIVEP3'},
{value: 'hjurp', id: 'HJURP'},
{value: 'hjv', id: 'HJV'},
{value: 'hk1', id: 'HK1'},
{value: 'hk2', id: 'HK2'},
{value: 'hk3', id: 'HK3'},
{value: 'hkdc1', id: 'HKDC1'},
{value: 'hlcs', id: 'HLCS'},
{value: 'hlf', id: 'HLF'},
{value: 'hltf', id: 'HLTF'},
{value: 'hlx', id: 'HLX'},
{value: 'hm13', id: 'HM13'},
{value: 'hmbox1', id: 'HMBOX1'},
{value: 'hmbs', id: 'HMBS'},
{value: 'hmces', id: 'HMCES'},
{value: 'hmcn1', id: 'HMCN1'},
{value: 'hmcn2', id: 'HMCN2'},
{value: 'hmg1l1', id: 'HMG1L1'},
{value: 'hmg20a', id: 'HMG20A'},
{value: 'hmg20b', id: 'HMG20B'},
{value: 'hmga1', id: 'HMGA1'},
{value: 'hmga2', id: 'HMGA2'},
{value: 'hmgb1', id: 'HMGB1'},
{value: 'hmgb2', id: 'HMGB2'},
{value: 'hmgb2l1', id: 'HMGB2L1'},
{value: 'hmgb3', id: 'HMGB3'},
{value: 'hmgb4', id: 'HMGB4'},
{value: 'hmgcl', id: 'HMGCL'},
{value: 'hmgcll1', id: 'HMGCLL1'},
{value: 'hmgcr', id: 'HMGCR'},
{value: 'hmgcs1', id: 'HMGCS1'},
{value: 'hmgcs2', id: 'HMGCS2'},
{value: 'hmgn1', id: 'HMGN1'},
{value: 'hmgn2', id: 'HMGN2'},
{value: 'hmgn3', id: 'HMGN3'},
{value: 'hmgn4', id: 'HMGN4'},
{value: 'hmgn5', id: 'HMGN5'},
{value: 'hmgn5b', id: 'HMGN5B'},
{value: 'hmgxb3', id: 'HMGXB3'},
{value: 'hmgxb4', id: 'HMGXB4'},
{value: 'hmmr', id: 'HMMR'},
{value: 'hmox1', id: 'HMOX1'},
{value: 'hmox2', id: 'HMOX2'},
{value: 'hmx1', id: 'HMX1'},
{value: 'hmx2', id: 'HMX2'},
{value: 'hmx3', id: 'HMX3'},
{value: 'hnf1a', id: 'HNF1A'},
{value: 'hnf1b', id: 'HNF1B'},
{value: 'hnf4a', id: 'HNF4A'},
{value: 'hnf4g', id: 'HNF4G'},
{value: 'hnmt', id: 'HNMT'},
{value: 'hnrnpa1', id: 'HNRNPA1'},
{value: 'hnrnpa2b1', id: 'HNRNPA2B1'},
{value: 'hnrnpa3', id: 'HNRNPA3'},
{value: 'hnrnpab', id: 'HNRNPAB'},
{value: 'hnrnpc', id: 'HNRNPC'},
{value: 'hnrnpd', id: 'HNRNPD'},
{value: 'hnrnpdl', id: 'HNRNPDL'},
{value: 'hnrnpf', id: 'HNRNPF'},
{value: 'hnrnph1', id: 'HNRNPH1'},
{value: 'hnrnph2', id: 'HNRNPH2'},
{value: 'hnrnph3', id: 'HNRNPH3'},
{value: 'hnrnpk', id: 'HNRNPK'},
{value: 'hnrnpl', id: 'HNRNPL'},
{value: 'hnrnpll', id: 'HNRNPLL'},
{value: 'hnrnpm', id: 'HNRNPM'},
{value: 'hnrnpr', id: 'HNRNPR'},
{value: 'hnrnpu', id: 'HNRNPU'},
{value: 'hnrnpul1', id: 'HNRNPUL1'},
{value: 'hnrnpul2', id: 'HNRNPUL2'},
{value: 'hoatz', id: 'HOATZ'},
{value: 'hoga1', id: 'HOGA1'},
{value: 'homer1', id: 'HOMER1'},
{value: 'homer2', id: 'HOMER2'},
{value: 'homer3', id: 'HOMER3'},
{value: 'homez', id: 'HOMEZ'},
{value: 'hook1', id: 'HOOK1'},
{value: 'hook2', id: 'HOOK2'},
{value: 'hook3', id: 'HOOK3'},
{value: 'hopx', id: 'HOPX'},
{value: 'hormad1', id: 'HORMAD1'},
{value: 'hormad2', id: 'HORMAD2'},
{value: 'hoxa1', id: 'HOXA1'},
{value: 'hoxa10', id: 'HOXA10'},
{value: 'hoxa11', id: 'HOXA11'},
{value: 'hoxa13', id: 'HOXA13'},
{value: 'hoxa2', id: 'HOXA2'},
{value: 'hoxa3', id: 'HOXA3'},
{value: 'hoxa4', id: 'HOXA4'},
{value: 'hoxa5', id: 'HOXA5'},
{value: 'hoxa6', id: 'HOXA6'},
{value: 'hoxa7', id: 'HOXA7'},
{value: 'hoxa9', id: 'HOXA9'},
{value: 'hoxb1', id: 'HOXB1'},
{value: 'hoxb13', id: 'HOXB13'},
{value: 'hoxb2', id: 'HOXB2'},
{value: 'hoxb3', id: 'HOXB3'},
{value: 'hoxb4', id: 'HOXB4'},
{value: 'hoxb5', id: 'HOXB5'},
{value: 'hoxb6', id: 'HOXB6'},
{value: 'hoxb7', id: 'HOXB7'},
{value: 'hoxb8', id: 'HOXB8'},
{value: 'hoxb9', id: 'HOXB9'},
{value: 'hoxc10', id: 'HOXC10'},
{value: 'hoxc11', id: 'HOXC11'},
{value: 'hoxc12', id: 'HOXC12'},
{value: 'hoxc13', id: 'HOXC13'},
{value: 'hoxc4', id: 'HOXC4'},
{value: 'hoxc5', id: 'HOXC5'},
{value: 'hoxc6', id: 'HOXC6'},
{value: 'hoxc8', id: 'HOXC8'},
{value: 'hoxc9', id: 'HOXC9'},
{value: 'hoxd1', id: 'HOXD1'},
{value: 'hoxd10', id: 'HOXD10'},
{value: 'hoxd11', id: 'HOXD11'},
{value: 'hoxd12', id: 'HOXD12'},
{value: 'hoxd13', id: 'HOXD13'},
{value: 'hoxd3', id: 'HOXD3'},
{value: 'hoxd4', id: 'HOXD4'},
{value: 'hoxd8', id: 'HOXD8'},
{value: 'hoxd9', id: 'HOXD9'},
{value: 'hp', id: 'HP'},
{value: 'hp1bp3', id: 'HP1BP3'},
{value: 'hpca', id: 'HPCA'},
{value: 'hpcal1', id: 'HPCAL1'},
{value: 'hpcal4', id: 'HPCAL4'},
{value: 'hpd', id: 'HPD'},
{value: 'hpdl', id: 'HPDL'},
{value: 'hpf1', id: 'HPF1'},
{value: 'hpgd', id: 'HPGD'},
{value: 'hpgds', id: 'HPGDS'},
{value: 'hpn', id: 'HPN'},
{value: 'hprt1', id: 'HPRT1'},
{value: 'hps1', id: 'HPS1'},
{value: 'hps3', id: 'HPS3'},
{value: 'hps4', id: 'HPS4'},
{value: 'hps5', id: 'HPS5'},
{value: 'hps6', id: 'HPS6'},
{value: 'hpse', id: 'HPSE'},
{value: 'hpx', id: 'HPX'},
{value: 'hr', id: 'HR'},
{value: 'hras', id: 'HRAS'},
{value: 'hrc', id: 'HRC'},
{value: 'hrct1', id: 'HRCT1'},
{value: 'hrg', id: 'HRG'},
{value: 'hrh1', id: 'HRH1'},
{value: 'hrh2', id: 'HRH2'},
{value: 'hrh3', id: 'HRH3'},
{value: 'hrh4', id: 'HRH4'},
{value: 'hrnr', id: 'HRNR'},
{value: 'hrob', id: 'HROB'},
{value: 'hs1bp3', id: 'HS1BP3'},
{value: 'hs2st1', id: 'HS2ST1'},
{value: 'hs3st1', id: 'HS3ST1'},
{value: 'hs3st2', id: 'HS3ST2'},
{value: 'hs3st3a1', id: 'HS3ST3A1'},
{value: 'hs3st3b1', id: 'HS3ST3B1'},
{value: 'hs3st5', id: 'HS3ST5'},
{value: 'hs3st6', id: 'HS3ST6'},
{value: 'hs6st1', id: 'HS6ST1'},
{value: 'hs6st2', id: 'HS6ST2'},
{value: 'hs6st3', id: 'HS6ST3'},
{value: 'hsbp1', id: 'HSBP1'},
{value: 'hsbp1l1', id: 'HSBP1L1'},
{value: 'hscb', id: 'HSCB'},
{value: 'hsd11b1', id: 'HSD11B1'},
{value: 'hsd11b2', id: 'HSD11B2'},
{value: 'hsd17b1', id: 'HSD17B1'},
{value: 'hsd17b10', id: 'HSD17B10'},
{value: 'hsd17b11', id: 'HSD17B11'},
{value: 'hsd17b12', id: 'HSD17B12'},
{value: 'hsd17b13', id: 'HSD17B13'},
{value: 'hsd17b14', id: 'HSD17B14'},
{value: 'hsd17b2', id: 'HSD17B2'},
{value: 'hsd17b3', id: 'HSD17B3'},
{value: 'hsd17b4', id: 'HSD17B4'},
{value: 'hsd17b6', id: 'HSD17B6'},
{value: 'hsd17b7', id: 'HSD17B7'},
{value: 'hsd17b8', id: 'HSD17B8'},
{value: 'hsd3b2', id: 'HSD3B2'},
{value: 'hsd3b3', id: 'HSD3B3'},
{value: 'hsd3b5', id: 'HSD3B5'},
{value: 'hsd3b7', id: 'HSD3B7'},
{value: 'hsdl1', id: 'HSDL1'},
{value: 'hsdl2', id: 'HSDL2'},
{value: 'hsf1', id: 'HSF1'},
{value: 'hsf2', id: 'HSF2'},
{value: 'hsf2bp', id: 'HSF2BP'},
{value: 'hsf4', id: 'HSF4'},
{value: 'hsf5', id: 'HSF5'},
{value: 'hsfy2', id: 'HSFY2'},
{value: 'hsh2d', id: 'HSH2D'},
{value: 'hsp90aa1', id: 'HSP90AA1'},
{value: 'hsp90ab1', id: 'HSP90AB1'},
{value: 'hsp90b1', id: 'HSP90B1'},
{value: 'hspa12a', id: 'HSPA12A'},
{value: 'hspa12b', id: 'HSPA12B'},
{value: 'hspa13', id: 'HSPA13'},
{value: 'hspa14', id: 'HSPA14'},
{value: 'hspa1a', id: 'HSPA1A'},
{value: 'hspa1b', id: 'HSPA1B'},
{value: 'hspa1l', id: 'HSPA1L'},
{value: 'hspa2', id: 'HSPA2'},
{value: 'hspa4', id: 'HSPA4'},
{value: 'hspa4l', id: 'HSPA4L'},
{value: 'hspa5', id: 'HSPA5'},
{value: 'hspa8', id: 'HSPA8'},
{value: 'hspa9', id: 'HSPA9'},
{value: 'hspb1', id: 'HSPB1'},
{value: 'hspb11', id: 'HSPB11'},
{value: 'hspb2', id: 'HSPB2'},
{value: 'hspb3', id: 'HSPB3'},
{value: 'hspb6', id: 'HSPB6'},
{value: 'hspb7', id: 'HSPB7'},
{value: 'hspb8', id: 'HSPB8'},
{value: 'hspb9', id: 'HSPB9'},
{value: 'hspbap1', id: 'HSPBAP1'},
{value: 'hspbp1', id: 'HSPBP1'},
{value: 'hspd1', id: 'HSPD1'},
{value: 'hspe1', id: 'HSPE1'},
{value: 'hspg2', id: 'HSPG2'},
{value: 'hsph1', id: 'HSPH1'},
{value: 'htatip2', id: 'HTATIP2'},
{value: 'htatsf1', id: 'HTATSF1'},
{value: 'htr1a', id: 'HTR1A'},
{value: 'htr1b', id: 'HTR1B'},
{value: 'htr1d', id: 'HTR1D'},
{value: 'htr1f', id: 'HTR1F'},
{value: 'htr2a', id: 'HTR2A'},
{value: 'htr2b', id: 'HTR2B'},
{value: 'htr2c', id: 'HTR2C'},
{value: 'htr3a', id: 'HTR3A'},
{value: 'htr3b', id: 'HTR3B'},
{value: 'htr4', id: 'HTR4'},
{value: 'htr5a', id: 'HTR5A'},
{value: 'htr5b', id: 'HTR5B'},
{value: 'htr6', id: 'HTR6'},
{value: 'htr7', id: 'HTR7'},
{value: 'htra1', id: 'HTRA1'},
{value: 'htra2', id: 'HTRA2'},
{value: 'htra3', id: 'HTRA3'},
{value: 'htra4', id: 'HTRA4'},
{value: 'htt', id: 'HTT'},
{value: 'hunk', id: 'HUNK'},
{value: 'hus1', id: 'HUS1'},
{value: 'hus1b', id: 'HUS1B'},
{value: 'huwe1', id: 'HUWE1'},
{value: 'hvcn1', id: 'HVCN1'},
{value: 'hyal1', id: 'HYAL1'},
{value: 'hyal2', id: 'HYAL2'},
{value: 'hyal3', id: 'HYAL3'},
{value: 'hyal4', id: 'HYAL4'},
{value: 'hyal5', id: 'HYAL5'},
{value: 'hyal6', id: 'HYAL6'},
{value: 'hydin', id: 'HYDIN'},
{value: 'hyi', id: 'HYI'},
{value: 'hykk', id: 'HYKK'},
{value: 'hyls1', id: 'HYLS1'},
{value: 'hyou1', id: 'HYOU1'},
{value: 'hypk', id: 'HYPK'},
{value: 'iah1', id: 'IAH1'},
{value: 'iapp', id: 'IAPP'},
{value: 'iars1', id: 'IARS1'},
{value: 'iars2', id: 'IARS2'},
{value: 'iba57', id: 'IBA57'},
{value: 'ibsp', id: 'IBSP'},
{value: 'ibtk', id: 'IBTK'},
{value: 'ica1', id: 'ICA1'},
{value: 'ica1l', id: 'ICA1L'},
{value: 'icam1', id: 'ICAM1'},
{value: 'icam2', id: 'ICAM2'},
{value: 'icam4', id: 'ICAM4'},
{value: 'icam5', id: 'ICAM5'},
{value: 'ice1', id: 'ICE1'},
{value: 'ice2', id: 'ICE2'},
{value: 'icmt', id: 'ICMT'},
{value: 'icos', id: 'ICOS'},
{value: 'icoslg', id: 'ICOSLG'},
{value: 'id1', id: 'ID1'},
{value: 'id2', id: 'ID2'},
{value: 'id3', id: 'ID3'},
{value: 'id4', id: 'ID4'},
{value: 'ide', id: 'IDE'},
{value: 'idh1', id: 'IDH1'},
{value: 'idh2', id: 'IDH2'},
{value: 'idh3a', id: 'IDH3A'},
{value: 'idh3b', id: 'IDH3B'},
{value: 'idh3g', id: 'IDH3G'},
{value: 'idi1', id: 'IDI1'},
{value: 'idnk', id: 'IDNK'},
{value: 'ido1', id: 'IDO1'},
{value: 'ido2', id: 'IDO2'},
{value: 'ids', id: 'IDS'},
{value: 'idua', id: 'IDUA'},
{value: 'ier2', id: 'IER2'},
{value: 'ier3', id: 'IER3'},
{value: 'ier3ip1', id: 'IER3IP1'},
{value: 'ier5', id: 'IER5'},
{value: 'ier5l', id: 'IER5L'},
{value: 'iffo1', id: 'IFFO1'},
{value: 'iffo2', id: 'IFFO2'},
{value: 'ifi27', id: 'IFI27'},
{value: 'ifi27l2b', id: 'IFI27L2B'},
{value: 'ifi30', id: 'IFI30'},
{value: 'ifi35', id: 'IFI35'},
{value: 'ifi44', id: 'IFI44'},
{value: 'ifi44l', id: 'IFI44L'},
{value: 'ifi47', id: 'IFI47'},
{value: 'ifih1', id: 'IFIH1'},
{value: 'ifit1', id: 'IFIT1'},
{value: 'ifit1bl', id: 'IFIT1BL'},
{value: 'ifit2', id: 'IFIT2'},
{value: 'ifit3', id: 'IFIT3'},
{value: 'ifitm1', id: 'IFITM1'},
{value: 'ifitm10', id: 'IFITM10'},
{value: 'ifitm2', id: 'IFITM2'},
{value: 'ifitm3', id: 'IFITM3'},
{value: 'ifitm5', id: 'IFITM5'},
{value: 'ifitm6', id: 'IFITM6'},
{value: 'ifitm7', id: 'IFITM7'},
{value: 'ifna1', id: 'IFNA1'},
{value: 'ifna11', id: 'IFNA11'},
{value: 'ifna4', id: 'IFNA4'},
{value: 'ifna5', id: 'IFNA5'},
{value: 'ifnar1', id: 'IFNAR1'},
{value: 'ifnar2', id: 'IFNAR2'},
{value: 'ifnb1', id: 'IFNB1'},
{value: 'ifne', id: 'IFNE'},
{value: 'ifng', id: 'IFNG'},
{value: 'ifngr1', id: 'IFNGR1'},
{value: 'ifngr2', id: 'IFNGR2'},
{value: 'ifnk', id: 'IFNK'},
{value: 'ifnl1', id: 'IFNL1'},
{value: 'ifnl3', id: 'IFNL3'},
{value: 'ifnlr1', id: 'IFNLR1'},
{value: 'ifrd1', id: 'IFRD1'},
{value: 'ifrd2', id: 'IFRD2'},
{value: 'ift122', id: 'IFT122'},
{value: 'ift140', id: 'IFT140'},
{value: 'ift172', id: 'IFT172'},
{value: 'ift20', id: 'IFT20'},
{value: 'ift22', id: 'IFT22'},
{value: 'ift27', id: 'IFT27'},
{value: 'ift43', id: 'IFT43'},
{value: 'ift46', id: 'IFT46'},
{value: 'ift52', id: 'IFT52'},
{value: 'ift57', id: 'IFT57'},
{value: 'ift74', id: 'IFT74'},
{value: 'ift80', id: 'IFT80'},
{value: 'ift81', id: 'IFT81'},
{value: 'ift88', id: 'IFT88'},
{value: 'iftap', id: 'IFTAP'},
{value: 'igbp1', id: 'IGBP1'},
{value: 'igbp1b', id: 'IGBP1B'},
{value: 'igdcc3', id: 'IGDCC3'},
{value: 'igdcc4', id: 'IGDCC4'},
{value: 'igf1', id: 'IGF1'},
{value: 'igf1r', id: 'IGF1R'},
{value: 'igf2', id: 'IGF2'},
{value: 'igf2bp1', id: 'IGF2BP1'},
{value: 'igf2bp2', id: 'IGF2BP2'},
{value: 'igf2bp3', id: 'IGF2BP3'},
{value: 'igf2r', id: 'IGF2R'},
{value: 'igfals', id: 'IGFALS'},
{value: 'igfbp1', id: 'IGFBP1'},
{value: 'igfbp2', id: 'IGFBP2'},
{value: 'igfbp3', id: 'IGFBP3'},
{value: 'igfbp4', id: 'IGFBP4'},
{value: 'igfbp5', id: 'IGFBP5'},
{value: 'igfbp6', id: 'IGFBP6'},
{value: 'igfbp7', id: 'IGFBP7'},
{value: 'igfbpl1', id: 'IGFBPL1'},
{value: 'igflr1', id: 'IGFLR1'},
{value: 'igfn1', id: 'IGFN1'},
{value: 'igh-6', id: 'IGH-6'},
{value: 'ighg1', id: 'IGHG1'},
{value: 'ighm', id: 'IGHM'},
{value: 'ighmbp2', id: 'IGHMBP2'},
{value: 'ighv1-58', id: 'IGHV1-58'},
{value: 'igip', id: 'IGIP'},
{value: 'igll1', id: 'IGLL1'},
{value: 'iglon5', id: 'IGLON5'},
{value: 'igsf1', id: 'IGSF1'},
{value: 'igsf10', id: 'IGSF10'},
{value: 'igsf11', id: 'IGSF11'},
{value: 'igsf21', id: 'IGSF21'},
{value: 'igsf22', id: 'IGSF22'},
{value: 'igsf23', id: 'IGSF23'},
{value: 'igsf3', id: 'IGSF3'},
{value: 'igsf5', id: 'IGSF5'},
{value: 'igsf6', id: 'IGSF6'},
{value: 'igsf8', id: 'IGSF8'},
{value: 'igsf9', id: 'IGSF9'},
{value: 'igsf9b', id: 'IGSF9B'},
{value: 'igtp', id: 'IGTP'},
{value: 'ihh', id: 'IHH'},
{value: 'iho1', id: 'IHO1'},
{value: 'ik', id: 'IK'},
{value: 'ikbip', id: 'IKBIP'},
{value: 'ikbkb', id: 'IKBKB'},
{value: 'ikbke', id: 'IKBKE'},
{value: 'ikbkg', id: 'IKBKG'},
{value: 'ikzf1', id: 'IKZF1'},
{value: 'ikzf2', id: 'IKZF2'},
{value: 'ikzf3', id: 'IKZF3'},
{value: 'ikzf4', id: 'IKZF4'},
{value: 'ikzf5', id: 'IKZF5'},
{value: 'il10', id: 'IL10'},
{value: 'il10ra', id: 'IL10RA'},
{value: 'il10rb', id: 'IL10RB'},
{value: 'il11', id: 'IL11'},
{value: 'il11ra1', id: 'IL11RA1'},
{value: 'il12a', id: 'IL12A'},
{value: 'il12b', id: 'IL12B'},
{value: 'il12rb1', id: 'IL12RB1'},
{value: 'il12rb2', id: 'IL12RB2'},
{value: 'il13', id: 'IL13'},
{value: 'il13ra1', id: 'IL13RA1'},
{value: 'il13ra2', id: 'IL13RA2'},
{value: 'il15', id: 'IL15'},
{value: 'il15ra', id: 'IL15RA'},
{value: 'il16', id: 'IL16'},
{value: 'il17a', id: 'IL17A'},
{value: 'il17b', id: 'IL17B'},
{value: 'il17c', id: 'IL17C'},
{value: 'il17d', id: 'IL17D'},
{value: 'il17f', id: 'IL17F'},
{value: 'il17ra', id: 'IL17RA'},
{value: 'il17rb', id: 'IL17RB'},
{value: 'il17rc', id: 'IL17RC'},
{value: 'il17rd', id: 'IL17RD'},
{value: 'il17re', id: 'IL17RE'},
{value: 'il18', id: 'IL18'},
{value: 'il18bp', id: 'IL18BP'},
{value: 'il18r1', id: 'IL18R1'},
{value: 'il18rap', id: 'IL18RAP'},
{value: 'il19', id: 'IL19'},
{value: 'il1a', id: 'IL1A'},
{value: 'il1b', id: 'IL1B'},
{value: 'il1f10', id: 'IL1F10'},
{value: 'il1r1', id: 'IL1R1'},
{value: 'il1r2', id: 'IL1R2'},
{value: 'il1rap', id: 'IL1RAP'},
{value: 'il1rapl1', id: 'IL1RAPL1'},
{value: 'il1rapl2', id: 'IL1RAPL2'},
{value: 'il1rl1', id: 'IL1RL1'},
{value: 'il1rl2', id: 'IL1RL2'},
{value: 'il1rn', id: 'IL1RN'},
{value: 'il2', id: 'IL2'},
{value: 'il20', id: 'IL20'},
{value: 'il20ra', id: 'IL20RA'},
{value: 'il20rb', id: 'IL20RB'},
{value: 'il21', id: 'IL21'},
{value: 'il21r', id: 'IL21R'},
{value: 'il22', id: 'IL22'},
{value: 'il22ra1', id: 'IL22RA1'},
{value: 'il22ra2', id: 'IL22RA2'},
{value: 'il23a', id: 'IL23A'},
{value: 'il23r', id: 'IL23R'},
{value: 'il24', id: 'IL24'},
{value: 'il25', id: 'IL25'},
{value: 'il27', id: 'IL27'},
{value: 'il27ra', id: 'IL27RA'},
{value: 'il2ra', id: 'IL2RA'},
{value: 'il2rb', id: 'IL2RB'},
{value: 'il2rg', id: 'IL2RG'},
{value: 'il3', id: 'IL3'},
{value: 'il31', id: 'IL31'},
{value: 'il31ra', id: 'IL31RA'},
{value: 'il33', id: 'IL33'},
{value: 'il34', id: 'IL34'},
{value: 'il36a', id: 'IL36A'},
{value: 'il36b', id: 'IL36B'},
{value: 'il36g', id: 'IL36G'},
{value: 'il36rn', id: 'IL36RN'},
{value: 'il3ra', id: 'IL3RA'},
{value: 'il4', id: 'IL4'},
{value: 'il4i1', id: 'IL4I1'},
{value: 'il4r', id: 'IL4R'},
{value: 'il5', id: 'IL5'},
{value: 'il5ra', id: 'IL5RA'},
{value: 'il6', id: 'IL6'},
{value: 'il6r', id: 'IL6R'},
{value: 'il6st', id: 'IL6ST'},
{value: 'il7', id: 'IL7'},
{value: 'il7r', id: 'IL7R'},
{value: 'il9', id: 'IL9'},
{value: 'il9r', id: 'IL9R'},
{value: 'ildr1', id: 'ILDR1'},
{value: 'ildr2', id: 'ILDR2'},
{value: 'ilf2', id: 'ILF2'},
{value: 'ilf3', id: 'ILF3'},
{value: 'ilk', id: 'ILK'},
{value: 'ilkap', id: 'ILKAP'},
{value: 'ilrun', id: 'ILRUN'},
{value: 'ilvbl', id: 'ILVBL'},
{value: 'immp1l', id: 'IMMP1L'},
{value: 'immp2l', id: 'IMMP2L'},
{value: 'immt', id: 'IMMT'},
{value: 'imp3', id: 'IMP3'},
{value: 'imp4', id: 'IMP4'},
{value: 'impa1', id: 'IMPA1'},
{value: 'impa2', id: 'IMPA2'},
{value: 'impact', id: 'IMPACT'},
{value: 'impdh1', id: 'IMPDH1'},
{value: 'impdh2', id: 'IMPDH2'},
{value: 'impg1', id: 'IMPG1'},
{value: 'impg2', id: 'IMPG2'},
{value: 'ina', id: 'INA'},
{value: 'inafm1', id: 'INAFM1'},
{value: 'inafm2', id: 'INAFM2'},
{value: 'inava', id: 'INAVA'},
{value: 'inca1', id: 'INCA1'},
{value: 'incenp', id: 'INCENP'},
{value: 'inf2', id: 'INF2'},
{value: 'ing1', id: 'ING1'},
{value: 'ing2', id: 'ING2'},
{value: 'ing3', id: 'ING3'},
{value: 'ing4', id: 'ING4'},
{value: 'ing5', id: 'ING5'},
{value: 'inha', id: 'INHA'},
{value: 'inhba', id: 'INHBA'},
{value: 'inhbb', id: 'INHBB'},
{value: 'inhbc', id: 'INHBC'},
{value: 'inhbe', id: 'INHBE'},
{value: 'inhca', id: 'INHCA'},
{value: 'inip', id: 'INIP'},
{value: 'inka1', id: 'INKA1'},
{value: 'inka2', id: 'INKA2'},
{value: 'inmt', id: 'INMT'},
{value: 'ino80', id: 'INO80'},
{value: 'ino80b', id: 'INO80B'},
{value: 'ino80c', id: 'INO80C'},
{value: 'ino80d', id: 'INO80D'},
{value: 'ino80e', id: 'INO80E'},
{value: 'inpp1', id: 'INPP1'},
{value: 'inpp4a', id: 'INPP4A'},
{value: 'inpp4b', id: 'INPP4B'},
{value: 'inpp5a', id: 'INPP5A'},
{value: 'inpp5b', id: 'INPP5B'},
{value: 'inpp5d', id: 'INPP5D'},
{value: 'inpp5e', id: 'INPP5E'},
{value: 'inpp5f', id: 'INPP5F'},
{value: 'inpp5j', id: 'INPP5J'},
{value: 'inpp5k', id: 'INPP5K'},
{value: 'inppl1', id: 'INPPL1'},
{value: 'ins1', id: 'INS1'},
{value: 'ins2', id: 'INS2'},
{value: 'insc', id: 'INSC'},
{value: 'insig1', id: 'INSIG1'},
{value: 'insig2', id: 'INSIG2'},
{value: 'insl3', id: 'INSL3'},
{value: 'insl6', id: 'INSL6'},
{value: 'insm2', id: 'INSM2'},
{value: 'insr', id: 'INSR'},
{value: 'insrr', id: 'INSRR'},
{value: 'insyn1', id: 'INSYN1'},
{value: 'insyn2a', id: 'INSYN2A'},
{value: 'insyn2b', id: 'INSYN2B'},
{value: 'ints1', id: 'INTS1'},
{value: 'ints10', id: 'INTS10'},
{value: 'ints11', id: 'INTS11'},
{value: 'ints12', id: 'INTS12'},
{value: 'ints13', id: 'INTS13'},
{value: 'ints14', id: 'INTS14'},
{value: 'ints2', id: 'INTS2'},
{value: 'ints3', id: 'INTS3'},
{value: 'ints4', id: 'INTS4'},
{value: 'ints5', id: 'INTS5'},
{value: 'ints6', id: 'INTS6'},
{value: 'ints6l', id: 'INTS6L'},
{value: 'ints7', id: 'INTS7'},
{value: 'ints8', id: 'INTS8'},
{value: 'ints9', id: 'INTS9'},
{value: 'intu', id: 'INTU'},
{value: 'invs', id: 'INVS'},
{value: 'ip6k1', id: 'IP6K1'},
{value: 'ip6k2', id: 'IP6K2'},
{value: 'ip6k3', id: 'IP6K3'},
{value: 'ipcef1', id: 'IPCEF1'},
{value: 'ipmk', id: 'IPMK'},
{value: 'ipo11', id: 'IPO11'},
{value: 'ipo13', id: 'IPO13'},
{value: 'ipo4', id: 'IPO4'},
{value: 'ipo5', id: 'IPO5'},
{value: 'ipo7', id: 'IPO7'},
{value: 'ipo8', id: 'IPO8'},
{value: 'ipo9', id: 'IPO9'},
{value: 'ipp', id: 'IPP'},
{value: 'ippk', id: 'IPPK'},
{value: 'iqank1', id: 'IQANK1'},
{value: 'iqca', id: 'IQCA'},
{value: 'iqca1', id: 'IQCA1'},
{value: 'iqca1l', id: 'IQCA1L'},
{value: 'iqcb1', id: 'IQCB1'},
{value: 'iqcc', id: 'IQCC'},
{value: 'iqcd', id: 'IQCD'},
{value: 'iqce', id: 'IQCE'},
{value: 'iqcf1', id: 'IQCF1'},
{value: 'iqcf3', id: 'IQCF3'},
{value: 'iqcf5', id: 'IQCF5'},
{value: 'iqcf6', id: 'IQCF6'},
{value: 'iqcg', id: 'IQCG'},
{value: 'iqch', id: 'IQCH'},
{value: 'iqck', id: 'IQCK'},
{value: 'iqcn', id: 'IQCN'},
{value: 'iqgap1', id: 'IQGAP1'},
{value: 'iqgap2', id: 'IQGAP2'},
{value: 'iqgap3', id: 'IQGAP3'},
{value: 'iqsec1', id: 'IQSEC1'},
{value: 'iqsec2', id: 'IQSEC2'},
{value: 'iqsec3', id: 'IQSEC3'},
{value: 'iqub', id: 'IQUB'},
{value: 'irag1', id: 'IRAG1'},
{value: 'irag2', id: 'IRAG2'},
{value: 'irak1', id: 'IRAK1'},
{value: 'irak1bp1', id: 'IRAK1BP1'},
{value: 'irak2', id: 'IRAK2'},
{value: 'irak3', id: 'IRAK3'},
{value: 'irak4', id: 'IRAK4'},
{value: 'ireb2', id: 'IREB2'},
{value: 'irf1', id: 'IRF1'},
{value: 'irf2', id: 'IRF2'},
{value: 'irf2bp1', id: 'IRF2BP1'},
{value: 'irf2bp2', id: 'IRF2BP2'},
{value: 'irf2bpl', id: 'IRF2BPL'},
{value: 'irf3', id: 'IRF3'},
{value: 'irf4', id: 'IRF4'},
{value: 'irf5', id: 'IRF5'},
{value: 'irf6', id: 'IRF6'},
{value: 'irf7', id: 'IRF7'},
{value: 'irf8', id: 'IRF8'},
{value: 'irf9', id: 'IRF9'},
{value: 'irgc', id: 'IRGC'},
{value: 'irgm', id: 'IRGM'},
{value: 'irgm2', id: 'IRGM2'},
{value: 'irgq', id: 'IRGQ'},
{value: 'irs1', id: 'IRS1'},
{value: 'irs2', id: 'IRS2'},
{value: 'irs3', id: 'IRS3'},
{value: 'irs4', id: 'IRS4'},
{value: 'irx1', id: 'IRX1'},
{value: 'irx2', id: 'IRX2'},
{value: 'irx3', id: 'IRX3'},
{value: 'irx4', id: 'IRX4'},
{value: 'irx5', id: 'IRX5'},
{value: 'irx6', id: 'IRX6'},
{value: 'isca1', id: 'ISCA1'},
{value: 'isca2', id: 'ISCA2'},
{value: 'iscu', id: 'ISCU'},
{value: 'isg15', id: 'ISG15'},
{value: 'isg20', id: 'ISG20'},
{value: 'isg20l2', id: 'ISG20L2'},
{value: 'isl1', id: 'ISL1'},
{value: 'isl2', id: 'ISL2'},
{value: 'islr', id: 'ISLR'},
{value: 'islr2', id: 'ISLR2'},
{value: 'ism2', id: 'ISM2'},
{value: 'isoc1', id: 'ISOC1'},
{value: 'isoc2b', id: 'ISOC2B'},
{value: 'ist1', id: 'IST1'},
{value: 'isx', id: 'ISX'},
{value: 'isy1', id: 'ISY1'},
{value: 'isyna1', id: 'ISYNA1'},
{value: 'itch', id: 'ITCH'},
{value: 'itfg1', id: 'ITFG1'},
{value: 'itfg2', id: 'ITFG2'},
{value: 'itga1', id: 'ITGA1'},
{value: 'itga10', id: 'ITGA10'},
{value: 'itga11', id: 'ITGA11'},
{value: 'itga2', id: 'ITGA2'},
{value: 'itga2b', id: 'ITGA2B'},
{value: 'itga3', id: 'ITGA3'},
{value: 'itga4', id: 'ITGA4'},
{value: 'itga5', id: 'ITGA5'},
{value: 'itga6', id: 'ITGA6'},
{value: 'itga7', id: 'ITGA7'},
{value: 'itga8', id: 'ITGA8'},
{value: 'itga9', id: 'ITGA9'},
{value: 'itgad', id: 'ITGAD'},
{value: 'itgae', id: 'ITGAE'},
{value: 'itgal', id: 'ITGAL'},
{value: 'itgam', id: 'ITGAM'},
{value: 'itgav', id: 'ITGAV'},
{value: 'itgax', id: 'ITGAX'},
{value: 'itgb1', id: 'ITGB1'},
{value: 'itgb1bp1', id: 'ITGB1BP1'},
{value: 'itgb1bp2', id: 'ITGB1BP2'},
{value: 'itgb2', id: 'ITGB2'},
{value: 'itgb3', id: 'ITGB3'},
{value: 'itgb3bp', id: 'ITGB3BP'},
{value: 'itgb4', id: 'ITGB4'},
{value: 'itgb5', id: 'ITGB5'},
{value: 'itgb6', id: 'ITGB6'},
{value: 'itgb7', id: 'ITGB7'},
{value: 'itgb8', id: 'ITGB8'},
{value: 'itgbl1', id: 'ITGBL1'},
{value: 'itih1', id: 'ITIH1'},
{value: 'itih2', id: 'ITIH2'},
{value: 'itih3', id: 'ITIH3'},
{value: 'itih4', id: 'ITIH4'},
{value: 'itih5', id: 'ITIH5'},
{value: 'itih6', id: 'ITIH6'},
{value: 'itk', id: 'ITK'},
{value: 'itln1', id: 'ITLN1'},
{value: 'itm2a', id: 'ITM2A'},
{value: 'itm2b', id: 'ITM2B'},
{value: 'itm2c', id: 'ITM2C'},
{value: 'itpa', id: 'ITPA'},
{value: 'itpk1', id: 'ITPK1'},
{value: 'itpka', id: 'ITPKA'},
{value: 'itpkb', id: 'ITPKB'},
{value: 'itpkc', id: 'ITPKC'},
{value: 'itpr1', id: 'ITPR1'},
{value: 'itpr2', id: 'ITPR2'},
{value: 'itpr3', id: 'ITPR3'},
{value: 'itprid1', id: 'ITPRID1'},
{value: 'itprid2', id: 'ITPRID2'},
{value: 'itprip', id: 'ITPRIP'},
{value: 'itpripl1', id: 'ITPRIPL1'},
{value: 'itpripl2', id: 'ITPRIPL2'},
{value: 'itsn1', id: 'ITSN1'},
{value: 'itsn2', id: 'ITSN2'},
{value: 'ivd', id: 'IVD'},
{value: 'ivl', id: 'IVL'},
{value: 'ivns1abp', id: 'IVNS1ABP'},
{value: 'iws1', id: 'IWS1'},
{value: 'iyd', id: 'IYD'},
{value: 'izumo1', id: 'IZUMO1'},
{value: 'izumo1r', id: 'IZUMO1R'},
{value: 'izumo2', id: 'IZUMO2'},
{value: 'izumo3', id: 'IZUMO3'},
{value: 'izumo4', id: 'IZUMO4'},
{value: 'jade1', id: 'JADE1'},
{value: 'jade2', id: 'JADE2'},
{value: 'jade3', id: 'JADE3'},
{value: 'jag1', id: 'JAG1'},
{value: 'jag2', id: 'JAG2'},
{value: 'jagn1', id: 'JAGN1'},
{value: 'jak1', id: 'JAK1'},
{value: 'jak2', id: 'JAK2'},
{value: 'jak3', id: 'JAK3'},
{value: 'jakmip1', id: 'JAKMIP1'},
{value: 'jakmip2', id: 'JAKMIP2'},
{value: 'jakmip3', id: 'JAKMIP3'},
{value: 'jam2', id: 'JAM2'},
{value: 'jam3', id: 'JAM3'},
{value: 'jaml', id: 'JAML'},
{value: 'jarid2', id: 'JARID2'},
{value: 'jazf1', id: 'JAZF1'},
{value: 'jcad', id: 'JCAD'},
{value: 'jchain', id: 'JCHAIN'},
{value: 'jdp2', id: 'JDP2'},
{value: 'jhy', id: 'JHY'},
{value: 'jkamp', id: 'JKAMP'},
{value: 'jmjd1c', id: 'JMJD1C'},
{value: 'jmjd4', id: 'JMJD4'},
{value: 'jmjd6', id: 'JMJD6'},
{value: 'jmjd7', id: 'JMJD7'},
{value: 'jmjd8', id: 'JMJD8'},
{value: 'jmy', id: 'JMY'},
{value: 'josd1', id: 'JOSD1'},
{value: 'josd2', id: 'JOSD2'},
{value: 'jph1', id: 'JPH1'},
{value: 'jph2', id: 'JPH2'},
{value: 'jph3', id: 'JPH3'},
{value: 'jph4', id: 'JPH4'},
{value: 'jpt1', id: 'JPT1'},
{value: 'jpt2', id: 'JPT2'},
{value: 'jrk', id: 'JRK'},
{value: 'jrkl', id: 'JRKL'},
{value: 'jsrp1', id: 'JSRP1'},
{value: 'jtb', id: 'JTB'},
{value: 'jun', id: 'JUN'},
{value: 'junb', id: 'JUNB'},
{value: 'jund', id: 'JUND'},
{value: 'jup', id: 'JUP'},
{value: 'ka11', id: 'KA11'},
{value: 'kalrn', id: 'KALRN'},
{value: 'kank1', id: 'KANK1'},
{value: 'kank2', id: 'KANK2'},
{value: 'kank3', id: 'KANK3'},
{value: 'kank4', id: 'KANK4'},
{value: 'kansl1', id: 'KANSL1'},
{value: 'kansl1l', id: 'KANSL1L'},
{value: 'kansl2', id: 'KANSL2'},
{value: 'kansl3', id: 'KANSL3'},
{value: 'kantr', id: 'KANTR'},
{value: 'kap', id: 'KAP'},
{value: 'kars1', id: 'KARS1'},
{value: 'kash5', id: 'KASH5'},
{value: 'kat14', id: 'KAT14'},
{value: 'kat2a', id: 'KAT2A'},
{value: 'kat2b', id: 'KAT2B'},
{value: 'kat5', id: 'KAT5'},
{value: 'kat6a', id: 'KAT6A'},
{value: 'kat6b', id: 'KAT6B'},
{value: 'kat7', id: 'KAT7'},
{value: 'kat8', id: 'KAT8'},
{value: 'katna1', id: 'KATNA1'},
{value: 'katnal1', id: 'KATNAL1'},
{value: 'katnal2', id: 'KATNAL2'},
{value: 'katnb1', id: 'KATNB1'},
{value: 'katnbl1', id: 'KATNBL1'},
{value: 'katnip', id: 'KATNIP'},
{value: 'kazald1', id: 'KAZALD1'},
{value: 'kazn', id: 'KAZN'},
{value: 'kb15', id: 'KB15'},
{value: 'kb23', id: 'KB23'},
{value: 'kbtbd11', id: 'KBTBD11'},
{value: 'kbtbd12', id: 'KBTBD12'},
{value: 'kbtbd2', id: 'KBTBD2'},
{value: 'kbtbd3', id: 'KBTBD3'},
{value: 'kbtbd4', id: 'KBTBD4'},
{value: 'kbtbd6', id: 'KBTBD6'},
{value: 'kbtbd7', id: 'KBTBD7'},
{value: 'kbtbd8', id: 'KBTBD8'},
{value: 'kcmf1', id: 'KCMF1'},
{value: 'kcna1', id: 'KCNA1'},
{value: 'kcna10', id: 'KCNA10'},
{value: 'kcna2', id: 'KCNA2'},
{value: 'kcna3', id: 'KCNA3'},
{value: 'kcna4', id: 'KCNA4'},
{value: 'kcna5', id: 'KCNA5'},
{value: 'kcna6', id: 'KCNA6'},
{value: 'kcna7', id: 'KCNA7'},
{value: 'kcnab1', id: 'KCNAB1'},
{value: 'kcnab2', id: 'KCNAB2'},
{value: 'kcnab3', id: 'KCNAB3'},
{value: 'kcnb1', id: 'KCNB1'},
{value: 'kcnb2', id: 'KCNB2'},
{value: 'kcnc1', id: 'KCNC1'},
{value: 'kcnc2', id: 'KCNC2'},
{value: 'kcnc3', id: 'KCNC3'},
{value: 'kcnc4', id: 'KCNC4'},
{value: 'kcnd1', id: 'KCND1'},
{value: 'kcnd2', id: 'KCND2'},
{value: 'kcnd3', id: 'KCND3'},
{value: 'kcne1', id: 'KCNE1'},
{value: 'kcne2', id: 'KCNE2'},
{value: 'kcne3', id: 'KCNE3'},
{value: 'kcne4', id: 'KCNE4'},
{value: 'kcne5', id: 'KCNE5'},
{value: 'kcnf1', id: 'KCNF1'},
{value: 'kcng1', id: 'KCNG1'},
{value: 'kcng2', id: 'KCNG2'},
{value: 'kcng3', id: 'KCNG3'},
{value: 'kcng4', id: 'KCNG4'},
{value: 'kcnh1', id: 'KCNH1'},
{value: 'kcnh2', id: 'KCNH2'},
{value: 'kcnh3', id: 'KCNH3'},
{value: 'kcnh4', id: 'KCNH4'},
{value: 'kcnh5', id: 'KCNH5'},
{value: 'kcnh6', id: 'KCNH6'},
{value: 'kcnh7', id: 'KCNH7'},
{value: 'kcnh8', id: 'KCNH8'},
{value: 'kcnip1', id: 'KCNIP1'},
{value: 'kcnip2', id: 'KCNIP2'},
{value: 'kcnip3', id: 'KCNIP3'},
{value: 'kcnip4', id: 'KCNIP4'},
{value: 'kcnj1', id: 'KCNJ1'},
{value: 'kcnj10', id: 'KCNJ10'},
{value: 'kcnj11', id: 'KCNJ11'},
{value: 'kcnj12', id: 'KCNJ12'},
{value: 'kcnj13', id: 'KCNJ13'},
{value: 'kcnj14', id: 'KCNJ14'},
{value: 'kcnj15', id: 'KCNJ15'},
{value: 'kcnj16', id: 'KCNJ16'},
{value: 'kcnj2', id: 'KCNJ2'},
{value: 'kcnj3', id: 'KCNJ3'},
{value: 'kcnj4', id: 'KCNJ4'},
{value: 'kcnj5', id: 'KCNJ5'},
{value: 'kcnj6', id: 'KCNJ6'},
{value: 'kcnj8', id: 'KCNJ8'},
{value: 'kcnj9', id: 'KCNJ9'},
{value: 'kcnk1', id: 'KCNK1'},
{value: 'kcnk10', id: 'KCNK10'},
{value: 'kcnk12', id: 'KCNK12'},
{value: 'kcnk13', id: 'KCNK13'},
{value: 'kcnk15', id: 'KCNK15'},
{value: 'kcnk16', id: 'KCNK16'},
{value: 'kcnk18', id: 'KCNK18'},
{value: 'kcnk2', id: 'KCNK2'},
{value: 'kcnk3', id: 'KCNK3'},
{value: 'kcnk4', id: 'KCNK4'},
{value: 'kcnk5', id: 'KCNK5'},
{value: 'kcnk6', id: 'KCNK6'},
{value: 'kcnk7', id: 'KCNK7'},
{value: 'kcnk9', id: 'KCNK9'},
{value: 'kcnma1', id: 'KCNMA1'},
{value: 'kcnmb1', id: 'KCNMB1'},
{value: 'kcnmb2', id: 'KCNMB2'},
{value: 'kcnmb3', id: 'KCNMB3'},
{value: 'kcnmb4', id: 'KCNMB4'},
{value: 'kcnn1', id: 'KCNN1'},
{value: 'kcnn2', id: 'KCNN2'},
{value: 'kcnn3', id: 'KCNN3'},
{value: 'kcnn4', id: 'KCNN4'},
{value: 'kcnq1', id: 'KCNQ1'},
{value: 'kcnq2', id: 'KCNQ2'},
{value: 'kcnq3', id: 'KCNQ3'},
{value: 'kcnq4', id: 'KCNQ4'},
{value: 'kcnq5', id: 'KCNQ5'},
{value: 'kcnrg', id: 'KCNRG'},
{value: 'kcns1', id: 'KCNS1'},
{value: 'kcns2', id: 'KCNS2'},
{value: 'kcns3', id: 'KCNS3'},
{value: 'kcnt1', id: 'KCNT1'},
{value: 'kcnt2', id: 'KCNT2'},
{value: 'kcnu1', id: 'KCNU1'},
{value: 'kcnv1', id: 'KCNV1'},
{value: 'kcnv2', id: 'KCNV2'},
{value: 'kcp', id: 'KCP'},
{value: 'kctd1', id: 'KCTD1'},
{value: 'kctd10', id: 'KCTD10'},
{value: 'kctd11', id: 'KCTD11'},
{value: 'kctd12', id: 'KCTD12'},
{value: 'kctd13', id: 'KCTD13'},
{value: 'kctd14', id: 'KCTD14'},
{value: 'kctd15', id: 'KCTD15'},
{value: 'kctd16', id: 'KCTD16'},
{value: 'kctd17', id: 'KCTD17'},
{value: 'kctd18', id: 'KCTD18'},
{value: 'kctd19', id: 'KCTD19'},
{value: 'kctd2', id: 'KCTD2'},
{value: 'kctd20', id: 'KCTD20'},
{value: 'kctd21', id: 'KCTD21'},
{value: 'kctd3', id: 'KCTD3'},
{value: 'kctd4', id: 'KCTD4'},
{value: 'kctd5', id: 'KCTD5'},
{value: 'kctd6', id: 'KCTD6'},
{value: 'kctd7', id: 'KCTD7'},
{value: 'kctd8', id: 'KCTD8'},
{value: 'kctd9', id: 'KCTD9'},
{value: 'kdelr1', id: 'KDELR1'},
{value: 'kdelr2', id: 'KDELR2'},
{value: 'kdelr3', id: 'KDELR3'},
{value: 'kdf1', id: 'KDF1'},
{value: 'kdm1a', id: 'KDM1A'},
{value: 'kdm1b', id: 'KDM1B'},
{value: 'kdm2a', id: 'KDM2A'},
{value: 'kdm2b', id: 'KDM2B'},
{value: 'kdm3a', id: 'KDM3A'},
{value: 'kdm3b', id: 'KDM3B'},
{value: 'kdm4a', id: 'KDM4A'},
{value: 'kdm4b', id: 'KDM4B'},
{value: 'kdm4c', id: 'KDM4C'},
{value: 'kdm4d', id: 'KDM4D'},
{value: 'kdm5a', id: 'KDM5A'},
{value: 'kdm5b', id: 'KDM5B'},
{value: 'kdm5c', id: 'KDM5C'},
{value: 'kdm5d', id: 'KDM5D'},
{value: 'kdm6a', id: 'KDM6A'},
{value: 'kdm6b', id: 'KDM6B'},
{value: 'kdm7a', id: 'KDM7A'},
{value: 'kdm8', id: 'KDM8'},
{value: 'kdr', id: 'KDR'},
{value: 'kdsr', id: 'KDSR'},
{value: 'keap1', id: 'KEAP1'},
{value: 'kel', id: 'KEL'},
{value: 'kera', id: 'KERA'},
{value: 'khdc1', id: 'KHDC1'},
{value: 'khdc3', id: 'KHDC3'},
{value: 'khdc4', id: 'KHDC4'},
{value: 'khdrbs1', id: 'KHDRBS1'},
{value: 'khdrbs2', id: 'KHDRBS2'},
{value: 'khdrbs3', id: 'KHDRBS3'},
{value: 'khk', id: 'KHK'},
{value: 'khnyn', id: 'KHNYN'},
{value: 'khsrp', id: 'KHSRP'},
{value: 'kiaa0408l', id: 'KIAA0408L'},
{value: 'kics2', id: 'KICS2'},
{value: 'kidins220', id: 'KIDINS220'},
{value: 'kif11', id: 'KIF11'},
{value: 'kif12', id: 'KIF12'},
{value: 'kif13a', id: 'KIF13A'},
{value: 'kif13b', id: 'KIF13B'},
{value: 'kif14', id: 'KIF14'},
{value: 'kif15', id: 'KIF15'},
{value: 'kif16b', id: 'KIF16B'},
{value: 'kif17', id: 'KIF17'},
{value: 'kif18a', id: 'KIF18A'},
{value: 'kif18b', id: 'KIF18B'},
{value: 'kif19', id: 'KIF19'},
{value: 'kif1a', id: 'KIF1A'},
{value: 'kif1b', id: 'KIF1B'},
{value: 'kif1c', id: 'KIF1C'},
{value: 'kif20a', id: 'KIF20A'},
{value: 'kif20b', id: 'KIF20B'},
{value: 'kif21a', id: 'KIF21A'},
{value: 'kif21b', id: 'KIF21B'},
{value: 'kif22', id: 'KIF22'},
{value: 'kif23', id: 'KIF23'},
{value: 'kif24', id: 'KIF24'},
{value: 'kif26a', id: 'KIF26A'},
{value: 'kif26b', id: 'KIF26B'},
{value: 'kif27', id: 'KIF27'},
{value: 'kif28p', id: 'KIF28P'},
{value: 'kif2a', id: 'KIF2A'},
{value: 'kif2b', id: 'KIF2B'},
{value: 'kif2c', id: 'KIF2C'},
{value: 'kif3a', id: 'KIF3A'},
{value: 'kif3b', id: 'KIF3B'},
{value: 'kif3c', id: 'KIF3C'},
{value: 'kif4a', id: 'KIF4A'},
{value: 'kif4b', id: 'KIF4B'},
{value: 'kif5a', id: 'KIF5A'},
{value: 'kif5b', id: 'KIF5B'},
{value: 'kif5c', id: 'KIF5C'},
{value: 'kif6', id: 'KIF6'},
{value: 'kif7', id: 'KIF7'},
{value: 'kif9', id: 'KIF9'},
{value: 'kifap3', id: 'KIFAP3'},
{value: 'kifbp', id: 'KIFBP'},
{value: 'kifc1', id: 'KIFC1'},
{value: 'kifc2', id: 'KIFC2'},
{value: 'kifc3', id: 'KIFC3'},
{value: 'kin', id: 'KIN'},
{value: 'kir3dl1', id: 'KIR3DL1'},
{value: 'kirrel1', id: 'KIRREL1'},
{value: 'kirrel2', id: 'KIRREL2'},
{value: 'kirrel3', id: 'KIRREL3'},
{value: 'kiss1', id: 'KISS1'},
{value: 'kiss1r', id: 'KISS1R'},
{value: 'kit', id: 'KIT'},
{value: 'kitlg', id: 'KITLG'},
{value: 'kiz', id: 'KIZ'},
{value: 'kl', id: 'KL'},
{value: 'klc1', id: 'KLC1'},
{value: 'klc2', id: 'KLC2'},
{value: 'klc3', id: 'KLC3'},
{value: 'klc4', id: 'KLC4'},
{value: 'klf1', id: 'KLF1'},
{value: 'klf10', id: 'KLF10'},
{value: 'klf11', id: 'KLF11'},
{value: 'klf12', id: 'KLF12'},
{value: 'klf13', id: 'KLF13'},
{value: 'klf14', id: 'KLF14'},
{value: 'klf15', id: 'KLF15'},
{value: 'klf16', id: 'KLF16'},
{value: 'klf17', id: 'KLF17'},
{value: 'klf2', id: 'KLF2'},
{value: 'klf3', id: 'KLF3'},
{value: 'klf4', id: 'KLF4'},
{value: 'klf5', id: 'KLF5'},
{value: 'klf6', id: 'KLF6'},
{value: 'klf7', id: 'KLF7'},
{value: 'klf8', id: 'KLF8'},
{value: 'klf9', id: 'KLF9'},
{value: 'klhdc1', id: 'KLHDC1'},
{value: 'klhdc10', id: 'KLHDC10'},
{value: 'klhdc2', id: 'KLHDC2'},
{value: 'klhdc3', id: 'KLHDC3'},
{value: 'klhdc4', id: 'KLHDC4'},
{value: 'klhdc7a', id: 'KLHDC7A'},
{value: 'klhdc7b', id: 'KLHDC7B'},
{value: 'klhdc8a', id: 'KLHDC8A'},
{value: 'klhdc8b', id: 'KLHDC8B'},
{value: 'klhdc9', id: 'KLHDC9'},
{value: 'klhl1', id: 'KLHL1'},
{value: 'klhl10', id: 'KLHL10'},
{value: 'klhl11', id: 'KLHL11'},
{value: 'klhl12', id: 'KLHL12'},
{value: 'klhl13', id: 'KLHL13'},
{value: 'klhl14', id: 'KLHL14'},
{value: 'klhl15', id: 'KLHL15'},
{value: 'klhl17', id: 'KLHL17'},
{value: 'klhl18', id: 'KLHL18'},
{value: 'klhl2', id: 'KLHL2'},
{value: 'klhl20', id: 'KLHL20'},
{value: 'klhl21', id: 'KLHL21'},
{value: 'klhl22', id: 'KLHL22'},
{value: 'klhl23', id: 'KLHL23'},
{value: 'klhl24', id: 'KLHL24'},
{value: 'klhl25', id: 'KLHL25'},
{value: 'klhl26', id: 'KLHL26'},
{value: 'klhl28', id: 'KLHL28'},
{value: 'klhl29', id: 'KLHL29'},
{value: 'klhl3', id: 'KLHL3'},
{value: 'klhl30', id: 'KLHL30'},
{value: 'klhl31', id: 'KLHL31'},
{value: 'klhl32', id: 'KLHL32'},
{value: 'klhl33', id: 'KLHL33'},
{value: 'klhl34', id: 'KLHL34'},
{value: 'klhl35', id: 'KLHL35'},
{value: 'klhl36', id: 'KLHL36'},
{value: 'klhl38', id: 'KLHL38'},
{value: 'klhl4', id: 'KLHL4'},
{value: 'klhl40', id: 'KLHL40'},
{value: 'klhl41', id: 'KLHL41'},
{value: 'klhl42', id: 'KLHL42'},
{value: 'klhl5', id: 'KLHL5'},
{value: 'klhl6', id: 'KLHL6'},
{value: 'klhl7', id: 'KLHL7'},
{value: 'klhl8', id: 'KLHL8'},
{value: 'klhl9', id: 'KLHL9'},
{value: 'klk1', id: 'KLK1'},
{value: 'klk10', id: 'KLK10'},
{value: 'klk11', id: 'KLK11'},
{value: 'klk12', id: 'KLK12'},
{value: 'klk13', id: 'KLK13'},
{value: 'klk14', id: 'KLK14'},
{value: 'klk15', id: 'KLK15'},
{value: 'klk1b3', id: 'KLK1B3'},
{value: 'klk1c10', id: 'KLK1C10'},
{value: 'klk1c12', id: 'KLK1C12'},
{value: 'klk1c2', id: 'KLK1C2'},
{value: 'klk1c3', id: 'KLK1C3'},
{value: 'klk1c4', id: 'KLK1C4'},
{value: 'klk1c6', id: 'KLK1C6'},
{value: 'klk1c8', id: 'KLK1C8'},
{value: 'klk1c9', id: 'KLK1C9'},
{value: 'klk4', id: 'KLK4'},
{value: 'klk5', id: 'KLK5'},
{value: 'klk6', id: 'KLK6'},
{value: 'klk7', id: 'KLK7'},
{value: 'klk8', id: 'KLK8'},
{value: 'klk9', id: 'KLK9'},
{value: 'klkb1', id: 'KLKB1'},
{value: 'klra1', id: 'KLRA1'},
{value: 'klra2', id: 'KLRA2'},
{value: 'klra22', id: 'KLRA22'},
{value: 'klra5', id: 'KLRA5'},
{value: 'klrb1', id: 'KLRB1'},
{value: 'klrb1a', id: 'KLRB1A'},
{value: 'klrb1b', id: 'KLRB1B'},
{value: 'klrb1c', id: 'KLRB1C'},
{value: 'klrc1', id: 'KLRC1'},
{value: 'klrc2', id: 'KLRC2'},
{value: 'klrc3', id: 'KLRC3'},
{value: 'klrd1', id: 'KLRD1'},
{value: 'klre1', id: 'KLRE1'},
{value: 'klrg1', id: 'KLRG1'},
{value: 'klrg2', id: 'KLRG2'},
{value: 'klrh1', id: 'KLRH1'},
{value: 'klri1', id: 'KLRI1'},
{value: 'klri2', id: 'KLRI2'},
{value: 'klrk1', id: 'KLRK1'},
{value: 'kmo', id: 'KMO'},
{value: 'kmt2a', id: 'KMT2A'},
{value: 'kmt2b', id: 'KMT2B'},
{value: 'kmt2c', id: 'KMT2C'},
{value: 'kmt2d', id: 'KMT2D'},
{value: 'kmt2e', id: 'KMT2E'},
{value: 'kmt5a', id: 'KMT5A'},
{value: 'kmt5b', id: 'KMT5B'},
{value: 'kmt5c', id: 'KMT5C'},
{value: 'kncn', id: 'KNCN'},
{value: 'kndc1', id: 'KNDC1'},
{value: 'kng1', id: 'KNG1'},
{value: 'kng2', id: 'KNG2'},
{value: 'kng2l1', id: 'KNG2L1'},
{value: 'knl1', id: 'KNL1'},
{value: 'knop1', id: 'KNOP1'},
{value: 'knstrn', id: 'KNSTRN'},
{value: 'kntc1', id: 'KNTC1'},
{value: 'kpna1', id: 'KPNA1'},
{value: 'kpna2', id: 'KPNA2'},
{value: 'kpna3', id: 'KPNA3'},
{value: 'kpna4', id: 'KPNA4'},
{value: 'kpna5', id: 'KPNA5'},
{value: 'kpna6', id: 'KPNA6'},
{value: 'kpna7', id: 'KPNA7'},
{value: 'kpnb1', id: 'KPNB1'},
{value: 'kprp', id: 'KPRP'},
{value: 'kptn', id: 'KPTN'},
{value: 'kras', id: 'KRAS'},
{value: 'krba1', id: 'KRBA1'},
{value: 'krcc1', id: 'KRCC1'},
{value: 'kremen1', id: 'KREMEN1'},
{value: 'kremen2', id: 'KREMEN2'},
{value: 'kri1', id: 'KRI1'},
{value: 'krit1', id: 'KRIT1'},
{value: 'krr1', id: 'KRR1'},
{value: 'krt1', id: 'KRT1'},
{value: 'krt10', id: 'KRT10'},
{value: 'krt12', id: 'KRT12'},
{value: 'krt13', id: 'KRT13'},
{value: 'krt14', id: 'KRT14'},
{value: 'krt15', id: 'KRT15'},
{value: 'krt16', id: 'KRT16'},
{value: 'krt17', id: 'KRT17'},
{value: 'krt18', id: 'KRT18'},
{value: 'krt19', id: 'KRT19'},
{value: 'krt2', id: 'KRT2'},
{value: 'krt20', id: 'KRT20'},
{value: 'krt222', id: 'KRT222'},
{value: 'krt23', id: 'KRT23'},
{value: 'krt24', id: 'KRT24'},
{value: 'krt25', id: 'KRT25'},
{value: 'krt26', id: 'KRT26'},
{value: 'krt27', id: 'KRT27'},
{value: 'krt28', id: 'KRT28'},
{value: 'krt31', id: 'KRT31'},
{value: 'krt32', id: 'KRT32'},
{value: 'krt33a', id: 'KRT33A'},
{value: 'krt33b', id: 'KRT33B'},
{value: 'krt34', id: 'KRT34'},
{value: 'krt35', id: 'KRT35'},
{value: 'krt36', id: 'KRT36'},
{value: 'krt39', id: 'KRT39'},
{value: 'krt4', id: 'KRT4'},
{value: 'krt40', id: 'KRT40'},
{value: 'krt42', id: 'KRT42'},
{value: 'krt5', id: 'KRT5'},
{value: 'krt7', id: 'KRT7'},
{value: 'krt71', id: 'KRT71'},
{value: 'krt72', id: 'KRT72'},
{value: 'krt73', id: 'KRT73'},
{value: 'krt75', id: 'KRT75'},
{value: 'krt76', id: 'KRT76'},
{value: 'krt77', id: 'KRT77'},
{value: 'krt78', id: 'KRT78'},
{value: 'krt79', id: 'KRT79'},
{value: 'krt8', id: 'KRT8'},
{value: 'krt80', id: 'KRT80'},
{value: 'krt81', id: 'KRT81'},
{value: 'krt82', id: 'KRT82'},
{value: 'krt83', id: 'KRT83'},
{value: 'krt84', id: 'KRT84'},
{value: 'krt85', id: 'KRT85'},
{value: 'krt86', id: 'KRT86'},
{value: 'krt9', id: 'KRT9'},
{value: 'krtap1-1', id: 'KRTAP1-1'},
{value: 'krtap1-3', id: 'KRTAP1-3'},
{value: 'krtap1-5', id: 'KRTAP1-5'},
{value: 'krtap11-1', id: 'KRTAP11-1'},
{value: 'krtap12-2', id: 'KRTAP12-2'},
{value: 'krtap13-1', id: 'KRTAP13-1'},
{value: 'krtap13-2', id: 'KRTAP13-2'},
{value: 'krtap14', id: 'KRTAP14'},
{value: 'krtap14l', id: 'KRTAP14L'},
{value: 'krtap15-1', id: 'KRTAP15-1'},
{value: 'krtap16-1', id: 'KRTAP16-1'},
{value: 'krtap16-5', id: 'KRTAP16-5'},
{value: 'krtap17-1', id: 'KRTAP17-1'},
{value: 'krtap19-5', id: 'KRTAP19-5'},
{value: 'krtap2-1', id: 'KRTAP2-1'},
{value: 'krtap2-4', id: 'KRTAP2-4'},
{value: 'krtap2-4l', id: 'KRTAP2-4L'},
{value: 'krtap20-1', id: 'KRTAP20-1'},
{value: 'krtap22-2', id: 'KRTAP22-2'},
{value: 'krtap24-1', id: 'KRTAP24-1'},
{value: 'krtap26-1', id: 'KRTAP26-1'},
{value: 'krtap27-1', id: 'KRTAP27-1'},
{value: 'krtap3-1', id: 'KRTAP3-1'},
{value: 'krtap3-2', id: 'KRTAP3-2'},
{value: 'krtap3-3', id: 'KRTAP3-3'},
{value: 'krtap31-1', id: 'KRTAP31-1'},
{value: 'krtap4-3', id: 'KRTAP4-3'},
{value: 'krtap4-9', id: 'KRTAP4-9'},
{value: 'krtap7-1', id: 'KRTAP7-1'},
{value: 'krtap8-1', id: 'KRTAP8-1'},
{value: 'krtap9-7', id: 'KRTAP9-7'},
{value: 'krtcap2', id: 'KRTCAP2'},
{value: 'krtcap3', id: 'KRTCAP3'},
{value: 'krtdap', id: 'KRTDAP'},
{value: 'ksr1', id: 'KSR1'},
{value: 'ksr2', id: 'KSR2'},
{value: 'kti12', id: 'KTI12'},
{value: 'ktn1', id: 'KTN1'},
{value: 'kxd1', id: 'KXD1'},
{value: 'ky', id: 'KY'},
{value: 'kyat1', id: 'KYAT1'},
{value: 'kyat3', id: 'KYAT3'},
{value: 'kynu', id: 'KYNU'},
{value: 'l1cam', id: 'L1CAM'},
{value: 'l1td1', id: 'L1TD1'},
{value: 'l2hgdh', id: 'L2HGDH'},
{value: 'l3hypdh', id: 'L3HYPDH'},
{value: 'l3mbtl1', id: 'L3MBTL1'},
{value: 'l3mbtl2', id: 'L3MBTL2'},
{value: 'l3mbtl3', id: 'L3MBTL3'},
{value: 'l3mbtl4', id: 'L3MBTL4'},
{value: 'lacc1', id: 'LACC1'},
{value: 'lactb', id: 'LACTB'},
{value: 'lactb2', id: 'LACTB2'},
{value: 'lad1', id: 'LAD1'},
{value: 'lag3', id: 'LAG3'},
{value: 'lage3', id: 'LAGE3'},
{value: 'lair1', id: 'LAIR1'},
{value: 'lalba', id: 'LALBA'},
{value: 'lama1', id: 'LAMA1'},
{value: 'lama2', id: 'LAMA2'},
{value: 'lama3', id: 'LAMA3'},
{value: 'lama4', id: 'LAMA4'},
{value: 'lama5', id: 'LAMA5'},
{value: 'lamb1', id: 'LAMB1'},
{value: 'lamb2', id: 'LAMB2'},
{value: 'lamb3', id: 'LAMB3'},
{value: 'lamc1', id: 'LAMC1'},
{value: 'lamc2', id: 'LAMC2'},
{value: 'lamc3', id: 'LAMC3'},
{value: 'lamp1', id: 'LAMP1'},
{value: 'lamp2', id: 'LAMP2'},
{value: 'lamp3', id: 'LAMP3'},
{value: 'lamp5', id: 'LAMP5'},
{value: 'lamtor1', id: 'LAMTOR1'},
{value: 'lamtor2', id: 'LAMTOR2'},
{value: 'lamtor3', id: 'LAMTOR3'},
{value: 'lamtor4', id: 'LAMTOR4'},
{value: 'lamtor5', id: 'LAMTOR5'},
{value: 'lancl1', id: 'LANCL1'},
{value: 'lancl2', id: 'LANCL2'},
{value: 'lancl3', id: 'LANCL3'},
{value: 'lao1', id: 'LAO1'},
{value: 'lap3', id: 'LAP3'},
{value: 'laptm4a', id: 'LAPTM4A'},
{value: 'laptm4b', id: 'LAPTM4B'},
{value: 'laptm5', id: 'LAPTM5'},
{value: 'large1', id: 'LARGE1'},
{value: 'large2', id: 'LARGE2'},
{value: 'larp1', id: 'LARP1'},
{value: 'larp1b', id: 'LARP1B'},
{value: 'larp4', id: 'LARP4'},
{value: 'larp4b', id: 'LARP4B'},
{value: 'larp6', id: 'LARP6'},
{value: 'larp7', id: 'LARP7'},
{value: 'lars1', id: 'LARS1'},
{value: 'lars2', id: 'LARS2'},
{value: 'las1l', id: 'LAS1L'},
{value: 'lasp1', id: 'LASP1'},
{value: 'lat', id: 'LAT'},
{value: 'lat2', id: 'LAT2'},
{value: 'lats1', id: 'LATS1'},
{value: 'lats2', id: 'LATS2'},
{value: 'lax1', id: 'LAX1'},
{value: 'layn', id: 'LAYN'},
{value: 'lbh', id: 'LBH'},
{value: 'lbhd2', id: 'LBHD2'},
{value: 'lbp', id: 'LBP'},
{value: 'lbr', id: 'LBR'},
{value: 'lbx1', id: 'LBX1'},
{value: 'lbx2', id: 'LBX2'},
{value: 'lca5', id: 'LCA5'},
{value: 'lca5l', id: 'LCA5L'},
{value: 'lcat', id: 'LCAT'},
{value: 'lce1f', id: 'LCE1F'},
{value: 'lce1l', id: 'LCE1L'},
{value: 'lce1m', id: 'LCE1M'},
{value: 'lce3e', id: 'LCE3E'},
{value: 'lce6a', id: 'LCE6A'},
{value: 'lck', id: 'LCK'},
{value: 'lclat1', id: 'LCLAT1'},
{value: 'lcmt1', id: 'LCMT1'},
{value: 'lcmt2', id: 'LCMT2'},
{value: 'lcn1', id: 'LCN1'},
{value: 'lcn10', id: 'LCN10'},
{value: 'lcn12', id: 'LCN12'},
{value: 'lcn2', id: 'LCN2'},
{value: 'lcn3', id: 'LCN3'},
{value: 'lcn4', id: 'LCN4'},
{value: 'lcn5', id: 'LCN5'},
{value: 'lcn6', id: 'LCN6'},
{value: 'lcn8', id: 'LCN8'},
{value: 'lcn9', id: 'LCN9'},
{value: 'lcor', id: 'LCOR'},
{value: 'lcorl', id: 'LCORL'},
{value: 'lcp1', id: 'LCP1'},
{value: 'lcp2', id: 'LCP2'},
{value: 'lct', id: 'LCT'},
{value: 'lctl', id: 'LCTL'},
{value: 'ldaf1', id: 'LDAF1'},
{value: 'ldah', id: 'LDAH'},
{value: 'ldb1', id: 'LDB1'},
{value: 'ldb2', id: 'LDB2'},
{value: 'ldb3', id: 'LDB3'},
{value: 'ldha', id: 'LDHA'},
{value: 'ldhal6b', id: 'LDHAL6B'},
{value: 'ldhb', id: 'LDHB'},
{value: 'ldhc', id: 'LDHC'},
{value: 'ldhd', id: 'LDHD'},
{value: 'ldlr', id: 'LDLR'},
{value: 'ldlrad1', id: 'LDLRAD1'},
{value: 'ldlrad2', id: 'LDLRAD2'},
{value: 'ldlrad3', id: 'LDLRAD3'},
{value: 'ldlrad4', id: 'LDLRAD4'},
{value: 'ldlrap1', id: 'LDLRAP1'},
{value: 'ldoc1', id: 'LDOC1'},
{value: 'leap2', id: 'LEAP2'},
{value: 'lect2', id: 'LECT2'},
{value: 'lef1', id: 'LEF1'},
{value: 'lefty1', id: 'LEFTY1'},
{value: 'lefty2', id: 'LEFTY2'},
{value: 'lekr1', id: 'LEKR1'},
{value: 'lemd1', id: 'LEMD1'},
{value: 'lemd2', id: 'LEMD2'},
{value: 'lemd3', id: 'LEMD3'},
{value: 'lenep', id: 'LENEP'},
{value: 'leng1', id: 'LENG1'},
{value: 'leng8', id: 'LENG8'},
{value: 'leng9', id: 'LENG9'},
{value: 'leo1', id: 'LEO1'},
{value: 'lep', id: 'LEP'},
{value: 'lepr', id: 'LEPR'},
{value: 'leprot', id: 'LEPROT'},
{value: 'leprotl1', id: 'LEPROTL1'},
{value: 'letm1', id: 'LETM1'},
{value: 'letm2', id: 'LETM2'},
{value: 'letmd1', id: 'LETMD1'},
{value: 'lexm', id: 'LEXM'},
{value: 'lfng', id: 'LFNG'},
{value: 'lgals1', id: 'LGALS1'},
{value: 'lgals12', id: 'LGALS12'},
{value: 'lgals2', id: 'LGALS2'},
{value: 'lgals3', id: 'LGALS3'},
{value: 'lgals3bp', id: 'LGALS3BP'},
{value: 'lgals4', id: 'LGALS4'},
{value: 'lgals5', id: 'LGALS5'},
{value: 'lgals7', id: 'LGALS7'},
{value: 'lgals8', id: 'LGALS8'},
{value: 'lgals9', id: 'LGALS9'},
{value: 'lgalsl', id: 'LGALSL'},
{value: 'lgi1', id: 'LGI1'},
{value: 'lgi2', id: 'LGI2'},
{value: 'lgi3', id: 'LGI3'},
{value: 'lgi4', id: 'LGI4'},
{value: 'lgmn', id: 'LGMN'},
{value: 'lgr4', id: 'LGR4'},
{value: 'lgr5', id: 'LGR5'},
{value: 'lgr6', id: 'LGR6'},
{value: 'lgsn', id: 'LGSN'},
{value: 'lhb', id: 'LHB'},
{value: 'lhcgr', id: 'LHCGR'},
{value: 'lhfpl1', id: 'LHFPL1'},
{value: 'lhfpl2', id: 'LHFPL2'},
{value: 'lhfpl4', id: 'LHFPL4'},
{value: 'lhfpl5', id: 'LHFPL5'},
{value: 'lhfpl6', id: 'LHFPL6'},
{value: 'lhpp', id: 'LHPP'},
{value: 'lhx1', id: 'LHX1'},
{value: 'lhx2', id: 'LHX2'},
{value: 'lhx3', id: 'LHX3'},
{value: 'lhx4', id: 'LHX4'},
{value: 'lhx5', id: 'LHX5'},
{value: 'lhx6', id: 'LHX6'},
{value: 'lhx8', id: 'LHX8'},
{value: 'lhx9', id: 'LHX9'},
{value: 'lias', id: 'LIAS'},
{value: 'lif', id: 'LIF'},
{value: 'lifr', id: 'LIFR'},
{value: 'lig1', id: 'LIG1'},
{value: 'lig3', id: 'LIG3'},
{value: 'lig4', id: 'LIG4'},
{value: 'lilra5', id: 'LILRA5'},
{value: 'lilrb1', id: 'LILRB1'},
{value: 'lilrb2', id: 'LILRB2'},
{value: 'lilrb3', id: 'LILRB3'},
{value: 'lilrb3a', id: 'LILRB3A'},
{value: 'lilrb3l', id: 'LILRB3L'},
{value: 'lilrb4', id: 'LILRB4'},
{value: 'lilrc2', id: 'LILRC2'},
{value: 'lim2', id: 'LIM2'},
{value: 'lima1', id: 'LIMA1'},
{value: 'limch1', id: 'LIMCH1'},
{value: 'limd1', id: 'LIMD1'},
{value: 'limd2', id: 'LIMD2'},
{value: 'lime1', id: 'LIME1'},
{value: 'limk1', id: 'LIMK1'},
{value: 'limk2', id: 'LIMK2'},
{value: 'lims1', id: 'LIMS1'},
{value: 'lims2', id: 'LIMS2'},
{value: 'lin28a', id: 'LIN28A'},
{value: 'lin28b', id: 'LIN28B'},
{value: 'lin37', id: 'LIN37'},
{value: 'lin52', id: 'LIN52'},
{value: 'lin54', id: 'LIN54'},
{value: 'lin7a', id: 'LIN7A'},
{value: 'lin7b', id: 'LIN7B'},
{value: 'lin7c', id: 'LIN7C'},
{value: 'lin9', id: 'LIN9'},
{value: 'linc00176', id: 'LINC00176'},
{value: 'lingo1', id: 'LINGO1'},
{value: 'lingo2', id: 'LINGO2'},
{value: 'lingo3', id: 'LINGO3'},
{value: 'lingo4', id: 'LINGO4'},
{value: 'lins1', id: 'LINS1'},
{value: 'lipa', id: 'LIPA'},
{value: 'lipc', id: 'LIPC'},
{value: 'lipe', id: 'LIPE'},
{value: 'lipf', id: 'LIPF'},
{value: 'lipg', id: 'LIPG'},
{value: 'lipi', id: 'LIPI'},
{value: 'lipj', id: 'LIPJ'},
{value: 'lipk', id: 'LIPK'},
{value: 'lipm', id: 'LIPM'},
{value: 'lipn', id: 'LIPN'},
{value: 'lipo1', id: 'LIPO1'},
{value: 'lipt1', id: 'LIPT1'},
{value: 'lipt2', id: 'LIPT2'},
{value: 'litaf', id: 'LITAF'},
{value: 'lix1', id: 'LIX1'},
{value: 'lix1l', id: 'LIX1L'},
{value: 'lkaaear1', id: 'LKAAEAR1'},
{value: 'llcfc1', id: 'LLCFC1'},
{value: 'llgl1', id: 'LLGL1'},
{value: 'llgl2', id: 'LLGL2'},
{value: 'llph', id: 'LLPH'},
{value: 'lman1', id: 'LMAN1'},
{value: 'lman1l', id: 'LMAN1L'},
{value: 'lman2', id: 'LMAN2'},
{value: 'lman2l', id: 'LMAN2L'},
{value: 'lmbr1', id: 'LMBR1'},
{value: 'lmbr1l', id: 'LMBR1L'},
{value: 'lmbrd1', id: 'LMBRD1'},
{value: 'lmbrd2', id: 'LMBRD2'},
{value: 'lmcd1', id: 'LMCD1'},
{value: 'lmf1', id: 'LMF1'},
{value: 'lmf2', id: 'LMF2'},
{value: 'lmln', id: 'LMLN'},
{value: 'lmln2', id: 'LMLN2'},
{value: 'lmna', id: 'LMNA'},
{value: 'lmnb1', id: 'LMNB1'},
{value: 'lmnb2', id: 'LMNB2'},
{value: 'lmntd1', id: 'LMNTD1'},
{value: 'lmntd2', id: 'LMNTD2'},
{value: 'lmo1', id: 'LMO1'},
{value: 'lmo2', id: 'LMO2'},
{value: 'lmo3', id: 'LMO3'},
{value: 'lmo4', id: 'LMO4'},
{value: 'lmo7', id: 'LMO7'},
{value: 'lmod1', id: 'LMOD1'},
{value: 'lmod2', id: 'LMOD2'},
{value: 'lmod3', id: 'LMOD3'},
{value: 'lmtk2', id: 'LMTK2'},
{value: 'lmtk3', id: 'LMTK3'},
{value: 'lmx1a', id: 'LMX1A'},
{value: 'lmx1b', id: 'LMX1B'},
{value: 'lnp1', id: 'LNP1'},
{value: 'lnpep', id: 'LNPEP'},
{value: 'lnpk', id: 'LNPK'},
{value: 'lnx1', id: 'LNX1'},
{value: 'lnx2', id: 'LNX2'},
{value: 'loc100125362', id: 'LOC100125362'},
{value: 'loc100125364', id: 'LOC100125364'},
{value: 'loc100125366', id: 'LOC100125366'},
{value: 'loc100125367', id: 'LOC100125367'},
{value: 'loc100125368', id: 'LOC100125368'},
{value: 'loc100125384', id: 'LOC100125384'},
{value: 'loc100134871', id: 'LOC100134871'},
{value: 'loc100174910', id: 'LOC100174910'},
{value: 'loc100271845', id: 'LOC100271845'},
{value: 'loc100294508', id: 'LOC100294508'},
{value: 'loc100302372', id: 'LOC100302372'},
{value: 'loc100302465', id: 'LOC100302465'},
{value: 'loc100359421', id: 'LOC100359421'},
{value: 'loc100359503', id: 'LOC100359503'},
{value: 'loc100359505', id: 'LOC100359505'},
{value: 'loc100359515', id: 'LOC100359515'},
{value: 'loc100359539', id: 'LOC100359539'},
{value: 'loc100359550', id: 'LOC100359550'},
{value: 'loc100359563', id: 'LOC100359563'},
{value: 'loc100359574', id: 'LOC100359574'},
{value: 'loc100359583', id: 'LOC100359583'},
{value: 'loc100359600', id: 'LOC100359600'},
{value: 'loc100359633', id: 'LOC100359633'},
{value: 'loc100359656', id: 'LOC100359656'},
{value: 'loc100359668', id: 'LOC100359668'},
{value: 'loc100359711', id: 'LOC100359711'},
{value: 'loc100359752', id: 'LOC100359752'},
{value: 'loc100359777', id: 'LOC100359777'},
{value: 'loc100359907', id: 'LOC100359907'},
{value: 'loc100359916', id: 'LOC100359916'},
{value: 'loc100359922', id: 'LOC100359922'},
{value: 'loc100359924', id: 'LOC100359924'},
{value: 'loc100359930', id: 'LOC100359930'},
{value: 'loc100359937', id: 'LOC100359937'},
{value: 'loc100359951', id: 'LOC100359951'},
{value: 'loc100359968', id: 'LOC100359968'},
{value: 'loc100360049', id: 'LOC100360049'},
{value: 'loc100360057', id: 'LOC100360057'},
{value: 'loc100360087', id: 'LOC100360087'},
{value: 'loc100360095', id: 'LOC100360095'},
{value: 'loc100360117', id: 'LOC100360117'},
{value: 'loc100360128', id: 'LOC100360128'},
{value: 'loc100360165', id: 'LOC100360165'},
{value: 'loc100360218', id: 'LOC100360218'},
{value: 'loc100360238', id: 'LOC100360238'},
{value: 'loc100360244', id: 'LOC100360244'},
{value: 'loc100360296', id: 'LOC100360296'},
{value: 'loc100360316', id: 'LOC100360316'},
{value: 'loc100360380', id: 'LOC100360380'},
{value: 'loc100360396', id: 'LOC100360396'},
{value: 'loc100360413', id: 'LOC100360413'},
{value: 'loc100360439', id: 'LOC100360439'},
{value: 'loc100360449', id: 'LOC100360449'},
{value: 'loc100360491', id: 'LOC100360491'},
{value: 'loc100360522', id: 'LOC100360522'},
{value: 'loc100360557', id: 'LOC100360557'},
{value: 'loc100360573', id: 'LOC100360573'},
{value: 'loc100360574', id: 'LOC100360574'},
{value: 'loc100360601', id: 'LOC100360601'},
{value: 'loc100360619', id: 'LOC100360619'},
{value: 'loc100360628', id: 'LOC100360628'},
{value: 'loc100360750', id: 'LOC100360750'},
{value: 'loc100360779', id: 'LOC100360779'},
{value: 'loc100360790', id: 'LOC100360790'},
{value: 'loc100360791', id: 'LOC100360791'},
{value: 'loc100360821', id: 'LOC100360821'},
{value: 'loc100360828', id: 'LOC100360828'},
{value: 'loc100360841', id: 'LOC100360841'},
{value: 'loc100360843', id: 'LOC100360843'},
{value: 'loc100360846', id: 'LOC100360846'},
{value: 'loc100360856', id: 'LOC100360856'},
{value: 'loc100360933', id: 'LOC100360933'},
{value: 'loc100360940', id: 'LOC100360940'},
{value: 'loc100360950', id: 'LOC100360950'},
{value: 'loc100361008', id: 'LOC100361008'},
{value: 'loc100361018', id: 'LOC100361018'},
{value: 'loc100361025', id: 'LOC100361025'},
{value: 'loc100361060', id: 'LOC100361060'},
{value: 'loc100361061', id: 'LOC100361061'},
{value: 'loc100361067', id: 'LOC100361067'},
{value: 'loc100361079', id: 'LOC100361079'},
{value: 'loc100361083', id: 'LOC100361083'},
{value: 'loc100361087', id: 'LOC100361087'},
{value: 'loc100361090', id: 'LOC100361090'},
{value: 'loc100361098', id: 'LOC100361098'},
{value: 'loc100361104', id: 'LOC100361104'},
{value: 'loc100361139', id: 'LOC100361139'},
{value: 'loc100361143', id: 'LOC100361143'},
{value: 'loc100361194', id: 'LOC100361194'},
{value: 'loc100361204', id: 'LOC100361204'},
{value: 'loc100361259', id: 'LOC100361259'},
{value: 'loc100361261', id: 'LOC100361261'},
{value: 'loc100361265', id: 'LOC100361265'},
{value: 'loc100361436', id: 'LOC100361436'},
{value: 'loc100361457', id: 'LOC100361457'},
{value: 'loc100361492', id: 'LOC100361492'},
{value: 'loc100361543', id: 'LOC100361543'},
{value: 'loc100361547', id: 'LOC100361547'},
{value: 'loc100361556', id: 'LOC100361556'},
{value: 'loc100361636', id: 'LOC100361636'},
{value: 'loc100361645', id: 'LOC100361645'},
{value: 'loc100361655', id: 'LOC100361655'},
{value: 'loc100361664', id: 'LOC100361664'},
{value: 'loc100361702', id: 'LOC100361702'},
{value: 'loc100361706', id: 'LOC100361706'},
{value: 'loc100361739', id: 'LOC100361739'},
{value: 'loc100361756', id: 'LOC100361756'},
{value: 'loc100361793', id: 'LOC100361793'},
{value: 'loc100361814', id: 'LOC100361814'},
{value: 'loc100361826', id: 'LOC100361826'},
{value: 'loc100361828', id: 'LOC100361828'},
{value: 'loc100361833', id: 'LOC100361833'},
{value: 'loc100361854', id: 'LOC100361854'},
{value: 'loc100361866', id: 'LOC100361866'},
{value: 'loc100361898', id: 'LOC100361898'},
{value: 'loc100361907', id: 'LOC100361907'},
{value: 'loc100361913', id: 'LOC100361913'},
{value: 'loc100361920', id: 'LOC100361920'},
{value: 'loc100361933', id: 'LOC100361933'},
{value: 'loc100361934', id: 'LOC100361934'},
{value: 'loc100361944', id: 'LOC100361944'},
{value: 'loc100361993', id: 'LOC100361993'},
{value: 'loc100362023', id: 'LOC100362023'},
{value: 'loc100362027', id: 'LOC100362027'},
{value: 'loc100362040', id: 'LOC100362040'},
{value: 'loc100362054', id: 'LOC100362054'},
{value: 'loc100362078', id: 'LOC100362078'},
{value: 'loc100362109', id: 'LOC100362109'},
{value: 'loc100362110', id: 'LOC100362110'},
{value: 'loc100362149', id: 'LOC100362149'},
{value: 'loc100362172', id: 'LOC100362172'},
{value: 'loc100362173', id: 'LOC100362173'},
{value: 'loc100362176', id: 'LOC100362176'},
{value: 'loc100362197', id: 'LOC100362197'},
{value: 'loc100362216', id: 'LOC100362216'},
{value: 'loc100362298', id: 'LOC100362298'},
{value: 'loc100362315', id: 'LOC100362315'},
{value: 'loc100362319', id: 'LOC100362319'},
{value: 'loc100362333', id: 'LOC100362333'},
{value: 'loc100362339', id: 'LOC100362339'},
{value: 'loc100362342', id: 'LOC100362342'},
{value: 'loc100362344', id: 'LOC100362344'},
{value: 'loc100362345', id: 'LOC100362345'},
{value: 'loc100362366', id: 'LOC100362366'},
{value: 'loc100362384', id: 'LOC100362384'},
{value: 'loc100362400', id: 'LOC100362400'},
{value: 'loc100362432', id: 'LOC100362432'},
{value: 'loc100362453', id: 'LOC100362453'},
{value: 'loc100362583', id: 'LOC100362583'},
{value: 'loc100362684', id: 'LOC100362684'},
{value: 'loc100362690', id: 'LOC100362690'},
{value: 'loc100362724', id: 'LOC100362724'},
{value: 'loc100362738', id: 'LOC100362738'},
{value: 'loc100362751', id: 'LOC100362751'},
{value: 'loc100362798', id: 'LOC100362798'},
{value: 'loc100362814', id: 'LOC100362814'},
{value: 'loc100362820', id: 'LOC100362820'},
{value: 'loc100362827', id: 'LOC100362827'},
{value: 'loc100362830', id: 'LOC100362830'},
{value: 'loc100362895', id: 'LOC100362895'},
{value: 'loc100362949', id: 'LOC100362949'},
{value: 'loc100362965', id: 'LOC100362965'},
{value: 'loc100362978', id: 'LOC100362978'},
{value: 'loc100362980', id: 'LOC100362980'},
{value: 'loc100362981', id: 'LOC100362981'},
{value: 'loc100362987', id: 'LOC100362987'},
{value: 'loc100362996', id: 'LOC100362996'},
{value: 'loc100362999', id: 'LOC100362999'},
{value: 'loc100363064', id: 'LOC100363064'},
{value: 'loc100363112', id: 'LOC100363112'},
{value: 'loc100363116', id: 'LOC100363116'},
{value: 'loc100363129', id: 'LOC100363129'},
{value: 'loc100363136', id: 'LOC100363136'},
{value: 'loc100363141', id: 'LOC100363141'},
{value: 'loc100363193', id: 'LOC100363193'},
{value: 'loc100363228', id: 'LOC100363228'},
{value: 'loc100363268', id: 'LOC100363268'},
{value: 'loc100363287', id: 'LOC100363287'},
{value: 'loc100363289', id: 'LOC100363289'},
{value: 'loc100363294', id: 'LOC100363294'},
{value: 'loc100363319', id: 'LOC100363319'},
{value: 'loc100363321', id: 'LOC100363321'},
{value: 'loc100363333', id: 'LOC100363333'},
{value: 'loc100363372', id: 'LOC100363372'},
{value: 'loc100363405', id: 'LOC100363405'},
{value: 'loc100363452', id: 'LOC100363452'},
{value: 'loc100363469', id: 'LOC100363469'},
{value: 'loc100363472', id: 'LOC100363472'},
{value: 'loc100363502', id: 'LOC100363502'},
{value: 'loc100363510', id: 'LOC100363510'},
{value: 'loc100363520', id: 'LOC100363520'},
{value: 'loc100363531', id: 'LOC100363531'},
{value: 'loc100363537', id: 'LOC100363537'},
{value: 'loc100363782', id: 'LOC100363782'},
{value: 'loc100363867', id: 'LOC100363867'},
{value: 'loc100363993', id: 'LOC100363993'},
{value: 'loc100364002', id: 'LOC100364002'},
{value: 'loc100364016', id: 'LOC100364016'},
{value: 'loc100364062', id: 'LOC100364062'},
{value: 'loc100364116', id: 'LOC100364116'},
{value: 'loc100364265', id: 'LOC100364265'},
{value: 'loc100364335', id: 'LOC100364335'},
{value: 'loc100364435', id: 'LOC100364435'},
{value: 'loc100364457', id: 'LOC100364457'},
{value: 'loc100364509', id: 'LOC100364509'},
{value: 'loc100364523', id: 'LOC100364523'},
{value: 'loc100364769', id: 'LOC100364769'},
{value: 'loc100364835', id: 'LOC100364835'},
{value: 'loc100364862', id: 'LOC100364862'},
{value: 'loc100364958', id: 'LOC100364958'},
{value: 'loc100365043', id: 'LOC100365043'},
{value: 'loc100365062', id: 'LOC100365062'},
{value: 'loc100365112', id: 'LOC100365112'},
{value: 'loc100365213', id: 'LOC100365213'},
{value: 'loc100365363', id: 'LOC100365363'},
{value: 'loc100365588', id: 'LOC100365588'},
{value: 'loc100365697', id: 'LOC100365697'},
{value: 'loc100365810', id: 'LOC100365810'},
{value: 'loc100365839', id: 'LOC100365839'},
{value: 'loc100366030', id: 'LOC100366030'},
{value: 'loc100366054', id: 'LOC100366054'},
{value: 'loc100366216', id: 'LOC100366216'},
{value: 'loc100909398', id: 'LOC100909398'},
{value: 'loc100909434', id: 'LOC100909434'},
{value: 'loc100909435', id: 'LOC100909435'},
{value: 'loc100909569', id: 'LOC100909569'},
{value: 'loc100909608', id: 'LOC100909608'},
{value: 'loc100909671', id: 'LOC100909671'},
{value: 'loc100909677', id: 'LOC100909677'},
{value: 'loc100909680', id: 'LOC100909680'},
{value: 'loc100909682', id: 'LOC100909682'},
{value: 'loc100909683', id: 'LOC100909683'},
{value: 'loc100909688', id: 'LOC100909688'},
{value: 'loc100909704', id: 'LOC100909704'},
{value: 'loc100909797', id: 'LOC100909797'},
{value: 'loc100909859', id: 'LOC100909859'},
{value: 'loc100909879', id: 'LOC100909879'},
{value: 'loc100909897', id: 'LOC100909897'},
{value: 'loc100909911', id: 'LOC100909911'},
{value: 'loc100909912', id: 'LOC100909912'},
{value: 'loc100909943', id: 'LOC100909943'},
{value: 'loc100909955', id: 'LOC100909955'},
{value: 'loc100909964', id: 'LOC100909964'},
{value: 'loc100909977', id: 'LOC100909977'},
{value: 'loc100909998', id: 'LOC100909998'},
{value: 'loc100910017', id: 'LOC100910017'},
{value: 'loc100910026', id: 'LOC100910026'},
{value: 'loc100910046', id: 'LOC100910046'},
{value: 'loc100910057', id: 'LOC100910057'},
{value: 'loc100910068', id: 'LOC100910068'},
{value: 'loc100910088', id: 'LOC100910088'},
{value: 'loc100910107', id: 'LOC100910107'},
{value: 'loc100910108', id: 'LOC100910108'},
{value: 'loc100910111', id: 'LOC100910111'},
{value: 'loc100910165', id: 'LOC100910165'},
{value: 'loc100910172', id: 'LOC100910172'},
{value: 'loc100910180', id: 'LOC100910180'},
{value: 'loc100910182', id: 'LOC100910182'},
{value: 'loc100910200', id: 'LOC100910200'},
{value: 'loc100910237', id: 'LOC100910237'},
{value: 'loc100910258', id: 'LOC100910258'},
{value: 'loc100910292', id: 'LOC100910292'},
{value: 'loc100910304', id: 'LOC100910304'},
{value: 'loc100910378', id: 'LOC100910378'},
{value: 'loc100910451', id: 'LOC100910451'},
{value: 'loc100910474', id: 'LOC100910474'},
{value: 'loc100910481', id: 'LOC100910481'},
{value: 'loc100910497', id: 'LOC100910497'},
{value: 'loc100910554', id: 'LOC100910554'},
{value: 'loc100910577', id: 'LOC100910577'},
{value: 'loc100910596', id: 'LOC100910596'},
{value: 'loc100910611', id: 'LOC100910611'},
{value: 'loc100910640', id: 'LOC100910640'},
{value: 'loc100910650', id: 'LOC100910650'},
{value: 'loc100910678', id: 'LOC100910678'},
{value: 'loc100910754', id: 'LOC100910754'},
{value: 'loc100910788', id: 'LOC100910788'},
{value: 'loc100910792', id: 'LOC100910792'},
{value: 'loc100910798', id: 'LOC100910798'},
{value: 'loc100910801', id: 'LOC100910801'},
{value: 'loc100910814', id: 'LOC100910814'},
{value: 'loc100910850', id: 'LOC100910850'},
{value: 'loc100910852', id: 'LOC100910852'},
{value: 'loc100910885', id: 'LOC100910885'},
{value: 'loc100910906', id: 'LOC100910906'},
{value: 'loc100910934', id: 'LOC100910934'},
{value: 'loc100910953', id: 'LOC100910953'},
{value: 'loc100910977', id: 'LOC100910977'},
{value: 'loc100910979', id: 'LOC100910979'},
{value: 'loc100910996', id: 'LOC100910996'},
{value: 'loc100911002', id: 'LOC100911002'},
{value: 'loc100911044', id: 'LOC100911044'},
{value: 'loc100911047', id: 'LOC100911047'},
{value: 'loc100911132', id: 'LOC100911132'},
{value: 'loc100911153', id: 'LOC100911153'},
{value: 'loc100911196', id: 'LOC100911196'},
{value: 'loc100911224', id: 'LOC100911224'},
{value: 'loc100911229', id: 'LOC100911229'},
{value: 'loc100911251', id: 'LOC100911251'},
{value: 'loc100911252', id: 'LOC100911252'},
{value: 'loc100911264', id: 'LOC100911264'},
{value: 'loc100911320', id: 'LOC100911320'},
{value: 'loc100911347', id: 'LOC100911347'},
{value: 'loc100911371', id: 'LOC100911371'},
{value: 'loc100911374', id: 'LOC100911374'},
{value: 'loc100911413', id: 'LOC100911413'},
{value: 'loc100911426', id: 'LOC100911426'},
{value: 'loc100911489', id: 'LOC100911489'},
{value: 'loc100911510', id: 'LOC100911510'},
{value: 'loc100911511', id: 'LOC100911511'},
{value: 'loc100911527', id: 'LOC100911527'},
{value: 'loc100911571', id: 'LOC100911571'},
{value: 'loc100911576', id: 'LOC100911576'},
{value: 'loc100911644', id: 'LOC100911644'},
{value: 'loc100911649', id: 'LOC100911649'},
{value: 'loc100911664', id: 'LOC100911664'},
{value: 'loc100911668', id: 'LOC100911668'},
{value: 'loc100911679', id: 'LOC100911679'},
{value: 'loc100911681', id: 'LOC100911681'},
{value: 'loc100911728', id: 'LOC100911728'},
{value: 'loc100911744', id: 'LOC100911744'},
{value: 'loc100911758', id: 'LOC100911758'},
{value: 'loc100911776', id: 'LOC100911776'},
{value: 'loc100911796', id: 'LOC100911796'},
{value: 'loc100911797', id: 'LOC100911797'},
{value: 'loc100911801', id: 'LOC100911801'},
{value: 'loc100911847', id: 'LOC100911847'},
{value: 'loc100911857', id: 'LOC100911857'},
{value: 'loc100911871', id: 'LOC100911871'},
{value: 'loc100911926', id: 'LOC100911926'},
{value: 'loc100911936', id: 'LOC100911936'},
{value: 'loc100911938', id: 'LOC100911938'},
{value: 'loc100911956', id: 'LOC100911956'},
{value: 'loc100911958', id: 'LOC100911958'},
{value: 'loc100911984', id: 'LOC100911984'},
{value: 'loc100912009', id: 'LOC100912009'},
{value: 'loc100912026', id: 'LOC100912026'},
{value: 'loc100912027', id: 'LOC100912027'},
{value: 'loc100912028', id: 'LOC100912028'},
{value: 'loc100912076', id: 'LOC100912076'},
{value: 'loc100912114', id: 'LOC100912114'},
{value: 'loc100912135', id: 'LOC100912135'},
{value: 'loc100912151', id: 'LOC100912151'},
{value: 'loc100912181', id: 'LOC100912181'},
{value: 'loc100912195', id: 'LOC100912195'},
{value: 'loc100912217', id: 'LOC100912217'},
{value: 'loc100912245', id: 'LOC100912245'},
{value: 'loc100912252', id: 'LOC100912252'},
{value: 'loc100912259', id: 'LOC100912259'},
{value: 'loc100912261', id: 'LOC100912261'},
{value: 'loc100912263', id: 'LOC100912263'},
{value: 'loc100912265', id: 'LOC100912265'},
{value: 'loc100912266', id: 'LOC100912266'},
{value: 'loc100912272', id: 'LOC100912272'},
{value: 'loc100912294', id: 'LOC100912294'},
{value: 'loc100912306', id: 'LOC100912306'},
{value: 'loc100912314', id: 'LOC100912314'},
{value: 'loc100912319', id: 'LOC100912319'},
{value: 'loc100912321', id: 'LOC100912321'},
{value: 'loc100912327', id: 'LOC100912327'},
{value: 'loc100912336', id: 'LOC100912336'},
{value: 'loc100912338', id: 'LOC100912338'},
{value: 'loc100912342', id: 'LOC100912342'},
{value: 'loc100912347', id: 'LOC100912347'},
{value: 'loc100912348', id: 'LOC100912348'},
{value: 'loc100912356', id: 'LOC100912356'},
{value: 'loc100912365', id: 'LOC100912365'},
{value: 'loc100912373', id: 'LOC100912373'},
{value: 'loc100912393', id: 'LOC100912393'},
{value: 'loc100912405', id: 'LOC100912405'},
{value: 'loc100912427', id: 'LOC100912427'},
{value: 'loc100912432', id: 'LOC100912432'},
{value: 'loc100912436', id: 'LOC100912436'},
{value: 'loc100912450', id: 'LOC100912450'},
{value: 'loc100912471', id: 'LOC100912471'},
{value: 'loc100912474', id: 'LOC100912474'},
{value: 'loc100912483', id: 'LOC100912483'},
{value: 'loc100912510', id: 'LOC100912510'},
{value: 'loc100912564', id: 'LOC100912564'},
{value: 'loc100912582', id: 'LOC100912582'},
{value: 'loc100912586', id: 'LOC100912586'},
{value: 'loc100912609', id: 'LOC100912609'},
{value: 'loc100912610', id: 'LOC100912610'},
{value: 'loc100912615', id: 'LOC100912615'},
{value: 'loc100912642', id: 'LOC100912642'},
{value: 'loc100912656', id: 'LOC100912656'},
{value: 'loc100912677', id: 'LOC100912677'},
{value: 'loc100912683', id: 'LOC100912683'},
{value: 'loc100912724', id: 'LOC100912724'},
{value: 'loc100912759', id: 'LOC100912759'},
{value: 'loc100912818', id: 'LOC100912818'},
{value: 'loc100912836', id: 'LOC100912836'},
{value: 'loc100912849', id: 'LOC100912849'},
{value: 'loc100912937', id: 'LOC100912937'},
{value: 'loc100912948', id: 'LOC100912948'},
{value: 'loc100912958', id: 'LOC100912958'},
{value: 'loc100912964', id: 'LOC100912964'},
{value: 'loc100912990', id: 'LOC100912990'},
{value: 'loc102546383', id: 'LOC102546383'},
{value: 'loc102546391', id: 'LOC102546391'},
{value: 'loc102546467', id: 'LOC102546467'},
{value: 'loc102546476', id: 'LOC102546476'},
{value: 'loc102546495', id: 'LOC102546495'},
{value: 'loc102546496', id: 'LOC102546496'},
{value: 'loc102546554', id: 'LOC102546554'},
{value: 'loc102546572', id: 'LOC102546572'},
{value: 'loc102546596', id: 'LOC102546596'},
{value: 'loc102546601', id: 'LOC102546601'},
{value: 'loc102546648', id: 'LOC102546648'},
{value: 'loc102546672', id: 'LOC102546672'},
{value: 'loc102546683', id: 'LOC102546683'},
{value: 'loc102546716', id: 'LOC102546716'},
{value: 'loc102546830', id: 'LOC102546830'},
{value: 'loc102546862', id: 'LOC102546862'},
{value: 'loc102546864', id: 'LOC102546864'},
{value: 'loc102546868', id: 'LOC102546868'},
{value: 'loc102546903', id: 'LOC102546903'},
{value: 'loc102546963', id: 'LOC102546963'},
{value: 'loc102546968', id: 'LOC102546968'},
{value: 'loc102547011', id: 'LOC102547011'},
{value: 'loc102547029', id: 'LOC102547029'},
{value: 'loc102547035', id: 'LOC102547035'},
{value: 'loc102547047', id: 'LOC102547047'},
{value: 'loc102547119', id: 'LOC102547119'},
{value: 'loc102547241', id: 'LOC102547241'},
{value: 'loc102547287', id: 'LOC102547287'},
{value: 'loc102547344', id: 'LOC102547344'},
{value: 'loc102547412', id: 'LOC102547412'},
{value: 'loc102547414', id: 'LOC102547414'},
{value: 'loc102547431', id: 'LOC102547431'},
{value: 'loc102547532', id: 'LOC102547532'},
{value: 'loc102547626', id: 'LOC102547626'},
{value: 'loc102547663', id: 'LOC102547663'},
{value: 'loc102547734', id: 'LOC102547734'},
{value: 'loc102547811', id: 'LOC102547811'},
{value: 'loc102547897', id: 'LOC102547897'},
{value: 'loc102547920', id: 'LOC102547920'},
{value: 'loc102547937', id: 'LOC102547937'},
{value: 'loc102547949', id: 'LOC102547949'},
{value: 'loc102547954', id: 'LOC102547954'},
{value: 'loc102547956', id: 'LOC102547956'},
{value: 'loc102548031', id: 'LOC102548031'},
{value: 'loc102548061', id: 'LOC102548061'},
{value: 'loc102548067', id: 'LOC102548067'},
{value: 'loc102548076', id: 'LOC102548076'},
{value: 'loc102548111', id: 'LOC102548111'},
{value: 'loc102548129', id: 'LOC102548129'},
{value: 'loc102548162', id: 'LOC102548162'},
{value: 'loc102548225', id: 'LOC102548225'},
{value: 'loc102548231', id: 'LOC102548231'},
{value: 'loc102548286', id: 'LOC102548286'},
{value: 'loc102548320', id: 'LOC102548320'},
{value: 'loc102548326', id: 'LOC102548326'},
{value: 'loc102548369', id: 'LOC102548369'},
{value: 'loc102548428', id: 'LOC102548428'},
{value: 'loc102548472', id: 'LOC102548472'},
{value: 'loc102548514', id: 'LOC102548514'},
{value: 'loc102548534', id: 'LOC102548534'},
{value: 'loc102548541', id: 'LOC102548541'},
{value: 'loc102548546', id: 'LOC102548546'},
{value: 'loc102548600', id: 'LOC102548600'},
{value: 'loc102548633', id: 'LOC102548633'},
{value: 'loc102548682', id: 'LOC102548682'},
{value: 'loc102548695', id: 'LOC102548695'},
{value: 'loc102548714', id: 'LOC102548714'},
{value: 'loc102548728', id: 'LOC102548728'},
{value: 'loc102548781', id: 'LOC102548781'},
{value: 'loc102548800', id: 'LOC102548800'},
{value: 'loc102548818', id: 'LOC102548818'},
{value: 'loc102548867', id: 'LOC102548867'},
{value: 'loc102548889', id: 'LOC102548889'},
{value: 'loc102548917', id: 'LOC102548917'},
{value: 'loc102548978', id: 'LOC102548978'},
{value: 'loc102549007', id: 'LOC102549007'},
{value: 'loc102549011', id: 'LOC102549011'},
{value: 'loc102549059', id: 'LOC102549059'},
{value: 'loc102549061', id: 'LOC102549061'},
{value: 'loc102549158', id: 'LOC102549158'},
{value: 'loc102549170', id: 'LOC102549170'},
{value: 'loc102549173', id: 'LOC102549173'},
{value: 'loc102549291', id: 'LOC102549291'},
{value: 'loc102549391', id: 'LOC102549391'},
{value: 'loc102549397', id: 'LOC102549397'},
{value: 'loc102549408', id: 'LOC102549408'},
{value: 'loc102549465', id: 'LOC102549465'},
{value: 'loc102549509', id: 'LOC102549509'},
{value: 'loc102549548', id: 'LOC102549548'},
{value: 'loc102549636', id: 'LOC102549636'},
{value: 'loc102549716', id: 'LOC102549716'},
{value: 'loc102549726', id: 'LOC102549726'},
{value: 'loc102549747', id: 'LOC102549747'},
{value: 'loc102549817', id: 'LOC102549817'},
{value: 'loc102549842', id: 'LOC102549842'},
{value: 'loc102549852', id: 'LOC102549852'},
{value: 'loc102549869', id: 'LOC102549869'},
{value: 'loc102549888', id: 'LOC102549888'},
{value: 'loc102549941', id: 'LOC102549941'},
{value: 'loc102549948', id: 'LOC102549948'},
{value: 'loc102549991', id: 'LOC102549991'},
{value: 'loc102550012', id: 'LOC102550012'},
{value: 'loc102550036', id: 'LOC102550036'},
{value: 'loc102550066', id: 'LOC102550066'},
{value: 'loc102550081', id: 'LOC102550081'},
{value: 'loc102550121', id: 'LOC102550121'},
{value: 'loc102550127', id: 'LOC102550127'},
{value: 'loc102550160', id: 'LOC102550160'},
{value: 'loc102550206', id: 'LOC102550206'},
{value: 'loc102550284', id: 'LOC102550284'},
{value: 'loc102550309', id: 'LOC102550309'},
{value: 'loc102550314', id: 'LOC102550314'},
{value: 'loc102550325', id: 'LOC102550325'},
{value: 'loc102550365', id: 'LOC102550365'},
{value: 'loc102550375', id: 'LOC102550375'},
{value: 'loc102550392', id: 'LOC102550392'},
{value: 'loc102550396', id: 'LOC102550396'},
{value: 'loc102550397', id: 'LOC102550397'},
{value: 'loc102550441', id: 'LOC102550441'},
{value: 'loc102550479', id: 'LOC102550479'},
{value: 'loc102550505', id: 'LOC102550505'},
{value: 'loc102550519', id: 'LOC102550519'},
{value: 'loc102550537', id: 'LOC102550537'},
{value: 'loc102550543', id: 'LOC102550543'},
{value: 'loc102550657', id: 'LOC102550657'},
{value: 'loc102550664', id: 'LOC102550664'},
{value: 'loc102550668', id: 'LOC102550668'},
{value: 'loc102550682', id: 'LOC102550682'},
{value: 'loc102550734', id: 'LOC102550734'},
{value: 'loc102550841', id: 'LOC102550841'},
{value: 'loc102550872', id: 'LOC102550872'},
{value: 'loc102550875', id: 'LOC102550875'},
{value: 'loc102550885', id: 'LOC102550885'},
{value: 'loc102550919', id: 'LOC102550919'},
{value: 'loc102550983', id: 'LOC102550983'},
{value: 'loc102550986', id: 'LOC102550986'},
{value: 'loc102551003', id: 'LOC102551003'},
{value: 'loc102551014', id: 'LOC102551014'},
{value: 'loc102551035', id: 'LOC102551035'},
{value: 'loc102551064', id: 'LOC102551064'},
{value: 'loc102551071', id: 'LOC102551071'},
{value: 'loc102551080', id: 'LOC102551080'},
{value: 'loc102551095', id: 'LOC102551095'},
{value: 'loc102551100', id: 'LOC102551100'},
{value: 'loc102551102', id: 'LOC102551102'},
{value: 'loc102551184', id: 'LOC102551184'},
{value: 'loc102551265', id: 'LOC102551265'},
{value: 'loc102551267', id: 'LOC102551267'},
{value: 'loc102551304', id: 'LOC102551304'},
{value: 'loc102551309', id: 'LOC102551309'},
{value: 'loc102551339', id: 'LOC102551339'},
{value: 'loc102551340', id: 'LOC102551340'},
{value: 'loc102551353', id: 'LOC102551353'},
{value: 'loc102551356', id: 'LOC102551356'},
{value: 'loc102551357', id: 'LOC102551357'},
{value: 'loc102551406', id: 'LOC102551406'},
{value: 'loc102551451', id: 'LOC102551451'},
{value: 'loc102551497', id: 'LOC102551497'},
{value: 'loc102551504', id: 'LOC102551504'},
{value: 'loc102551518', id: 'LOC102551518'},
{value: 'loc102551526', id: 'LOC102551526'},
{value: 'loc102551549', id: 'LOC102551549'},
{value: 'loc102551575', id: 'LOC102551575'},
{value: 'loc102551606', id: 'LOC102551606'},
{value: 'loc102551633', id: 'LOC102551633'},
{value: 'loc102551647', id: 'LOC102551647'},
{value: 'loc102551679', id: 'LOC102551679'},
{value: 'loc102551695', id: 'LOC102551695'},
{value: 'loc102551754', id: 'LOC102551754'},
{value: 'loc102551788', id: 'LOC102551788'},
{value: 'loc102551806', id: 'LOC102551806'},
{value: 'loc102551819', id: 'LOC102551819'},
{value: 'loc102551820', id: 'LOC102551820'},
{value: 'loc102551838', id: 'LOC102551838'},
{value: 'loc102551882', id: 'LOC102551882'},
{value: 'loc102551929', id: 'LOC102551929'},
{value: 'loc102552000', id: 'LOC102552000'},
{value: 'loc102552043', id: 'LOC102552043'},
{value: 'loc102552101', id: 'LOC102552101'},
{value: 'loc102552128', id: 'LOC102552128'},
{value: 'loc102552182', id: 'LOC102552182'},
{value: 'loc102552204', id: 'LOC102552204'},
{value: 'loc102552273', id: 'LOC102552273'},
{value: 'loc102552284', id: 'LOC102552284'},
{value: 'loc102552318', id: 'LOC102552318'},
{value: 'loc102552324', id: 'LOC102552324'},
{value: 'loc102552326', id: 'LOC102552326'},
{value: 'loc102552344', id: 'LOC102552344'},
{value: 'loc102552356', id: 'LOC102552356'},
{value: 'loc102552378', id: 'LOC102552378'},
{value: 'loc102552387', id: 'LOC102552387'},
{value: 'loc102552398', id: 'LOC102552398'},
{value: 'loc102552474', id: 'LOC102552474'},
{value: 'loc102552523', id: 'LOC102552523'},
{value: 'loc102552527', id: 'LOC102552527'},
{value: 'loc102552542', id: 'LOC102552542'},
{value: 'loc102552617', id: 'LOC102552617'},
{value: 'loc102552619', id: 'LOC102552619'},
{value: 'loc102552627', id: 'LOC102552627'},
{value: 'loc102552638', id: 'LOC102552638'},
{value: 'loc102552640', id: 'LOC102552640'},
{value: 'loc102552729', id: 'LOC102552729'},
{value: 'loc102552784', id: 'LOC102552784'},
{value: 'loc102552798', id: 'LOC102552798'},
{value: 'loc102552805', id: 'LOC102552805'},
{value: 'loc102552829', id: 'LOC102552829'},
{value: 'loc102552882', id: 'LOC102552882'},
{value: 'loc102552911', id: 'LOC102552911'},
{value: 'loc102552913', id: 'LOC102552913'},
{value: 'loc102552988', id: 'LOC102552988'},
{value: 'loc102553018', id: 'LOC102553018'},
{value: 'loc102553083', id: 'LOC102553083'},
{value: 'loc102553110', id: 'LOC102553110'},
{value: 'loc102553138', id: 'LOC102553138'},
{value: 'loc102553190', id: 'LOC102553190'},
{value: 'loc102553278', id: 'LOC102553278'},
{value: 'loc102553338', id: 'LOC102553338'},
{value: 'loc102553349', id: 'LOC102553349'},
{value: 'loc102553386', id: 'LOC102553386'},
{value: 'loc102553472', id: 'LOC102553472'},
{value: 'loc102553495', id: 'LOC102553495'},
{value: 'loc102553498', id: 'LOC102553498'},
{value: 'loc102553570', id: 'LOC102553570'},
{value: 'loc102553734', id: 'LOC102553734'},
{value: 'loc102553737', id: 'LOC102553737'},
{value: 'loc102553760', id: 'LOC102553760'},
{value: 'loc102553774', id: 'LOC102553774'},
{value: 'loc102553785', id: 'LOC102553785'},
{value: 'loc102553814', id: 'LOC102553814'},
{value: 'loc102553825', id: 'LOC102553825'},
{value: 'loc102553828', id: 'LOC102553828'},
{value: 'loc102554058', id: 'LOC102554058'},
{value: 'loc102554127', id: 'LOC102554127'},
{value: 'loc102554154', id: 'LOC102554154'},
{value: 'loc102554202', id: 'LOC102554202'},
{value: 'loc102554231', id: 'LOC102554231'},
{value: 'loc102554315', id: 'LOC102554315'},
{value: 'loc102554367', id: 'LOC102554367'},
{value: 'loc102554508', id: 'LOC102554508'},
{value: 'loc102554525', id: 'LOC102554525'},
{value: 'loc102554600', id: 'LOC102554600'},
{value: 'loc102554605', id: 'LOC102554605'},
{value: 'loc102554608', id: 'LOC102554608'},
{value: 'loc102554637', id: 'LOC102554637'},
{value: 'loc102554666', id: 'LOC102554666'},
{value: 'loc102554737', id: 'LOC102554737'},
{value: 'loc102554746', id: 'LOC102554746'},
{value: 'loc102554748', id: 'LOC102554748'},
{value: 'loc102554799', id: 'LOC102554799'},
{value: 'loc102554813', id: 'LOC102554813'},
{value: 'loc102554842', id: 'LOC102554842'},
{value: 'loc102554874', id: 'LOC102554874'},
{value: 'loc102554977', id: 'LOC102554977'},
{value: 'loc102555023', id: 'LOC102555023'},
{value: 'loc102555033', id: 'LOC102555033'},
{value: 'loc102555094', id: 'LOC102555094'},
{value: 'loc102555217', id: 'LOC102555217'},
{value: 'loc102555239', id: 'LOC102555239'},
{value: 'loc102555241', id: 'LOC102555241'},
{value: 'loc102555289', id: 'LOC102555289'},
{value: 'loc102555319', id: 'LOC102555319'},
{value: 'loc102555326', id: 'LOC102555326'},
{value: 'loc102555330', id: 'LOC102555330'},
{value: 'loc102555377', id: 'LOC102555377'},
{value: 'loc102555445', id: 'LOC102555445'},
{value: 'loc102555453', id: 'LOC102555453'},
{value: 'loc102555525', id: 'LOC102555525'},
{value: 'loc102555578', id: 'LOC102555578'},
{value: 'loc102555598', id: 'LOC102555598'},
{value: 'loc102555599', id: 'LOC102555599'},
{value: 'loc102555634', id: 'LOC102555634'},
{value: 'loc102555635', id: 'LOC102555635'},
{value: 'loc102555669', id: 'LOC102555669'},
{value: 'loc102555672', id: 'LOC102555672'},
{value: 'loc102555727', id: 'LOC102555727'},
{value: 'loc102555749', id: 'LOC102555749'},
{value: 'loc102555814', id: 'LOC102555814'},
{value: 'loc102555832', id: 'LOC102555832'},
{value: 'loc102555852', id: 'LOC102555852'},
{value: 'loc102555924', id: 'LOC102555924'},
{value: 'loc102556051', id: 'LOC102556051'},
{value: 'loc102556092', id: 'LOC102556092'},
{value: 'loc102556096', id: 'LOC102556096'},
{value: 'loc102556157', id: 'LOC102556157'},
{value: 'loc102556187', id: 'LOC102556187'},
{value: 'loc102556295', id: 'LOC102556295'},
{value: 'loc102556347', id: 'LOC102556347'},
{value: 'loc102556351', id: 'LOC102556351'},
{value: 'loc102556361', id: 'LOC102556361'},
{value: 'loc102556410', id: 'LOC102556410'},
{value: 'loc102556446', id: 'LOC102556446'},
{value: 'loc102556459', id: 'LOC102556459'},
{value: 'loc102556504', id: 'LOC102556504'},
{value: 'loc102556539', id: 'LOC102556539'},
{value: 'loc102556556', id: 'LOC102556556'},
{value: 'loc102556572', id: 'LOC102556572'},
{value: 'loc102556574', id: 'LOC102556574'},
{value: 'loc102556629', id: 'LOC102556629'},
{value: 'loc102556703', id: 'LOC102556703'},
{value: 'loc102556755', id: 'LOC102556755'},
{value: 'loc102556795', id: 'LOC102556795'},
{value: 'loc102556813', id: 'LOC102556813'},
{value: 'loc102556827', id: 'LOC102556827'},
{value: 'loc102556957', id: 'LOC102556957'},
{value: 'loc102556967', id: 'LOC102556967'},
{value: 'loc102557044', id: 'LOC102557044'},
{value: 'loc102557071', id: 'LOC102557071'},
{value: 'loc102557137', id: 'LOC102557137'},
{value: 'loc102557179', id: 'LOC102557179'},
{value: 'loc102557244', id: 'LOC102557244'},
{value: 'loc102557255', id: 'LOC102557255'},
{value: 'loc102557259', id: 'LOC102557259'},
{value: 'loc102557303', id: 'LOC102557303'},
{value: 'loc102557319', id: 'LOC102557319'},
{value: 'loc102557335', id: 'LOC102557335'},
{value: 'loc102557368', id: 'LOC102557368'},
{value: 'loc102557406', id: 'LOC102557406'},
{value: 'loc102557433', id: 'LOC102557433'},
{value: 'loc102557454', id: 'LOC102557454'},
{value: 'loc102557498', id: 'LOC102557498'},
{value: 'loc102557539', id: 'LOC102557539'},
{value: 'loc102557552', id: 'LOC102557552'},
{value: 'loc102557569', id: 'LOC102557569'},
{value: 'loc102557590', id: 'LOC102557590'},
{value: 'loc102557607', id: 'LOC102557607'},
{value: 'loc103689928', id: 'LOC103689928'},
{value: 'loc103689940', id: 'LOC103689940'},
{value: 'loc103689947', id: 'LOC103689947'},
{value: 'loc103689958', id: 'LOC103689958'},
{value: 'loc103689966', id: 'LOC103689966'},
{value: 'loc103690040', id: 'LOC103690040'},
{value: 'loc103690050', id: 'LOC103690050'},
{value: 'loc103690059', id: 'LOC103690059'},
{value: 'loc103690064', id: 'LOC103690064'},
{value: 'loc103690071', id: 'LOC103690071'},
{value: 'loc103690076', id: 'LOC103690076'},
{value: 'loc103690088', id: 'LOC103690088'},
{value: 'loc103690109', id: 'LOC103690109'},
{value: 'loc103690112', id: 'LOC103690112'},
{value: 'loc103690114', id: 'LOC103690114'},
{value: 'loc103690150', id: 'LOC103690150'},
{value: 'loc103690164', id: 'LOC103690164'},
{value: 'loc103690165', id: 'LOC103690165'},
{value: 'loc103690166', id: 'LOC103690166'},
{value: 'loc103690168', id: 'LOC103690168'},
{value: 'loc103690174', id: 'LOC103690174'},
{value: 'loc103690177', id: 'LOC103690177'},
{value: 'loc103690190', id: 'LOC103690190'},
{value: 'loc103690198', id: 'LOC103690198'},
{value: 'loc103690217', id: 'LOC103690217'},
{value: 'loc103690241', id: 'LOC103690241'},
{value: 'loc103690286', id: 'LOC103690286'},
{value: 'loc103690289', id: 'LOC103690289'},
{value: 'loc103690293', id: 'LOC103690293'},
{value: 'loc103690298', id: 'LOC103690298'},
{value: 'loc103690302', id: 'LOC103690302'},
{value: 'loc103690303', id: 'LOC103690303'},
{value: 'loc103690320', id: 'LOC103690320'},
{value: 'loc103690322', id: 'LOC103690322'},
{value: 'loc103690324', id: 'LOC103690324'},
{value: 'loc103690340', id: 'LOC103690340'},
{value: 'loc103690357', id: 'LOC103690357'},
{value: 'loc103690365', id: 'LOC103690365'},
{value: 'loc103690372', id: 'LOC103690372'},
{value: 'loc103690393', id: 'LOC103690393'},
{value: 'loc103690420', id: 'LOC103690420'},
{value: 'loc103690425', id: 'LOC103690425'},
{value: 'loc103690477', id: 'LOC103690477'},
{value: 'loc103690502', id: 'LOC103690502'},
{value: 'loc103690528', id: 'LOC103690528'},
{value: 'loc103690563', id: 'LOC103690563'},
{value: 'loc103690609', id: 'LOC103690609'},
{value: 'loc103690612', id: 'LOC103690612'},
{value: 'loc103690615', id: 'LOC103690615'},
{value: 'loc103690642', id: 'LOC103690642'},
{value: 'loc103690689', id: 'LOC103690689'},
{value: 'loc103690719', id: 'LOC103690719'},
{value: 'loc103690794', id: 'LOC103690794'},
{value: 'loc103690796', id: 'LOC103690796'},
{value: 'loc103690821', id: 'LOC103690821'},
{value: 'loc103690834', id: 'LOC103690834'},
{value: 'loc103690855', id: 'LOC103690855'},
{value: 'loc103690878', id: 'LOC103690878'},
{value: 'loc103690884', id: 'LOC103690884'},
{value: 'loc103690887', id: 'LOC103690887'},
{value: 'loc103690888', id: 'LOC103690888'},
{value: 'loc103690980', id: 'LOC103690980'},
{value: 'loc103690996', id: 'LOC103690996'},
{value: 'loc103691005', id: 'LOC103691005'},
{value: 'loc103691061', id: 'LOC103691061'},
{value: 'loc103691088', id: 'LOC103691088'},
{value: 'loc103691092', id: 'LOC103691092'},
{value: 'loc103691157', id: 'LOC103691157'},
{value: 'loc103691230', id: 'LOC103691230'},
{value: 'loc103691238', id: 'LOC103691238'},
{value: 'loc103691261', id: 'LOC103691261'},
{value: 'loc103691301', id: 'LOC103691301'},
{value: 'loc103691310', id: 'LOC103691310'},
{value: 'loc103691423', id: 'LOC103691423'},
{value: 'loc103691563', id: 'LOC103691563'},
{value: 'loc103691604', id: 'LOC103691604'},
{value: 'loc103691692', id: 'LOC103691692'},
{value: 'loc103691740', id: 'LOC103691740'},
{value: 'loc103691749', id: 'LOC103691749'},
{value: 'loc103691922', id: 'LOC103691922'},
{value: 'loc103692002', id: 'LOC103692002'},
{value: 'loc103692018', id: 'LOC103692018'},
{value: 'loc103692025', id: 'LOC103692025'},
{value: 'loc103692066', id: 'LOC103692066'},
{value: 'loc103692073', id: 'LOC103692073'},
{value: 'loc103692128', id: 'LOC103692128'},
{value: 'loc103692139', id: 'LOC103692139'},
{value: 'loc103692273', id: 'LOC103692273'},
{value: 'loc103692302', id: 'LOC103692302'},
{value: 'loc103692328', id: 'LOC103692328'},
{value: 'loc103692343', id: 'LOC103692343'},
{value: 'loc103692434', id: 'LOC103692434'},
{value: 'loc103692437', id: 'LOC103692437'},
{value: 'loc103692449', id: 'LOC103692449'},
{value: 'loc103692514', id: 'LOC103692514'},
{value: 'loc103692534', id: 'LOC103692534'},
{value: 'loc103692570', id: 'LOC103692570'},
{value: 'loc103692699', id: 'LOC103692699'},
{value: 'loc103692785', id: 'LOC103692785'},
{value: 'loc103692804', id: 'LOC103692804'},
{value: 'loc103692829', id: 'LOC103692829'},
{value: 'loc103692831', id: 'LOC103692831'},
{value: 'loc103692869', id: 'LOC103692869'},
{value: 'loc103692939', id: 'LOC103692939'},
{value: 'loc103692984', id: 'LOC103692984'},
{value: 'loc103693040', id: 'LOC103693040'},
{value: 'loc103693048', id: 'LOC103693048'},
{value: 'loc103693053', id: 'LOC103693053'},
{value: 'loc103693101', id: 'LOC103693101'},
{value: 'loc103693118', id: 'LOC103693118'},
{value: 'loc103693197', id: 'LOC103693197'},
{value: 'loc103693202', id: 'LOC103693202'},
{value: 'loc103693372', id: 'LOC103693372'},
{value: 'loc103693375', id: 'LOC103693375'},
{value: 'loc103693383', id: 'LOC103693383'},
{value: 'loc103693430', id: 'LOC103693430'},
{value: 'loc103693494', id: 'LOC103693494'},
{value: 'loc103693512', id: 'LOC103693512'},
{value: 'loc103693538', id: 'LOC103693538'},
{value: 'loc103693608', id: 'LOC103693608'},
{value: 'loc103693627', id: 'LOC103693627'},
{value: 'loc103693688', id: 'LOC103693688'},
{value: 'loc103693699', id: 'LOC103693699'},
{value: 'loc103693711', id: 'LOC103693711'},
{value: 'loc103693740', id: 'LOC103693740'},
{value: 'loc103693809', id: 'LOC103693809'},
{value: 'loc103693823', id: 'LOC103693823'},
{value: 'loc103693839', id: 'LOC103693839'},
{value: 'loc103693854', id: 'LOC103693854'},
{value: 'loc103693862', id: 'LOC103693862'},
{value: 'loc103693999', id: 'LOC103693999'},
{value: 'loc103694079', id: 'LOC103694079'},
{value: 'loc103694082', id: 'LOC103694082'},
{value: 'loc103694120', id: 'LOC103694120'},
{value: 'loc103694169', id: 'LOC103694169'},
{value: 'loc103694201', id: 'LOC103694201'},
{value: 'loc103694278', id: 'LOC103694278'},
{value: 'loc103694291', id: 'LOC103694291'},
{value: 'loc103694314', id: 'LOC103694314'},
{value: 'loc103694328', id: 'LOC103694328'},
{value: 'loc103694397', id: 'LOC103694397'},
{value: 'loc103694398', id: 'LOC103694398'},
{value: 'loc103694404', id: 'LOC103694404'},
{value: 'loc103694416', id: 'LOC103694416'},
{value: 'loc103694424', id: 'LOC103694424'},
{value: 'loc103694487', id: 'LOC103694487'},
{value: 'loc103694491', id: 'LOC103694491'},
{value: 'loc103694506', id: 'LOC103694506'},
{value: 'loc103694516', id: 'LOC103694516'},
{value: 'loc103694517', id: 'LOC103694517'},
{value: 'loc103694537', id: 'LOC103694537'},
{value: 'loc103694540', id: 'LOC103694540'},
{value: 'loc103694557', id: 'LOC103694557'},
{value: 'loc103694562', id: 'LOC103694562'},
{value: 'loc103694568', id: 'LOC103694568'},
{value: 'loc103694627', id: 'LOC103694627'},
{value: 'loc103694745', id: 'LOC103694745'},
{value: 'loc103694747', id: 'LOC103694747'},
{value: 'loc103694883', id: 'LOC103694883'},
{value: 'loc103694908', id: 'LOC103694908'},
{value: 'loc103695019', id: 'LOC103695019'},
{value: 'loc103695117', id: 'LOC103695117'},
{value: 'loc103695118', id: 'LOC103695118'},
{value: 'loc103695128', id: 'LOC103695128'},
{value: 'loc103695172', id: 'LOC103695172'},
{value: 'loc103695201', id: 'LOC103695201'},
{value: 'loc103695261', id: 'LOC103695261'},
{value: 'loc103695268', id: 'LOC103695268'},
{value: 'loc103695284', id: 'LOC103695284'},
{value: 'loc103695290', id: 'LOC103695290'},
{value: 'loc103695338', id: 'LOC103695338'},
{value: 'loc108348032', id: 'LOC108348032'},
{value: 'loc108348047', id: 'LOC108348047'},
{value: 'loc108348048', id: 'LOC108348048'},
{value: 'loc108348051', id: 'LOC108348051'},
{value: 'loc108348053', id: 'LOC108348053'},
{value: 'loc108348067', id: 'LOC108348067'},
{value: 'loc108348070', id: 'LOC108348070'},
{value: 'loc108348081', id: 'LOC108348081'},
{value: 'loc108348090', id: 'LOC108348090'},
{value: 'loc108348096', id: 'LOC108348096'},
{value: 'loc108348103', id: 'LOC108348103'},
{value: 'loc108348105', id: 'LOC108348105'},
{value: 'loc108348108', id: 'LOC108348108'},
{value: 'loc108348130', id: 'LOC108348130'},
{value: 'loc108348137', id: 'LOC108348137'},
{value: 'loc108348163', id: 'LOC108348163'},
{value: 'loc108348180', id: 'LOC108348180'},
{value: 'loc108348190', id: 'LOC108348190'},
{value: 'loc108348192', id: 'LOC108348192'},
{value: 'loc108348197', id: 'LOC108348197'},
{value: 'loc108348201', id: 'LOC108348201'},
{value: 'loc108348215', id: 'LOC108348215'},
{value: 'loc108348229', id: 'LOC108348229'},
{value: 'loc108348235', id: 'LOC108348235'},
{value: 'loc108348240', id: 'LOC108348240'},
{value: 'loc108348242', id: 'LOC108348242'},
{value: 'loc108348244', id: 'LOC108348244'},
{value: 'loc108348257', id: 'LOC108348257'},
{value: 'loc108348261', id: 'LOC108348261'},
{value: 'loc108348266', id: 'LOC108348266'},
{value: 'loc108348268', id: 'LOC108348268'},
{value: 'loc108348270', id: 'LOC108348270'},
{value: 'loc108348276', id: 'LOC108348276'},
{value: 'loc108348280', id: 'LOC108348280'},
{value: 'loc108348287', id: 'LOC108348287'},
{value: 'loc108348290', id: 'LOC108348290'},
{value: 'loc108348293', id: 'LOC108348293'},
{value: 'loc108348294', id: 'LOC108348294'},
{value: 'loc108348298', id: 'LOC108348298'},
{value: 'loc108348299', id: 'LOC108348299'},
{value: 'loc108348301', id: 'LOC108348301'},
{value: 'loc108348302', id: 'LOC108348302'},
{value: 'loc108348307', id: 'LOC108348307'},
{value: 'loc108348308', id: 'LOC108348308'},
{value: 'loc108348323', id: 'LOC108348323'},
{value: 'loc108348326', id: 'LOC108348326'},
{value: 'loc108348338', id: 'LOC108348338'},
{value: 'loc108348356', id: 'LOC108348356'},
{value: 'loc108348409', id: 'LOC108348409'},
{value: 'loc108348426', id: 'LOC108348426'},
{value: 'loc108348433', id: 'LOC108348433'},
{value: 'loc108348453', id: 'LOC108348453'},
{value: 'loc108348472', id: 'LOC108348472'},
{value: 'loc108348474', id: 'LOC108348474'},
{value: 'loc108348568', id: 'LOC108348568'},
{value: 'loc108348617', id: 'LOC108348617'},
{value: 'loc108348739', id: 'LOC108348739'},
{value: 'loc108348752', id: 'LOC108348752'},
{value: 'loc108348781', id: 'LOC108348781'},
{value: 'loc108348882', id: 'LOC108348882'},
{value: 'loc108348883', id: 'LOC108348883'},
{value: 'loc108349002', id: 'LOC108349002'},
{value: 'loc108349009', id: 'LOC108349009'},
{value: 'loc108349010', id: 'LOC108349010'},
{value: 'loc108349076', id: 'LOC108349076'},
{value: 'loc108349135', id: 'LOC108349135'},
{value: 'loc108349143', id: 'LOC108349143'},
{value: 'loc108349189', id: 'LOC108349189'},
{value: 'loc108349228', id: 'LOC108349228'},
{value: 'loc108349244', id: 'LOC108349244'},
{value: 'loc108349258', id: 'LOC108349258'},
{value: 'loc108349290', id: 'LOC108349290'},
{value: 'loc108349340', id: 'LOC108349340'},
{value: 'loc108349347', id: 'LOC108349347'},
{value: 'loc108349399', id: 'LOC108349399'},
{value: 'loc108349408', id: 'LOC108349408'},
{value: 'loc108349419', id: 'LOC108349419'},
{value: 'loc108349468', id: 'LOC108349468'},
{value: 'loc108349479', id: 'LOC108349479'},
{value: 'loc108349548', id: 'LOC108349548'},
{value: 'loc108349573', id: 'LOC108349573'},
{value: 'loc108349606', id: 'LOC108349606'},
{value: 'loc108349632', id: 'LOC108349632'},
{value: 'loc108349637', id: 'LOC108349637'},
{value: 'loc108349660', id: 'LOC108349660'},
{value: 'loc108349682', id: 'LOC108349682'},
{value: 'loc108349732', id: 'LOC108349732'},
{value: 'loc108349745', id: 'LOC108349745'},
{value: 'loc108349752', id: 'LOC108349752'},
{value: 'loc108349760', id: 'LOC108349760'},
{value: 'loc108349761', id: 'LOC108349761'},
{value: 'loc108349768', id: 'LOC108349768'},
{value: 'loc108349777', id: 'LOC108349777'},
{value: 'loc108349792', id: 'LOC108349792'},
{value: 'loc108349922', id: 'LOC108349922'},
{value: 'loc108349943', id: 'LOC108349943'},
{value: 'loc108349946', id: 'LOC108349946'},
{value: 'loc108350020', id: 'LOC108350020'},
{value: 'loc108350036', id: 'LOC108350036'},
{value: 'loc108350059', id: 'LOC108350059'},
{value: 'loc108350128', id: 'LOC108350128'},
{value: 'loc108350198', id: 'LOC108350198'},
{value: 'loc108350225', id: 'LOC108350225'},
{value: 'loc108350226', id: 'LOC108350226'},
{value: 'loc108350227', id: 'LOC108350227'},
{value: 'loc108350228', id: 'LOC108350228'},
{value: 'loc108350369', id: 'LOC108350369'},
{value: 'loc108350407', id: 'LOC108350407'},
{value: 'loc108350427', id: 'LOC108350427'},
{value: 'loc108350501', id: 'LOC108350501'},
{value: 'loc108350502', id: 'LOC108350502'},
{value: 'loc108350518', id: 'LOC108350518'},
{value: 'loc108350528', id: 'LOC108350528'},
{value: 'loc108350575', id: 'LOC108350575'},
{value: 'loc108350707', id: 'LOC108350707'},
{value: 'loc108350709', id: 'LOC108350709'},
{value: 'loc108350822', id: 'LOC108350822'},
{value: 'loc108350839', id: 'LOC108350839'},
{value: 'loc108350852', id: 'LOC108350852'},
{value: 'loc108350970', id: 'LOC108350970'},
{value: 'loc108351043', id: 'LOC108351043'},
{value: 'loc108351058', id: 'LOC108351058'},
{value: 'loc108351063', id: 'LOC108351063'},
{value: 'loc108351077', id: 'LOC108351077'},
{value: 'loc108351090', id: 'LOC108351090'},
{value: 'loc108351097', id: 'LOC108351097'},
{value: 'loc108351104', id: 'LOC108351104'},
{value: 'loc108351130', id: 'LOC108351130'},
{value: 'loc108351137', id: 'LOC108351137'},
{value: 'loc108351155', id: 'LOC108351155'},
{value: 'loc108351255', id: 'LOC108351255'},
{value: 'loc108351351', id: 'LOC108351351'},
{value: 'loc108351358', id: 'LOC108351358'},
{value: 'loc108351365', id: 'LOC108351365'},
{value: 'loc108351370', id: 'LOC108351370'},
{value: 'loc108351377', id: 'LOC108351377'},
{value: 'loc108351411', id: 'LOC108351411'},
{value: 'loc108351450', id: 'LOC108351450'},
{value: 'loc108351482', id: 'LOC108351482'},
{value: 'loc108351520', id: 'LOC108351520'},
{value: 'loc108351524', id: 'LOC108351524'},
{value: 'loc108351533', id: 'LOC108351533'},
{value: 'loc108351567', id: 'LOC108351567'},
{value: 'loc108351576', id: 'LOC108351576'},
{value: 'loc108351581', id: 'LOC108351581'},
{value: 'loc108351584', id: 'LOC108351584'},
{value: 'loc108351589', id: 'LOC108351589'},
{value: 'loc108351601', id: 'LOC108351601'},
{value: 'loc108351645', id: 'LOC108351645'},
{value: 'loc108351693', id: 'LOC108351693'},
{value: 'loc108351703', id: 'LOC108351703'},
{value: 'loc108351723', id: 'LOC108351723'},
{value: 'loc108351807', id: 'LOC108351807'},
{value: 'loc108351811', id: 'LOC108351811'},
{value: 'loc108351902', id: 'LOC108351902'},
{value: 'loc108351965', id: 'LOC108351965'},
{value: 'loc108351994', id: 'LOC108351994'},
{value: 'loc108352003', id: 'LOC108352003'},
{value: 'loc108352109', id: 'LOC108352109'},
{value: 'loc108352134', id: 'LOC108352134'},
{value: 'loc108352174', id: 'LOC108352174'},
{value: 'loc108352189', id: 'LOC108352189'},
{value: 'loc108352191', id: 'LOC108352191'},
{value: 'loc108352207', id: 'LOC108352207'},
{value: 'loc108352225', id: 'LOC108352225'},
{value: 'loc108352322', id: 'LOC108352322'},
{value: 'loc108352348', id: 'LOC108352348'},
{value: 'loc108352445', id: 'LOC108352445'},
{value: 'loc108352523', id: 'LOC108352523'},
{value: 'loc108352551', id: 'LOC108352551'},
{value: 'loc108352591', id: 'LOC108352591'},
{value: 'loc108352616', id: 'LOC108352616'},
{value: 'loc108352618', id: 'LOC108352618'},
{value: 'loc108352627', id: 'LOC108352627'},
{value: 'loc108352650', id: 'LOC108352650'},
{value: 'loc108352688', id: 'LOC108352688'},
{value: 'loc108352699', id: 'LOC108352699'},
{value: 'loc108352727', id: 'LOC108352727'},
{value: 'loc108352742', id: 'LOC108352742'},
{value: 'loc108352756', id: 'LOC108352756'},
{value: 'loc108352818', id: 'LOC108352818'},
{value: 'loc108352829', id: 'LOC108352829'},
{value: 'loc108352861', id: 'LOC108352861'},
{value: 'loc108352875', id: 'LOC108352875'},
{value: 'loc108352926', id: 'LOC108352926'},
{value: 'loc108352928', id: 'LOC108352928'},
{value: 'loc108353015', id: 'LOC108353015'},
{value: 'loc108353037', id: 'LOC108353037'},
{value: 'loc108353098', id: 'LOC108353098'},
{value: 'loc108353135', id: 'LOC108353135'},
{value: 'loc108353146', id: 'LOC108353146'},
{value: 'loc108353166', id: 'LOC108353166'},
{value: 'loc108353264', id: 'LOC108353264'},
{value: 'loc108353386', id: 'LOC108353386'},
{value: 'loc108353410', id: 'LOC108353410'},
{value: 'loc108353421', id: 'LOC108353421'},
{value: 'loc108353429', id: 'LOC108353429'},
{value: 'loc108353446', id: 'LOC108353446'},
{value: 'loc108353458', id: 'LOC108353458'},
{value: 'loc108353494', id: 'LOC108353494'},
{value: 'loc108353604', id: 'LOC108353604'},
{value: 'loc108353628', id: 'LOC108353628'},
{value: 'loc108353631', id: 'LOC108353631'},
{value: 'loc108353656', id: 'LOC108353656'},
{value: 'loc108353703', id: 'LOC108353703'},
{value: 'loc108353737', id: 'LOC108353737'},
{value: 'loc108353746', id: 'LOC108353746'},
{value: 'loc108353753', id: 'LOC108353753'},
{value: 'loc108353758', id: 'LOC108353758'},
{value: 'loc108353765', id: 'LOC108353765'},
{value: 'loc108353795', id: 'LOC108353795'},
{value: 'loc108353809', id: 'LOC108353809'},
{value: 'loc120093089', id: 'LOC120093089'},
{value: 'loc120093097', id: 'LOC120093097'},
{value: 'loc171573', id: 'LOC171573'},
{value: 'loc24906', id: 'LOC24906'},
{value: 'loc252831', id: 'LOC252831'},
{value: 'loc252890', id: 'LOC252890'},
{value: 'loc257643', id: 'LOC257643'},
{value: 'loc257650', id: 'LOC257650'},
{value: 'loc259244', id: 'LOC259244'},
{value: 'loc259245', id: 'LOC259245'},
{value: 'loc259246', id: 'LOC259246'},
{value: 'loc286960', id: 'LOC286960'},
{value: 'loc286987', id: 'LOC286987'},
{value: 'loc288521', id: 'LOC288521'},
{value: 'loc288803', id: 'LOC288803'},
{value: 'loc288913', id: 'LOC288913'},
{value: 'loc288978', id: 'LOC288978'},
{value: 'loc290209', id: 'LOC290209'},
{value: 'loc290595', id: 'LOC290595'},
{value: 'loc290876', id: 'LOC290876'},
{value: 'loc291276', id: 'LOC291276'},
{value: 'loc291863', id: 'LOC291863'},
{value: 'loc292543', id: 'LOC292543'},
{value: 'loc296235', id: 'LOC296235'},
{value: 'loc297568', id: 'LOC297568'},
{value: 'loc297756', id: 'LOC297756'},
{value: 'loc298111', id: 'LOC298111'},
{value: 'loc298116', id: 'LOC298116'},
{value: 'loc298139', id: 'LOC298139'},
{value: 'loc298795', id: 'LOC298795'},
{value: 'loc299277', id: 'LOC299277'},
{value: 'loc299282', id: 'LOC299282'},
{value: 'loc299312', id: 'LOC299312'},
{value: 'loc300024', id: 'LOC300024'},
{value: 'loc300249', id: 'LOC300249'},
{value: 'loc300303', id: 'LOC300303'},
{value: 'loc300308', id: 'LOC300308'},
{value: 'loc301165', id: 'LOC301165'},
{value: 'loc301444', id: 'LOC301444'},
{value: 'loc302192', id: 'LOC302192'},
{value: 'loc302576', id: 'LOC302576'},
{value: 'loc302827', id: 'LOC302827'},
{value: 'loc303140', id: 'LOC303140'},
{value: 'loc303448', id: 'LOC303448'},
{value: 'loc304725', id: 'LOC304725'},
{value: 'loc306079', id: 'LOC306079'},
{value: 'loc306766', id: 'LOC306766'},
{value: 'loc307727', id: 'LOC307727'},
{value: 'loc308990', id: 'LOC308990'},
{value: 'loc309927', id: 'LOC309927'},
{value: 'loc310177', id: 'LOC310177'},
{value: 'loc310926', id: 'LOC310926'},
{value: 'loc312273', id: 'LOC312273'},
{value: 'loc314123', id: 'LOC314123'},
{value: 'loc314140', id: 'LOC314140'},
{value: 'loc314407', id: 'LOC314407'},
{value: 'loc316124', id: 'LOC316124'},
{value: 'loc317165', id: 'LOC317165'},
{value: 'loc317456', id: 'LOC317456'},
{value: 'loc317588', id: 'LOC317588'},
{value: 'loc360919', id: 'LOC360919'},
{value: 'loc360933', id: 'LOC360933'},
{value: 'loc360998', id: 'LOC360998'},
{value: 'loc361346', id: 'LOC361346'},
{value: 'loc361559', id: 'LOC361559'},
{value: 'loc361914', id: 'LOC361914'},
{value: 'loc361985', id: 'LOC361985'},
{value: 'loc362901', id: 'LOC362901'},
{value: 'loc362921', id: 'LOC362921'},
{value: 'loc363337', id: 'LOC363337'},
{value: 'loc365238', id: 'LOC365238'},
{value: 'loc365287', id: 'LOC365287'},
{value: 'loc365778', id: 'LOC365778'},
{value: 'loc365791', id: 'LOC365791'},
{value: 'loc365839', id: 'LOC365839'},
{value: 'loc365949', id: 'LOC365949'},
{value: 'loc367050', id: 'LOC367050'},
{value: 'loc367117', id: 'LOC367117'},
{value: 'loc367195', id: 'LOC367195'},
{value: 'loc367515', id: 'LOC367515'},
{value: 'loc367516', id: 'LOC367516'},
{value: 'loc367746', id: 'LOC367746'},
{value: 'loc367830', id: 'LOC367830'},
{value: 'loc367975', id: 'LOC367975'},
{value: 'loc497796', id: 'LOC497796'},
{value: 'loc497940', id: 'LOC497940'},
{value: 'loc498084', id: 'LOC498084'},
{value: 'loc498122', id: 'LOC498122'},
{value: 'loc498152', id: 'LOC498152'},
{value: 'loc498154', id: 'LOC498154'},
{value: 'loc498155', id: 'LOC498155'},
{value: 'loc498222', id: 'LOC498222'},
{value: 'loc498231', id: 'LOC498231'},
{value: 'loc498236', id: 'LOC498236'},
{value: 'loc498265', id: 'LOC498265'},
{value: 'loc498276', id: 'LOC498276'},
{value: 'loc498300', id: 'LOC498300'},
{value: 'loc498308', id: 'LOC498308'},
{value: 'loc498339', id: 'LOC498339'},
{value: 'loc498368', id: 'LOC498368'},
{value: 'loc498414', id: 'LOC498414'},
{value: 'loc498424', id: 'LOC498424'},
{value: 'loc498453', id: 'LOC498453'},
{value: 'loc498460', id: 'LOC498460'},
{value: 'loc498555', id: 'LOC498555'},
{value: 'loc498592', id: 'LOC498592'},
{value: 'loc498675', id: 'LOC498675'},
{value: 'loc498705', id: 'LOC498705'},
{value: 'loc498750', id: 'LOC498750'},
{value: 'loc498759', id: 'LOC498759'},
{value: 'loc498826', id: 'LOC498826'},
{value: 'loc498836', id: 'LOC498836'},
{value: 'loc498912', id: 'LOC498912'},
{value: 'loc498933', id: 'LOC498933'},
{value: 'loc499136', id: 'LOC499136'},
{value: 'loc499179', id: 'LOC499179'},
{value: 'loc499219', id: 'LOC499219'},
{value: 'loc499235', id: 'LOC499235'},
{value: 'loc499240', id: 'LOC499240'},
{value: 'loc499276', id: 'LOC499276'},
{value: 'loc499331', id: 'LOC499331'},
{value: 'loc499407', id: 'LOC499407'},
{value: 'loc499530', id: 'LOC499530'},
{value: 'loc499541', id: 'LOC499541'},
{value: 'loc499542', id: 'LOC499542'},
{value: 'loc499544', id: 'LOC499544'},
{value: 'loc499565', id: 'LOC499565'},
{value: 'loc499573', id: 'LOC499573'},
{value: 'loc499584', id: 'LOC499584'},
{value: 'loc499643', id: 'LOC499643'},
{value: 'loc499644', id: 'LOC499644'},
{value: 'loc499715', id: 'LOC499715'},
{value: 'loc499742', id: 'LOC499742'},
{value: 'loc499796', id: 'LOC499796'},
{value: 'loc499823', id: 'LOC499823'},
{value: 'loc499843', id: 'LOC499843'},
{value: 'loc499886', id: 'LOC499886'},
{value: 'loc500007', id: 'LOC500007'},
{value: 'loc500028', id: 'LOC500028'},
{value: 'loc500035', id: 'LOC500035'},
{value: 'loc500124', id: 'LOC500124'},
{value: 'loc500148', id: 'LOC500148'},
{value: 'loc500227', id: 'LOC500227'},
{value: 'loc500265', id: 'LOC500265'},
{value: 'loc500273', id: 'LOC500273'},
{value: 'loc500331', id: 'LOC500331'},
{value: 'loc500350', id: 'LOC500350'},
{value: 'loc500354', id: 'LOC500354'},
{value: 'loc500460', id: 'LOC500460'},
{value: 'loc500473', id: 'LOC500473'},
{value: 'loc500475', id: 'LOC500475'},
{value: 'loc500567', id: 'LOC500567'},
{value: 'loc500584', id: 'LOC500584'},
{value: 'loc500594', id: 'LOC500594'},
{value: 'loc500684', id: 'LOC500684'},
{value: 'loc500702', id: 'LOC500702'},
{value: 'loc500712', id: 'LOC500712'},
{value: 'loc500827', id: 'LOC500827'},
{value: 'loc500845', id: 'LOC500845'},
{value: 'loc500846', id: 'LOC500846'},
{value: 'loc500876', id: 'LOC500876'},
{value: 'loc500877', id: 'LOC500877'},
{value: 'loc500948', id: 'LOC500948'},
{value: 'loc500959', id: 'LOC500959'},
{value: 'loc500990', id: 'LOC500990'},
{value: 'loc501038', id: 'LOC501038'},
{value: 'loc501116', id: 'LOC501116'},
{value: 'loc501233', id: 'LOC501233'},
{value: 'loc501297', id: 'LOC501297'},
{value: 'loc501396', id: 'LOC501396'},
{value: 'loc501406', id: 'LOC501406'},
{value: 'loc501416', id: 'LOC501416'},
{value: 'loc501456', id: 'LOC501456'},
{value: 'loc501467', id: 'LOC501467'},
{value: 'loc501618', id: 'LOC501618'},
{value: 'loc501698', id: 'LOC501698'},
{value: 'loc502109', id: 'LOC502109'},
{value: 'loc502176', id: 'LOC502176'},
{value: 'loc502358', id: 'LOC502358'},
{value: 'loc502618', id: 'LOC502618'},
{value: 'loc502660', id: 'LOC502660'},
{value: 'loc502684', id: 'LOC502684'},
{value: 'loc502907', id: 'LOC502907'},
{value: 'loc502908', id: 'LOC502908'},
{value: 'loc503053', id: 'LOC503053'},
{value: 'loc503455', id: 'LOC503455'},
{value: 'loc678817', id: 'LOC678817'},
{value: 'loc679087', id: 'LOC679087'},
{value: 'loc679129', id: 'LOC679129'},
{value: 'loc679149', id: 'LOC679149'},
{value: 'loc679356', id: 'LOC679356'},
{value: 'loc679368', id: 'LOC679368'},
{value: 'loc679539', id: 'LOC679539'},
{value: 'loc679565', id: 'LOC679565'},
{value: 'loc679581', id: 'LOC679581'},
{value: 'loc679594', id: 'LOC679594'},
{value: 'loc679711', id: 'LOC679711'},
{value: 'loc679731', id: 'LOC679731'},
{value: 'loc679739', id: 'LOC679739'},
{value: 'loc679748', id: 'LOC679748'},
{value: 'loc679803', id: 'LOC679803'},
{value: 'loc679822', id: 'LOC679822'},
{value: 'loc679863', id: 'LOC679863'},
{value: 'loc679881', id: 'LOC679881'},
{value: 'loc679894', id: 'LOC679894'},
{value: 'loc679924', id: 'LOC679924'},
{value: 'loc679960', id: 'LOC679960'},
{value: 'loc680039', id: 'LOC680039'},
{value: 'loc680045', id: 'LOC680045'},
{value: 'loc680121', id: 'LOC680121'},
{value: 'loc680142', id: 'LOC680142'},
{value: 'loc680190', id: 'LOC680190'},
{value: 'loc680200', id: 'LOC680200'},
{value: 'loc680227', id: 'LOC680227'},
{value: 'loc680254', id: 'LOC680254'},
{value: 'loc680316', id: 'LOC680316'},
{value: 'loc680329', id: 'LOC680329'},
{value: 'loc680346', id: 'LOC680346'},
{value: 'loc680353', id: 'LOC680353'},
{value: 'loc680377', id: 'LOC680377'},
{value: 'loc680385', id: 'LOC680385'},
{value: 'loc680406', id: 'LOC680406'},
{value: 'loc680428', id: 'LOC680428'},
{value: 'loc680432', id: 'LOC680432'},
{value: 'loc680442', id: 'LOC680442'},
{value: 'loc680491', id: 'LOC680491'},
{value: 'loc680512', id: 'LOC680512'},
{value: 'loc680520', id: 'LOC680520'},
{value: 'loc680565', id: 'LOC680565'},
{value: 'loc680590', id: 'LOC680590'},
{value: 'loc680624', id: 'LOC680624'},
{value: 'loc680700', id: 'LOC680700'},
{value: 'loc680875', id: 'LOC680875'},
{value: 'loc680885', id: 'LOC680885'},
{value: 'loc680913', id: 'LOC680913'},
{value: 'loc680920', id: 'LOC680920'},
{value: 'loc680955', id: 'LOC680955'},
{value: 'loc680959', id: 'LOC680959'},
{value: 'loc680989', id: 'LOC680989'},
{value: 'loc681006', id: 'LOC681006'},
{value: 'loc681067', id: 'LOC681067'},
{value: 'loc681107', id: 'LOC681107'},
{value: 'loc681117', id: 'LOC681117'},
{value: 'loc681177', id: 'LOC681177'},
{value: 'loc681182', id: 'LOC681182'},
{value: 'loc681241', id: 'LOC681241'},
{value: 'loc681282', id: 'LOC681282'},
{value: 'loc681300', id: 'LOC681300'},
{value: 'loc681314', id: 'LOC681314'},
{value: 'loc681325', id: 'LOC681325'},
{value: 'loc681355', id: 'LOC681355'},
{value: 'loc681367', id: 'LOC681367'},
{value: 'loc681382', id: 'LOC681382'},
{value: 'loc681385', id: 'LOC681385'},
{value: 'loc681410', id: 'LOC681410'},
{value: 'loc681458', id: 'LOC681458'},
{value: 'loc681470', id: 'LOC681470'},
{value: 'loc681544', id: 'LOC681544'},
{value: 'loc682042', id: 'LOC682042'},
{value: 'loc682102', id: 'LOC682102'},
{value: 'loc682206', id: 'LOC682206'},
{value: 'loc682225', id: 'LOC682225'},
{value: 'loc682402', id: 'LOC682402'},
{value: 'loc682419', id: 'LOC682419'},
{value: 'loc682793', id: 'LOC682793'},
{value: 'loc682831', id: 'LOC682831'},
{value: 'loc683212', id: 'LOC683212'},
{value: 'loc683295', id: 'LOC683295'},
{value: 'loc683313', id: 'LOC683313'},
{value: 'loc683420', id: 'LOC683420'},
{value: 'loc683422', id: 'LOC683422'},
{value: 'loc683430', id: 'LOC683430'},
{value: 'loc683469', id: 'LOC683469'},
{value: 'loc683600', id: 'LOC683600'},
{value: 'loc683674', id: 'LOC683674'},
{value: 'loc683761', id: 'LOC683761'},
{value: 'loc684107', id: 'LOC684107'},
{value: 'loc684170', id: 'LOC684170'},
{value: 'loc684171', id: 'LOC684171'},
{value: 'loc684179', id: 'LOC684179'},
{value: 'loc684193', id: 'LOC684193'},
{value: 'loc684208', id: 'LOC684208'},
{value: 'loc684270', id: 'LOC684270'},
{value: 'loc684365', id: 'LOC684365'},
{value: 'loc684376', id: 'LOC684376'},
{value: 'loc684444', id: 'LOC684444'},
{value: 'loc684466', id: 'LOC684466'},
{value: 'loc684471', id: 'LOC684471'},
{value: 'loc684509', id: 'LOC684509'},
{value: 'loc684539', id: 'LOC684539'},
{value: 'loc684683', id: 'LOC684683'},
{value: 'loc684709', id: 'LOC684709'},
{value: 'loc684773', id: 'LOC684773'},
{value: 'loc684797', id: 'LOC684797'},
{value: 'loc684970', id: 'LOC684970'},
{value: 'loc684988', id: 'LOC684988'},
{value: 'loc685025', id: 'LOC685025'},
{value: 'loc685067', id: 'LOC685067'},
{value: 'loc685069', id: 'LOC685069'},
{value: 'loc685085', id: 'LOC685085'},
{value: 'loc685108', id: 'LOC685108'},
{value: 'loc685157', id: 'LOC685157'},
{value: 'loc685171', id: 'LOC685171'},
{value: 'loc685184', id: 'LOC685184'},
{value: 'loc685199', id: 'LOC685199'},
{value: 'loc685203', id: 'LOC685203'},
{value: 'loc685273', id: 'LOC685273'},
{value: 'loc685279', id: 'LOC685279'},
{value: 'loc685321', id: 'LOC685321'},
{value: 'loc685351', id: 'LOC685351'},
{value: 'loc685391', id: 'LOC685391'},
{value: 'loc685406', id: 'LOC685406'},
{value: 'loc685431', id: 'LOC685431'},
{value: 'loc685488', id: 'LOC685488'},
{value: 'loc685544', id: 'LOC685544'},
{value: 'loc685564', id: 'LOC685564'},
{value: 'loc685590', id: 'LOC685590'},
{value: 'loc685619', id: 'LOC685619'},
{value: 'loc685655', id: 'LOC685655'},
{value: 'loc685680', id: 'LOC685680'},
{value: 'loc685699', id: 'LOC685699'},
{value: 'loc685700', id: 'LOC685700'},
{value: 'loc685716', id: 'LOC685716'},
{value: 'loc685718', id: 'LOC685718'},
{value: 'loc685747', id: 'LOC685747'},
{value: 'loc685762', id: 'LOC685762'},
{value: 'loc685767', id: 'LOC685767'},
{value: 'loc685789', id: 'LOC685789'},
{value: 'loc685793', id: 'LOC685793'},
{value: 'loc685796', id: 'LOC685796'},
{value: 'loc685828', id: 'LOC685828'},
{value: 'loc685849', id: 'LOC685849'},
{value: 'loc685881', id: 'LOC685881'},
{value: 'loc685883', id: 'LOC685883'},
{value: 'loc685907', id: 'LOC685907'},
{value: 'loc685933', id: 'LOC685933'},
{value: 'loc685963', id: 'LOC685963'},
{value: 'loc685964', id: 'LOC685964'},
{value: 'loc686013', id: 'LOC686013'},
{value: 'loc686031', id: 'LOC686031'},
{value: 'loc686042', id: 'LOC686042'},
{value: 'loc686074', id: 'LOC686074'},
{value: 'loc686087', id: 'LOC686087'},
{value: 'loc686129', id: 'LOC686129'},
{value: 'loc686142', id: 'LOC686142'},
{value: 'loc686143', id: 'LOC686143'},
{value: 'loc686632', id: 'LOC686632'},
{value: 'loc686660', id: 'LOC686660'},
{value: 'loc686677', id: 'LOC686677'},
{value: 'loc686683', id: 'LOC686683'},
{value: 'loc686720', id: 'LOC686720'},
{value: 'loc686748', id: 'LOC686748'},
{value: 'loc687035', id: 'LOC687035'},
{value: 'loc687065', id: 'LOC687065'},
{value: 'loc687082', id: 'LOC687082'},
{value: 'loc687088', id: 'LOC687088'},
{value: 'loc687097', id: 'LOC687097'},
{value: 'loc687119', id: 'LOC687119'},
{value: 'loc687399', id: 'LOC687399'},
{value: 'loc687465', id: 'LOC687465'},
{value: 'loc687532', id: 'LOC687532'},
{value: 'loc687560', id: 'LOC687560'},
{value: 'loc687631', id: 'LOC687631'},
{value: 'loc687679', id: 'LOC687679'},
{value: 'loc687707', id: 'LOC687707'},
{value: 'loc687780', id: 'LOC687780'},
{value: 'loc687881', id: 'LOC687881'},
{value: 'loc687896', id: 'LOC687896'},
{value: 'loc688022', id: 'LOC688022'},
{value: 'loc688064', id: 'LOC688064'},
{value: 'loc688090', id: 'LOC688090'},
{value: 'loc688282', id: 'LOC688282'},
{value: 'loc688286', id: 'LOC688286'},
{value: 'loc688320', id: 'LOC688320'},
{value: 'loc688321', id: 'LOC688321'},
{value: 'loc688335', id: 'LOC688335'},
{value: 'loc688389', id: 'LOC688389'},
{value: 'loc688390', id: 'LOC688390'},
{value: 'loc688442', id: 'LOC688442'},
{value: 'loc688459', id: 'LOC688459'},
{value: 'loc688542', id: 'LOC688542'},
{value: 'loc688553', id: 'LOC688553'},
{value: 'loc688613', id: 'LOC688613'},
{value: 'loc688649', id: 'LOC688649'},
{value: 'loc688655', id: 'LOC688655'},
{value: 'loc688672', id: 'LOC688672'},
{value: 'loc688684', id: 'LOC688684'},
{value: 'loc688708', id: 'LOC688708'},
{value: 'loc688712', id: 'LOC688712'},
{value: 'loc688754', id: 'LOC688754'},
{value: 'loc688778', id: 'LOC688778'},
{value: 'loc688812', id: 'LOC688812'},
{value: 'loc688815', id: 'LOC688815'},
{value: 'loc688828', id: 'LOC688828'},
{value: 'loc688842', id: 'LOC688842'},
{value: 'loc688924', id: 'LOC688924'},
{value: 'loc688925', id: 'LOC688925'},
{value: 'loc688932', id: 'LOC688932'},
{value: 'loc688980', id: 'LOC688980'},
{value: 'loc688981', id: 'LOC688981'},
{value: 'loc689039', id: 'LOC689039'},
{value: 'loc689042', id: 'LOC689042'},
{value: 'loc689065', id: 'LOC689065'},
{value: 'loc689081', id: 'LOC689081'},
{value: 'loc689130', id: 'LOC689130'},
{value: 'loc689220', id: 'LOC689220'},
{value: 'loc689230', id: 'LOC689230'},
{value: 'loc689396', id: 'LOC689396'},
{value: 'loc689408', id: 'LOC689408'},
{value: 'loc689412', id: 'LOC689412'},
{value: 'loc689439', id: 'LOC689439'},
{value: 'loc689458', id: 'LOC689458'},
{value: 'loc689479', id: 'LOC689479'},
{value: 'loc689519', id: 'LOC689519'},
{value: 'loc689526', id: 'LOC689526'},
{value: 'loc689569', id: 'LOC689569'},
{value: 'loc689587', id: 'LOC689587'},
{value: 'loc689589', id: 'LOC689589'},
{value: 'loc689599', id: 'LOC689599'},
{value: 'loc689600', id: 'LOC689600'},
{value: 'loc689618', id: 'LOC689618'},
{value: 'loc689629', id: 'LOC689629'},
{value: 'loc689713', id: 'LOC689713'},
{value: 'loc689725', id: 'LOC689725'},
{value: 'loc689728', id: 'LOC689728'},
{value: 'loc689757', id: 'LOC689757'},
{value: 'loc689766', id: 'LOC689766'},
{value: 'loc689803', id: 'LOC689803'},
{value: 'loc689899', id: 'LOC689899'},
{value: 'loc689955', id: 'LOC689955'},
{value: 'loc689959', id: 'LOC689959'},
{value: 'loc689968', id: 'LOC689968'},
{value: 'loc689986', id: 'LOC689986'},
{value: 'loc690000', id: 'LOC690000'},
{value: 'loc690020', id: 'LOC690020'},
{value: 'loc690035', id: 'LOC690035'},
{value: 'loc690045', id: 'LOC690045'},
{value: 'loc690097', id: 'LOC690097'},
{value: 'loc690114', id: 'LOC690114'},
{value: 'loc690126', id: 'LOC690126'},
{value: 'loc690140', id: 'LOC690140'},
{value: 'loc690142', id: 'LOC690142'},
{value: 'loc690171', id: 'LOC690171'},
{value: 'loc690182', id: 'LOC690182'},
{value: 'loc690183', id: 'LOC690183'},
{value: 'loc690206', id: 'LOC690206'},
{value: 'loc690235', id: 'LOC690235'},
{value: 'loc690251', id: 'LOC690251'},
{value: 'loc690276', id: 'LOC690276'},
{value: 'loc690283', id: 'LOC690283'},
{value: 'loc690284', id: 'LOC690284'},
{value: 'loc690299', id: 'LOC690299'},
{value: 'loc690348', id: 'LOC690348'},
{value: 'loc690352', id: 'LOC690352'},
{value: 'loc690384', id: 'LOC690384'},
{value: 'loc690386', id: 'LOC690386'},
{value: 'loc690414', id: 'LOC690414'},
{value: 'loc690435', id: 'LOC690435'},
{value: 'loc690460', id: 'LOC690460'},
{value: 'loc690468', id: 'LOC690468'},
{value: 'loc690478', id: 'LOC690478'},
{value: 'loc690507', id: 'LOC690507'},
{value: 'loc690617', id: 'LOC690617'},
{value: 'loc690675', id: 'LOC690675'},
{value: 'loc690784', id: 'LOC690784'},
{value: 'loc690813', id: 'LOC690813'},
{value: 'loc690821', id: 'LOC690821'},
{value: 'loc691000', id: 'LOC691000'},
{value: 'loc691030', id: 'LOC691030'},
{value: 'loc691033', id: 'LOC691033'},
{value: 'loc691083', id: 'LOC691083'},
{value: 'loc691113', id: 'LOC691113'},
{value: 'loc691135', id: 'LOC691135'},
{value: 'loc691141', id: 'LOC691141'},
{value: 'loc691143', id: 'LOC691143'},
{value: 'loc691170', id: 'LOC691170'},
{value: 'loc691189', id: 'LOC691189'},
{value: 'loc691196', id: 'LOC691196'},
{value: 'loc691215', id: 'LOC691215'},
{value: 'loc691254', id: 'LOC691254'},
{value: 'loc691257', id: 'LOC691257'},
{value: 'loc691272', id: 'LOC691272'},
{value: 'loc691277', id: 'LOC691277'},
{value: 'loc691286', id: 'LOC691286'},
{value: 'loc691311', id: 'LOC691311'},
{value: 'loc691325', id: 'LOC691325'},
{value: 'loc691352', id: 'LOC691352'},
{value: 'loc691354', id: 'LOC691354'},
{value: 'loc691368', id: 'LOC691368'},
{value: 'loc691387', id: 'LOC691387'},
{value: 'loc691418', id: 'LOC691418'},
{value: 'loc691422', id: 'LOC691422'},
{value: 'loc691427', id: 'LOC691427'},
{value: 'loc691485', id: 'LOC691485'},
{value: 'loc691519', id: 'LOC691519'},
{value: 'loc691520', id: 'LOC691520'},
{value: 'loc691546', id: 'LOC691546'},
{value: 'loc691551', id: 'LOC691551'},
{value: 'loc691603', id: 'LOC691603'},
{value: 'loc691642', id: 'LOC691642'},
{value: 'loc691658', id: 'LOC691658'},
{value: 'loc691661', id: 'LOC691661'},
{value: 'loc691670', id: 'LOC691670'},
{value: 'loc691716', id: 'LOC691716'},
{value: 'loc691807', id: 'LOC691807'},
{value: 'loc691850', id: 'LOC691850'},
{value: 'loc691895', id: 'LOC691895'},
{value: 'loc691921', id: 'LOC691921'},
{value: 'loc691960', id: 'LOC691960'},
{value: 'loc691970', id: 'LOC691970'},
{value: 'loc691995', id: 'LOC691995'},
{value: 'lonp1', id: 'LONP1'},
{value: 'lonp2', id: 'LONP2'},
{value: 'lonrf1', id: 'LONRF1'},
{value: 'lonrf2', id: 'LONRF2'},
{value: 'lonrf3', id: 'LONRF3'},
{value: 'loricrin', id: 'LORICRIN'},
{value: 'lox', id: 'LOX'},
{value: 'loxhd1', id: 'LOXHD1'},
{value: 'loxl1', id: 'LOXL1'},
{value: 'loxl2', id: 'LOXL2'},
{value: 'loxl3', id: 'LOXL3'},
{value: 'loxl4', id: 'LOXL4'},
{value: 'lpal2', id: 'LPAL2'},
{value: 'lpar1', id: 'LPAR1'},
{value: 'lpar2', id: 'LPAR2'},
{value: 'lpar3', id: 'LPAR3'},
{value: 'lpar4', id: 'LPAR4'},
{value: 'lpar5', id: 'LPAR5'},
{value: 'lpar6', id: 'LPAR6'},
{value: 'lpcat1', id: 'LPCAT1'},
{value: 'lpcat2', id: 'LPCAT2'},
{value: 'lpcat2b', id: 'LPCAT2B'},
{value: 'lpcat3', id: 'LPCAT3'},
{value: 'lpcat4', id: 'LPCAT4'},
{value: 'lpgat1', id: 'LPGAT1'},
{value: 'lpin1', id: 'LPIN1'},
{value: 'lpin2', id: 'LPIN2'},
{value: 'lpin3', id: 'LPIN3'},
{value: 'lpl', id: 'LPL'},
{value: 'lpo', id: 'LPO'},
{value: 'lpp', id: 'LPP'},
{value: 'lpxn', id: 'LPXN'},
{value: 'lrat', id: 'LRAT'},
{value: 'lratd1', id: 'LRATD1'},
{value: 'lratd2', id: 'LRATD2'},
{value: 'lrba', id: 'LRBA'},
{value: 'lrch1', id: 'LRCH1'},
{value: 'lrch2', id: 'LRCH2'},
{value: 'lrch3', id: 'LRCH3'},
{value: 'lrch4', id: 'LRCH4'},
{value: 'lrcol1', id: 'LRCOL1'},
{value: 'lrfn1', id: 'LRFN1'},
{value: 'lrfn2', id: 'LRFN2'},
{value: 'lrfn3', id: 'LRFN3'},
{value: 'lrfn4', id: 'LRFN4'},
{value: 'lrfn5', id: 'LRFN5'},
{value: 'lrg1', id: 'LRG1'},
{value: 'lrguk', id: 'LRGUK'},
{value: 'lrif1', id: 'LRIF1'},
{value: 'lrig1', id: 'LRIG1'},
{value: 'lrig2', id: 'LRIG2'},
{value: 'lrig3', id: 'LRIG3'},
{value: 'lrit1', id: 'LRIT1'},
{value: 'lrit2', id: 'LRIT2'},
{value: 'lrit3', id: 'LRIT3'},
{value: 'lrmda', id: 'LRMDA'},
{value: 'lrp1', id: 'LRP1'},
{value: 'lrp10', id: 'LRP10'},
{value: 'lrp11', id: 'LRP11'},
{value: 'lrp12', id: 'LRP12'},
{value: 'lrp1b', id: 'LRP1B'},
{value: 'lrp2', id: 'LRP2'},
{value: 'lrp2bp', id: 'LRP2BP'},
{value: 'lrp3', id: 'LRP3'},
{value: 'lrp4', id: 'LRP4'},
{value: 'lrp5', id: 'LRP5'},
{value: 'lrp6', id: 'LRP6'},
{value: 'lrp8', id: 'LRP8'},
{value: 'lrpap1', id: 'LRPAP1'},
{value: 'lrpprc', id: 'LRPPRC'},
{value: 'lrr1', id: 'LRR1'},
{value: 'lrrc1', id: 'LRRC1'},
{value: 'lrrc10', id: 'LRRC10'},
{value: 'lrrc10b', id: 'LRRC10B'},
{value: 'lrrc14', id: 'LRRC14'},
{value: 'lrrc14b', id: 'LRRC14B'},
{value: 'lrrc15', id: 'LRRC15'},
{value: 'lrrc17', id: 'LRRC17'},
{value: 'lrrc18', id: 'LRRC18'},
{value: 'lrrc19', id: 'LRRC19'},
{value: 'lrrc2', id: 'LRRC2'},
{value: 'lrrc20', id: 'LRRC20'},
{value: 'lrrc23', id: 'LRRC23'},
{value: 'lrrc24', id: 'LRRC24'},
{value: 'lrrc25', id: 'LRRC25'},
{value: 'lrrc26', id: 'LRRC26'},
{value: 'lrrc27', id: 'LRRC27'},
{value: 'lrrc28', id: 'LRRC28'},
{value: 'lrrc29', id: 'LRRC29'},
{value: 'lrrc3', id: 'LRRC3'},
{value: 'lrrc30', id: 'LRRC30'},
{value: 'lrrc31', id: 'LRRC31'},
{value: 'lrrc32', id: 'LRRC32'},
{value: 'lrrc34', id: 'LRRC34'},
{value: 'lrrc36', id: 'LRRC36'},
{value: 'lrrc37a', id: 'LRRC37A'},
{value: 'lrrc38', id: 'LRRC38'},
{value: 'lrrc39', id: 'LRRC39'},
{value: 'lrrc3b', id: 'LRRC3B'},
{value: 'lrrc3c', id: 'LRRC3C'},
{value: 'lrrc4', id: 'LRRC4'},
{value: 'lrrc40', id: 'LRRC40'},
{value: 'lrrc41', id: 'LRRC41'},
{value: 'lrrc42', id: 'LRRC42'},
{value: 'lrrc43', id: 'LRRC43'},
{value: 'lrrc45', id: 'LRRC45'},
{value: 'lrrc46', id: 'LRRC46'},
{value: 'lrrc47', id: 'LRRC47'},
{value: 'lrrc49', id: 'LRRC49'},
{value: 'lrrc4b', id: 'LRRC4B'},
{value: 'lrrc4c', id: 'LRRC4C'},
{value: 'lrrc51', id: 'LRRC51'},
{value: 'lrrc52', id: 'LRRC52'},
{value: 'lrrc53', id: 'LRRC53'},
{value: 'lrrc55', id: 'LRRC55'},
{value: 'lrrc56', id: 'LRRC56'},
{value: 'lrrc57', id: 'LRRC57'},
{value: 'lrrc58', id: 'LRRC58'},
{value: 'lrrc59', id: 'LRRC59'},
{value: 'lrrc61', id: 'LRRC61'},
{value: 'lrrc63', id: 'LRRC63'},
{value: 'lrrc66', id: 'LRRC66'},
{value: 'lrrc69', id: 'LRRC69'},
{value: 'lrrc7', id: 'LRRC7'},
{value: 'lrrc71', id: 'LRRC71'},
{value: 'lrrc72', id: 'LRRC72'},
{value: 'lrrc73', id: 'LRRC73'},
{value: 'lrrc74a', id: 'LRRC74A'},
{value: 'lrrc74b', id: 'LRRC74B'},
{value: 'lrrc75a', id: 'LRRC75A'},
{value: 'lrrc75b', id: 'LRRC75B'},
{value: 'lrrc8a', id: 'LRRC8A'},
{value: 'lrrc8b', id: 'LRRC8B'},
{value: 'lrrc8c', id: 'LRRC8C'},
{value: 'lrrc8d', id: 'LRRC8D'},
{value: 'lrrc8e', id: 'LRRC8E'},
{value: 'lrrc9', id: 'LRRC9'},
{value: 'lrrcc1', id: 'LRRCC1'},
{value: 'lrrd1', id: 'LRRD1'},
{value: 'lrrfip1', id: 'LRRFIP1'},
{value: 'lrrfip2', id: 'LRRFIP2'},
{value: 'lrriq1', id: 'LRRIQ1'},
{value: 'lrriq3', id: 'LRRIQ3'},
{value: 'lrriq4', id: 'LRRIQ4'},
{value: 'lrrk1', id: 'LRRK1'},
{value: 'lrrk2', id: 'LRRK2'},
{value: 'lrrn1', id: 'LRRN1'},
{value: 'lrrn2', id: 'LRRN2'},
{value: 'lrrn3', id: 'LRRN3'},
{value: 'lrrn4', id: 'LRRN4'},
{value: 'lrrn4cl', id: 'LRRN4CL'},
{value: 'lrrtm1', id: 'LRRTM1'},
{value: 'lrrtm2', id: 'LRRTM2'},
{value: 'lrrtm3', id: 'LRRTM3'},
{value: 'lrrtm4', id: 'LRRTM4'},
{value: 'lrsam1', id: 'LRSAM1'},
{value: 'lrtm2', id: 'LRTM2'},
{value: 'lrtomt', id: 'LRTOMT'},
{value: 'lrwd1', id: 'LRWD1'},
{value: 'lsamp', id: 'LSAMP'},
{value: 'lsg1', id: 'LSG1'},
{value: 'lsm1', id: 'LSM1'},
{value: 'lsm10', id: 'LSM10'},
{value: 'lsm11', id: 'LSM11'},
{value: 'lsm12', id: 'LSM12'},
{value: 'lsm14a', id: 'LSM14A'},
{value: 'lsm14b', id: 'LSM14B'},
{value: 'lsm2', id: 'LSM2'},
{value: 'lsm3', id: 'LSM3'},
{value: 'lsm4', id: 'LSM4'},
{value: 'lsm5', id: 'LSM5'},
{value: 'lsm6', id: 'LSM6'},
{value: 'lsm7', id: 'LSM7'},
{value: 'lsm8', id: 'LSM8'},
{value: 'lsmem1', id: 'LSMEM1'},
{value: 'lsmem2', id: 'LSMEM2'},
{value: 'lsp1', id: 'LSP1'},
{value: 'lsr', id: 'LSR'},
{value: 'lss', id: 'LSS'},
{value: 'lst1', id: 'LST1'},
{value: 'lta', id: 'LTA'},
{value: 'lta4h', id: 'LTA4H'},
{value: 'ltb', id: 'LTB'},
{value: 'ltb4r', id: 'LTB4R'},
{value: 'ltb4r2', id: 'LTB4R2'},
{value: 'ltbp1', id: 'LTBP1'},
{value: 'ltbp2', id: 'LTBP2'},
{value: 'ltbp3', id: 'LTBP3'},
{value: 'ltbp4', id: 'LTBP4'},
{value: 'ltbr', id: 'LTBR'},
{value: 'ltc4s', id: 'LTC4S'},
{value: 'ltf', id: 'LTF'},
{value: 'ltk', id: 'LTK'},
{value: 'ltn1', id: 'LTN1'},
{value: 'lto1', id: 'LTO1'},
{value: 'ltv1', id: 'LTV1'},
{value: 'luc7l', id: 'LUC7L'},
{value: 'luc7l2', id: 'LUC7L2'},
{value: 'luc7l3', id: 'LUC7L3'},
{value: 'lum', id: 'LUM'},
{value: 'lurap1', id: 'LURAP1'},
{value: 'lurap1l', id: 'LURAP1L'},
{value: 'luzp1', id: 'LUZP1'},
{value: 'luzp2', id: 'LUZP2'},
{value: 'luzp4', id: 'LUZP4'},
{value: 'lvrn', id: 'LVRN'},
{value: 'lxn', id: 'LXN'},
{value: 'ly49i2', id: 'LY49I2'},
{value: 'ly49i4', id: 'LY49I4'},
{value: 'ly49i7', id: 'LY49I7'},
{value: 'ly49s3', id: 'LY49S3'},
{value: 'ly49s4', id: 'LY49S4'},
{value: 'ly49s5', id: 'LY49S5'},
{value: 'ly49s6', id: 'LY49S6'},
{value: 'ly49s7', id: 'LY49S7'},
{value: 'ly49si1', id: 'LY49SI1'},
{value: 'ly49si2', id: 'LY49SI2'},
{value: 'ly49si3', id: 'LY49SI3'},
{value: 'ly6al', id: 'LY6AL'},
{value: 'ly6c', id: 'LY6C'},
{value: 'ly6d', id: 'LY6D'},
{value: 'ly6e', id: 'LY6E'},
{value: 'ly6g5b', id: 'LY6G5B'},
{value: 'ly6g5c', id: 'LY6G5C'},
{value: 'ly6g6c', id: 'LY6G6C'},
{value: 'ly6g6d', id: 'LY6G6D'},
{value: 'ly6g6e', id: 'LY6G6E'},
{value: 'ly6g6f', id: 'LY6G6F'},
{value: 'ly6g6g', id: 'LY6G6G'},
{value: 'ly6h', id: 'LY6H'},
{value: 'ly6i', id: 'LY6I'},
{value: 'ly6k', id: 'LY6K'},
{value: 'ly6l', id: 'LY6L'},
{value: 'ly75', id: 'LY75'},
{value: 'ly86', id: 'LY86'},
{value: 'ly9', id: 'LY9'},
{value: 'ly96', id: 'LY96'},
{value: 'lyar', id: 'LYAR'},
{value: 'lyc2', id: 'LYC2'},
{value: 'lyg1', id: 'LYG1'},
{value: 'lyl1', id: 'LYL1'},
{value: 'lyn', id: 'LYN'},
{value: 'lynx1', id: 'LYNX1'},
{value: 'lypd1', id: 'LYPD1'},
{value: 'lypd2', id: 'LYPD2'},
{value: 'lypd3', id: 'LYPD3'},
{value: 'lypd4', id: 'LYPD4'},
{value: 'lypd5', id: 'LYPD5'},
{value: 'lypd6', id: 'LYPD6'},
{value: 'lypd6b', id: 'LYPD6B'},
{value: 'lypd8', id: 'LYPD8'},
{value: 'lypla1', id: 'LYPLA1'},
{value: 'lypla2', id: 'LYPLA2'},
{value: 'lyplal1', id: 'LYPLAL1'},
{value: 'lyrm1', id: 'LYRM1'},
{value: 'lyrm2', id: 'LYRM2'},
{value: 'lyrm4', id: 'LYRM4'},
{value: 'lyrm7', id: 'LYRM7'},
{value: 'lyrm9', id: 'LYRM9'},
{value: 'lysmd1', id: 'LYSMD1'},
{value: 'lysmd2', id: 'LYSMD2'},
{value: 'lysmd3', id: 'LYSMD3'},
{value: 'lysmd4', id: 'LYSMD4'},
{value: 'lyst', id: 'LYST'},
{value: 'lyve1', id: 'LYVE1'},
{value: 'lyz2', id: 'LYZ2'},
{value: 'lyzl1', id: 'LYZL1'},
{value: 'lyzl4', id: 'LYZL4'},
{value: 'lyzl6', id: 'LYZL6'},
{value: 'lzic', id: 'LZIC'},
{value: 'lztfl1', id: 'LZTFL1'},
{value: 'lztr1', id: 'LZTR1'},
{value: 'lzts1', id: 'LZTS1'},
{value: 'lzts2', id: 'LZTS2'},
{value: 'lzts3', id: 'LZTS3'},
{value: 'm1ap', id: 'M1AP'},
{value: 'm6pr', id: 'M6PR'},
{value: 'mab21l1', id: 'MAB21L1'},
{value: 'mab21l2', id: 'MAB21L2'},
{value: 'mab21l3', id: 'MAB21L3'},
{value: 'mab21l4', id: 'MAB21L4'},
{value: 'macc1', id: 'MACC1'},
{value: 'macf1', id: 'MACF1'},
{value: 'macir', id: 'MACIR'},
{value: 'maco1', id: 'MACO1'},
{value: 'macrod1', id: 'MACROD1'},
{value: 'macrod2', id: 'MACROD2'},
{value: 'macroh2a1', id: 'MACROH2A1'},
{value: 'macroh2a2', id: 'MACROH2A2'},
{value: 'mad1l1', id: 'MAD1L1'},
{value: 'mad2l1', id: 'MAD2L1'},
{value: 'mad2l1bp', id: 'MAD2L1BP'},
{value: 'mad2l2', id: 'MAD2L2'},
{value: 'madcam1', id: 'MADCAM1'},
{value: 'madd', id: 'MADD'},
{value: 'maea', id: 'MAEA'},
{value: 'mael', id: 'MAEL'},
{value: 'maf', id: 'MAF'},
{value: 'maf1', id: 'MAF1'},
{value: 'mafa', id: 'MAFA'},
{value: 'mafb', id: 'MAFB'},
{value: 'maff', id: 'MAFF'},
{value: 'mafg', id: 'MAFG'},
{value: 'mafk', id: 'MAFK'},
{value: 'mag', id: 'MAG'},
{value: 'magea10', id: 'MAGEA10'},
{value: 'magea13', id: 'MAGEA13'},
{value: 'magea4', id: 'MAGEA4'},
{value: 'magea8', id: 'MAGEA8'},
{value: 'magea9', id: 'MAGEA9'},
{value: 'mageb16', id: 'MAGEB16'},
{value: 'mageb18', id: 'MAGEB18'},
{value: 'mageb1l1', id: 'MAGEB1L1'},
{value: 'mageb2', id: 'MAGEB2'},
{value: 'mageb3', id: 'MAGEB3'},
{value: 'mageb4', id: 'MAGEB4'},
{value: 'mageb5', id: 'MAGEB5'},
{value: 'mageb6', id: 'MAGEB6'},
{value: 'mageb7', id: 'MAGEB7'},
{value: 'magebl1', id: 'MAGEBL1'},
{value: 'magec2', id: 'MAGEC2'},
{value: 'maged1', id: 'MAGED1'},
{value: 'maged2', id: 'MAGED2'},
{value: 'magee1', id: 'MAGEE1'},
{value: 'magee2', id: 'MAGEE2'},
{value: 'mageh1', id: 'MAGEH1'},
{value: 'magel2', id: 'MAGEL2'},
{value: 'magi1', id: 'MAGI1'},
{value: 'magi2', id: 'MAGI2'},
{value: 'magi3', id: 'MAGI3'},
{value: 'magix', id: 'MAGIX'},
{value: 'magoh', id: 'MAGOH'},
{value: 'magohb', id: 'MAGOHB'},
{value: 'magt1', id: 'MAGT1'},
{value: 'maip1', id: 'MAIP1'},
{value: 'majin', id: 'MAJIN'},
{value: 'mak', id: 'MAK'},
{value: 'mak16', id: 'MAK16'},
{value: 'mal', id: 'MAL'},
{value: 'mal2', id: 'MAL2'},
{value: 'mall', id: 'MALL'},
{value: 'malrd1', id: 'MALRD1'},
{value: 'malsu1', id: 'MALSU1'},
{value: 'malt1', id: 'MALT1'},
{value: 'mamdc2', id: 'MAMDC2'},
{value: 'mamdc4', id: 'MAMDC4'},
{value: 'maml1', id: 'MAML1'},
{value: 'maml2', id: 'MAML2'},
{value: 'maml3', id: 'MAML3'},
{value: 'mamstr', id: 'MAMSTR'},
{value: 'man1a1', id: 'MAN1A1'},
{value: 'man1a2', id: 'MAN1A2'},
{value: 'man1b1', id: 'MAN1B1'},
{value: 'man1c1', id: 'MAN1C1'},
{value: 'man2a1', id: 'MAN2A1'},
{value: 'man2a2', id: 'MAN2A2'},
{value: 'man2b1', id: 'MAN2B1'},
{value: 'man2b2', id: 'MAN2B2'},
{value: 'man2c1', id: 'MAN2C1'},
{value: 'manba', id: 'MANBA'},
{value: 'manbal', id: 'MANBAL'},
{value: 'manea', id: 'MANEA'},
{value: 'maneal', id: 'MANEAL'},
{value: 'manf', id: 'MANF'},
{value: 'mansc1', id: 'MANSC1'},
{value: 'mansc4', id: 'MANSC4'},
{value: 'maoa', id: 'MAOA'},
{value: 'maob', id: 'MAOB'},
{value: 'map10', id: 'MAP10'},
{value: 'map1a', id: 'MAP1A'},
{value: 'map1b', id: 'MAP1B'},
{value: 'map1lc3a', id: 'MAP1LC3A'},
{value: 'map1lc3b', id: 'MAP1LC3B'},
{value: 'map1lc3b2', id: 'MAP1LC3B2'},
{value: 'map1s', id: 'MAP1S'},
{value: 'map2', id: 'MAP2'},
{value: 'map2k1', id: 'MAP2K1'},
{value: 'map2k2', id: 'MAP2K2'},
{value: 'map2k3', id: 'MAP2K3'},
{value: 'map2k4', id: 'MAP2K4'},
{value: 'map2k5', id: 'MAP2K5'},
{value: 'map2k6', id: 'MAP2K6'},
{value: 'map2k7', id: 'MAP2K7'},
{value: 'map3k1', id: 'MAP3K1'},
{value: 'map3k10', id: 'MAP3K10'},
{value: 'map3k11', id: 'MAP3K11'},
{value: 'map3k12', id: 'MAP3K12'},
{value: 'map3k13', id: 'MAP3K13'},
{value: 'map3k14', id: 'MAP3K14'},
{value: 'map3k15', id: 'MAP3K15'},
{value: 'map3k19', id: 'MAP3K19'},
{value: 'map3k2', id: 'MAP3K2'},
{value: 'map3k20', id: 'MAP3K20'},
{value: 'map3k21', id: 'MAP3K21'},
{value: 'map3k3', id: 'MAP3K3'},
{value: 'map3k4', id: 'MAP3K4'},
{value: 'map3k5', id: 'MAP3K5'},
{value: 'map3k6', id: 'MAP3K6'},
{value: 'map3k7', id: 'MAP3K7'},
{value: 'map3k7cl', id: 'MAP3K7CL'},
{value: 'map3k8', id: 'MAP3K8'},
{value: 'map3k9', id: 'MAP3K9'},
{value: 'map4', id: 'MAP4'},
{value: 'map4k1', id: 'MAP4K1'},
{value: 'map4k2', id: 'MAP4K2'},
{value: 'map4k3', id: 'MAP4K3'},
{value: 'map4k4', id: 'MAP4K4'},
{value: 'map4k5', id: 'MAP4K5'},
{value: 'map6', id: 'MAP6'},
{value: 'map6d1', id: 'MAP6D1'},
{value: 'map7', id: 'MAP7'},
{value: 'map7d1', id: 'MAP7D1'},
{value: 'map7d2', id: 'MAP7D2'},
{value: 'map7d3', id: 'MAP7D3'},
{value: 'map9', id: 'MAP9'},
{value: 'mapk1', id: 'MAPK1'},
{value: 'mapk10', id: 'MAPK10'},
{value: 'mapk11', id: 'MAPK11'},
{value: 'mapk12', id: 'MAPK12'},
{value: 'mapk13', id: 'MAPK13'},
{value: 'mapk14', id: 'MAPK14'},
{value: 'mapk15', id: 'MAPK15'},
{value: 'mapk1ip1', id: 'MAPK1IP1'},
{value: 'mapk1ip1l', id: 'MAPK1IP1L'},
{value: 'mapk3', id: 'MAPK3'},
{value: 'mapk4', id: 'MAPK4'},
{value: 'mapk6', id: 'MAPK6'},
{value: 'mapk7', id: 'MAPK7'},
{value: 'mapk8', id: 'MAPK8'},
{value: 'mapk8ip1', id: 'MAPK8IP1'},
{value: 'mapk8ip2', id: 'MAPK8IP2'},
{value: 'mapk8ip3', id: 'MAPK8IP3'},
{value: 'mapk9', id: 'MAPK9'},
{value: 'mapkap1', id: 'MAPKAP1'},
{value: 'mapkapk2', id: 'MAPKAPK2'},
{value: 'mapkapk3', id: 'MAPKAPK3'},
{value: 'mapkapk5', id: 'MAPKAPK5'},
{value: 'mapkbp1', id: 'MAPKBP1'},
{value: 'mapre1', id: 'MAPRE1'},
{value: 'mapre2', id: 'MAPRE2'},
{value: 'mapre3', id: 'MAPRE3'},
{value: 'mapt', id: 'MAPT'},
{value: 'marchf1', id: 'MARCHF1'},
{value: 'marchf10', id: 'MARCHF10'},
{value: 'marchf11', id: 'MARCHF11'},
{value: 'marchf2', id: 'MARCHF2'},
{value: 'marchf3', id: 'MARCHF3'},
{value: 'marchf4', id: 'MARCHF4'},
{value: 'marchf5', id: 'MARCHF5'},
{value: 'marchf6', id: 'MARCHF6'},
{value: 'marchf7', id: 'MARCHF7'},
{value: 'marchf8', id: 'MARCHF8'},
{value: 'marcks', id: 'MARCKS'},
{value: 'marcksl1', id: 'MARCKSL1'},
{value: 'marco', id: 'MARCO'},
{value: 'marf1', id: 'MARF1'},
{value: 'mark1', id: 'MARK1'},
{value: 'mark2', id: 'MARK2'},
{value: 'mark3', id: 'MARK3'},
{value: 'mark4', id: 'MARK4'},
{value: 'mars1', id: 'MARS1'},
{value: 'mars2', id: 'MARS2'},
{value: 'marveld1', id: 'MARVELD1'},
{value: 'marveld2', id: 'MARVELD2'},
{value: 'marveld3', id: 'MARVELD3'},
{value: 'mas1', id: 'MAS1'},
{value: 'mas1l', id: 'MAS1L'},
{value: 'masp1', id: 'MASP1'},
{value: 'masp2', id: 'MASP2'},
{value: 'mast1', id: 'MAST1'},
{value: 'mast2', id: 'MAST2'},
{value: 'mast3', id: 'MAST3'},
{value: 'mast4', id: 'MAST4'},
{value: 'mastl', id: 'MASTL'},
{value: 'mat1a', id: 'MAT1A'},
{value: 'mat2a', id: 'MAT2A'},
{value: 'mat2b', id: 'MAT2B'},
{value: 'matk', id: 'MATK'},
{value: 'matn1', id: 'MATN1'},
{value: 'matn2', id: 'MATN2'},
{value: 'matn3', id: 'MATN3'},
{value: 'matn4', id: 'MATN4'},
{value: 'matr3', id: 'MATR3'},
{value: 'mau2', id: 'MAU2'},
{value: 'mavs', id: 'MAVS'},
{value: 'max', id: 'MAX'},
{value: 'maz', id: 'MAZ'},
{value: 'mb', id: 'MB'},
{value: 'mb21d2', id: 'MB21D2'},
{value: 'mbd1', id: 'MBD1'},
{value: 'mbd2', id: 'MBD2'},
{value: 'mbd3', id: 'MBD3'},
{value: 'mbd3l1', id: 'MBD3L1'},
{value: 'mbd3l2', id: 'MBD3L2'},
{value: 'mbd3l2l', id: 'MBD3L2L'},
{value: 'mbd4', id: 'MBD4'},
{value: 'mbd5', id: 'MBD5'},
{value: 'mbd6', id: 'MBD6'},
{value: 'mbip', id: 'MBIP'},
{value: 'mbl1', id: 'MBL1'},
{value: 'mbl2', id: 'MBL2'},
{value: 'mblac1', id: 'MBLAC1'},
{value: 'mblac2', id: 'MBLAC2'},
{value: 'mbnl1', id: 'MBNL1'},
{value: 'mbnl2', id: 'MBNL2'},
{value: 'mbnl3', id: 'MBNL3'},
{value: 'mboat1', id: 'MBOAT1'},
{value: 'mboat2', id: 'MBOAT2'},
{value: 'mboat4', id: 'MBOAT4'},
{value: 'mboat7', id: 'MBOAT7'},
{value: 'mboat7l1', id: 'MBOAT7L1'},
{value: 'mbp', id: 'MBP'},
{value: 'mbtd1', id: 'MBTD1'},
{value: 'mbtps1', id: 'MBTPS1'},
{value: 'mbtps2', id: 'MBTPS2'},
{value: 'mc2r', id: 'MC2R'},
{value: 'mc3r', id: 'MC3R'},
{value: 'mc4r', id: 'MC4R'},
{value: 'mc5r', id: 'MC5R'},
{value: 'mcam', id: 'MCAM'},
{value: 'mcat', id: 'MCAT'},
{value: 'mcc', id: 'MCC'},
{value: 'mccc1', id: 'MCCC1'},
{value: 'mccc2', id: 'MCCC2'},
{value: 'mcee', id: 'MCEE'},
{value: 'mcemp1', id: 'MCEMP1'},
{value: 'mcf2', id: 'MCF2'},
{value: 'mcf2l', id: 'MCF2L'},
{value: 'mcfd2', id: 'MCFD2'},
{value: 'mchr1', id: 'MCHR1'},
{value: 'mcidas', id: 'MCIDAS'},
{value: 'mcl1', id: 'MCL1'},
{value: 'mcm10', id: 'MCM10'},
{value: 'mcm2', id: 'MCM2'},
{value: 'mcm3', id: 'MCM3'},
{value: 'mcm3ap', id: 'MCM3AP'},
{value: 'mcm4', id: 'MCM4'},
{value: 'mcm5', id: 'MCM5'},
{value: 'mcm6', id: 'MCM6'},
{value: 'mcm7', id: 'MCM7'},
{value: 'mcm8', id: 'MCM8'},
{value: 'mcm9', id: 'MCM9'},
{value: 'mcmbp', id: 'MCMBP'},
{value: 'mcmdc2', id: 'MCMDC2'},
{value: 'mcoln1', id: 'MCOLN1'},
{value: 'mcoln2', id: 'MCOLN2'},
{value: 'mcoln3', id: 'MCOLN3'},
{value: 'mcph1', id: 'MCPH1'},
{value: 'mcpt1', id: 'MCPT1'},
{value: 'mcpt10', id: 'MCPT10'},
{value: 'mcpt1l1', id: 'MCPT1L1'},
{value: 'mcpt1l2', id: 'MCPT1L2'},
{value: 'mcpt1l3', id: 'MCPT1L3'},
{value: 'mcpt1l4', id: 'MCPT1L4'},
{value: 'mcpt2', id: 'MCPT2'},
{value: 'mcpt3', id: 'MCPT3'},
{value: 'mcpt4', id: 'MCPT4'},
{value: 'mcpt8', id: 'MCPT8'},
{value: 'mcpt8l2', id: 'MCPT8L2'},
{value: 'mcpt8l3', id: 'MCPT8L3'},
{value: 'mcpt9', id: 'MCPT9'},
{value: 'mcrip1', id: 'MCRIP1'},
{value: 'mcrip2', id: 'MCRIP2'},
{value: 'mcrs1', id: 'MCRS1'},
{value: 'mctp1', id: 'MCTP1'},
{value: 'mctp2', id: 'MCTP2'},
{value: 'mcts1', id: 'MCTS1'},
{value: 'mcts2', id: 'MCTS2'},
{value: 'mcu', id: 'MCU'},
{value: 'mcub', id: 'MCUB'},
{value: 'mcur1', id: 'MCUR1'},
{value: 'mdc1', id: 'MDC1'},
{value: 'mdfi', id: 'MDFI'},
{value: 'mdfic', id: 'MDFIC'},
{value: 'mdfic2', id: 'MDFIC2'},
{value: 'mdga1', id: 'MDGA1'},
{value: 'mdga2', id: 'MDGA2'},
{value: 'mdh1', id: 'MDH1'},
{value: 'mdh1b', id: 'MDH1B'},
{value: 'mdh2', id: 'MDH2'},
{value: 'mdk', id: 'MDK'},
{value: 'mdm1', id: 'MDM1'},
{value: 'mdm2', id: 'MDM2'},
{value: 'mdm4', id: 'MDM4'},
{value: 'mdn1', id: 'MDN1'},
{value: 'mdp1', id: 'MDP1'},
{value: 'me1', id: 'ME1'},
{value: 'me2', id: 'ME2'},
{value: 'me3', id: 'ME3'},
{value: 'mea1', id: 'MEA1'},
{value: 'meaf6', id: 'MEAF6'},
{value: 'meak7', id: 'MEAK7'},
{value: 'mecom', id: 'MECOM'},
{value: 'mecp2', id: 'MECP2'},
{value: 'mecr', id: 'MECR'},
{value: 'med1', id: 'MED1'},
{value: 'med10', id: 'MED10'},
{value: 'med11', id: 'MED11'},
{value: 'med12', id: 'MED12'},
{value: 'med12l', id: 'MED12L'},
{value: 'med13', id: 'MED13'},
{value: 'med13l', id: 'MED13L'},
{value: 'med14', id: 'MED14'},
{value: 'med15', id: 'MED15'},
{value: 'med16', id: 'MED16'},
{value: 'med17', id: 'MED17'},
{value: 'med18', id: 'MED18'},
{value: 'med19', id: 'MED19'},
{value: 'med20', id: 'MED20'},
{value: 'med21', id: 'MED21'},
{value: 'med22', id: 'MED22'},
{value: 'med23', id: 'MED23'},
{value: 'med24', id: 'MED24'},
{value: 'med25', id: 'MED25'},
{value: 'med26', id: 'MED26'},
{value: 'med27', id: 'MED27'},
{value: 'med28', id: 'MED28'},
{value: 'med29', id: 'MED29'},
{value: 'med30', id: 'MED30'},
{value: 'med31', id: 'MED31'},
{value: 'med4', id: 'MED4'},
{value: 'med6', id: 'MED6'},
{value: 'med7', id: 'MED7'},
{value: 'med8', id: 'MED8'},
{value: 'med9', id: 'MED9'},
{value: 'medag', id: 'MEDAG'},
{value: 'mef2a', id: 'MEF2A'},
{value: 'mef2b', id: 'MEF2B'},
{value: 'mef2c', id: 'MEF2C'},
{value: 'mef2d', id: 'MEF2D'},
{value: 'mefv', id: 'MEFV'},
{value: 'megf10', id: 'MEGF10'},
{value: 'megf11', id: 'MEGF11'},
{value: 'megf6', id: 'MEGF6'},
{value: 'megf8', id: 'MEGF8'},
{value: 'megf9', id: 'MEGF9'},
{value: 'mei1', id: 'MEI1'},
{value: 'mei4', id: 'MEI4'},
{value: 'meig1', id: 'MEIG1'},
{value: 'meikin', id: 'MEIKIN'},
{value: 'meiob', id: 'MEIOB'},
{value: 'meioc', id: 'MEIOC'},
{value: 'meis1', id: 'MEIS1'},
{value: 'meis2', id: 'MEIS2'},
{value: 'meis3', id: 'MEIS3'},
{value: 'melk', id: 'MELK'},
{value: 'meltf', id: 'MELTF'},
{value: 'memo1', id: 'MEMO1'},
{value: 'men1', id: 'MEN1'},
{value: 'meox1', id: 'MEOX1'},
{value: 'meox2', id: 'MEOX2'},
{value: 'mep1a', id: 'MEP1A'},
{value: 'mep1b', id: 'MEP1B'},
{value: 'mepce', id: 'MEPCE'},
{value: 'mepe', id: 'MEPE'},
{value: 'mertk', id: 'MERTK'},
{value: 'mesd', id: 'MESD'},
{value: 'mesp1', id: 'MESP1'},
{value: 'mesp2', id: 'MESP2'},
{value: 'mest', id: 'MEST'},
{value: 'met', id: 'MET'},
{value: 'metap1', id: 'METAP1'},
{value: 'metap1d', id: 'METAP1D'},
{value: 'metap2', id: 'METAP2'},
{value: 'metazoa_srp', id: 'METAZOA_SRP'},
{value: 'metrn', id: 'METRN'},
{value: 'metrnl', id: 'METRNL'},
{value: 'mettl1', id: 'METTL1'},
{value: 'mettl13', id: 'METTL13'},
{value: 'mettl14', id: 'METTL14'},
{value: 'mettl15', id: 'METTL15'},
{value: 'mettl16', id: 'METTL16'},
{value: 'mettl17', id: 'METTL17'},
{value: 'mettl18', id: 'METTL18'},
{value: 'mettl2', id: 'METTL2'},
{value: 'mettl21a', id: 'METTL21A'},
{value: 'mettl21c', id: 'METTL21C'},
{value: 'mettl21cl1', id: 'METTL21CL1'},
{value: 'mettl22', id: 'METTL22'},
{value: 'mettl23', id: 'METTL23'},
{value: 'mettl24', id: 'METTL24'},
{value: 'mettl25', id: 'METTL25'},
{value: 'mettl25b', id: 'METTL25B'},
{value: 'mettl26', id: 'METTL26'},
{value: 'mettl27', id: 'METTL27'},
{value: 'mettl3', id: 'METTL3'},
{value: 'mettl4', id: 'METTL4'},
{value: 'mettl5', id: 'METTL5'},
{value: 'mettl6', id: 'METTL6'},
{value: 'mettl7a', id: 'METTL7A'},
{value: 'mettl7b', id: 'METTL7B'},
{value: 'mettl8', id: 'METTL8'},
{value: 'mettl9', id: 'METTL9'},
{value: 'mex3a', id: 'MEX3A'},
{value: 'mex3b', id: 'MEX3B'},
{value: 'mex3c', id: 'MEX3C'},
{value: 'mex3d', id: 'MEX3D'},
{value: 'mfap1a', id: 'MFAP1A'},
{value: 'mfap2', id: 'MFAP2'},
{value: 'mfap3', id: 'MFAP3'},
{value: 'mfap3l', id: 'MFAP3L'},
{value: 'mfap4', id: 'MFAP4'},
{value: 'mfap5', id: 'MFAP5'},
{value: 'mff', id: 'MFF'},
{value: 'mfge8', id: 'MFGE8'},
{value: 'mfhas1', id: 'MFHAS1'},
{value: 'mfn1', id: 'MFN1'},
{value: 'mfn2', id: 'MFN2'},
{value: 'mfng', id: 'MFNG'},
{value: 'mfrp', id: 'MFRP'},
{value: 'mfsd1', id: 'MFSD1'},
{value: 'mfsd10', id: 'MFSD10'},
{value: 'mfsd11', id: 'MFSD11'},
{value: 'mfsd12', id: 'MFSD12'},
{value: 'mfsd13a', id: 'MFSD13A'},
{value: 'mfsd14a', id: 'MFSD14A'},
{value: 'mfsd14b', id: 'MFSD14B'},
{value: 'mfsd2a', id: 'MFSD2A'},
{value: 'mfsd2b', id: 'MFSD2B'},
{value: 'mfsd3', id: 'MFSD3'},
{value: 'mfsd4a', id: 'MFSD4A'},
{value: 'mfsd4b', id: 'MFSD4B'},
{value: 'mfsd5', id: 'MFSD5'},
{value: 'mfsd6', id: 'MFSD6'},
{value: 'mfsd6l', id: 'MFSD6L'},
{value: 'mfsd8', id: 'MFSD8'},
{value: 'mfsd9', id: 'MFSD9'},
{value: 'mga', id: 'MGA'},
{value: 'mgam', id: 'MGAM'},
{value: 'mgarp', id: 'MGARP'},
{value: 'mgat1', id: 'MGAT1'},
{value: 'mgat2', id: 'MGAT2'},
{value: 'mgat3', id: 'MGAT3'},
{value: 'mgat4a', id: 'MGAT4A'},
{value: 'mgat4b', id: 'MGAT4B'},
{value: 'mgat4c', id: 'MGAT4C'},
{value: 'mgat4d', id: 'MGAT4D'},
{value: 'mgat4e-like', id: 'MGAT4E-LIKE'},
{value: 'mgat4e', id: 'MGAT4E'},
{value: 'mgat5', id: 'MGAT5'},
{value: 'mgat5b', id: 'MGAT5B'},
{value: 'mgc105567', id: 'MGC105567'},
{value: 'mgc105649', id: 'MGC105649'},
{value: 'mgc108823', id: 'MGC108823'},
{value: 'mgc109340', id: 'MGC109340'},
{value: 'mgc112692', id: 'MGC112692'},
{value: 'mgc114246', id: 'MGC114246'},
{value: 'mgc114483', id: 'MGC114483'},
{value: 'mgc114492', id: 'MGC114492'},
{value: 'mgc114499', id: 'MGC114499'},
{value: 'mgc116121', id: 'MGC116121'},
{value: 'mgc116197', id: 'MGC116197'},
{value: 'mgc116202', id: 'MGC116202'},
{value: 'mgc94207', id: 'MGC94207'},
{value: 'mgc94891', id: 'MGC94891'},
{value: 'mgc95208', id: 'MGC95208'},
{value: 'mgc95210', id: 'MGC95210'},
{value: 'mgll', id: 'MGLL'},
{value: 'mgme1', id: 'MGME1'},
{value: 'mgmt', id: 'MGMT'},
{value: 'mgp', id: 'MGP'},
{value: 'mgrn1', id: 'MGRN1'},
{value: 'mgst1', id: 'MGST1'},
{value: 'mgst2', id: 'MGST2'},
{value: 'mgst3', id: 'MGST3'},
{value: 'mia', id: 'MIA'},
{value: 'mia2', id: 'MIA2'},
{value: 'mia3', id: 'MIA3'},
{value: 'mib1', id: 'MIB1'},
{value: 'mib2', id: 'MIB2'},
{value: 'mical1', id: 'MICAL1'},
{value: 'mical2', id: 'MICAL2'},
{value: 'mical3', id: 'MICAL3'},
{value: 'micalcl', id: 'MICALCL'},
{value: 'micall1', id: 'MICALL1'},
{value: 'micall2', id: 'MICALL2'},
{value: 'micb', id: 'MICB'},
{value: 'micos10', id: 'MICOS10'},
{value: 'micos13', id: 'MICOS13'},
{value: 'micu1', id: 'MICU1'},
{value: 'micu2', id: 'MICU2'},
{value: 'micu3', id: 'MICU3'},
{value: 'mid1', id: 'MID1'},
{value: 'mid1ip1', id: 'MID1IP1'},
{value: 'mid2', id: 'MID2'},
{value: 'mideas', id: 'MIDEAS'},
{value: 'midn', id: 'MIDN'},
{value: 'mief1', id: 'MIEF1'},
{value: 'mief2', id: 'MIEF2'},
{value: 'mien1', id: 'MIEN1'},
{value: 'mier1', id: 'MIER1'},
{value: 'mier2', id: 'MIER2'},
{value: 'mier3', id: 'MIER3'},
{value: 'mif', id: 'MIF'},
{value: 'mif4gd', id: 'MIF4GD'},
{value: 'miga1', id: 'MIGA1'},
{value: 'miga2', id: 'MIGA2'},
{value: 'miip', id: 'MIIP'},
{value: 'mill1', id: 'MILL1'},
{value: 'milr1', id: 'MILR1'},
{value: 'minar1', id: 'MINAR1'},
{value: 'minar2', id: 'MINAR2'},
{value: 'mindy1', id: 'MINDY1'},
{value: 'mindy2', id: 'MINDY2'},
{value: 'mindy3', id: 'MINDY3'},
{value: 'mindy4', id: 'MINDY4'},
{value: 'mink1', id: 'MINK1'},
{value: 'minpp1', id: 'MINPP1'},
{value: 'mios', id: 'MIOS'},
{value: 'miox', id: 'MIOX'},
{value: 'mip', id: 'MIP'},
{value: 'mipep', id: 'MIPEP'},
{value: 'mipol1', id: 'MIPOL1'},
{value: 'mir100', id: 'MIR100'},
{value: 'mir101-2', id: 'MIR101-2'},
{value: 'mir101a', id: 'MIR101A'},
{value: 'mir103a2', id: 'MIR103A2'},
{value: 'mir106a', id: 'MIR106A'},
{value: 'mir106b', id: 'MIR106B'},
{value: 'mir107', id: 'MIR107'},
{value: 'mir10a', id: 'MIR10A'},
{value: 'mir10b', id: 'MIR10B'},
{value: 'mir1188', id: 'MIR1188'},
{value: 'mir1193', id: 'MIR1193'},
{value: 'mir1199', id: 'MIR1199'},
{value: 'mir1224', id: 'MIR1224'},
{value: 'mir124-1', id: 'MIR124-1'},
{value: 'mir124-2', id: 'MIR124-2'},
{value: 'mir124-3', id: 'MIR124-3'},
{value: 'mir1247', id: 'MIR1247'},
{value: 'mir1249', id: 'MIR1249'},
{value: 'mir1251', id: 'MIR1251'},
{value: 'mir125b1', id: 'MIR125B1'},
{value: 'mir128-2', id: 'MIR128-2'},
{value: 'mir129-1', id: 'MIR129-1'},
{value: 'mir129-2', id: 'MIR129-2'},
{value: 'mir130a', id: 'MIR130A'},
{value: 'mir133a', id: 'MIR133A'},
{value: 'mir133b', id: 'MIR133B'},
{value: 'mir135a-1', id: 'MIR135A-1'},
{value: 'mir135a', id: 'MIR135A'},
{value: 'mir136', id: 'MIR136'},
{value: 'mir137', id: 'MIR137'},
{value: 'mir138-1', id: 'MIR138-1'},
{value: 'mir138-2', id: 'MIR138-2'},
{value: 'mir139', id: 'MIR139'},
{value: 'mir140', id: 'MIR140'},
{value: 'mir141', id: 'MIR141'},
{value: 'mir142', id: 'MIR142'},
{value: 'mir143', id: 'MIR143'},
{value: 'mir144', id: 'MIR144'},
{value: 'mir145', id: 'MIR145'},
{value: 'mir146a', id: 'MIR146A'},
{value: 'mir146b', id: 'MIR146B'},
{value: 'mir147', id: 'MIR147'},
{value: 'mir148a', id: 'MIR148A'},
{value: 'mir149', id: 'MIR149'},
{value: 'mir150', id: 'MIR150'},
{value: 'mir151b', id: 'MIR151B'},
{value: 'mir152', id: 'MIR152'},
{value: 'mir153', id: 'MIR153'},
{value: 'mir154', id: 'MIR154'},
{value: 'mir155', id: 'MIR155'},
{value: 'mir15a', id: 'MIR15A'},
{value: 'mir17', id: 'MIR17'},
{value: 'mir181a2', id: 'MIR181A2'},
{value: 'mir181b1', id: 'MIR181B1'},
{value: 'mir181b2', id: 'MIR181B2'},
{value: 'mir181c', id: 'MIR181C'},
{value: 'mir183', id: 'MIR183'},
{value: 'mir1839', id: 'MIR1839'},
{value: 'mir184', id: 'MIR184'},
{value: 'mir1843b', id: 'MIR1843B'},
{value: 'mir186', id: 'MIR186'},
{value: 'mir187', id: 'MIR187'},
{value: 'mir1893', id: 'MIR1893'},
{value: 'mir1895', id: 'MIR1895'},
{value: 'mir190', id: 'MIR190'},
{value: 'mir1903', id: 'MIR1903'},
{value: 'mir1907', id: 'MIR1907'},
{value: 'mir190a-2', id: 'MIR190A-2'},
{value: 'mir190b', id: 'MIR190B'},
{value: 'mir192', id: 'MIR192'},
{value: 'mir193a', id: 'MIR193A'},
{value: 'mir193b', id: 'MIR193B'},
{value: 'mir194-1', id: 'MIR194-1'},
{value: 'mir194-2', id: 'MIR194-2'},
{value: 'mir1956', id: 'MIR1956'},
{value: 'mir196a', id: 'MIR196A'},
{value: 'mir196b-2', id: 'MIR196B-2'},
{value: 'mir196c', id: 'MIR196C'},
{value: 'mir199b', id: 'MIR199B'},
{value: 'mir1b', id: 'MIR1B'},
{value: 'mir200b', id: 'MIR200B'},
{value: 'mir202', id: 'MIR202'},
{value: 'mir205', id: 'MIR205'},
{value: 'mir206', id: 'MIR206'},
{value: 'mir207', id: 'MIR207'},
{value: 'mir21', id: 'MIR21'},
{value: 'mir211', id: 'MIR211'},
{value: 'mir212', id: 'MIR212'},
{value: 'mir216a', id: 'MIR216A'},
{value: 'mir216b', id: 'MIR216B'},
{value: 'mir217', id: 'MIR217'},
{value: 'mir218-2', id: 'MIR218-2'},
{value: 'mir219a1', id: 'MIR219A1'},
{value: 'mir221', id: 'MIR221'},
{value: 'mir222', id: 'MIR222'},
{value: 'mir223', id: 'MIR223'},
{value: 'mir224', id: 'MIR224'},
{value: 'mir23a', id: 'MIR23A'},
{value: 'mir23b', id: 'MIR23B'},
{value: 'mir24-2', id: 'MIR24-2'},
{value: 'mir26a', id: 'MIR26A'},
{value: 'mir26b', id: 'MIR26B'},
{value: 'mir28', id: 'MIR28'},
{value: 'mir2861', id: 'MIR2861'},
{value: 'mir290', id: 'MIR290'},
{value: 'mir291a', id: 'MIR291A'},
{value: 'mir294', id: 'MIR294'},
{value: 'mir295-2', id: 'MIR295-2'},
{value: 'mir296', id: 'MIR296'},
{value: 'mir2964', id: 'MIR2964'},
{value: 'mir297', id: 'MIR297'},
{value: 'mir298', id: 'MIR298'},
{value: 'mir2985', id: 'MIR2985'},
{value: 'mir29b-3', id: 'MIR29B-3'},
{value: 'mir29b1', id: 'MIR29B1'},
{value: 'mir29b2', id: 'MIR29B2'},
{value: 'mir301b', id: 'MIR301B'},
{value: 'mir3059', id: 'MIR3059'},
{value: 'mir3072', id: 'MIR3072'},
{value: 'mir3074', id: 'MIR3074'},
{value: 'mir3075', id: 'MIR3075'},
{value: 'mir3084a', id: 'MIR3084A'},
{value: 'mir3084c', id: 'MIR3084C'},
{value: 'mir3085', id: 'MIR3085'},
{value: 'mir3099', id: 'MIR3099'},
{value: 'mir30a', id: 'MIR30A'},
{value: 'mir30b', id: 'MIR30B'},
{value: 'mir30c1', id: 'MIR30C1'},
{value: 'mir30c2', id: 'MIR30C2'},
{value: 'mir30d', id: 'MIR30D'},
{value: 'mir30e', id: 'MIR30E'},
{value: 'mir3102', id: 'MIR3102'},
{value: 'mir3106', id: 'MIR3106'},
{value: 'mir3120', id: 'MIR3120'},
{value: 'mir32', id: 'MIR32'},
{value: 'mir320a', id: 'MIR320A'},
{value: 'mir322', id: 'MIR322'},
{value: 'mir325', id: 'MIR325'},
{value: 'mir327', id: 'MIR327'},
{value: 'mir328', id: 'MIR328'},
{value: 'mir328b', id: 'MIR328B'},
{value: 'mir329', id: 'MIR329'},
{value: 'mir33', id: 'MIR33'},
{value: 'mir330', id: 'MIR330'},
{value: 'mir331', id: 'MIR331'},
{value: 'mir336', id: 'MIR336'},
{value: 'mir337', id: 'MIR337'},
{value: 'mir341', id: 'MIR341'},
{value: 'mir344a-2', id: 'MIR344A-2'},
{value: 'mir344b-1', id: 'MIR344B-1'},
{value: 'mir344b-2', id: 'MIR344B-2'},
{value: 'mir344b-3', id: 'MIR344B-3'},
{value: 'mir344g', id: 'MIR344G'},
{value: 'mir344i', id: 'MIR344I'},
{value: 'mir345', id: 'MIR345'},
{value: 'mir346', id: 'MIR346'},
{value: 'mir34a', id: 'MIR34A'},
{value: 'mir34b', id: 'MIR34B'},
{value: 'mir34c', id: 'MIR34C'},
{value: 'mir351-2', id: 'MIR351-2'},
{value: 'mir351', id: 'MIR351'},
{value: 'mir3541', id: 'MIR3541'},
{value: 'mir3545', id: 'MIR3545'},
{value: 'mir3546', id: 'MIR3546'},
{value: 'mir3548', id: 'MIR3548'},
{value: 'mir3554', id: 'MIR3554'},
{value: 'mir3555', id: 'MIR3555'},
{value: 'mir3556a', id: 'MIR3556A'},
{value: 'mir3556b-2', id: 'MIR3556B-2'},
{value: 'mir3556b', id: 'MIR3556B'},
{value: 'mir3557', id: 'MIR3557'},
{value: 'mir3558', id: 'MIR3558'},
{value: 'mir3559', id: 'MIR3559'},
{value: 'mir3561', id: 'MIR3561'},
{value: 'mir3564', id: 'MIR3564'},
{value: 'mir3566', id: 'MIR3566'},
{value: 'mir3568', id: 'MIR3568'},
{value: 'mir3569', id: 'MIR3569'},
{value: 'mir3570', id: 'MIR3570'},
{value: 'mir3572', id: 'MIR3572'},
{value: 'mir3573', id: 'MIR3573'},
{value: 'mir3574', id: 'MIR3574'},
{value: 'mir3575', id: 'MIR3575'},
{value: 'mir3576', id: 'MIR3576'},
{value: 'mir3580', id: 'MIR3580'},
{value: 'mir3581', id: 'MIR3581'},
{value: 'mir3584', id: 'MIR3584'},
{value: 'mir3585', id: 'MIR3585'},
{value: 'mir3588', id: 'MIR3588'},
{value: 'mir3589', id: 'MIR3589'},
{value: 'mir3591', id: 'MIR3591'},
{value: 'mir3592', id: 'MIR3592'},
{value: 'mir3593', id: 'MIR3593'},
{value: 'mir3594', id: 'MIR3594'},
{value: 'mir3596a', id: 'MIR3596A'},
{value: 'mir3596c', id: 'MIR3596C'},
{value: 'mir3597-1', id: 'MIR3597-1'},
{value: 'mir3597-2', id: 'MIR3597-2'},
{value: 'mir3597-3', id: 'MIR3597-3'},
{value: 'mir363', id: 'MIR363'},
{value: 'mir365', id: 'MIR365'},
{value: 'mir365b', id: 'MIR365B'},
{value: 'mir370', id: 'MIR370'},
{value: 'mir374b', id: 'MIR374B'},
{value: 'mir375', id: 'MIR375'},
{value: 'mir376c', id: 'MIR376C'},
{value: 'mir377', id: 'MIR377'},
{value: 'mir378b', id: 'MIR378B'},
{value: 'mir379', id: 'MIR379'},
{value: 'mir381', id: 'MIR381'},
{value: 'mir383', id: 'MIR383'},
{value: 'mir384', id: 'MIR384'},
{value: 'mir410', id: 'MIR410'},
{value: 'mir421', id: 'MIR421'},
{value: 'mir423', id: 'MIR423'},
{value: 'mir425', id: 'MIR425'},
{value: 'mir429', id: 'MIR429'},
{value: 'mir431', id: 'MIR431'},
{value: 'mir433', id: 'MIR433'},
{value: 'mir449a', id: 'MIR449A'},
{value: 'mir449c', id: 'MIR449C'},
{value: 'mir451a', id: 'MIR451A'},
{value: 'mir455', id: 'MIR455'},
{value: 'mir463', id: 'MIR463'},
{value: 'mir465', id: 'MIR465'},
{value: 'mir484', id: 'MIR484'},
{value: 'mir486', id: 'MIR486'},
{value: 'mir487b', id: 'MIR487B'},
{value: 'mir488', id: 'MIR488'},
{value: 'mir490', id: 'MIR490'},
{value: 'mir493', id: 'MIR493'},
{value: 'mir495', id: 'MIR495'},
{value: 'mir496', id: 'MIR496'},
{value: 'mir497', id: 'MIR497'},
{value: 'mir499a', id: 'MIR499A'},
{value: 'mir500', id: 'MIR500'},
{value: 'mir509', id: 'MIR509'},
{value: 'mir5125', id: 'MIR5125'},
{value: 'mir5126', id: 'MIR5126'},
{value: 'mir541', id: 'MIR541'},
{value: 'mir542-2', id: 'MIR542-2'},
{value: 'mir542-3', id: 'MIR542-3'},
{value: 'mir547', id: 'MIR547'},
{value: 'mir551b', id: 'MIR551B'},
{value: 'mir592', id: 'MIR592'},
{value: 'mir615', id: 'MIR615'},
{value: 'mir6215', id: 'MIR6215'},
{value: 'mir6216', id: 'MIR6216'},
{value: 'mir6316', id: 'MIR6316'},
{value: 'mir6317-1', id: 'MIR6317-1'},
{value: 'mir6317-2', id: 'MIR6317-2'},
{value: 'mir6318', id: 'MIR6318'},
{value: 'mir6319-1', id: 'MIR6319-1'},
{value: 'mir6319-2', id: 'MIR6319-2'},
{value: 'mir632', id: 'MIR632'},
{value: 'mir6321', id: 'MIR6321'},
{value: 'mir6322', id: 'MIR6322'},
{value: 'mir6323', id: 'MIR6323'},
{value: 'mir6325', id: 'MIR6325'},
{value: 'mir6326', id: 'MIR6326'},
{value: 'mir6327', id: 'MIR6327'},
{value: 'mir6328', id: 'MIR6328'},
{value: 'mir6329', id: 'MIR6329'},
{value: 'mir6330', id: 'MIR6330'},
{value: 'mir6332', id: 'MIR6332'},
{value: 'mir6333', id: 'MIR6333'},
{value: 'mir6334', id: 'MIR6334'},
{value: 'mir652', id: 'MIR652'},
{value: 'mir665', id: 'MIR665'},
{value: 'mir667', id: 'MIR667'},
{value: 'mir671', id: 'MIR671'},
{value: 'mir672', id: 'MIR672'},
{value: 'mir673', id: 'MIR673'},
{value: 'mir674', id: 'MIR674'},
{value: 'mir675', id: 'MIR675'},
{value: 'mir678', id: 'MIR678'},
{value: 'mir7-1', id: 'MIR7-1'},
{value: 'mir708', id: 'MIR708'},
{value: 'mir7239', id: 'MIR7239'},
{value: 'mir742', id: 'MIR742'},
{value: 'mir743a', id: 'MIR743A'},
{value: 'mir744', id: 'MIR744'},
{value: 'mir7578', id: 'MIR7578'},
{value: 'mir759', id: 'MIR759'},
{value: 'mir760', id: 'MIR760'},
{value: 'mir761', id: 'MIR761'},
{value: 'mir762', id: 'MIR762'},
{value: 'mir7654', id: 'MIR7654'},
{value: 'mir770', id: 'MIR770'},
{value: 'mir7a2', id: 'MIR7A2'},
{value: 'mir7b', id: 'MIR7B'},
{value: 'mir802', id: 'MIR802'},
{value: 'mir871', id: 'MIR871'},
{value: 'mir872', id: 'MIR872'},
{value: 'mir873', id: 'MIR873'},
{value: 'mir874', id: 'MIR874'},
{value: 'mir875', id: 'MIR875'},
{value: 'mir876', id: 'MIR876'},
{value: 'mir877', id: 'MIR877'},
{value: 'mir878', id: 'MIR878'},
{value: 'mir879', id: 'MIR879'},
{value: 'mir880', id: 'MIR880'},
{value: 'mir881', id: 'MIR881'},
{value: 'mir883', id: 'MIR883'},
{value: 'mir883b', id: 'MIR883B'},
{value: 'mir92a1', id: 'MIR92A1'},
{value: 'mir92b', id: 'MIR92B'},
{value: 'mir93', id: 'MIR93'},
{value: 'mir935', id: 'MIR935'},
{value: 'mir96', id: 'MIR96'},
{value: 'mir98', id: 'MIR98'},
{value: 'mir99a', id: 'MIR99A'},
{value: 'mir99b', id: 'MIR99B'},
{value: 'mirlet7a1', id: 'MIRLET7A1'},
{value: 'mirlet7b', id: 'MIRLET7B'},
{value: 'mirlet7c1', id: 'MIRLET7C1'},
{value: 'mirlet7c2', id: 'MIRLET7C2'},
{value: 'mirlet7d', id: 'MIRLET7D'},
{value: 'mirlet7g', id: 'MIRLET7G'},
{value: 'mirlet7i', id: 'MIRLET7I'},
{value: 'mis12', id: 'MIS12'},
{value: 'mis18a', id: 'MIS18A'},
{value: 'mis18bp1', id: 'MIS18BP1'},
{value: 'misp', id: 'MISP'},
{value: 'misp3', id: 'MISP3'},
{value: 'mitd1', id: 'MITD1'},
{value: 'mitf', id: 'MITF'},
{value: 'mix23', id: 'MIX23'},
{value: 'mixl1', id: 'MIXL1'},
{value: 'mk1', id: 'MK1'},
{value: 'mki67', id: 'MKI67'},
{value: 'mkks', id: 'MKKS'},
{value: 'mkln1', id: 'MKLN1'},
{value: 'mknk1', id: 'MKNK1'},
{value: 'mknk2', id: 'MKNK2'},
{value: 'mkrn1', id: 'MKRN1'},
{value: 'mkrn2', id: 'MKRN2'},
{value: 'mkrn2os', id: 'MKRN2OS'},
{value: 'mkrn3', id: 'MKRN3'},
{value: 'mks1', id: 'MKS1'},
{value: 'mkx', id: 'MKX'},
{value: 'mlana', id: 'MLANA'},
{value: 'mlc1', id: 'MLC1'},
{value: 'mlec', id: 'MLEC'},
{value: 'mlf1', id: 'MLF1'},
{value: 'mlf2', id: 'MLF2'},
{value: 'mlh1', id: 'MLH1'},
{value: 'mlh3', id: 'MLH3'},
{value: 'mlip', id: 'MLIP'},
{value: 'mlkl', id: 'MLKL'},
{value: 'mllt1', id: 'MLLT1'},
{value: 'mllt10', id: 'MLLT10'},
{value: 'mllt11', id: 'MLLT11'},
{value: 'mllt3', id: 'MLLT3'},
{value: 'mllt6', id: 'MLLT6'},
{value: 'mlnr', id: 'MLNR'},
{value: 'mlph', id: 'MLPH'},
{value: 'mlst8', id: 'MLST8'},
{value: 'mlx', id: 'MLX'},
{value: 'mlxip', id: 'MLXIP'},
{value: 'mlxipl', id: 'MLXIPL'},
{value: 'mlycd', id: 'MLYCD'},
{value: 'mmaa', id: 'MMAA'},
{value: 'mmab', id: 'MMAB'},
{value: 'mmachc', id: 'MMACHC'},
{value: 'mmadhc', id: 'MMADHC'},
{value: 'mmd', id: 'MMD'},
{value: 'mmd2', id: 'MMD2'},
{value: 'mme', id: 'MME'},
{value: 'mmel1', id: 'MMEL1'},
{value: 'mmgt1', id: 'MMGT1'},
{value: 'mmp1', id: 'MMP1'},
{value: 'mmp10', id: 'MMP10'},
{value: 'mmp11', id: 'MMP11'},
{value: 'mmp12', id: 'MMP12'},
{value: 'mmp13', id: 'MMP13'},
{value: 'mmp14', id: 'MMP14'},
{value: 'mmp15', id: 'MMP15'},
{value: 'mmp16', id: 'MMP16'},
{value: 'mmp17', id: 'MMP17'},
{value: 'mmp19', id: 'MMP19'},
{value: 'mmp1b', id: 'MMP1B'},
{value: 'mmp2', id: 'MMP2'},
{value: 'mmp20', id: 'MMP20'},
{value: 'mmp21', id: 'MMP21'},
{value: 'mmp23', id: 'MMP23'},
{value: 'mmp24', id: 'MMP24'},
{value: 'mmp25', id: 'MMP25'},
{value: 'mmp27', id: 'MMP27'},
{value: 'mmp28', id: 'MMP28'},
{value: 'mmp3', id: 'MMP3'},
{value: 'mmp7', id: 'MMP7'},
{value: 'mmp8', id: 'MMP8'},
{value: 'mmp9', id: 'MMP9'},
{value: 'mmrn1', id: 'MMRN1'},
{value: 'mmrn2', id: 'MMRN2'},
{value: 'mms19', id: 'MMS19'},
{value: 'mms22l', id: 'MMS22L'},
{value: 'mmut', id: 'MMUT'},
{value: 'mn1', id: 'MN1'},
{value: 'mnat1', id: 'MNAT1'},
{value: 'mnd1', id: 'MND1'},
{value: 'mnda', id: 'MNDA'},
{value: 'mns1', id: 'MNS1'},
{value: 'mnt', id: 'MNT'},
{value: 'mnx1', id: 'MNX1'},
{value: 'moap1', id: 'MOAP1'},
{value: 'mob1a', id: 'MOB1A'},
{value: 'mob1b', id: 'MOB1B'},
{value: 'mob2', id: 'MOB2'},
{value: 'mob3a', id: 'MOB3A'},
{value: 'mob3b', id: 'MOB3B'},
{value: 'mob3c', id: 'MOB3C'},
{value: 'mob4', id: 'MOB4'},
{value: 'mobp', id: 'MOBP'},
{value: 'mocos', id: 'MOCOS'},
{value: 'mocs1', id: 'MOCS1'},
{value: 'mocs2', id: 'MOCS2'},
{value: 'mocs3', id: 'MOCS3'},
{value: 'mog', id: 'MOG'},
{value: 'mogat1', id: 'MOGAT1'},
{value: 'mogat2', id: 'MOGAT2'},
{value: 'mogat3', id: 'MOGAT3'},
{value: 'mogs', id: 'MOGS'},
{value: 'mok', id: 'MOK'},
{value: 'mon1a', id: 'MON1A'},
{value: 'mon1b', id: 'MON1B'},
{value: 'mon2', id: 'MON2'},
{value: 'morc1', id: 'MORC1'},
{value: 'morc2', id: 'MORC2'},
{value: 'morc2b', id: 'MORC2B'},
{value: 'morc3', id: 'MORC3'},
{value: 'morc4', id: 'MORC4'},
{value: 'morf4l1', id: 'MORF4L1'},
{value: 'morf4l2', id: 'MORF4L2'},
{value: 'morn1', id: 'MORN1'},
{value: 'morn2', id: 'MORN2'},
{value: 'morn3', id: 'MORN3'},
{value: 'morn4', id: 'MORN4'},
{value: 'morn5', id: 'MORN5'},
{value: 'mos', id: 'MOS'},
{value: 'mosmo', id: 'MOSMO'},
{value: 'mospd1', id: 'MOSPD1'},
{value: 'mospd2', id: 'MOSPD2'},
{value: 'mospd3', id: 'MOSPD3'},
{value: 'mospd4', id: 'MOSPD4'},
{value: 'mov10', id: 'MOV10'},
{value: 'mov10l1', id: 'MOV10L1'},
{value: 'moxd1', id: 'MOXD1'},
{value: 'moxd2', id: 'MOXD2'},
{value: 'mpc1', id: 'MPC1'},
{value: 'mpc2', id: 'MPC2'},
{value: 'mpdu1', id: 'MPDU1'},
{value: 'mpdz', id: 'MPDZ'},
{value: 'mpeg1', id: 'MPEG1'},
{value: 'mpg', id: 'MPG'},
{value: 'mphosph10', id: 'MPHOSPH10'},
{value: 'mphosph6', id: 'MPHOSPH6'},
{value: 'mphosph8', id: 'MPHOSPH8'},
{value: 'mphosph9', id: 'MPHOSPH9'},
{value: 'mpi', id: 'MPI'},
{value: 'mpig6b', id: 'MPIG6B'},
{value: 'mpl', id: 'MPL'},
{value: 'mplkip', id: 'MPLKIP'},
{value: 'mpnd', id: 'MPND'},
{value: 'mpo', id: 'MPO'},
{value: 'mpp1', id: 'MPP1'},
{value: 'mpp2', id: 'MPP2'},
{value: 'mpp3', id: 'MPP3'},
{value: 'mpp4', id: 'MPP4'},
{value: 'mpp7', id: 'MPP7'},
{value: 'mppe1', id: 'MPPE1'},
{value: 'mpped1', id: 'MPPED1'},
{value: 'mpped2', id: 'MPPED2'},
{value: 'mprip', id: 'MPRIP'},
{value: 'mpst', id: 'MPST'},
{value: 'mptx1', id: 'MPTX1'},
{value: 'mpv17', id: 'MPV17'},
{value: 'mpv17l', id: 'MPV17L'},
{value: 'mpv17l2', id: 'MPV17L2'},
{value: 'mpz', id: 'MPZ'},
{value: 'mpzl1', id: 'MPZL1'},
{value: 'mpzl2', id: 'MPZL2'},
{value: 'mpzl3', id: 'MPZL3'},
{value: 'mr1', id: 'MR1'},
{value: 'mrap', id: 'MRAP'},
{value: 'mrap2', id: 'MRAP2'},
{value: 'mras', id: 'MRAS'},
{value: 'mrc1', id: 'MRC1'},
{value: 'mrc2', id: 'MRC2'},
{value: 'mre11', id: 'MRE11'},
{value: 'mreg', id: 'MREG'},
{value: 'mrfap1', id: 'MRFAP1'},
{value: 'mrgbp', id: 'MRGBP'},
{value: 'mrgprb13', id: 'MRGPRB13'},
{value: 'mrgprb3', id: 'MRGPRB3'},
{value: 'mrgprb4', id: 'MRGPRB4'},
{value: 'mrgprb5', id: 'MRGPRB5'},
{value: 'mrgprd', id: 'MRGPRD'},
{value: 'mrgpre', id: 'MRGPRE'},
{value: 'mrgprf', id: 'MRGPRF'},
{value: 'mrgprg', id: 'MRGPRG'},
{value: 'mrgprx1', id: 'MRGPRX1'},
{value: 'mrgprx2', id: 'MRGPRX2'},
{value: 'mrgprx2l', id: 'MRGPRX2L'},
{value: 'mrgprx3', id: 'MRGPRX3'},
{value: 'mrgprx4', id: 'MRGPRX4'},
{value: 'mri1', id: 'MRI1'},
{value: 'mrm1', id: 'MRM1'},
{value: 'mrm2', id: 'MRM2'},
{value: 'mrm3', id: 'MRM3'},
{value: 'mrnip', id: 'MRNIP'},
{value: 'mro', id: 'MRO'},
{value: 'mroh1', id: 'MROH1'},
{value: 'mroh2a', id: 'MROH2A'},
{value: 'mroh2b', id: 'MROH2B'},
{value: 'mroh4', id: 'MROH4'},
{value: 'mroh5', id: 'MROH5'},
{value: 'mroh6', id: 'MROH6'},
{value: 'mroh7', id: 'MROH7'},
{value: 'mroh8', id: 'MROH8'},
{value: 'mroh9', id: 'MROH9'},
{value: 'mrpl1', id: 'MRPL1'},
{value: 'mrpl10', id: 'MRPL10'},
{value: 'mrpl11', id: 'MRPL11'},
{value: 'mrpl12', id: 'MRPL12'},
{value: 'mrpl13', id: 'MRPL13'},
{value: 'mrpl14', id: 'MRPL14'},
{value: 'mrpl15', id: 'MRPL15'},
{value: 'mrpl16', id: 'MRPL16'},
{value: 'mrpl17', id: 'MRPL17'},
{value: 'mrpl18', id: 'MRPL18'},
{value: 'mrpl19', id: 'MRPL19'},
{value: 'mrpl2', id: 'MRPL2'},
{value: 'mrpl20', id: 'MRPL20'},
{value: 'mrpl21', id: 'MRPL21'},
{value: 'mrpl22', id: 'MRPL22'},
{value: 'mrpl23', id: 'MRPL23'},
{value: 'mrpl24', id: 'MRPL24'},
{value: 'mrpl27', id: 'MRPL27'},
{value: 'mrpl28', id: 'MRPL28'},
{value: 'mrpl3', id: 'MRPL3'},
{value: 'mrpl30', id: 'MRPL30'},
{value: 'mrpl32', id: 'MRPL32'},
{value: 'mrpl33', id: 'MRPL33'},
{value: 'mrpl34', id: 'MRPL34'},
{value: 'mrpl35', id: 'MRPL35'},
{value: 'mrpl36', id: 'MRPL36'},
{value: 'mrpl37', id: 'MRPL37'},
{value: 'mrpl38', id: 'MRPL38'},
{value: 'mrpl39', id: 'MRPL39'},
{value: 'mrpl4', id: 'MRPL4'},
{value: 'mrpl40', id: 'MRPL40'},
{value: 'mrpl41', id: 'MRPL41'},
{value: 'mrpl42', id: 'MRPL42'},
{value: 'mrpl43', id: 'MRPL43'},
{value: 'mrpl44', id: 'MRPL44'},
{value: 'mrpl45', id: 'MRPL45'},
{value: 'mrpl46', id: 'MRPL46'},
{value: 'mrpl47', id: 'MRPL47'},
{value: 'mrpl48', id: 'MRPL48'},
{value: 'mrpl49', id: 'MRPL49'},
{value: 'mrpl50', id: 'MRPL50'},
{value: 'mrpl51', id: 'MRPL51'},
{value: 'mrpl52', id: 'MRPL52'},
{value: 'mrpl53', id: 'MRPL53'},
{value: 'mrpl54', id: 'MRPL54'},
{value: 'mrpl55', id: 'MRPL55'},
{value: 'mrpl57', id: 'MRPL57'},
{value: 'mrpl58', id: 'MRPL58'},
{value: 'mrpl9', id: 'MRPL9'},
{value: 'mrps10', id: 'MRPS10'},
{value: 'mrps11', id: 'MRPS11'},
{value: 'mrps12', id: 'MRPS12'},
{value: 'mrps14', id: 'MRPS14'},
{value: 'mrps15', id: 'MRPS15'},
{value: 'mrps16', id: 'MRPS16'},
{value: 'mrps17', id: 'MRPS17'},
{value: 'mrps18a', id: 'MRPS18A'},
{value: 'mrps18b', id: 'MRPS18B'},
{value: 'mrps18c', id: 'MRPS18C'},
{value: 'mrps2', id: 'MRPS2'},
{value: 'mrps21', id: 'MRPS21'},
{value: 'mrps21l', id: 'MRPS21L'},
{value: 'mrps22', id: 'MRPS22'},
{value: 'mrps23', id: 'MRPS23'},
{value: 'mrps24', id: 'MRPS24'},
{value: 'mrps25', id: 'MRPS25'},
{value: 'mrps26', id: 'MRPS26'},
{value: 'mrps27', id: 'MRPS27'},
{value: 'mrps28', id: 'MRPS28'},
{value: 'mrps30', id: 'MRPS30'},
{value: 'mrps31', id: 'MRPS31'},
{value: 'mrps33', id: 'MRPS33'},
{value: 'mrps34', id: 'MRPS34'},
{value: 'mrps35', id: 'MRPS35'},
{value: 'mrps36', id: 'MRPS36'},
{value: 'mrps5', id: 'MRPS5'},
{value: 'mrps6', id: 'MRPS6'},
{value: 'mrps7', id: 'MRPS7'},
{value: 'mrps9', id: 'MRPS9'},
{value: 'mrrf', id: 'MRRF'},
{value: 'mrs2', id: 'MRS2'},
{value: 'mrtfa', id: 'MRTFA'},
{value: 'mrtfb', id: 'MRTFB'},
{value: 'mrto4', id: 'MRTO4'},
{value: 'ms4a1', id: 'MS4A1'},
{value: 'ms4a10', id: 'MS4A10'},
{value: 'ms4a12', id: 'MS4A12'},
{value: 'ms4a14', id: 'MS4A14'},
{value: 'ms4a15', id: 'MS4A15'},
{value: 'ms4a18', id: 'MS4A18'},
{value: 'ms4a2', id: 'MS4A2'},
{value: 'ms4a3', id: 'MS4A3'},
{value: 'ms4a4a', id: 'MS4A4A'},
{value: 'ms4a4c', id: 'MS4A4C'},
{value: 'ms4a5', id: 'MS4A5'},
{value: 'ms4a6a', id: 'MS4A6A'},
{value: 'ms4a6b', id: 'MS4A6B'},
{value: 'ms4a6bl', id: 'MS4A6BL'},
{value: 'ms4a7', id: 'MS4A7'},
{value: 'ms4a8', id: 'MS4A8'},
{value: 'msantd1', id: 'MSANTD1'},
{value: 'msantd2', id: 'MSANTD2'},
{value: 'msantd3', id: 'MSANTD3'},
{value: 'msantd4', id: 'MSANTD4'},
{value: 'msantd5', id: 'MSANTD5'},
{value: 'msc', id: 'MSC'},
{value: 'msgn1', id: 'MSGN1'},
{value: 'msh2', id: 'MSH2'},
{value: 'msh3', id: 'MSH3'},
{value: 'msh4', id: 'MSH4'},
{value: 'msh5', id: 'MSH5'},
{value: 'msh6', id: 'MSH6'},
{value: 'msi1', id: 'MSI1'},
{value: 'msi2', id: 'MSI2'},
{value: 'msl1', id: 'MSL1'},
{value: 'msl2', id: 'MSL2'},
{value: 'msl3', id: 'MSL3'},
{value: 'msl3l2', id: 'MSL3L2'},
{value: 'msln', id: 'MSLN'},
{value: 'mslnl', id: 'MSLNL'},
{value: 'msmb', id: 'MSMB'},
{value: 'msmo1', id: 'MSMO1'},
{value: 'msmp', id: 'MSMP'},
{value: 'msn', id: 'MSN'},
{value: 'msr1', id: 'MSR1'},
{value: 'msra', id: 'MSRA'},
{value: 'msrb1', id: 'MSRB1'},
{value: 'msrb2', id: 'MSRB2'},
{value: 'msrb3', id: 'MSRB3'},
{value: 'mss51', id: 'MSS51'},
{value: 'mst1', id: 'MST1'},
{value: 'mst1r', id: 'MST1R'},
{value: 'mstn', id: 'MSTN'},
{value: 'msto1', id: 'MSTO1'},
{value: 'msx1', id: 'MSX1'},
{value: 'msx2', id: 'MSX2'},
{value: 'msx3', id: 'MSX3'},
{value: 'mt-atp6', id: 'MT-ATP6'},
{value: 'mt-atp8', id: 'MT-ATP8'},
{value: 'mt-co1', id: 'MT-CO1'},
{value: 'mt-co2', id: 'MT-CO2'},
{value: 'mt-co3', id: 'MT-CO3'},
{value: 'mt-cyb', id: 'MT-CYB'},
{value: 'mt-nd1', id: 'MT-ND1'},
{value: 'mt-nd2', id: 'MT-ND2'},
{value: 'mt-nd3', id: 'MT-ND3'},
{value: 'mt-nd4', id: 'MT-ND4'},
{value: 'mt-nd4l', id: 'MT-ND4L'},
{value: 'mt-nd5', id: 'MT-ND5'},
{value: 'mt-nd6', id: 'MT-ND6'},
{value: 'mt1', id: 'MT1'},
{value: 'mt1m', id: 'MT1M'},
{value: 'mt2a', id: 'MT2A'},
{value: 'mt3', id: 'MT3'},
{value: 'mt4', id: 'MT4'},
{value: 'mta1', id: 'MTA1'},
{value: 'mta2', id: 'MTA2'},
{value: 'mta3', id: 'MTA3'},
{value: 'mtap', id: 'MTAP'},
{value: 'mtarc1', id: 'MTARC1'},
{value: 'mtarc2', id: 'MTARC2'},
{value: 'mtbp', id: 'MTBP'},
{value: 'mtch1', id: 'MTCH1'},
{value: 'mtch2', id: 'MTCH2'},
{value: 'mtcl1', id: 'MTCL1'},
{value: 'mtcp1', id: 'MTCP1'},
{value: 'mtdh', id: 'MTDH'},
{value: 'mterf1', id: 'MTERF1'},
{value: 'mterf2', id: 'MTERF2'},
{value: 'mterf3', id: 'MTERF3'},
{value: 'mterf4', id: 'MTERF4'},
{value: 'mtf1', id: 'MTF1'},
{value: 'mtf2', id: 'MTF2'},
{value: 'mtfmt', id: 'MTFMT'},
{value: 'mtfp1', id: 'MTFP1'},
{value: 'mtfr1-ps1', id: 'MTFR1-PS1'},
{value: 'mtfr1', id: 'MTFR1'},
{value: 'mtfr1l', id: 'MTFR1L'},
{value: 'mtfr2', id: 'MTFR2'},
{value: 'mtg1', id: 'MTG1'},
{value: 'mtg2', id: 'MTG2'},
{value: 'mthfd1', id: 'MTHFD1'},
{value: 'mthfd1l', id: 'MTHFD1L'},
{value: 'mthfd2', id: 'MTHFD2'},
{value: 'mthfd2l', id: 'MTHFD2L'},
{value: 'mthfr', id: 'MTHFR'},
{value: 'mthfs', id: 'MTHFS'},
{value: 'mthfsd', id: 'MTHFSD'},
{value: 'mtif2', id: 'MTIF2'},
{value: 'mtif3', id: 'MTIF3'},
{value: 'mtm1', id: 'MTM1'},
{value: 'mtmr1', id: 'MTMR1'},
{value: 'mtmr10', id: 'MTMR10'},
{value: 'mtmr11', id: 'MTMR11'},
{value: 'mtmr12', id: 'MTMR12'},
{value: 'mtmr14', id: 'MTMR14'},
{value: 'mtmr2', id: 'MTMR2'},
{value: 'mtmr3', id: 'MTMR3'},
{value: 'mtmr4', id: 'MTMR4'},
{value: 'mtmr6', id: 'MTMR6'},
{value: 'mtmr7', id: 'MTMR7'},
{value: 'mtmr9', id: 'MTMR9'},
{value: 'mtnr1a', id: 'MTNR1A'},
{value: 'mtnr1b', id: 'MTNR1B'},
{value: 'mto1', id: 'MTO1'},
{value: 'mtor', id: 'MTOR'},
{value: 'mtpap', id: 'MTPAP'},
{value: 'mtpn', id: 'MTPN'},
{value: 'mtr', id: 'MTR'},
{value: 'mtres1', id: 'MTRES1'},
{value: 'mtrex', id: 'MTREX'},
{value: 'mtrf1', id: 'MTRF1'},
{value: 'mtrf1l', id: 'MTRF1L'},
{value: 'mtrfr', id: 'MTRFR'},
{value: 'mtrr', id: 'MTRR'},
{value: 'mtss1', id: 'MTSS1'},
{value: 'mtss2', id: 'MTSS2'},
{value: 'mttp', id: 'MTTP'},
{value: 'mturn', id: 'MTURN'},
{value: 'mtus1', id: 'MTUS1'},
{value: 'mtus2', id: 'MTUS2'},
{value: 'mtx1', id: 'MTX1'},
{value: 'mtx2', id: 'MTX2'},
{value: 'mtx3', id: 'MTX3'},
{value: 'muc1', id: 'MUC1'},
{value: 'muc13', id: 'MUC13'},
{value: 'muc15', id: 'MUC15'},
{value: 'muc16', id: 'MUC16'},
{value: 'muc2', id: 'MUC2'},
{value: 'muc20', id: 'MUC20'},
{value: 'muc4', id: 'MUC4'},
{value: 'muc5ac', id: 'MUC5AC'},
{value: 'muc5b', id: 'MUC5B'},
{value: 'muc6', id: 'MUC6'},
{value: 'mucl1', id: 'MUCL1'},
{value: 'mucl3', id: 'MUCL3'},
{value: 'mug1', id: 'MUG1'},
{value: 'mug2', id: 'MUG2'},
{value: 'mul1', id: 'MUL1'},
{value: 'mup4', id: 'MUP4'},
{value: 'mup5', id: 'MUP5'},
{value: 'mus81', id: 'MUS81'},
{value: 'musk', id: 'MUSK'},
{value: 'mustn1', id: 'MUSTN1'},
{value: 'mutyh', id: 'MUTYH'},
{value: 'mvb12a', id: 'MVB12A'},
{value: 'mvb12b', id: 'MVB12B'},
{value: 'mvd', id: 'MVD'},
{value: 'mvk', id: 'MVK'},
{value: 'mvp', id: 'MVP'},
{value: 'mx1', id: 'MX1'},
{value: 'mx2', id: 'MX2'},
{value: 'mxd1', id: 'MXD1'},
{value: 'mxd3', id: 'MXD3'},
{value: 'mxd4', id: 'MXD4'},
{value: 'mxi1', id: 'MXI1'},
{value: 'mxra7', id: 'MXRA7'},
{value: 'mxra8', id: 'MXRA8'},
{value: 'myadm', id: 'MYADM'},
{value: 'myadml2', id: 'MYADML2'},
{value: 'myb', id: 'MYB'},
{value: 'mybbp1a', id: 'MYBBP1A'},
{value: 'mybl1', id: 'MYBL1'},
{value: 'mybl2', id: 'MYBL2'},
{value: 'mybpc1', id: 'MYBPC1'},
{value: 'mybpc2', id: 'MYBPC2'},
{value: 'mybpc3', id: 'MYBPC3'},
{value: 'mybph', id: 'MYBPH'},
{value: 'mybphl', id: 'MYBPHL'},
{value: 'myc', id: 'MYC'},
{value: 'mycbp', id: 'MYCBP'},
{value: 'mycbp2', id: 'MYCBP2'},
{value: 'mycbpap', id: 'MYCBPAP'},
{value: 'mycl', id: 'MYCL'},
{value: 'mycn', id: 'MYCN'},
{value: 'mycs', id: 'MYCS'},
{value: 'myct1', id: 'MYCT1'},
{value: 'myd88', id: 'MYD88'},
{value: 'mydgf', id: 'MYDGF'},
{value: 'myef2', id: 'MYEF2'},
{value: 'myf5', id: 'MYF5'},
{value: 'myf6', id: 'MYF6'},
{value: 'myg1', id: 'MYG1'},
{value: 'myh1', id: 'MYH1'},
{value: 'myh10', id: 'MYH10'},
{value: 'myh11', id: 'MYH11'},
{value: 'myh13', id: 'MYH13'},
{value: 'myh14', id: 'MYH14'},
{value: 'myh15', id: 'MYH15'},
{value: 'myh2', id: 'MYH2'},
{value: 'myh3', id: 'MYH3'},
{value: 'myh4', id: 'MYH4'},
{value: 'myh6', id: 'MYH6'},
{value: 'myh7', id: 'MYH7'},
{value: 'myh7b', id: 'MYH7B'},
{value: 'myh8', id: 'MYH8'},
{value: 'myh9', id: 'MYH9'},
{value: 'myl1', id: 'MYL1'},
{value: 'myl10', id: 'MYL10'},
{value: 'myl12a', id: 'MYL12A'},
{value: 'myl12b', id: 'MYL12B'},
{value: 'myl2', id: 'MYL2'},
{value: 'myl3', id: 'MYL3'},
{value: 'myl4', id: 'MYL4'},
{value: 'myl6', id: 'MYL6'},
{value: 'myl6l', id: 'MYL6L'},
{value: 'myl7', id: 'MYL7'},
{value: 'myl9', id: 'MYL9'},
{value: 'mylip', id: 'MYLIP'},
{value: 'mylk', id: 'MYLK'},
{value: 'mylk2', id: 'MYLK2'},
{value: 'mylk3', id: 'MYLK3'},
{value: 'mylk4', id: 'MYLK4'},
{value: 'mylpf', id: 'MYLPF'},
{value: 'mymk', id: 'MYMK'},
{value: 'mymx', id: 'MYMX'},
{value: 'mynn', id: 'MYNN'},
{value: 'myo10', id: 'MYO10'},
{value: 'myo15a', id: 'MYO15A'},
{value: 'myo16', id: 'MYO16'},
{value: 'myo18a', id: 'MYO18A'},
{value: 'myo18b', id: 'MYO18B'},
{value: 'myo19', id: 'MYO19'},
{value: 'myo1a', id: 'MYO1A'},
{value: 'myo1b', id: 'MYO1B'},
{value: 'myo1c', id: 'MYO1C'},
{value: 'myo1d', id: 'MYO1D'},
{value: 'myo1e', id: 'MYO1E'},
{value: 'myo1f', id: 'MYO1F'},
{value: 'myo1g', id: 'MYO1G'},
{value: 'myo1h', id: 'MYO1H'},
{value: 'myo3a', id: 'MYO3A'},
{value: 'myo3b', id: 'MYO3B'},
{value: 'myo5a', id: 'MYO5A'},
{value: 'myo5b', id: 'MYO5B'},
{value: 'myo5c', id: 'MYO5C'},
{value: 'myo6', id: 'MYO6'},
{value: 'myo7a', id: 'MYO7A'},
{value: 'myo7b', id: 'MYO7B'},
{value: 'myo9a', id: 'MYO9A'},
{value: 'myo9b', id: 'MYO9B'},
{value: 'myoc', id: 'MYOC'},
{value: 'myocd', id: 'MYOCD'},
{value: 'myod1', id: 'MYOD1'},
{value: 'myof', id: 'MYOF'},
{value: 'myog', id: 'MYOG'},
{value: 'myom1', id: 'MYOM1'},
{value: 'myom2', id: 'MYOM2'},
{value: 'myom3', id: 'MYOM3'},
{value: 'myorg', id: 'MYORG'},
{value: 'myot', id: 'MYOT'},
{value: 'myoz1', id: 'MYOZ1'},
{value: 'myoz2', id: 'MYOZ2'},
{value: 'myoz3', id: 'MYOZ3'},
{value: 'mypn', id: 'MYPN'},
{value: 'mypop', id: 'MYPOP'},
{value: 'myrf', id: 'MYRF'},
{value: 'myrfl', id: 'MYRFL'},
{value: 'myrip', id: 'MYRIP'},
{value: 'mysm1', id: 'MYSM1'},
{value: 'myt1', id: 'MYT1'},
{value: 'myt1l', id: 'MYT1L'},
{value: 'myzap', id: 'MYZAP'},
{value: 'mzb1', id: 'MZB1'},
{value: 'mzf1', id: 'MZF1'},
{value: 'mzt1', id: 'MZT1'},
{value: 'mzt2b', id: 'MZT2B'},
{value: 'n4bp1', id: 'N4BP1'},
{value: 'n4bp2', id: 'N4BP2'},
{value: 'n4bp2l1', id: 'N4BP2L1'},
{value: 'n4bp2l2', id: 'N4BP2L2'},
{value: 'n4bp3', id: 'N4BP3'},
{value: 'n5', id: 'N5'},
{value: 'n6amt1', id: 'N6AMT1'},
{value: 'na', id: 'NA'},
{value: 'naa10', id: 'NAA10'},
{value: 'naa11', id: 'NAA11'},
{value: 'naa15', id: 'NAA15'},
{value: 'naa16', id: 'NAA16'},
{value: 'naa20', id: 'NAA20'},
{value: 'naa25', id: 'NAA25'},
{value: 'naa30', id: 'NAA30'},
{value: 'naa35', id: 'NAA35'},
{value: 'naa38', id: 'NAA38'},
{value: 'naa40', id: 'NAA40'},
{value: 'naa50', id: 'NAA50'},
{value: 'naa60', id: 'NAA60'},
{value: 'naa80', id: 'NAA80'},
{value: 'naaa', id: 'NAAA'},
{value: 'naalad2', id: 'NAALAD2'},
{value: 'naaladl1', id: 'NAALADL1'},
{value: 'naaladl2', id: 'NAALADL2'},
{value: 'nab1', id: 'NAB1'},
{value: 'nab2', id: 'NAB2'},
{value: 'nabp1', id: 'NABP1'},
{value: 'nabp2', id: 'NABP2'},
{value: 'naca', id: 'NACA'},
{value: 'nacad', id: 'NACAD'},
{value: 'nacc1', id: 'NACC1'},
{value: 'nacc2', id: 'NACC2'},
{value: 'nadk', id: 'NADK'},
{value: 'nadk2', id: 'NADK2'},
{value: 'nadsyn1', id: 'NADSYN1'},
{value: 'nae1', id: 'NAE1'},
{value: 'naf1', id: 'NAF1'},
{value: 'naga', id: 'NAGA'},
{value: 'nagk', id: 'NAGK'},
{value: 'naglt1', id: 'NAGLT1'},
{value: 'naglu', id: 'NAGLU'},
{value: 'nagpa', id: 'NAGPA'},
{value: 'nags', id: 'NAGS'},
{value: 'naif1', id: 'NAIF1'},
{value: 'naip2', id: 'NAIP2'},
{value: 'naip5', id: 'NAIP5'},
{value: 'naip6', id: 'NAIP6'},
{value: 'nalcn', id: 'NALCN'},
{value: 'nalf1', id: 'NALF1'},
{value: 'nalf2', id: 'NALF2'},
{value: 'nampt', id: 'NAMPT'},
{value: 'nanos1', id: 'NANOS1'},
{value: 'nanos2', id: 'NANOS2'},
{value: 'nanos3', id: 'NANOS3'},
{value: 'nanp', id: 'NANP'},
{value: 'nans', id: 'NANS'},
{value: 'nap1l1', id: 'NAP1L1'},
{value: 'nap1l2', id: 'NAP1L2'},
{value: 'nap1l3', id: 'NAP1L3'},
{value: 'nap1l4', id: 'NAP1L4'},
{value: 'nap1l5', id: 'NAP1L5'},
{value: 'napa', id: 'NAPA'},
{value: 'napb', id: 'NAPB'},
{value: 'napepld', id: 'NAPEPLD'},
{value: 'napg', id: 'NAPG'},
{value: 'naprt', id: 'NAPRT'},
{value: 'napsa', id: 'NAPSA'},
{value: 'narf', id: 'NARF'},
{value: 'nars1', id: 'NARS1'},
{value: 'nars2', id: 'NARS2'},
{value: 'nasp', id: 'NASP'},
{value: 'nat1', id: 'NAT1'},
{value: 'nat10', id: 'NAT10'},
{value: 'nat14', id: 'NAT14'},
{value: 'nat2', id: 'NAT2'},
{value: 'nat3', id: 'NAT3'},
{value: 'nat8', id: 'NAT8'},
{value: 'nat8b', id: 'NAT8B'},
{value: 'nat8f1', id: 'NAT8F1'},
{value: 'nat8f2', id: 'NAT8F2'},
{value: 'nat8f3', id: 'NAT8F3'},
{value: 'nat8f4', id: 'NAT8F4'},
{value: 'nat8f5', id: 'NAT8F5'},
{value: 'nat8l', id: 'NAT8L'},
{value: 'nat9', id: 'NAT9'},
{value: 'natd1', id: 'NATD1'},
{value: 'nav1', id: 'NAV1'},
{value: 'nav2', id: 'NAV2'},
{value: 'nav3', id: 'NAV3'},
{value: 'naxd', id: 'NAXD'},
{value: 'naxe', id: 'NAXE'},
{value: 'nbas', id: 'NBAS'},
{value: 'nbea', id: 'NBEA'},
{value: 'nbeal1', id: 'NBEAL1'},
{value: 'nbeal2', id: 'NBEAL2'},
{value: 'nbl1', id: 'NBL1'},
{value: 'nbn', id: 'NBN'},
{value: 'nbr1', id: 'NBR1'},
{value: 'ncald', id: 'NCALD'},
{value: 'ncam1', id: 'NCAM1'},
{value: 'ncam2', id: 'NCAM2'},
{value: 'ncan', id: 'NCAN'},
{value: 'ncapd2', id: 'NCAPD2'},
{value: 'ncapd3', id: 'NCAPD3'},
{value: 'ncapg', id: 'NCAPG'},
{value: 'ncapg2', id: 'NCAPG2'},
{value: 'ncaph', id: 'NCAPH'},
{value: 'ncaph2', id: 'NCAPH2'},
{value: 'ncbp1', id: 'NCBP1'},
{value: 'ncbp2', id: 'NCBP2'},
{value: 'ncbp3', id: 'NCBP3'},
{value: 'nccrp1', id: 'NCCRP1'},
{value: 'ncdn', id: 'NCDN'},
{value: 'nceh1', id: 'NCEH1'},
{value: 'ncf1', id: 'NCF1'},
{value: 'ncf2', id: 'NCF2'},
{value: 'ncf4', id: 'NCF4'},
{value: 'nck1', id: 'NCK1'},
{value: 'nck2', id: 'NCK2'},
{value: 'nckap1', id: 'NCKAP1'},
{value: 'nckap1l', id: 'NCKAP1L'},
{value: 'nckap5', id: 'NCKAP5'},
{value: 'nckap5l', id: 'NCKAP5L'},
{value: 'nckipsd', id: 'NCKIPSD'},
{value: 'ncl', id: 'NCL'},
{value: 'ncln', id: 'NCLN'},
{value: 'ncmap', id: 'NCMAP'},
{value: 'ncoa1', id: 'NCOA1'},
{value: 'ncoa2', id: 'NCOA2'},
{value: 'ncoa3', id: 'NCOA3'},
{value: 'ncoa4', id: 'NCOA4'},
{value: 'ncoa5', id: 'NCOA5'},
{value: 'ncoa6', id: 'NCOA6'},
{value: 'ncoa7', id: 'NCOA7'},
{value: 'ncor1', id: 'NCOR1'},
{value: 'ncor2', id: 'NCOR2'},
{value: 'ncr1', id: 'NCR1'},
{value: 'ncr3', id: 'NCR3'},
{value: 'ncs1', id: 'NCS1'},
{value: 'ncstn', id: 'NCSTN'},
{value: 'ndc1', id: 'NDC1'},
{value: 'ndc80', id: 'NDC80'},
{value: 'nde1', id: 'NDE1'},
{value: 'ndel1', id: 'NDEL1'},
{value: 'ndfip1', id: 'NDFIP1'},
{value: 'ndfip2', id: 'NDFIP2'},
{value: 'ndn', id: 'NDN'},
{value: 'ndnf', id: 'NDNF'},
{value: 'ndor1', id: 'NDOR1'},
{value: 'ndp', id: 'NDP'},
{value: 'ndrg1', id: 'NDRG1'},
{value: 'ndrg2', id: 'NDRG2'},
{value: 'ndrg3', id: 'NDRG3'},
{value: 'ndrg4', id: 'NDRG4'},
{value: 'ndst1', id: 'NDST1'},
{value: 'ndst2', id: 'NDST2'},
{value: 'ndst3', id: 'NDST3'},
{value: 'ndst4', id: 'NDST4'},
{value: 'ndufa1', id: 'NDUFA1'},
{value: 'ndufa10', id: 'NDUFA10'},
{value: 'ndufa10l1', id: 'NDUFA10L1'},
{value: 'ndufa11', id: 'NDUFA11'},
{value: 'ndufa12', id: 'NDUFA12'},
{value: 'ndufa13-ps1', id: 'NDUFA13-PS1'},
{value: 'ndufa13', id: 'NDUFA13'},
{value: 'ndufa2', id: 'NDUFA2'},
{value: 'ndufa3', id: 'NDUFA3'},
{value: 'ndufa4', id: 'NDUFA4'},
{value: 'ndufa4l2', id: 'NDUFA4L2'},
{value: 'ndufa5', id: 'NDUFA5'},
{value: 'ndufa6', id: 'NDUFA6'},
{value: 'ndufa7', id: 'NDUFA7'},
{value: 'ndufa8', id: 'NDUFA8'},
{value: 'ndufa9', id: 'NDUFA9'},
{value: 'ndufab1', id: 'NDUFAB1'},
{value: 'ndufaf1', id: 'NDUFAF1'},
{value: 'ndufaf2', id: 'NDUFAF2'},
{value: 'ndufaf3', id: 'NDUFAF3'},
{value: 'ndufaf4', id: 'NDUFAF4'},
{value: 'ndufaf5', id: 'NDUFAF5'},
{value: 'ndufaf6', id: 'NDUFAF6'},
{value: 'ndufaf7', id: 'NDUFAF7'},
{value: 'ndufaf8', id: 'NDUFAF8'},
{value: 'ndufb10', id: 'NDUFB10'},
{value: 'ndufb11', id: 'NDUFB11'},
{value: 'ndufb2', id: 'NDUFB2'},
{value: 'ndufb3', id: 'NDUFB3'},
{value: 'ndufb4', id: 'NDUFB4'},
{value: 'ndufb4l1-ps1', id: 'NDUFB4L1-PS1'},
{value: 'ndufb5', id: 'NDUFB5'},
{value: 'ndufb6', id: 'NDUFB6'},
{value: 'ndufb7', id: 'NDUFB7'},
{value: 'ndufb8', id: 'NDUFB8'},
{value: 'ndufb9', id: 'NDUFB9'},
{value: 'ndufc1', id: 'NDUFC1'},
{value: 'ndufc2', id: 'NDUFC2'},
{value: 'ndufs1', id: 'NDUFS1'},
{value: 'ndufs2', id: 'NDUFS2'},
{value: 'ndufs3', id: 'NDUFS3'},
{value: 'ndufs4', id: 'NDUFS4'},
{value: 'ndufs5', id: 'NDUFS5'},
{value: 'ndufs6', id: 'NDUFS6'},
{value: 'ndufs7', id: 'NDUFS7'},
{value: 'ndufs8', id: 'NDUFS8'},
{value: 'ndufv1', id: 'NDUFV1'},
{value: 'ndufv2', id: 'NDUFV2'},
{value: 'ndufv3-ps1', id: 'NDUFV3-PS1'},
{value: 'ndufv3', id: 'NDUFV3'},
{value: 'neb', id: 'NEB'},
{value: 'nebl', id: 'NEBL'},
{value: 'necab1', id: 'NECAB1'},
{value: 'necab2', id: 'NECAB2'},
{value: 'necab3', id: 'NECAB3'},
{value: 'necap1', id: 'NECAP1'},
{value: 'necap2', id: 'NECAP2'},
{value: 'nectin1', id: 'NECTIN1'},
{value: 'nectin2', id: 'NECTIN2'},
{value: 'nectin3', id: 'NECTIN3'},
{value: 'nectin4', id: 'NECTIN4'},
{value: 'nedd1', id: 'NEDD1'},
{value: 'nedd4', id: 'NEDD4'},
{value: 'nedd4l', id: 'NEDD4L'},
{value: 'nedd8', id: 'NEDD8'},
{value: 'nedd9', id: 'NEDD9'},
{value: 'nefh', id: 'NEFH'},
{value: 'nefl', id: 'NEFL'},
{value: 'nefm', id: 'NEFM'},
{value: 'negr1', id: 'NEGR1'},
{value: 'neil1', id: 'NEIL1'},
{value: 'neil2', id: 'NEIL2'},
{value: 'neil3', id: 'NEIL3'},
{value: 'nek1', id: 'NEK1'},
{value: 'nek10', id: 'NEK10'},
{value: 'nek11', id: 'NEK11'},
{value: 'nek2', id: 'NEK2'},
{value: 'nek2l1', id: 'NEK2L1'},
{value: 'nek3', id: 'NEK3'},
{value: 'nek4', id: 'NEK4'},
{value: 'nek5', id: 'NEK5'},
{value: 'nek6', id: 'NEK6'},
{value: 'nek7', id: 'NEK7'},
{value: 'nek8', id: 'NEK8'},
{value: 'nek9', id: 'NEK9'},
{value: 'nelfa', id: 'NELFA'},
{value: 'nelfb', id: 'NELFB'},
{value: 'nelfcd', id: 'NELFCD'},
{value: 'nelfe', id: 'NELFE'},
{value: 'nell1', id: 'NELL1'},
{value: 'nell2', id: 'NELL2'},
{value: 'nemf', id: 'NEMF'},
{value: 'nemp1', id: 'NEMP1'},
{value: 'nemp2', id: 'NEMP2'},
{value: 'nenf', id: 'NENF'},
{value: 'neo1', id: 'NEO1'},
{value: 'nepn', id: 'NEPN'},
{value: 'nepro', id: 'NEPRO'},
{value: 'nes', id: 'NES'},
{value: 'net1', id: 'NET1'},
{value: 'neto1', id: 'NETO1'},
{value: 'neto2', id: 'NETO2'},
{value: 'neu1', id: 'NEU1'},
{value: 'neu2', id: 'NEU2'},
{value: 'neu3', id: 'NEU3'},
{value: 'neu4', id: 'NEU4'},
{value: 'neurl1', id: 'NEURL1'},
{value: 'neurl1b', id: 'NEURL1B'},
{value: 'neurl2', id: 'NEURL2'},
{value: 'neurl3', id: 'NEURL3'},
{value: 'neurl4', id: 'NEURL4'},
{value: 'neurod1', id: 'NEUROD1'},
{value: 'neurod2', id: 'NEUROD2'},
{value: 'neurod4', id: 'NEUROD4'},
{value: 'neurod6', id: 'NEUROD6'},
{value: 'neurog1', id: 'NEUROG1'},
{value: 'neurog2', id: 'NEUROG2'},
{value: 'neurog3', id: 'NEUROG3'},
{value: 'newgene_1587253', id: 'NEWGENE_1587253'},
{value: 'newgene_2324572', id: 'NEWGENE_2324572'},
{value: 'newgene_619861', id: 'NEWGENE_619861'},
{value: 'newgene_6497122', id: 'NEWGENE_6497122'},
{value: 'newgene_735020', id: 'NEWGENE_735020'},
{value: 'nexmif', id: 'NEXMIF'},
{value: 'nexn', id: 'NEXN'},
{value: 'nf1', id: 'NF1'},
{value: 'nf2', id: 'NF2'},
{value: 'nfam1', id: 'NFAM1'},
{value: 'nfasc', id: 'NFASC'},
{value: 'nfat5', id: 'NFAT5'},
{value: 'nfatc1', id: 'NFATC1'},
{value: 'nfatc2', id: 'NFATC2'},
{value: 'nfatc2ip', id: 'NFATC2IP'},
{value: 'nfatc3', id: 'NFATC3'},
{value: 'nfatc4', id: 'NFATC4'},
{value: 'nfe2', id: 'NFE2'},
{value: 'nfe2l1', id: 'NFE2L1'},
{value: 'nfe2l2', id: 'NFE2L2'},
{value: 'nfe2l3', id: 'NFE2L3'},
{value: 'nfia', id: 'NFIA'},
{value: 'nfib', id: 'NFIB'},
{value: 'nfic', id: 'NFIC'},
{value: 'nfil3', id: 'NFIL3'},
{value: 'nfix', id: 'NFIX'},
{value: 'nfkb1', id: 'NFKB1'},
{value: 'nfkb2', id: 'NFKB2'},
{value: 'nfkbia', id: 'NFKBIA'},
{value: 'nfkbib', id: 'NFKBIB'},
{value: 'nfkbid', id: 'NFKBID'},
{value: 'nfkbie', id: 'NFKBIE'},
{value: 'nfkbil1', id: 'NFKBIL1'},
{value: 'nfkbiz', id: 'NFKBIZ'},
{value: 'nfrkb', id: 'NFRKB'},
{value: 'nfs1', id: 'NFS1'},
{value: 'nfu1', id: 'NFU1'},
{value: 'nfx1', id: 'NFX1'},
{value: 'nfxl1', id: 'NFXL1'},
{value: 'nfya', id: 'NFYA'},
{value: 'nfyb', id: 'NFYB'},
{value: 'nfyc', id: 'NFYC'},
{value: 'ngb', id: 'NGB'},
{value: 'ngdn', id: 'NGDN'},
{value: 'ngef', id: 'NGEF'},
{value: 'ngf', id: 'NGF'},
{value: 'ngfr', id: 'NGFR'},
{value: 'ngly1', id: 'NGLY1'},
{value: 'ngp', id: 'NGP'},
{value: 'ngrn', id: 'NGRN'},
{value: 'nhej1', id: 'NHEJ1'},
{value: 'nhlh1', id: 'NHLH1'},
{value: 'nhlh2', id: 'NHLH2'},
{value: 'nhlrc1', id: 'NHLRC1'},
{value: 'nhlrc2', id: 'NHLRC2'},
{value: 'nhlrc3', id: 'NHLRC3'},
{value: 'nhlrc4', id: 'NHLRC4'},
{value: 'nhp2', id: 'NHP2'},
{value: 'nhs', id: 'NHS'},
{value: 'nhsl1', id: 'NHSL1'},
{value: 'nhsl2', id: 'NHSL2'},
{value: 'niban1', id: 'NIBAN1'},
{value: 'niban2', id: 'NIBAN2'},
{value: 'niban3', id: 'NIBAN3'},
{value: 'nicn1', id: 'NICN1'},
{value: 'nid1', id: 'NID1'},
{value: 'nid2', id: 'NID2'},
{value: 'nif3l1', id: 'NIF3L1'},
{value: 'nifk', id: 'NIFK'},
{value: 'nim1k', id: 'NIM1K'},
{value: 'nin', id: 'NIN'},
{value: 'ninj1', id: 'NINJ1'},
{value: 'ninj2', id: 'NINJ2'},
{value: 'ninl', id: 'NINL'},
{value: 'nip7', id: 'NIP7'},
{value: 'nipa1', id: 'NIPA1'},
{value: 'nipa2', id: 'NIPA2'},
{value: 'nipal1', id: 'NIPAL1'},
{value: 'nipal2', id: 'NIPAL2'},
{value: 'nipal3', id: 'NIPAL3'},
{value: 'nipal4', id: 'NIPAL4'},
{value: 'nipbl', id: 'NIPBL'},
{value: 'nipsnap1', id: 'NIPSNAP1'},
{value: 'nipsnap2', id: 'NIPSNAP2'},
{value: 'nipsnap3a', id: 'NIPSNAP3A'},
{value: 'nipsnap3b', id: 'NIPSNAP3B'},
{value: 'nisch', id: 'NISCH'},
{value: 'nit1', id: 'NIT1'},
{value: 'nit2', id: 'NIT2'},
{value: 'nkain1', id: 'NKAIN1'},
{value: 'nkain2', id: 'NKAIN2'},
{value: 'nkain3', id: 'NKAIN3'},
{value: 'nkain4', id: 'NKAIN4'},
{value: 'nkap', id: 'NKAP'},
{value: 'nkapd1', id: 'NKAPD1'},
{value: 'nkapl', id: 'NKAPL'},
{value: 'nkd1', id: 'NKD1'},
{value: 'nkd2', id: 'NKD2'},
{value: 'nkg7', id: 'NKG7'},
{value: 'nkiras1', id: 'NKIRAS1'},
{value: 'nkiras2', id: 'NKIRAS2'},
{value: 'nkpd1', id: 'NKPD1'},
{value: 'nkrf', id: 'NKRF'},
{value: 'nktr', id: 'NKTR'},
{value: 'nkx1-1', id: 'NKX1-1'},
{value: 'nkx1-2', id: 'NKX1-2'},
{value: 'nkx2-1', id: 'NKX2-1'},
{value: 'nkx2-2', id: 'NKX2-2'},
{value: 'nkx2-3', id: 'NKX2-3'},
{value: 'nkx2-4', id: 'NKX2-4'},
{value: 'nkx2-5', id: 'NKX2-5'},
{value: 'nkx2-6', id: 'NKX2-6'},
{value: 'nkx2-8', id: 'NKX2-8'},
{value: 'nkx3-1', id: 'NKX3-1'},
{value: 'nkx3-2', id: 'NKX3-2'},
{value: 'nkx6-1', id: 'NKX6-1'},
{value: 'nkx6-2', id: 'NKX6-2'},
{value: 'nkx6-3', id: 'NKX6-3'},
{value: 'nle1', id: 'NLE1'},
{value: 'nlgn1', id: 'NLGN1'},
{value: 'nlgn2', id: 'NLGN2'},
{value: 'nlgn3', id: 'NLGN3'},
{value: 'nlk', id: 'NLK'},
{value: 'nln', id: 'NLN'},
{value: 'nlrc3', id: 'NLRC3'},
{value: 'nlrc4', id: 'NLRC4'},
{value: 'nlrc5', id: 'NLRC5'},
{value: 'nlrp10', id: 'NLRP10'},
{value: 'nlrp12', id: 'NLRP12'},
{value: 'nlrp14', id: 'NLRP14'},
{value: 'nlrp1a', id: 'NLRP1A'},
{value: 'nlrp1b', id: 'NLRP1B'},
{value: 'nlrp2', id: 'NLRP2'},
{value: 'nlrp3', id: 'NLRP3'},
{value: 'nlrp4', id: 'NLRP4'},
{value: 'nlrp4a', id: 'NLRP4A'},
{value: 'nlrp4b', id: 'NLRP4B'},
{value: 'nlrp4f', id: 'NLRP4F'},
{value: 'nlrp5', id: 'NLRP5'},
{value: 'nlrp6', id: 'NLRP6'},
{value: 'nlrp9', id: 'NLRP9'},
{value: 'nlrx1', id: 'NLRX1'},
{value: 'nmb', id: 'NMB'},
{value: 'nmbr', id: 'NMBR'},
{value: 'nmd3', id: 'NMD3'},
{value: 'nme1', id: 'NME1'},
{value: 'nme2', id: 'NME2'},
{value: 'nme3', id: 'NME3'},
{value: 'nme4', id: 'NME4'},
{value: 'nme5', id: 'NME5'},
{value: 'nme6', id: 'NME6'},
{value: 'nme7', id: 'NME7'},
{value: 'nme8', id: 'NME8'},
{value: 'nme9', id: 'NME9'},
{value: 'nmi', id: 'NMI'},
{value: 'nmnat1', id: 'NMNAT1'},
{value: 'nmnat2', id: 'NMNAT2'},
{value: 'nmnat3', id: 'NMNAT3'},
{value: 'nmral1', id: 'NMRAL1'},
{value: 'nmrk1', id: 'NMRK1'},
{value: 'nmrk2', id: 'NMRK2'},
{value: 'nms', id: 'NMS'},
{value: 'nmt1', id: 'NMT1'},
{value: 'nmt2', id: 'NMT2'},
{value: 'nmu', id: 'NMU'},
{value: 'nmur1', id: 'NMUR1'},
{value: 'nmur2', id: 'NMUR2'},
{value: 'nnat', id: 'NNAT'},
{value: 'nnmt', id: 'NNMT'},
{value: 'nnt', id: 'NNT'},
{value: 'noa1', id: 'NOA1'},
{value: 'nob1', id: 'NOB1'},
{value: 'nobox', id: 'NOBOX'},
{value: 'noc2l', id: 'NOC2L'},
{value: 'noc3l', id: 'NOC3L'},
{value: 'noc4l', id: 'NOC4L'},
{value: 'noct', id: 'NOCT'},
{value: 'nod1', id: 'NOD1'},
{value: 'nod2', id: 'NOD2'},
{value: 'nodal', id: 'NODAL'},
{value: 'nog', id: 'NOG'},
{value: 'nol10', id: 'NOL10'},
{value: 'nol11', id: 'NOL11'},
{value: 'nol12', id: 'NOL12'},
{value: 'nol3', id: 'NOL3'},
{value: 'nol4', id: 'NOL4'},
{value: 'nol4l', id: 'NOL4L'},
{value: 'nol6', id: 'NOL6'},
{value: 'nol7', id: 'NOL7'},
{value: 'nol8', id: 'NOL8'},
{value: 'nol9', id: 'NOL9'},
{value: 'nolc1', id: 'NOLC1'},
{value: 'nom1', id: 'NOM1'},
{value: 'nomo1', id: 'NOMO1'},
{value: 'nono', id: 'NONO'},
{value: 'nop10', id: 'NOP10'},
{value: 'nop14', id: 'NOP14'},
{value: 'nop16', id: 'NOP16'},
{value: 'nop2', id: 'NOP2'},
{value: 'nop53', id: 'NOP53'},
{value: 'nop56', id: 'NOP56'},
{value: 'nop58', id: 'NOP58'},
{value: 'nop9', id: 'NOP9'},
{value: 'nopchap1', id: 'NOPCHAP1'},
{value: 'nos1', id: 'NOS1'},
{value: 'nos1ap', id: 'NOS1AP'},
{value: 'nos2', id: 'NOS2'},
{value: 'nos3', id: 'NOS3'},
{value: 'nosip', id: 'NOSIP'},
{value: 'nostrin', id: 'NOSTRIN'},
{value: 'notch1', id: 'NOTCH1'},
{value: 'notch2', id: 'NOTCH2'},
{value: 'notch3', id: 'NOTCH3'},
{value: 'notch4', id: 'NOTCH4'},
{value: 'noto', id: 'NOTO'},
{value: 'notum', id: 'NOTUM'},
{value: 'nova1', id: 'NOVA1'},
{value: 'nova2', id: 'NOVA2'},
{value: 'nox1', id: 'NOX1'},
{value: 'nox3', id: 'NOX3'},
{value: 'nox4', id: 'NOX4'},
{value: 'noxa1', id: 'NOXA1'},
{value: 'noxo1', id: 'NOXO1'},
{value: 'noxred1', id: 'NOXRED1'},
{value: 'np4', id: 'NP4'},
{value: 'npas1', id: 'NPAS1'},
{value: 'npas2', id: 'NPAS2'},
{value: 'npas3', id: 'NPAS3'},
{value: 'npas4', id: 'NPAS4'},
{value: 'npat', id: 'NPAT'},
{value: 'npb', id: 'NPB'},
{value: 'npbwr1', id: 'NPBWR1'},
{value: 'npc1', id: 'NPC1'},
{value: 'npc1l1', id: 'NPC1L1'},
{value: 'npc2', id: 'NPC2'},
{value: 'npdc1', id: 'NPDC1'},
{value: 'npepl1', id: 'NPEPL1'},
{value: 'npepps', id: 'NPEPPS'},
{value: 'npff', id: 'NPFF'},
{value: 'npffr1', id: 'NPFFR1'},
{value: 'npffr2', id: 'NPFFR2'},
{value: 'nphp1', id: 'NPHP1'},
{value: 'nphp3', id: 'NPHP3'},
{value: 'nphp4', id: 'NPHP4'},
{value: 'nphs1', id: 'NPHS1'},
{value: 'nphs2', id: 'NPHS2'},
{value: 'npl', id: 'NPL'},
{value: 'nploc4', id: 'NPLOC4'},
{value: 'npm1', id: 'NPM1'},
{value: 'npm2', id: 'NPM2'},
{value: 'npm3', id: 'NPM3'},
{value: 'npnt', id: 'NPNT'},
{value: 'nppa', id: 'NPPA'},
{value: 'nppb', id: 'NPPB'},
{value: 'nppc', id: 'NPPC'},
{value: 'npr1', id: 'NPR1'},
{value: 'npr2', id: 'NPR2'},
{value: 'npr3', id: 'NPR3'},
{value: 'nprl2', id: 'NPRL2'},
{value: 'nprl3', id: 'NPRL3'},
{value: 'nps', id: 'NPS'},
{value: 'npsr1', id: 'NPSR1'},
{value: 'nptn', id: 'NPTN'},
{value: 'nptx1', id: 'NPTX1'},
{value: 'nptx2', id: 'NPTX2'},
{value: 'nptxr', id: 'NPTXR'},
{value: 'npvf', id: 'NPVF'},
{value: 'npw', id: 'NPW'},
{value: 'npy', id: 'NPY'},
{value: 'npy1r', id: 'NPY1R'},
{value: 'npy2r', id: 'NPY2R'},
{value: 'npy4r', id: 'NPY4R'},
{value: 'npy5r', id: 'NPY5R'},
{value: 'nqo1', id: 'NQO1'},
{value: 'nqo2', id: 'NQO2'},
{value: 'nr0b1', id: 'NR0B1'},
{value: 'nr0b2', id: 'NR0B2'},
{value: 'nr1d1', id: 'NR1D1'},
{value: 'nr1d2', id: 'NR1D2'},
{value: 'nr1h2', id: 'NR1H2'},
{value: 'nr1h3', id: 'NR1H3'},
{value: 'nr1h4', id: 'NR1H4'},
{value: 'nr1h5', id: 'NR1H5'},
{value: 'nr1i2', id: 'NR1I2'},
{value: 'nr1i3', id: 'NR1I3'},
{value: 'nr2c1', id: 'NR2C1'},
{value: 'nr2c2', id: 'NR2C2'},
{value: 'nr2c2ap', id: 'NR2C2AP'},
{value: 'nr2e1', id: 'NR2E1'},
{value: 'nr2e3', id: 'NR2E3'},
{value: 'nr2f1', id: 'NR2F1'},
{value: 'nr2f2', id: 'NR2F2'},
{value: 'nr2f6', id: 'NR2F6'},
{value: 'nr3c1', id: 'NR3C1'},
{value: 'nr3c2', id: 'NR3C2'},
{value: 'nr4a1', id: 'NR4A1'},
{value: 'nr4a2', id: 'NR4A2'},
{value: 'nr4a3', id: 'NR4A3'},
{value: 'nr5a1', id: 'NR5A1'},
{value: 'nr5a2', id: 'NR5A2'},
{value: 'nr6a1', id: 'NR6A1'},
{value: 'nradd', id: 'NRADD'},
{value: 'nrap', id: 'NRAP'},
{value: 'nrarp', id: 'NRARP'},
{value: 'nras', id: 'NRAS'},
{value: 'nrbf2', id: 'NRBF2'},
{value: 'nrbp1', id: 'NRBP1'},
{value: 'nrbp2', id: 'NRBP2'},
{value: 'nrcam', id: 'NRCAM'},
{value: 'nrdc', id: 'NRDC'},
{value: 'nrde2', id: 'NRDE2'},
{value: 'nrep', id: 'NREP'},
{value: 'nrf1', id: 'NRF1'},
{value: 'nrg1', id: 'NRG1'},
{value: 'nrg2', id: 'NRG2'},
{value: 'nrg3', id: 'NRG3'},
{value: 'nrg4', id: 'NRG4'},
{value: 'nrgn', id: 'NRGN'},
{value: 'nrip1', id: 'NRIP1'},
{value: 'nrip2', id: 'NRIP2'},
{value: 'nrip3', id: 'NRIP3'},
{value: 'nrk', id: 'NRK'},
{value: 'nrl', id: 'NRL'},
{value: 'nrm', id: 'NRM'},
{value: 'nrn1', id: 'NRN1'},
{value: 'nrn1l', id: 'NRN1L'},
{value: 'nron', id: 'NRON'},
{value: 'nrp1', id: 'NRP1'},
{value: 'nrp2', id: 'NRP2'},
{value: 'nrros', id: 'NRROS'},
{value: 'nrsn1', id: 'NRSN1'},
{value: 'nrsn2', id: 'NRSN2'},
{value: 'nrtn', id: 'NRTN'},
{value: 'nrxn1', id: 'NRXN1'},
{value: 'nrxn2', id: 'NRXN2'},
{value: 'nrxn3', id: 'NRXN3'},
{value: 'ns5atp4', id: 'NS5ATP4'},
{value: 'nsa2', id: 'NSA2'},
{value: 'nsd1', id: 'NSD1'},
{value: 'nsd2', id: 'NSD2'},
{value: 'nsd3', id: 'NSD3'},
{value: 'nsdhl', id: 'NSDHL'},
{value: 'nsf', id: 'NSF'},
{value: 'nsfl1c', id: 'NSFL1C'},
{value: 'nsg1', id: 'NSG1'},
{value: 'nsg2', id: 'NSG2'},
{value: 'nsl1', id: 'NSL1'},
{value: 'nsmaf', id: 'NSMAF'},
{value: 'nsmce1', id: 'NSMCE1'},
{value: 'nsmce2', id: 'NSMCE2'},
{value: 'nsmce3', id: 'NSMCE3'},
{value: 'nsmce4a', id: 'NSMCE4A'},
{value: 'nsmf', id: 'NSMF'},
{value: 'nsrp1', id: 'NSRP1'},
{value: 'nsun2', id: 'NSUN2'},
{value: 'nsun3', id: 'NSUN3'},
{value: 'nsun4', id: 'NSUN4'},
{value: 'nsun5', id: 'NSUN5'},
{value: 'nsun6', id: 'NSUN6'},
{value: 'nsun7', id: 'NSUN7'},
{value: 'nt5c', id: 'NT5C'},
{value: 'nt5c1a', id: 'NT5C1A'},
{value: 'nt5c1b', id: 'NT5C1B'},
{value: 'nt5c2', id: 'NT5C2'},
{value: 'nt5c3a', id: 'NT5C3A'},
{value: 'nt5c3b', id: 'NT5C3B'},
{value: 'nt5dc1', id: 'NT5DC1'},
{value: 'nt5dc2', id: 'NT5DC2'},
{value: 'nt5dc3', id: 'NT5DC3'},
{value: 'nt5e', id: 'NT5E'},
{value: 'nt5m', id: 'NT5M'},
{value: 'ntan1', id: 'NTAN1'},
{value: 'ntaq1', id: 'NTAQ1'},
{value: 'ntf3', id: 'NTF3'},
{value: 'ntf4', id: 'NTF4'},
{value: 'nthl1', id: 'NTHL1'},
{value: 'ntm', id: 'NTM'},
{value: 'ntmt1', id: 'NTMT1'},
{value: 'ntmt2', id: 'NTMT2'},
{value: 'ntn1', id: 'NTN1'},
{value: 'ntn3', id: 'NTN3'},
{value: 'ntn4', id: 'NTN4'},
{value: 'ntn5', id: 'NTN5'},
{value: 'ntng1', id: 'NTNG1'},
{value: 'ntng2', id: 'NTNG2'},
{value: 'ntpcr', id: 'NTPCR'},
{value: 'ntrk1', id: 'NTRK1'},
{value: 'ntrk2', id: 'NTRK2'},
{value: 'ntrk3', id: 'NTRK3'},
{value: 'nts', id: 'NTS'},
{value: 'ntsr1', id: 'NTSR1'},
{value: 'ntsr2', id: 'NTSR2'},
{value: 'nuak1', id: 'NUAK1'},
{value: 'nuak2', id: 'NUAK2'},
{value: 'nub1', id: 'NUB1'},
{value: 'nubp1', id: 'NUBP1'},
{value: 'nubp2', id: 'NUBP2'},
{value: 'nubpl', id: 'NUBPL'},
{value: 'nucb1', id: 'NUCB1'},
{value: 'nucb2', id: 'NUCB2'},
{value: 'nucks1', id: 'NUCKS1'},
{value: 'nudc', id: 'NUDC'},
{value: 'nudcd1', id: 'NUDCD1'},
{value: 'nudcd2', id: 'NUDCD2'},
{value: 'nudcd3', id: 'NUDCD3'},
{value: 'nudt1', id: 'NUDT1'},
{value: 'nudt10', id: 'NUDT10'},
{value: 'nudt11', id: 'NUDT11'},
{value: 'nudt12', id: 'NUDT12'},
{value: 'nudt13', id: 'NUDT13'},
{value: 'nudt14', id: 'NUDT14'},
{value: 'nudt15', id: 'NUDT15'},
{value: 'nudt16', id: 'NUDT16'},
{value: 'nudt16l1', id: 'NUDT16L1'},
{value: 'nudt17', id: 'NUDT17'},
{value: 'nudt18', id: 'NUDT18'},
{value: 'nudt19', id: 'NUDT19'},
{value: 'nudt2', id: 'NUDT2'},
{value: 'nudt21', id: 'NUDT21'},
{value: 'nudt22', id: 'NUDT22'},
{value: 'nudt3', id: 'NUDT3'},
{value: 'nudt4', id: 'NUDT4'},
{value: 'nudt5', id: 'NUDT5'},
{value: 'nudt6', id: 'NUDT6'},
{value: 'nudt7', id: 'NUDT7'},
{value: 'nudt8', id: 'NUDT8'},
{value: 'nudt9', id: 'NUDT9'},
{value: 'nuf2', id: 'NUF2'},
{value: 'nufip1', id: 'NUFIP1'},
{value: 'nufip2', id: 'NUFIP2'},
{value: 'nuggc', id: 'NUGGC'},
{value: 'numa1', id: 'NUMA1'},
{value: 'numb', id: 'NUMB'},
{value: 'numbl', id: 'NUMBL'},
{value: 'nup107', id: 'NUP107'},
{value: 'nup133', id: 'NUP133'},
{value: 'nup153', id: 'NUP153'},
{value: 'nup155', id: 'NUP155'},
{value: 'nup160', id: 'NUP160'},
{value: 'nup188', id: 'NUP188'},
{value: 'nup205', id: 'NUP205'},
{value: 'nup210', id: 'NUP210'},
{value: 'nup210l', id: 'NUP210L'},
{value: 'nup214', id: 'NUP214'},
{value: 'nup35', id: 'NUP35'},
{value: 'nup37', id: 'NUP37'},
{value: 'nup43', id: 'NUP43'},
{value: 'nup50', id: 'NUP50'},
{value: 'nup54', id: 'NUP54'},
{value: 'nup58', id: 'NUP58'},
{value: 'nup62', id: 'NUP62'},
{value: 'nup62cl', id: 'NUP62CL'},
{value: 'nup85', id: 'NUP85'},
{value: 'nup88', id: 'NUP88'},
{value: 'nup93', id: 'NUP93'},
{value: 'nup98', id: 'NUP98'},
{value: 'nupl2', id: 'NUPL2'},
{value: 'nupr1', id: 'NUPR1'},
{value: 'nupr1l1', id: 'NUPR1L1'},
{value: 'nupr2', id: 'NUPR2'},
{value: 'nus1', id: 'NUS1'},
{value: 'nusap1', id: 'NUSAP1'},
{value: 'nutf2', id: 'NUTF2'},
{value: 'nutm1', id: 'NUTM1'},
{value: 'nutm2f', id: 'NUTM2F'},
{value: 'nvl', id: 'NVL'},
{value: 'nwd1', id: 'NWD1'},
{value: 'nwd2', id: 'NWD2'},
{value: 'nxf1', id: 'NXF1'},
{value: 'nxf2', id: 'NXF2'},
{value: 'nxf3', id: 'NXF3'},
{value: 'nxf5', id: 'NXF5'},
{value: 'nxf7', id: 'NXF7'},
{value: 'nxn', id: 'NXN'},
{value: 'nxnl1', id: 'NXNL1'},
{value: 'nxnl2', id: 'NXNL2'},
{value: 'nxpe1', id: 'NXPE1'},
{value: 'nxpe2', id: 'NXPE2'},
{value: 'nxpe3', id: 'NXPE3'},
{value: 'nxpe4', id: 'NXPE4'},
{value: 'nxpe5', id: 'NXPE5'},
{value: 'nxpe5l1', id: 'NXPE5L1'},
{value: 'nxph1', id: 'NXPH1'},
{value: 'nxph2', id: 'NXPH2'},
{value: 'nxph3', id: 'NXPH3'},
{value: 'nxph4', id: 'NXPH4'},
{value: 'nxt1', id: 'NXT1'},
{value: 'nxt2', id: 'NXT2'},
{value: 'nyap1', id: 'NYAP1'},
{value: 'nyap2', id: 'NYAP2'},
{value: 'nynrin', id: 'NYNRIN'},
{value: 'nyx', id: 'NYX'},
{value: 'oacyl', id: 'OACYL'},
{value: 'oaf', id: 'OAF'},
{value: 'oard1', id: 'OARD1'},
{value: 'oas1a', id: 'OAS1A'},
{value: 'oas1b', id: 'OAS1B'},
{value: 'oas1d', id: 'OAS1D'},
{value: 'oas1e', id: 'OAS1E'},
{value: 'oas1f', id: 'OAS1F'},
{value: 'oas1g', id: 'OAS1G'},
{value: 'oas1h', id: 'OAS1H'},
{value: 'oas1i', id: 'OAS1I'},
{value: 'oas1k', id: 'OAS1K'},
{value: 'oas2', id: 'OAS2'},
{value: 'oas3', id: 'OAS3'},
{value: 'oasl', id: 'OASL'},
{value: 'oasl2', id: 'OASL2'},
{value: 'oat', id: 'OAT'},
{value: 'oaz1', id: 'OAZ1'},
{value: 'oaz2', id: 'OAZ2'},
{value: 'oaz3', id: 'OAZ3'},
{value: 'obi1', id: 'OBI1'},
{value: 'obox2', id: 'OBOX2'},
{value: 'obp1f', id: 'OBP1F'},
{value: 'obp2a', id: 'OBP2A'},
{value: 'obp2b', id: 'OBP2B'},
{value: 'obp3', id: 'OBP3'},
{value: 'obscn', id: 'OBSCN'},
{value: 'obsl1', id: 'OBSL1'},
{value: 'oc90', id: 'OC90'},
{value: 'oca2', id: 'OCA2'},
{value: 'ocel1', id: 'OCEL1'},
{value: 'ociad1', id: 'OCIAD1'},
{value: 'ociad2', id: 'OCIAD2'},
{value: 'ocln', id: 'OCLN'},
{value: 'ocm', id: 'OCM'},
{value: 'ocrl', id: 'OCRL'},
{value: 'ocstamp', id: 'OCSTAMP'},
{value: 'odad1', id: 'ODAD1'},
{value: 'odad2', id: 'ODAD2'},
{value: 'odad3', id: 'ODAD3'},
{value: 'odad4', id: 'ODAD4'},
{value: 'odam', id: 'ODAM'},
{value: 'odaph', id: 'ODAPH'},
{value: 'odc1', id: 'ODC1'},
{value: 'odf1', id: 'ODF1'},
{value: 'odf2', id: 'ODF2'},
{value: 'odf2l', id: 'ODF2L'},
{value: 'odf3', id: 'ODF3'},
{value: 'odf3b', id: 'ODF3B'},
{value: 'odf3l1', id: 'ODF3L1'},
{value: 'odf3l2', id: 'ODF3L2'},
{value: 'odf4', id: 'ODF4'},
{value: 'odr4', id: 'ODR4'},
{value: 'ofcc1', id: 'OFCC1'},
{value: 'ofd1', id: 'OFD1'},
{value: 'oga', id: 'OGA'},
{value: 'ogdh', id: 'OGDH'},
{value: 'ogdhl', id: 'OGDHL'},
{value: 'ogfod1', id: 'OGFOD1'},
{value: 'ogfod2', id: 'OGFOD2'},
{value: 'ogfod3', id: 'OGFOD3'},
{value: 'ogfr', id: 'OGFR'},
{value: 'ogfrl1', id: 'OGFRL1'},
{value: 'ogg1', id: 'OGG1'},
{value: 'ogn', id: 'OGN'},
{value: 'ogt', id: 'OGT'},
{value: 'oip5', id: 'OIP5'},
{value: 'oit3', id: 'OIT3'},
{value: 'ola1', id: 'OLA1'},
{value: 'olah', id: 'OLAH'},
{value: 'olfm1', id: 'OLFM1'},
{value: 'olfm2', id: 'OLFM2'},
{value: 'olfm3', id: 'OLFM3'},
{value: 'olfm4', id: 'OLFM4'},
{value: 'olfml1', id: 'OLFML1'},
{value: 'olfml2a', id: 'OLFML2A'},
{value: 'olfml2b', id: 'OLFML2B'},
{value: 'olfml3', id: 'OLFML3'},
{value: 'olfr1085', id: 'OLFR1085'},
{value: 'olfr122', id: 'OLFR122'},
{value: 'olfr1320', id: 'OLFR1320'},
{value: 'olfr1330-ps1', id: 'OLFR1330-PS1'},
{value: 'olfr1442', id: 'OLFR1442'},
{value: 'olfr225', id: 'OLFR225'},
{value: 'olfr286', id: 'OLFR286'},
{value: 'olfr574', id: 'OLFR574'},
{value: 'olfr656', id: 'OLFR656'},
{value: 'olfr822', id: 'OLFR822'},
{value: 'olfr873', id: 'OLFR873'},
{value: 'olig1', id: 'OLIG1'},
{value: 'olig2', id: 'OLIG2'},
{value: 'olig3', id: 'OLIG3'},
{value: 'olr1', id: 'OLR1'},
{value: 'olr10', id: 'OLR10'},
{value: 'olr1002', id: 'OLR1002'},
{value: 'olr1006', id: 'OLR1006'},
{value: 'olr1007', id: 'OLR1007'},
{value: 'olr1012', id: 'OLR1012'},
{value: 'olr1016', id: 'OLR1016'},
{value: 'olr1020', id: 'OLR1020'},
{value: 'olr1022', id: 'OLR1022'},
{value: 'olr1029', id: 'OLR1029'},
{value: 'olr103', id: 'OLR103'},
{value: 'olr104', id: 'OLR104'},
{value: 'olr1049', id: 'OLR1049'},
{value: 'olr105', id: 'OLR105'},
{value: 'olr1055', id: 'OLR1055'},
{value: 'olr1057', id: 'OLR1057'},
{value: 'olr1058', id: 'OLR1058'},
{value: 'olr1059', id: 'OLR1059'},
{value: 'olr106', id: 'OLR106'},
{value: 'olr1060', id: 'OLR1060'},
{value: 'olr1061', id: 'OLR1061'},
{value: 'olr1063', id: 'OLR1063'},
{value: 'olr1064', id: 'OLR1064'},
{value: 'olr1065', id: 'OLR1065'},
{value: 'olr1067', id: 'OLR1067'},
{value: 'olr1068', id: 'OLR1068'},
{value: 'olr1069', id: 'OLR1069'},
{value: 'olr107', id: 'OLR107'},
{value: 'olr1070', id: 'OLR1070'},
{value: 'olr1071', id: 'OLR1071'},
{value: 'olr1072', id: 'OLR1072'},
{value: 'olr1073', id: 'OLR1073'},
{value: 'olr1075', id: 'OLR1075'},
{value: 'olr1076', id: 'OLR1076'},
{value: 'olr1077', id: 'OLR1077'},
{value: 'olr1078', id: 'OLR1078'},
{value: 'olr1079', id: 'OLR1079'},
{value: 'olr108', id: 'OLR108'},
{value: 'olr1081', id: 'OLR1081'},
{value: 'olr1082', id: 'OLR1082'},
{value: 'olr1083', id: 'OLR1083'},
{value: 'olr1084', id: 'OLR1084'},
{value: 'olr1085', id: 'OLR1085'},
{value: 'olr1086', id: 'OLR1086'},
{value: 'olr1087', id: 'OLR1087'},
{value: 'olr109', id: 'OLR109'},
{value: 'olr1090', id: 'OLR1090'},
{value: 'olr1091', id: 'OLR1091'},
{value: 'olr1095', id: 'OLR1095'},
{value: 'olr1096', id: 'OLR1096'},
{value: 'olr11', id: 'OLR11'},
{value: 'olr110', id: 'OLR110'},
{value: 'olr1102', id: 'OLR1102'},
{value: 'olr1104', id: 'OLR1104'},
{value: 'olr1105', id: 'OLR1105'},
{value: 'olr1106', id: 'OLR1106'},
{value: 'olr1107', id: 'OLR1107'},
{value: 'olr1108', id: 'OLR1108'},
{value: 'olr1111', id: 'OLR1111'},
{value: 'olr1115', id: 'OLR1115'},
{value: 'olr1117', id: 'OLR1117'},
{value: 'olr1118', id: 'OLR1118'},
{value: 'olr1119', id: 'OLR1119'},
{value: 'olr112', id: 'OLR112'},
{value: 'olr1121', id: 'OLR1121'},
{value: 'olr1122', id: 'OLR1122'},
{value: 'olr1124', id: 'OLR1124'},
{value: 'olr1125', id: 'OLR1125'},
{value: 'olr1126', id: 'OLR1126'},
{value: 'olr1128', id: 'OLR1128'},
{value: 'olr1129', id: 'OLR1129'},
{value: 'olr1130', id: 'OLR1130'},
{value: 'olr1132', id: 'OLR1132'},
{value: 'olr1135', id: 'OLR1135'},
{value: 'olr1142', id: 'OLR1142'},
{value: 'olr1143', id: 'OLR1143'},
{value: 'olr1144', id: 'OLR1144'},
{value: 'olr1145', id: 'OLR1145'},
{value: 'olr1147', id: 'OLR1147'},
{value: 'olr1148', id: 'OLR1148'},
{value: 'olr1149', id: 'OLR1149'},
{value: 'olr115', id: 'OLR115'},
{value: 'olr1151', id: 'OLR1151'},
{value: 'olr1155', id: 'OLR1155'},
{value: 'olr1156', id: 'OLR1156'},
{value: 'olr1158', id: 'OLR1158'},
{value: 'olr1159', id: 'OLR1159'},
{value: 'olr1160', id: 'OLR1160'},
{value: 'olr1162', id: 'OLR1162'},
{value: 'olr1163', id: 'OLR1163'},
{value: 'olr1164', id: 'OLR1164'},
{value: 'olr1165', id: 'OLR1165'},
{value: 'olr1166', id: 'OLR1166'},
{value: 'olr1169', id: 'OLR1169'},
{value: 'olr1171', id: 'OLR1171'},
{value: 'olr1172', id: 'OLR1172'},
{value: 'olr1174', id: 'OLR1174'},
{value: 'olr1179', id: 'OLR1179'},
{value: 'olr1185', id: 'OLR1185'},
{value: 'olr1186', id: 'OLR1186'},
{value: 'olr119', id: 'OLR119'},
{value: 'olr1192', id: 'OLR1192'},
{value: 'olr1193', id: 'OLR1193'},
{value: 'olr1194', id: 'OLR1194'},
{value: 'olr1195', id: 'OLR1195'},
{value: 'olr1196', id: 'OLR1196'},
{value: 'olr1197', id: 'OLR1197'},
{value: 'olr1199', id: 'OLR1199'},
{value: 'olr12', id: 'OLR12'},
{value: 'olr120', id: 'OLR120'},
{value: 'olr1200', id: 'OLR1200'},
{value: 'olr1201', id: 'OLR1201'},
{value: 'olr1202', id: 'OLR1202'},
{value: 'olr1203', id: 'OLR1203'},
{value: 'olr1204', id: 'OLR1204'},
{value: 'olr1206', id: 'OLR1206'},
{value: 'olr121', id: 'OLR121'},
{value: 'olr1213', id: 'OLR1213'},
{value: 'olr1214', id: 'OLR1214'},
{value: 'olr1217', id: 'OLR1217'},
{value: 'olr1218', id: 'OLR1218'},
{value: 'olr1219', id: 'OLR1219'},
{value: 'olr122', id: 'OLR122'},
{value: 'olr1223', id: 'OLR1223'},
{value: 'olr1225', id: 'OLR1225'},
{value: 'olr1226', id: 'OLR1226'},
{value: 'olr1227', id: 'OLR1227'},
{value: 'olr1229', id: 'OLR1229'},
{value: 'olr1230', id: 'OLR1230'},
{value: 'olr1231', id: 'OLR1231'},
{value: 'olr1233', id: 'OLR1233'},
{value: 'olr1234', id: 'OLR1234'},
{value: 'olr1235', id: 'OLR1235'},
{value: 'olr1236', id: 'OLR1236'},
{value: 'olr1237', id: 'OLR1237'},
{value: 'olr124', id: 'OLR124'},
{value: 'olr1240', id: 'OLR1240'},
{value: 'olr1241', id: 'OLR1241'},
{value: 'olr1242', id: 'OLR1242'},
{value: 'olr1243', id: 'OLR1243'},
{value: 'olr1244', id: 'OLR1244'},
{value: 'olr1245', id: 'OLR1245'},
{value: 'olr1249', id: 'OLR1249'},
{value: 'olr125', id: 'OLR125'},
{value: 'olr1250', id: 'OLR1250'},
{value: 'olr1251', id: 'OLR1251'},
{value: 'olr1252', id: 'OLR1252'},
{value: 'olr1253', id: 'OLR1253'},
{value: 'olr1259', id: 'OLR1259'},
{value: 'olr126', id: 'OLR126'},
{value: 'olr1260', id: 'OLR1260'},
{value: 'olr1261', id: 'OLR1261'},
{value: 'olr1262', id: 'OLR1262'},
{value: 'olr1264', id: 'OLR1264'},
{value: 'olr1265', id: 'OLR1265'},
{value: 'olr127', id: 'OLR127'},
{value: 'olr1274', id: 'OLR1274'},
{value: 'olr1280', id: 'OLR1280'},
{value: 'olr1283', id: 'OLR1283'},
{value: 'olr1285', id: 'OLR1285'},
{value: 'olr1286', id: 'OLR1286'},
{value: 'olr1288', id: 'OLR1288'},
{value: 'olr129', id: 'OLR129'},
{value: 'olr1291', id: 'OLR1291'},
{value: 'olr1292', id: 'OLR1292'},
{value: 'olr1294', id: 'OLR1294'},
{value: 'olr1295', id: 'OLR1295'},
{value: 'olr1297', id: 'OLR1297'},
{value: 'olr13', id: 'OLR13'},
{value: 'olr1302', id: 'OLR1302'},
{value: 'olr1303', id: 'OLR1303'},
{value: 'olr1304', id: 'OLR1304'},
{value: 'olr1305', id: 'OLR1305'},
{value: 'olr1306', id: 'OLR1306'},
{value: 'olr1307', id: 'OLR1307'},
{value: 'olr1308', id: 'OLR1308'},
{value: 'olr1309', id: 'OLR1309'},
{value: 'olr131', id: 'OLR131'},
{value: 'olr1311', id: 'OLR1311'},
{value: 'olr1313', id: 'OLR1313'},
{value: 'olr1314', id: 'OLR1314'},
{value: 'olr1315', id: 'OLR1315'},
{value: 'olr1316', id: 'OLR1316'},
{value: 'olr1318', id: 'OLR1318'},
{value: 'olr1319', id: 'OLR1319'},
{value: 'olr132', id: 'OLR132'},
{value: 'olr1320', id: 'OLR1320'},
{value: 'olr1321', id: 'OLR1321'},
{value: 'olr1323', id: 'OLR1323'},
{value: 'olr1325', id: 'OLR1325'},
{value: 'olr1326', id: 'OLR1326'},
{value: 'olr1327', id: 'OLR1327'},
{value: 'olr1328', id: 'OLR1328'},
{value: 'olr1329', id: 'OLR1329'},
{value: 'olr1330', id: 'OLR1330'},
{value: 'olr1331', id: 'OLR1331'},
{value: 'olr1334', id: 'OLR1334'},
{value: 'olr1335', id: 'OLR1335'},
{value: 'olr1337', id: 'OLR1337'},
{value: 'olr1338', id: 'OLR1338'},
{value: 'olr1339', id: 'OLR1339'},
{value: 'olr1340', id: 'OLR1340'},
{value: 'olr1341', id: 'OLR1341'},
{value: 'olr1343', id: 'OLR1343'},
{value: 'olr1344', id: 'OLR1344'},
{value: 'olr1345', id: 'OLR1345'},
{value: 'olr1346', id: 'OLR1346'},
{value: 'olr1347', id: 'OLR1347'},
{value: 'olr1349', id: 'OLR1349'},
{value: 'olr135', id: 'OLR135'},
{value: 'olr1350', id: 'OLR1350'},
{value: 'olr1351', id: 'OLR1351'},
{value: 'olr1352', id: 'OLR1352'},
{value: 'olr1353', id: 'OLR1353'},
{value: 'olr1356', id: 'OLR1356'},
{value: 'olr1358', id: 'OLR1358'},
{value: 'olr136', id: 'OLR136'},
{value: 'olr1361', id: 'OLR1361'},
{value: 'olr1362', id: 'OLR1362'},
{value: 'olr1366', id: 'OLR1366'},
{value: 'olr1368', id: 'OLR1368'},
{value: 'olr1369', id: 'OLR1369'},
{value: 'olr137', id: 'OLR137'},
{value: 'olr1370', id: 'OLR1370'},
{value: 'olr1372', id: 'OLR1372'},
{value: 'olr1373', id: 'OLR1373'},
{value: 'olr1375', id: 'OLR1375'},
{value: 'olr1376', id: 'OLR1376'},
{value: 'olr1378', id: 'OLR1378'},
{value: 'olr1380', id: 'OLR1380'},
{value: 'olr1381', id: 'OLR1381'},
{value: 'olr1382', id: 'OLR1382'},
{value: 'olr1383', id: 'OLR1383'},
{value: 'olr1384', id: 'OLR1384'},
{value: 'olr1385', id: 'OLR1385'},
{value: 'olr1386', id: 'OLR1386'},
{value: 'olr1387', id: 'OLR1387'},
{value: 'olr1388', id: 'OLR1388'},
{value: 'olr1389', id: 'OLR1389'},
{value: 'olr139', id: 'OLR139'},
{value: 'olr1391', id: 'OLR1391'},
{value: 'olr1392', id: 'OLR1392'},
{value: 'olr1393', id: 'OLR1393'},
{value: 'olr1395', id: 'OLR1395'},
{value: 'olr1396', id: 'OLR1396'},
{value: 'olr1397', id: 'OLR1397'},
{value: 'olr1398', id: 'OLR1398'},
{value: 'olr1399', id: 'OLR1399'},
{value: 'olr140', id: 'OLR140'},
{value: 'olr1400', id: 'OLR1400'},
{value: 'olr1401', id: 'OLR1401'},
{value: 'olr1404', id: 'OLR1404'},
{value: 'olr1405', id: 'OLR1405'},
{value: 'olr1406', id: 'OLR1406'},
{value: 'olr1407', id: 'OLR1407'},
{value: 'olr1408', id: 'OLR1408'},
{value: 'olr1409', id: 'OLR1409'},
{value: 'olr141', id: 'OLR141'},
{value: 'olr1411', id: 'OLR1411'},
{value: 'olr1413', id: 'OLR1413'},
{value: 'olr1414', id: 'OLR1414'},
{value: 'olr1415', id: 'OLR1415'},
{value: 'olr1416', id: 'OLR1416'},
{value: 'olr1417', id: 'OLR1417'},
{value: 'olr1418', id: 'OLR1418'},
{value: 'olr142', id: 'OLR142'},
{value: 'olr1421', id: 'OLR1421'},
{value: 'olr1422', id: 'OLR1422'},
{value: 'olr1423', id: 'OLR1423'},
{value: 'olr1424', id: 'OLR1424'},
{value: 'olr1425', id: 'OLR1425'},
{value: 'olr143', id: 'OLR143'},
{value: 'olr1431', id: 'OLR1431'},
{value: 'olr1432', id: 'OLR1432'},
{value: 'olr1433', id: 'OLR1433'},
{value: 'olr1435', id: 'OLR1435'},
{value: 'olr1437', id: 'OLR1437'},
{value: 'olr144', id: 'OLR144'},
{value: 'olr1440', id: 'OLR1440'},
{value: 'olr1442', id: 'OLR1442'},
{value: 'olr1443', id: 'OLR1443'},
{value: 'olr1445', id: 'OLR1445'},
{value: 'olr145', id: 'OLR145'},
{value: 'olr1450', id: 'OLR1450'},
{value: 'olr1452', id: 'OLR1452'},
{value: 'olr1453', id: 'OLR1453'},
{value: 'olr1455', id: 'OLR1455'},
{value: 'olr1456', id: 'OLR1456'},
{value: 'olr1457', id: 'OLR1457'},
{value: 'olr1458', id: 'OLR1458'},
{value: 'olr1459', id: 'OLR1459'},
{value: 'olr1460', id: 'OLR1460'},
{value: 'olr1461', id: 'OLR1461'},
{value: 'olr1462', id: 'OLR1462'},
{value: 'olr1463', id: 'OLR1463'},
{value: 'olr1467', id: 'OLR1467'},
{value: 'olr1468', id: 'OLR1468'},
{value: 'olr1469', id: 'OLR1469'},
{value: 'olr148', id: 'OLR148'},
{value: 'olr1486', id: 'OLR1486'},
{value: 'olr1488', id: 'OLR1488'},
{value: 'olr149', id: 'OLR149'},
{value: 'olr1491', id: 'OLR1491'},
{value: 'olr1492', id: 'OLR1492'},
{value: 'olr1493', id: 'OLR1493'},
{value: 'olr1496', id: 'OLR1496'},
{value: 'olr1498', id: 'OLR1498'},
{value: 'olr1499', id: 'OLR1499'},
{value: 'olr150', id: 'OLR150'},
{value: 'olr1500', id: 'OLR1500'},
{value: 'olr1501', id: 'OLR1501'},
{value: 'olr1504', id: 'OLR1504'},
{value: 'olr1505', id: 'OLR1505'},
{value: 'olr1507', id: 'OLR1507'},
{value: 'olr1509', id: 'OLR1509'},
{value: 'olr1511', id: 'OLR1511'},
{value: 'olr1512', id: 'OLR1512'},
{value: 'olr1513', id: 'OLR1513'},
{value: 'olr1514', id: 'OLR1514'},
{value: 'olr1515', id: 'OLR1515'},
{value: 'olr1516', id: 'OLR1516'},
{value: 'olr1517', id: 'OLR1517'},
{value: 'olr1519', id: 'OLR1519'},
{value: 'olr152', id: 'OLR152'},
{value: 'olr1520', id: 'OLR1520'},
{value: 'olr1521', id: 'OLR1521'},
{value: 'olr1523', id: 'OLR1523'},
{value: 'olr1528', id: 'OLR1528'},
{value: 'olr153', id: 'OLR153'},
{value: 'olr1530', id: 'OLR1530'},
{value: 'olr1531', id: 'OLR1531'},
{value: 'olr1532', id: 'OLR1532'},
{value: 'olr1533', id: 'OLR1533'},
{value: 'olr1535', id: 'OLR1535'},
{value: 'olr1536', id: 'OLR1536'},
{value: 'olr1537', id: 'OLR1537'},
{value: 'olr1538', id: 'OLR1538'},
{value: 'olr1539', id: 'OLR1539'},
{value: 'olr154', id: 'OLR154'},
{value: 'olr1540', id: 'OLR1540'},
{value: 'olr1541', id: 'OLR1541'},
{value: 'olr1542', id: 'OLR1542'},
{value: 'olr1543', id: 'OLR1543'},
{value: 'olr1545', id: 'OLR1545'},
{value: 'olr1546', id: 'OLR1546'},
{value: 'olr1547', id: 'OLR1547'},
{value: 'olr1548', id: 'OLR1548'},
{value: 'olr1549', id: 'OLR1549'},
{value: 'olr155', id: 'OLR155'},
{value: 'olr1551', id: 'OLR1551'},
{value: 'olr1553', id: 'OLR1553'},
{value: 'olr1555', id: 'OLR1555'},
{value: 'olr1557', id: 'OLR1557'},
{value: 'olr1558', id: 'OLR1558'},
{value: 'olr1559', id: 'OLR1559'},
{value: 'olr156', id: 'OLR156'},
{value: 'olr1561', id: 'OLR1561'},
{value: 'olr1565', id: 'OLR1565'},
{value: 'olr1566', id: 'OLR1566'},
{value: 'olr1567', id: 'OLR1567'},
{value: 'olr1576', id: 'OLR1576'},
{value: 'olr1579', id: 'OLR1579'},
{value: 'olr158', id: 'OLR158'},
{value: 'olr1581', id: 'OLR1581'},
{value: 'olr1582', id: 'OLR1582'},
{value: 'olr1583', id: 'OLR1583'},
{value: 'olr1585', id: 'OLR1585'},
{value: 'olr1587', id: 'OLR1587'},
{value: 'olr1588', id: 'OLR1588'},
{value: 'olr1589', id: 'OLR1589'},
{value: 'olr159', id: 'OLR159'},
{value: 'olr1590', id: 'OLR1590'},
{value: 'olr1591', id: 'OLR1591'},
{value: 'olr1592', id: 'OLR1592'},
{value: 'olr1593', id: 'OLR1593'},
{value: 'olr1595', id: 'OLR1595'},
{value: 'olr1596', id: 'OLR1596'},
{value: 'olr1597', id: 'OLR1597'},
{value: 'olr1598', id: 'OLR1598'},
{value: 'olr160', id: 'OLR160'},
{value: 'olr1600', id: 'OLR1600'},
{value: 'olr1602', id: 'OLR1602'},
{value: 'olr1605', id: 'OLR1605'},
{value: 'olr1606', id: 'OLR1606'},
{value: 'olr1607', id: 'OLR1607'},
{value: 'olr1608', id: 'OLR1608'},
{value: 'olr1609', id: 'OLR1609'},
{value: 'olr161', id: 'OLR161'},
{value: 'olr1610', id: 'OLR1610'},
{value: 'olr1611', id: 'OLR1611'},
{value: 'olr1612', id: 'OLR1612'},
{value: 'olr1614', id: 'OLR1614'},
{value: 'olr1615', id: 'OLR1615'},
{value: 'olr1616', id: 'OLR1616'},
{value: 'olr1617', id: 'OLR1617'},
{value: 'olr1619', id: 'OLR1619'},
{value: 'olr162', id: 'OLR162'},
{value: 'olr1620', id: 'OLR1620'},
{value: 'olr1621', id: 'OLR1621'},
{value: 'olr1622', id: 'OLR1622'},
{value: 'olr1624', id: 'OLR1624'},
{value: 'olr1625', id: 'OLR1625'},
{value: 'olr1626', id: 'OLR1626'},
{value: 'olr1627', id: 'OLR1627'},
{value: 'olr1629', id: 'OLR1629'},
{value: 'olr163', id: 'OLR163'},
{value: 'olr1630', id: 'OLR1630'},
{value: 'olr1631', id: 'OLR1631'},
{value: 'olr1632', id: 'OLR1632'},
{value: 'olr1633', id: 'OLR1633'},
{value: 'olr1635', id: 'OLR1635'},
{value: 'olr1637', id: 'OLR1637'},
{value: 'olr1638', id: 'OLR1638'},
{value: 'olr1639', id: 'OLR1639'},
{value: 'olr164', id: 'OLR164'},
{value: 'olr1640', id: 'OLR1640'},
{value: 'olr1641', id: 'OLR1641'},
{value: 'olr1642', id: 'OLR1642'},
{value: 'olr1643', id: 'OLR1643'},
{value: 'olr1644', id: 'OLR1644'},
{value: 'olr1645', id: 'OLR1645'},
{value: 'olr1646', id: 'OLR1646'},
{value: 'olr165', id: 'OLR165'},
{value: 'olr1652', id: 'OLR1652'},
{value: 'olr1653', id: 'OLR1653'},
{value: 'olr1654', id: 'OLR1654'},
{value: 'olr1657', id: 'OLR1657'},
{value: 'olr1658', id: 'OLR1658'},
{value: 'olr1660', id: 'OLR1660'},
{value: 'olr1662', id: 'OLR1662'},
{value: 'olr1664', id: 'OLR1664'},
{value: 'olr1666', id: 'OLR1666'},
{value: 'olr1667', id: 'OLR1667'},
{value: 'olr1668', id: 'OLR1668'},
{value: 'olr167', id: 'OLR167'},
{value: 'olr1670', id: 'OLR1670'},
{value: 'olr1671', id: 'OLR1671'},
{value: 'olr1673', id: 'OLR1673'},
{value: 'olr1675', id: 'OLR1675'},
{value: 'olr1679', id: 'OLR1679'},
{value: 'olr1682', id: 'OLR1682'},
{value: 'olr1683', id: 'OLR1683'},
{value: 'olr1684', id: 'OLR1684'},
{value: 'olr1686', id: 'OLR1686'},
{value: 'olr1687', id: 'OLR1687'},
{value: 'olr1688', id: 'OLR1688'},
{value: 'olr1690', id: 'OLR1690'},
{value: 'olr1691', id: 'OLR1691'},
{value: 'olr1692', id: 'OLR1692'},
{value: 'olr1693', id: 'OLR1693'},
{value: 'olr1694', id: 'OLR1694'},
{value: 'olr1695', id: 'OLR1695'},
{value: 'olr1700', id: 'OLR1700'},
{value: 'olr1702', id: 'OLR1702'},
{value: 'olr1704', id: 'OLR1704'},
{value: 'olr1705', id: 'OLR1705'},
{value: 'olr1709', id: 'OLR1709'},
{value: 'olr1710', id: 'OLR1710'},
{value: 'olr1714', id: 'OLR1714'},
{value: 'olr1718', id: 'OLR1718'},
{value: 'olr1722', id: 'OLR1722'},
{value: 'olr1724', id: 'OLR1724'},
{value: 'olr1726', id: 'OLR1726'},
{value: 'olr1730', id: 'OLR1730'},
{value: 'olr1733', id: 'OLR1733'},
{value: 'olr1734', id: 'OLR1734'},
{value: 'olr1735', id: 'OLR1735'},
{value: 'olr1736', id: 'OLR1736'},
{value: 'olr1737', id: 'OLR1737'},
{value: 'olr1738', id: 'OLR1738'},
{value: 'olr1739', id: 'OLR1739'},
{value: 'olr1743', id: 'OLR1743'},
{value: 'olr1744', id: 'OLR1744'},
{value: 'olr1748', id: 'OLR1748'},
{value: 'olr1749', id: 'OLR1749'},
{value: 'olr1750', id: 'OLR1750'},
{value: 'olr1765', id: 'OLR1765'},
{value: 'olr1766', id: 'OLR1766'},
{value: 'olr1767', id: 'OLR1767'},
{value: 'olr1768', id: 'OLR1768'},
{value: 'olr178', id: 'OLR178'},
{value: 'olr179', id: 'OLR179'},
{value: 'olr180', id: 'OLR180'},
{value: 'olr181', id: 'OLR181'},
{value: 'olr183', id: 'OLR183'},
{value: 'olr184', id: 'OLR184'},
{value: 'olr1845', id: 'OLR1845'},
{value: 'olr185', id: 'OLR185'},
{value: 'olr186', id: 'OLR186'},
{value: 'olr1867', id: 'OLR1867'},
{value: 'olr1868', id: 'OLR1868'},
{value: 'olr1874', id: 'OLR1874'},
{value: 'olr1875', id: 'OLR1875'},
{value: 'olr1876', id: 'OLR1876'},
{value: 'olr188', id: 'OLR188'},
{value: 'olr189', id: 'OLR189'},
{value: 'olr190', id: 'OLR190'},
{value: 'olr193', id: 'OLR193'},
{value: 'olr194', id: 'OLR194'},
{value: 'olr196', id: 'OLR196'},
{value: 'olr197', id: 'OLR197'},
{value: 'olr198', id: 'OLR198'},
{value: 'olr199', id: 'OLR199'},
{value: 'olr1l', id: 'OLR1L'},
{value: 'olr20', id: 'OLR20'},
{value: 'olr200', id: 'OLR200'},
{value: 'olr201', id: 'OLR201'},
{value: 'olr202', id: 'OLR202'},
{value: 'olr203', id: 'OLR203'},
{value: 'olr204', id: 'OLR204'},
{value: 'olr205', id: 'OLR205'},
{value: 'olr206', id: 'OLR206'},
{value: 'olr208', id: 'OLR208'},
{value: 'olr209', id: 'OLR209'},
{value: 'olr210', id: 'OLR210'},
{value: 'olr211', id: 'OLR211'},
{value: 'olr213', id: 'OLR213'},
{value: 'olr214', id: 'OLR214'},
{value: 'olr215', id: 'OLR215'},
{value: 'olr217', id: 'OLR217'},
{value: 'olr218', id: 'OLR218'},
{value: 'olr219', id: 'OLR219'},
{value: 'olr221', id: 'OLR221'},
{value: 'olr222', id: 'OLR222'},
{value: 'olr223', id: 'OLR223'},
{value: 'olr224', id: 'OLR224'},
{value: 'olr226', id: 'OLR226'},
{value: 'olr227', id: 'OLR227'},
{value: 'olr229', id: 'OLR229'},
{value: 'olr23', id: 'OLR23'},
{value: 'olr230', id: 'OLR230'},
{value: 'olr231', id: 'OLR231'},
{value: 'olr232', id: 'OLR232'},
{value: 'olr233', id: 'OLR233'},
{value: 'olr234', id: 'OLR234'},
{value: 'olr235', id: 'OLR235'},
{value: 'olr237', id: 'OLR237'},
{value: 'olr239', id: 'OLR239'},
{value: 'olr24', id: 'OLR24'},
{value: 'olr240', id: 'OLR240'},
{value: 'olr241', id: 'OLR241'},
{value: 'olr242', id: 'OLR242'},
{value: 'olr244', id: 'OLR244'},
{value: 'olr245', id: 'OLR245'},
{value: 'olr246', id: 'OLR246'},
{value: 'olr247', id: 'OLR247'},
{value: 'olr25', id: 'OLR25'},
{value: 'olr250', id: 'OLR250'},
{value: 'olr251', id: 'OLR251'},
{value: 'olr252', id: 'OLR252'},
{value: 'olr255', id: 'OLR255'},
{value: 'olr257', id: 'OLR257'},
{value: 'olr259', id: 'OLR259'},
{value: 'olr260', id: 'OLR260'},
{value: 'olr262', id: 'OLR262'},
{value: 'olr263', id: 'OLR263'},
{value: 'olr264', id: 'OLR264'},
{value: 'olr267', id: 'OLR267'},
{value: 'olr268', id: 'OLR268'},
{value: 'olr27', id: 'OLR27'},
{value: 'olr270', id: 'OLR270'},
{value: 'olr271', id: 'OLR271'},
{value: 'olr276', id: 'OLR276'},
{value: 'olr278', id: 'OLR278'},
{value: 'olr279', id: 'OLR279'},
{value: 'olr281', id: 'OLR281'},
{value: 'olr282', id: 'OLR282'},
{value: 'olr283', id: 'OLR283'},
{value: 'olr285', id: 'OLR285'},
{value: 'olr286', id: 'OLR286'},
{value: 'olr287', id: 'OLR287'},
{value: 'olr288', id: 'OLR288'},
{value: 'olr289', id: 'OLR289'},
{value: 'olr29', id: 'OLR29'},
{value: 'olr292', id: 'OLR292'},
{value: 'olr295', id: 'OLR295'},
{value: 'olr297', id: 'OLR297'},
{value: 'olr298', id: 'OLR298'},
{value: 'olr299', id: 'OLR299'},
{value: 'olr3', id: 'OLR3'},
{value: 'olr30', id: 'OLR30'},
{value: 'olr300', id: 'OLR300'},
{value: 'olr302', id: 'OLR302'},
{value: 'olr303', id: 'OLR303'},
{value: 'olr304', id: 'OLR304'},
{value: 'olr305', id: 'OLR305'},
{value: 'olr306', id: 'OLR306'},
{value: 'olr307', id: 'OLR307'},
{value: 'olr308', id: 'OLR308'},
{value: 'olr309', id: 'OLR309'},
{value: 'olr310', id: 'OLR310'},
{value: 'olr311', id: 'OLR311'},
{value: 'olr312', id: 'OLR312'},
{value: 'olr313', id: 'OLR313'},
{value: 'olr315', id: 'OLR315'},
{value: 'olr318', id: 'OLR318'},
{value: 'olr319', id: 'OLR319'},
{value: 'olr32', id: 'OLR32'},
{value: 'olr321', id: 'OLR321'},
{value: 'olr322', id: 'OLR322'},
{value: 'olr323', id: 'OLR323'},
{value: 'olr324', id: 'OLR324'},
{value: 'olr325', id: 'OLR325'},
{value: 'olr326', id: 'OLR326'},
{value: 'olr330', id: 'OLR330'},
{value: 'olr332', id: 'OLR332'},
{value: 'olr334', id: 'OLR334'},
{value: 'olr336', id: 'OLR336'},
{value: 'olr337', id: 'OLR337'},
{value: 'olr338', id: 'OLR338'},
{value: 'olr339', id: 'OLR339'},
{value: 'olr34', id: 'OLR34'},
{value: 'olr340', id: 'OLR340'},
{value: 'olr341', id: 'OLR341'},
{value: 'olr343', id: 'OLR343'},
{value: 'olr344', id: 'OLR344'},
{value: 'olr346', id: 'OLR346'},
{value: 'olr347', id: 'OLR347'},
{value: 'olr348', id: 'OLR348'},
{value: 'olr349', id: 'OLR349'},
{value: 'olr35', id: 'OLR35'},
{value: 'olr352', id: 'OLR352'},
{value: 'olr354', id: 'OLR354'},
{value: 'olr357', id: 'OLR357'},
{value: 'olr358', id: 'OLR358'},
{value: 'olr36', id: 'OLR36'},
{value: 'olr360', id: 'OLR360'},
{value: 'olr361', id: 'OLR361'},
{value: 'olr363', id: 'OLR363'},
{value: 'olr365', id: 'OLR365'},
{value: 'olr367', id: 'OLR367'},
{value: 'olr371', id: 'OLR371'},
{value: 'olr372', id: 'OLR372'},
{value: 'olr373', id: 'OLR373'},
{value: 'olr374', id: 'OLR374'},
{value: 'olr375', id: 'OLR375'},
{value: 'olr376', id: 'OLR376'},
{value: 'olr377', id: 'OLR377'},
{value: 'olr378', id: 'OLR378'},
{value: 'olr379', id: 'OLR379'},
{value: 'olr38-ps', id: 'OLR38-PS'},
{value: 'olr380', id: 'OLR380'},
{value: 'olr382', id: 'OLR382'},
{value: 'olr383', id: 'OLR383'},
{value: 'olr384', id: 'OLR384'},
{value: 'olr386', id: 'OLR386'},
{value: 'olr387', id: 'OLR387'},
{value: 'olr39', id: 'OLR39'},
{value: 'olr390', id: 'OLR390'},
{value: 'olr392', id: 'OLR392'},
{value: 'olr395', id: 'OLR395'},
{value: 'olr396', id: 'OLR396'},
{value: 'olr397', id: 'OLR397'},
{value: 'olr398', id: 'OLR398'},
{value: 'olr399', id: 'OLR399'},
{value: 'olr4', id: 'OLR4'},
{value: 'olr40', id: 'OLR40'},
{value: 'olr400', id: 'OLR400'},
{value: 'olr401', id: 'OLR401'},
{value: 'olr402', id: 'OLR402'},
{value: 'olr403', id: 'OLR403'},
{value: 'olr404', id: 'OLR404'},
{value: 'olr406', id: 'OLR406'},
{value: 'olr407', id: 'OLR407'},
{value: 'olr408', id: 'OLR408'},
{value: 'olr409', id: 'OLR409'},
{value: 'olr41', id: 'OLR41'},
{value: 'olr410', id: 'OLR410'},
{value: 'olr411', id: 'OLR411'},
{value: 'olr413', id: 'OLR413'},
{value: 'olr414', id: 'OLR414'},
{value: 'olr415', id: 'OLR415'},
{value: 'olr416', id: 'OLR416'},
{value: 'olr417', id: 'OLR417'},
{value: 'olr418', id: 'OLR418'},
{value: 'olr419', id: 'OLR419'},
{value: 'olr420', id: 'OLR420'},
{value: 'olr421', id: 'OLR421'},
{value: 'olr422', id: 'OLR422'},
{value: 'olr423', id: 'OLR423'},
{value: 'olr424', id: 'OLR424'},
{value: 'olr425', id: 'OLR425'},
{value: 'olr427', id: 'OLR427'},
{value: 'olr428', id: 'OLR428'},
{value: 'olr429', id: 'OLR429'},
{value: 'olr43', id: 'OLR43'},
{value: 'olr434', id: 'OLR434'},
{value: 'olr436', id: 'OLR436'},
{value: 'olr437', id: 'OLR437'},
{value: 'olr439', id: 'OLR439'},
{value: 'olr44', id: 'OLR44'},
{value: 'olr440', id: 'OLR440'},
{value: 'olr441', id: 'OLR441'},
{value: 'olr442', id: 'OLR442'},
{value: 'olr443', id: 'OLR443'},
{value: 'olr444', id: 'OLR444'},
{value: 'olr445', id: 'OLR445'},
{value: 'olr446', id: 'OLR446'},
{value: 'olr447', id: 'OLR447'},
{value: 'olr448', id: 'OLR448'},
{value: 'olr45', id: 'OLR45'},
{value: 'olr450', id: 'OLR450'},
{value: 'olr453', id: 'OLR453'},
{value: 'olr454', id: 'OLR454'},
{value: 'olr455', id: 'OLR455'},
{value: 'olr456', id: 'OLR456'},
{value: 'olr458', id: 'OLR458'},
{value: 'olr459', id: 'OLR459'},
{value: 'olr46', id: 'OLR46'},
{value: 'olr461', id: 'OLR461'},
{value: 'olr462', id: 'OLR462'},
{value: 'olr463', id: 'OLR463'},
{value: 'olr464', id: 'OLR464'},
{value: 'olr465', id: 'OLR465'},
{value: 'olr466', id: 'OLR466'},
{value: 'olr468', id: 'OLR468'},
{value: 'olr469', id: 'OLR469'},
{value: 'olr47', id: 'OLR47'},
{value: 'olr470', id: 'OLR470'},
{value: 'olr471', id: 'OLR471'},
{value: 'olr472', id: 'OLR472'},
{value: 'olr473', id: 'OLR473'},
{value: 'olr475', id: 'OLR475'},
{value: 'olr476', id: 'OLR476'},
{value: 'olr477', id: 'OLR477'},
{value: 'olr479', id: 'OLR479'},
{value: 'olr48', id: 'OLR48'},
{value: 'olr480', id: 'OLR480'},
{value: 'olr481', id: 'OLR481'},
{value: 'olr482', id: 'OLR482'},
{value: 'olr483', id: 'OLR483'},
{value: 'olr484', id: 'OLR484'},
{value: 'olr485', id: 'OLR485'},
{value: 'olr486', id: 'OLR486'},
{value: 'olr488', id: 'OLR488'},
{value: 'olr49', id: 'OLR49'},
{value: 'olr496', id: 'OLR496'},
{value: 'olr50', id: 'OLR50'},
{value: 'olr500', id: 'OLR500'},
{value: 'olr502', id: 'OLR502'},
{value: 'olr505', id: 'OLR505'},
{value: 'olr507', id: 'OLR507'},
{value: 'olr508', id: 'OLR508'},
{value: 'olr510', id: 'OLR510'},
{value: 'olr513', id: 'OLR513'},
{value: 'olr514', id: 'OLR514'},
{value: 'olr515', id: 'OLR515'},
{value: 'olr516', id: 'OLR516'},
{value: 'olr517', id: 'OLR517'},
{value: 'olr518', id: 'OLR518'},
{value: 'olr519', id: 'OLR519'},
{value: 'olr522', id: 'OLR522'},
{value: 'olr526', id: 'OLR526'},
{value: 'olr527', id: 'OLR527'},
{value: 'olr528', id: 'OLR528'},
{value: 'olr529', id: 'OLR529'},
{value: 'olr53', id: 'OLR53'},
{value: 'olr530', id: 'OLR530'},
{value: 'olr531', id: 'OLR531'},
{value: 'olr532', id: 'OLR532'},
{value: 'olr533', id: 'OLR533'},
{value: 'olr535', id: 'OLR535'},
{value: 'olr536', id: 'OLR536'},
{value: 'olr537', id: 'OLR537'},
{value: 'olr539', id: 'OLR539'},
{value: 'olr540', id: 'OLR540'},
{value: 'olr541', id: 'OLR541'},
{value: 'olr542', id: 'OLR542'},
{value: 'olr544', id: 'OLR544'},
{value: 'olr545', id: 'OLR545'},
{value: 'olr546', id: 'OLR546'},
{value: 'olr550', id: 'OLR550'},
{value: 'olr552', id: 'OLR552'},
{value: 'olr554', id: 'OLR554'},
{value: 'olr555', id: 'OLR555'},
{value: 'olr556', id: 'OLR556'},
{value: 'olr557', id: 'OLR557'},
{value: 'olr558', id: 'OLR558'},
{value: 'olr559', id: 'OLR559'},
{value: 'olr56', id: 'OLR56'},
{value: 'olr560', id: 'OLR560'},
{value: 'olr561', id: 'OLR561'},
{value: 'olr562', id: 'OLR562'},
{value: 'olr563', id: 'OLR563'},
{value: 'olr566', id: 'OLR566'},
{value: 'olr567', id: 'OLR567'},
{value: 'olr569', id: 'OLR569'},
{value: 'olr57', id: 'OLR57'},
{value: 'olr575', id: 'OLR575'},
{value: 'olr576', id: 'OLR576'},
{value: 'olr577', id: 'OLR577'},
{value: 'olr578', id: 'OLR578'},
{value: 'olr581', id: 'OLR581'},
{value: 'olr582', id: 'OLR582'},
{value: 'olr583', id: 'OLR583'},
{value: 'olr584', id: 'OLR584'},
{value: 'olr586', id: 'OLR586'},
{value: 'olr587', id: 'OLR587'},
{value: 'olr588', id: 'OLR588'},
{value: 'olr59', id: 'OLR59'},
{value: 'olr590', id: 'OLR590'},
{value: 'olr592', id: 'OLR592'},
{value: 'olr594', id: 'OLR594'},
{value: 'olr595', id: 'OLR595'},
{value: 'olr596', id: 'OLR596'},
{value: 'olr597', id: 'OLR597'},
{value: 'olr598', id: 'OLR598'},
{value: 'olr6', id: 'OLR6'},
{value: 'olr60', id: 'OLR60'},
{value: 'olr601', id: 'OLR601'},
{value: 'olr602', id: 'OLR602'},
{value: 'olr604', id: 'OLR604'},
{value: 'olr606', id: 'OLR606'},
{value: 'olr607', id: 'OLR607'},
{value: 'olr608', id: 'OLR608'},
{value: 'olr609', id: 'OLR609'},
{value: 'olr61', id: 'OLR61'},
{value: 'olr610', id: 'OLR610'},
{value: 'olr611', id: 'OLR611'},
{value: 'olr613', id: 'OLR613'},
{value: 'olr614', id: 'OLR614'},
{value: 'olr619', id: 'OLR619'},
{value: 'olr62', id: 'OLR62'},
{value: 'olr621', id: 'OLR621'},
{value: 'olr623', id: 'OLR623'},
{value: 'olr629', id: 'OLR629'},
{value: 'olr63', id: 'OLR63'},
{value: 'olr630', id: 'OLR630'},
{value: 'olr631', id: 'OLR631'},
{value: 'olr633', id: 'OLR633'},
{value: 'olr634', id: 'OLR634'},
{value: 'olr635', id: 'OLR635'},
{value: 'olr636', id: 'OLR636'},
{value: 'olr641', id: 'OLR641'},
{value: 'olr646', id: 'OLR646'},
{value: 'olr648', id: 'OLR648'},
{value: 'olr649', id: 'OLR649'},
{value: 'olr650', id: 'OLR650'},
{value: 'olr651', id: 'OLR651'},
{value: 'olr652', id: 'OLR652'},
{value: 'olr653', id: 'OLR653'},
{value: 'olr657', id: 'OLR657'},
{value: 'olr658', id: 'OLR658'},
{value: 'olr659', id: 'OLR659'},
{value: 'olr660', id: 'OLR660'},
{value: 'olr661', id: 'OLR661'},
{value: 'olr662', id: 'OLR662'},
{value: 'olr663', id: 'OLR663'},
{value: 'olr664', id: 'OLR664'},
{value: 'olr665', id: 'OLR665'},
{value: 'olr666', id: 'OLR666'},
{value: 'olr668', id: 'OLR668'},
{value: 'olr669', id: 'OLR669'},
{value: 'olr67', id: 'OLR67'},
{value: 'olr670', id: 'OLR670'},
{value: 'olr671', id: 'OLR671'},
{value: 'olr675', id: 'OLR675'},
{value: 'olr677', id: 'OLR677'},
{value: 'olr678', id: 'OLR678'},
{value: 'olr679', id: 'OLR679'},
{value: 'olr68', id: 'OLR68'},
{value: 'olr681', id: 'OLR681'},
{value: 'olr684', id: 'OLR684'},
{value: 'olr686', id: 'OLR686'},
{value: 'olr687', id: 'OLR687'},
{value: 'olr689', id: 'OLR689'},
{value: 'olr69', id: 'OLR69'},
{value: 'olr690', id: 'OLR690'},
{value: 'olr691', id: 'OLR691'},
{value: 'olr693', id: 'OLR693'},
{value: 'olr694', id: 'OLR694'},
{value: 'olr695', id: 'OLR695'},
{value: 'olr696', id: 'OLR696'},
{value: 'olr697', id: 'OLR697'},
{value: 'olr698', id: 'OLR698'},
{value: 'olr7', id: 'OLR7'},
{value: 'olr70', id: 'OLR70'},
{value: 'olr701', id: 'OLR701'},
{value: 'olr702', id: 'OLR702'},
{value: 'olr704', id: 'OLR704'},
{value: 'olr705', id: 'OLR705'},
{value: 'olr707', id: 'OLR707'},
{value: 'olr709', id: 'OLR709'},
{value: 'olr710', id: 'OLR710'},
{value: 'olr711', id: 'OLR711'},
{value: 'olr712', id: 'OLR712'},
{value: 'olr713', id: 'OLR713'},
{value: 'olr714', id: 'OLR714'},
{value: 'olr715', id: 'OLR715'},
{value: 'olr716', id: 'OLR716'},
{value: 'olr717', id: 'OLR717'},
{value: 'olr718', id: 'OLR718'},
{value: 'olr72', id: 'OLR72'},
{value: 'olr720', id: 'OLR720'},
{value: 'olr721', id: 'OLR721'},
{value: 'olr722', id: 'OLR722'},
{value: 'olr724', id: 'OLR724'},
{value: 'olr725', id: 'OLR725'},
{value: 'olr726', id: 'OLR726'},
{value: 'olr727', id: 'OLR727'},
{value: 'olr728', id: 'OLR728'},
{value: 'olr729', id: 'OLR729'},
{value: 'olr731', id: 'OLR731'},
{value: 'olr733', id: 'OLR733'},
{value: 'olr734', id: 'OLR734'},
{value: 'olr735', id: 'OLR735'},
{value: 'olr736', id: 'OLR736'},
{value: 'olr737', id: 'OLR737'},
{value: 'olr74', id: 'OLR74'},
{value: 'olr741', id: 'OLR741'},
{value: 'olr742', id: 'OLR742'},
{value: 'olr744', id: 'OLR744'},
{value: 'olr745', id: 'OLR745'},
{value: 'olr747', id: 'OLR747'},
{value: 'olr748', id: 'OLR748'},
{value: 'olr749', id: 'OLR749'},
{value: 'olr75', id: 'OLR75'},
{value: 'olr750', id: 'OLR750'},
{value: 'olr752', id: 'OLR752'},
{value: 'olr753', id: 'OLR753'},
{value: 'olr754', id: 'OLR754'},
{value: 'olr756', id: 'OLR756'},
{value: 'olr757', id: 'OLR757'},
{value: 'olr758', id: 'OLR758'},
{value: 'olr760', id: 'OLR760'},
{value: 'olr763', id: 'OLR763'},
{value: 'olr765', id: 'OLR765'},
{value: 'olr766', id: 'OLR766'},
{value: 'olr767', id: 'OLR767'},
{value: 'olr768', id: 'OLR768'},
{value: 'olr769', id: 'OLR769'},
{value: 'olr77', id: 'OLR77'},
{value: 'olr770', id: 'OLR770'},
{value: 'olr771', id: 'OLR771'},
{value: 'olr772', id: 'OLR772'},
{value: 'olr773', id: 'OLR773'},
{value: 'olr774', id: 'OLR774'},
{value: 'olr775', id: 'OLR775'},
{value: 'olr776', id: 'OLR776'},
{value: 'olr777', id: 'OLR777'},
{value: 'olr778', id: 'OLR778'},
{value: 'olr779', id: 'OLR779'},
{value: 'olr78', id: 'OLR78'},
{value: 'olr780', id: 'OLR780'},
{value: 'olr781', id: 'OLR781'},
{value: 'olr782', id: 'OLR782'},
{value: 'olr783', id: 'OLR783'},
{value: 'olr784', id: 'OLR784'},
{value: 'olr785', id: 'OLR785'},
{value: 'olr786', id: 'OLR786'},
{value: 'olr788', id: 'OLR788'},
{value: 'olr789', id: 'OLR789'},
{value: 'olr79', id: 'OLR79'},
{value: 'olr790', id: 'OLR790'},
{value: 'olr791', id: 'OLR791'},
{value: 'olr792', id: 'OLR792'},
{value: 'olr795', id: 'OLR795'},
{value: 'olr796', id: 'OLR796'},
{value: 'olr799', id: 'OLR799'},
{value: 'olr8', id: 'OLR8'},
{value: 'olr80', id: 'OLR80'},
{value: 'olr801', id: 'OLR801'},
{value: 'olr802', id: 'OLR802'},
{value: 'olr803', id: 'OLR803'},
{value: 'olr804', id: 'OLR804'},
{value: 'olr806', id: 'OLR806'},
{value: 'olr807', id: 'OLR807'},
{value: 'olr808', id: 'OLR808'},
{value: 'olr81', id: 'OLR81'},
{value: 'olr811', id: 'OLR811'},
{value: 'olr812', id: 'OLR812'},
{value: 'olr813', id: 'OLR813'},
{value: 'olr816', id: 'OLR816'},
{value: 'olr818', id: 'OLR818'},
{value: 'olr819', id: 'OLR819'},
{value: 'olr82', id: 'OLR82'},
{value: 'olr820', id: 'OLR820'},
{value: 'olr821', id: 'OLR821'},
{value: 'olr823', id: 'OLR823'},
{value: 'olr824', id: 'OLR824'},
{value: 'olr825', id: 'OLR825'},
{value: 'olr826', id: 'OLR826'},
{value: 'olr827', id: 'OLR827'},
{value: 'olr828', id: 'OLR828'},
{value: 'olr829', id: 'OLR829'},
{value: 'olr83', id: 'OLR83'},
{value: 'olr830', id: 'OLR830'},
{value: 'olr831', id: 'OLR831'},
{value: 'olr832', id: 'OLR832'},
{value: 'olr833', id: 'OLR833'},
{value: 'olr834', id: 'OLR834'},
{value: 'olr836', id: 'OLR836'},
{value: 'olr837', id: 'OLR837'},
{value: 'olr838', id: 'OLR838'},
{value: 'olr839', id: 'OLR839'},
{value: 'olr84', id: 'OLR84'},
{value: 'olr840', id: 'OLR840'},
{value: 'olr841', id: 'OLR841'},
{value: 'olr844', id: 'OLR844'},
{value: 'olr845', id: 'OLR845'},
{value: 'olr846-ps', id: 'OLR846-PS'},
{value: 'olr847', id: 'OLR847'},
{value: 'olr848', id: 'OLR848'},
{value: 'olr85', id: 'OLR85'},
{value: 'olr850', id: 'OLR850'},
{value: 'olr851', id: 'OLR851'},
{value: 'olr852', id: 'OLR852'},
{value: 'olr853', id: 'OLR853'},
{value: 'olr854', id: 'OLR854'},
{value: 'olr855', id: 'OLR855'},
{value: 'olr857', id: 'OLR857'},
{value: 'olr858', id: 'OLR858'},
{value: 'olr859', id: 'OLR859'},
{value: 'olr86', id: 'OLR86'},
{value: 'olr860', id: 'OLR860'},
{value: 'olr862', id: 'OLR862'},
{value: 'olr865', id: 'OLR865'},
{value: 'olr866', id: 'OLR866'},
{value: 'olr867', id: 'OLR867'},
{value: 'olr868', id: 'OLR868'},
{value: 'olr869', id: 'OLR869'},
{value: 'olr87', id: 'OLR87'},
{value: 'olr875', id: 'OLR875'},
{value: 'olr877', id: 'OLR877'},
{value: 'olr878', id: 'OLR878'},
{value: 'olr879', id: 'OLR879'},
{value: 'olr88', id: 'OLR88'},
{value: 'olr880', id: 'OLR880'},
{value: 'olr881', id: 'OLR881'},
{value: 'olr886', id: 'OLR886'},
{value: 'olr889', id: 'OLR889'},
{value: 'olr89', id: 'OLR89'},
{value: 'olr892', id: 'OLR892'},
{value: 'olr894', id: 'OLR894'},
{value: 'olr896', id: 'OLR896'},
{value: 'olr898', id: 'OLR898'},
{value: 'olr901', id: 'OLR901'},
{value: 'olr903', id: 'OLR903'},
{value: 'olr905', id: 'OLR905'},
{value: 'olr906', id: 'OLR906'},
{value: 'olr908', id: 'OLR908'},
{value: 'olr91', id: 'OLR91'},
{value: 'olr916', id: 'OLR916'},
{value: 'olr917', id: 'OLR917'},
{value: 'olr92', id: 'OLR92'},
{value: 'olr920', id: 'OLR920'},
{value: 'olr927', id: 'OLR927'},
{value: 'olr93', id: 'OLR93'},
{value: 'olr931', id: 'OLR931'},
{value: 'olr932', id: 'OLR932'},
{value: 'olr937', id: 'OLR937'},
{value: 'olr94', id: 'OLR94'},
{value: 'olr943', id: 'OLR943'},
{value: 'olr947', id: 'OLR947'},
{value: 'olr95', id: 'OLR95'},
{value: 'olr950', id: 'OLR950'},
{value: 'olr951', id: 'OLR951'},
{value: 'olr954', id: 'OLR954'},
{value: 'olr956', id: 'OLR956'},
{value: 'olr96', id: 'OLR96'},
{value: 'olr960', id: 'OLR960'},
{value: 'olr964', id: 'OLR964'},
{value: 'olr97', id: 'OLR97'},
{value: 'olr98', id: 'OLR98'},
{value: 'olr982', id: 'OLR982'},
{value: 'olr984', id: 'OLR984'},
{value: 'olr987', id: 'OLR987'},
{value: 'olr990', id: 'OLR990'},
{value: 'olr991', id: 'OLR991'},
{value: 'olr996', id: 'OLR996'},
{value: 'olr997-ps', id: 'OLR997-PS'},
{value: 'oma1', id: 'OMA1'},
{value: 'omd', id: 'OMD'},
{value: 'omg', id: 'OMG'},
{value: 'omp', id: 'OMP'},
{value: 'onecut1', id: 'ONECUT1'},
{value: 'onecut2', id: 'ONECUT2'},
{value: 'ooep', id: 'OOEP'},
{value: 'oog1', id: 'OOG1'},
{value: 'oog3', id: 'OOG3'},
{value: 'oosp2', id: 'OOSP2'},
{value: 'opa1', id: 'OPA1'},
{value: 'opa3', id: 'OPA3'},
{value: 'opalin', id: 'OPALIN'},
{value: 'opcml', id: 'OPCML'},
{value: 'ophn1', id: 'OPHN1'},
{value: 'oplah', id: 'OPLAH'},
{value: 'opn1mw', id: 'OPN1MW'},
{value: 'opn1sw', id: 'OPN1SW'},
{value: 'opn3', id: 'OPN3'},
{value: 'opn4', id: 'OPN4'},
{value: 'opn5', id: 'OPN5'},
{value: 'oprd1', id: 'OPRD1'},
{value: 'oprk1', id: 'OPRK1'},
{value: 'oprl1', id: 'OPRL1'},
{value: 'oprm1', id: 'OPRM1'},
{value: 'oprpn', id: 'OPRPN'},
{value: 'optc', id: 'OPTC'},
{value: 'optn', id: 'OPTN'},
{value: 'or7e24', id: 'OR7E24'},
{value: 'orai1', id: 'ORAI1'},
{value: 'orai2', id: 'ORAI2'},
{value: 'orai3', id: 'ORAI3'},
{value: 'orc1', id: 'ORC1'},
{value: 'orc2', id: 'ORC2'},
{value: 'orc3', id: 'ORC3'},
{value: 'orc4', id: 'ORC4'},
{value: 'orc5', id: 'ORC5'},
{value: 'orc6', id: 'ORC6'},
{value: 'orm1', id: 'ORM1'},
{value: 'ormdl1', id: 'ORMDL1'},
{value: 'ormdl2', id: 'ORMDL2'},
{value: 'ormdl3', id: 'ORMDL3'},
{value: 'os9', id: 'OS9'},
{value: 'osbp', id: 'OSBP'},
{value: 'osbp2', id: 'OSBP2'},
{value: 'osbpl10', id: 'OSBPL10'},
{value: 'osbpl11', id: 'OSBPL11'},
{value: 'osbpl1a', id: 'OSBPL1A'},
{value: 'osbpl2', id: 'OSBPL2'},
{value: 'osbpl3', id: 'OSBPL3'},
{value: 'osbpl5', id: 'OSBPL5'},
{value: 'osbpl6', id: 'OSBPL6'},
{value: 'osbpl7', id: 'OSBPL7'},
{value: 'osbpl8', id: 'OSBPL8'},
{value: 'osbpl9', id: 'OSBPL9'},
{value: 'oscar', id: 'OSCAR'},
{value: 'oscp1', id: 'OSCP1'},
{value: 'oser1', id: 'OSER1'},
{value: 'osgep', id: 'OSGEP'},
{value: 'osgepl1', id: 'OSGEPL1'},
{value: 'osgin1', id: 'OSGIN1'},
{value: 'osgin2', id: 'OSGIN2'},
{value: 'osm', id: 'OSM'},
{value: 'osmr', id: 'OSMR'},
{value: 'osr1', id: 'OSR1'},
{value: 'osr2', id: 'OSR2'},
{value: 'ost4', id: 'OST4'},
{value: 'ostc', id: 'OSTC'},
{value: 'ostf1', id: 'OSTF1'},
{value: 'ostm1', id: 'OSTM1'},
{value: 'ostn', id: 'OSTN'},
{value: 'otc', id: 'OTC'},
{value: 'otoa', id: 'OTOA'},
{value: 'otof', id: 'OTOF'},
{value: 'otog', id: 'OTOG'},
{value: 'otogl', id: 'OTOGL'},
{value: 'otol1', id: 'OTOL1'},
{value: 'otop1', id: 'OTOP1'},
{value: 'otop2', id: 'OTOP2'},
{value: 'otop3', id: 'OTOP3'},
{value: 'otor', id: 'OTOR'},
{value: 'otos', id: 'OTOS'},
{value: 'otp', id: 'OTP'},
{value: 'otub1', id: 'OTUB1'},
{value: 'otub2', id: 'OTUB2'},
{value: 'otud1', id: 'OTUD1'},
{value: 'otud3', id: 'OTUD3'},
{value: 'otud4', id: 'OTUD4'},
{value: 'otud5', id: 'OTUD5'},
{value: 'otud6a', id: 'OTUD6A'},
{value: 'otud6b', id: 'OTUD6B'},
{value: 'otud7a', id: 'OTUD7A'},
{value: 'otud7b', id: 'OTUD7B'},
{value: 'otulin', id: 'OTULIN'},
{value: 'otulinl', id: 'OTULINL'},
{value: 'otx1', id: 'OTX1'},
{value: 'otx2', id: 'OTX2'},
{value: 'ovca2', id: 'OVCA2'},
{value: 'ovch2', id: 'OVCH2'},
{value: 'ovol1', id: 'OVOL1'},
{value: 'ovol2', id: 'OVOL2'},
{value: 'ovol3', id: 'OVOL3'},
{value: 'oxa1l', id: 'OXA1L'},
{value: 'oxct1', id: 'OXCT1'},
{value: 'oxgr1', id: 'OXGR1'},
{value: 'oxld1', id: 'OXLD1'},
{value: 'oxnad1', id: 'OXNAD1'},
{value: 'oxr1', id: 'OXR1'},
{value: 'oxsm', id: 'OXSM'},
{value: 'oxsr1', id: 'OXSR1'},
{value: 'oxt', id: 'OXT'},
{value: 'oxtr', id: 'OXTR'},
{value: 'p22k15', id: 'P22K15'},
{value: 'p2rx1', id: 'P2RX1'},
{value: 'p2rx2', id: 'P2RX2'},
{value: 'p2rx3', id: 'P2RX3'},
{value: 'p2rx4', id: 'P2RX4'},
{value: 'p2rx5', id: 'P2RX5'},
{value: 'p2rx6', id: 'P2RX6'},
{value: 'p2rx7', id: 'P2RX7'},
{value: 'p2ry1', id: 'P2RY1'},
{value: 'p2ry10', id: 'P2RY10'},
{value: 'p2ry12', id: 'P2RY12'},
{value: 'p2ry13', id: 'P2RY13'},
{value: 'p2ry14', id: 'P2RY14'},
{value: 'p2ry2', id: 'P2RY2'},
{value: 'p2ry4', id: 'P2RY4'},
{value: 'p2ry6', id: 'P2RY6'},
{value: 'p3h1', id: 'P3H1'},
{value: 'p3h2', id: 'P3H2'},
{value: 'p3h3', id: 'P3H3'},
{value: 'p3h4', id: 'P3H4'},
{value: 'p3r3urf', id: 'P3R3URF'},
{value: 'p4ha1', id: 'P4HA1'},
{value: 'p4ha2', id: 'P4HA2'},
{value: 'p4ha3', id: 'P4HA3'},
{value: 'p4hb', id: 'P4HB'},
{value: 'p4htm', id: 'P4HTM'},
{value: 'pa2g4', id: 'PA2G4'},
{value: 'pabir1', id: 'PABIR1'},
{value: 'pabir2', id: 'PABIR2'},
{value: 'pabir3', id: 'PABIR3'},
{value: 'pabpc1', id: 'PABPC1'},
{value: 'pabpc1l', id: 'PABPC1L'},
{value: 'pabpc1l2a', id: 'PABPC1L2A'},
{value: 'pabpc2', id: 'PABPC2'},
{value: 'pabpc4', id: 'PABPC4'},
{value: 'pabpc4l', id: 'PABPC4L'},
{value: 'pabpc5', id: 'PABPC5'},
{value: 'pabpc6', id: 'PABPC6'},
{value: 'pabpn1', id: 'PABPN1'},
{value: 'pabpn1l', id: 'PABPN1L'},
{value: 'pacc1', id: 'PACC1'},
{value: 'pacrg', id: 'PACRG'},
{value: 'pacrgl', id: 'PACRGL'},
{value: 'pacs1', id: 'PACS1'},
{value: 'pacs2', id: 'PACS2'},
{value: 'pacsin1', id: 'PACSIN1'},
{value: 'pacsin2', id: 'PACSIN2'},
{value: 'pacsin3', id: 'PACSIN3'},
{value: 'padi1', id: 'PADI1'},
{value: 'padi2', id: 'PADI2'},
{value: 'padi3', id: 'PADI3'},
{value: 'padi4', id: 'PADI4'},
{value: 'padi6', id: 'PADI6'},
{value: 'paep', id: 'PAEP'},
{value: 'paf1', id: 'PAF1'},
{value: 'pafah1b1', id: 'PAFAH1B1'},
{value: 'pafah1b2', id: 'PAFAH1B2'},
{value: 'pafah1b3', id: 'PAFAH1B3'},
{value: 'pafah2', id: 'PAFAH2'},
{value: 'pag1', id: 'PAG1'},
{value: 'pagr1', id: 'PAGR1'},
{value: 'pah', id: 'PAH'},
{value: 'paics', id: 'PAICS'},
{value: 'paip1', id: 'PAIP1'},
{value: 'paip2', id: 'PAIP2'},
{value: 'paip2b', id: 'PAIP2B'},
{value: 'pak1', id: 'PAK1'},
{value: 'pak1ip1', id: 'PAK1IP1'},
{value: 'pak2', id: 'PAK2'},
{value: 'pak3', id: 'PAK3'},
{value: 'pak4', id: 'PAK4'},
{value: 'pak5', id: 'PAK5'},
{value: 'pak6', id: 'PAK6'},
{value: 'palb2', id: 'PALB2'},
{value: 'pald1', id: 'PALD1'},
{value: 'palld', id: 'PALLD'},
{value: 'palldl1', id: 'PALLDL1'},
{value: 'palm', id: 'PALM'},
{value: 'palm2', id: 'PALM2'},
{value: 'palm3', id: 'PALM3'},
{value: 'palmd', id: 'PALMD'},
{value: 'pals1', id: 'PALS1'},
{value: 'pals2', id: 'PALS2'},
{value: 'pam', id: 'PAM'},
{value: 'pam16', id: 'PAM16'},
{value: 'pamr1', id: 'PAMR1'},
{value: 'pan2', id: 'PAN2'},
{value: 'pan3', id: 'PAN3'},
{value: 'pank1', id: 'PANK1'},
{value: 'pank2', id: 'PANK2'},
{value: 'pank3', id: 'PANK3'},
{value: 'pank4', id: 'PANK4'},
{value: 'panx1', id: 'PANX1'},
{value: 'panx2', id: 'PANX2'},
{value: 'panx3', id: 'PANX3'},
{value: 'paox', id: 'PAOX'},
{value: 'papln', id: 'PAPLN'},
{value: 'papola', id: 'PAPOLA'},
{value: 'papolb', id: 'PAPOLB'},
{value: 'papolg', id: 'PAPOLG'},
{value: 'pappa1', id: 'PAPPA1'},
{value: 'pappa2', id: 'PAPPA2'},
{value: 'papss1', id: 'PAPSS1'},
{value: 'papss2', id: 'PAPSS2'},
{value: 'paqr3', id: 'PAQR3'},
{value: 'paqr4', id: 'PAQR4'},
{value: 'paqr5', id: 'PAQR5'},
{value: 'paqr6', id: 'PAQR6'},
{value: 'paqr7', id: 'PAQR7'},
{value: 'paqr8', id: 'PAQR8'},
{value: 'paqr9', id: 'PAQR9'},
{value: 'pard3', id: 'PARD3'},
{value: 'pard3b', id: 'PARD3B'},
{value: 'pard6a', id: 'PARD6A'},
{value: 'pard6b', id: 'PARD6B'},
{value: 'pard6g', id: 'PARD6G'},
{value: 'parg', id: 'PARG'},
{value: 'park7', id: 'PARK7'},
{value: 'parl', id: 'PARL'},
{value: 'parm1', id: 'PARM1'},
{value: 'parn', id: 'PARN'},
{value: 'parp1', id: 'PARP1'},
{value: 'parp10', id: 'PARP10'},
{value: 'parp11', id: 'PARP11'},
{value: 'parp12', id: 'PARP12'},
{value: 'parp14', id: 'PARP14'},
{value: 'parp16', id: 'PARP16'},
{value: 'parp2', id: 'PARP2'},
{value: 'parp3', id: 'PARP3'},
{value: 'parp4', id: 'PARP4'},
{value: 'parp6', id: 'PARP6'},
{value: 'parp8', id: 'PARP8'},
{value: 'parp9', id: 'PARP9'},
{value: 'parpbp', id: 'PARPBP'},
{value: 'pars2', id: 'PARS2'},
{value: 'parva', id: 'PARVA'},
{value: 'parvb', id: 'PARVB'},
{value: 'parvg', id: 'PARVG'},
{value: 'pasd1', id: 'PASD1'},
{value: 'pask', id: 'PASK'},
{value: 'pate-f', id: 'PATE-F'},
{value: 'pate1', id: 'PATE1'},
{value: 'pate2', id: 'PATE2'},
{value: 'pate3', id: 'PATE3'},
{value: 'pate4', id: 'PATE4'},
{value: 'patj', id: 'PATJ'},
{value: 'patl1', id: 'PATL1'},
{value: 'patl2', id: 'PATL2'},
{value: 'patz1', id: 'PATZ1'},
{value: 'pawr', id: 'PAWR'},
{value: 'pax1', id: 'PAX1'},
{value: 'pax2', id: 'PAX2'},
{value: 'pax3', id: 'PAX3'},
{value: 'pax4', id: 'PAX4'},
{value: 'pax5', id: 'PAX5'},
{value: 'pax6', id: 'PAX6'},
{value: 'pax7', id: 'PAX7'},
{value: 'pax8', id: 'PAX8'},
{value: 'pax9', id: 'PAX9'},
{value: 'paxbp1', id: 'PAXBP1'},
{value: 'paxip1', id: 'PAXIP1'},
{value: 'paxx', id: 'PAXX'},
{value: 'pbdc1', id: 'PBDC1'},
{value: 'pbk', id: 'PBK'},
{value: 'pbld1', id: 'PBLD1'},
{value: 'pbp2', id: 'PBP2'},
{value: 'pbrm1', id: 'PBRM1'},
{value: 'pbsn', id: 'PBSN'},
{value: 'pbx1', id: 'PBX1'},
{value: 'pbx2', id: 'PBX2'},
{value: 'pbx3', id: 'PBX3'},
{value: 'pbx4', id: 'PBX4'},
{value: 'pbxip1', id: 'PBXIP1'},
{value: 'pc', id: 'PC'},
{value: 'pcare', id: 'PCARE'},
{value: 'pcbd1', id: 'PCBD1'},
{value: 'pcbd2', id: 'PCBD2'},
{value: 'pcbp1', id: 'PCBP1'},
{value: 'pcbp2', id: 'PCBP2'},
{value: 'pcbp3', id: 'PCBP3'},
{value: 'pcbp4', id: 'PCBP4'},
{value: 'pcca', id: 'PCCA'},
{value: 'pccb', id: 'PCCB'},
{value: 'pcdh1', id: 'PCDH1'},
{value: 'pcdh10', id: 'PCDH10'},
{value: 'pcdh11x', id: 'PCDH11X'},
{value: 'pcdh12', id: 'PCDH12'},
{value: 'pcdh15', id: 'PCDH15'},
{value: 'pcdh17', id: 'PCDH17'},
{value: 'pcdh18', id: 'PCDH18'},
{value: 'pcdh19', id: 'PCDH19'},
{value: 'pcdh20', id: 'PCDH20'},
{value: 'pcdh7', id: 'PCDH7'},
{value: 'pcdh8', id: 'PCDH8'},
{value: 'pcdh9', id: 'PCDH9'},
{value: 'pcdha10', id: 'PCDHA10'},
{value: 'pcdha11', id: 'PCDHA11'},
{value: 'pcdha12', id: 'PCDHA12'},
{value: 'pcdha13', id: 'PCDHA13'},
{value: 'pcdha2', id: 'PCDHA2'},
{value: 'pcdha3', id: 'PCDHA3'},
{value: 'pcdha4', id: 'PCDHA4'},
{value: 'pcdha5', id: 'PCDHA5'},
{value: 'pcdha6', id: 'PCDHA6'},
{value: 'pcdha7', id: 'PCDHA7'},
{value: 'pcdha8', id: 'PCDHA8'},
{value: 'pcdha9', id: 'PCDHA9'},
{value: 'pcdhac1', id: 'PCDHAC1'},
{value: 'pcdhac2', id: 'PCDHAC2'},
{value: 'pcdhb1', id: 'PCDHB1'},
{value: 'pcdhb10', id: 'PCDHB10'},
{value: 'pcdhb11', id: 'PCDHB11'},
{value: 'pcdhb12', id: 'PCDHB12'},
{value: 'pcdhb14', id: 'PCDHB14'},
{value: 'pcdhb16', id: 'PCDHB16'},
{value: 'pcdhb17', id: 'PCDHB17'},
{value: 'pcdhb19', id: 'PCDHB19'},
{value: 'pcdhb2', id: 'PCDHB2'},
{value: 'pcdhb20', id: 'PCDHB20'},
{value: 'pcdhb21', id: 'PCDHB21'},
{value: 'pcdhb22', id: 'PCDHB22'},
{value: 'pcdhb3', id: 'PCDHB3'},
{value: 'pcdhb4', id: 'PCDHB4'},
{value: 'pcdhb5', id: 'PCDHB5'},
{value: 'pcdhb6', id: 'PCDHB6'},
{value: 'pcdhb6l', id: 'PCDHB6L'},
{value: 'pcdhb7', id: 'PCDHB7'},
{value: 'pcdhb8', id: 'PCDHB8'},
{value: 'pcdhb9', id: 'PCDHB9'},
{value: 'pcdhga1', id: 'PCDHGA1'},
{value: 'pcdhga10', id: 'PCDHGA10'},
{value: 'pcdhga11', id: 'PCDHGA11'},
{value: 'pcdhga12', id: 'PCDHGA12'},
{value: 'pcdhga2', id: 'PCDHGA2'},
{value: 'pcdhga3', id: 'PCDHGA3'},
{value: 'pcdhga4', id: 'PCDHGA4'},
{value: 'pcdhga5', id: 'PCDHGA5'},
{value: 'pcdhga6', id: 'PCDHGA6'},
{value: 'pcdhga7', id: 'PCDHGA7'},
{value: 'pcdhga8', id: 'PCDHGA8'},
{value: 'pcdhga9', id: 'PCDHGA9'},
{value: 'pcdhgb1', id: 'PCDHGB1'},
{value: 'pcdhgb4', id: 'PCDHGB4'},
{value: 'pcdhgb5', id: 'PCDHGB5'},
{value: 'pcdhgb6', id: 'PCDHGB6'},
{value: 'pcdhgb7', id: 'PCDHGB7'},
{value: 'pcdhgb8', id: 'PCDHGB8'},
{value: 'pcdhgc3', id: 'PCDHGC3'},
{value: 'pcdhgc5', id: 'PCDHGC5'},
{value: 'pced1a', id: 'PCED1A'},
{value: 'pced1b', id: 'PCED1B'},
{value: 'pcf11', id: 'PCF11'},
{value: 'pcgf1', id: 'PCGF1'},
{value: 'pcgf2', id: 'PCGF2'},
{value: 'pcgf3', id: 'PCGF3'},
{value: 'pcgf5', id: 'PCGF5'},
{value: 'pcgf6', id: 'PCGF6'},
{value: 'pcid2', id: 'PCID2'},
{value: 'pcif1', id: 'PCIF1'},
{value: 'pck1', id: 'PCK1'},
{value: 'pck2', id: 'PCK2'},
{value: 'pclaf', id: 'PCLAF'},
{value: 'pclo', id: 'PCLO'},
{value: 'pcm1', id: 'PCM1'},
{value: 'pcmt1', id: 'PCMT1'},
{value: 'pcmtd1', id: 'PCMTD1'},
{value: 'pcmtd2', id: 'PCMTD2'},
{value: 'pcna', id: 'PCNA'},
{value: 'pcnp', id: 'PCNP'},
{value: 'pcnt', id: 'PCNT'},
{value: 'pcnx1', id: 'PCNX1'},
{value: 'pcnx2', id: 'PCNX2'},
{value: 'pcnx3', id: 'PCNX3'},
{value: 'pcnx4', id: 'PCNX4'},
{value: 'pcolce', id: 'PCOLCE'},
{value: 'pcolce2', id: 'PCOLCE2'},
{value: 'pcp2', id: 'PCP2'},
{value: 'pcp4', id: 'PCP4'},
{value: 'pcp4l1', id: 'PCP4L1'},
{value: 'pcsk1', id: 'PCSK1'},
{value: 'pcsk1n', id: 'PCSK1N'},
{value: 'pcsk2', id: 'PCSK2'},
{value: 'pcsk4', id: 'PCSK4'},
{value: 'pcsk5', id: 'PCSK5'},
{value: 'pcsk6', id: 'PCSK6'},
{value: 'pcsk7', id: 'PCSK7'},
{value: 'pcsk9', id: 'PCSK9'},
{value: 'pctp', id: 'PCTP'},
{value: 'pcyox1', id: 'PCYOX1'},
{value: 'pcyox1l', id: 'PCYOX1L'},
{value: 'pcyt1a', id: 'PCYT1A'},
{value: 'pcyt1b', id: 'PCYT1B'},
{value: 'pcyt2', id: 'PCYT2'},
{value: 'pdap1', id: 'PDAP1'},
{value: 'pdc', id: 'PDC'},
{value: 'pdcd1', id: 'PDCD1'},
{value: 'pdcd10', id: 'PDCD10'},
{value: 'pdcd11', id: 'PDCD11'},
{value: 'pdcd1lg2', id: 'PDCD1LG2'},
{value: 'pdcd2', id: 'PDCD2'},
{value: 'pdcd2l', id: 'PDCD2L'},
{value: 'pdcd4', id: 'PDCD4'},
{value: 'pdcd5', id: 'PDCD5'},
{value: 'pdcd6', id: 'PDCD6'},
{value: 'pdcd6ip', id: 'PDCD6IP'},
{value: 'pdcd7', id: 'PDCD7'},
{value: 'pdcl', id: 'PDCL'},
{value: 'pdcl2', id: 'PDCL2'},
{value: 'pdcl3', id: 'PDCL3'},
{value: 'pde10a', id: 'PDE10A'},
{value: 'pde11a', id: 'PDE11A'},
{value: 'pde12', id: 'PDE12'},
{value: 'pde1a', id: 'PDE1A'},
{value: 'pde1b', id: 'PDE1B'},
{value: 'pde1c', id: 'PDE1C'},
{value: 'pde2a', id: 'PDE2A'},
{value: 'pde3a', id: 'PDE3A'},
{value: 'pde3b', id: 'PDE3B'},
{value: 'pde4a', id: 'PDE4A'},
{value: 'pde4b', id: 'PDE4B'},
{value: 'pde4c', id: 'PDE4C'},
{value: 'pde4d', id: 'PDE4D'},
{value: 'pde4dip', id: 'PDE4DIP'},
{value: 'pde5a', id: 'PDE5A'},
{value: 'pde6a', id: 'PDE6A'},
{value: 'pde6b', id: 'PDE6B'},
{value: 'pde6c', id: 'PDE6C'},
{value: 'pde6d', id: 'PDE6D'},
{value: 'pde6g', id: 'PDE6G'},
{value: 'pde6h', id: 'PDE6H'},
{value: 'pde7a', id: 'PDE7A'},
{value: 'pde7b', id: 'PDE7B'},
{value: 'pde8a', id: 'PDE8A'},
{value: 'pde8b', id: 'PDE8B'},
{value: 'pde9a', id: 'PDE9A'},
{value: 'pdf', id: 'PDF'},
{value: 'pdgfa', id: 'PDGFA'},
{value: 'pdgfb', id: 'PDGFB'},
{value: 'pdgfc', id: 'PDGFC'},
{value: 'pdgfd', id: 'PDGFD'},
{value: 'pdgfra', id: 'PDGFRA'},
{value: 'pdgfrb', id: 'PDGFRB'},
{value: 'pdgfrl', id: 'PDGFRL'},
{value: 'pdha1', id: 'PDHA1'},
{value: 'pdha1l1', id: 'PDHA1L1'},
{value: 'pdha2', id: 'PDHA2'},
{value: 'pdhb', id: 'PDHB'},
{value: 'pdhx', id: 'PDHX'},
{value: 'pdia2', id: 'PDIA2'},
{value: 'pdia3', id: 'PDIA3'},
{value: 'pdia4', id: 'PDIA4'},
{value: 'pdia5', id: 'PDIA5'},
{value: 'pdia6', id: 'PDIA6'},
{value: 'pdik1l', id: 'PDIK1L'},
{value: 'pdilt', id: 'PDILT'},
{value: 'pdk1', id: 'PDK1'},
{value: 'pdk2', id: 'PDK2'},
{value: 'pdk3', id: 'PDK3'},
{value: 'pdk4', id: 'PDK4'},
{value: 'pdlim1', id: 'PDLIM1'},
{value: 'pdlim2', id: 'PDLIM2'},
{value: 'pdlim3', id: 'PDLIM3'},
{value: 'pdlim4', id: 'PDLIM4'},
{value: 'pdlim5', id: 'PDLIM5'},
{value: 'pdlim7', id: 'PDLIM7'},
{value: 'pdp1', id: 'PDP1'},
{value: 'pdp2', id: 'PDP2'},
{value: 'pdpk1', id: 'PDPK1'},
{value: 'pdpn', id: 'PDPN'},
{value: 'pdpr', id: 'PDPR'},
{value: 'pdrg1', id: 'PDRG1'},
{value: 'pds5a', id: 'PDS5A'},
{value: 'pds5b', id: 'PDS5B'},
{value: 'pdss1', id: 'PDSS1'},
{value: 'pdss2', id: 'PDSS2'},
{value: 'pdx1', id: 'PDX1'},
{value: 'pdxdc1', id: 'PDXDC1'},
{value: 'pdxk', id: 'PDXK'},
{value: 'pdxp', id: 'PDXP'},
{value: 'pdyn', id: 'PDYN'},
{value: 'pdzd11', id: 'PDZD11'},
{value: 'pdzd2', id: 'PDZD2'},
{value: 'pdzd3', id: 'PDZD3'},
{value: 'pdzd4', id: 'PDZD4'},
{value: 'pdzd7', id: 'PDZD7'},
{value: 'pdzd8', id: 'PDZD8'},
{value: 'pdzd9', id: 'PDZD9'},
{value: 'pdzk1', id: 'PDZK1'},
{value: 'pdzk1ip1', id: 'PDZK1IP1'},
{value: 'pdzrn3', id: 'PDZRN3'},
{value: 'pdzrn4', id: 'PDZRN4'},
{value: 'pea15', id: 'PEA15'},
{value: 'peak1', id: 'PEAK1'},
{value: 'pear1', id: 'PEAR1'},
{value: 'pebp1', id: 'PEBP1'},
{value: 'pecam1', id: 'PECAM1'},
{value: 'pecr', id: 'PECR'},
{value: 'peds1', id: 'PEDS1'},
{value: 'pef1', id: 'PEF1'},
{value: 'peg10', id: 'PEG10'},
{value: 'peg12', id: 'PEG12'},
{value: 'peg3', id: 'PEG3'},
{value: 'peli1', id: 'PELI1'},
{value: 'peli2', id: 'PELI2'},
{value: 'peli3', id: 'PELI3'},
{value: 'pelo', id: 'PELO'},
{value: 'pelp1', id: 'PELP1'},
{value: 'pemt', id: 'PEMT'},
{value: 'penk', id: 'PENK'},
{value: 'pepd', id: 'PEPD'},
{value: 'per1', id: 'PER1'},
{value: 'per2', id: 'PER2'},
{value: 'per3', id: 'PER3'},
{value: 'perm1', id: 'PERM1'},
{value: 'perp', id: 'PERP'},
{value: 'pes1', id: 'PES1'},
{value: 'pet100', id: 'PET100'},
{value: 'pex1', id: 'PEX1'},
{value: 'pex10', id: 'PEX10'},
{value: 'pex11a', id: 'PEX11A'},
{value: 'pex11b', id: 'PEX11B'},
{value: 'pex11g', id: 'PEX11G'},
{value: 'pex12', id: 'PEX12'},
{value: 'pex13', id: 'PEX13'},
{value: 'pex14', id: 'PEX14'},
{value: 'pex16', id: 'PEX16'},
{value: 'pex19', id: 'PEX19'},
{value: 'pex2', id: 'PEX2'},
{value: 'pex26', id: 'PEX26'},
{value: 'pex3', id: 'PEX3'},
{value: 'pex5', id: 'PEX5'},
{value: 'pex5l', id: 'PEX5L'},
{value: 'pex6', id: 'PEX6'},
{value: 'pex7', id: 'PEX7'},
{value: 'pf4', id: 'PF4'},
{value: 'pfas', id: 'PFAS'},
{value: 'pfdn1', id: 'PFDN1'},
{value: 'pfdn2', id: 'PFDN2'},
{value: 'pfdn4', id: 'PFDN4'},
{value: 'pfdn5', id: 'PFDN5'},
{value: 'pfdn6', id: 'PFDN6'},
{value: 'pfkfb1', id: 'PFKFB1'},
{value: 'pfkfb2', id: 'PFKFB2'},
{value: 'pfkfb3', id: 'PFKFB3'},
{value: 'pfkfb4', id: 'PFKFB4'},
{value: 'pfkl', id: 'PFKL'},
{value: 'pfkm', id: 'PFKM'},
{value: 'pfkp', id: 'PFKP'},
{value: 'pfn1', id: 'PFN1'},
{value: 'pfn2', id: 'PFN2'},
{value: 'pfn3', id: 'PFN3'},
{value: 'pfn4', id: 'PFN4'},
{value: 'pfpl', id: 'PFPL'},
{value: 'pga5', id: 'PGA5'},
{value: 'pgam1', id: 'PGAM1'},
{value: 'pgam2', id: 'PGAM2'},
{value: 'pgam5', id: 'PGAM5'},
{value: 'pgap1', id: 'PGAP1'},
{value: 'pgap2', id: 'PGAP2'},
{value: 'pgap3', id: 'PGAP3'},
{value: 'pgap4', id: 'PGAP4'},
{value: 'pgap6', id: 'PGAP6'},
{value: 'pgbd5', id: 'PGBD5'},
{value: 'pgc', id: 'PGC'},
{value: 'pgd', id: 'PGD'},
{value: 'pgf', id: 'PGF'},
{value: 'pgghg', id: 'PGGHG'},
{value: 'pggt1b', id: 'PGGT1B'},
{value: 'pgk1', id: 'PGK1'},
{value: 'pgk2', id: 'PGK2'},
{value: 'pgls', id: 'PGLS'},
{value: 'pglyrp1', id: 'PGLYRP1'},
{value: 'pglyrp2', id: 'PGLYRP2'},
{value: 'pglyrp3', id: 'PGLYRP3'},
{value: 'pglyrp4', id: 'PGLYRP4'},
{value: 'pgm1', id: 'PGM1'},
{value: 'pgm2', id: 'PGM2'},
{value: 'pgm2l1', id: 'PGM2L1'},
{value: 'pgm3', id: 'PGM3'},
{value: 'pgm5', id: 'PGM5'},
{value: 'pgp', id: 'PGP'},
{value: 'pgpep1', id: 'PGPEP1'},
{value: 'pgpep1l', id: 'PGPEP1L'},
{value: 'pgr', id: 'PGR'},
{value: 'pgr15l', id: 'PGR15L'},
{value: 'pgrmc1', id: 'PGRMC1'},
{value: 'pgrmc2', id: 'PGRMC2'},
{value: 'pgs1', id: 'PGS1'},
{value: 'phactr1', id: 'PHACTR1'},
{value: 'phactr2', id: 'PHACTR2'},
{value: 'phactr3', id: 'PHACTR3'},
{value: 'phactr4', id: 'PHACTR4'},
{value: 'phaf1', id: 'PHAF1'},
{value: 'phax', id: 'PHAX'},
{value: 'phb-ps1', id: 'PHB-PS1'},
{value: 'phb1', id: 'PHB1'},
{value: 'phb2', id: 'PHB2'},
{value: 'phc1', id: 'PHC1'},
{value: 'phc2', id: 'PHC2'},
{value: 'phc3', id: 'PHC3'},
{value: 'pheta1', id: 'PHETA1'},
{value: 'pheta2', id: 'PHETA2'},
{value: 'phex', id: 'PHEX'},
{value: 'phf1', id: 'PHF1'},
{value: 'phf10', id: 'PHF10'},
{value: 'phf11', id: 'PHF11'},
{value: 'phf11b', id: 'PHF11B'},
{value: 'phf12', id: 'PHF12'},
{value: 'phf13', id: 'PHF13'},
{value: 'phf14', id: 'PHF14'},
{value: 'phf19', id: 'PHF19'},
{value: 'phf2', id: 'PHF2'},
{value: 'phf20', id: 'PHF20'},
{value: 'phf20l1', id: 'PHF20L1'},
{value: 'phf21a', id: 'PHF21A'},
{value: 'phf21b', id: 'PHF21B'},
{value: 'phf23', id: 'PHF23'},
{value: 'phf24', id: 'PHF24'},
{value: 'phf3', id: 'PHF3'},
{value: 'phf5a', id: 'PHF5A'},
{value: 'phf6', id: 'PHF6'},
{value: 'phf7', id: 'PHF7'},
{value: 'phf8', id: 'PHF8'},
{value: 'phgdh', id: 'PHGDH'},
{value: 'phip', id: 'PHIP'},
{value: 'phka1', id: 'PHKA1'},
{value: 'phka2', id: 'PHKA2'},
{value: 'phkb', id: 'PHKB'},
{value: 'phkg1', id: 'PHKG1'},
{value: 'phkg2', id: 'PHKG2'},
{value: 'phlda1', id: 'PHLDA1'},
{value: 'phlda2', id: 'PHLDA2'},
{value: 'phlda3', id: 'PHLDA3'},
{value: 'phldb1', id: 'PHLDB1'},
{value: 'phldb2', id: 'PHLDB2'},
{value: 'phldb3', id: 'PHLDB3'},
{value: 'phlpp1', id: 'PHLPP1'},
{value: 'phlpp2', id: 'PHLPP2'},
{value: 'phospho1', id: 'PHOSPHO1'},
{value: 'phospho2', id: 'PHOSPHO2'},
{value: 'phox2a', id: 'PHOX2A'},
{value: 'phox2b', id: 'PHOX2B'},
{value: 'phpt1', id: 'PHPT1'},
{value: 'phrf1', id: 'PHRF1'},
{value: 'phtf1', id: 'PHTF1'},
{value: 'phtf2', id: 'PHTF2'},
{value: 'phyh', id: 'PHYH'},
{value: 'phyhd1', id: 'PHYHD1'},
{value: 'phyhip', id: 'PHYHIP'},
{value: 'phyhipl', id: 'PHYHIPL'},
{value: 'phykpl', id: 'PHYKPL'},
{value: 'pi15', id: 'PI15'},
{value: 'pi16', id: 'PI16'},
{value: 'pi4k2a', id: 'PI4K2A'},
{value: 'pi4k2b', id: 'PI4K2B'},
{value: 'pi4ka', id: 'PI4KA'},
{value: 'pi4kb', id: 'PI4KB'},
{value: 'pianp', id: 'PIANP'},
{value: 'pias1', id: 'PIAS1'},
{value: 'pias2', id: 'PIAS2'},
{value: 'pias3', id: 'PIAS3'},
{value: 'pias4', id: 'PIAS4'},
{value: 'pibf1', id: 'PIBF1'},
{value: 'picalm', id: 'PICALM'},
{value: 'pick1', id: 'PICK1'},
{value: 'pid1', id: 'PID1'},
{value: 'pidd1', id: 'PIDD1'},
{value: 'piezo1', id: 'PIEZO1'},
{value: 'piezo2', id: 'PIEZO2'},
{value: 'pif1', id: 'PIF1'},
{value: 'pifo', id: 'PIFO'},
{value: 'piga', id: 'PIGA'},
{value: 'pigb', id: 'PIGB'},
{value: 'pigbos1', id: 'PIGBOS1'},
{value: 'pigc', id: 'PIGC'},
{value: 'pigf', id: 'PIGF'},
{value: 'pigg', id: 'PIGG'},
{value: 'pigh', id: 'PIGH'},
{value: 'pigk', id: 'PIGK'},
{value: 'pigl', id: 'PIGL'},
{value: 'pigm', id: 'PIGM'},
{value: 'pign', id: 'PIGN'},
{value: 'pigo', id: 'PIGO'},
{value: 'pigp', id: 'PIGP'},
{value: 'pigq', id: 'PIGQ'},
{value: 'pigr', id: 'PIGR'},
{value: 'pigs', id: 'PIGS'},
{value: 'pigt', id: 'PIGT'},
{value: 'pigu', id: 'PIGU'},
{value: 'pigv', id: 'PIGV'},
{value: 'pigw', id: 'PIGW'},
{value: 'pigx', id: 'PIGX'},
{value: 'pigy', id: 'PIGY'},
{value: 'pigz', id: 'PIGZ'},
{value: 'pih1d1', id: 'PIH1D1'},
{value: 'pih1d2', id: 'PIH1D2'},
{value: 'pik3ap1', id: 'PIK3AP1'},
{value: 'pik3c2a', id: 'PIK3C2A'},
{value: 'pik3c2b', id: 'PIK3C2B'},
{value: 'pik3c2g', id: 'PIK3C2G'},
{value: 'pik3c3', id: 'PIK3C3'},
{value: 'pik3ca', id: 'PIK3CA'},
{value: 'pik3cb', id: 'PIK3CB'},
{value: 'pik3cd', id: 'PIK3CD'},
{value: 'pik3cg', id: 'PIK3CG'},
{value: 'pik3ip1', id: 'PIK3IP1'},
{value: 'pik3r1', id: 'PIK3R1'},
{value: 'pik3r2', id: 'PIK3R2'},
{value: 'pik3r3', id: 'PIK3R3'},
{value: 'pik3r4', id: 'PIK3R4'},
{value: 'pik3r5', id: 'PIK3R5'},
{value: 'pik3r6', id: 'PIK3R6'},
{value: 'pikfyve', id: 'PIKFYVE'},
{value: 'pilra', id: 'PILRA'},
{value: 'pilrb', id: 'PILRB'},
{value: 'pim1', id: 'PIM1'},
{value: 'pim2', id: 'PIM2'},
{value: 'pim3', id: 'PIM3'},
{value: 'pimreg', id: 'PIMREG'},
{value: 'pin1', id: 'PIN1'},
{value: 'pin4', id: 'PIN4'},
{value: 'pink1', id: 'PINK1'},
{value: 'pinlyp', id: 'PINLYP'},
{value: 'pinx1', id: 'PINX1'},
{value: 'pip', id: 'PIP'},
{value: 'pip4k2a', id: 'PIP4K2A'},
{value: 'pip4k2b', id: 'PIP4K2B'},
{value: 'pip4k2c', id: 'PIP4K2C'},
{value: 'pip4p1', id: 'PIP4P1'},
{value: 'pip4p2', id: 'PIP4P2'},
{value: 'pip5k1a', id: 'PIP5K1A'},
{value: 'pip5k1b', id: 'PIP5K1B'},
{value: 'pip5k1c', id: 'PIP5K1C'},
{value: 'pip5kl1', id: 'PIP5KL1'},
{value: 'pipox', id: 'PIPOX'},
{value: 'pir', id: 'PIR'},
{value: 'pirb', id: 'PIRB'},
{value: 'pirt', id: 'PIRT'},
{value: 'pisd', id: 'PISD'},
{value: 'pithd1', id: 'PITHD1'},
{value: 'pitpna', id: 'PITPNA'},
{value: 'pitpnb', id: 'PITPNB'},
{value: 'pitpnc1', id: 'PITPNC1'},
{value: 'pitpnm1', id: 'PITPNM1'},
{value: 'pitpnm2', id: 'PITPNM2'},
{value: 'pitpnm3', id: 'PITPNM3'},
{value: 'pitrm1', id: 'PITRM1'},
{value: 'pitx1', id: 'PITX1'},
{value: 'pitx2', id: 'PITX2'},
{value: 'pitx3', id: 'PITX3'},
{value: 'piwil1', id: 'PIWIL1'},
{value: 'piwil2', id: 'PIWIL2'},
{value: 'piwil4', id: 'PIWIL4'},
{value: 'pja1', id: 'PJA1'},
{value: 'pja2', id: 'PJA2'},
{value: 'pjvk', id: 'PJVK'},
{value: 'pkd1', id: 'PKD1'},
{value: 'pkd1l1', id: 'PKD1L1'},
{value: 'pkd1l2', id: 'PKD1L2'},
{value: 'pkd1l3', id: 'PKD1L3'},
{value: 'pkd2', id: 'PKD2'},
{value: 'pkd2l1', id: 'PKD2L1'},
{value: 'pkd2l2', id: 'PKD2L2'},
{value: 'pkdcc', id: 'PKDCC'},
{value: 'pkdrej', id: 'PKDREJ'},
{value: 'pkhd1', id: 'PKHD1'},
{value: 'pkhd1l1', id: 'PKHD1L1'},
{value: 'pkia', id: 'PKIA'},
{value: 'pkib', id: 'PKIB'},
{value: 'pkig', id: 'PKIG'},
{value: 'pklr', id: 'PKLR'},
{value: 'pkm', id: 'PKM'},
{value: 'pkmyt1', id: 'PKMYT1'},
{value: 'pkn1', id: 'PKN1'},
{value: 'pkn2', id: 'PKN2'},
{value: 'pkn3', id: 'PKN3'},
{value: 'pknox1', id: 'PKNOX1'},
{value: 'pknox2', id: 'PKNOX2'},
{value: 'pkp1', id: 'PKP1'},
{value: 'pkp2', id: 'PKP2'},
{value: 'pkp3', id: 'PKP3'},
{value: 'pkp4', id: 'PKP4'},
{value: 'pla1a', id: 'PLA1A'},
{value: 'pla2g10', id: 'PLA2G10'},
{value: 'pla2g12a', id: 'PLA2G12A'},
{value: 'pla2g12b', id: 'PLA2G12B'},
{value: 'pla2g15', id: 'PLA2G15'},
{value: 'pla2g1b', id: 'PLA2G1B'},
{value: 'pla2g2a', id: 'PLA2G2A'},
{value: 'pla2g2c', id: 'PLA2G2C'},
{value: 'pla2g2d', id: 'PLA2G2D'},
{value: 'pla2g2e', id: 'PLA2G2E'},
{value: 'pla2g2f', id: 'PLA2G2F'},
{value: 'pla2g3', id: 'PLA2G3'},
{value: 'pla2g4a', id: 'PLA2G4A'},
{value: 'pla2g4b', id: 'PLA2G4B'},
{value: 'pla2g4c', id: 'PLA2G4C'},
{value: 'pla2g4cl1', id: 'PLA2G4CL1'},
{value: 'pla2g4d', id: 'PLA2G4D'},
{value: 'pla2g4e', id: 'PLA2G4E'},
{value: 'pla2g4f', id: 'PLA2G4F'},
{value: 'pla2g5', id: 'PLA2G5'},
{value: 'pla2g6', id: 'PLA2G6'},
{value: 'pla2g7', id: 'PLA2G7'},
{value: 'pla2r1', id: 'PLA2R1'},
{value: 'plaa', id: 'PLAA'},
{value: 'plaat1', id: 'PLAAT1'},
{value: 'plaat3', id: 'PLAAT3'},
{value: 'plaat5', id: 'PLAAT5'},
{value: 'plac1', id: 'PLAC1'},
{value: 'plac8', id: 'PLAC8'},
{value: 'plac8l1', id: 'PLAC8L1'},
{value: 'plac9', id: 'PLAC9'},
{value: 'plag1', id: 'PLAG1'},
{value: 'plagl1', id: 'PLAGL1'},
{value: 'plagl2', id: 'PLAGL2'},
{value: 'plat', id: 'PLAT'},
{value: 'plau', id: 'PLAU'},
{value: 'plaur', id: 'PLAUR'},
{value: 'plb1', id: 'PLB1'},
{value: 'plbd1', id: 'PLBD1'},
{value: 'plbd2', id: 'PLBD2'},
{value: 'plcb1', id: 'PLCB1'},
{value: 'plcb2', id: 'PLCB2'},
{value: 'plcb3', id: 'PLCB3'},
{value: 'plcb4', id: 'PLCB4'},
{value: 'plcd1', id: 'PLCD1'},
{value: 'plcd3', id: 'PLCD3'},
{value: 'plcd4', id: 'PLCD4'},
{value: 'plce1', id: 'PLCE1'},
{value: 'plcg1', id: 'PLCG1'},
{value: 'plcg2', id: 'PLCG2'},
{value: 'plch1', id: 'PLCH1'},
{value: 'plch2', id: 'PLCH2'},
{value: 'plcl1', id: 'PLCL1'},
{value: 'plcl2', id: 'PLCL2'},
{value: 'plcxd1', id: 'PLCXD1'},
{value: 'plcxd2', id: 'PLCXD2'},
{value: 'plcxd3', id: 'PLCXD3'},
{value: 'plcz1', id: 'PLCZ1'},
{value: 'pld1', id: 'PLD1'},
{value: 'pld2', id: 'PLD2'},
{value: 'pld3', id: 'PLD3'},
{value: 'pld4', id: 'PLD4'},
{value: 'pld5', id: 'PLD5'},
{value: 'pld6', id: 'PLD6'},
{value: 'plec', id: 'PLEC'},
{value: 'plek', id: 'PLEK'},
{value: 'plek2', id: 'PLEK2'},
{value: 'plekha1', id: 'PLEKHA1'},
{value: 'plekha2', id: 'PLEKHA2'},
{value: 'plekha3', id: 'PLEKHA3'},
{value: 'plekha4', id: 'PLEKHA4'},
{value: 'plekha5', id: 'PLEKHA5'},
{value: 'plekha6', id: 'PLEKHA6'},
{value: 'plekha7', id: 'PLEKHA7'},
{value: 'plekha8', id: 'PLEKHA8'},
{value: 'plekhb1', id: 'PLEKHB1'},
{value: 'plekhb2', id: 'PLEKHB2'},
{value: 'plekhd1', id: 'PLEKHD1'},
{value: 'plekhf1', id: 'PLEKHF1'},
{value: 'plekhf2', id: 'PLEKHF2'},
{value: 'plekhg1', id: 'PLEKHG1'},
{value: 'plekhg2', id: 'PLEKHG2'},
{value: 'plekhg3', id: 'PLEKHG3'},
{value: 'plekhg4', id: 'PLEKHG4'},
{value: 'plekhg5', id: 'PLEKHG5'},
{value: 'plekhg6', id: 'PLEKHG6'},
{value: 'plekhg7', id: 'PLEKHG7'},
{value: 'plekhh1', id: 'PLEKHH1'},
{value: 'plekhh2', id: 'PLEKHH2'},
{value: 'plekhh3', id: 'PLEKHH3'},
{value: 'plekhj1', id: 'PLEKHJ1'},
{value: 'plekhm1', id: 'PLEKHM1'},
{value: 'plekhm2', id: 'PLEKHM2'},
{value: 'plekhm3', id: 'PLEKHM3'},
{value: 'plekhn1', id: 'PLEKHN1'},
{value: 'plekho1', id: 'PLEKHO1'},
{value: 'plekho2', id: 'PLEKHO2'},
{value: 'plekhs1', id: 'PLEKHS1'},
{value: 'plet1', id: 'PLET1'},
{value: 'plg', id: 'PLG'},
{value: 'plgrkt', id: 'PLGRKT'},
{value: 'plin1', id: 'PLIN1'},
{value: 'plin2', id: 'PLIN2'},
{value: 'plin3', id: 'PLIN3'},
{value: 'plin4', id: 'PLIN4'},
{value: 'plin5', id: 'PLIN5'},
{value: 'plk1', id: 'PLK1'},
{value: 'plk2', id: 'PLK2'},
{value: 'plk3', id: 'PLK3'},
{value: 'plk4', id: 'PLK4'},
{value: 'plk5', id: 'PLK5'},
{value: 'pllp', id: 'PLLP'},
{value: 'pln', id: 'PLN'},
{value: 'plod1', id: 'PLOD1'},
{value: 'plod2', id: 'PLOD2'},
{value: 'plod3', id: 'PLOD3'},
{value: 'plp1', id: 'PLP1'},
{value: 'plp2', id: 'PLP2'},
{value: 'plpbp', id: 'PLPBP'},
{value: 'plpp1', id: 'PLPP1'},
{value: 'plpp2', id: 'PLPP2'},
{value: 'plpp3', id: 'PLPP3'},
{value: 'plpp4', id: 'PLPP4'},
{value: 'plpp5', id: 'PLPP5'},
{value: 'plpp6', id: 'PLPP6'},
{value: 'plpp7', id: 'PLPP7'},
{value: 'plppr1', id: 'PLPPR1'},
{value: 'plppr2', id: 'PLPPR2'},
{value: 'plppr3', id: 'PLPPR3'},
{value: 'plppr4', id: 'PLPPR4'},
{value: 'plppr5', id: 'PLPPR5'},
{value: 'plrg1', id: 'PLRG1'},
{value: 'pls1', id: 'PLS1'},
{value: 'pls3', id: 'PLS3'},
{value: 'plscr1', id: 'PLSCR1'},
{value: 'plscr2', id: 'PLSCR2'},
{value: 'plscr3', id: 'PLSCR3'},
{value: 'plscr4', id: 'PLSCR4'},
{value: 'plscr5', id: 'PLSCR5'},
{value: 'pltp', id: 'PLTP'},
{value: 'plvap', id: 'PLVAP'},
{value: 'plxdc1', id: 'PLXDC1'},
{value: 'plxdc2', id: 'PLXDC2'},
{value: 'plxna1', id: 'PLXNA1'},
{value: 'plxna2', id: 'PLXNA2'},
{value: 'plxna3', id: 'PLXNA3'},
{value: 'plxna4', id: 'PLXNA4'},
{value: 'plxnb1', id: 'PLXNB1'},
{value: 'plxnb2', id: 'PLXNB2'},
{value: 'plxnb3', id: 'PLXNB3'},
{value: 'plxnc1', id: 'PLXNC1'},
{value: 'plxnd1', id: 'PLXND1'},
{value: 'pm20d1', id: 'PM20D1'},
{value: 'pm20d2', id: 'PM20D2'},
{value: 'pmaip1', id: 'PMAIP1'},
{value: 'pmch', id: 'PMCH'},
{value: 'pmel', id: 'PMEL'},
{value: 'pmepa1', id: 'PMEPA1'},
{value: 'pmf1', id: 'PMF1'},
{value: 'pmfbp1', id: 'PMFBP1'},
{value: 'pml', id: 'PML'},
{value: 'pmm1', id: 'PMM1'},
{value: 'pmm2', id: 'PMM2'},
{value: 'pmp2', id: 'PMP2'},
{value: 'pmp22', id: 'PMP22'},
{value: 'pmpca', id: 'PMPCA'},
{value: 'pmpcb', id: 'PMPCB'},
{value: 'pms1', id: 'PMS1'},
{value: 'pms2', id: 'PMS2'},
{value: 'pmvk', id: 'PMVK'},
{value: 'pnck', id: 'PNCK'},
{value: 'pnisr', id: 'PNISR'},
{value: 'pnkd', id: 'PNKD'},
{value: 'pnkp', id: 'PNKP'},
{value: 'pnldc1', id: 'PNLDC1'},
{value: 'pnlip', id: 'PNLIP'},
{value: 'pnliprp1', id: 'PNLIPRP1'},
{value: 'pnliprp2', id: 'PNLIPRP2'},
{value: 'pnma1', id: 'PNMA1'},
{value: 'pnma2', id: 'PNMA2'},
{value: 'pnma3', id: 'PNMA3'},
{value: 'pnma5', id: 'PNMA5'},
{value: 'pnma8a', id: 'PNMA8A'},
{value: 'pnma8b', id: 'PNMA8B'},
{value: 'pnmt', id: 'PNMT'},
{value: 'pnn', id: 'PNN'},
{value: 'pno1', id: 'PNO1'},
{value: 'pnoc', id: 'PNOC'},
{value: 'pnp', id: 'PNP'},
{value: 'pnpla1', id: 'PNPLA1'},
{value: 'pnpla2', id: 'PNPLA2'},
{value: 'pnpla3', id: 'PNPLA3'},
{value: 'pnpla4', id: 'PNPLA4'},
{value: 'pnpla5', id: 'PNPLA5'},
{value: 'pnpla6', id: 'PNPLA6'},
{value: 'pnpla7', id: 'PNPLA7'},
{value: 'pnpla8', id: 'PNPLA8'},
{value: 'pnpo', id: 'PNPO'},
{value: 'pnpt1', id: 'PNPT1'},
{value: 'pnrc1', id: 'PNRC1'},
{value: 'pnrc2', id: 'PNRC2'},
{value: 'poc1a', id: 'POC1A'},
{value: 'poc1b', id: 'POC1B'},
{value: 'poc5', id: 'POC5'},
{value: 'podn', id: 'PODN'},
{value: 'podnl1', id: 'PODNL1'},
{value: 'podxl', id: 'PODXL'},
{value: 'podxl2', id: 'PODXL2'},
{value: 'pof1b', id: 'POF1B'},
{value: 'pofut1', id: 'POFUT1'},
{value: 'pofut2', id: 'POFUT2'},
{value: 'pogk', id: 'POGK'},
{value: 'poglut1', id: 'POGLUT1'},
{value: 'poglut2', id: 'POGLUT2'},
{value: 'poglut3', id: 'POGLUT3'},
{value: 'pogz', id: 'POGZ'},
{value: 'pola1', id: 'POLA1'},
{value: 'pola2', id: 'POLA2'},
{value: 'polb', id: 'POLB'},
{value: 'pold1', id: 'POLD1'},
{value: 'pold2', id: 'POLD2'},
{value: 'pold3', id: 'POLD3'},
{value: 'pold4', id: 'POLD4'},
{value: 'poldip2', id: 'POLDIP2'},
{value: 'poldip3', id: 'POLDIP3'},
{value: 'pole', id: 'POLE'},
{value: 'pole2', id: 'POLE2'},
{value: 'pole3', id: 'POLE3'},
{value: 'pole4', id: 'POLE4'},
{value: 'polg', id: 'POLG'},
{value: 'polg2', id: 'POLG2'},
{value: 'polh', id: 'POLH'},
{value: 'poli', id: 'POLI'},
{value: 'polk', id: 'POLK'},
{value: 'poll', id: 'POLL'},
{value: 'polm', id: 'POLM'},
{value: 'poln', id: 'POLN'},
{value: 'polq', id: 'POLQ'},
{value: 'polr1a', id: 'POLR1A'},
{value: 'polr1b', id: 'POLR1B'},
{value: 'polr1c', id: 'POLR1C'},
{value: 'polr1d', id: 'POLR1D'},
{value: 'polr1e', id: 'POLR1E'},
{value: 'polr1f', id: 'POLR1F'},
{value: 'polr1g', id: 'POLR1G'},
{value: 'polr1h', id: 'POLR1H'},
{value: 'polr2a', id: 'POLR2A'},
{value: 'polr2b', id: 'POLR2B'},
{value: 'polr2c', id: 'POLR2C'},
{value: 'polr2d', id: 'POLR2D'},
{value: 'polr2e', id: 'POLR2E'},
{value: 'polr2f', id: 'POLR2F'},
{value: 'polr2g', id: 'POLR2G'},
{value: 'polr2h', id: 'POLR2H'},
{value: 'polr2i', id: 'POLR2I'},
{value: 'polr2j', id: 'POLR2J'},
{value: 'polr2k', id: 'POLR2K'},
{value: 'polr2l', id: 'POLR2L'},
{value: 'polr2m', id: 'POLR2M'},
{value: 'polr3a', id: 'POLR3A'},
{value: 'polr3b', id: 'POLR3B'},
{value: 'polr3c', id: 'POLR3C'},
{value: 'polr3d', id: 'POLR3D'},
{value: 'polr3e', id: 'POLR3E'},
{value: 'polr3f', id: 'POLR3F'},
{value: 'polr3g', id: 'POLR3G'},
{value: 'polr3gl', id: 'POLR3GL'},
{value: 'polr3h', id: 'POLR3H'},
{value: 'polr3k', id: 'POLR3K'},
{value: 'polrmt', id: 'POLRMT'},
{value: 'pom121', id: 'POM121'},
{value: 'pom121l12', id: 'POM121L12'},
{value: 'pom121l2', id: 'POM121L2'},
{value: 'pomc', id: 'POMC'},
{value: 'pomgnt1', id: 'POMGNT1'},
{value: 'pomgnt2', id: 'POMGNT2'},
{value: 'pomk', id: 'POMK'},
{value: 'pomp', id: 'POMP'},
{value: 'pomt1', id: 'POMT1'},
{value: 'pomt2', id: 'POMT2'},
{value: 'pon1', id: 'PON1'},
{value: 'pon2', id: 'PON2'},
{value: 'pon3', id: 'PON3'},
{value: 'pop1', id: 'POP1'},
{value: 'pop4', id: 'POP4'},
{value: 'pop5', id: 'POP5'},
{value: 'pop7', id: 'POP7'},
{value: 'popdc2', id: 'POPDC2'},
{value: 'popdc3', id: 'POPDC3'},
{value: 'por', id: 'POR'},
{value: 'porcn', id: 'PORCN'},
{value: 'postn', id: 'POSTN'},
{value: 'pot1', id: 'POT1'},
{value: 'pot1b', id: 'POT1B'},
{value: 'potec', id: 'POTEC'},
{value: 'poted', id: 'POTED'},
{value: 'potef', id: 'POTEF'},
{value: 'poteg', id: 'POTEG'},
{value: 'poteh', id: 'POTEH'},
{value: 'potem', id: 'POTEM'},
{value: 'pou1f1', id: 'POU1F1'},
{value: 'pou2af1', id: 'POU2AF1'},
{value: 'pou2f1', id: 'POU2F1'},
{value: 'pou2f2', id: 'POU2F2'},
{value: 'pou2f3', id: 'POU2F3'},
{value: 'pou3f1', id: 'POU3F1'},
{value: 'pou3f2', id: 'POU3F2'},
{value: 'pou3f3', id: 'POU3F3'},
{value: 'pou3f4', id: 'POU3F4'},
{value: 'pou4f1', id: 'POU4F1'},
{value: 'pou4f2', id: 'POU4F2'},
{value: 'pou4f3', id: 'POU4F3'},
{value: 'pou5f1', id: 'POU5F1'},
{value: 'pou5f2', id: 'POU5F2'},
{value: 'pou6f1', id: 'POU6F1'},
{value: 'pp2d1', id: 'PP2D1'},
{value: 'ppa1', id: 'PPA1'},
{value: 'ppa2', id: 'PPA2'},
{value: 'ppan', id: 'PPAN'},
{value: 'ppara', id: 'PPARA'},
{value: 'ppard', id: 'PPARD'},
{value: 'pparg', id: 'PPARG'},
{value: 'ppargc1a', id: 'PPARGC1A'},
{value: 'ppargc1b', id: 'PPARGC1B'},
{value: 'ppat', id: 'PPAT'},
{value: 'ppbp', id: 'PPBP'},
{value: 'ppcdc', id: 'PPCDC'},
{value: 'ppcs', id: 'PPCS'},
{value: 'ppdpf', id: 'PPDPF'},
{value: 'ppef1', id: 'PPEF1'},
{value: 'ppef2', id: 'PPEF2'},
{value: 'ppfia1', id: 'PPFIA1'},
{value: 'ppfia2', id: 'PPFIA2'},
{value: 'ppfia3', id: 'PPFIA3'},
{value: 'ppfia4', id: 'PPFIA4'},
{value: 'ppfibp1', id: 'PPFIBP1'},
{value: 'ppfibp2', id: 'PPFIBP2'},
{value: 'pphln1', id: 'PPHLN1'},
{value: 'ppia', id: 'PPIA'},
{value: 'ppial4d', id: 'PPIAL4D'},
{value: 'ppial4g', id: 'PPIAL4G'},
{value: 'ppib', id: 'PPIB'},
{value: 'ppic', id: 'PPIC'},
{value: 'ppid', id: 'PPID'},
{value: 'ppidl1', id: 'PPIDL1'},
{value: 'ppie', id: 'PPIE'},
{value: 'ppif', id: 'PPIF'},
{value: 'ppig', id: 'PPIG'},
{value: 'ppih', id: 'PPIH'},
{value: 'ppil1', id: 'PPIL1'},
{value: 'ppil2', id: 'PPIL2'},
{value: 'ppil3', id: 'PPIL3'},
{value: 'ppil4', id: 'PPIL4'},
{value: 'ppil6', id: 'PPIL6'},
{value: 'ppip5k1', id: 'PPIP5K1'},
{value: 'ppip5k2', id: 'PPIP5K2'},
{value: 'ppl', id: 'PPL'},
{value: 'ppm1a', id: 'PPM1A'},
{value: 'ppm1b', id: 'PPM1B'},
{value: 'ppm1d', id: 'PPM1D'},
{value: 'ppm1e', id: 'PPM1E'},
{value: 'ppm1f', id: 'PPM1F'},
{value: 'ppm1g', id: 'PPM1G'},
{value: 'ppm1h', id: 'PPM1H'},
{value: 'ppm1j', id: 'PPM1J'},
{value: 'ppm1k', id: 'PPM1K'},
{value: 'ppm1l', id: 'PPM1L'},
{value: 'ppm1m', id: 'PPM1M'},
{value: 'ppm1n', id: 'PPM1N'},
{value: 'ppme1', id: 'PPME1'},
{value: 'ppox', id: 'PPOX'},
{value: 'ppp1ca', id: 'PPP1CA'},
{value: 'ppp1cb', id: 'PPP1CB'},
{value: 'ppp1cc', id: 'PPP1CC'},
{value: 'ppp1r10', id: 'PPP1R10'},
{value: 'ppp1r11', id: 'PPP1R11'},
{value: 'ppp1r12a', id: 'PPP1R12A'},
{value: 'ppp1r12b', id: 'PPP1R12B'},
{value: 'ppp1r12c', id: 'PPP1R12C'},
{value: 'ppp1r13b', id: 'PPP1R13B'},
{value: 'ppp1r13l', id: 'PPP1R13L'},
{value: 'ppp1r14a', id: 'PPP1R14A'},
{value: 'ppp1r14b', id: 'PPP1R14B'},
{value: 'ppp1r14c', id: 'PPP1R14C'},
{value: 'ppp1r14d', id: 'PPP1R14D'},
{value: 'ppp1r15a', id: 'PPP1R15A'},
{value: 'ppp1r15b', id: 'PPP1R15B'},
{value: 'ppp1r16a', id: 'PPP1R16A'},
{value: 'ppp1r16b', id: 'PPP1R16B'},
{value: 'ppp1r17', id: 'PPP1R17'},
{value: 'ppp1r18', id: 'PPP1R18'},
{value: 'ppp1r1a', id: 'PPP1R1A'},
{value: 'ppp1r1b', id: 'PPP1R1B'},
{value: 'ppp1r1c', id: 'PPP1R1C'},
{value: 'ppp1r2', id: 'PPP1R2'},
{value: 'ppp1r21', id: 'PPP1R21'},
{value: 'ppp1r26', id: 'PPP1R26'},
{value: 'ppp1r27', id: 'PPP1R27'},
{value: 'ppp1r32', id: 'PPP1R32'},
{value: 'ppp1r35', id: 'PPP1R35'},
{value: 'ppp1r36', id: 'PPP1R36'},
{value: 'ppp1r37', id: 'PPP1R37'},
{value: 'ppp1r3a', id: 'PPP1R3A'},
{value: 'ppp1r3b', id: 'PPP1R3B'},
{value: 'ppp1r3c', id: 'PPP1R3C'},
{value: 'ppp1r3d', id: 'PPP1R3D'},
{value: 'ppp1r3e', id: 'PPP1R3E'},
{value: 'ppp1r3f', id: 'PPP1R3F'},
{value: 'ppp1r3g', id: 'PPP1R3G'},
{value: 'ppp1r42', id: 'PPP1R42'},
{value: 'ppp1r7', id: 'PPP1R7'},
{value: 'ppp1r8', id: 'PPP1R8'},
{value: 'ppp1r9a', id: 'PPP1R9A'},
{value: 'ppp1r9b', id: 'PPP1R9B'},
{value: 'ppp2ca', id: 'PPP2CA'},
{value: 'ppp2cb', id: 'PPP2CB'},
{value: 'ppp2r1a', id: 'PPP2R1A'},
{value: 'ppp2r1b', id: 'PPP2R1B'},
{value: 'ppp2r2a', id: 'PPP2R2A'},
{value: 'ppp2r2b', id: 'PPP2R2B'},
{value: 'ppp2r2c', id: 'PPP2R2C'},
{value: 'ppp2r2d', id: 'PPP2R2D'},
{value: 'ppp2r3a', id: 'PPP2R3A'},
{value: 'ppp2r3b', id: 'PPP2R3B'},
{value: 'ppp2r3c', id: 'PPP2R3C'},
{value: 'ppp2r5a', id: 'PPP2R5A'},
{value: 'ppp2r5b', id: 'PPP2R5B'},
{value: 'ppp2r5c', id: 'PPP2R5C'},
{value: 'ppp2r5d', id: 'PPP2R5D'},
{value: 'ppp2r5e', id: 'PPP2R5E'},
{value: 'ppp3ca', id: 'PPP3CA'},
{value: 'ppp3cb', id: 'PPP3CB'},
{value: 'ppp3cc', id: 'PPP3CC'},
{value: 'ppp3r1', id: 'PPP3R1'},
{value: 'ppp3r2', id: 'PPP3R2'},
{value: 'ppp4c', id: 'PPP4C'},
{value: 'ppp4r1', id: 'PPP4R1'},
{value: 'ppp4r2', id: 'PPP4R2'},
{value: 'ppp4r3a', id: 'PPP4R3A'},
{value: 'ppp4r3b', id: 'PPP4R3B'},
{value: 'ppp4r4', id: 'PPP4R4'},
{value: 'ppp5c', id: 'PPP5C'},
{value: 'ppp6c', id: 'PPP6C'},
{value: 'ppp6r1', id: 'PPP6R1'},
{value: 'ppp6r2', id: 'PPP6R2'},
{value: 'ppp6r3', id: 'PPP6R3'},
{value: 'pprc1', id: 'PPRC1'},
{value: 'ppt1', id: 'PPT1'},
{value: 'ppt2', id: 'PPT2'},
{value: 'pptc7', id: 'PPTC7'},
{value: 'ppwd1', id: 'PPWD1'},
{value: 'ppy', id: 'PPY'},
{value: 'pqbp1', id: 'PQBP1'},
{value: 'pradc1', id: 'PRADC1'},
{value: 'praf2', id: 'PRAF2'},
{value: 'prag1', id: 'PRAG1'},
{value: 'pram1', id: 'PRAM1'},
{value: 'prame', id: 'PRAME'},
{value: 'pramef12', id: 'PRAMEF12'},
{value: 'pramef17', id: 'PRAMEF17'},
{value: 'pramef20', id: 'PRAMEF20'},
{value: 'pramef20l', id: 'PRAMEF20L'},
{value: 'pramef25', id: 'PRAMEF25'},
{value: 'pramef27', id: 'PRAMEF27'},
{value: 'pramef8', id: 'PRAMEF8'},
{value: 'pramel', id: 'PRAMEL'},
{value: 'pramel3', id: 'PRAMEL3'},
{value: 'pramel6', id: 'PRAMEL6'},
{value: 'pramel7', id: 'PRAMEL7'},
{value: 'prap1', id: 'PRAP1'},
{value: 'prb1', id: 'PRB1'},
{value: 'prb3', id: 'PRB3'},
{value: 'prc1', id: 'PRC1'},
{value: 'prcc', id: 'PRCC'},
{value: 'prcd', id: 'PRCD'},
{value: 'prcp', id: 'PRCP'},
{value: 'prdm1', id: 'PRDM1'},
{value: 'prdm10', id: 'PRDM10'},
{value: 'prdm11', id: 'PRDM11'},
{value: 'prdm12', id: 'PRDM12'},
{value: 'prdm13', id: 'PRDM13'},
{value: 'prdm14', id: 'PRDM14'},
{value: 'prdm15', id: 'PRDM15'},
{value: 'prdm16', id: 'PRDM16'},
{value: 'prdm2', id: 'PRDM2'},
{value: 'prdm4', id: 'PRDM4'},
{value: 'prdm5', id: 'PRDM5'},
{value: 'prdm6', id: 'PRDM6'},
{value: 'prdm8', id: 'PRDM8'},
{value: 'prdm9', id: 'PRDM9'},
{value: 'prdx1', id: 'PRDX1'},
{value: 'prdx2', id: 'PRDX2'},
{value: 'prdx3', id: 'PRDX3'},
{value: 'prdx4', id: 'PRDX4'},
{value: 'prdx5', id: 'PRDX5'},
{value: 'prdx6', id: 'PRDX6'},
{value: 'preb', id: 'PREB'},
{value: 'prelid1', id: 'PRELID1'},
{value: 'prelid2', id: 'PRELID2'},
{value: 'prelid3a', id: 'PRELID3A'},
{value: 'prelid3b', id: 'PRELID3B'},
{value: 'prelp', id: 'PRELP'},
{value: 'prep', id: 'PREP'},
{value: 'prepl', id: 'PREPL'},
{value: 'prex1', id: 'PREX1'},
{value: 'prex2', id: 'PREX2'},
{value: 'prf1', id: 'PRF1'},
{value: 'prg2', id: 'PRG2'},
{value: 'prg3', id: 'PRG3'},
{value: 'prg4', id: 'PRG4'},
{value: 'prickle1', id: 'PRICKLE1'},
{value: 'prickle2', id: 'PRICKLE2'},
{value: 'prickle3', id: 'PRICKLE3'},
{value: 'prickle4', id: 'PRICKLE4'},
{value: 'prim1', id: 'PRIM1'},
{value: 'prim2', id: 'PRIM2'},
{value: 'prima1', id: 'PRIMA1'},
{value: 'primpol', id: 'PRIMPOL'},
{value: 'prkaa1', id: 'PRKAA1'},
{value: 'prkaa2', id: 'PRKAA2'},
{value: 'prkab1', id: 'PRKAB1'},
{value: 'prkab2', id: 'PRKAB2'},
{value: 'prkaca', id: 'PRKACA'},
{value: 'prkacb', id: 'PRKACB'},
{value: 'prkag1', id: 'PRKAG1'},
{value: 'prkag2', id: 'PRKAG2'},
{value: 'prkag3', id: 'PRKAG3'},
{value: 'prkar1a', id: 'PRKAR1A'},
{value: 'prkar1b', id: 'PRKAR1B'},
{value: 'prkar2a', id: 'PRKAR2A'},
{value: 'prkar2b', id: 'PRKAR2B'},
{value: 'prkca', id: 'PRKCA'},
{value: 'prkcb', id: 'PRKCB'},
{value: 'prkcd', id: 'PRKCD'},
{value: 'prkce', id: 'PRKCE'},
{value: 'prkcg', id: 'PRKCG'},
{value: 'prkch', id: 'PRKCH'},
{value: 'prkci', id: 'PRKCI'},
{value: 'prkcq', id: 'PRKCQ'},
{value: 'prkcsh', id: 'PRKCSH'},
{value: 'prkcz', id: 'PRKCZ'},
{value: 'prkd1', id: 'PRKD1'},
{value: 'prkd2', id: 'PRKD2'},
{value: 'prkd3', id: 'PRKD3'},
{value: 'prkdc', id: 'PRKDC'},
{value: 'prkg1', id: 'PRKG1'},
{value: 'prkg2', id: 'PRKG2'},
{value: 'prkn', id: 'PRKN'},
{value: 'prkra', id: 'PRKRA'},
{value: 'prkrip1', id: 'PRKRIP1'},
{value: 'prkx', id: 'PRKX'},
{value: 'prl', id: 'PRL'},
{value: 'prl2a1', id: 'PRL2A1'},
{value: 'prl2b1', id: 'PRL2B1'},
{value: 'prl2c1', id: 'PRL2C1'},
{value: 'prl3a1', id: 'PRL3A1'},
{value: 'prl3b1', id: 'PRL3B1'},
{value: 'prl3c1', id: 'PRL3C1'},
{value: 'prl3d1', id: 'PRL3D1'},
{value: 'prl3d2', id: 'PRL3D2'},
{value: 'prl3d4', id: 'PRL3D4'},
{value: 'prl4a1', id: 'PRL4A1'},
{value: 'prl5a1', id: 'PRL5A1'},
{value: 'prl5a2', id: 'PRL5A2'},
{value: 'prl6a1', id: 'PRL6A1'},
{value: 'prl7a3', id: 'PRL7A3'},
{value: 'prl7a4', id: 'PRL7A4'},
{value: 'prl7b1', id: 'PRL7B1'},
{value: 'prl7d1', id: 'PRL7D1'},
{value: 'prl8a2', id: 'PRL8A2'},
{value: 'prl8a3', id: 'PRL8A3'},
{value: 'prl8a4', id: 'PRL8A4'},
{value: 'prl8a5', id: 'PRL8A5'},
{value: 'prl8a7', id: 'PRL8A7'},
{value: 'prl8a9', id: 'PRL8A9'},
{value: 'prlh', id: 'PRLH'},
{value: 'prlhr', id: 'PRLHR'},
{value: 'prlr', id: 'PRLR'},
{value: 'prm1', id: 'PRM1'},
{value: 'prm2', id: 'PRM2'},
{value: 'prm3', id: 'PRM3'},
{value: 'prmt1', id: 'PRMT1'},
{value: 'prmt2', id: 'PRMT2'},
{value: 'prmt3', id: 'PRMT3'},
{value: 'prmt5', id: 'PRMT5'},
{value: 'prmt6', id: 'PRMT6'},
{value: 'prmt7', id: 'PRMT7'},
{value: 'prmt8', id: 'PRMT8'},
{value: 'prmt9', id: 'PRMT9'},
{value: 'prnd', id: 'PRND'},
{value: 'prnp', id: 'PRNP'},
{value: 'prob1', id: 'PROB1'},
{value: 'proc', id: 'PROC'},
{value: 'proca1', id: 'PROCA1'},
{value: 'procr', id: 'PROCR'},
{value: 'prodh1', id: 'PRODH1'},
{value: 'prodh2', id: 'PRODH2'},
{value: 'prok1', id: 'PROK1'},
{value: 'prok2', id: 'PROK2'},
{value: 'prokr1', id: 'PROKR1'},
{value: 'prokr2', id: 'PROKR2'},
{value: 'prom1', id: 'PROM1'},
{value: 'prom2', id: 'PROM2'},
{value: 'prop1', id: 'PROP1'},
{value: 'prorp', id: 'PRORP'},
{value: 'prorsd1', id: 'PRORSD1'},
{value: 'pros1', id: 'PROS1'},
{value: 'proser1', id: 'PROSER1'},
{value: 'proser2', id: 'PROSER2'},
{value: 'proser3', id: 'PROSER3'},
{value: 'prox1', id: 'PROX1'},
{value: 'prox2', id: 'PROX2'},
{value: 'proz', id: 'PROZ'},
{value: 'prp15', id: 'PRP15'},
{value: 'prp2', id: 'PRP2'},
{value: 'prp2l1', id: 'PRP2L1'},
{value: 'prpf18', id: 'PRPF18'},
{value: 'prpf19', id: 'PRPF19'},
{value: 'prpf3', id: 'PRPF3'},
{value: 'prpf31', id: 'PRPF31'},
{value: 'prpf38a', id: 'PRPF38A'},
{value: 'prpf38b', id: 'PRPF38B'},
{value: 'prpf39', id: 'PRPF39'},
{value: 'prpf4', id: 'PRPF4'},
{value: 'prpf40a', id: 'PRPF40A'},
{value: 'prpf40b', id: 'PRPF40B'},
{value: 'prpf4b', id: 'PRPF4B'},
{value: 'prpf6', id: 'PRPF6'},
{value: 'prpf8', id: 'PRPF8'},
{value: 'prph', id: 'PRPH'},
{value: 'prpmp5', id: 'PRPMP5'},
{value: 'prps1', id: 'PRPS1'},
{value: 'prps1l1', id: 'PRPS1L1'},
{value: 'prps2', id: 'PRPS2'},
{value: 'prpsap1', id: 'PRPSAP1'},
{value: 'prpsap2', id: 'PRPSAP2'},
{value: 'prr11', id: 'PRR11'},
{value: 'prr12', id: 'PRR12'},
{value: 'prr13', id: 'PRR13'},
{value: 'prr14', id: 'PRR14'},
{value: 'prr14l', id: 'PRR14L'},
{value: 'prr15', id: 'PRR15'},
{value: 'prr15l', id: 'PRR15L'},
{value: 'prr16', id: 'PRR16'},
{value: 'prr18', id: 'PRR18'},
{value: 'prr19', id: 'PRR19'},
{value: 'prr20e', id: 'PRR20E'},
{value: 'prr22', id: 'PRR22'},
{value: 'prr23a', id: 'PRR23A'},
{value: 'prr23d1', id: 'PRR23D1'},
{value: 'prr27', id: 'PRR27'},
{value: 'prr29', id: 'PRR29'},
{value: 'prr3', id: 'PRR3'},
{value: 'prr30', id: 'PRR30'},
{value: 'prr32', id: 'PRR32'},
{value: 'prr33', id: 'PRR33'},
{value: 'prr35', id: 'PRR35'},
{value: 'prr36', id: 'PRR36'},
{value: 'prr4', id: 'PRR4'},
{value: 'prr5', id: 'PRR5'},
{value: 'prr5l', id: 'PRR5L'},
{value: 'prr7', id: 'PRR7'},
{value: 'prr9', id: 'PRR9'},
{value: 'prrc1', id: 'PRRC1'},
{value: 'prrc2a', id: 'PRRC2A'},
{value: 'prrc2b', id: 'PRRC2B'},
{value: 'prrg1', id: 'PRRG1'},
{value: 'prrg2', id: 'PRRG2'},
{value: 'prrg3', id: 'PRRG3'},
{value: 'prrg4', id: 'PRRG4'},
{value: 'prrt1', id: 'PRRT1'},
{value: 'prrt1b', id: 'PRRT1B'},
{value: 'prrt2', id: 'PRRT2'},
{value: 'prrt3', id: 'PRRT3'},
{value: 'prrt4', id: 'PRRT4'},
{value: 'prrx1', id: 'PRRX1'},
{value: 'prrx2', id: 'PRRX2'},
{value: 'prss1', id: 'PRSS1'},
{value: 'prss12', id: 'PRSS12'},
{value: 'prss16', id: 'PRSS16'},
{value: 'prss2', id: 'PRSS2'},
{value: 'prss21', id: 'PRSS21'},
{value: 'prss22', id: 'PRSS22'},
{value: 'prss23', id: 'PRSS23'},
{value: 'prss27', id: 'PRSS27'},
{value: 'prss29', id: 'PRSS29'},
{value: 'prss3', id: 'PRSS3'},
{value: 'prss30', id: 'PRSS30'},
{value: 'prss32', id: 'PRSS32'},
{value: 'prss33', id: 'PRSS33'},
{value: 'prss34', id: 'PRSS34'},
{value: 'prss35', id: 'PRSS35'},
{value: 'prss36', id: 'PRSS36'},
{value: 'prss37', id: 'PRSS37'},
{value: 'prss38', id: 'PRSS38'},
{value: 'prss39', id: 'PRSS39'},
{value: 'prss3b', id: 'PRSS3B'},
{value: 'prss40', id: 'PRSS40'},
{value: 'prss41', id: 'PRSS41'},
{value: 'prss42', id: 'PRSS42'},
{value: 'prss44', id: 'PRSS44'},
{value: 'prss45', id: 'PRSS45'},
{value: 'prss46', id: 'PRSS46'},
{value: 'prss47', id: 'PRSS47'},
{value: 'prss48', id: 'PRSS48'},
{value: 'prss50', id: 'PRSS50'},
{value: 'prss51', id: 'PRSS51'},
{value: 'prss53', id: 'PRSS53'},
{value: 'prss54', id: 'PRSS54'},
{value: 'prss55', id: 'PRSS55'},
{value: 'prss56', id: 'PRSS56'},
{value: 'prss57', id: 'PRSS57'},
{value: 'prss58', id: 'PRSS58'},
{value: 'prss59', id: 'PRSS59'},
{value: 'prss8', id: 'PRSS8'},
{value: 'prtfdc1', id: 'PRTFDC1'},
{value: 'prtg', id: 'PRTG'},
{value: 'prtn3', id: 'PRTN3'},
{value: 'prune1', id: 'PRUNE1'},
{value: 'prune2', id: 'PRUNE2'},
{value: 'prx', id: 'PRX'},
{value: 'prxl2a', id: 'PRXL2A'},
{value: 'prxl2b', id: 'PRXL2B'},
{value: 'prxl2c', id: 'PRXL2C'},
{value: 'psap', id: 'PSAP'},
{value: 'psapl1', id: 'PSAPL1'},
{value: 'psat1', id: 'PSAT1'},
{value: 'psca', id: 'PSCA'},
{value: 'psd', id: 'PSD'},
{value: 'psd2', id: 'PSD2'},
{value: 'psd3', id: 'PSD3'},
{value: 'psd4', id: 'PSD4'},
{value: 'psen1', id: 'PSEN1'},
{value: 'psen2', id: 'PSEN2'},
{value: 'psenen', id: 'PSENEN'},
{value: 'psg16', id: 'PSG16'},
{value: 'psg19', id: 'PSG19'},
{value: 'psg29', id: 'PSG29'},
{value: 'psgb1', id: 'PSGB1'},
{value: 'psip1', id: 'PSIP1'},
{value: 'pskh1', id: 'PSKH1'},
{value: 'psma1', id: 'PSMA1'},
{value: 'psma2', id: 'PSMA2'},
{value: 'psma3', id: 'PSMA3'},
{value: 'psma3l', id: 'PSMA3L'},
{value: 'psma4', id: 'PSMA4'},
{value: 'psma5', id: 'PSMA5'},
{value: 'psma6', id: 'PSMA6'},
{value: 'psma7', id: 'PSMA7'},
{value: 'psma8', id: 'PSMA8'},
{value: 'psmb1', id: 'PSMB1'},
{value: 'psmb10', id: 'PSMB10'},
{value: 'psmb11', id: 'PSMB11'},
{value: 'psmb2', id: 'PSMB2'},
{value: 'psmb3', id: 'PSMB3'},
{value: 'psmb4', id: 'PSMB4'},
{value: 'psmb5', id: 'PSMB5'},
{value: 'psmb6', id: 'PSMB6'},
{value: 'psmb7', id: 'PSMB7'},
{value: 'psmb8', id: 'PSMB8'},
{value: 'psmb9', id: 'PSMB9'},
{value: 'psmc1', id: 'PSMC1'},
{value: 'psmc2', id: 'PSMC2'},
{value: 'psmc3', id: 'PSMC3'},
{value: 'psmc3ip', id: 'PSMC3IP'},
{value: 'psmc4', id: 'PSMC4'},
{value: 'psmc5', id: 'PSMC5'},
{value: 'psmc6', id: 'PSMC6'},
{value: 'psmd1', id: 'PSMD1'},
{value: 'psmd10', id: 'PSMD10'},
{value: 'psmd11', id: 'PSMD11'},
{value: 'psmd12', id: 'PSMD12'},
{value: 'psmd13', id: 'PSMD13'},
{value: 'psmd14', id: 'PSMD14'},
{value: 'psmd2', id: 'PSMD2'},
{value: 'psmd3', id: 'PSMD3'},
{value: 'psmd4', id: 'PSMD4'},
{value: 'psmd5', id: 'PSMD5'},
{value: 'psmd6', id: 'PSMD6'},
{value: 'psmd7', id: 'PSMD7'},
{value: 'psmd8', id: 'PSMD8'},
{value: 'psmd9', id: 'PSMD9'},
{value: 'psme1-ps1', id: 'PSME1-PS1'},
{value: 'psme1', id: 'PSME1'},
{value: 'psme2', id: 'PSME2'},
{value: 'psme3', id: 'PSME3'},
{value: 'psme3ip1', id: 'PSME3IP1'},
{value: 'psme4', id: 'PSME4'},
{value: 'psmf1', id: 'PSMF1'},
{value: 'psmg1', id: 'PSMG1'},
{value: 'psmg2', id: 'PSMG2'},
{value: 'psmg3', id: 'PSMG3'},
{value: 'psmg4', id: 'PSMG4'},
{value: 'psors1c2', id: 'PSORS1C2'},
{value: 'pspc1', id: 'PSPC1'},
{value: 'psph', id: 'PSPH'},
{value: 'pspn', id: 'PSPN'},
{value: 'psrc1', id: 'PSRC1'},
{value: 'pstk', id: 'PSTK'},
{value: 'pstpip1', id: 'PSTPIP1'},
{value: 'pstpip2', id: 'PSTPIP2'},
{value: 'psx1', id: 'PSX1'},
{value: 'ptafr', id: 'PTAFR'},
{value: 'ptar1', id: 'PTAR1'},
{value: 'ptbp1', id: 'PTBP1'},
{value: 'ptbp2', id: 'PTBP2'},
{value: 'ptbp3', id: 'PTBP3'},
{value: 'ptcd1', id: 'PTCD1'},
{value: 'ptcd2', id: 'PTCD2'},
{value: 'ptcd3', id: 'PTCD3'},
{value: 'ptch1', id: 'PTCH1'},
{value: 'ptch2', id: 'PTCH2'},
{value: 'ptchd1', id: 'PTCHD1'},
{value: 'ptchd3', id: 'PTCHD3'},
{value: 'ptchd4', id: 'PTCHD4'},
{value: 'ptcra', id: 'PTCRA'},
{value: 'ptdss1', id: 'PTDSS1'},
{value: 'ptdss2', id: 'PTDSS2'},
{value: 'pten', id: 'PTEN'},
{value: 'pter', id: 'PTER'},
{value: 'ptf1a', id: 'PTF1A'},
{value: 'ptgdr2', id: 'PTGDR2'},
{value: 'ptgdrl', id: 'PTGDRL'},
{value: 'ptgds', id: 'PTGDS'},
{value: 'ptger1', id: 'PTGER1'},
{value: 'ptger2', id: 'PTGER2'},
{value: 'ptger3', id: 'PTGER3'},
{value: 'ptger4', id: 'PTGER4'},
{value: 'ptges', id: 'PTGES'},
{value: 'ptges2', id: 'PTGES2'},
{value: 'ptges3', id: 'PTGES3'},
{value: 'ptges3l', id: 'PTGES3L'},
{value: 'ptges3l1', id: 'PTGES3L1'},
{value: 'ptgfr', id: 'PTGFR'},
{value: 'ptgfrn', id: 'PTGFRN'},
{value: 'ptgir', id: 'PTGIR'},
{value: 'ptgis', id: 'PTGIS'},
{value: 'ptgr1', id: 'PTGR1'},
{value: 'ptgr2', id: 'PTGR2'},
{value: 'ptgs1', id: 'PTGS1'},
{value: 'ptgs2', id: 'PTGS2'},
{value: 'pth', id: 'PTH'},
{value: 'pth1r', id: 'PTH1R'},
{value: 'pth2', id: 'PTH2'},
{value: 'pth2r', id: 'PTH2R'},
{value: 'pthlh', id: 'PTHLH'},
{value: 'ptk2', id: 'PTK2'},
{value: 'ptk2b', id: 'PTK2B'},
{value: 'ptk6', id: 'PTK6'},
{value: 'ptk7', id: 'PTK7'},
{value: 'ptma', id: 'PTMA'},
{value: 'ptms', id: 'PTMS'},
{value: 'ptn', id: 'PTN'},
{value: 'ptov1', id: 'PTOV1'},
{value: 'ptp4a1', id: 'PTP4A1'},
{value: 'ptp4a2', id: 'PTP4A2'},
{value: 'ptp4a3', id: 'PTP4A3'},
{value: 'ptpa', id: 'PTPA'},
{value: 'ptpdc1', id: 'PTPDC1'},
{value: 'ptpmt1', id: 'PTPMT1'},
{value: 'ptpn1', id: 'PTPN1'},
{value: 'ptpn11', id: 'PTPN11'},
{value: 'ptpn12', id: 'PTPN12'},
{value: 'ptpn13', id: 'PTPN13'},
{value: 'ptpn14', id: 'PTPN14'},
{value: 'ptpn18', id: 'PTPN18'},
{value: 'ptpn2', id: 'PTPN2'},
{value: 'ptpn20', id: 'PTPN20'},
{value: 'ptpn21', id: 'PTPN21'},
{value: 'ptpn22', id: 'PTPN22'},
{value: 'ptpn23', id: 'PTPN23'},
{value: 'ptpn3', id: 'PTPN3'},
{value: 'ptpn4', id: 'PTPN4'},
{value: 'ptpn5', id: 'PTPN5'},
{value: 'ptpn6', id: 'PTPN6'},
{value: 'ptpn7', id: 'PTPN7'},
{value: 'ptpn9', id: 'PTPN9'},
{value: 'ptpra', id: 'PTPRA'},
{value: 'ptprb', id: 'PTPRB'},
{value: 'ptprc', id: 'PTPRC'},
{value: 'ptprcap', id: 'PTPRCAP'},
{value: 'ptprd', id: 'PTPRD'},
{value: 'ptpre', id: 'PTPRE'},
{value: 'ptprf', id: 'PTPRF'},
{value: 'ptprg', id: 'PTPRG'},
{value: 'ptprh', id: 'PTPRH'},
{value: 'ptprj', id: 'PTPRJ'},
{value: 'ptprk', id: 'PTPRK'},
{value: 'ptprm', id: 'PTPRM'},
{value: 'ptprn', id: 'PTPRN'},
{value: 'ptprn2', id: 'PTPRN2'},
{value: 'ptpro', id: 'PTPRO'},
{value: 'ptprq', id: 'PTPRQ'},
{value: 'ptprr', id: 'PTPRR'},
{value: 'ptprs', id: 'PTPRS'},
{value: 'ptprt', id: 'PTPRT'},
{value: 'ptpru', id: 'PTPRU'},
{value: 'ptprv', id: 'PTPRV'},
{value: 'ptprz1', id: 'PTPRZ1'},
{value: 'ptrh1', id: 'PTRH1'},
{value: 'ptrh2', id: 'PTRH2'},
{value: 'ptrhd1', id: 'PTRHD1'},
{value: 'pts', id: 'PTS'},
{value: 'pttg1', id: 'PTTG1'},
{value: 'pttg1ip', id: 'PTTG1IP'},
{value: 'pttg1ip2', id: 'PTTG1IP2'},
{value: 'ptx3', id: 'PTX3'},
{value: 'ptx4', id: 'PTX4'},
{value: 'pudp', id: 'PUDP'},
{value: 'puf60', id: 'PUF60'},
{value: 'pum1', id: 'PUM1'},
{value: 'pum2', id: 'PUM2'},
{value: 'pum3', id: 'PUM3'},
{value: 'pura', id: 'PURA'},
{value: 'purb', id: 'PURB'},
{value: 'purg', id: 'PURG'},
{value: 'pus1', id: 'PUS1'},
{value: 'pus10', id: 'PUS10'},
{value: 'pus3', id: 'PUS3'},
{value: 'pus7', id: 'PUS7'},
{value: 'pus7l', id: 'PUS7L'},
{value: 'pusl1', id: 'PUSL1'},
{value: 'pvalb', id: 'PVALB'},
{value: 'pvr', id: 'PVR'},
{value: 'pwp1', id: 'PWP1'},
{value: 'pwp2', id: 'PWP2'},
{value: 'pwwp2a', id: 'PWWP2A'},
{value: 'pwwp2b', id: 'PWWP2B'},
{value: 'pwwp3a', id: 'PWWP3A'},
{value: 'pwwp3b', id: 'PWWP3B'},
{value: 'pwwp4', id: 'PWWP4'},
{value: 'pxdc1', id: 'PXDC1'},
{value: 'pxdn', id: 'PXDN'},
{value: 'pxk', id: 'PXK'},
{value: 'pxmp2', id: 'PXMP2'},
{value: 'pxmp4', id: 'PXMP4'},
{value: 'pxn', id: 'PXN'},
{value: 'pxt1', id: 'PXT1'},
{value: 'pxylp1', id: 'PXYLP1'},
{value: 'pycard', id: 'PYCARD'},
{value: 'pycr1', id: 'PYCR1'},
{value: 'pycr2', id: 'PYCR2'},
{value: 'pycr3', id: 'PYCR3'},
{value: 'pygb', id: 'PYGB'},
{value: 'pygl', id: 'PYGL'},
{value: 'pygm', id: 'PYGM'},
{value: 'pygo1', id: 'PYGO1'},
{value: 'pygo2', id: 'PYGO2'},
{value: 'pym1', id: 'PYM1'},
{value: 'pyroxd1', id: 'PYROXD1'},
{value: 'pyroxd2', id: 'PYROXD2'},
{value: 'pyurf', id: 'PYURF'},
{value: 'pyy', id: 'PYY'},
{value: 'pzp', id: 'PZP'},
{value: 'qars1', id: 'QARS1'},
{value: 'qdpr', id: 'QDPR'},
{value: 'qk', id: 'QK'},
{value: 'qpct', id: 'QPCT'},
{value: 'qpctl', id: 'QPCTL'},
{value: 'qprt', id: 'QPRT'},
{value: 'qrfp', id: 'QRFP'},
{value: 'qrfpr', id: 'QRFPR'},
{value: 'qrich1', id: 'QRICH1'},
{value: 'qrich2', id: 'QRICH2'},
{value: 'qrsl1', id: 'QRSL1'},
{value: 'qser1', id: 'QSER1'},
{value: 'qsox1', id: 'QSOX1'},
{value: 'qsox2', id: 'QSOX2'},
{value: 'qtrt1', id: 'QTRT1'},
{value: 'qtrt2', id: 'QTRT2'},
{value: 'r3hcc1', id: 'R3HCC1'},
{value: 'r3hcc1l', id: 'R3HCC1L'},
{value: 'r3hdm1', id: 'R3HDM1'},
{value: 'r3hdm2', id: 'R3HDM2'},
{value: 'r3hdm4', id: 'R3HDM4'},
{value: 'r3hdml', id: 'R3HDML'},
{value: 'rab10', id: 'RAB10'},
{value: 'rab11a', id: 'RAB11A'},
{value: 'rab11b', id: 'RAB11B'},
{value: 'rab11fip1', id: 'RAB11FIP1'},
{value: 'rab11fip2', id: 'RAB11FIP2'},
{value: 'rab11fip3', id: 'RAB11FIP3'},
{value: 'rab11fip4', id: 'RAB11FIP4'},
{value: 'rab11fip5', id: 'RAB11FIP5'},
{value: 'rab12', id: 'RAB12'},
{value: 'rab13', id: 'RAB13'},
{value: 'rab14', id: 'RAB14'},
{value: 'rab15', id: 'RAB15'},
{value: 'rab17', id: 'RAB17'},
{value: 'rab18', id: 'RAB18'},
{value: 'rab19', id: 'RAB19'},
{value: 'rab1a', id: 'RAB1A'},
{value: 'rab1b-ps1', id: 'RAB1B-PS1'},
{value: 'rab1b', id: 'RAB1B'},
{value: 'rab20', id: 'RAB20'},
{value: 'rab21', id: 'RAB21'},
{value: 'rab22a', id: 'RAB22A'},
{value: 'rab23', id: 'RAB23'},
{value: 'rab24', id: 'RAB24'},
{value: 'rab25', id: 'RAB25'},
{value: 'rab26', id: 'RAB26'},
{value: 'rab27a', id: 'RAB27A'},
{value: 'rab27b', id: 'RAB27B'},
{value: 'rab28', id: 'RAB28'},
{value: 'rab29', id: 'RAB29'},
{value: 'rab2a', id: 'RAB2A'},
{value: 'rab2b', id: 'RAB2B'},
{value: 'rab30', id: 'RAB30'},
{value: 'rab31', id: 'RAB31'},
{value: 'rab32', id: 'RAB32'},
{value: 'rab33a', id: 'RAB33A'},
{value: 'rab33b', id: 'RAB33B'},
{value: 'rab34', id: 'RAB34'},
{value: 'rab35', id: 'RAB35'},
{value: 'rab36', id: 'RAB36'},
{value: 'rab37', id: 'RAB37'},
{value: 'rab38', id: 'RAB38'},
{value: 'rab39a', id: 'RAB39A'},
{value: 'rab3a', id: 'RAB3A'},
{value: 'rab3b', id: 'RAB3B'},
{value: 'rab3c', id: 'RAB3C'},
{value: 'rab3d', id: 'RAB3D'},
{value: 'rab3gap1', id: 'RAB3GAP1'},
{value: 'rab3gap2', id: 'RAB3GAP2'},
{value: 'rab3il1', id: 'RAB3IL1'},
{value: 'rab3ip', id: 'RAB3IP'},
{value: 'rab40b', id: 'RAB40B'},
{value: 'rab40c', id: 'RAB40C'},
{value: 'rab42', id: 'RAB42'},
{value: 'rab43', id: 'RAB43'},
{value: 'rab44', id: 'RAB44'},
{value: 'rab4a', id: 'RAB4A'},
{value: 'rab4b', id: 'RAB4B'},
{value: 'rab5a', id: 'RAB5A'},
{value: 'rab5al1', id: 'RAB5AL1'},
{value: 'rab5b', id: 'RAB5B'},
{value: 'rab5c', id: 'RAB5C'},
{value: 'rab5if', id: 'RAB5IF'},
{value: 'rab6a', id: 'RAB6A'},
{value: 'rab6b', id: 'RAB6B'},
{value: 'rab7a', id: 'RAB7A'},
{value: 'rab7b', id: 'RAB7B'},
{value: 'rab8a', id: 'RAB8A'},
{value: 'rab8b', id: 'RAB8B'},
{value: 'rab9a', id: 'RAB9A'},
{value: 'rab9b', id: 'RAB9B'},
{value: 'rabac1', id: 'RABAC1'},
{value: 'rabep1', id: 'RABEP1'},
{value: 'rabep2', id: 'RABEP2'},
{value: 'rabepk', id: 'RABEPK'},
{value: 'rabgap1', id: 'RABGAP1'},
{value: 'rabgap1l', id: 'RABGAP1L'},
{value: 'rabgef1', id: 'RABGEF1'},
{value: 'rabggta', id: 'RABGGTA'},
{value: 'rabggtb', id: 'RABGGTB'},
{value: 'rabif', id: 'RABIF'},
{value: 'rabl2', id: 'RABL2'},
{value: 'rabl3', id: 'RABL3'},
{value: 'rabl6', id: 'RABL6'},
{value: 'rac1', id: 'RAC1'},
{value: 'rac2', id: 'RAC2'},
{value: 'rac3', id: 'RAC3'},
{value: 'racgap1', id: 'RACGAP1'},
{value: 'rack1', id: 'RACK1'},
{value: 'rad1', id: 'RAD1'},
{value: 'rad17', id: 'RAD17'},
{value: 'rad18', id: 'RAD18'},
{value: 'rad21', id: 'RAD21'},
{value: 'rad21l1', id: 'RAD21L1'},
{value: 'rad23a', id: 'RAD23A'},
{value: 'rad23b', id: 'RAD23B'},
{value: 'rad50', id: 'RAD50'},
{value: 'rad51', id: 'RAD51'},
{value: 'rad51ap1', id: 'RAD51AP1'},
{value: 'rad51ap2', id: 'RAD51AP2'},
{value: 'rad51b', id: 'RAD51B'},
{value: 'rad51c', id: 'RAD51C'},
{value: 'rad51d', id: 'RAD51D'},
{value: 'rad52', id: 'RAD52'},
{value: 'rad54b', id: 'RAD54B'},
{value: 'rad54l', id: 'RAD54L'},
{value: 'rad54l2', id: 'RAD54L2'},
{value: 'rad9a', id: 'RAD9A'},
{value: 'rad9b', id: 'RAD9B'},
{value: 'radil', id: 'RADIL'},
{value: 'radx', id: 'RADX'},
{value: 'rae1', id: 'RAE1'},
{value: 'raet1e', id: 'RAET1E'},
{value: 'raf1', id: 'RAF1'},
{value: 'rag1', id: 'RAG1'},
{value: 'rag2', id: 'RAG2'},
{value: 'rai1', id: 'RAI1'},
{value: 'rai14', id: 'RAI14'},
{value: 'rai2', id: 'RAI2'},
{value: 'rala', id: 'RALA'},
{value: 'ralb', id: 'RALB'},
{value: 'ralbp1', id: 'RALBP1'},
{value: 'ralgapa1', id: 'RALGAPA1'},
{value: 'ralgapa2', id: 'RALGAPA2'},
{value: 'ralgapb', id: 'RALGAPB'},
{value: 'ralgds', id: 'RALGDS'},
{value: 'ralgps1', id: 'RALGPS1'},
{value: 'ralgps2', id: 'RALGPS2'},
{value: 'raly', id: 'RALY'},
{value: 'ralyl', id: 'RALYL'},
{value: 'ramac', id: 'RAMAC'},
{value: 'ramp1', id: 'RAMP1'},
{value: 'ramp2', id: 'RAMP2'},
{value: 'ramp3', id: 'RAMP3'},
{value: 'ran', id: 'RAN'},
{value: 'ranbp1', id: 'RANBP1'},
{value: 'ranbp10', id: 'RANBP10'},
{value: 'ranbp17', id: 'RANBP17'},
{value: 'ranbp2', id: 'RANBP2'},
{value: 'ranbp3', id: 'RANBP3'},
{value: 'ranbp3l', id: 'RANBP3L'},
{value: 'ranbp6', id: 'RANBP6'},
{value: 'ranbp9', id: 'RANBP9'},
{value: 'rangap1', id: 'RANGAP1'},
{value: 'rangrf', id: 'RANGRF'},
{value: 'rap1a', id: 'RAP1A'},
{value: 'rap1b', id: 'RAP1B'},
{value: 'rap1gap', id: 'RAP1GAP'},
{value: 'rap1gap2', id: 'RAP1GAP2'},
{value: 'rap1gds1', id: 'RAP1GDS1'},
{value: 'rap2a', id: 'RAP2A'},
{value: 'rap2b', id: 'RAP2B'},
{value: 'rap2c', id: 'RAP2C'},
{value: 'rapgef1', id: 'RAPGEF1'},
{value: 'rapgef2', id: 'RAPGEF2'},
{value: 'rapgef3', id: 'RAPGEF3'},
{value: 'rapgef4', id: 'RAPGEF4'},
{value: 'rapgef5', id: 'RAPGEF5'},
{value: 'rapgef6', id: 'RAPGEF6'},
{value: 'rapgefl1', id: 'RAPGEFL1'},
{value: 'raph1', id: 'RAPH1'},
{value: 'rapsn', id: 'RAPSN'},
{value: 'rara', id: 'RARA'},
{value: 'rarb', id: 'RARB'},
{value: 'rarg', id: 'RARG'},
{value: 'rarres1', id: 'RARRES1'},
{value: 'rarres2', id: 'RARRES2'},
{value: 'rars1', id: 'RARS1'},
{value: 'rars2', id: 'RARS2'},
{value: 'rasa1', id: 'RASA1'},
{value: 'rasa2', id: 'RASA2'},
{value: 'rasa3', id: 'RASA3'},
{value: 'rasa4', id: 'RASA4'},
{value: 'rasal1', id: 'RASAL1'},
{value: 'rasal2', id: 'RASAL2'},
{value: 'rasal3', id: 'RASAL3'},
{value: 'rasd1', id: 'RASD1'},
{value: 'rasd2', id: 'RASD2'},
{value: 'rasef', id: 'RASEF'},
{value: 'rasgef1a', id: 'RASGEF1A'},
{value: 'rasgef1b', id: 'RASGEF1B'},
{value: 'rasgef1c', id: 'RASGEF1C'},
{value: 'rasgrf1', id: 'RASGRF1'},
{value: 'rasgrf2', id: 'RASGRF2'},
{value: 'rasgrp1', id: 'RASGRP1'},
{value: 'rasgrp2', id: 'RASGRP2'},
{value: 'rasgrp3', id: 'RASGRP3'},
{value: 'rasgrp4', id: 'RASGRP4'},
{value: 'rasip1', id: 'RASIP1'},
{value: 'rasl10a', id: 'RASL10A'},
{value: 'rasl10b', id: 'RASL10B'},
{value: 'rasl11a', id: 'RASL11A'},
{value: 'rasl11b', id: 'RASL11B'},
{value: 'rasl12', id: 'RASL12'},
{value: 'rasl2-9', id: 'RASL2-9'},
{value: 'rassf1', id: 'RASSF1'},
{value: 'rassf10', id: 'RASSF10'},
{value: 'rassf2', id: 'RASSF2'},
{value: 'rassf3', id: 'RASSF3'},
{value: 'rassf4', id: 'RASSF4'},
{value: 'rassf5', id: 'RASSF5'},
{value: 'rassf6', id: 'RASSF6'},
{value: 'rassf7', id: 'RASSF7'},
{value: 'rassf8', id: 'RASSF8'},
{value: 'rassf9', id: 'RASSF9'},
{value: 'ratnp-3b', id: 'RATNP-3B'},
{value: 'raver1', id: 'RAVER1'},
{value: 'raver2', id: 'RAVER2'},
{value: 'rax', id: 'RAX'},
{value: 'rb1', id: 'RB1'},
{value: 'rb1cc1', id: 'RB1CC1'},
{value: 'rbak', id: 'RBAK'},
{value: 'rbbp4', id: 'RBBP4'},
{value: 'rbbp5', id: 'RBBP5'},
{value: 'rbbp6', id: 'RBBP6'},
{value: 'rbbp7', id: 'RBBP7'},
{value: 'rbbp8', id: 'RBBP8'},
{value: 'rbbp8nl', id: 'RBBP8NL'},
{value: 'rbbp9', id: 'RBBP9'},
{value: 'rbck1', id: 'RBCK1'},
{value: 'rbfa', id: 'RBFA'},
{value: 'rbfox1', id: 'RBFOX1'},
{value: 'rbfox2', id: 'RBFOX2'},
{value: 'rbfox3', id: 'RBFOX3'},
{value: 'rbis', id: 'RBIS'},
{value: 'rbks', id: 'RBKS'},
{value: 'rbl1', id: 'RBL1'},
{value: 'rbl2', id: 'RBL2'},
{value: 'rbm10', id: 'RBM10'},
{value: 'rbm11', id: 'RBM11'},
{value: 'rbm12', id: 'RBM12'},
{value: 'rbm12b', id: 'RBM12B'},
{value: 'rbm14', id: 'RBM14'},
{value: 'rbm15', id: 'RBM15'},
{value: 'rbm15b', id: 'RBM15B'},
{value: 'rbm17', id: 'RBM17'},
{value: 'rbm18', id: 'RBM18'},
{value: 'rbm19', id: 'RBM19'},
{value: 'rbm20', id: 'RBM20'},
{value: 'rbm22', id: 'RBM22'},
{value: 'rbm24', id: 'RBM24'},
{value: 'rbm25l1', id: 'RBM25L1'},
{value: 'rbm26', id: 'RBM26'},
{value: 'rbm27', id: 'RBM27'},
{value: 'rbm28', id: 'RBM28'},
{value: 'rbm3', id: 'RBM3'},
{value: 'rbm31y', id: 'RBM31Y'},
{value: 'rbm33', id: 'RBM33'},
{value: 'rbm34', id: 'RBM34'},
{value: 'rbm38', id: 'RBM38'},
{value: 'rbm39', id: 'RBM39'},
{value: 'rbm4', id: 'RBM4'},
{value: 'rbm41', id: 'RBM41'},
{value: 'rbm42', id: 'RBM42'},
{value: 'rbm43', id: 'RBM43'},
{value: 'rbm44', id: 'RBM44'},
{value: 'rbm45', id: 'RBM45'},
{value: 'rbm46', id: 'RBM46'},
{value: 'rbm47', id: 'RBM47'},
{value: 'rbm48', id: 'RBM48'},
{value: 'rbm4b', id: 'RBM4B'},
{value: 'rbm5', id: 'RBM5'},
{value: 'rbm6', id: 'RBM6'},
{value: 'rbm7', id: 'RBM7'},
{value: 'rbm8a', id: 'RBM8A'},
{value: 'rbms1', id: 'RBMS1'},
{value: 'rbms2', id: 'RBMS2'},
{value: 'rbms3', id: 'RBMS3'},
{value: 'rbmx', id: 'RBMX'},
{value: 'rbmx2', id: 'RBMX2'},
{value: 'rbmxl1', id: 'RBMXL1'},
{value: 'rbmxl1b', id: 'RBMXL1B'},
{value: 'rbmxl2', id: 'RBMXL2'},
{value: 'rbmy1j', id: 'RBMY1J'},
{value: 'rbp1', id: 'RBP1'},
{value: 'rbp2', id: 'RBP2'},
{value: 'rbp3', id: 'RBP3'},
{value: 'rbp4', id: 'RBP4'},
{value: 'rbp7', id: 'RBP7'},
{value: 'rbpj', id: 'RBPJ'},
{value: 'rbpjl', id: 'RBPJL'},
{value: 'rbpjl2', id: 'RBPJL2'},
{value: 'rbpms', id: 'RBPMS'},
{value: 'rbpms2', id: 'RBPMS2'},
{value: 'rbsn', id: 'RBSN'},
{value: 'rbx1', id: 'RBX1'},
{value: 'rc3h1', id: 'RC3H1'},
{value: 'rc3h2', id: 'RC3H2'},
{value: 'rcan1', id: 'RCAN1'},
{value: 'rcan2', id: 'RCAN2'},
{value: 'rcan3', id: 'RCAN3'},
{value: 'rcbtb1', id: 'RCBTB1'},
{value: 'rcbtb2', id: 'RCBTB2'},
{value: 'rcc1', id: 'RCC1'},
{value: 'rcc1l', id: 'RCC1L'},
{value: 'rcc2', id: 'RCC2'},
{value: 'rccd1', id: 'RCCD1'},
{value: 'rce1', id: 'RCE1'},
{value: 'rchy1', id: 'RCHY1'},
{value: 'rcl1', id: 'RCL1'},
{value: 'rcn1', id: 'RCN1'},
{value: 'rcn2', id: 'RCN2'},
{value: 'rcn3', id: 'RCN3'},
{value: 'rcor1', id: 'RCOR1'},
{value: 'rcor2', id: 'RCOR2'},
{value: 'rcor2l1', id: 'RCOR2L1'},
{value: 'rcor3', id: 'RCOR3'},
{value: 'rcsd1', id: 'RCSD1'},
{value: 'rcvrn', id: 'RCVRN'},
{value: 'rd3', id: 'RD3'},
{value: 'rd3l', id: 'RD3L'},
{value: 'rdh10', id: 'RDH10'},
{value: 'rdh11', id: 'RDH11'},
{value: 'rdh12', id: 'RDH12'},
{value: 'rdh13', id: 'RDH13'},
{value: 'rdh14', id: 'RDH14'},
{value: 'rdh16', id: 'RDH16'},
{value: 'rdh5', id: 'RDH5'},
{value: 'rdh7', id: 'RDH7'},
{value: 'rdh8', id: 'RDH8'},
{value: 'rdm1', id: 'RDM1'},
{value: 'rdx', id: 'RDX'},
{value: 'rec114', id: 'REC114'},
{value: 'rec8', id: 'REC8'},
{value: 'reck', id: 'RECK'},
{value: 'recql', id: 'RECQL'},
{value: 'recql4', id: 'RECQL4'},
{value: 'recql5', id: 'RECQL5'},
{value: 'reep1', id: 'REEP1'},
{value: 'reep2', id: 'REEP2'},
{value: 'reep3', id: 'REEP3'},
{value: 'reep4', id: 'REEP4'},
{value: 'reep5', id: 'REEP5'},
{value: 'reep6', id: 'REEP6'},
{value: 'reg1a', id: 'REG1A'},
{value: 'reg3a', id: 'REG3A'},
{value: 'reg3b', id: 'REG3B'},
{value: 'reg3g', id: 'REG3G'},
{value: 'reg4', id: 'REG4'},
{value: 'rel', id: 'REL'},
{value: 'rela', id: 'RELA'},
{value: 'relb', id: 'RELB'},
{value: 'relch', id: 'RELCH'},
{value: 'rell1', id: 'RELL1'},
{value: 'rell2', id: 'RELL2'},
{value: 'reln', id: 'RELN'},
{value: 'relt', id: 'RELT'},
{value: 'rem1', id: 'REM1'},
{value: 'rem2', id: 'REM2'},
{value: 'ren', id: 'REN'},
{value: 'renbp', id: 'RENBP'},
{value: 'rep15', id: 'REP15'},
{value: 'repin1', id: 'REPIN1'},
{value: 'reps1', id: 'REPS1'},
{value: 'reps2', id: 'REPS2'},
{value: 'rer1', id: 'RER1'},
{value: 'rere', id: 'RERE'},
{value: 'rerg', id: 'RERG'},
{value: 'rergl', id: 'RERGL'},
{value: 'resf1', id: 'RESF1'},
{value: 'resp18', id: 'RESP18'},
{value: 'rest', id: 'REST'},
{value: 'ret', id: 'RET'},
{value: 'retn', id: 'RETN'},
{value: 'retnla', id: 'RETNLA'},
{value: 'retnlb', id: 'RETNLB'},
{value: 'retnlg', id: 'RETNLG'},
{value: 'retreg1', id: 'RETREG1'},
{value: 'retreg2', id: 'RETREG2'},
{value: 'retreg3', id: 'RETREG3'},
{value: 'retsat', id: 'RETSAT'},
{value: 'rev1', id: 'REV1'},
{value: 'rev3l', id: 'REV3L'},
{value: 'rex1bd', id: 'REX1BD'},
{value: 'rex2', id: 'REX2'},
{value: 'rexo1', id: 'REXO1'},
{value: 'rexo1l1-ps1', id: 'REXO1L1-PS1'},
{value: 'rexo2', id: 'REXO2'},
{value: 'rexo4', id: 'REXO4'},
{value: 'rexo5', id: 'REXO5'},
{value: 'rfc1', id: 'RFC1'},
{value: 'rfc2', id: 'RFC2'},
{value: 'rfc3', id: 'RFC3'},
{value: 'rfc4', id: 'RFC4'},
{value: 'rfc5', id: 'RFC5'},
{value: 'rfesd', id: 'RFESD'},
{value: 'rffl', id: 'RFFL'},
{value: 'rfk', id: 'RFK'},
{value: 'rflna', id: 'RFLNA'},
{value: 'rflnb', id: 'RFLNB'},
{value: 'rfng', id: 'RFNG'},
{value: 'rfpl4a', id: 'RFPL4A'},
{value: 'rft1', id: 'RFT1'},
{value: 'rftn1', id: 'RFTN1'},
{value: 'rftn2', id: 'RFTN2'},
{value: 'rfwd3', id: 'RFWD3'},
{value: 'rfx1', id: 'RFX1'},
{value: 'rfx2', id: 'RFX2'},
{value: 'rfx3', id: 'RFX3'},
{value: 'rfx4', id: 'RFX4'},
{value: 'rfx5', id: 'RFX5'},
{value: 'rfx6', id: 'RFX6'},
{value: 'rfx7', id: 'RFX7'},
{value: 'rfx8', id: 'RFX8'},
{value: 'rfxank', id: 'RFXANK'},
{value: 'rfxap', id: 'RFXAP'},
{value: 'rgcc', id: 'RGCC'},
{value: 'rgd1302996', id: 'RGD1302996'},
{value: 'rgd1304554', id: 'RGD1304554'},
{value: 'rgd1304567', id: 'RGD1304567'},
{value: 'rgd1304587', id: 'RGD1304587'},
{value: 'rgd1304622', id: 'RGD1304622'},
{value: 'rgd1304624', id: 'RGD1304624'},
{value: 'rgd1304694', id: 'RGD1304694'},
{value: 'rgd1304728', id: 'RGD1304728'},
{value: 'rgd1304745', id: 'RGD1304745'},
{value: 'rgd1304770', id: 'RGD1304770'},
{value: 'rgd1304884', id: 'RGD1304884'},
{value: 'rgd1305014', id: 'RGD1305014'},
{value: 'rgd1305178', id: 'RGD1305178'},
{value: 'rgd1305184', id: 'RGD1305184'},
{value: 'rgd1305207', id: 'RGD1305207'},
{value: 'rgd1305298', id: 'RGD1305298'},
{value: 'rgd1305347', id: 'RGD1305347'},
{value: 'rgd1305350', id: 'RGD1305350'},
{value: 'rgd1305464', id: 'RGD1305464'},
{value: 'rgd1305704', id: 'RGD1305704'},
{value: 'rgd1305713', id: 'RGD1305713'},
{value: 'rgd1305807', id: 'RGD1305807'},
{value: 'rgd1306063', id: 'RGD1306063'},
{value: 'rgd1306186', id: 'RGD1306186'},
{value: 'rgd1306195', id: 'RGD1306195'},
{value: 'rgd1306227', id: 'RGD1306227'},
{value: 'rgd1306233', id: 'RGD1306233'},
{value: 'rgd1306271', id: 'RGD1306271'},
{value: 'rgd1306282', id: 'RGD1306282'},
{value: 'rgd1306441', id: 'RGD1306441'},
{value: 'rgd1306474', id: 'RGD1306474'},
{value: 'rgd1306502', id: 'RGD1306502'},
{value: 'rgd1306519', id: 'RGD1306519'},
{value: 'rgd1306556', id: 'RGD1306556'},
{value: 'rgd1306746', id: 'RGD1306746'},
{value: 'rgd1306750', id: 'RGD1306750'},
{value: 'rgd1306782', id: 'RGD1306782'},
{value: 'rgd1306941', id: 'RGD1306941'},
{value: 'rgd1307100', id: 'RGD1307100'},
{value: 'rgd1307182', id: 'RGD1307182'},
{value: 'rgd1307443', id: 'RGD1307443'},
{value: 'rgd1307461', id: 'RGD1307461'},
{value: 'rgd1307537', id: 'RGD1307537'},
{value: 'rgd1307554', id: 'RGD1307554'},
{value: 'rgd1307595', id: 'RGD1307595'},
{value: 'rgd1307603', id: 'RGD1307603'},
{value: 'rgd1307621', id: 'RGD1307621'},
{value: 'rgd1307916', id: 'RGD1307916'},
{value: 'rgd1307929', id: 'RGD1307929'},
{value: 'rgd1307947', id: 'RGD1307947'},
{value: 'rgd1308065', id: 'RGD1308065'},
{value: 'rgd1308117', id: 'RGD1308117'},
{value: 'rgd1308134', id: 'RGD1308134'},
{value: 'rgd1308147', id: 'RGD1308147'},
{value: 'rgd1308430', id: 'RGD1308430'},
{value: 'rgd1308544', id: 'RGD1308544'},
{value: 'rgd1308564', id: 'RGD1308564'},
{value: 'rgd1308601', id: 'RGD1308601'},
{value: 'rgd1308706', id: 'RGD1308706'},
{value: 'rgd1308742', id: 'RGD1308742'},
{value: 'rgd1308750', id: 'RGD1308750'},
{value: 'rgd1308751', id: 'RGD1308751'},
{value: 'rgd1308775', id: 'RGD1308775'},
{value: 'rgd1308878', id: 'RGD1308878'},
{value: 'rgd1309036', id: 'RGD1309036'},
{value: 'rgd1309049', id: 'RGD1309049'},
{value: 'rgd1309104', id: 'RGD1309104'},
{value: 'rgd1309106', id: 'RGD1309106'},
{value: 'rgd1309110', id: 'RGD1309110'},
{value: 'rgd1309170', id: 'RGD1309170'},
{value: 'rgd1309291', id: 'RGD1309291'},
{value: 'rgd1309350', id: 'RGD1309350'},
{value: 'rgd1309362', id: 'RGD1309362'},
{value: 'rgd1309489', id: 'RGD1309489'},
{value: 'rgd1309534', id: 'RGD1309534'},
{value: 'rgd1309540', id: 'RGD1309540'},
{value: 'rgd1309651', id: 'RGD1309651'},
{value: 'rgd1309748', id: 'RGD1309748'},
{value: 'rgd1309779', id: 'RGD1309779'},
{value: 'rgd1309808', id: 'RGD1309808'},
{value: 'rgd1310166', id: 'RGD1310166'},
{value: 'rgd1310212', id: 'RGD1310212'},
{value: 'rgd1310352', id: 'RGD1310352'},
{value: 'rgd1310429', id: 'RGD1310429'},
{value: 'rgd1310495', id: 'RGD1310495'},
{value: 'rgd1310553', id: 'RGD1310553'},
{value: 'rgd1310587', id: 'RGD1310587'},
{value: 'rgd1310717', id: 'RGD1310717'},
{value: 'rgd1310852', id: 'RGD1310852'},
{value: 'rgd1310935', id: 'RGD1310935'},
{value: 'rgd1310951', id: 'RGD1310951'},
{value: 'rgd1311084', id: 'RGD1311084'},
{value: 'rgd1311164', id: 'RGD1311164'},
{value: 'rgd1311251', id: 'RGD1311251'},
{value: 'rgd1311343', id: 'RGD1311343'},
{value: 'rgd1311345', id: 'RGD1311345'},
{value: 'rgd1311517', id: 'RGD1311517'},
{value: 'rgd1311595', id: 'RGD1311595'},
{value: 'rgd1311703', id: 'RGD1311703'},
{value: 'rgd1311739', id: 'RGD1311739'},
{value: 'rgd1311744', id: 'RGD1311744'},
{value: 'rgd1311745', id: 'RGD1311745'},
{value: 'rgd1311847', id: 'RGD1311847'},
{value: 'rgd1311899', id: 'RGD1311899'},
{value: 'rgd1311933', id: 'RGD1311933'},
{value: 'rgd1311946', id: 'RGD1311946'},
{value: 'rgd1359108', id: 'RGD1359108'},
{value: 'rgd1359127', id: 'RGD1359127'},
{value: 'rgd1359158', id: 'RGD1359158'},
{value: 'rgd1359290', id: 'RGD1359290'},
{value: 'rgd1359334', id: 'RGD1359334'},
{value: 'rgd1359449', id: 'RGD1359449'},
{value: 'rgd1359508', id: 'RGD1359508'},
{value: 'rgd1359634', id: 'RGD1359634'},
{value: 'rgd1559441', id: 'RGD1559441'},
{value: 'rgd1559459', id: 'RGD1559459'},
{value: 'rgd1559482', id: 'RGD1559482'},
{value: 'rgd1559508', id: 'RGD1559508'},
{value: 'rgd1559534', id: 'RGD1559534'},
{value: 'rgd1559536', id: 'RGD1559536'},
{value: 'rgd1559575', id: 'RGD1559575'},
{value: 'rgd1559578', id: 'RGD1559578'},
{value: 'rgd1559588', id: 'RGD1559588'},
{value: 'rgd1559600', id: 'RGD1559600'},
{value: 'rgd1559622', id: 'RGD1559622'},
{value: 'rgd1559644', id: 'RGD1559644'},
{value: 'rgd1559662', id: 'RGD1559662'},
{value: 'rgd1559708', id: 'RGD1559708'},
{value: 'rgd1559714', id: 'RGD1559714'},
{value: 'rgd1559726', id: 'RGD1559726'},
{value: 'rgd1559804', id: 'RGD1559804'},
{value: 'rgd1559810', id: 'RGD1559810'},
{value: 'rgd1559821', id: 'RGD1559821'},
{value: 'rgd1559877', id: 'RGD1559877'},
{value: 'rgd1559891', id: 'RGD1559891'},
{value: 'rgd1559896', id: 'RGD1559896'},
{value: 'rgd1559903', id: 'RGD1559903'},
{value: 'rgd1559908', id: 'RGD1559908'},
{value: 'rgd1559909', id: 'RGD1559909'},
{value: 'rgd1559938', id: 'RGD1559938'},
{value: 'rgd1559951', id: 'RGD1559951'},
{value: 'rgd1559955', id: 'RGD1559955'},
{value: 'rgd1559960', id: 'RGD1559960'},
{value: 'rgd1559962', id: 'RGD1559962'},
{value: 'rgd1559970', id: 'RGD1559970'},
{value: 'rgd1559972', id: 'RGD1559972'},
{value: 'rgd1559995', id: 'RGD1559995'},
{value: 'rgd1560010', id: 'RGD1560010'},
{value: 'rgd1560015', id: 'RGD1560015'},
{value: 'rgd1560017', id: 'RGD1560017'},
{value: 'rgd1560028', id: 'RGD1560028'},
{value: 'rgd1560034', id: 'RGD1560034'},
{value: 'rgd1560065', id: 'RGD1560065'},
{value: 'rgd1560069', id: 'RGD1560069'},
{value: 'rgd1560110', id: 'RGD1560110'},
{value: 'rgd1560112', id: 'RGD1560112'},
{value: 'rgd1560146', id: 'RGD1560146'},
{value: 'rgd1560171', id: 'RGD1560171'},
{value: 'rgd1560186', id: 'RGD1560186'},
{value: 'rgd1560203', id: 'RGD1560203'},
{value: 'rgd1560212', id: 'RGD1560212'},
{value: 'rgd1560263', id: 'RGD1560263'},
{value: 'rgd1560281', id: 'RGD1560281'},
{value: 'rgd1560289', id: 'RGD1560289'},
{value: 'rgd1560291', id: 'RGD1560291'},
{value: 'rgd1560303', id: 'RGD1560303'},
{value: 'rgd1560314', id: 'RGD1560314'},
{value: 'rgd1560324', id: 'RGD1560324'},
{value: 'rgd1560341', id: 'RGD1560341'},
{value: 'rgd1560394', id: 'RGD1560394'},
{value: 'rgd1560398', id: 'RGD1560398'},
{value: 'rgd1560402', id: 'RGD1560402'},
{value: 'rgd1560436', id: 'RGD1560436'},
{value: 'rgd1560455', id: 'RGD1560455'},
{value: 'rgd1560464', id: 'RGD1560464'},
{value: 'rgd1560510', id: 'RGD1560510'},
{value: 'rgd1560523', id: 'RGD1560523'},
{value: 'rgd1560539', id: 'RGD1560539'},
{value: 'rgd1560554', id: 'RGD1560554'},
{value: 'rgd1560559', id: 'RGD1560559'},
{value: 'rgd1560585', id: 'RGD1560585'},
{value: 'rgd1560617', id: 'RGD1560617'},
{value: 'rgd1560633', id: 'RGD1560633'},
{value: 'rgd1560687', id: 'RGD1560687'},
{value: 'rgd1560718', id: 'RGD1560718'},
{value: 'rgd1560723', id: 'RGD1560723'},
{value: 'rgd1560730', id: 'RGD1560730'},
{value: 'rgd1560775', id: 'RGD1560775'},
{value: 'rgd1560795', id: 'RGD1560795'},
{value: 'rgd1560801', id: 'RGD1560801'},
{value: 'rgd1560813', id: 'RGD1560813'},
{value: 'rgd1560821', id: 'RGD1560821'},
{value: 'rgd1560831', id: 'RGD1560831'},
{value: 'rgd1560854', id: 'RGD1560854'},
{value: 'rgd1560860', id: 'RGD1560860'},
{value: 'rgd1560883', id: 'RGD1560883'},
{value: 'rgd1560917', id: 'RGD1560917'},
{value: 'rgd1560925', id: 'RGD1560925'},
{value: 'rgd1560927', id: 'RGD1560927'},
{value: 'rgd1560958', id: 'RGD1560958'},
{value: 'rgd1560986', id: 'RGD1560986'},
{value: 'rgd1561034', id: 'RGD1561034'},
{value: 'rgd1561102', id: 'RGD1561102'},
{value: 'rgd1561114', id: 'RGD1561114'},
{value: 'rgd1561137', id: 'RGD1561137'},
{value: 'rgd1561143', id: 'RGD1561143'},
{value: 'rgd1561149', id: 'RGD1561149'},
{value: 'rgd1561150', id: 'RGD1561150'},
{value: 'rgd1561157', id: 'RGD1561157'},
{value: 'rgd1561195', id: 'RGD1561195'},
{value: 'rgd1561215', id: 'RGD1561215'},
{value: 'rgd1561226', id: 'RGD1561226'},
{value: 'rgd1561230', id: 'RGD1561230'},
{value: 'rgd1561277', id: 'RGD1561277'},
{value: 'rgd1561306', id: 'RGD1561306'},
{value: 'rgd1561310', id: 'RGD1561310'},
{value: 'rgd1561318', id: 'RGD1561318'},
{value: 'rgd1561327', id: 'RGD1561327'},
{value: 'rgd1561333', id: 'RGD1561333'},
{value: 'rgd1561381', id: 'RGD1561381'},
{value: 'rgd1561382', id: 'RGD1561382'},
{value: 'rgd1561413', id: 'RGD1561413'},
{value: 'rgd1561415', id: 'RGD1561415'},
{value: 'rgd1561442', id: 'RGD1561442'},
{value: 'rgd1561444', id: 'RGD1561444'},
{value: 'rgd1561445', id: 'RGD1561445'},
{value: 'rgd1561465', id: 'RGD1561465'},
{value: 'rgd1561481', id: 'RGD1561481'},
{value: 'rgd1561517', id: 'RGD1561517'},
{value: 'rgd1561551', id: 'RGD1561551'},
{value: 'rgd1561552', id: 'RGD1561552'},
{value: 'rgd1561557', id: 'RGD1561557'},
{value: 'rgd1561558', id: 'RGD1561558'},
{value: 'rgd1561589', id: 'RGD1561589'},
{value: 'rgd1561590', id: 'RGD1561590'},
{value: 'rgd1561618', id: 'RGD1561618'},
{value: 'rgd1561627', id: 'RGD1561627'},
{value: 'rgd1561635', id: 'RGD1561635'},
{value: 'rgd1561636', id: 'RGD1561636'},
{value: 'rgd1561648', id: 'RGD1561648'},
{value: 'rgd1561661', id: 'RGD1561661'},
{value: 'rgd1561662', id: 'RGD1561662'},
{value: 'rgd1561671', id: 'RGD1561671'},
{value: 'rgd1561684', id: 'RGD1561684'},
{value: 'rgd1561694', id: 'RGD1561694'},
{value: 'rgd1561699', id: 'RGD1561699'},
{value: 'rgd1561730', id: 'RGD1561730'},
{value: 'rgd1561777', id: 'RGD1561777'},
{value: 'rgd1561795', id: 'RGD1561795'},
{value: 'rgd1561796', id: 'RGD1561796'},
{value: 'rgd1561870', id: 'RGD1561870'},
{value: 'rgd1561890', id: 'RGD1561890'},
{value: 'rgd1561916', id: 'RGD1561916'},
{value: 'rgd1561919', id: 'RGD1561919'},
{value: 'rgd1561958', id: 'RGD1561958'},
{value: 'rgd1561977', id: 'RGD1561977'},
{value: 'rgd1561998', id: 'RGD1561998'},
{value: 'rgd1562011', id: 'RGD1562011'},
{value: 'rgd1562024', id: 'RGD1562024'},
{value: 'rgd1562029', id: 'RGD1562029'},
{value: 'rgd1562036', id: 'RGD1562036'},
{value: 'rgd1562039', id: 'RGD1562039'},
{value: 'rgd1562066', id: 'RGD1562066'},
{value: 'rgd1562107', id: 'RGD1562107'},
{value: 'rgd1562139', id: 'RGD1562139'},
{value: 'rgd1562178', id: 'RGD1562178'},
{value: 'rgd1562218', id: 'RGD1562218'},
{value: 'rgd1562229', id: 'RGD1562229'},
{value: 'rgd1562299', id: 'RGD1562299'},
{value: 'rgd1562319', id: 'RGD1562319'},
{value: 'rgd1562339', id: 'RGD1562339'},
{value: 'rgd1562355', id: 'RGD1562355'},
{value: 'rgd1562378', id: 'RGD1562378'},
{value: 'rgd1562381', id: 'RGD1562381'},
{value: 'rgd1562394', id: 'RGD1562394'},
{value: 'rgd1562400', id: 'RGD1562400'},
{value: 'rgd1562402', id: 'RGD1562402'},
{value: 'rgd1562404', id: 'RGD1562404'},
{value: 'rgd1562415', id: 'RGD1562415'},
{value: 'rgd1562420', id: 'RGD1562420'},
{value: 'rgd1562423', id: 'RGD1562423'},
{value: 'rgd1562431', id: 'RGD1562431'},
{value: 'rgd1562433', id: 'RGD1562433'},
{value: 'rgd1562451', id: 'RGD1562451'},
{value: 'rgd1562485', id: 'RGD1562485'},
{value: 'rgd1562492', id: 'RGD1562492'},
{value: 'rgd1562508', id: 'RGD1562508'},
{value: 'rgd1562515', id: 'RGD1562515'},
{value: 'rgd1562532', id: 'RGD1562532'},
{value: 'rgd1562608', id: 'RGD1562608'},
{value: 'rgd1562636', id: 'RGD1562636'},
{value: 'rgd1562638', id: 'RGD1562638'},
{value: 'rgd1562641', id: 'RGD1562641'},
{value: 'rgd1562650', id: 'RGD1562650'},
{value: 'rgd1562652', id: 'RGD1562652'},
{value: 'rgd1562667', id: 'RGD1562667'},
{value: 'rgd1562683', id: 'RGD1562683'},
{value: 'rgd1562690', id: 'RGD1562690'},
{value: 'rgd1562699', id: 'RGD1562699'},
{value: 'rgd1562726', id: 'RGD1562726'},
{value: 'rgd1562811', id: 'RGD1562811'},
{value: 'rgd1562844', id: 'RGD1562844'},
{value: 'rgd1562871', id: 'RGD1562871'},
{value: 'rgd1562914', id: 'RGD1562914'},
{value: 'rgd1562932', id: 'RGD1562932'},
{value: 'rgd1562987', id: 'RGD1562987'},
{value: 'rgd1563056', id: 'RGD1563056'},
{value: 'rgd1563060', id: 'RGD1563060'},
{value: 'rgd1563072', id: 'RGD1563072'},
{value: 'rgd1563099', id: 'RGD1563099'},
{value: 'rgd1563100', id: 'RGD1563100'},
{value: 'rgd1563104', id: 'RGD1563104'},
{value: 'rgd1563124', id: 'RGD1563124'},
{value: 'rgd1563159', id: 'RGD1563159'},
{value: 'rgd1563217', id: 'RGD1563217'},
{value: 'rgd1563222', id: 'RGD1563222'},
{value: 'rgd1563231', id: 'RGD1563231'},
{value: 'rgd1563263', id: 'RGD1563263'},
{value: 'rgd1563270', id: 'RGD1563270'},
{value: 'rgd1563294', id: 'RGD1563294'},
{value: 'rgd1563300', id: 'RGD1563300'},
{value: 'rgd1563302', id: 'RGD1563302'},
{value: 'rgd1563307', id: 'RGD1563307'},
{value: 'rgd1563334', id: 'RGD1563334'},
{value: 'rgd1563352', id: 'RGD1563352'},
{value: 'rgd1563354', id: 'RGD1563354'},
{value: 'rgd1563356', id: 'RGD1563356'},
{value: 'rgd1563378', id: 'RGD1563378'},
{value: 'rgd1563400', id: 'RGD1563400'},
{value: 'rgd1563562', id: 'RGD1563562'},
{value: 'rgd1563570', id: 'RGD1563570'},
{value: 'rgd1563581', id: 'RGD1563581'},
{value: 'rgd1563590', id: 'RGD1563590'},
{value: 'rgd1563601', id: 'RGD1563601'},
{value: 'rgd1563606', id: 'RGD1563606'},
{value: 'rgd1563620', id: 'RGD1563620'},
{value: 'rgd1563667', id: 'RGD1563667'},
{value: 'rgd1563668', id: 'RGD1563668'},
{value: 'rgd1563705', id: 'RGD1563705'},
{value: 'rgd1563709', id: 'RGD1563709'},
{value: 'rgd1563815', id: 'RGD1563815'},
{value: 'rgd1563818', id: 'RGD1563818'},
{value: 'rgd1563835', id: 'RGD1563835'},
{value: 'rgd1563861', id: 'RGD1563861'},
{value: 'rgd1563888', id: 'RGD1563888'},
{value: 'rgd1563905', id: 'RGD1563905'},
{value: 'rgd1563917', id: 'RGD1563917'},
{value: 'rgd1563931', id: 'RGD1563931'},
{value: 'rgd1563941', id: 'RGD1563941'},
{value: 'rgd1563956', id: 'RGD1563956'},
{value: 'rgd1563958', id: 'RGD1563958'},
{value: 'rgd1564053', id: 'RGD1564053'},
{value: 'rgd1564126', id: 'RGD1564126'},
{value: 'rgd1564129', id: 'RGD1564129'},
{value: 'rgd1564148', id: 'RGD1564148'},
{value: 'rgd1564149', id: 'RGD1564149'},
{value: 'rgd1564162', id: 'RGD1564162'},
{value: 'rgd1564243', id: 'RGD1564243'},
{value: 'rgd1564247', id: 'RGD1564247'},
{value: 'rgd1564268', id: 'RGD1564268'},
{value: 'rgd1564306', id: 'RGD1564306'},
{value: 'rgd1564308', id: 'RGD1564308'},
{value: 'rgd1564313', id: 'RGD1564313'},
{value: 'rgd1564319', id: 'RGD1564319'},
{value: 'rgd1564324', id: 'RGD1564324'},
{value: 'rgd1564325', id: 'RGD1564325'},
{value: 'rgd1564347', id: 'RGD1564347'},
{value: 'rgd1564380', id: 'RGD1564380'},
{value: 'rgd1564400', id: 'RGD1564400'},
{value: 'rgd1564405', id: 'RGD1564405'},
{value: 'rgd1564425', id: 'RGD1564425'},
{value: 'rgd1564433', id: 'RGD1564433'},
{value: 'rgd1564447', id: 'RGD1564447'},
{value: 'rgd1564463', id: 'RGD1564463'},
{value: 'rgd1564480', id: 'RGD1564480'},
{value: 'rgd1564513', id: 'RGD1564513'},
{value: 'rgd1564515', id: 'RGD1564515'},
{value: 'rgd1564534', id: 'RGD1564534'},
{value: 'rgd1564548', id: 'RGD1564548'},
{value: 'rgd1564571', id: 'RGD1564571'},
{value: 'rgd1564597', id: 'RGD1564597'},
{value: 'rgd1564599', id: 'RGD1564599'},
{value: 'rgd1564606', id: 'RGD1564606'},
{value: 'rgd1564613', id: 'RGD1564613'},
{value: 'rgd1564617', id: 'RGD1564617'},
{value: 'rgd1564651', id: 'RGD1564651'},
{value: 'rgd1564657', id: 'RGD1564657'},
{value: 'rgd1564664', id: 'RGD1564664'},
{value: 'rgd1564712', id: 'RGD1564712'},
{value: 'rgd1564730', id: 'RGD1564730'},
{value: 'rgd1564744', id: 'RGD1564744'},
{value: 'rgd1564748', id: 'RGD1564748'},
{value: 'rgd1564770', id: 'RGD1564770'},
{value: 'rgd1564786', id: 'RGD1564786'},
{value: 'rgd1564801', id: 'RGD1564801'},
{value: 'rgd1564804', id: 'RGD1564804'},
{value: 'rgd1564807', id: 'RGD1564807'},
{value: 'rgd1564827', id: 'RGD1564827'},
{value: 'rgd1564836', id: 'RGD1564836'},
{value: 'rgd1564839', id: 'RGD1564839'},
{value: 'rgd1564854', id: 'RGD1564854'},
{value: 'rgd1564855', id: 'RGD1564855'},
{value: 'rgd1564865', id: 'RGD1564865'},
{value: 'rgd1564899', id: 'RGD1564899'},
{value: 'rgd1564937', id: 'RGD1564937'},
{value: 'rgd1564955', id: 'RGD1564955'},
{value: 'rgd1564958', id: 'RGD1564958'},
{value: 'rgd1564999', id: 'RGD1564999'},
{value: 'rgd1565010', id: 'RGD1565010'},
{value: 'rgd1565033', id: 'RGD1565033'},
{value: 'rgd1565058', id: 'RGD1565058'},
{value: 'rgd1565059', id: 'RGD1565059'},
{value: 'rgd1565071', id: 'RGD1565071'},
{value: 'rgd1565117', id: 'RGD1565117'},
{value: 'rgd1565129', id: 'RGD1565129'},
{value: 'rgd1565131', id: 'RGD1565131'},
{value: 'rgd1565158', id: 'RGD1565158'},
{value: 'rgd1565166', id: 'RGD1565166'},
{value: 'rgd1565183', id: 'RGD1565183'},
{value: 'rgd1565212', id: 'RGD1565212'},
{value: 'rgd1565222', id: 'RGD1565222'},
{value: 'rgd1565317', id: 'RGD1565317'},
{value: 'rgd1565323', id: 'RGD1565323'},
{value: 'rgd1565355', id: 'RGD1565355'},
{value: 'rgd1565367', id: 'RGD1565367'},
{value: 'rgd1565395', id: 'RGD1565395'},
{value: 'rgd1565410', id: 'RGD1565410'},
{value: 'rgd1565415', id: 'RGD1565415'},
{value: 'rgd1565472', id: 'RGD1565472'},
{value: 'rgd1565478', id: 'RGD1565478'},
{value: 'rgd1565560', id: 'RGD1565560'},
{value: 'rgd1565611', id: 'RGD1565611'},
{value: 'rgd1565617', id: 'RGD1565617'},
{value: 'rgd1565622', id: 'RGD1565622'},
{value: 'rgd1565655', id: 'RGD1565655'},
{value: 'rgd1565657', id: 'RGD1565657'},
{value: 'rgd1565660', id: 'RGD1565660'},
{value: 'rgd1565685', id: 'RGD1565685'},
{value: 'rgd1565753', id: 'RGD1565753'},
{value: 'rgd1565767', id: 'RGD1565767'},
{value: 'rgd1565784', id: 'RGD1565784'},
{value: 'rgd1565798', id: 'RGD1565798'},
{value: 'rgd1565806', id: 'RGD1565806'},
{value: 'rgd1565844', id: 'RGD1565844'},
{value: 'rgd1565862', id: 'RGD1565862'},
{value: 'rgd1565866', id: 'RGD1565866'},
{value: 'rgd1565959', id: 'RGD1565959'},
{value: 'rgd1565987', id: 'RGD1565987'},
{value: 'rgd1565989', id: 'RGD1565989'},
{value: 'rgd1566006', id: 'RGD1566006'},
{value: 'rgd1566035', id: 'RGD1566035'},
{value: 'rgd1566078', id: 'RGD1566078'},
{value: 'rgd1566085', id: 'RGD1566085'},
{value: 'rgd1566093', id: 'RGD1566093'},
{value: 'rgd1566099', id: 'RGD1566099'},
{value: 'rgd1566134', id: 'RGD1566134'},
{value: 'rgd1566136', id: 'RGD1566136'},
{value: 'rgd1566159', id: 'RGD1566159'},
{value: 'rgd1566184', id: 'RGD1566184'},
{value: 'rgd1566189', id: 'RGD1566189'},
{value: 'rgd1566197', id: 'RGD1566197'},
{value: 'rgd1566212', id: 'RGD1566212'},
{value: 'rgd1566225', id: 'RGD1566225'},
{value: 'rgd1566265', id: 'RGD1566265'},
{value: 'rgd1566325', id: 'RGD1566325'},
{value: 'rgd1566337', id: 'RGD1566337'},
{value: 'rgd1566359', id: 'RGD1566359'},
{value: 'rgd1566368', id: 'RGD1566368'},
{value: 'rgd1566369', id: 'RGD1566369'},
{value: 'rgd1566373', id: 'RGD1566373'},
{value: 'rgd1566386', id: 'RGD1566386'},
{value: 'rgd1566387', id: 'RGD1566387'},
{value: 'rgd1597339', id: 'RGD1597339'},
{value: 'rgd1624210', id: 'RGD1624210'},
{value: 'rgd2320734', id: 'RGD2320734'},
{value: 'rgd735065', id: 'RGD735065'},
{value: 'rgl1', id: 'RGL1'},
{value: 'rgl2', id: 'RGL2'},
{value: 'rgl3', id: 'RGL3'},
{value: 'rgma', id: 'RGMA'},
{value: 'rgmb', id: 'RGMB'},
{value: 'rgn', id: 'RGN'},
{value: 'rgp1', id: 'RGP1'},
{value: 'rgr', id: 'RGR'},
{value: 'rgs1', id: 'RGS1'},
{value: 'rgs10', id: 'RGS10'},
{value: 'rgs11', id: 'RGS11'},
{value: 'rgs12', id: 'RGS12'},
{value: 'rgs13', id: 'RGS13'},
{value: 'rgs14', id: 'RGS14'},
{value: 'rgs16', id: 'RGS16'},
{value: 'rgs17', id: 'RGS17'},
{value: 'rgs18', id: 'RGS18'},
{value: 'rgs19', id: 'RGS19'},
{value: 'rgs2', id: 'RGS2'},
{value: 'rgs20', id: 'RGS20'},
{value: 'rgs21', id: 'RGS21'},
{value: 'rgs22', id: 'RGS22'},
{value: 'rgs3', id: 'RGS3'},
{value: 'rgs4', id: 'RGS4'},
{value: 'rgs5', id: 'RGS5'},
{value: 'rgs6', id: 'RGS6'},
{value: 'rgs7', id: 'RGS7'},
{value: 'rgs7bp', id: 'RGS7BP'},
{value: 'rgs8', id: 'RGS8'},
{value: 'rgs9', id: 'RGS9'},
{value: 'rgs9bp', id: 'RGS9BP'},
{value: 'rgsl1', id: 'RGSL1'},
{value: 'rhag', id: 'RHAG'},
{value: 'rhbdd1', id: 'RHBDD1'},
{value: 'rhbdd2', id: 'RHBDD2'},
{value: 'rhbdd3', id: 'RHBDD3'},
{value: 'rhbdf1', id: 'RHBDF1'},
{value: 'rhbdf2', id: 'RHBDF2'},
{value: 'rhbdl1', id: 'RHBDL1'},
{value: 'rhbdl2', id: 'RHBDL2'},
{value: 'rhbdl3', id: 'RHBDL3'},
{value: 'rhbg', id: 'RHBG'},
{value: 'rhcg', id: 'RHCG'},
{value: 'rhd', id: 'RHD'},
{value: 'rheb', id: 'RHEB'},
{value: 'rhebl1', id: 'RHEBL1'},
{value: 'rhno1', id: 'RHNO1'},
{value: 'rho', id: 'RHO'},
{value: 'rhoa', id: 'RHOA'},
{value: 'rhob', id: 'RHOB'},
{value: 'rhobtb1', id: 'RHOBTB1'},
{value: 'rhobtb2', id: 'RHOBTB2'},
{value: 'rhobtb3', id: 'RHOBTB3'},
{value: 'rhoc', id: 'RHOC'},
{value: 'rhod', id: 'RHOD'},
{value: 'rhof', id: 'RHOF'},
{value: 'rhog', id: 'RHOG'},
{value: 'rhoh', id: 'RHOH'},
{value: 'rhoj', id: 'RHOJ'},
{value: 'rhoq', id: 'RHOQ'},
{value: 'rhot1', id: 'RHOT1'},
{value: 'rhot2', id: 'RHOT2'},
{value: 'rhou', id: 'RHOU'},
{value: 'rhov', id: 'RHOV'},
{value: 'rhox10', id: 'RHOX10'},
{value: 'rhox11', id: 'RHOX11'},
{value: 'rhox12', id: 'RHOX12'},
{value: 'rhox13', id: 'RHOX13'},
{value: 'rhox2', id: 'RHOX2'},
{value: 'rhox3', id: 'RHOX3'},
{value: 'rhox5', id: 'RHOX5'},
{value: 'rhox8', id: 'RHOX8'},
{value: 'rhpn1', id: 'RHPN1'},
{value: 'rhpn2', id: 'RHPN2'},
{value: 'ribc1', id: 'RIBC1'},
{value: 'ribc2', id: 'RIBC2'},
{value: 'ric1', id: 'RIC1'},
{value: 'ric3', id: 'RIC3'},
{value: 'ric8a', id: 'RIC8A'},
{value: 'ric8b', id: 'RIC8B'},
{value: 'rictor', id: 'RICTOR'},
{value: 'rida', id: 'RIDA'},
{value: 'rif1', id: 'RIF1'},
{value: 'riiad1', id: 'RIIAD1'},
{value: 'rilp', id: 'RILP'},
{value: 'rilpl1', id: 'RILPL1'},
{value: 'rilpl2', id: 'RILPL2'},
{value: 'rimbp2', id: 'RIMBP2'},
{value: 'rimbp3', id: 'RIMBP3'},
{value: 'rimkla', id: 'RIMKLA'},
{value: 'rimklb', id: 'RIMKLB'},
{value: 'rimoc1', id: 'RIMOC1'},
{value: 'rims1', id: 'RIMS1'},
{value: 'rims2', id: 'RIMS2'},
{value: 'rims3', id: 'RIMS3'},
{value: 'rims4', id: 'RIMS4'},
{value: 'rin1', id: 'RIN1'},
{value: 'rin2', id: 'RIN2'},
{value: 'rin3', id: 'RIN3'},
{value: 'ring1', id: 'RING1'},
{value: 'rinl', id: 'RINL'},
{value: 'rint1', id: 'RINT1'},
{value: 'riok1', id: 'RIOK1'},
{value: 'riok2', id: 'RIOK2'},
{value: 'riok3', id: 'RIOK3'},
{value: 'riox1', id: 'RIOX1'},
{value: 'riox2', id: 'RIOX2'},
{value: 'ripk1', id: 'RIPK1'},
{value: 'ripk2', id: 'RIPK2'},
{value: 'ripk3', id: 'RIPK3'},
{value: 'ripk4', id: 'RIPK4'},
{value: 'ripor1', id: 'RIPOR1'},
{value: 'ripor2', id: 'RIPOR2'},
{value: 'ripor3', id: 'RIPOR3'},
{value: 'ripply1', id: 'RIPPLY1'},
{value: 'ripply2', id: 'RIPPLY2'},
{value: 'ripply3', id: 'RIPPLY3'},
{value: 'rit1', id: 'RIT1'},
{value: 'rit2', id: 'RIT2'},
{value: 'rita1', id: 'RITA1'},
{value: 'rlbp1', id: 'RLBP1'},
{value: 'rlf', id: 'RLF'},
{value: 'rliml1', id: 'RLIML1'},
{value: 'rln1', id: 'RLN1'},
{value: 'rln3', id: 'RLN3'},
{value: 'rmc1', id: 'RMC1'},
{value: 'rmdn1', id: 'RMDN1'},
{value: 'rmdn2', id: 'RMDN2'},
{value: 'rmdn3', id: 'RMDN3'},
{value: 'rmi1', id: 'RMI1'},
{value: 'rmi2', id: 'RMI2'},
{value: 'rmnd1', id: 'RMND1'},
{value: 'rmnd5a', id: 'RMND5A'},
{value: 'rmnd5b', id: 'RMND5B'},
{value: 'rn5-8s', id: 'RN5-8S'},
{value: 'rn5s', id: 'RN5S'},
{value: 'rn7sl1', id: 'RN7SL1'},
{value: 'rnase_mrp', id: 'RNASE_MRP'},
{value: 'rnase1', id: 'RNASE1'},
{value: 'rnase10', id: 'RNASE10'},
{value: 'rnase11', id: 'RNASE11'},
{value: 'rnase12', id: 'RNASE12'},
{value: 'rnase13', id: 'RNASE13'},
{value: 'rnase17', id: 'RNASE17'},
{value: 'rnase1l1', id: 'RNASE1L1'},
{value: 'rnase1l2', id: 'RNASE1L2'},
{value: 'rnase2', id: 'RNASE2'},
{value: 'rnase3', id: 'RNASE3'},
{value: 'rnase4', id: 'RNASE4'},
{value: 'rnase6', id: 'RNASE6'},
{value: 'rnase9', id: 'RNASE9'},
{value: 'rnaseh1', id: 'RNASEH1'},
{value: 'rnaseh2a', id: 'RNASEH2A'},
{value: 'rnaseh2b', id: 'RNASEH2B'},
{value: 'rnaseh2c', id: 'RNASEH2C'},
{value: 'rnasek', id: 'RNASEK'},
{value: 'rnasel', id: 'RNASEL'},
{value: 'rnasep_nuc', id: 'RNASEP_NUC'},
{value: 'rnaset2', id: 'RNASET2'},
{value: 'rnd1', id: 'RND1'},
{value: 'rnd2', id: 'RND2'},
{value: 'rnd3', id: 'RND3'},
{value: 'rnf10', id: 'RNF10'},
{value: 'rnf103', id: 'RNF103'},
{value: 'rnf111', id: 'RNF111'},
{value: 'rnf112', id: 'RNF112'},
{value: 'rnf113a1', id: 'RNF113A1'},
{value: 'rnf113a2', id: 'RNF113A2'},
{value: 'rnf114', id: 'RNF114'},
{value: 'rnf115', id: 'RNF115'},
{value: 'rnf11l1', id: 'RNF11L1'},
{value: 'rnf121', id: 'RNF121'},
{value: 'rnf122', id: 'RNF122'},
{value: 'rnf123', id: 'RNF123'},
{value: 'rnf125', id: 'RNF125'},
{value: 'rnf126', id: 'RNF126'},
{value: 'rnf128', id: 'RNF128'},
{value: 'rnf13', id: 'RNF13'},
{value: 'rnf130', id: 'RNF130'},
{value: 'rnf135', id: 'RNF135'},
{value: 'rnf138', id: 'RNF138'},
{value: 'rnf139', id: 'RNF139'},
{value: 'rnf14', id: 'RNF14'},
{value: 'rnf141', id: 'RNF141'},
{value: 'rnf144a', id: 'RNF144A'},
{value: 'rnf144b', id: 'RNF144B'},
{value: 'rnf145', id: 'RNF145'},
{value: 'rnf146', id: 'RNF146'},
{value: 'rnf148', id: 'RNF148'},
{value: 'rnf149', id: 'RNF149'},
{value: 'rnf150', id: 'RNF150'},
{value: 'rnf151', id: 'RNF151'},
{value: 'rnf152', id: 'RNF152'},
{value: 'rnf157', id: 'RNF157'},
{value: 'rnf165', id: 'RNF165'},
{value: 'rnf166', id: 'RNF166'},
{value: 'rnf167', id: 'RNF167'},
{value: 'rnf168', id: 'RNF168'},
{value: 'rnf169', id: 'RNF169'},
{value: 'rnf17', id: 'RNF17'},
{value: 'rnf170', id: 'RNF170'},
{value: 'rnf180', id: 'RNF180'},
{value: 'rnf181', id: 'RNF181'},
{value: 'rnf182', id: 'RNF182'},
{value: 'rnf183', id: 'RNF183'},
{value: 'rnf185', id: 'RNF185'},
{value: 'rnf186', id: 'RNF186'},
{value: 'rnf187', id: 'RNF187'},
{value: 'rnf19a', id: 'RNF19A'},
{value: 'rnf19b', id: 'RNF19B'},
{value: 'rnf2', id: 'RNF2'},
{value: 'rnf20', id: 'RNF20'},
{value: 'rnf207', id: 'RNF207'},
{value: 'rnf208', id: 'RNF208'},
{value: 'rnf212', id: 'RNF212'},
{value: 'rnf213', id: 'RNF213'},
{value: 'rnf214', id: 'RNF214'},
{value: 'rnf215', id: 'RNF215'},
{value: 'rnf216', id: 'RNF216'},
{value: 'rnf217', id: 'RNF217'},
{value: 'rnf220', id: 'RNF220'},
{value: 'rnf222', id: 'RNF222'},
{value: 'rnf224', id: 'RNF224'},
{value: 'rnf225', id: 'RNF225'},
{value: 'rnf227', id: 'RNF227'},
{value: 'rnf24', id: 'RNF24'},
{value: 'rnf25', id: 'RNF25'},
{value: 'rnf26', id: 'RNF26'},
{value: 'rnf31', id: 'RNF31'},
{value: 'rnf32', id: 'RNF32'},
{value: 'rnf34', id: 'RNF34'},
{value: 'rnf38', id: 'RNF38'},
{value: 'rnf39', id: 'RNF39'},
{value: 'rnf4', id: 'RNF4'},
{value: 'rnf40', id: 'RNF40'},
{value: 'rnf41', id: 'RNF41'},
{value: 'rnf43', id: 'RNF43'},
{value: 'rnf44', id: 'RNF44'},
{value: 'rnf5', id: 'RNF5'},
{value: 'rnf6', id: 'RNF6'},
{value: 'rnf7', id: 'RNF7'},
{value: 'rnf8', id: 'RNF8'},
{value: 'rnft1', id: 'RNFT1'},
{value: 'rnft2', id: 'RNFT2'},
{value: 'rngtt', id: 'RNGTT'},
{value: 'rnh1', id: 'RNH1'},
{value: 'rnls', id: 'RNLS'},
{value: 'rnmt', id: 'RNMT'},
{value: 'rno-mir-344a-1', id: 'RNO-MIR-344A-1'},
{value: 'rno-mir-3542-1', id: 'RNO-MIR-3542-1'},
{value: 'rno-mir-3584-1', id: 'RNO-MIR-3584-1'},
{value: 'rno-mir-484', id: 'RNO-MIR-484'},
{value: 'rnpc3', id: 'RNPC3'},
{value: 'rnpep', id: 'RNPEP'},
{value: 'rnpepl1', id: 'RNPEPL1'},
{value: 'rnps1', id: 'RNPS1'},
{value: 'rnu11', id: 'RNU11'},
{value: 'rnu3a', id: 'RNU3A'},
{value: 'ro60', id: 'RO60'},
{value: 'robo1', id: 'ROBO1'},
{value: 'robo2', id: 'ROBO2'},
{value: 'robo3', id: 'ROBO3'},
{value: 'robo4', id: 'ROBO4'},
{value: 'rock1', id: 'ROCK1'},
{value: 'rock2', id: 'ROCK2'},
{value: 'rogdi', id: 'ROGDI'},
{value: 'rom1', id: 'ROM1'},
{value: 'romo1', id: 'ROMO1'},
{value: 'ropn1', id: 'ROPN1'},
{value: 'ropn1l', id: 'ROPN1L'},
{value: 'ror1', id: 'ROR1'},
{value: 'ror2', id: 'ROR2'},
{value: 'rora', id: 'RORA'},
{value: 'rorb', id: 'RORB'},
{value: 'rorc', id: 'RORC'},
{value: 'ros1', id: 'ROS1'},
{value: 'rp1', id: 'RP1'},
{value: 'rp1l1', id: 'RP1L1'},
{value: 'rp2', id: 'RP2'},
{value: 'rp9', id: 'RP9'},
{value: 'rpa1', id: 'RPA1'},
{value: 'rpa2', id: 'RPA2'},
{value: 'rpa3', id: 'RPA3'},
{value: 'rpain', id: 'RPAIN'},
{value: 'rpap1', id: 'RPAP1'},
{value: 'rpap2', id: 'RPAP2'},
{value: 'rpap3', id: 'RPAP3'},
{value: 'rpe', id: 'RPE'},
{value: 'rpe65', id: 'RPE65'},
{value: 'rpf1', id: 'RPF1'},
{value: 'rpf2', id: 'RPF2'},
{value: 'rpgr', id: 'RPGR'},
{value: 'rpgrip1', id: 'RPGRIP1'},
{value: 'rpgrip1l', id: 'RPGRIP1L'},
{value: 'rph3a', id: 'RPH3A'},
{value: 'rph3al', id: 'RPH3AL'},
{value: 'rpia', id: 'RPIA'},
{value: 'rpl10', id: 'RPL10'},
{value: 'rpl10a', id: 'RPL10A'},
{value: 'rpl10l', id: 'RPL10L'},
{value: 'rpl11', id: 'RPL11'},
{value: 'rpl12-ps1', id: 'RPL12-PS1'},
{value: 'rpl12', id: 'RPL12'},
{value: 'rpl13', id: 'RPL13'},
{value: 'rpl13a', id: 'RPL13A'},
{value: 'rpl14', id: 'RPL14'},
{value: 'rpl15', id: 'RPL15'},
{value: 'rpl17', id: 'RPL17'},
{value: 'rpl18', id: 'RPL18'},
{value: 'rpl18a', id: 'RPL18A'},
{value: 'rpl19', id: 'RPL19'},
{value: 'rpl21', id: 'RPL21'},
{value: 'rpl22', id: 'RPL22'},
{value: 'rpl22l1', id: 'RPL22L1'},
{value: 'rpl22l2', id: 'RPL22L2'},
{value: 'rpl23', id: 'RPL23'},
{value: 'rpl23a', id: 'RPL23A'},
{value: 'rpl24', id: 'RPL24'},
{value: 'rpl26-ps3', id: 'RPL26-PS3'},
{value: 'rpl26', id: 'RPL26'},
{value: 'rpl27-l1', id: 'RPL27-L1'},
{value: 'rpl27', id: 'RPL27'},
{value: 'rpl27a', id: 'RPL27A'},
{value: 'rpl28', id: 'RPL28'},
{value: 'rpl29', id: 'RPL29'},
{value: 'rpl3', id: 'RPL3'},
{value: 'rpl30', id: 'RPL30'},
{value: 'rpl30l1', id: 'RPL30L1'},
{value: 'rpl31', id: 'RPL31'},
{value: 'rpl31l2', id: 'RPL31L2'},
{value: 'rpl31l4', id: 'RPL31L4'},
{value: 'rpl32', id: 'RPL32'},
{value: 'rpl34', id: 'RPL34'},
{value: 'rpl35', id: 'RPL35'},
{value: 'rpl35a', id: 'RPL35A'},
{value: 'rpl35al1', id: 'RPL35AL1'},
{value: 'rpl36', id: 'RPL36'},
{value: 'rpl36a-ps2', id: 'RPL36A-PS2'},
{value: 'rpl36a', id: 'RPL36A'},
{value: 'rpl36al', id: 'RPL36AL'},
{value: 'rpl37', id: 'RPL37'},
{value: 'rpl37a', id: 'RPL37A'},
{value: 'rpl38', id: 'RPL38'},
{value: 'rpl39', id: 'RPL39'},
{value: 'rpl39l', id: 'RPL39L'},
{value: 'rpl3l', id: 'RPL3L'},
{value: 'rpl4', id: 'RPL4'},
{value: 'rpl41', id: 'RPL41'},
{value: 'rpl5', id: 'RPL5'},
{value: 'rpl6-ps1', id: 'RPL6-PS1'},
{value: 'rpl6', id: 'RPL6'},
{value: 'rpl7', id: 'RPL7'},
{value: 'rpl7a', id: 'RPL7A'},
{value: 'rpl7l1', id: 'RPL7L1'},
{value: 'rpl8', id: 'RPL8'},
{value: 'rpl9', id: 'RPL9'},
{value: 'rplp0', id: 'RPLP0'},
{value: 'rplp0l1', id: 'RPLP0L1'},
{value: 'rplp1', id: 'RPLP1'},
{value: 'rplp2', id: 'RPLP2'},
{value: 'rpn1', id: 'RPN1'},
{value: 'rpn2', id: 'RPN2'},
{value: 'rpp14', id: 'RPP14'},
{value: 'rpp21', id: 'RPP21'},
{value: 'rpp25', id: 'RPP25'},
{value: 'rpp25l', id: 'RPP25L'},
{value: 'rpp30', id: 'RPP30'},
{value: 'rpp38', id: 'RPP38'},
{value: 'rpp40', id: 'RPP40'},
{value: 'rpph1', id: 'RPPH1'},
{value: 'rprd1a', id: 'RPRD1A'},
{value: 'rprd1b', id: 'RPRD1B'},
{value: 'rprd2', id: 'RPRD2'},
{value: 'rprm', id: 'RPRM'},
{value: 'rprml', id: 'RPRML'},
{value: 'rps10', id: 'RPS10'},
{value: 'rps10l1', id: 'RPS10L1'},
{value: 'rps11', id: 'RPS11'},
{value: 'rps12', id: 'RPS12'},
{value: 'rps12l3', id: 'RPS12L3'},
{value: 'rps13', id: 'RPS13'},
{value: 'rps14', id: 'RPS14'},
{value: 'rps15', id: 'RPS15'},
{value: 'rps15a', id: 'RPS15A'},
{value: 'rps15al2', id: 'RPS15AL2'},
{value: 'rps15al4', id: 'RPS15AL4'},
{value: 'rps16', id: 'RPS16'},
{value: 'rps17', id: 'RPS17'},
{value: 'rps17l', id: 'RPS17L'},
{value: 'rps18', id: 'RPS18'},
{value: 'rps18l1', id: 'RPS18L1'},
{value: 'rps19', id: 'RPS19'},
{value: 'rps19bp1', id: 'RPS19BP1'},
{value: 'rps19l1', id: 'RPS19L1'},
{value: 'rps2', id: 'RPS2'},
{value: 'rps20', id: 'RPS20'},
{value: 'rps21', id: 'RPS21'},
{value: 'rps23', id: 'RPS23'},
{value: 'rps24', id: 'RPS24'},
{value: 'rps25', id: 'RPS25'},
{value: 'rps26', id: 'RPS26'},
{value: 'rps27', id: 'RPS27'},
{value: 'rps27a-ps1', id: 'RPS27A-PS1'},
{value: 'rps27a', id: 'RPS27A'},
{value: 'rps27l', id: 'RPS27L'},
{value: 'rps28', id: 'RPS28'},
{value: 'rps29', id: 'RPS29'},
{value: 'rps3', id: 'RPS3'},
{value: 'rps3a', id: 'RPS3A'},
{value: 'rps4x-ps9', id: 'RPS4X-PS9'},
{value: 'rps4x', id: 'RPS4X'},
{value: 'rps4y2', id: 'RPS4Y2'},
{value: 'rps5', id: 'RPS5'},
{value: 'rps6', id: 'RPS6'},
{value: 'rps6ka1', id: 'RPS6KA1'},
{value: 'rps6ka2', id: 'RPS6KA2'},
{value: 'rps6ka3', id: 'RPS6KA3'},
{value: 'rps6ka4', id: 'RPS6KA4'},
{value: 'rps6ka5', id: 'RPS6KA5'},
{value: 'rps6ka6', id: 'RPS6KA6'},
{value: 'rps6kb1', id: 'RPS6KB1'},
{value: 'rps6kb2', id: 'RPS6KB2'},
{value: 'rps6kc1', id: 'RPS6KC1'},
{value: 'rps6kl1', id: 'RPS6KL1'},
{value: 'rps7', id: 'RPS7'},
{value: 'rps8', id: 'RPS8'},
{value: 'rps9', id: 'RPS9'},
{value: 'rps9l1', id: 'RPS9L1'},
{value: 'rpsa', id: 'RPSA'},
{value: 'rptn', id: 'RPTN'},
{value: 'rptor', id: 'RPTOR'},
{value: 'rpusd1', id: 'RPUSD1'},
{value: 'rpusd2', id: 'RPUSD2'},
{value: 'rpusd3', id: 'RPUSD3'},
{value: 'rpusd4', id: 'RPUSD4'},
{value: 'rrad', id: 'RRAD'},
{value: 'rraga', id: 'RRAGA'},
{value: 'rragb', id: 'RRAGB'},
{value: 'rragc', id: 'RRAGC'},
{value: 'rragd', id: 'RRAGD'},
{value: 'rras', id: 'RRAS'},
{value: 'rras2', id: 'RRAS2'},
{value: 'rrbp1', id: 'RRBP1'},
{value: 'rreb1', id: 'RREB1'},
{value: 'rrh', id: 'RRH'},
{value: 'rrm1', id: 'RRM1'},
{value: 'rrm2', id: 'RRM2'},
{value: 'rrm2b', id: 'RRM2B'},
{value: 'rrn3', id: 'RRN3'},
{value: 'rrp1', id: 'RRP1'},
{value: 'rrp12', id: 'RRP12'},
{value: 'rrp15', id: 'RRP15'},
{value: 'rrp1b', id: 'RRP1B'},
{value: 'rrp36', id: 'RRP36'},
{value: 'rrp7a', id: 'RRP7A'},
{value: 'rrp8', id: 'RRP8'},
{value: 'rrp9', id: 'RRP9'},
{value: 'rrs1', id: 'RRS1'},
{value: 'rs1', id: 'RS1'},
{value: 'rsa-14-44', id: 'RSA-14-44'},
{value: 'rsad1', id: 'RSAD1'},
{value: 'rsad2', id: 'RSAD2'},
{value: 'rsbn1', id: 'RSBN1'},
{value: 'rsbn1l', id: 'RSBN1L'},
{value: 'rsf1', id: 'RSF1'},
{value: 'rskr', id: 'RSKR'},
{value: 'rsl1', id: 'RSL1'},
{value: 'rsl1d1', id: 'RSL1D1'},
{value: 'rsl1d1l1', id: 'RSL1D1L1'},
{value: 'rsl24d1', id: 'RSL24D1'},
{value: 'rsph1', id: 'RSPH1'},
{value: 'rsph10b', id: 'RSPH10B'},
{value: 'rsph14', id: 'RSPH14'},
{value: 'rsph3', id: 'RSPH3'},
{value: 'rsph4a', id: 'RSPH4A'},
{value: 'rsph6a', id: 'RSPH6A'},
{value: 'rsph9', id: 'RSPH9'},
{value: 'rspo1', id: 'RSPO1'},
{value: 'rspo2', id: 'RSPO2'},
{value: 'rspo3', id: 'RSPO3'},
{value: 'rspo4', id: 'RSPO4'},
{value: 'rspry1', id: 'RSPRY1'},
{value: 'rsrc1', id: 'RSRC1'},
{value: 'rsrc2', id: 'RSRC2'},
{value: 'rsrp1', id: 'RSRP1'},
{value: 'rsu1', id: 'RSU1'},
{value: 'rt1-a', id: 'RT1-A'},
{value: 'rt1-a1', id: 'RT1-A1'},
{value: 'rt1-a2', id: 'RT1-A2'},
{value: 'rt1-ba', id: 'RT1-BA'},
{value: 'rt1-bb', id: 'RT1-BB'},
{value: 'rt1-ce1', id: 'RT1-CE1'},
{value: 'rt1-ce10', id: 'RT1-CE10'},
{value: 'rt1-ce11', id: 'RT1-CE11'},
{value: 'rt1-ce13', id: 'RT1-CE13'},
{value: 'rt1-ce15', id: 'RT1-CE15'},
{value: 'rt1-ce16', id: 'RT1-CE16'},
{value: 'rt1-ce2', id: 'RT1-CE2'},
{value: 'rt1-ce3', id: 'RT1-CE3'},
{value: 'rt1-ce4', id: 'RT1-CE4'},
{value: 'rt1-ce5', id: 'RT1-CE5'},
{value: 'rt1-ce7', id: 'RT1-CE7'},
{value: 'rt1-cl', id: 'RT1-CL'},
{value: 'rt1-da', id: 'RT1-DA'},
{value: 'rt1-db1', id: 'RT1-DB1'},
{value: 'rt1-db2', id: 'RT1-DB2'},
{value: 'rt1-dma', id: 'RT1-DMA'},
{value: 'rt1-dmb', id: 'RT1-DMB'},
{value: 'rt1-doa', id: 'RT1-DOA'},
{value: 'rt1-dob', id: 'RT1-DOB'},
{value: 'rt1-ha', id: 'RT1-HA'},
{value: 'rt1-m1-2', id: 'RT1-M1-2'},
{value: 'rt1-m2', id: 'RT1-M2'},
{value: 'rt1-m3-1', id: 'RT1-M3-1'},
{value: 'rt1-m4', id: 'RT1-M4'},
{value: 'rt1-m5', id: 'RT1-M5'},
{value: 'rt1-m6-1', id: 'RT1-M6-1'},
{value: 'rt1-m6-2', id: 'RT1-M6-2'},
{value: 'rt1-n3', id: 'RT1-N3'},
{value: 'rt1-s2', id: 'RT1-S2'},
{value: 'rt1-s3', id: 'RT1-S3'},
{value: 'rt1-t18', id: 'RT1-T18'},
{value: 'rt1-t24-1', id: 'RT1-T24-1'},
{value: 'rt1-t24-3', id: 'RT1-T24-3'},
{value: 'rt1-t24-4', id: 'RT1-T24-4'},
{value: 'rtbdn', id: 'RTBDN'},
{value: 'rtca', id: 'RTCA'},
{value: 'rtcb', id: 'RTCB'},
{value: 'rtel1', id: 'RTEL1'},
{value: 'rtf1', id: 'RTF1'},
{value: 'rtfdc1', id: 'RTFDC1'},
{value: 'rtkn', id: 'RTKN'},
{value: 'rtkn2', id: 'RTKN2'},
{value: 'rtl1', id: 'RTL1'},
{value: 'rtl3', id: 'RTL3'},
{value: 'rtl4', id: 'RTL4'},
{value: 'rtl5', id: 'RTL5'},
{value: 'rtl6', id: 'RTL6'},
{value: 'rtl8a', id: 'RTL8A'},
{value: 'rtl8b', id: 'RTL8B'},
{value: 'rtl9', id: 'RTL9'},
{value: 'rtn1', id: 'RTN1'},
{value: 'rtn2', id: 'RTN2'},
{value: 'rtn3', id: 'RTN3'},
{value: 'rtn4', id: 'RTN4'},
{value: 'rtn4ip1', id: 'RTN4IP1'},
{value: 'rtn4r', id: 'RTN4R'},
{value: 'rtn4rl1', id: 'RTN4RL1'},
{value: 'rtn4rl2', id: 'RTN4RL2'},
{value: 'rtp1', id: 'RTP1'},
{value: 'rtp2', id: 'RTP2'},
{value: 'rtp3', id: 'RTP3'},
{value: 'rtp4', id: 'RTP4'},
{value: 'rtraf', id: 'RTRAF'},
{value: 'rttn', id: 'RTTN'},
{value: 'rubcn', id: 'RUBCN'},
{value: 'rubcnl', id: 'RUBCNL'},
{value: 'rufy1', id: 'RUFY1'},
{value: 'rufy2', id: 'RUFY2'},
{value: 'rufy3', id: 'RUFY3'},
{value: 'rufy4', id: 'RUFY4'},
{value: 'rundc1', id: 'RUNDC1'},
{value: 'rundc3a', id: 'RUNDC3A'},
{value: 'rundc3b', id: 'RUNDC3B'},
{value: 'runx1', id: 'RUNX1'},
{value: 'runx1t1', id: 'RUNX1T1'},
{value: 'runx2', id: 'RUNX2'},
{value: 'runx3', id: 'RUNX3'},
{value: 'rup2', id: 'RUP2'},
{value: 'rusc1', id: 'RUSC1'},
{value: 'rusc2', id: 'RUSC2'},
{value: 'rusf1', id: 'RUSF1'},
{value: 'ruvbl1', id: 'RUVBL1'},
{value: 'ruvbl2', id: 'RUVBL2'},
{value: 'rwdd1', id: 'RWDD1'},
{value: 'rwdd2a', id: 'RWDD2A'},
{value: 'rwdd2b', id: 'RWDD2B'},
{value: 'rwdd3', id: 'RWDD3'},
{value: 'rwdd4', id: 'RWDD4'},
{value: 'rxfp1', id: 'RXFP1'},
{value: 'rxfp2', id: 'RXFP2'},
{value: 'rxfp3', id: 'RXFP3'},
{value: 'rxra', id: 'RXRA'},
{value: 'rxrb', id: 'RXRB'},
{value: 'rxrg', id: 'RXRG'},
{value: 'rxylt1', id: 'RXYLT1'},
{value: 'rybp', id: 'RYBP'},
{value: 'ryk', id: 'RYK'},
{value: 'ryr1', id: 'RYR1'},
{value: 'ryr2', id: 'RYR2'},
{value: 'ryr3', id: 'RYR3'},
{value: 's100a1', id: 'S100A1'},
{value: 's100a10', id: 'S100A10'},
{value: 's100a11', id: 'S100A11'},
{value: 's100a13', id: 'S100A13'},
{value: 's100a14', id: 'S100A14'},
{value: 's100a16', id: 'S100A16'},
{value: 's100a3', id: 'S100A3'},
{value: 's100a4', id: 'S100A4'},
{value: 's100a5', id: 'S100A5'},
{value: 's100a6', id: 'S100A6'},
{value: 's100a7a', id: 'S100A7A'},
{value: 's100a7l2', id: 'S100A7L2'},
{value: 's100a8', id: 'S100A8'},
{value: 's100a9', id: 'S100A9'},
{value: 's100b', id: 'S100B'},
{value: 's100g', id: 'S100G'},
{value: 's100pbp', id: 'S100PBP'},
{value: 's100vp', id: 'S100VP'},
{value: 's100z', id: 'S100Z'},
{value: 's1pr1', id: 'S1PR1'},
{value: 's1pr2', id: 'S1PR2'},
{value: 's1pr3', id: 'S1PR3'},
{value: 's1pr4', id: 'S1PR4'},
{value: 's1pr5', id: 'S1PR5'},
{value: 'saa4', id: 'SAA4'},
{value: 'saal1', id: 'SAAL1'},
{value: 'sac3d1', id: 'SAC3D1'},
{value: 'sacm1l', id: 'SACM1L'},
{value: 'sacs', id: 'SACS'},
{value: 'sae1', id: 'SAE1'},
{value: 'safb', id: 'SAFB'},
{value: 'safb2', id: 'SAFB2'},
{value: 'sag', id: 'SAG'},
{value: 'sall1', id: 'SALL1'},
{value: 'sall2', id: 'SALL2'},
{value: 'sall3', id: 'SALL3'},
{value: 'sall4', id: 'SALL4'},
{value: 'samd1', id: 'SAMD1'},
{value: 'samd10', id: 'SAMD10'},
{value: 'samd11', id: 'SAMD11'},
{value: 'samd12', id: 'SAMD12'},
{value: 'samd13', id: 'SAMD13'},
{value: 'samd14', id: 'SAMD14'},
{value: 'samd15', id: 'SAMD15'},
{value: 'samd3', id: 'SAMD3'},
{value: 'samd4a', id: 'SAMD4A'},
{value: 'samd4b', id: 'SAMD4B'},
{value: 'samd5', id: 'SAMD5'},
{value: 'samd7', id: 'SAMD7'},
{value: 'samd8', id: 'SAMD8'},
{value: 'samd9', id: 'SAMD9'},
{value: 'samd9l', id: 'SAMD9L'},
{value: 'samhd1', id: 'SAMHD1'},
{value: 'samm50', id: 'SAMM50'},
{value: 'samsn1', id: 'SAMSN1'},
{value: 'samt3', id: 'SAMT3'},
{value: 'samt4', id: 'SAMT4'},
{value: 'sanbr', id: 'SANBR'},
{value: 'sap130', id: 'SAP130'},
{value: 'sap18', id: 'SAP18'},
{value: 'sap25', id: 'SAP25'},
{value: 'sap30', id: 'SAP30'},
{value: 'sap30bp', id: 'SAP30BP'},
{value: 'sap30l', id: 'SAP30L'},
{value: 'sapcd1', id: 'SAPCD1'},
{value: 'sapcd2', id: 'SAPCD2'},
{value: 'sar1a', id: 'SAR1A'},
{value: 'sar1b', id: 'SAR1B'},
{value: 'saraf', id: 'SARAF'},
{value: 'sardh', id: 'SARDH'},
{value: 'sarm1', id: 'SARM1'},
{value: 'sarnp', id: 'SARNP'},
{value: 'sars1', id: 'SARS1'},
{value: 'sars2', id: 'SARS2'},
{value: 'sart1', id: 'SART1'},
{value: 'sart3', id: 'SART3'},
{value: 'sash1', id: 'SASH1'},
{value: 'sash3', id: 'SASH3'},
{value: 'sass6', id: 'SASS6'},
{value: 'sat1', id: 'SAT1'},
{value: 'sat2', id: 'SAT2'},
{value: 'satb1', id: 'SATB1'},
{value: 'satb2', id: 'SATB2'},
{value: 'satl1', id: 'SATL1'},
{value: 'sav1', id: 'SAV1'},
{value: 'saxo1', id: 'SAXO1'},
{value: 'saxo2', id: 'SAXO2'},
{value: 'saxol1', id: 'SAXOL1'},
{value: 'saysd1', id: 'SAYSD1'},
{value: 'sbds', id: 'SBDS'},
{value: 'sbf1', id: 'SBF1'},
{value: 'sbf2', id: 'SBF2'},
{value: 'sbk1', id: 'SBK1'},
{value: 'sbk2', id: 'SBK2'},
{value: 'sbk3', id: 'SBK3'},
{value: 'sbno1', id: 'SBNO1'},
{value: 'sbno2', id: 'SBNO2'},
{value: 'sbp', id: 'SBP'},
{value: 'sbsn', id: 'SBSN'},
{value: 'sbspon', id: 'SBSPON'},
{value: 'sc5d', id: 'SC5D'},
{value: 'scaf1', id: 'SCAF1'},
{value: 'scaf11', id: 'SCAF11'},
{value: 'scaf4', id: 'SCAF4'},
{value: 'scaf8', id: 'SCAF8'},
{value: 'scai', id: 'SCAI'},
{value: 'scamp1', id: 'SCAMP1'},
{value: 'scamp2', id: 'SCAMP2'},
{value: 'scamp3', id: 'SCAMP3'},
{value: 'scamp4', id: 'SCAMP4'},
{value: 'scamp5', id: 'SCAMP5'},
{value: 'scand1', id: 'SCAND1'},
{value: 'scap', id: 'SCAP'},
{value: 'scaper', id: 'SCAPER'},
{value: 'scara3', id: 'SCARA3'},
{value: 'scara5', id: 'SCARA5'},
{value: 'scarb1', id: 'SCARB1'},
{value: 'scarb2', id: 'SCARB2'},
{value: 'scarf1', id: 'SCARF1'},
{value: 'scarf2', id: 'SCARF2'},
{value: 'scarna10', id: 'SCARNA10'},
{value: 'scarna15', id: 'SCARNA15'},
{value: 'scarna2', id: 'SCARNA2'},
{value: 'scarna6', id: 'SCARNA6'},
{value: 'scart1', id: 'SCART1'},
{value: 'sccpdh', id: 'SCCPDH'},
{value: 'scd', id: 'SCD'},
{value: 'scd2', id: 'SCD2'},
{value: 'scd4', id: 'SCD4'},
{value: 'scel', id: 'SCEL'},
{value: 'scfd1', id: 'SCFD1'},
{value: 'scfd2', id: 'SCFD2'},
{value: 'scg2', id: 'SCG2'},
{value: 'scg3', id: 'SCG3'},
{value: 'scg5', id: 'SCG5'},
{value: 'scgb1a1', id: 'SCGB1A1'},
{value: 'scgb1b24', id: 'SCGB1B24'},
{value: 'scgb1b30', id: 'SCGB1B30'},
{value: 'scgb1c1', id: 'SCGB1C1'},
{value: 'scgb1d2', id: 'SCGB1D2'},
{value: 'scgb1d4', id: 'SCGB1D4'},
{value: 'scgb2a1', id: 'SCGB2A1'},
{value: 'scgb2b2', id: 'SCGB2B2'},
{value: 'scgb2b24', id: 'SCGB2B24'},
{value: 'scgb3a1', id: 'SCGB3A1'},
{value: 'scgb3a2', id: 'SCGB3A2'},
{value: 'scgn', id: 'SCGN'},
{value: 'schip1', id: 'SCHIP1'},
{value: 'scimp', id: 'SCIMP'},
{value: 'scin', id: 'SCIN'},
{value: 'sclt1', id: 'SCLT1'},
{value: 'scly', id: 'SCLY'},
{value: 'scmh1', id: 'SCMH1'},
{value: 'scml2', id: 'SCML2'},
{value: 'scml4', id: 'SCML4'},
{value: 'scn10a', id: 'SCN10A'},
{value: 'scn11a', id: 'SCN11A'},
{value: 'scn1a', id: 'SCN1A'},
{value: 'scn1b', id: 'SCN1B'},
{value: 'scn2a', id: 'SCN2A'},
{value: 'scn2b', id: 'SCN2B'},
{value: 'scn3a', id: 'SCN3A'},
{value: 'scn3b', id: 'SCN3B'},
{value: 'scn4a', id: 'SCN4A'},
{value: 'scn4b', id: 'SCN4B'},
{value: 'scn5a', id: 'SCN5A'},
{value: 'scn7a', id: 'SCN7A'},
{value: 'scn8a', id: 'SCN8A'},
{value: 'scn9a', id: 'SCN9A'},
{value: 'scnm1', id: 'SCNM1'},
{value: 'scnn1a', id: 'SCNN1A'},
{value: 'scnn1b', id: 'SCNN1B'},
{value: 'scnn1g', id: 'SCNN1G'},
{value: 'sco1', id: 'SCO1'},
{value: 'scoc', id: 'SCOC'},
{value: 'scp2', id: 'SCP2'},
{value: 'scp2d1', id: 'SCP2D1'},
{value: 'scpep1', id: 'SCPEP1'},
{value: 'scrg1', id: 'SCRG1'},
{value: 'scrib', id: 'SCRIB'},
{value: 'scrn1', id: 'SCRN1'},
{value: 'scrn2', id: 'SCRN2'},
{value: 'scrn3', id: 'SCRN3'},
{value: 'scrt1', id: 'SCRT1'},
{value: 'scrt2', id: 'SCRT2'},
{value: 'sct', id: 'SCT'},
{value: 'sctr', id: 'SCTR'},
{value: 'scube1', id: 'SCUBE1'},
{value: 'scube2', id: 'SCUBE2'},
{value: 'scube3', id: 'SCUBE3'},
{value: 'scx', id: 'SCX'},
{value: 'scyl1', id: 'SCYL1'},
{value: 'scyl2', id: 'SCYL2'},
{value: 'scyl3', id: 'SCYL3'},
{value: 'sdad1', id: 'SDAD1'},
{value: 'sdc1', id: 'SDC1'},
{value: 'sdc2', id: 'SDC2'},
{value: 'sdc3', id: 'SDC3'},
{value: 'sdc4', id: 'SDC4'},
{value: 'sdcbp', id: 'SDCBP'},
{value: 'sdcbp2', id: 'SDCBP2'},
{value: 'sdccag8', id: 'SDCCAG8'},
{value: 'sde2', id: 'SDE2'},
{value: 'sdf2', id: 'SDF2'},
{value: 'sdf2l1', id: 'SDF2L1'},
{value: 'sdf4', id: 'SDF4'},
{value: 'sdha', id: 'SDHA'},
{value: 'sdhaf1', id: 'SDHAF1'},
{value: 'sdhaf2', id: 'SDHAF2'},
{value: 'sdhaf3', id: 'SDHAF3'},
{value: 'sdhaf4', id: 'SDHAF4'},
{value: 'sdhb', id: 'SDHB'},
{value: 'sdhc', id: 'SDHC'},
{value: 'sdhd', id: 'SDHD'},
{value: 'sdk1', id: 'SDK1'},
{value: 'sdk2', id: 'SDK2'},
{value: 'sdr16c5', id: 'SDR16C5'},
{value: 'sdr16c6', id: 'SDR16C6'},
{value: 'sdr39u1', id: 'SDR39U1'},
{value: 'sdr42e1', id: 'SDR42E1'},
{value: 'sdr9c7', id: 'SDR9C7'},
{value: 'sds', id: 'SDS'},
{value: 'sdsl', id: 'SDSL'},
{value: 'sebox', id: 'SEBOX'},
{value: 'sec1', id: 'SEC1'},
{value: 'sec11a', id: 'SEC11A'},
{value: 'sec11c', id: 'SEC11C'},
{value: 'sec13', id: 'SEC13'},
{value: 'sec14l1', id: 'SEC14L1'},
{value: 'sec14l2', id: 'SEC14L2'},
{value: 'sec14l3', id: 'SEC14L3'},
{value: 'sec14l4', id: 'SEC14L4'},
{value: 'sec14l5', id: 'SEC14L5'},
{value: 'sec16a', id: 'SEC16A'},
{value: 'sec16b', id: 'SEC16B'},
{value: 'sec22a', id: 'SEC22A'},
{value: 'sec22b', id: 'SEC22B'},
{value: 'sec22c', id: 'SEC22C'},
{value: 'sec23a', id: 'SEC23A'},
{value: 'sec23b', id: 'SEC23B'},
{value: 'sec23ip', id: 'SEC23IP'},
{value: 'sec24a', id: 'SEC24A'},
{value: 'sec24b', id: 'SEC24B'},
{value: 'sec24c', id: 'SEC24C'},
{value: 'sec24d', id: 'SEC24D'},
{value: 'sec31a', id: 'SEC31A'},
{value: 'sec31b', id: 'SEC31B'},
{value: 'sec61a1', id: 'SEC61A1'},
{value: 'sec61a2', id: 'SEC61A2'},
{value: 'sec61b', id: 'SEC61B'},
{value: 'sec61g', id: 'SEC61G'},
{value: 'sec62', id: 'SEC62'},
{value: 'sec63', id: 'SEC63'},
{value: 'secisbp2', id: 'SECISBP2'},
{value: 'secisbp2l', id: 'SECISBP2L'},
{value: 'sectm1a', id: 'SECTM1A'},
{value: 'sectm1b', id: 'SECTM1B'},
{value: 'seh1l', id: 'SEH1L'},
{value: 'sel1l', id: 'SEL1L'},
{value: 'sel1l2', id: 'SEL1L2'},
{value: 'sel1l3', id: 'SEL1L3'},
{value: 'sele', id: 'SELE'},
{value: 'selenbp1', id: 'SELENBP1'},
{value: 'selenof', id: 'SELENOF'},
{value: 'selenoh', id: 'SELENOH'},
{value: 'selenoi', id: 'SELENOI'},
{value: 'selenok', id: 'SELENOK'},
{value: 'selenom', id: 'SELENOM'},
{value: 'selenon', id: 'SELENON'},
{value: 'selenoo', id: 'SELENOO'},
{value: 'selenop', id: 'SELENOP'},
{value: 'selenos', id: 'SELENOS'},
{value: 'selenot', id: 'SELENOT'},
{value: 'selenov', id: 'SELENOV'},
{value: 'selenow', id: 'SELENOW'},
{value: 'sell', id: 'SELL'},
{value: 'selp', id: 'SELP'},
{value: 'selplg', id: 'SELPLG'},
{value: 'sem1', id: 'SEM1'},
{value: 'sema3a', id: 'SEMA3A'},
{value: 'sema3b', id: 'SEMA3B'},
{value: 'sema3c', id: 'SEMA3C'},
{value: 'sema3d', id: 'SEMA3D'},
{value: 'sema3e', id: 'SEMA3E'},
{value: 'sema3f', id: 'SEMA3F'},
{value: 'sema3g', id: 'SEMA3G'},
{value: 'sema4a', id: 'SEMA4A'},
{value: 'sema4b', id: 'SEMA4B'},
{value: 'sema4c', id: 'SEMA4C'},
{value: 'sema4d', id: 'SEMA4D'},
{value: 'sema4f', id: 'SEMA4F'},
{value: 'sema4g', id: 'SEMA4G'},
{value: 'sema5a', id: 'SEMA5A'},
{value: 'sema5b', id: 'SEMA5B'},
{value: 'sema6a', id: 'SEMA6A'},
{value: 'sema6b', id: 'SEMA6B'},
{value: 'sema6c', id: 'SEMA6C'},
{value: 'sema6d', id: 'SEMA6D'},
{value: 'sema7a', id: 'SEMA7A'},
{value: 'semg1', id: 'SEMG1'},
{value: 'senp1', id: 'SENP1'},
{value: 'senp17', id: 'SENP17'},
{value: 'senp18', id: 'SENP18'},
{value: 'senp2', id: 'SENP2'},
{value: 'senp3', id: 'SENP3'},
{value: 'senp5', id: 'SENP5'},
{value: 'senp6', id: 'SENP6'},
{value: 'senp7', id: 'SENP7'},
{value: 'senp8', id: 'SENP8'},
{value: 'sephs1', id: 'SEPHS1'},
{value: 'sephs2', id: 'SEPHS2'},
{value: 'sepsecs', id: 'SEPSECS'},
{value: 'septin1', id: 'SEPTIN1'},
{value: 'septin10', id: 'SEPTIN10'},
{value: 'septin11', id: 'SEPTIN11'},
{value: 'septin12', id: 'SEPTIN12'},
{value: 'septin14', id: 'SEPTIN14'},
{value: 'septin2', id: 'SEPTIN2'},
{value: 'septin3', id: 'SEPTIN3'},
{value: 'septin4', id: 'SEPTIN4'},
{value: 'septin5', id: 'SEPTIN5'},
{value: 'septin6', id: 'SEPTIN6'},
{value: 'septin7', id: 'SEPTIN7'},
{value: 'septin8', id: 'SEPTIN8'},
{value: 'septin9', id: 'SEPTIN9'},
{value: 'serac1', id: 'SERAC1'},
{value: 'serbp1', id: 'SERBP1'},
{value: 'serf1', id: 'SERF1'},
{value: 'serf2', id: 'SERF2'},
{value: 'sergef', id: 'SERGEF'},
{value: 'serhl2', id: 'SERHL2'},
{value: 'serinc1', id: 'SERINC1'},
{value: 'serinc2', id: 'SERINC2'},
{value: 'serinc3', id: 'SERINC3'},
{value: 'serinc4', id: 'SERINC4'},
{value: 'serinc5', id: 'SERINC5'},
{value: 'serp1', id: 'SERP1'},
{value: 'serp2', id: 'SERP2'},
{value: 'serpina1', id: 'SERPINA1'},
{value: 'serpina10', id: 'SERPINA10'},
{value: 'serpina11', id: 'SERPINA11'},
{value: 'serpina12', id: 'SERPINA12'},
{value: 'serpina16', id: 'SERPINA16'},
{value: 'serpina1f', id: 'SERPINA1F'},
{value: 'serpina3a', id: 'SERPINA3A'},
{value: 'serpina3c', id: 'SERPINA3C'},
{value: 'serpina3m', id: 'SERPINA3M'},
{value: 'serpina3n', id: 'SERPINA3N'},
{value: 'serpina4', id: 'SERPINA4'},
{value: 'serpina5', id: 'SERPINA5'},
{value: 'serpina6', id: 'SERPINA6'},
{value: 'serpina7', id: 'SERPINA7'},
{value: 'serpina9', id: 'SERPINA9'},
{value: 'serpinb10', id: 'SERPINB10'},
{value: 'serpinb11', id: 'SERPINB11'},
{value: 'serpinb12', id: 'SERPINB12'},
{value: 'serpinb13', id: 'SERPINB13'},
{value: 'serpinb1a', id: 'SERPINB1A'},
{value: 'serpinb1b', id: 'SERPINB1B'},
{value: 'serpinb2', id: 'SERPINB2'},
{value: 'serpinb3', id: 'SERPINB3'},
{value: 'serpinb3a', id: 'SERPINB3A'},
{value: 'serpinb5', id: 'SERPINB5'},
{value: 'serpinb6a', id: 'SERPINB6A'},
{value: 'serpinb6b', id: 'SERPINB6B'},
{value: 'serpinb6e', id: 'SERPINB6E'},
{value: 'serpinb7', id: 'SERPINB7'},
{value: 'serpinb8', id: 'SERPINB8'},
{value: 'serpinb9', id: 'SERPINB9'},
{value: 'serpinb9d', id: 'SERPINB9D'},
{value: 'serpinc1', id: 'SERPINC1'},
{value: 'serpind1', id: 'SERPIND1'},
{value: 'serpine1', id: 'SERPINE1'},
{value: 'serpine2', id: 'SERPINE2'},
{value: 'serpine3', id: 'SERPINE3'},
{value: 'serpinf1', id: 'SERPINF1'},
{value: 'serpinf2', id: 'SERPINF2'},
{value: 'serping1', id: 'SERPING1'},
{value: 'serpinh1', id: 'SERPINH1'},
{value: 'serpini1', id: 'SERPINI1'},
{value: 'serpini2', id: 'SERPINI2'},
{value: 'sertad1', id: 'SERTAD1'},
{value: 'sertad2', id: 'SERTAD2'},
{value: 'sertad3', id: 'SERTAD3'},
{value: 'sertad4', id: 'SERTAD4'},
{value: 'sertm1', id: 'SERTM1'},
{value: 'sesn1', id: 'SESN1'},
{value: 'sesn2', id: 'SESN2'},
{value: 'sesn3', id: 'SESN3'},
{value: 'sestd1', id: 'SESTD1'},
{value: 'set', id: 'SET'},
{value: 'setbp1', id: 'SETBP1'},
{value: 'setd1a', id: 'SETD1A'},
{value: 'setd1b', id: 'SETD1B'},
{value: 'setd2', id: 'SETD2'},
{value: 'setd3', id: 'SETD3'},
{value: 'setd4', id: 'SETD4'},
{value: 'setd5', id: 'SETD5'},
{value: 'setd6', id: 'SETD6'},
{value: 'setd7', id: 'SETD7'},
{value: 'setdb1', id: 'SETDB1'},
{value: 'setdb2', id: 'SETDB2'},
{value: 'setmar', id: 'SETMAR'},
{value: 'setsip', id: 'SETSIP'},
{value: 'setx', id: 'SETX'},
{value: 'sez6', id: 'SEZ6'},
{value: 'sez6l', id: 'SEZ6L'},
{value: 'sez6l2', id: 'SEZ6L2'},
{value: 'sf1', id: 'SF1'},
{value: 'sf3a1', id: 'SF3A1'},
{value: 'sf3a2', id: 'SF3A2'},
{value: 'sf3a3', id: 'SF3A3'},
{value: 'sf3b1', id: 'SF3B1'},
{value: 'sf3b2', id: 'SF3B2'},
{value: 'sf3b3', id: 'SF3B3'},
{value: 'sf3b4', id: 'SF3B4'},
{value: 'sf3b5', id: 'SF3B5'},
{value: 'sf3b6', id: 'SF3B6'},
{value: 'sfi1', id: 'SFI1'},
{value: 'sfmbt1', id: 'SFMBT1'},
{value: 'sfmbt2', id: 'SFMBT2'},
{value: 'sfn', id: 'SFN'},
{value: 'sfpq', id: 'SFPQ'},
{value: 'sfr1', id: 'SFR1'},
{value: 'sfrp1', id: 'SFRP1'},
{value: 'sfrp2', id: 'SFRP2'},
{value: 'sfrp4', id: 'SFRP4'},
{value: 'sfrp5', id: 'SFRP5'},
{value: 'sfswap', id: 'SFSWAP'},
{value: 'sft2d1', id: 'SFT2D1'},
{value: 'sft2d2', id: 'SFT2D2'},
{value: 'sft2d3', id: 'SFT2D3'},
{value: 'sfta2', id: 'SFTA2'},
{value: 'sftpa1', id: 'SFTPA1'},
{value: 'sftpb', id: 'SFTPB'},
{value: 'sftpc', id: 'SFTPC'},
{value: 'sftpd', id: 'SFTPD'},
{value: 'sfxn1', id: 'SFXN1'},
{value: 'sfxn2', id: 'SFXN2'},
{value: 'sfxn3', id: 'SFXN3'},
{value: 'sfxn4', id: 'SFXN4'},
{value: 'sfxn5', id: 'SFXN5'},
{value: 'sgca', id: 'SGCA'},
{value: 'sgcb', id: 'SGCB'},
{value: 'sgcd', id: 'SGCD'},
{value: 'sgce', id: 'SGCE'},
{value: 'sgcg', id: 'SGCG'},
{value: 'sgcz', id: 'SGCZ'},
{value: 'sgf29', id: 'SGF29'},
{value: 'sgip1', id: 'SGIP1'},
{value: 'sgk1', id: 'SGK1'},
{value: 'sgk2', id: 'SGK2'},
{value: 'sgk3', id: 'SGK3'},
{value: 'sgms1', id: 'SGMS1'},
{value: 'sgms2', id: 'SGMS2'},
{value: 'sgo1', id: 'SGO1'},
{value: 'sgo2', id: 'SGO2'},
{value: 'sgpl1', id: 'SGPL1'},
{value: 'sgpp1', id: 'SGPP1'},
{value: 'sgpp2', id: 'SGPP2'},
{value: 'sgsh', id: 'SGSH'},
{value: 'sgsm1', id: 'SGSM1'},
{value: 'sgsm2', id: 'SGSM2'},
{value: 'sgsm3', id: 'SGSM3'},
{value: 'sgta', id: 'SGTA'},
{value: 'sgtb', id: 'SGTB'},
{value: 'sh2b1', id: 'SH2B1'},
{value: 'sh2b2', id: 'SH2B2'},
{value: 'sh2b3', id: 'SH2B3'},
{value: 'sh2d1a', id: 'SH2D1A'},
{value: 'sh2d1b', id: 'SH2D1B'},
{value: 'sh2d1b2', id: 'SH2D1B2'},
{value: 'sh2d2a', id: 'SH2D2A'},
{value: 'sh2d3c', id: 'SH2D3C'},
{value: 'sh2d4a', id: 'SH2D4A'},
{value: 'sh2d4b', id: 'SH2D4B'},
{value: 'sh2d5', id: 'SH2D5'},
{value: 'sh2d6', id: 'SH2D6'},
{value: 'sh2d7', id: 'SH2D7'},
{value: 'sh3bgr', id: 'SH3BGR'},
{value: 'sh3bgrl', id: 'SH3BGRL'},
{value: 'sh3bgrl2', id: 'SH3BGRL2'},
{value: 'sh3bgrl3', id: 'SH3BGRL3'},
{value: 'sh3bp1', id: 'SH3BP1'},
{value: 'sh3bp2', id: 'SH3BP2'},
{value: 'sh3bp4', id: 'SH3BP4'},
{value: 'sh3bp5', id: 'SH3BP5'},
{value: 'sh3bp5l', id: 'SH3BP5L'},
{value: 'sh3d19', id: 'SH3D19'},
{value: 'sh3d21', id: 'SH3D21'},
{value: 'sh3gl1', id: 'SH3GL1'},
{value: 'sh3gl2', id: 'SH3GL2'},
{value: 'sh3gl3', id: 'SH3GL3'},
{value: 'sh3glb1', id: 'SH3GLB1'},
{value: 'sh3glb2', id: 'SH3GLB2'},
{value: 'sh3kbp1', id: 'SH3KBP1'},
{value: 'sh3pxd2a', id: 'SH3PXD2A'},
{value: 'sh3pxd2b', id: 'SH3PXD2B'},
{value: 'sh3rf1', id: 'SH3RF1'},
{value: 'sh3rf2', id: 'SH3RF2'},
{value: 'sh3rf3', id: 'SH3RF3'},
{value: 'sh3tc1', id: 'SH3TC1'},
{value: 'sh3tc2', id: 'SH3TC2'},
{value: 'sh3yl1', id: 'SH3YL1'},
{value: 'shank1', id: 'SHANK1'},
{value: 'shank2', id: 'SHANK2'},
{value: 'shank3', id: 'SHANK3'},
{value: 'sharpin', id: 'SHARPIN'},
{value: 'shb', id: 'SHB'},
{value: 'shbg', id: 'SHBG'},
{value: 'shc1', id: 'SHC1'},
{value: 'shc2', id: 'SHC2'},
{value: 'shc3', id: 'SHC3'},
{value: 'shc4', id: 'SHC4'},
{value: 'shcbp1', id: 'SHCBP1'},
{value: 'shcbp1l', id: 'SHCBP1L'},
{value: 'shd', id: 'SHD'},
{value: 'she', id: 'SHE'},
{value: 'shf', id: 'SHF'},
{value: 'shfl', id: 'SHFL'},
{value: 'shh', id: 'SHH'},
{value: 'shisa2', id: 'SHISA2'},
{value: 'shisa3', id: 'SHISA3'},
{value: 'shisa4', id: 'SHISA4'},
{value: 'shisa5', id: 'SHISA5'},
{value: 'shisa6', id: 'SHISA6'},
{value: 'shisa7', id: 'SHISA7'},
{value: 'shisa8', id: 'SHISA8'},
{value: 'shisa9', id: 'SHISA9'},
{value: 'shisal1', id: 'SHISAL1'},
{value: 'shisal2a', id: 'SHISAL2A'},
{value: 'shisal2b', id: 'SHISAL2B'},
{value: 'shkbp1', id: 'SHKBP1'},
{value: 'shld1', id: 'SHLD1'},
{value: 'shld2', id: 'SHLD2'},
{value: 'shmt1', id: 'SHMT1'},
{value: 'shmt2', id: 'SHMT2'},
{value: 'shoc1', id: 'SHOC1'},
{value: 'shoc2', id: 'SHOC2'},
{value: 'shox2', id: 'SHOX2'},
{value: 'shpk', id: 'SHPK'},
{value: 'shprh', id: 'SHPRH'},
{value: 'shq1', id: 'SHQ1'},
{value: 'shroom1', id: 'SHROOM1'},
{value: 'shroom2', id: 'SHROOM2'},
{value: 'shroom3', id: 'SHROOM3'},
{value: 'shroom4', id: 'SHROOM4'},
{value: 'shtn1', id: 'SHTN1'},
{value: 'si', id: 'SI'},
{value: 'siae', id: 'SIAE'},
{value: 'siah1', id: 'SIAH1'},
{value: 'siah2', id: 'SIAH2'},
{value: 'siah3', id: 'SIAH3'},
{value: 'sidt1', id: 'SIDT1'},
{value: 'sidt2', id: 'SIDT2'},
{value: 'sigirr', id: 'SIGIRR'},
{value: 'siglec1', id: 'SIGLEC1'},
{value: 'siglec10', id: 'SIGLEC10'},
{value: 'siglec15', id: 'SIGLEC15'},
{value: 'siglec5', id: 'SIGLEC5'},
{value: 'siglec8', id: 'SIGLEC8'},
{value: 'siglech', id: 'SIGLECH'},
{value: 'siglecl1', id: 'SIGLECL1'},
{value: 'sigmar1', id: 'SIGMAR1'},
{value: 'sik1', id: 'SIK1'},
{value: 'sik2', id: 'SIK2'},
{value: 'sik3', id: 'SIK3'},
{value: 'sike1', id: 'SIKE1'},
{value: 'sil1', id: 'SIL1'},
{value: 'sim1', id: 'SIM1'},
{value: 'sim2', id: 'SIM2'},
{value: 'simc1', id: 'SIMC1'},
{value: 'sin3a', id: 'SIN3A'},
{value: 'sin3b', id: 'SIN3B'},
{value: 'sinhcaf', id: 'SINHCAF'},
{value: 'sipa1', id: 'SIPA1'},
{value: 'sipa1l1', id: 'SIPA1L1'},
{value: 'sipa1l2', id: 'SIPA1L2'},
{value: 'sipa1l3', id: 'SIPA1L3'},
{value: 'sirpa', id: 'SIRPA'},
{value: 'sirpb3', id: 'SIRPB3'},
{value: 'sirpd', id: 'SIRPD'},
{value: 'sirt1', id: 'SIRT1'},
{value: 'sirt2', id: 'SIRT2'},
{value: 'sirt3', id: 'SIRT3'},
{value: 'sirt4', id: 'SIRT4'},
{value: 'sirt5', id: 'SIRT5'},
{value: 'sirt6', id: 'SIRT6'},
{value: 'sirt7', id: 'SIRT7'},
{value: 'sit1', id: 'SIT1'},
{value: 'siva1', id: 'SIVA1'},
{value: 'six1', id: 'SIX1'},
{value: 'six2', id: 'SIX2'},
{value: 'six3', id: 'SIX3'},
{value: 'six4', id: 'SIX4'},
{value: 'six5', id: 'SIX5'},
{value: 'six6', id: 'SIX6'},
{value: 'six6os1', id: 'SIX6OS1'},
{value: 'ska1', id: 'SKA1'},
{value: 'ska2', id: 'SKA2'},
{value: 'ska3', id: 'SKA3'},
{value: 'skap1', id: 'SKAP1'},
{value: 'skap2', id: 'SKAP2'},
{value: 'ski', id: 'SKI'},
{value: 'skida1', id: 'SKIDA1'},
{value: 'skil', id: 'SKIL'},
{value: 'skint1', id: 'SKINT1'},
{value: 'skint10', id: 'SKINT10'},
{value: 'skint8', id: 'SKINT8'},
{value: 'skiv2l', id: 'SKIV2L'},
{value: 'skor1', id: 'SKOR1'},
{value: 'skor2', id: 'SKOR2'},
{value: 'skp1', id: 'SKP1'},
{value: 'skp2', id: 'SKP2'},
{value: 'sla', id: 'SLA'},
{value: 'sla2', id: 'SLA2'},
{value: 'slain1', id: 'SLAIN1'},
{value: 'slain2', id: 'SLAIN2'},
{value: 'slamf1', id: 'SLAMF1'},
{value: 'slamf6', id: 'SLAMF6'},
{value: 'slamf7', id: 'SLAMF7'},
{value: 'slamf8', id: 'SLAMF8'},
{value: 'slamf9', id: 'SLAMF9'},
{value: 'slbp', id: 'SLBP'},
{value: 'slc10a1', id: 'SLC10A1'},
{value: 'slc10a2', id: 'SLC10A2'},
{value: 'slc10a3', id: 'SLC10A3'},
{value: 'slc10a4', id: 'SLC10A4'},
{value: 'slc10a5', id: 'SLC10A5'},
{value: 'slc10a6', id: 'SLC10A6'},
{value: 'slc10a7', id: 'SLC10A7'},
{value: 'slc11a1', id: 'SLC11A1'},
{value: 'slc11a2', id: 'SLC11A2'},
{value: 'slc12a1', id: 'SLC12A1'},
{value: 'slc12a2', id: 'SLC12A2'},
{value: 'slc12a3', id: 'SLC12A3'},
{value: 'slc12a4', id: 'SLC12A4'},
{value: 'slc12a5', id: 'SLC12A5'},
{value: 'slc12a6', id: 'SLC12A6'},
{value: 'slc12a7', id: 'SLC12A7'},
{value: 'slc12a8', id: 'SLC12A8'},
{value: 'slc12a9', id: 'SLC12A9'},
{value: 'slc13a1', id: 'SLC13A1'},
{value: 'slc13a2', id: 'SLC13A2'},
{value: 'slc13a3', id: 'SLC13A3'},
{value: 'slc13a4', id: 'SLC13A4'},
{value: 'slc13a5', id: 'SLC13A5'},
{value: 'slc14a1', id: 'SLC14A1'},
{value: 'slc14a2', id: 'SLC14A2'},
{value: 'slc15a1', id: 'SLC15A1'},
{value: 'slc15a2', id: 'SLC15A2'},
{value: 'slc15a3', id: 'SLC15A3'},
{value: 'slc15a4', id: 'SLC15A4'},
{value: 'slc15a5', id: 'SLC15A5'},
{value: 'slc16a1', id: 'SLC16A1'},
{value: 'slc16a10', id: 'SLC16A10'},
{value: 'slc16a11', id: 'SLC16A11'},
{value: 'slc16a12', id: 'SLC16A12'},
{value: 'slc16a13', id: 'SLC16A13'},
{value: 'slc16a14', id: 'SLC16A14'},
{value: 'slc16a2', id: 'SLC16A2'},
{value: 'slc16a3', id: 'SLC16A3'},
{value: 'slc16a4', id: 'SLC16A4'},
{value: 'slc16a5', id: 'SLC16A5'},
{value: 'slc16a6', id: 'SLC16A6'},
{value: 'slc16a7', id: 'SLC16A7'},
{value: 'slc16a8', id: 'SLC16A8'},
{value: 'slc16a9', id: 'SLC16A9'},
{value: 'slc17a1', id: 'SLC17A1'},
{value: 'slc17a2', id: 'SLC17A2'},
{value: 'slc17a3', id: 'SLC17A3'},
{value: 'slc17a4', id: 'SLC17A4'},
{value: 'slc17a5', id: 'SLC17A5'},
{value: 'slc17a6', id: 'SLC17A6'},
{value: 'slc17a7', id: 'SLC17A7'},
{value: 'slc17a8', id: 'SLC17A8'},
{value: 'slc17a9', id: 'SLC17A9'},
{value: 'slc18a1', id: 'SLC18A1'},
{value: 'slc18a2', id: 'SLC18A2'},
{value: 'slc18a3', id: 'SLC18A3'},
{value: 'slc18b1', id: 'SLC18B1'},
{value: 'slc19a1', id: 'SLC19A1'},
{value: 'slc19a2', id: 'SLC19A2'},
{value: 'slc19a3', id: 'SLC19A3'},
{value: 'slc1a1', id: 'SLC1A1'},
{value: 'slc1a2', id: 'SLC1A2'},
{value: 'slc1a3', id: 'SLC1A3'},
{value: 'slc1a4', id: 'SLC1A4'},
{value: 'slc1a5', id: 'SLC1A5'},
{value: 'slc1a6', id: 'SLC1A6'},
{value: 'slc1a7', id: 'SLC1A7'},
{value: 'slc20a1', id: 'SLC20A1'},
{value: 'slc20a2', id: 'SLC20A2'},
{value: 'slc22a1', id: 'SLC22A1'},
{value: 'slc22a12', id: 'SLC22A12'},
{value: 'slc22a13', id: 'SLC22A13'},
{value: 'slc22a13l1', id: 'SLC22A13L1'},
{value: 'slc22a15', id: 'SLC22A15'},
{value: 'slc22a17', id: 'SLC22A17'},
{value: 'slc22a18', id: 'SLC22A18'},
{value: 'slc22a2', id: 'SLC22A2'},
{value: 'slc22a20', id: 'SLC22A20'},
{value: 'slc22a22', id: 'SLC22A22'},
{value: 'slc22a23', id: 'SLC22A23'},
{value: 'slc22a24', id: 'SLC22A24'},
{value: 'slc22a25', id: 'SLC22A25'},
{value: 'slc22a3', id: 'SLC22A3'},
{value: 'slc22a4', id: 'SLC22A4'},
{value: 'slc22a5', id: 'SLC22A5'},
{value: 'slc22a6', id: 'SLC22A6'},
{value: 'slc22a7', id: 'SLC22A7'},
{value: 'slc22a8', id: 'SLC22A8'},
{value: 'slc23a1', id: 'SLC23A1'},
{value: 'slc23a2', id: 'SLC23A2'},
{value: 'slc23a3', id: 'SLC23A3'},
{value: 'slc24a1', id: 'SLC24A1'},
{value: 'slc24a2', id: 'SLC24A2'},
{value: 'slc24a3', id: 'SLC24A3'},
{value: 'slc24a4', id: 'SLC24A4'},
{value: 'slc24a5', id: 'SLC24A5'},
{value: 'slc25a1', id: 'SLC25A1'},
{value: 'slc25a10', id: 'SLC25A10'},
{value: 'slc25a11', id: 'SLC25A11'},
{value: 'slc25a12', id: 'SLC25A12'},
{value: 'slc25a13', id: 'SLC25A13'},
{value: 'slc25a14', id: 'SLC25A14'},
{value: 'slc25a15', id: 'SLC25A15'},
{value: 'slc25a16', id: 'SLC25A16'},
{value: 'slc25a17', id: 'SLC25A17'},
{value: 'slc25a18', id: 'SLC25A18'},
{value: 'slc25a19', id: 'SLC25A19'},
{value: 'slc25a2', id: 'SLC25A2'},
{value: 'slc25a20', id: 'SLC25A20'},
{value: 'slc25a21', id: 'SLC25A21'},
{value: 'slc25a22', id: 'SLC25A22'},
{value: 'slc25a23', id: 'SLC25A23'},
{value: 'slc25a24', id: 'SLC25A24'},
{value: 'slc25a25', id: 'SLC25A25'},
{value: 'slc25a26', id: 'SLC25A26'},
{value: 'slc25a27', id: 'SLC25A27'},
{value: 'slc25a28', id: 'SLC25A28'},
{value: 'slc25a29', id: 'SLC25A29'},
{value: 'slc25a3', id: 'SLC25A3'},
{value: 'slc25a30', id: 'SLC25A30'},
{value: 'slc25a31', id: 'SLC25A31'},
{value: 'slc25a32', id: 'SLC25A32'},
{value: 'slc25a33', id: 'SLC25A33'},
{value: 'slc25a34', id: 'SLC25A34'},
{value: 'slc25a35', id: 'SLC25A35'},
{value: 'slc25a36', id: 'SLC25A36'},
{value: 'slc25a36l1', id: 'SLC25A36L1'},
{value: 'slc25a37', id: 'SLC25A37'},
{value: 'slc25a38', id: 'SLC25A38'},
{value: 'slc25a39', id: 'SLC25A39'},
{value: 'slc25a4', id: 'SLC25A4'},
{value: 'slc25a40', id: 'SLC25A40'},
{value: 'slc25a41', id: 'SLC25A41'},
{value: 'slc25a42', id: 'SLC25A42'},
{value: 'slc25a43', id: 'SLC25A43'},
{value: 'slc25a44', id: 'SLC25A44'},
{value: 'slc25a45', id: 'SLC25A45'},
{value: 'slc25a46', id: 'SLC25A46'},
{value: 'slc25a47', id: 'SLC25A47'},
{value: 'slc25a48', id: 'SLC25A48'},
{value: 'slc25a5', id: 'SLC25A5'},
{value: 'slc25a51', id: 'SLC25A51'},
{value: 'slc25a52', id: 'SLC25A52'},
{value: 'slc25a53', id: 'SLC25A53'},
{value: 'slc25a54', id: 'SLC25A54'},
{value: 'slc25a6', id: 'SLC25A6'},
{value: 'slc26a1', id: 'SLC26A1'},
{value: 'slc26a10', id: 'SLC26A10'},
{value: 'slc26a11', id: 'SLC26A11'},
{value: 'slc26a2', id: 'SLC26A2'},
{value: 'slc26a3', id: 'SLC26A3'},
{value: 'slc26a4', id: 'SLC26A4'},
{value: 'slc26a5', id: 'SLC26A5'},
{value: 'slc26a6', id: 'SLC26A6'},
{value: 'slc26a7', id: 'SLC26A7'},
{value: 'slc26a8', id: 'SLC26A8'},
{value: 'slc26a9', id: 'SLC26A9'},
{value: 'slc27a1', id: 'SLC27A1'},
{value: 'slc27a2', id: 'SLC27A2'},
{value: 'slc27a3', id: 'SLC27A3'},
{value: 'slc27a4', id: 'SLC27A4'},
{value: 'slc27a5', id: 'SLC27A5'},
{value: 'slc27a6', id: 'SLC27A6'},
{value: 'slc28a1', id: 'SLC28A1'},
{value: 'slc28a2', id: 'SLC28A2'},
{value: 'slc28a3', id: 'SLC28A3'},
{value: 'slc29a1', id: 'SLC29A1'},
{value: 'slc29a2', id: 'SLC29A2'},
{value: 'slc29a3', id: 'SLC29A3'},
{value: 'slc29a4', id: 'SLC29A4'},
{value: 'slc2a1', id: 'SLC2A1'},
{value: 'slc2a10', id: 'SLC2A10'},
{value: 'slc2a12', id: 'SLC2A12'},
{value: 'slc2a13', id: 'SLC2A13'},
{value: 'slc2a2', id: 'SLC2A2'},
{value: 'slc2a3', id: 'SLC2A3'},
{value: 'slc2a4', id: 'SLC2A4'},
{value: 'slc2a5', id: 'SLC2A5'},
{value: 'slc2a6', id: 'SLC2A6'},
{value: 'slc2a7', id: 'SLC2A7'},
{value: 'slc2a8', id: 'SLC2A8'},
{value: 'slc2a9', id: 'SLC2A9'},
{value: 'slc30a1', id: 'SLC30A1'},
{value: 'slc30a10', id: 'SLC30A10'},
{value: 'slc30a2', id: 'SLC30A2'},
{value: 'slc30a3', id: 'SLC30A3'},
{value: 'slc30a4', id: 'SLC30A4'},
{value: 'slc30a5', id: 'SLC30A5'},
{value: 'slc30a6', id: 'SLC30A6'},
{value: 'slc30a7', id: 'SLC30A7'},
{value: 'slc30a8', id: 'SLC30A8'},
{value: 'slc30a9', id: 'SLC30A9'},
{value: 'slc31a1', id: 'SLC31A1'},
{value: 'slc31a2', id: 'SLC31A2'},
{value: 'slc32a1', id: 'SLC32A1'},
{value: 'slc33a1', id: 'SLC33A1'},
{value: 'slc34a1', id: 'SLC34A1'},
{value: 'slc34a2', id: 'SLC34A2'},
{value: 'slc34a3', id: 'SLC34A3'},
{value: 'slc35a1', id: 'SLC35A1'},
{value: 'slc35a2', id: 'SLC35A2'},
{value: 'slc35a3', id: 'SLC35A3'},
{value: 'slc35a4', id: 'SLC35A4'},
{value: 'slc35a5', id: 'SLC35A5'},
{value: 'slc35b1', id: 'SLC35B1'},
{value: 'slc35b2', id: 'SLC35B2'},
{value: 'slc35b3', id: 'SLC35B3'},
{value: 'slc35b4', id: 'SLC35B4'},
{value: 'slc35c1', id: 'SLC35C1'},
{value: 'slc35c2', id: 'SLC35C2'},
{value: 'slc35d1', id: 'SLC35D1'},
{value: 'slc35d2', id: 'SLC35D2'},
{value: 'slc35d3', id: 'SLC35D3'},
{value: 'slc35e1', id: 'SLC35E1'},
{value: 'slc35e2b', id: 'SLC35E2B'},
{value: 'slc35e3', id: 'SLC35E3'},
{value: 'slc35e4', id: 'SLC35E4'},
{value: 'slc35f1', id: 'SLC35F1'},
{value: 'slc35f2', id: 'SLC35F2'},
{value: 'slc35f3', id: 'SLC35F3'},
{value: 'slc35f4', id: 'SLC35F4'},
{value: 'slc35f5', id: 'SLC35F5'},
{value: 'slc35f6', id: 'SLC35F6'},
{value: 'slc35g1', id: 'SLC35G1'},
{value: 'slc35g2', id: 'SLC35G2'},
{value: 'slc35g3', id: 'SLC35G3'},
{value: 'slc36a1', id: 'SLC36A1'},
{value: 'slc36a2', id: 'SLC36A2'},
{value: 'slc36a3', id: 'SLC36A3'},
{value: 'slc36a4', id: 'SLC36A4'},
{value: 'slc37a1', id: 'SLC37A1'},
{value: 'slc37a2', id: 'SLC37A2'},
{value: 'slc37a3', id: 'SLC37A3'},
{value: 'slc37a4', id: 'SLC37A4'},
{value: 'slc38a1', id: 'SLC38A1'},
{value: 'slc38a10', id: 'SLC38A10'},
{value: 'slc38a11', id: 'SLC38A11'},
{value: 'slc38a2', id: 'SLC38A2'},
{value: 'slc38a3', id: 'SLC38A3'},
{value: 'slc38a4', id: 'SLC38A4'},
{value: 'slc38a5', id: 'SLC38A5'},
{value: 'slc38a6', id: 'SLC38A6'},
{value: 'slc38a7', id: 'SLC38A7'},
{value: 'slc38a8', id: 'SLC38A8'},
{value: 'slc38a9', id: 'SLC38A9'},
{value: 'slc39a1', id: 'SLC39A1'},
{value: 'slc39a10', id: 'SLC39A10'},
{value: 'slc39a11', id: 'SLC39A11'},
{value: 'slc39a12', id: 'SLC39A12'},
{value: 'slc39a13', id: 'SLC39A13'},
{value: 'slc39a14', id: 'SLC39A14'},
{value: 'slc39a2', id: 'SLC39A2'},
{value: 'slc39a3', id: 'SLC39A3'},
{value: 'slc39a4', id: 'SLC39A4'},
{value: 'slc39a5', id: 'SLC39A5'},
{value: 'slc39a6', id: 'SLC39A6'},
{value: 'slc39a7', id: 'SLC39A7'},
{value: 'slc39a8', id: 'SLC39A8'},
{value: 'slc39a9', id: 'SLC39A9'},
{value: 'slc3a1', id: 'SLC3A1'},
{value: 'slc3a2', id: 'SLC3A2'},
{value: 'slc40a1', id: 'SLC40A1'},
{value: 'slc41a1', id: 'SLC41A1'},
{value: 'slc41a2', id: 'SLC41A2'},
{value: 'slc41a3', id: 'SLC41A3'},
{value: 'slc43a1', id: 'SLC43A1'},
{value: 'slc43a2', id: 'SLC43A2'},
{value: 'slc43a3', id: 'SLC43A3'},
{value: 'slc44a1', id: 'SLC44A1'},
{value: 'slc44a2', id: 'SLC44A2'},
{value: 'slc44a3', id: 'SLC44A3'},
{value: 'slc44a4', id: 'SLC44A4'},
{value: 'slc44a5', id: 'SLC44A5'},
{value: 'slc45a1', id: 'SLC45A1'},
{value: 'slc45a2', id: 'SLC45A2'},
{value: 'slc45a3', id: 'SLC45A3'},
{value: 'slc45a4', id: 'SLC45A4'},
{value: 'slc46a1', id: 'SLC46A1'},
{value: 'slc46a2', id: 'SLC46A2'},
{value: 'slc46a3', id: 'SLC46A3'},
{value: 'slc47a1', id: 'SLC47A1'},
{value: 'slc47a2', id: 'SLC47A2'},
{value: 'slc48a1', id: 'SLC48A1'},
{value: 'slc49a3', id: 'SLC49A3'},
{value: 'slc49a4', id: 'SLC49A4'},
{value: 'slc4a1', id: 'SLC4A1'},
{value: 'slc4a10', id: 'SLC4A10'},
{value: 'slc4a11', id: 'SLC4A11'},
{value: 'slc4a1ap', id: 'SLC4A1AP'},
{value: 'slc4a2', id: 'SLC4A2'},
{value: 'slc4a3', id: 'SLC4A3'},
{value: 'slc4a4', id: 'SLC4A4'},
{value: 'slc4a5', id: 'SLC4A5'},
{value: 'slc4a7', id: 'SLC4A7'},
{value: 'slc4a8', id: 'SLC4A8'},
{value: 'slc4a9', id: 'SLC4A9'},
{value: 'slc50a1', id: 'SLC50A1'},
{value: 'slc51a', id: 'SLC51A'},
{value: 'slc51b', id: 'SLC51B'},
{value: 'slc52a2', id: 'SLC52A2'},
{value: 'slc52a3', id: 'SLC52A3'},
{value: 'slc5a1', id: 'SLC5A1'},
{value: 'slc5a10', id: 'SLC5A10'},
{value: 'slc5a11', id: 'SLC5A11'},
{value: 'slc5a12', id: 'SLC5A12'},
{value: 'slc5a2', id: 'SLC5A2'},
{value: 'slc5a3', id: 'SLC5A3'},
{value: 'slc5a4', id: 'SLC5A4'},
{value: 'slc5a4b', id: 'SLC5A4B'},
{value: 'slc5a5', id: 'SLC5A5'},
{value: 'slc5a6', id: 'SLC5A6'},
{value: 'slc5a7', id: 'SLC5A7'},
{value: 'slc5a8', id: 'SLC5A8'},
{value: 'slc5a9', id: 'SLC5A9'},
{value: 'slc66a1', id: 'SLC66A1'},
{value: 'slc66a2', id: 'SLC66A2'},
{value: 'slc66a3', id: 'SLC66A3'},
{value: 'slc6a1', id: 'SLC6A1'},
{value: 'slc6a11', id: 'SLC6A11'},
{value: 'slc6a12', id: 'SLC6A12'},
{value: 'slc6a13', id: 'SLC6A13'},
{value: 'slc6a14', id: 'SLC6A14'},
{value: 'slc6a15', id: 'SLC6A15'},
{value: 'slc6a16', id: 'SLC6A16'},
{value: 'slc6a17', id: 'SLC6A17'},
{value: 'slc6a18', id: 'SLC6A18'},
{value: 'slc6a19', id: 'SLC6A19'},
{value: 'slc6a2', id: 'SLC6A2'},
{value: 'slc6a20', id: 'SLC6A20'},
{value: 'slc6a3', id: 'SLC6A3'},
{value: 'slc6a4', id: 'SLC6A4'},
{value: 'slc6a5', id: 'SLC6A5'},
{value: 'slc6a6', id: 'SLC6A6'},
{value: 'slc6a7', id: 'SLC6A7'},
{value: 'slc6a8', id: 'SLC6A8'},
{value: 'slc6a9', id: 'SLC6A9'},
{value: 'slc7a1', id: 'SLC7A1'},
{value: 'slc7a10', id: 'SLC7A10'},
{value: 'slc7a11', id: 'SLC7A11'},
{value: 'slc7a12', id: 'SLC7A12'},
{value: 'slc7a13', id: 'SLC7A13'},
{value: 'slc7a14', id: 'SLC7A14'},
{value: 'slc7a15', id: 'SLC7A15'},
{value: 'slc7a2', id: 'SLC7A2'},
{value: 'slc7a3', id: 'SLC7A3'},
{value: 'slc7a4', id: 'SLC7A4'},
{value: 'slc7a5', id: 'SLC7A5'},
{value: 'slc7a6', id: 'SLC7A6'},
{value: 'slc7a6os', id: 'SLC7A6OS'},
{value: 'slc7a7', id: 'SLC7A7'},
{value: 'slc7a8', id: 'SLC7A8'},
{value: 'slc7a9', id: 'SLC7A9'},
{value: 'slc8a1', id: 'SLC8A1'},
{value: 'slc8a2', id: 'SLC8A2'},
{value: 'slc8a3', id: 'SLC8A3'},
{value: 'slc8b1', id: 'SLC8B1'},
{value: 'slc9a1', id: 'SLC9A1'},
{value: 'slc9a2', id: 'SLC9A2'},
{value: 'slc9a3', id: 'SLC9A3'},
{value: 'slc9a3r1', id: 'SLC9A3R1'},
{value: 'slc9a3r2', id: 'SLC9A3R2'},
{value: 'slc9a4', id: 'SLC9A4'},
{value: 'slc9a5', id: 'SLC9A5'},
{value: 'slc9a6', id: 'SLC9A6'},
{value: 'slc9a7', id: 'SLC9A7'},
{value: 'slc9a8', id: 'SLC9A8'},
{value: 'slc9a9', id: 'SLC9A9'},
{value: 'slc9b1', id: 'SLC9B1'},
{value: 'slc9b2', id: 'SLC9B2'},
{value: 'slc9c1', id: 'SLC9C1'},
{value: 'slc9c2', id: 'SLC9C2'},
{value: 'slco1a1', id: 'SLCO1A1'},
{value: 'slco1a3', id: 'SLCO1A3'},
{value: 'slco1a4', id: 'SLCO1A4'},
{value: 'slco1a5', id: 'SLCO1A5'},
{value: 'slco1a6', id: 'SLCO1A6'},
{value: 'slco1b2', id: 'SLCO1B2'},
{value: 'slco1c1', id: 'SLCO1C1'},
{value: 'slco2a1', id: 'SLCO2A1'},
{value: 'slco2b1', id: 'SLCO2B1'},
{value: 'slco3a1', id: 'SLCO3A1'},
{value: 'slco4a1', id: 'SLCO4A1'},
{value: 'slco4c1', id: 'SLCO4C1'},
{value: 'slco5a1', id: 'SLCO5A1'},
{value: 'slco6b1', id: 'SLCO6B1'},
{value: 'slco6c1', id: 'SLCO6C1'},
{value: 'slco6d1', id: 'SLCO6D1'},
{value: 'slf1', id: 'SLF1'},
{value: 'slf2', id: 'SLF2'},
{value: 'slfn1', id: 'SLFN1'},
{value: 'slfn13', id: 'SLFN13'},
{value: 'slfn14', id: 'SLFN14'},
{value: 'slfn2', id: 'SLFN2'},
{value: 'slfn4', id: 'SLFN4'},
{value: 'slfn5', id: 'SLFN5'},
{value: 'slfnl1', id: 'SLFNL1'},
{value: 'slirp', id: 'SLIRP'},
{value: 'slit1', id: 'SLIT1'},
{value: 'slit2', id: 'SLIT2'},
{value: 'slit3', id: 'SLIT3'},
{value: 'slitrk1', id: 'SLITRK1'},
{value: 'slitrk2', id: 'SLITRK2'},
{value: 'slitrk3', id: 'SLITRK3'},
{value: 'slitrk4', id: 'SLITRK4'},
{value: 'slitrk5', id: 'SLITRK5'},
{value: 'slitrk6', id: 'SLITRK6'},
{value: 'slk', id: 'SLK'},
{value: 'slmap', id: 'SLMAP'},
{value: 'sln', id: 'SLN'},
{value: 'slpi', id: 'SLPI'},
{value: 'slpil2', id: 'SLPIL2'},
{value: 'slpil3', id: 'SLPIL3'},
{value: 'sltm', id: 'SLTM'},
{value: 'slu7', id: 'SLU7'},
{value: 'slurp1', id: 'SLURP1'},
{value: 'slurp2', id: 'SLURP2'},
{value: 'slx1b', id: 'SLX1B'},
{value: 'slx4', id: 'SLX4'},
{value: 'slx4ip', id: 'SLX4IP'},
{value: 'slx9', id: 'SLX9'},
{value: 'smad1', id: 'SMAD1'},
{value: 'smad2', id: 'SMAD2'},
{value: 'smad3', id: 'SMAD3'},
{value: 'smad4', id: 'SMAD4'},
{value: 'smad5', id: 'SMAD5'},
{value: 'smad6', id: 'SMAD6'},
{value: 'smad7', id: 'SMAD7'},
{value: 'smad9', id: 'SMAD9'},
{value: 'smagp', id: 'SMAGP'},
{value: 'smap1', id: 'SMAP1'},
{value: 'smap2', id: 'SMAP2'},
{value: 'smarca1', id: 'SMARCA1'},
{value: 'smarca2', id: 'SMARCA2'},
{value: 'smarca4', id: 'SMARCA4'},
{value: 'smarca5', id: 'SMARCA5'},
{value: 'smarcad1', id: 'SMARCAD1'},
{value: 'smarcal1', id: 'SMARCAL1'},
{value: 'smarcb1', id: 'SMARCB1'},
{value: 'smarcc1', id: 'SMARCC1'},
{value: 'smarcc2', id: 'SMARCC2'},
{value: 'smarcd1', id: 'SMARCD1'},
{value: 'smarcd2', id: 'SMARCD2'},
{value: 'smarcd3', id: 'SMARCD3'},
{value: 'smarce1', id: 'SMARCE1'},
{value: 'smc1a', id: 'SMC1A'},
{value: 'smc1b', id: 'SMC1B'},
{value: 'smc2', id: 'SMC2'},
{value: 'smc3', id: 'SMC3'},
{value: 'smc4', id: 'SMC4'},
{value: 'smc5', id: 'SMC5'},
{value: 'smc6', id: 'SMC6'},
{value: 'smchd1', id: 'SMCHD1'},
{value: 'smco1', id: 'SMCO1'},
{value: 'smco2', id: 'SMCO2'},
{value: 'smco3', id: 'SMCO3'},
{value: 'smco4', id: 'SMCO4'},
{value: 'smcp', id: 'SMCP'},
{value: 'smcr8', id: 'SMCR8'},
{value: 'smdt1', id: 'SMDT1'},
{value: 'smg1', id: 'SMG1'},
{value: 'smg5', id: 'SMG5'},
{value: 'smg6', id: 'SMG6'},
{value: 'smg7', id: 'SMG7'},
{value: 'smg8', id: 'SMG8'},
{value: 'smg9', id: 'SMG9'},
{value: 'smgc', id: 'SMGC'},
{value: 'smim1', id: 'SMIM1'},
{value: 'smim10l1', id: 'SMIM10L1'},
{value: 'smim11', id: 'SMIM11'},
{value: 'smim12', id: 'SMIM12'},
{value: 'smim13', id: 'SMIM13'},
{value: 'smim14', id: 'SMIM14'},
{value: 'smim15', id: 'SMIM15'},
{value: 'smim17', id: 'SMIM17'},
{value: 'smim18', id: 'SMIM18'},
{value: 'smim19', id: 'SMIM19'},
{value: 'smim20', id: 'SMIM20'},
{value: 'smim22', id: 'SMIM22'},
{value: 'smim23', id: 'SMIM23'},
{value: 'smim24', id: 'SMIM24'},
{value: 'smim26', id: 'SMIM26'},
{value: 'smim29', id: 'SMIM29'},
{value: 'smim3', id: 'SMIM3'},
{value: 'smim31', id: 'SMIM31'},
{value: 'smim35', id: 'SMIM35'},
{value: 'smim38', id: 'SMIM38'},
{value: 'smim4', id: 'SMIM4'},
{value: 'smim5', id: 'SMIM5'},
{value: 'smim7', id: 'SMIM7'},
{value: 'smim8', id: 'SMIM8'},
{value: 'smlr1', id: 'SMLR1'},
{value: 'smn1', id: 'SMN1'},
{value: 'smndc1', id: 'SMNDC1'},
{value: 'smo', id: 'SMO'},
{value: 'smoc1', id: 'SMOC1'},
{value: 'smoc2', id: 'SMOC2'},
{value: 'smok2a', id: 'SMOK2A'},
{value: 'smox', id: 'SMOX'},
{value: 'smpd1', id: 'SMPD1'},
{value: 'smpd2', id: 'SMPD2'},
{value: 'smpd3', id: 'SMPD3'},
{value: 'smpd4', id: 'SMPD4'},
{value: 'smpd5', id: 'SMPD5'},
{value: 'smpdl3a', id: 'SMPDL3A'},
{value: 'smpdl3b', id: 'SMPDL3B'},
{value: 'smptb', id: 'SMPTB'},
{value: 'smpx', id: 'SMPX'},
{value: 'smr3b', id: 'SMR3B'},
{value: 'sms', id: 'SMS'},
{value: 'smtn', id: 'SMTN'},
{value: 'smtnl1', id: 'SMTNL1'},
{value: 'smtnl2', id: 'SMTNL2'},
{value: 'smu1', id: 'SMU1'},
{value: 'smug1', id: 'SMUG1'},
{value: 'smurf1', id: 'SMURF1'},
{value: 'smurf2', id: 'SMURF2'},
{value: 'smyd1', id: 'SMYD1'},
{value: 'smyd2', id: 'SMYD2'},
{value: 'smyd3', id: 'SMYD3'},
{value: 'smyd4', id: 'SMYD4'},
{value: 'smyd5', id: 'SMYD5'},
{value: 'snai1', id: 'SNAI1'},
{value: 'snai2', id: 'SNAI2'},
{value: 'snai3', id: 'SNAI3'},
{value: 'snap23', id: 'SNAP23'},
{value: 'snap25', id: 'SNAP25'},
{value: 'snap29', id: 'SNAP29'},
{value: 'snap47', id: 'SNAP47'},
{value: 'snap91', id: 'SNAP91'},
{value: 'snapc1', id: 'SNAPC1'},
{value: 'snapc2', id: 'SNAPC2'},
{value: 'snapc3', id: 'SNAPC3'},
{value: 'snapc4', id: 'SNAPC4'},
{value: 'snapc5', id: 'SNAPC5'},
{value: 'snapin', id: 'SNAPIN'},
{value: 'snca', id: 'SNCA'},
{value: 'sncaip', id: 'SNCAIP'},
{value: 'sncb', id: 'SNCB'},
{value: 'sncg', id: 'SNCG'},
{value: 'snd1', id: 'SND1'},
{value: 'sned1', id: 'SNED1'},
{value: 'snf8', id: 'SNF8'},
{value: 'snhg11', id: 'SNHG11'},
{value: 'snip1', id: 'SNIP1'},
{value: 'snn', id: 'SNN'},
{value: 'snora16a', id: 'SNORA16A'},
{value: 'snora17', id: 'SNORA17'},
{value: 'snora19', id: 'SNORA19'},
{value: 'snora21', id: 'SNORA21'},
{value: 'snora26', id: 'SNORA26'},
{value: 'snora31', id: 'SNORA31'},
{value: 'snora33', id: 'SNORA33'},
{value: 'snora35', id: 'SNORA35'},
{value: 'snora36c', id: 'SNORA36C'},
{value: 'snora47', id: 'SNORA47'},
{value: 'snora58', id: 'SNORA58'},
{value: 'snora62', id: 'SNORA62'},
{value: 'snora63', id: 'SNORA63'},
{value: 'snora64', id: 'SNORA64'},
{value: 'snora65', id: 'SNORA65'},
{value: 'snora66', id: 'SNORA66'},
{value: 'snora70', id: 'SNORA70'},
{value: 'snora71', id: 'SNORA71'},
{value: 'snora72', id: 'SNORA72'},
{value: 'snora73', id: 'SNORA73'},
{value: 'snora74', id: 'SNORA74'},
{value: 'snora75', id: 'SNORA75'},
{value: 'snora78', id: 'SNORA78'},
{value: 'snora7a', id: 'SNORA7A'},
{value: 'snora9', id: 'SNORA9'},
{value: 'snorc', id: 'SNORC'},
{value: 'snord104', id: 'SNORD104'},
{value: 'snord11', id: 'SNORD11'},
{value: 'snord115', id: 'SNORD115'},
{value: 'snord116', id: 'SNORD116'},
{value: 'snord123', id: 'SNORD123'},
{value: 'snord14', id: 'SNORD14'},
{value: 'snord15', id: 'SNORD15'},
{value: 'snord17', id: 'SNORD17'},
{value: 'snord1b', id: 'SNORD1B'},
{value: 'snord1c', id: 'SNORD1C'},
{value: 'snord22', id: 'SNORD22'},
{value: 'snord24', id: 'SNORD24'},
{value: 'snord25', id: 'SNORD25'},
{value: 'snord29', id: 'SNORD29'},
{value: 'snord33', id: 'SNORD33'},
{value: 'snord34', id: 'SNORD34'},
{value: 'snord36', id: 'SNORD36'},
{value: 'snord39', id: 'SNORD39'},
{value: 'snord45c', id: 'SNORD45C'},
{value: 'snord49b', id: 'SNORD49B'},
{value: 'snord58', id: 'SNORD58'},
{value: 'snord61', id: 'SNORD61'},
{value: 'snord62', id: 'SNORD62'},
{value: 'snord66', id: 'SNORD66'},
{value: 'snord71', id: 'SNORD71'},
{value: 'snord72', id: 'SNORD72'},
{value: 'snord81', id: 'SNORD81'},
{value: 'snord83', id: 'SNORD83'},
{value: 'snord83b', id: 'SNORD83B'},
{value: 'snord85', id: 'SNORD85'},
{value: 'snord87', id: 'SNORD87'},
{value: 'snord89', id: 'SNORD89'},
{value: 'snord98', id: 'SNORD98'},
{value: 'snph', id: 'SNPH'},
{value: 'snrk', id: 'SNRK'},
{value: 'snrnp200', id: 'SNRNP200'},
{value: 'snrnp25', id: 'SNRNP25'},
{value: 'snrnp27', id: 'SNRNP27'},
{value: 'snrnp35', id: 'SNRNP35'},
{value: 'snrnp40', id: 'SNRNP40'},
{value: 'snrnp48', id: 'SNRNP48'},
{value: 'snrnp70', id: 'SNRNP70'},
{value: 'snrpa', id: 'SNRPA'},
{value: 'snrpa1', id: 'SNRPA1'},
{value: 'snrpb', id: 'SNRPB'},
{value: 'snrpb2', id: 'SNRPB2'},
{value: 'snrpc', id: 'SNRPC'},
{value: 'snrpd1', id: 'SNRPD1'},
{value: 'snrpd2', id: 'SNRPD2'},
{value: 'snrpd2l', id: 'SNRPD2L'},
{value: 'snrpd3', id: 'SNRPD3'},
{value: 'snrpe', id: 'SNRPE'},
{value: 'snrpel1', id: 'SNRPEL1'},
{value: 'snrpf', id: 'SNRPF'},
{value: 'snrpg', id: 'SNRPG'},
{value: 'snrpn', id: 'SNRPN'},
{value: 'snta1', id: 'SNTA1'},
{value: 'sntb1', id: 'SNTB1'},
{value: 'sntb2', id: 'SNTB2'},
{value: 'sntg1', id: 'SNTG1'},
{value: 'sntg2', id: 'SNTG2'},
{value: 'sntn', id: 'SNTN'},
{value: 'snu13', id: 'SNU13'},
{value: 'snupn', id: 'SNUPN'},
{value: 'snurf', id: 'SNURF'},
{value: 'snw1', id: 'SNW1'},
{value: 'snx1', id: 'SNX1'},
{value: 'snx10', id: 'SNX10'},
{value: 'snx11', id: 'SNX11'},
{value: 'snx12', id: 'SNX12'},
{value: 'snx13', id: 'SNX13'},
{value: 'snx14', id: 'SNX14'},
{value: 'snx15', id: 'SNX15'},
{value: 'snx16', id: 'SNX16'},
{value: 'snx17', id: 'SNX17'},
{value: 'snx18', id: 'SNX18'},
{value: 'snx19', id: 'SNX19'},
{value: 'snx2', id: 'SNX2'},
{value: 'snx20', id: 'SNX20'},
{value: 'snx21', id: 'SNX21'},
{value: 'snx22', id: 'SNX22'},
{value: 'snx24', id: 'SNX24'},
{value: 'snx25', id: 'SNX25'},
{value: 'snx27', id: 'SNX27'},
{value: 'snx29', id: 'SNX29'},
{value: 'snx3', id: 'SNX3'},
{value: 'snx30', id: 'SNX30'},
{value: 'snx31', id: 'SNX31'},
{value: 'snx32', id: 'SNX32'},
{value: 'snx33', id: 'SNX33'},
{value: 'snx4', id: 'SNX4'},
{value: 'snx5', id: 'SNX5'},
{value: 'snx6', id: 'SNX6'},
{value: 'snx7', id: 'SNX7'},
{value: 'snx8', id: 'SNX8'},
{value: 'snx9', id: 'SNX9'},
{value: 'soat1', id: 'SOAT1'},
{value: 'soat2', id: 'SOAT2'},
{value: 'sobp', id: 'SOBP'},
{value: 'socs1', id: 'SOCS1'},
{value: 'socs2', id: 'SOCS2'},
{value: 'socs3', id: 'SOCS3'},
{value: 'socs4', id: 'SOCS4'},
{value: 'socs5', id: 'SOCS5'},
{value: 'socs6', id: 'SOCS6'},
{value: 'socs7', id: 'SOCS7'},
{value: 'sod1', id: 'SOD1'},
{value: 'sod2', id: 'SOD2'},
{value: 'sod3', id: 'SOD3'},
{value: 'soga1', id: 'SOGA1'},
{value: 'soga3', id: 'SOGA3'},
{value: 'sohlh1', id: 'SOHLH1'},
{value: 'sohlh2', id: 'SOHLH2'},
{value: 'son', id: 'SON'},
{value: 'sorbs1', id: 'SORBS1'},
{value: 'sorbs2', id: 'SORBS2'},
{value: 'sorbs3', id: 'SORBS3'},
{value: 'sorcs1', id: 'SORCS1'},
{value: 'sorcs2', id: 'SORCS2'},
{value: 'sorcs3', id: 'SORCS3'},
{value: 'sord', id: 'SORD'},
{value: 'sorl1', id: 'SORL1'},
{value: 'sort1', id: 'SORT1'},
{value: 'sos1', id: 'SOS1'},
{value: 'sos2', id: 'SOS2'},
{value: 'sost', id: 'SOST'},
{value: 'sostdc1', id: 'SOSTDC1'},
{value: 'sowaha', id: 'SOWAHA'},
{value: 'sowahb', id: 'SOWAHB'},
{value: 'sowahc', id: 'SOWAHC'},
{value: 'sowahd', id: 'SOWAHD'},
{value: 'sox1', id: 'SOX1'},
{value: 'sox10', id: 'SOX10'},
{value: 'sox11', id: 'SOX11'},
{value: 'sox12', id: 'SOX12'},
{value: 'sox13', id: 'SOX13'},
{value: 'sox14', id: 'SOX14'},
{value: 'sox15', id: 'SOX15'},
{value: 'sox17', id: 'SOX17'},
{value: 'sox18', id: 'SOX18'},
{value: 'sox2', id: 'SOX2'},
{value: 'sox21', id: 'SOX21'},
{value: 'sox3', id: 'SOX3'},
{value: 'sox30', id: 'SOX30'},
{value: 'sox4', id: 'SOX4'},
{value: 'sox5', id: 'SOX5'},
{value: 'sox6', id: 'SOX6'},
{value: 'sox7', id: 'SOX7'},
{value: 'sox8', id: 'SOX8'},
{value: 'sox9', id: 'SOX9'},
{value: 'sp1', id: 'SP1'},
{value: 'sp100', id: 'SP100'},
{value: 'sp110', id: 'SP110'},
{value: 'sp140', id: 'SP140'},
{value: 'sp2', id: 'SP2'},
{value: 'sp3', id: 'SP3'},
{value: 'sp4', id: 'SP4'},
{value: 'sp5', id: 'SP5'},
{value: 'sp6', id: 'SP6'},
{value: 'sp7', id: 'SP7'},
{value: 'sp8', id: 'SP8'},
{value: 'sp9', id: 'SP9'},
{value: 'spa17', id: 'SPA17'},
{value: 'spaca1', id: 'SPACA1'},
{value: 'spaca3', id: 'SPACA3'},
{value: 'spaca4', id: 'SPACA4'},
{value: 'spaca5', id: 'SPACA5'},
{value: 'spaca6', id: 'SPACA6'},
{value: 'spaca7', id: 'SPACA7'},
{value: 'spaca9', id: 'SPACA9'},
{value: 'spag1', id: 'SPAG1'},
{value: 'spag11a', id: 'SPAG11A'},
{value: 'spag11bl', id: 'SPAG11BL'},
{value: 'spag16', id: 'SPAG16'},
{value: 'spag17', id: 'SPAG17'},
{value: 'spag4', id: 'SPAG4'},
{value: 'spag5', id: 'SPAG5'},
{value: 'spag6', id: 'SPAG6'},
{value: 'spag6l', id: 'SPAG6L'},
{value: 'spag7', id: 'SPAG7'},
{value: 'spag8', id: 'SPAG8'},
{value: 'spag9', id: 'SPAG9'},
{value: 'spam1', id: 'SPAM1'},
{value: 'sparc', id: 'SPARC'},
{value: 'sparcl1', id: 'SPARCL1'},
{value: 'spart', id: 'SPART'},
{value: 'spast', id: 'SPAST'},
{value: 'spata1', id: 'SPATA1'},
{value: 'spata13', id: 'SPATA13'},
{value: 'spata16', id: 'SPATA16'},
{value: 'spata17', id: 'SPATA17'},
{value: 'spata18', id: 'SPATA18'},
{value: 'spata19', id: 'SPATA19'},
{value: 'spata2', id: 'SPATA2'},
{value: 'spata20', id: 'SPATA20'},
{value: 'spata21', id: 'SPATA21'},
{value: 'spata22', id: 'SPATA22'},
{value: 'spata24', id: 'SPATA24'},
{value: 'spata25', id: 'SPATA25'},
{value: 'spata2l', id: 'SPATA2L'},
{value: 'spata3', id: 'SPATA3'},
{value: 'spata31a5', id: 'SPATA31A5'},
{value: 'spata31d1', id: 'SPATA31D1'},
{value: 'spata31d1b', id: 'SPATA31D1B'},
{value: 'spata31d3', id: 'SPATA31D3'},
{value: 'spata31e1', id: 'SPATA31E1'},
{value: 'spata32', id: 'SPATA32'},
{value: 'spata33', id: 'SPATA33'},
{value: 'spata4', id: 'SPATA4'},
{value: 'spata45l1', id: 'SPATA45L1'},
{value: 'spata46', id: 'SPATA46'},
{value: 'spata48', id: 'SPATA48'},
{value: 'spata5', id: 'SPATA5'},
{value: 'spata5l1', id: 'SPATA5L1'},
{value: 'spata6', id: 'SPATA6'},
{value: 'spata6l', id: 'SPATA6L'},
{value: 'spata7', id: 'SPATA7'},
{value: 'spata9', id: 'SPATA9'},
{value: 'spatc1', id: 'SPATC1'},
{value: 'spatc1l', id: 'SPATC1L'},
{value: 'spats1', id: 'SPATS1'},
{value: 'spats2', id: 'SPATS2'},
{value: 'spats2l', id: 'SPATS2L'},
{value: 'spc24', id: 'SPC24'},
{value: 'spc25', id: 'SPC25'},
{value: 'spcs1', id: 'SPCS1'},
{value: 'spcs2', id: 'SPCS2'},
{value: 'spcs3', id: 'SPCS3'},
{value: 'spdef', id: 'SPDEF'},
{value: 'spdl1', id: 'SPDL1'},
{value: 'spdya', id: 'SPDYA'},
{value: 'spdye4', id: 'SPDYE4'},
{value: 'specc1', id: 'SPECC1'},
{value: 'specc1l', id: 'SPECC1L'},
{value: 'spef1', id: 'SPEF1'},
{value: 'spef2', id: 'SPEF2'},
{value: 'speg', id: 'SPEG'},
{value: 'spem1', id: 'SPEM1'},
{value: 'spem2', id: 'SPEM2'},
{value: 'spem3', id: 'SPEM3'},
{value: 'spen', id: 'SPEN'},
{value: 'spesp1', id: 'SPESP1'},
{value: 'spetex-2c', id: 'SPETEX-2C'},
{value: 'spetex-2f', id: 'SPETEX-2F'},
{value: 'spetex-2h', id: 'SPETEX-2H'},
{value: 'spg11', id: 'SPG11'},
{value: 'spg21', id: 'SPG21'},
{value: 'spg7', id: 'SPG7'},
{value: 'sphk1', id: 'SPHK1'},
{value: 'sphk2', id: 'SPHK2'},
{value: 'sphkap', id: 'SPHKAP'},
{value: 'spi1', id: 'SPI1'},
{value: 'spib', id: 'SPIB'},
{value: 'spic', id: 'SPIC'},
{value: 'spice1', id: 'SPICE1'},
{value: 'spidr', id: 'SPIDR'},
{value: 'spin1', id: 'SPIN1'},
{value: 'spin2a', id: 'SPIN2A'},
{value: 'spin4', id: 'SPIN4'},
{value: 'spindoc', id: 'SPINDOC'},
{value: 'spink1', id: 'SPINK1'},
{value: 'spink13', id: 'SPINK13'},
{value: 'spink14', id: 'SPINK14'},
{value: 'spink1l', id: 'SPINK1L'},
{value: 'spink2', id: 'SPINK2'},
{value: 'spink4', id: 'SPINK4'},
{value: 'spink5', id: 'SPINK5'},
{value: 'spink6', id: 'SPINK6'},
{value: 'spink7', id: 'SPINK7'},
{value: 'spink8', id: 'SPINK8'},
{value: 'spink9', id: 'SPINK9'},
{value: 'spint1', id: 'SPINT1'},
{value: 'spint2', id: 'SPINT2'},
{value: 'spint3', id: 'SPINT3'},
{value: 'spint5p', id: 'SPINT5P'},
{value: 'spire1', id: 'SPIRE1'},
{value: 'spire2', id: 'SPIRE2'},
{value: 'spn', id: 'SPN'},
{value: 'spns1', id: 'SPNS1'},
{value: 'spns2', id: 'SPNS2'},
{value: 'spns3', id: 'SPNS3'},
{value: 'spo11', id: 'SPO11'},
{value: 'spock1', id: 'SPOCK1'},
{value: 'spock2', id: 'SPOCK2'},
{value: 'spock3', id: 'SPOCK3'},
{value: 'spon1', id: 'SPON1'},
{value: 'spon2', id: 'SPON2'},
{value: 'spop', id: 'SPOP'},
{value: 'spopl', id: 'SPOPL'},
{value: 'spout1', id: 'SPOUT1'},
{value: 'spp1', id: 'SPP1'},
{value: 'spp2', id: 'SPP2'},
{value: 'sppl2a', id: 'SPPL2A'},
{value: 'sppl2b', id: 'SPPL2B'},
{value: 'sppl2c', id: 'SPPL2C'},
{value: 'sppl3', id: 'SPPL3'},
{value: 'spr', id: 'SPR'},
{value: 'spred1', id: 'SPRED1'},
{value: 'spred2', id: 'SPRED2'},
{value: 'spred3', id: 'SPRED3'},
{value: 'spring1', id: 'SPRING1'},
{value: 'sprn', id: 'SPRN'},
{value: 'sprr1a', id: 'SPRR1A'},
{value: 'sprr1b', id: 'SPRR1B'},
{value: 'sprr2b', id: 'SPRR2B'},
{value: 'sprr2d', id: 'SPRR2D'},
{value: 'sprr2g', id: 'SPRR2G'},
{value: 'sprr3', id: 'SPRR3'},
{value: 'sprtn', id: 'SPRTN'},
{value: 'spry1', id: 'SPRY1'},
{value: 'spry2', id: 'SPRY2'},
{value: 'spry3', id: 'SPRY3'},
{value: 'spry4', id: 'SPRY4'},
{value: 'spryd3', id: 'SPRYD3'},
{value: 'spryd4', id: 'SPRYD4'},
{value: 'spryd7', id: 'SPRYD7'},
{value: 'spsb1', id: 'SPSB1'},
{value: 'spsb2', id: 'SPSB2'},
{value: 'spsb3', id: 'SPSB3'},
{value: 'spsb4', id: 'SPSB4'},
{value: 'spt1', id: 'SPT1'},
{value: 'spta1', id: 'SPTA1'},
{value: 'sptan1', id: 'SPTAN1'},
{value: 'sptb', id: 'SPTB'},
{value: 'sptbn1', id: 'SPTBN1'},
{value: 'sptbn2', id: 'SPTBN2'},
{value: 'sptbn4', id: 'SPTBN4'},
{value: 'sptbn5', id: 'SPTBN5'},
{value: 'sptlc1', id: 'SPTLC1'},
{value: 'sptlc2', id: 'SPTLC2'},
{value: 'sptlc3', id: 'SPTLC3'},
{value: 'sptssa', id: 'SPTSSA'},
{value: 'sptssb', id: 'SPTSSB'},
{value: 'spty2d1', id: 'SPTY2D1'},
{value: 'spx', id: 'SPX'},
{value: 'spz1', id: 'SPZ1'},
{value: 'sqle', id: 'SQLE'},
{value: 'sqor', id: 'SQOR'},
{value: 'sqstm1', id: 'SQSTM1'},
{value: 'sra1', id: 'SRA1'},
{value: 'srarp', id: 'SRARP'},
{value: 'srbd1', id: 'SRBD1'},
{value: 'src', id: 'SRC'},
{value: 'srcap', id: 'SRCAP'},
{value: 'srcin1', id: 'SRCIN1'},
{value: 'srd5a1', id: 'SRD5A1'},
{value: 'srd5a2', id: 'SRD5A2'},
{value: 'srd5a3', id: 'SRD5A3'},
{value: 'srebf1', id: 'SREBF1'},
{value: 'srebf2', id: 'SREBF2'},
{value: 'srek1', id: 'SREK1'},
{value: 'srek1ip1', id: 'SREK1IP1'},
{value: 'srf', id: 'SRF'},
{value: 'srfbp1', id: 'SRFBP1'},
{value: 'srgap1', id: 'SRGAP1'},
{value: 'srgap2', id: 'SRGAP2'},
{value: 'srgap3', id: 'SRGAP3'},
{value: 'srgn', id: 'SRGN'},
{value: 'sri', id: 'SRI'},
{value: 'srl', id: 'SRL'},
{value: 'srm', id: 'SRM'},
{value: 'srms', id: 'SRMS'},
{value: 'srp14', id: 'SRP14'},
{value: 'srp19', id: 'SRP19'},
{value: 'srp54a', id: 'SRP54A'},
{value: 'srp68', id: 'SRP68'},
{value: 'srp72', id: 'SRP72'},
{value: 'srp9', id: 'SRP9'},
{value: 'srpk1', id: 'SRPK1'},
{value: 'srpk2', id: 'SRPK2'},
{value: 'srpk3', id: 'SRPK3'},
{value: 'srpra', id: 'SRPRA'},
{value: 'srprb', id: 'SRPRB'},
{value: 'srpx', id: 'SRPX'},
{value: 'srpx2', id: 'SRPX2'},
{value: 'srr', id: 'SRR'},
{value: 'srrd', id: 'SRRD'},
{value: 'srrm1', id: 'SRRM1'},
{value: 'srrm2', id: 'SRRM2'},
{value: 'srrm3', id: 'SRRM3'},
{value: 'srrm4', id: 'SRRM4'},
{value: 'srrm5', id: 'SRRM5'},
{value: 'srrt', id: 'SRRT'},
{value: 'srsf1', id: 'SRSF1'},
{value: 'srsf10', id: 'SRSF10'},
{value: 'srsf11', id: 'SRSF11'},
{value: 'srsf12', id: 'SRSF12'},
{value: 'srsf2', id: 'SRSF2'},
{value: 'srsf3', id: 'SRSF3'},
{value: 'srsf4', id: 'SRSF4'},
{value: 'srsf5', id: 'SRSF5'},
{value: 'srsf6', id: 'SRSF6'},
{value: 'srsf7', id: 'SRSF7'},
{value: 'srsf8', id: 'SRSF8'},
{value: 'srsf9', id: 'SRSF9'},
{value: 'srxn1', id: 'SRXN1'},
{value: 'sry', id: 'SRY'},
{value: 'ss18', id: 'SS18'},
{value: 'ss18l1', id: 'SS18L1'},
{value: 'ss18l2', id: 'SS18L2'},
{value: 'ssb', id: 'SSB'},
{value: 'ssbp1', id: 'SSBP1'},
{value: 'ssbp2', id: 'SSBP2'},
{value: 'ssbp3', id: 'SSBP3'},
{value: 'ssbp4', id: 'SSBP4'},
{value: 'ssc4d', id: 'SSC4D'},
{value: 'ssc5d', id: 'SSC5D'},
{value: 'ssh1', id: 'SSH1'},
{value: 'ssh2', id: 'SSH2'},
{value: 'ssh3', id: 'SSH3'},
{value: 'ssmem1', id: 'SSMEM1'},
{value: 'ssna1', id: 'SSNA1'},
{value: 'sspn', id: 'SSPN'},
{value: 'sspo', id: 'SSPO'},
{value: 'ssr1', id: 'SSR1'},
{value: 'ssr2', id: 'SSR2'},
{value: 'ssr3', id: 'SSR3'},
{value: 'ssr4', id: 'SSR4'},
{value: 'ssrp1', id: 'SSRP1'},
{value: 'sst', id: 'SST'},
{value: 'sstr1', id: 'SSTR1'},
{value: 'sstr2', id: 'SSTR2'},
{value: 'sstr3', id: 'SSTR3'},
{value: 'sstr4', id: 'SSTR4'},
{value: 'sstr5', id: 'SSTR5'},
{value: 'ssty1', id: 'SSTY1'},
{value: 'ssu72', id: 'SSU72'},
{value: 'ssuh2', id: 'SSUH2'},
{value: 'ssx1', id: 'SSX1'},
{value: 'ssx2ip', id: 'SSX2IP'},
{value: 'st13', id: 'ST13'},
{value: 'st14', id: 'ST14'},
{value: 'st18', id: 'ST18'},
{value: 'st3gal1', id: 'ST3GAL1'},
{value: 'st3gal2', id: 'ST3GAL2'},
{value: 'st3gal3', id: 'ST3GAL3'},
{value: 'st3gal4', id: 'ST3GAL4'},
{value: 'st3gal5', id: 'ST3GAL5'},
{value: 'st3gal6', id: 'ST3GAL6'},
{value: 'st6gal1', id: 'ST6GAL1'},
{value: 'st6gal2', id: 'ST6GAL2'},
{value: 'st6galnac1', id: 'ST6GALNAC1'},
{value: 'st6galnac2', id: 'ST6GALNAC2'},
{value: 'st6galnac3', id: 'ST6GALNAC3'},
{value: 'st6galnac4', id: 'ST6GALNAC4'},
{value: 'st6galnac5', id: 'ST6GALNAC5'},
{value: 'st6galnac6', id: 'ST6GALNAC6'},
{value: 'st7', id: 'ST7'},
{value: 'st7l', id: 'ST7L'},
{value: 'st8sia1', id: 'ST8SIA1'},
{value: 'st8sia2', id: 'ST8SIA2'},
{value: 'st8sia3', id: 'ST8SIA3'},
{value: 'st8sia4', id: 'ST8SIA4'},
{value: 'st8sia5', id: 'ST8SIA5'},
{value: 'st8sia6', id: 'ST8SIA6'},
{value: 'stab1', id: 'STAB1'},
{value: 'stab2', id: 'STAB2'},
{value: 'stac', id: 'STAC'},
{value: 'stac2', id: 'STAC2'},
{value: 'stac3', id: 'STAC3'},
{value: 'stag1', id: 'STAG1'},
{value: 'stag2', id: 'STAG2'},
{value: 'stag3', id: 'STAG3'},
{value: 'stam', id: 'STAM'},
{value: 'stam2', id: 'STAM2'},
{value: 'stambp', id: 'STAMBP'},
{value: 'stambpl1', id: 'STAMBPL1'},
{value: 'stap1', id: 'STAP1'},
{value: 'stap2', id: 'STAP2'},
{value: 'star', id: 'STAR'},
{value: 'stard10', id: 'STARD10'},
{value: 'stard13', id: 'STARD13'},
{value: 'stard3', id: 'STARD3'},
{value: 'stard3nl', id: 'STARD3NL'},
{value: 'stard4', id: 'STARD4'},
{value: 'stard5', id: 'STARD5'},
{value: 'stard6', id: 'STARD6'},
{value: 'stard7', id: 'STARD7'},
{value: 'stard8', id: 'STARD8'},
{value: 'stard9', id: 'STARD9'},
{value: 'stat1', id: 'STAT1'},
{value: 'stat2', id: 'STAT2'},
{value: 'stat3', id: 'STAT3'},
{value: 'stat4', id: 'STAT4'},
{value: 'stat5a', id: 'STAT5A'},
{value: 'stat5b', id: 'STAT5B'},
{value: 'stat6', id: 'STAT6'},
{value: 'stath', id: 'STATH'},
{value: 'stau1', id: 'STAU1'},
{value: 'stau2', id: 'STAU2'},
{value: 'stbd1', id: 'STBD1'},
{value: 'stc1', id: 'STC1'},
{value: 'stc2', id: 'STC2'},
{value: 'steap1', id: 'STEAP1'},
{value: 'steap2', id: 'STEAP2'},
{value: 'steap3', id: 'STEAP3'},
{value: 'steap4', id: 'STEAP4'},
{value: 'steep1', id: 'STEEP1'},
{value: 'stfa2', id: 'STFA2'},
{value: 'stfa2l1', id: 'STFA2L1'},
{value: 'stfa2l2', id: 'STFA2L2'},
{value: 'stfa3', id: 'STFA3'},
{value: 'stfa3l1', id: 'STFA3L1'},
{value: 'stil', id: 'STIL'},
{value: 'stim1', id: 'STIM1'},
{value: 'stim2', id: 'STIM2'},
{value: 'stimate', id: 'STIMATE'},
{value: 'sting1', id: 'STING1'},
{value: 'stip1', id: 'STIP1'},
{value: 'stk10', id: 'STK10'},
{value: 'stk11', id: 'STK11'},
{value: 'stk11ip', id: 'STK11IP'},
{value: 'stk16', id: 'STK16'},
{value: 'stk17b', id: 'STK17B'},
{value: 'stk19', id: 'STK19'},
{value: 'stk24', id: 'STK24'},
{value: 'stk25', id: 'STK25'},
{value: 'stk26', id: 'STK26'},
{value: 'stk3', id: 'STK3'},
{value: 'stk31', id: 'STK31'},
{value: 'stk32a', id: 'STK32A'},
{value: 'stk32b', id: 'STK32B'},
{value: 'stk32c', id: 'STK32C'},
{value: 'stk33', id: 'STK33'},
{value: 'stk35', id: 'STK35'},
{value: 'stk36', id: 'STK36'},
{value: 'stk38', id: 'STK38'},
{value: 'stk38l', id: 'STK38L'},
{value: 'stk39', id: 'STK39'},
{value: 'stk4', id: 'STK4'},
{value: 'stk40', id: 'STK40'},
{value: 'stkld1', id: 'STKLD1'},
{value: 'stmn1', id: 'STMN1'},
{value: 'stmn2', id: 'STMN2'},
{value: 'stmn3', id: 'STMN3'},
{value: 'stmn4', id: 'STMN4'},
{value: 'stmnd1', id: 'STMND1'},
{value: 'stmp1', id: 'STMP1'},
{value: 'stn1', id: 'STN1'},
{value: 'stom', id: 'STOM'},
{value: 'stoml1', id: 'STOML1'},
{value: 'stoml2', id: 'STOML2'},
{value: 'stoml3', id: 'STOML3'},
{value: 'ston1', id: 'STON1'},
{value: 'ston2', id: 'STON2'},
{value: 'stox1', id: 'STOX1'},
{value: 'stox2', id: 'STOX2'},
{value: 'stpg1', id: 'STPG1'},
{value: 'stpg2', id: 'STPG2'},
{value: 'stpg3', id: 'STPG3'},
{value: 'stpg4', id: 'STPG4'},
{value: 'stra6', id: 'STRA6'},
{value: 'stra8', id: 'STRA8'},
{value: 'strada', id: 'STRADA'},
{value: 'stradb', id: 'STRADB'},
{value: 'strap', id: 'STRAP'},
{value: 'strbp', id: 'STRBP'},
{value: 'strc', id: 'STRC'},
{value: 'strip1', id: 'STRIP1'},
{value: 'strip2', id: 'STRIP2'},
{value: 'strn', id: 'STRN'},
{value: 'strn3', id: 'STRN3'},
{value: 'strn4', id: 'STRN4'},
{value: 'sts', id: 'STS'},
{value: 'stt3a', id: 'STT3A'},
{value: 'stt3b', id: 'STT3B'},
{value: 'stub1', id: 'STUB1'},
{value: 'stum', id: 'STUM'},
{value: 'stx11', id: 'STX11'},
{value: 'stx12', id: 'STX12'},
{value: 'stx16', id: 'STX16'},
{value: 'stx17', id: 'STX17'},
{value: 'stx18', id: 'STX18'},
{value: 'stx1a', id: 'STX1A'},
{value: 'stx1b', id: 'STX1B'},
{value: 'stx2', id: 'STX2'},
{value: 'stx3', id: 'STX3'},
{value: 'stx4', id: 'STX4'},
{value: 'stx5', id: 'STX5'},
{value: 'stx6', id: 'STX6'},
{value: 'stx7', id: 'STX7'},
{value: 'stx8', id: 'STX8'},
{value: 'stxbp1', id: 'STXBP1'},
{value: 'stxbp2', id: 'STXBP2'},
{value: 'stxbp3', id: 'STXBP3'},
{value: 'stxbp4', id: 'STXBP4'},
{value: 'stxbp5', id: 'STXBP5'},
{value: 'stxbp5l', id: 'STXBP5L'},
{value: 'stxbp6', id: 'STXBP6'},
{value: 'styk1', id: 'STYK1'},
{value: 'styx-ps1', id: 'STYX-PS1'},
{value: 'styx', id: 'STYX'},
{value: 'styxl1', id: 'STYXL1'},
{value: 'styxl2', id: 'STYXL2'},
{value: 'sub1', id: 'SUB1'},
{value: 'sucla2', id: 'SUCLA2'},
{value: 'suclg1', id: 'SUCLG1'},
{value: 'suclg2', id: 'SUCLG2'},
{value: 'sucnr1', id: 'SUCNR1'},
{value: 'suco', id: 'SUCO'},
{value: 'suds3', id: 'SUDS3'},
{value: 'sufu', id: 'SUFU'},
{value: 'sugct', id: 'SUGCT'},
{value: 'sugp1', id: 'SUGP1'},
{value: 'sugp2', id: 'SUGP2'},
{value: 'sugt1', id: 'SUGT1'},
{value: 'sulf1', id: 'SULF1'},
{value: 'sulf2', id: 'SULF2'},
{value: 'sult1a1', id: 'SULT1A1'},
{value: 'sult1b1', id: 'SULT1B1'},
{value: 'sult1c2', id: 'SULT1C2'},
{value: 'sult1c2a', id: 'SULT1C2A'},
{value: 'sult1c3', id: 'SULT1C3'},
{value: 'sult1d1', id: 'SULT1D1'},
{value: 'sult1e1', id: 'SULT1E1'},
{value: 'sult2a1', id: 'SULT2A1'},
{value: 'sult2a2', id: 'SULT2A2'},
{value: 'sult2a6', id: 'SULT2A6'},
{value: 'sult2b1', id: 'SULT2B1'},
{value: 'sult4a1', id: 'SULT4A1'},
{value: 'sult5a1', id: 'SULT5A1'},
{value: 'sult6b1', id: 'SULT6B1'},
{value: 'sumf1', id: 'SUMF1'},
{value: 'sumf2', id: 'SUMF2'},
{value: 'sumo1', id: 'SUMO1'},
{value: 'sumo2', id: 'SUMO2'},
{value: 'sumo3', id: 'SUMO3'},
{value: 'sumo4', id: 'SUMO4'},
{value: 'sun1', id: 'SUN1'},
{value: 'sun2', id: 'SUN2'},
{value: 'sun3', id: 'SUN3'},
{value: 'sun5', id: 'SUN5'},
{value: 'suox', id: 'SUOX'},
{value: 'supt16h', id: 'SUPT16H'},
{value: 'supt20h', id: 'SUPT20H'},
{value: 'supt3h', id: 'SUPT3H'},
{value: 'supt4h1', id: 'SUPT4H1'},
{value: 'supt5h', id: 'SUPT5H'},
{value: 'supt6h', id: 'SUPT6H'},
{value: 'supt7l', id: 'SUPT7L'},
{value: 'supv3l1', id: 'SUPV3L1'},
{value: 'surf1', id: 'SURF1'},
{value: 'surf2', id: 'SURF2'},
{value: 'surf4', id: 'SURF4'},
{value: 'surf6', id: 'SURF6'},
{value: 'susd1', id: 'SUSD1'},
{value: 'susd2', id: 'SUSD2'},
{value: 'susd3', id: 'SUSD3'},
{value: 'susd4', id: 'SUSD4'},
{value: 'susd5', id: 'SUSD5'},
{value: 'susd6', id: 'SUSD6'},
{value: 'suv39h1l1', id: 'SUV39H1L1'},
{value: 'suv39h2', id: 'SUV39H2'},
{value: 'suz12', id: 'SUZ12'},
{value: 'sv2a', id: 'SV2A'},
{value: 'sv2b', id: 'SV2B'},
{value: 'sv2c', id: 'SV2C'},
{value: 'sval1', id: 'SVAL1'},
{value: 'sval2', id: 'SVAL2'},
{value: 'svbp', id: 'SVBP'},
{value: 'svep1', id: 'SVEP1'},
{value: 'svil', id: 'SVIL'},
{value: 'svip', id: 'SVIP'},
{value: 'svop', id: 'SVOP'},
{value: 'svopl', id: 'SVOPL'},
{value: 'svs1', id: 'SVS1'},
{value: 'svs3a', id: 'SVS3A'},
{value: 'svs3b', id: 'SVS3B'},
{value: 'svs5', id: 'SVS5'},
{value: 'swap70', id: 'SWAP70'},
{value: 'swi5', id: 'SWI5'},
{value: 'swsap1', id: 'SWSAP1'},
{value: 'swt1', id: 'SWT1'},
{value: 'syap1', id: 'SYAP1'},
{value: 'sybu', id: 'SYBU'},
{value: 'syce1', id: 'SYCE1'},
{value: 'syce1l', id: 'SYCE1L'},
{value: 'syce2', id: 'SYCE2'},
{value: 'syce3', id: 'SYCE3'},
{value: 'sycn', id: 'SYCN'},
{value: 'sycp1', id: 'SYCP1'},
{value: 'sycp2', id: 'SYCP2'},
{value: 'sycp3', id: 'SYCP3'},
{value: 'syde1', id: 'SYDE1'},
{value: 'syde2', id: 'SYDE2'},
{value: 'syf2', id: 'SYF2'},
{value: 'syk', id: 'SYK'},
{value: 'sympk', id: 'SYMPK'},
{value: 'syn1', id: 'SYN1'},
{value: 'syn2', id: 'SYN2'},
{value: 'syn3', id: 'SYN3'},
{value: 'synb', id: 'SYNB'},
{value: 'sync', id: 'SYNC'},
{value: 'syncrip', id: 'SYNCRIP'},
{value: 'syndig1', id: 'SYNDIG1'},
{value: 'syndig1l', id: 'SYNDIG1L'},
{value: 'syne1', id: 'SYNE1'},
{value: 'syne2', id: 'SYNE2'},
{value: 'syne3', id: 'SYNE3'},
{value: 'syne4', id: 'SYNE4'},
{value: 'syngap1', id: 'SYNGAP1'},
{value: 'syngr1', id: 'SYNGR1'},
{value: 'syngr2', id: 'SYNGR2'},
{value: 'syngr3', id: 'SYNGR3'},
{value: 'syngr4', id: 'SYNGR4'},
{value: 'synj1', id: 'SYNJ1'},
{value: 'synj2', id: 'SYNJ2'},
{value: 'synj2bp', id: 'SYNJ2BP'},
{value: 'synm', id: 'SYNM'},
{value: 'synpo', id: 'SYNPO'},
{value: 'synpo2', id: 'SYNPO2'},
{value: 'synpo2l', id: 'SYNPO2L'},
{value: 'synpr', id: 'SYNPR'},
{value: 'synrg', id: 'SYNRG'},
{value: 'syp', id: 'SYP'},
{value: 'sypl1', id: 'SYPL1'},
{value: 'sypl2', id: 'SYPL2'},
{value: 'sys1', id: 'SYS1'},
{value: 'syt1', id: 'SYT1'},
{value: 'syt10', id: 'SYT10'},
{value: 'syt11', id: 'SYT11'},
{value: 'syt12', id: 'SYT12'},
{value: 'syt13', id: 'SYT13'},
{value: 'syt14', id: 'SYT14'},
{value: 'syt15', id: 'SYT15'},
{value: 'syt16', id: 'SYT16'},
{value: 'syt17', id: 'SYT17'},
{value: 'syt2', id: 'SYT2'},
{value: 'syt3', id: 'SYT3'},
{value: 'syt4', id: 'SYT4'},
{value: 'syt5', id: 'SYT5'},
{value: 'syt6', id: 'SYT6'},
{value: 'syt7', id: 'SYT7'},
{value: 'syt8', id: 'SYT8'},
{value: 'syt9', id: 'SYT9'},
{value: 'sytl1', id: 'SYTL1'},
{value: 'sytl2', id: 'SYTL2'},
{value: 'sytl3', id: 'SYTL3'},
{value: 'sytl4', id: 'SYTL4'},
{value: 'sytl5', id: 'SYTL5'},
{value: 'syvn1', id: 'SYVN1'},
{value: 'szrd1', id: 'SZRD1'},
{value: 'szt2', id: 'SZT2'},
{value: 't2', id: 'T2'},
{value: 'taar1', id: 'TAAR1'},
{value: 'taar2', id: 'TAAR2'},
{value: 'taar3', id: 'TAAR3'},
{value: 'taar4', id: 'TAAR4'},
{value: 'taar5', id: 'TAAR5'},
{value: 'taar6', id: 'TAAR6'},
{value: 'taar7a', id: 'TAAR7A'},
{value: 'taar7b', id: 'TAAR7B'},
{value: 'taar7c', id: 'TAAR7C'},
{value: 'taar7d', id: 'TAAR7D'},
{value: 'taar7e', id: 'TAAR7E'},
{value: 'taar7g', id: 'TAAR7G'},
{value: 'taar7h', id: 'TAAR7H'},
{value: 'taar8a', id: 'TAAR8A'},
{value: 'taar8b', id: 'TAAR8B'},
{value: 'taar9', id: 'TAAR9'},
{value: 'tab1', id: 'TAB1'},
{value: 'tab2', id: 'TAB2'},
{value: 'tab3', id: 'TAB3'},
{value: 'tac1', id: 'TAC1'},
{value: 'tac3', id: 'TAC3'},
{value: 'tac4', id: 'TAC4'},
{value: 'tacc1', id: 'TACC1'},
{value: 'tacc2', id: 'TACC2'},
{value: 'tacc3', id: 'TACC3'},
{value: 'taco1', id: 'TACO1'},
{value: 'tacr1', id: 'TACR1'},
{value: 'tacr2', id: 'TACR2'},
{value: 'tacr3', id: 'TACR3'},
{value: 'tacstd2', id: 'TACSTD2'},
{value: 'tada1', id: 'TADA1'},
{value: 'tada2a', id: 'TADA2A'},
{value: 'tada2b', id: 'TADA2B'},
{value: 'tada3', id: 'TADA3'},
{value: 'taf1', id: 'TAF1'},
{value: 'taf10', id: 'TAF10'},
{value: 'taf11', id: 'TAF11'},
{value: 'taf12', id: 'TAF12'},
{value: 'taf13', id: 'TAF13'},
{value: 'taf15', id: 'TAF15'},
{value: 'taf1a', id: 'TAF1A'},
{value: 'taf1b', id: 'TAF1B'},
{value: 'taf1c', id: 'TAF1C'},
{value: 'taf1d', id: 'TAF1D'},
{value: 'taf2', id: 'TAF2'},
{value: 'taf3', id: 'TAF3'},
{value: 'taf4', id: 'TAF4'},
{value: 'taf4b', id: 'TAF4B'},
{value: 'taf5', id: 'TAF5'},
{value: 'taf5l', id: 'TAF5L'},
{value: 'taf6', id: 'TAF6'},
{value: 'taf6l', id: 'TAF6L'},
{value: 'taf7', id: 'TAF7'},
{value: 'taf7l', id: 'TAF7L'},
{value: 'taf7l2', id: 'TAF7L2'},
{value: 'taf8', id: 'TAF8'},
{value: 'taf9', id: 'TAF9'},
{value: 'taf9b', id: 'TAF9B'},
{value: 'tafa1', id: 'TAFA1'},
{value: 'tafa2', id: 'TAFA2'},
{value: 'tafa3', id: 'TAFA3'},
{value: 'tafa4', id: 'TAFA4'},
{value: 'tafa5', id: 'TAFA5'},
{value: 'tafazzin', id: 'TAFAZZIN'},
{value: 'tagap', id: 'TAGAP'},
{value: 'tagln', id: 'TAGLN'},
{value: 'tagln2', id: 'TAGLN2'},
{value: 'tagln3', id: 'TAGLN3'},
{value: 'tal1', id: 'TAL1'},
{value: 'tal2', id: 'TAL2'},
{value: 'taldo1', id: 'TALDO1'},
{value: 'tamalin', id: 'TAMALIN'},
{value: 'tamm41', id: 'TAMM41'},
{value: 'tanc1', id: 'TANC1'},
{value: 'tanc2', id: 'TANC2'},
{value: 'tango2', id: 'TANGO2'},
{value: 'tango6', id: 'TANGO6'},
{value: 'tank', id: 'TANK'},
{value: 'taok1', id: 'TAOK1'},
{value: 'taok2', id: 'TAOK2'},
{value: 'taok3', id: 'TAOK3'},
{value: 'tap1', id: 'TAP1'},
{value: 'tap2', id: 'TAP2'},
{value: 'tapbp', id: 'TAPBP'},
{value: 'tapbpl', id: 'TAPBPL'},
{value: 'tapt1', id: 'TAPT1'},
{value: 'tarbp1', id: 'TARBP1'},
{value: 'tarbp2', id: 'TARBP2'},
{value: 'tardbp', id: 'TARDBP'},
{value: 'tarm1', id: 'TARM1'},
{value: 'tars1', id: 'TARS1'},
{value: 'tars2', id: 'TARS2'},
{value: 'tars3', id: 'TARS3'},
{value: 'tas1r1', id: 'TAS1R1'},
{value: 'tas1r2', id: 'TAS1R2'},
{value: 'tas1r3', id: 'TAS1R3'},
{value: 'tas2r102', id: 'TAS2R102'},
{value: 'tas2r103', id: 'TAS2R103'},
{value: 'tas2r104', id: 'TAS2R104'},
{value: 'tas2r105', id: 'TAS2R105'},
{value: 'tas2r106', id: 'TAS2R106'},
{value: 'tas2r107', id: 'TAS2R107'},
{value: 'tas2r108', id: 'TAS2R108'},
{value: 'tas2r109', id: 'TAS2R109'},
{value: 'tas2r110', id: 'TAS2R110'},
{value: 'tas2r113', id: 'TAS2R113'},
{value: 'tas2r114', id: 'TAS2R114'},
{value: 'tas2r116', id: 'TAS2R116'},
{value: 'tas2r117', id: 'TAS2R117'},
{value: 'tas2r118', id: 'TAS2R118'},
{value: 'tas2r119', id: 'TAS2R119'},
{value: 'tas2r120', id: 'TAS2R120'},
{value: 'tas2r121', id: 'TAS2R121'},
{value: 'tas2r123', id: 'TAS2R123'},
{value: 'tas2r124', id: 'TAS2R124'},
{value: 'tas2r125', id: 'TAS2R125'},
{value: 'tas2r126', id: 'TAS2R126'},
{value: 'tas2r129', id: 'TAS2R129'},
{value: 'tas2r13', id: 'TAS2R13'},
{value: 'tas2r130', id: 'TAS2R130'},
{value: 'tas2r134', id: 'TAS2R134'},
{value: 'tas2r135', id: 'TAS2R135'},
{value: 'tas2r136', id: 'TAS2R136'},
{value: 'tas2r137', id: 'TAS2R137'},
{value: 'tas2r138', id: 'TAS2R138'},
{value: 'tas2r139', id: 'TAS2R139'},
{value: 'tas2r140', id: 'TAS2R140'},
{value: 'tas2r143', id: 'TAS2R143'},
{value: 'tas2r144', id: 'TAS2R144'},
{value: 'tas2r145', id: 'TAS2R145'},
{value: 'tas2r7l', id: 'TAS2R7L'},
{value: 'tasl', id: 'TASL'},
{value: 'tasor', id: 'TASOR'},
{value: 'tasor2', id: 'TASOR2'},
{value: 'tasp1', id: 'TASP1'},
{value: 'tat', id: 'TAT'},
{value: 'tatdn1', id: 'TATDN1'},
{value: 'tatdn2', id: 'TATDN2'},
{value: 'tatdn3', id: 'TATDN3'},
{value: 'tax1bp1', id: 'TAX1BP1'},
{value: 'tax1bp3', id: 'TAX1BP3'},
{value: 'tbata', id: 'TBATA'},
{value: 'tbc1d1', id: 'TBC1D1'},
{value: 'tbc1d10a', id: 'TBC1D10A'},
{value: 'tbc1d10b', id: 'TBC1D10B'},
{value: 'tbc1d10c', id: 'TBC1D10C'},
{value: 'tbc1d12', id: 'TBC1D12'},
{value: 'tbc1d13', id: 'TBC1D13'},
{value: 'tbc1d14', id: 'TBC1D14'},
{value: 'tbc1d15', id: 'TBC1D15'},
{value: 'tbc1d16', id: 'TBC1D16'},
{value: 'tbc1d17', id: 'TBC1D17'},
{value: 'tbc1d19', id: 'TBC1D19'},
{value: 'tbc1d2', id: 'TBC1D2'},
{value: 'tbc1d20', id: 'TBC1D20'},
{value: 'tbc1d21', id: 'TBC1D21'},
{value: 'tbc1d22a', id: 'TBC1D22A'},
{value: 'tbc1d22b', id: 'TBC1D22B'},
{value: 'tbc1d23', id: 'TBC1D23'},
{value: 'tbc1d24', id: 'TBC1D24'},
{value: 'tbc1d25', id: 'TBC1D25'},
{value: 'tbc1d2b', id: 'TBC1D2B'},
{value: 'tbc1d30', id: 'TBC1D30'},
{value: 'tbc1d31', id: 'TBC1D31'},
{value: 'tbc1d32', id: 'TBC1D32'},
{value: 'tbc1d4', id: 'TBC1D4'},
{value: 'tbc1d5', id: 'TBC1D5'},
{value: 'tbc1d7', id: 'TBC1D7'},
{value: 'tbc1d8', id: 'TBC1D8'},
{value: 'tbc1d8b', id: 'TBC1D8B'},
{value: 'tbc1d9', id: 'TBC1D9'},
{value: 'tbc1d9b', id: 'TBC1D9B'},
{value: 'tbca', id: 'TBCA'},
{value: 'tbcb', id: 'TBCB'},
{value: 'tbcc', id: 'TBCC'},
{value: 'tbccd1', id: 'TBCCD1'},
{value: 'tbcd', id: 'TBCD'},
{value: 'tbce', id: 'TBCE'},
{value: 'tbcel', id: 'TBCEL'},
{value: 'tbck', id: 'TBCK'},
{value: 'tbk1', id: 'TBK1'},
{value: 'tbkbp1', id: 'TBKBP1'},
{value: 'tbl1x', id: 'TBL1X'},
{value: 'tbl1xr1', id: 'TBL1XR1'},
{value: 'tbl2', id: 'TBL2'},
{value: 'tbl3', id: 'TBL3'},
{value: 'tbp', id: 'TBP'},
{value: 'tbpl1', id: 'TBPL1'},
{value: 'tbpl2', id: 'TBPL2'},
{value: 'tbr1', id: 'TBR1'},
{value: 'tbrg1', id: 'TBRG1'},
{value: 'tbrg4', id: 'TBRG4'},
{value: 'tbx1', id: 'TBX1'},
{value: 'tbx10', id: 'TBX10'},
{value: 'tbx15', id: 'TBX15'},
{value: 'tbx18', id: 'TBX18'},
{value: 'tbx19', id: 'TBX19'},
{value: 'tbx2', id: 'TBX2'},
{value: 'tbx20', id: 'TBX20'},
{value: 'tbx21', id: 'TBX21'},
{value: 'tbx22', id: 'TBX22'},
{value: 'tbx3', id: 'TBX3'},
{value: 'tbx4', id: 'TBX4'},
{value: 'tbx5', id: 'TBX5'},
{value: 'tbx6', id: 'TBX6'},
{value: 'tbxa2r', id: 'TBXA2R'},
{value: 'tbxas1', id: 'TBXAS1'},
{value: 'tbxt', id: 'TBXT'},
{value: 'tc2n', id: 'TC2N'},
{value: 'tcaf1', id: 'TCAF1'},
{value: 'tcaf2', id: 'TCAF2'},
{value: 'tcaf2c', id: 'TCAF2C'},
{value: 'tcaim', id: 'TCAIM'},
{value: 'tcam1', id: 'TCAM1'},
{value: 'tcap', id: 'TCAP'},
{value: 'tcea1', id: 'TCEA1'},
{value: 'tcea2', id: 'TCEA2'},
{value: 'tcea3', id: 'TCEA3'},
{value: 'tceal1', id: 'TCEAL1'},
{value: 'tceal3', id: 'TCEAL3'},
{value: 'tceal5', id: 'TCEAL5'},
{value: 'tceal6', id: 'TCEAL6'},
{value: 'tceal7', id: 'TCEAL7'},
{value: 'tceal8', id: 'TCEAL8'},
{value: 'tceal9', id: 'TCEAL9'},
{value: 'tceanc', id: 'TCEANC'},
{value: 'tceanc2', id: 'TCEANC2'},
{value: 'tcerg1', id: 'TCERG1'},
{value: 'tcerg1l', id: 'TCERG1L'},
{value: 'tcf12', id: 'TCF12'},
{value: 'tcf15', id: 'TCF15'},
{value: 'tcf19', id: 'TCF19'},
{value: 'tcf20', id: 'TCF20'},
{value: 'tcf21', id: 'TCF21'},
{value: 'tcf23', id: 'TCF23'},
{value: 'tcf24', id: 'TCF24'},
{value: 'tcf25', id: 'TCF25'},
{value: 'tcf3', id: 'TCF3'},
{value: 'tcf4', id: 'TCF4'},
{value: 'tcf7', id: 'TCF7'},
{value: 'tcf7l1', id: 'TCF7L1'},
{value: 'tcf7l2', id: 'TCF7L2'},
{value: 'tcfl5', id: 'TCFL5'},
{value: 'tchh', id: 'TCHH'},
{value: 'tchhl1', id: 'TCHHL1'},
{value: 'tchp', id: 'TCHP'},
{value: 'tcim', id: 'TCIM'},
{value: 'tcirg1', id: 'TCIRG1'},
{value: 'tcl1a', id: 'TCL1A'},
{value: 'tcn2', id: 'TCN2'},
{value: 'tcof1', id: 'TCOF1'},
{value: 'tcp1', id: 'TCP1'},
{value: 'tcp10b', id: 'TCP10B'},
{value: 'tcp11', id: 'TCP11'},
{value: 'tcp11l1', id: 'TCP11L1'},
{value: 'tcp11l2', id: 'TCP11L2'},
{value: 'tcp11x2', id: 'TCP11X2'},
{value: 'tcta', id: 'TCTA'},
{value: 'tcte1', id: 'TCTE1'},
{value: 'tctn1', id: 'TCTN1'},
{value: 'tctn2', id: 'TCTN2'},
{value: 'tctn3', id: 'TCTN3'},
{value: 'tdg', id: 'TDG'},
{value: 'tdgf1', id: 'TDGF1'},
{value: 'tdh', id: 'TDH'},
{value: 'tdo2', id: 'TDO2'},
{value: 'tdp1', id: 'TDP1'},
{value: 'tdp2', id: 'TDP2'},
{value: 'tdrd1', id: 'TDRD1'},
{value: 'tdrd12', id: 'TDRD12'},
{value: 'tdrd15', id: 'TDRD15'},
{value: 'tdrd3', id: 'TDRD3'},
{value: 'tdrd5', id: 'TDRD5'},
{value: 'tdrd6', id: 'TDRD6'},
{value: 'tdrd7', id: 'TDRD7'},
{value: 'tdrd9', id: 'TDRD9'},
{value: 'tdrkh', id: 'TDRKH'},
{value: 'tdrp', id: 'TDRP'},
{value: 'tead1', id: 'TEAD1'},
{value: 'tead2', id: 'TEAD2'},
{value: 'tead3', id: 'TEAD3'},
{value: 'tead4', id: 'TEAD4'},
{value: 'tec', id: 'TEC'},
{value: 'tecpr1', id: 'TECPR1'},
{value: 'tecpr2', id: 'TECPR2'},
{value: 'tecr', id: 'TECR'},
{value: 'tecrl', id: 'TECRL'},
{value: 'tecta', id: 'TECTA'},
{value: 'tectb', id: 'TECTB'},
{value: 'tedc1', id: 'TEDC1'},
{value: 'tedc2', id: 'TEDC2'},
{value: 'teddm1', id: 'TEDDM1'},
{value: 'tef', id: 'TEF'},
{value: 'tefm', id: 'TEFM'},
{value: 'tek', id: 'TEK'},
{value: 'tekt1', id: 'TEKT1'},
{value: 'tekt2', id: 'TEKT2'},
{value: 'tekt3', id: 'TEKT3'},
{value: 'tekt4', id: 'TEKT4'},
{value: 'tekt5', id: 'TEKT5'},
{value: 'telo2', id: 'TELO2'},
{value: 'telomerase-vert', id: 'TELOMERASE-VERT'},
{value: 'ten1', id: 'TEN1'},
{value: 'tenm1', id: 'TENM1'},
{value: 'tenm2', id: 'TENM2'},
{value: 'tenm3', id: 'TENM3'},
{value: 'tenm4', id: 'TENM4'},
{value: 'tent2', id: 'TENT2'},
{value: 'tent4a', id: 'TENT4A'},
{value: 'tent4b', id: 'TENT4B'},
{value: 'tent5a', id: 'TENT5A'},
{value: 'tent5b', id: 'TENT5B'},
{value: 'tent5c', id: 'TENT5C'},
{value: 'tent5d', id: 'TENT5D'},
{value: 'tep1', id: 'TEP1'},
{value: 'tepp', id: 'TEPP'},
{value: 'tepsin', id: 'TEPSIN'},
{value: 'terb1', id: 'TERB1'},
{value: 'terb2', id: 'TERB2'},
{value: 'terc', id: 'TERC'},
{value: 'terf1', id: 'TERF1'},
{value: 'terf2', id: 'TERF2'},
{value: 'terf2ip', id: 'TERF2IP'},
{value: 'tert', id: 'TERT'},
{value: 'tes', id: 'TES'},
{value: 'tesc', id: 'TESC'},
{value: 'tescl', id: 'TESCL'},
{value: 'tesk1', id: 'TESK1'},
{value: 'tesk2', id: 'TESK2'},
{value: 'tesl', id: 'TESL'},
{value: 'tesmin', id: 'TESMIN'},
{value: 'testin', id: 'TESTIN'},
{value: 'tet1', id: 'TET1'},
{value: 'tet2', id: 'TET2'},
{value: 'tet3', id: 'TET3'},
{value: 'tex10', id: 'TEX10'},
{value: 'tex101', id: 'TEX101'},
{value: 'tex11', id: 'TEX11'},
{value: 'tex12', id: 'TEX12'},
{value: 'tex13a', id: 'TEX13A'},
{value: 'tex13b', id: 'TEX13B'},
{value: 'tex13c', id: 'TEX13C'},
{value: 'tex14', id: 'TEX14'},
{value: 'tex15', id: 'TEX15'},
{value: 'tex19.1', id: 'TEX19.1'},
{value: 'tex19.2', id: 'TEX19.2'},
{value: 'tex2', id: 'TEX2'},
{value: 'tex21', id: 'TEX21'},
{value: 'tex22', id: 'TEX22'},
{value: 'tex26', id: 'TEX26'},
{value: 'tex261', id: 'TEX261'},
{value: 'tex264', id: 'TEX264'},
{value: 'tex28', id: 'TEX28'},
{value: 'tex29', id: 'TEX29'},
{value: 'tex30', id: 'TEX30'},
{value: 'tex33', id: 'TEX33'},
{value: 'tex35', id: 'TEX35'},
{value: 'tex36', id: 'TEX36'},
{value: 'tex37', id: 'TEX37'},
{value: 'tex38', id: 'TEX38'},
{value: 'tex43', id: 'TEX43'},
{value: 'tex44', id: 'TEX44'},
{value: 'tex45', id: 'TEX45'},
{value: 'tex46', id: 'TEX46'},
{value: 'tex47', id: 'TEX47'},
{value: 'tex49', id: 'TEX49'},
{value: 'tex50', id: 'TEX50'},
{value: 'tex52', id: 'TEX52'},
{value: 'tex55', id: 'TEX55'},
{value: 'tex9', id: 'TEX9'},
{value: 'tf', id: 'TF'},
{value: 'tfam', id: 'TFAM'},
{value: 'tfap2a', id: 'TFAP2A'},
{value: 'tfap2b', id: 'TFAP2B'},
{value: 'tfap2c', id: 'TFAP2C'},
{value: 'tfap2d', id: 'TFAP2D'},
{value: 'tfap2e', id: 'TFAP2E'},
{value: 'tfap4', id: 'TFAP4'},
{value: 'tfb1m', id: 'TFB1M'},
{value: 'tfb2m', id: 'TFB2M'},
{value: 'tfcp2', id: 'TFCP2'},
{value: 'tfcp2l1', id: 'TFCP2L1'},
{value: 'tfdp1', id: 'TFDP1'},
{value: 'tfdp2', id: 'TFDP2'},
{value: 'tfe3', id: 'TFE3'},
{value: 'tfeb', id: 'TFEB'},
{value: 'tfec', id: 'TFEC'},
{value: 'tff1', id: 'TFF1'},
{value: 'tff2', id: 'TFF2'},
{value: 'tff3', id: 'TFF3'},
{value: 'tfg', id: 'TFG'},
{value: 'tfip11', id: 'TFIP11'},
{value: 'tfpi', id: 'TFPI'},
{value: 'tfpi2', id: 'TFPI2'},
{value: 'tfpt', id: 'TFPT'},
{value: 'tfr2', id: 'TFR2'},
{value: 'tfrc', id: 'TFRC'},
{value: 'tg', id: 'TG'},
{value: 'tgap1', id: 'TGAP1'},
{value: 'tgds', id: 'TGDS'},
{value: 'tgfa', id: 'TGFA'},
{value: 'tgfb1', id: 'TGFB1'},
{value: 'tgfb1i1', id: 'TGFB1I1'},
{value: 'tgfb2', id: 'TGFB2'},
{value: 'tgfb3', id: 'TGFB3'},
{value: 'tgfbi', id: 'TGFBI'},
{value: 'tgfbr1', id: 'TGFBR1'},
{value: 'tgfbr2', id: 'TGFBR2'},
{value: 'tgfbr3', id: 'TGFBR3'},
{value: 'tgfbr3l', id: 'TGFBR3L'},
{value: 'tgfbrap1', id: 'TGFBRAP1'},
{value: 'tgif1', id: 'TGIF1'},
{value: 'tgif2', id: 'TGIF2'},
{value: 'tgif2lx2', id: 'TGIF2LX2'},
{value: 'tgm1', id: 'TGM1'},
{value: 'tgm2', id: 'TGM2'},
{value: 'tgm3', id: 'TGM3'},
{value: 'tgm4', id: 'TGM4'},
{value: 'tgm5', id: 'TGM5'},
{value: 'tgm6', id: 'TGM6'},
{value: 'tgm7', id: 'TGM7'},
{value: 'tgoln2', id: 'TGOLN2'},
{value: 'tgs1', id: 'TGS1'},
{value: 'th', id: 'TH'},
{value: 'thada', id: 'THADA'},
{value: 'thap1', id: 'THAP1'},
{value: 'thap11', id: 'THAP11'},
{value: 'thap12', id: 'THAP12'},
{value: 'thap2', id: 'THAP2'},
{value: 'thap3', id: 'THAP3'},
{value: 'thap4', id: 'THAP4'},
{value: 'thap6', id: 'THAP6'},
{value: 'thap7', id: 'THAP7'},
{value: 'thbd', id: 'THBD'},
{value: 'thbs1', id: 'THBS1'},
{value: 'thbs2', id: 'THBS2'},
{value: 'thbs3', id: 'THBS3'},
{value: 'thbs4', id: 'THBS4'},
{value: 'theg', id: 'THEG'},
{value: 'thegl', id: 'THEGL'},
{value: 'them4', id: 'THEM4'},
{value: 'them5', id: 'THEM5'},
{value: 'them6', id: 'THEM6'},
{value: 'themis', id: 'THEMIS'},
{value: 'themis2', id: 'THEMIS2'},
{value: 'thg1l', id: 'THG1L'},
{value: 'thnsl1', id: 'THNSL1'},
{value: 'thnsl2', id: 'THNSL2'},
{value: 'thoc1', id: 'THOC1'},
{value: 'thoc2', id: 'THOC2'},
{value: 'thoc3', id: 'THOC3'},
{value: 'thoc5', id: 'THOC5'},
{value: 'thoc6', id: 'THOC6'},
{value: 'thoc7', id: 'THOC7'},
{value: 'thop1', id: 'THOP1'},
{value: 'thpo', id: 'THPO'},
{value: 'thpol1', id: 'THPOL1'},
{value: 'thra', id: 'THRA'},
{value: 'thrap3', id: 'THRAP3'},
{value: 'thrb', id: 'THRB'},
{value: 'thrsp', id: 'THRSP'},
{value: 'thsd1', id: 'THSD1'},
{value: 'thsd4', id: 'THSD4'},
{value: 'thsd7a', id: 'THSD7A'},
{value: 'thsd7b', id: 'THSD7B'},
{value: 'thtpa', id: 'THTPA'},
{value: 'thumpd1', id: 'THUMPD1'},
{value: 'thumpd2', id: 'THUMPD2'},
{value: 'thumpd3', id: 'THUMPD3'},
{value: 'thy1', id: 'THY1'},
{value: 'thyn1', id: 'THYN1'},
{value: 'tia1', id: 'TIA1'},
{value: 'tial1', id: 'TIAL1'},
{value: 'tiam1', id: 'TIAM1'},
{value: 'tiam2', id: 'TIAM2'},
{value: 'ticam2', id: 'TICAM2'},
{value: 'ticrr', id: 'TICRR'},
{value: 'tie1', id: 'TIE1'},
{value: 'tifa', id: 'TIFA'},
{value: 'tifab', id: 'TIFAB'},
{value: 'tigar', id: 'TIGAR'},
{value: 'tigd2', id: 'TIGD2'},
{value: 'tigd3', id: 'TIGD3'},
{value: 'tigd4', id: 'TIGD4'},
{value: 'tigd5', id: 'TIGD5'},
{value: 'tigit', id: 'TIGIT'},
{value: 'timd2', id: 'TIMD2'},
{value: 'timd4', id: 'TIMD4'},
{value: 'timeless', id: 'TIMELESS'},
{value: 'timm10', id: 'TIMM10'},
{value: 'timm10b', id: 'TIMM10B'},
{value: 'timm13', id: 'TIMM13'},
{value: 'timm17a', id: 'TIMM17A'},
{value: 'timm17b', id: 'TIMM17B'},
{value: 'timm21', id: 'TIMM21'},
{value: 'timm22', id: 'TIMM22'},
{value: 'timm23', id: 'TIMM23'},
{value: 'timm23b', id: 'TIMM23B'},
{value: 'timm29', id: 'TIMM29'},
{value: 'timm44', id: 'TIMM44'},
{value: 'timm50', id: 'TIMM50'},
{value: 'timm8a1', id: 'TIMM8A1'},
{value: 'timm8a2', id: 'TIMM8A2'},
{value: 'timm8b', id: 'TIMM8B'},
{value: 'timm9', id: 'TIMM9'},
{value: 'timmdc1', id: 'TIMMDC1'},
{value: 'timp1', id: 'TIMP1'},
{value: 'timp2', id: 'TIMP2'},
{value: 'timp3', id: 'TIMP3'},
{value: 'timp4', id: 'TIMP4'},
{value: 'tinag', id: 'TINAG'},
{value: 'tinagl1', id: 'TINAGL1'},
{value: 'tinf2', id: 'TINF2'},
{value: 'tiparp', id: 'TIPARP'},
{value: 'tipin', id: 'TIPIN'},
{value: 'tipinl1', id: 'TIPINL1'},
{value: 'tiprl', id: 'TIPRL'},
{value: 'tirap', id: 'TIRAP'},
{value: 'tjap1', id: 'TJAP1'},
{value: 'tjp1', id: 'TJP1'},
{value: 'tjp2', id: 'TJP2'},
{value: 'tjp3', id: 'TJP3'},
{value: 'tk1', id: 'TK1'},
{value: 'tk2', id: 'TK2'},
{value: 'tkfc', id: 'TKFC'},
{value: 'tkt', id: 'TKT'},
{value: 'tktl1', id: 'TKTL1'},
{value: 'tktl2', id: 'TKTL2'},
{value: 'tlcd1', id: 'TLCD1'},
{value: 'tlcd2', id: 'TLCD2'},
{value: 'tlcd3a', id: 'TLCD3A'},
{value: 'tlcd3b', id: 'TLCD3B'},
{value: 'tlcd4', id: 'TLCD4'},
{value: 'tlcd5', id: 'TLCD5'},
{value: 'tldc2', id: 'TLDC2'},
{value: 'tle1', id: 'TLE1'},
{value: 'tle2', id: 'TLE2'},
{value: 'tle3', id: 'TLE3'},
{value: 'tle4', id: 'TLE4'},
{value: 'tle5', id: 'TLE5'},
{value: 'tle6', id: 'TLE6'},
{value: 'tle7', id: 'TLE7'},
{value: 'tlk1', id: 'TLK1'},
{value: 'tlk2', id: 'TLK2'},
{value: 'tll1', id: 'TLL1'},
{value: 'tll2', id: 'TLL2'},
{value: 'tln1', id: 'TLN1'},
{value: 'tln2', id: 'TLN2'},
{value: 'tlnrd1', id: 'TLNRD1'},
{value: 'tlr1', id: 'TLR1'},
{value: 'tlr11', id: 'TLR11'},
{value: 'tlr12', id: 'TLR12'},
{value: 'tlr13', id: 'TLR13'},
{value: 'tlr2', id: 'TLR2'},
{value: 'tlr3', id: 'TLR3'},
{value: 'tlr4', id: 'TLR4'},
{value: 'tlr5', id: 'TLR5'},
{value: 'tlr6', id: 'TLR6'},
{value: 'tlr7', id: 'TLR7'},
{value: 'tlr8', id: 'TLR8'},
{value: 'tlr9', id: 'TLR9'},
{value: 'tlx1', id: 'TLX1'},
{value: 'tlx2', id: 'TLX2'},
{value: 'tlx3', id: 'TLX3'},
{value: 'tm2d1', id: 'TM2D1'},
{value: 'tm2d2', id: 'TM2D2'},
{value: 'tm2d3', id: 'TM2D3'},
{value: 'tm4sf1', id: 'TM4SF1'},
{value: 'tm4sf19', id: 'TM4SF19'},
{value: 'tm4sf20', id: 'TM4SF20'},
{value: 'tm4sf4', id: 'TM4SF4'},
{value: 'tm4sf5', id: 'TM4SF5'},
{value: 'tm6sf1', id: 'TM6SF1'},
{value: 'tm6sf2', id: 'TM6SF2'},
{value: 'tm7sf2', id: 'TM7SF2'},
{value: 'tm7sf3', id: 'TM7SF3'},
{value: 'tm9sf1', id: 'TM9SF1'},
{value: 'tm9sf2', id: 'TM9SF2'},
{value: 'tm9sf3', id: 'TM9SF3'},
{value: 'tm9sf4', id: 'TM9SF4'},
{value: 'tma16', id: 'TMA16'},
{value: 'tma7', id: 'TMA7'},
{value: 'tmbim1', id: 'TMBIM1'},
{value: 'tmbim4', id: 'TMBIM4'},
{value: 'tmbim6', id: 'TMBIM6'},
{value: 'tmbim7', id: 'TMBIM7'},
{value: 'tmc1', id: 'TMC1'},
{value: 'tmc2', id: 'TMC2'},
{value: 'tmc3', id: 'TMC3'},
{value: 'tmc4', id: 'TMC4'},
{value: 'tmc5', id: 'TMC5'},
{value: 'tmc7', id: 'TMC7'},
{value: 'tmc8', id: 'TMC8'},
{value: 'tmcc1', id: 'TMCC1'},
{value: 'tmcc2', id: 'TMCC2'},
{value: 'tmcc3', id: 'TMCC3'},
{value: 'tmco1', id: 'TMCO1'},
{value: 'tmco2', id: 'TMCO2'},
{value: 'tmco3', id: 'TMCO3'},
{value: 'tmco4', id: 'TMCO4'},
{value: 'tmco5a', id: 'TMCO5A'},
{value: 'tmco5b', id: 'TMCO5B'},
{value: 'tmco6', id: 'TMCO6'},
{value: 'tmed1', id: 'TMED1'},
{value: 'tmed10', id: 'TMED10'},
{value: 'tmed11', id: 'TMED11'},
{value: 'tmed2', id: 'TMED2'},
{value: 'tmed3', id: 'TMED3'},
{value: 'tmed4', id: 'TMED4'},
{value: 'tmed5', id: 'TMED5'},
{value: 'tmed6', id: 'TMED6'},
{value: 'tmed7', id: 'TMED7'},
{value: 'tmed8', id: 'TMED8'},
{value: 'tmed9', id: 'TMED9'},
{value: 'tmeff1', id: 'TMEFF1'},
{value: 'tmeff2', id: 'TMEFF2'},
{value: 'tmem100', id: 'TMEM100'},
{value: 'tmem101', id: 'TMEM101'},
{value: 'tmem102', id: 'TMEM102'},
{value: 'tmem104', id: 'TMEM104'},
{value: 'tmem106a', id: 'TMEM106A'},
{value: 'tmem106b', id: 'TMEM106B'},
{value: 'tmem106c', id: 'TMEM106C'},
{value: 'tmem107', id: 'TMEM107'},
{value: 'tmem108', id: 'TMEM108'},
{value: 'tmem109', id: 'TMEM109'},
{value: 'tmem11', id: 'TMEM11'},
{value: 'tmem114', id: 'TMEM114'},
{value: 'tmem115', id: 'TMEM115'},
{value: 'tmem116', id: 'TMEM116'},
{value: 'tmem117', id: 'TMEM117'},
{value: 'tmem119', id: 'TMEM119'},
{value: 'tmem120a', id: 'TMEM120A'},
{value: 'tmem120b', id: 'TMEM120B'},
{value: 'tmem121', id: 'TMEM121'},
{value: 'tmem121b', id: 'TMEM121B'},
{value: 'tmem123', id: 'TMEM123'},
{value: 'tmem125', id: 'TMEM125'},
{value: 'tmem126a', id: 'TMEM126A'},
{value: 'tmem126b', id: 'TMEM126B'},
{value: 'tmem127', id: 'TMEM127'},
{value: 'tmem128', id: 'TMEM128'},
{value: 'tmem129', id: 'TMEM129'},
{value: 'tmem130', id: 'TMEM130'},
{value: 'tmem131', id: 'TMEM131'},
{value: 'tmem131l', id: 'TMEM131L'},
{value: 'tmem132a', id: 'TMEM132A'},
{value: 'tmem132b', id: 'TMEM132B'},
{value: 'tmem132c', id: 'TMEM132C'},
{value: 'tmem132d', id: 'TMEM132D'},
{value: 'tmem132e', id: 'TMEM132E'},
{value: 'tmem134', id: 'TMEM134'},
{value: 'tmem135', id: 'TMEM135'},
{value: 'tmem138', id: 'TMEM138'},
{value: 'tmem139', id: 'TMEM139'},
{value: 'tmem140', id: 'TMEM140'},
{value: 'tmem141', id: 'TMEM141'},
{value: 'tmem143', id: 'TMEM143'},
{value: 'tmem144', id: 'TMEM144'},
{value: 'tmem145', id: 'TMEM145'},
{value: 'tmem147', id: 'TMEM147'},
{value: 'tmem14a', id: 'TMEM14A'},
{value: 'tmem14c', id: 'TMEM14C'},
{value: 'tmem150a', id: 'TMEM150A'},
{value: 'tmem150b', id: 'TMEM150B'},
{value: 'tmem150c', id: 'TMEM150C'},
{value: 'tmem151a', id: 'TMEM151A'},
{value: 'tmem151b', id: 'TMEM151B'},
{value: 'tmem154', id: 'TMEM154'},
{value: 'tmem156', id: 'TMEM156'},
{value: 'tmem158', id: 'TMEM158'},
{value: 'tmem160', id: 'TMEM160'},
{value: 'tmem161a', id: 'TMEM161A'},
{value: 'tmem161b', id: 'TMEM161B'},
{value: 'tmem163', id: 'TMEM163'},
{value: 'tmem164', id: 'TMEM164'},
{value: 'tmem165', id: 'TMEM165'},
{value: 'tmem167a', id: 'TMEM167A'},
{value: 'tmem167b', id: 'TMEM167B'},
{value: 'tmem168', id: 'TMEM168'},
{value: 'tmem169', id: 'TMEM169'},
{value: 'tmem17', id: 'TMEM17'},
{value: 'tmem170a', id: 'TMEM170A'},
{value: 'tmem170b', id: 'TMEM170B'},
{value: 'tmem171', id: 'TMEM171'},
{value: 'tmem174', id: 'TMEM174'},
{value: 'tmem175', id: 'TMEM175'},
{value: 'tmem176a', id: 'TMEM176A'},
{value: 'tmem176b', id: 'TMEM176B'},
{value: 'tmem177', id: 'TMEM177'},
{value: 'tmem178a', id: 'TMEM178A'},
{value: 'tmem178b', id: 'TMEM178B'},
{value: 'tmem179', id: 'TMEM179'},
{value: 'tmem179b', id: 'TMEM179B'},
{value: 'tmem18', id: 'TMEM18'},
{value: 'tmem181', id: 'TMEM181'},
{value: 'tmem182', id: 'TMEM182'},
{value: 'tmem183a', id: 'TMEM183A'},
{value: 'tmem184a', id: 'TMEM184A'},
{value: 'tmem184b', id: 'TMEM184B'},
{value: 'tmem184c', id: 'TMEM184C'},
{value: 'tmem185a', id: 'TMEM185A'},
{value: 'tmem185b', id: 'TMEM185B'},
{value: 'tmem186', id: 'TMEM186'},
{value: 'tmem19', id: 'TMEM19'},
{value: 'tmem190', id: 'TMEM190'},
{value: 'tmem191c', id: 'TMEM191C'},
{value: 'tmem192', id: 'TMEM192'},
{value: 'tmem196', id: 'TMEM196'},
{value: 'tmem198', id: 'TMEM198'},
{value: 'tmem198b', id: 'TMEM198B'},
{value: 'tmem199', id: 'TMEM199'},
{value: 'tmem200a', id: 'TMEM200A'},
{value: 'tmem200b', id: 'TMEM200B'},
{value: 'tmem200c', id: 'TMEM200C'},
{value: 'tmem201', id: 'TMEM201'},
{value: 'tmem202', id: 'TMEM202'},
{value: 'tmem203', id: 'TMEM203'},
{value: 'tmem204', id: 'TMEM204'},
{value: 'tmem205', id: 'TMEM205'},
{value: 'tmem207', id: 'TMEM207'},
{value: 'tmem208', id: 'TMEM208'},
{value: 'tmem209', id: 'TMEM209'},
{value: 'tmem210', id: 'TMEM210'},
{value: 'tmem211', id: 'TMEM211'},
{value: 'tmem212', id: 'TMEM212'},
{value: 'tmem213', id: 'TMEM213'},
{value: 'tmem214', id: 'TMEM214'},
{value: 'tmem215', id: 'TMEM215'},
{value: 'tmem216', id: 'TMEM216'},
{value: 'tmem217', id: 'TMEM217'},
{value: 'tmem218', id: 'TMEM218'},
{value: 'tmem219', id: 'TMEM219'},
{value: 'tmem220', id: 'TMEM220'},
{value: 'tmem221', id: 'TMEM221'},
{value: 'tmem222', id: 'TMEM222'},
{value: 'tmem223', id: 'TMEM223'},
{value: 'tmem225', id: 'TMEM225'},
{value: 'tmem229a', id: 'TMEM229A'},
{value: 'tmem229b', id: 'TMEM229B'},
{value: 'tmem230', id: 'TMEM230'},
{value: 'tmem231', id: 'TMEM231'},
{value: 'tmem232', id: 'TMEM232'},
{value: 'tmem233', id: 'TMEM233'},
{value: 'tmem234', id: 'TMEM234'},
{value: 'tmem235', id: 'TMEM235'},
{value: 'tmem236', id: 'TMEM236'},
{value: 'tmem237', id: 'TMEM237'},
{value: 'tmem238', id: 'TMEM238'},
{value: 'tmem239', id: 'TMEM239'},
{value: 'tmem240', id: 'TMEM240'},
{value: 'tmem241', id: 'TMEM241'},
{value: 'tmem242', id: 'TMEM242'},
{value: 'tmem243', id: 'TMEM243'},
{value: 'tmem245', id: 'TMEM245'},
{value: 'tmem247', id: 'TMEM247'},
{value: 'tmem248', id: 'TMEM248'},
{value: 'tmem25', id: 'TMEM25'},
{value: 'tmem250', id: 'TMEM250'},
{value: 'tmem251', id: 'TMEM251'},
{value: 'tmem252', id: 'TMEM252'},
{value: 'tmem253', id: 'TMEM253'},
{value: 'tmem254', id: 'TMEM254'},
{value: 'tmem255a', id: 'TMEM255A'},
{value: 'tmem255b', id: 'TMEM255B'},
{value: 'tmem256', id: 'TMEM256'},
{value: 'tmem258', id: 'TMEM258'},
{value: 'tmem258b', id: 'TMEM258B'},
{value: 'tmem259', id: 'TMEM259'},
{value: 'tmem26', id: 'TMEM26'},
{value: 'tmem260', id: 'TMEM260'},
{value: 'tmem262', id: 'TMEM262'},
{value: 'tmem263', id: 'TMEM263'},
{value: 'tmem266', id: 'TMEM266'},
{value: 'tmem267', id: 'TMEM267'},
{value: 'tmem268', id: 'TMEM268'},
{value: 'tmem269', id: 'TMEM269'},
{value: 'tmem270', id: 'TMEM270'},
{value: 'tmem273', id: 'TMEM273'},
{value: 'tmem30a', id: 'TMEM30A'},
{value: 'tmem30b', id: 'TMEM30B'},
{value: 'tmem30c', id: 'TMEM30C'},
{value: 'tmem33', id: 'TMEM33'},
{value: 'tmem35a', id: 'TMEM35A'},
{value: 'tmem35b', id: 'TMEM35B'},
{value: 'tmem37', id: 'TMEM37'},
{value: 'tmem38a', id: 'TMEM38A'},
{value: 'tmem38b', id: 'TMEM38B'},
{value: 'tmem39a', id: 'TMEM39A'},
{value: 'tmem39b', id: 'TMEM39B'},
{value: 'tmem40', id: 'TMEM40'},
{value: 'tmem41a', id: 'TMEM41A'},
{value: 'tmem41b', id: 'TMEM41B'},
{value: 'tmem42', id: 'TMEM42'},
{value: 'tmem43', id: 'TMEM43'},
{value: 'tmem44', id: 'TMEM44'},
{value: 'tmem45a', id: 'TMEM45A'},
{value: 'tmem45al', id: 'TMEM45AL'},
{value: 'tmem45b', id: 'TMEM45B'},
{value: 'tmem47', id: 'TMEM47'},
{value: 'tmem50a', id: 'TMEM50A'},
{value: 'tmem50b', id: 'TMEM50B'},
{value: 'tmem51', id: 'TMEM51'},
{value: 'tmem52', id: 'TMEM52'},
{value: 'tmem52b', id: 'TMEM52B'},
{value: 'tmem53', id: 'TMEM53'},
{value: 'tmem54', id: 'TMEM54'},
{value: 'tmem59', id: 'TMEM59'},
{value: 'tmem59l', id: 'TMEM59L'},
{value: 'tmem60', id: 'TMEM60'},
{value: 'tmem61', id: 'TMEM61'},
{value: 'tmem62', id: 'TMEM62'},
{value: 'tmem63a', id: 'TMEM63A'},
{value: 'tmem63b', id: 'TMEM63B'},
{value: 'tmem63c', id: 'TMEM63C'},
{value: 'tmem64', id: 'TMEM64'},
{value: 'tmem65', id: 'TMEM65'},
{value: 'tmem67', id: 'TMEM67'},
{value: 'tmem68', id: 'TMEM68'},
{value: 'tmem69', id: 'TMEM69'},
{value: 'tmem70', id: 'TMEM70'},
{value: 'tmem71', id: 'TMEM71'},
{value: 'tmem72', id: 'TMEM72'},
{value: 'tmem74', id: 'TMEM74'},
{value: 'tmem74b', id: 'TMEM74B'},
{value: 'tmem79', id: 'TMEM79'},
{value: 'tmem80', id: 'TMEM80'},
{value: 'tmem81', id: 'TMEM81'},
{value: 'tmem82', id: 'TMEM82'},
{value: 'tmem86a', id: 'TMEM86A'},
{value: 'tmem86b', id: 'TMEM86B'},
{value: 'tmem87a', id: 'TMEM87A'},
{value: 'tmem87b', id: 'TMEM87B'},
{value: 'tmem88', id: 'TMEM88'},
{value: 'tmem88b', id: 'TMEM88B'},
{value: 'tmem89', id: 'TMEM89'},
{value: 'tmem8b', id: 'TMEM8B'},
{value: 'tmem9', id: 'TMEM9'},
{value: 'tmem91', id: 'TMEM91'},
{value: 'tmem94', id: 'TMEM94'},
{value: 'tmem95', id: 'TMEM95'},
{value: 'tmem97', id: 'TMEM97'},
{value: 'tmem98', id: 'TMEM98'},
{value: 'tmem9b', id: 'TMEM9B'},
{value: 'tmf1', id: 'TMF1'},
{value: 'tmie', id: 'TMIE'},
{value: 'tmigd1', id: 'TMIGD1'},
{value: 'tmigd3', id: 'TMIGD3'},
{value: 'tmlhe', id: 'TMLHE'},
{value: 'tmod1', id: 'TMOD1'},
{value: 'tmod2', id: 'TMOD2'},
{value: 'tmod3', id: 'TMOD3'},
{value: 'tmod4', id: 'TMOD4'},
{value: 'tmpo', id: 'TMPO'},
{value: 'tmppe', id: 'TMPPE'},
{value: 'tmprss11a', id: 'TMPRSS11A'},
{value: 'tmprss11b', id: 'TMPRSS11B'},
{value: 'tmprss11c', id: 'TMPRSS11C'},
{value: 'tmprss11d', id: 'TMPRSS11D'},
{value: 'tmprss11e', id: 'TMPRSS11E'},
{value: 'tmprss11f', id: 'TMPRSS11F'},
{value: 'tmprss11g', id: 'TMPRSS11G'},
{value: 'tmprss12', id: 'TMPRSS12'},
{value: 'tmprss13', id: 'TMPRSS13'},
{value: 'tmprss15', id: 'TMPRSS15'},
{value: 'tmprss2', id: 'TMPRSS2'},
{value: 'tmprss3', id: 'TMPRSS3'},
{value: 'tmprss4', id: 'TMPRSS4'},
{value: 'tmprss5', id: 'TMPRSS5'},
{value: 'tmprss6', id: 'TMPRSS6'},
{value: 'tmprss7', id: 'TMPRSS7'},
{value: 'tmprss9', id: 'TMPRSS9'},
{value: 'tmsb10', id: 'TMSB10'},
{value: 'tmsb15b2', id: 'TMSB15B2'},
{value: 'tmsb4x', id: 'TMSB4X'},
{value: 'tmtc1', id: 'TMTC1'},
{value: 'tmtc2', id: 'TMTC2'},
{value: 'tmtc3', id: 'TMTC3'},
{value: 'tmtc4', id: 'TMTC4'},
{value: 'tmub1', id: 'TMUB1'},
{value: 'tmub2', id: 'TMUB2'},
{value: 'tmx1', id: 'TMX1'},
{value: 'tmx2', id: 'TMX2'},
{value: 'tmx3', id: 'TMX3'},
{value: 'tmx4', id: 'TMX4'},
{value: 'tnc', id: 'TNC'},
{value: 'tnf', id: 'TNF'},
{value: 'tnfaip1', id: 'TNFAIP1'},
{value: 'tnfaip2', id: 'TNFAIP2'},
{value: 'tnfaip3', id: 'TNFAIP3'},
{value: 'tnfaip6', id: 'TNFAIP6'},
{value: 'tnfaip8', id: 'TNFAIP8'},
{value: 'tnfaip8l1', id: 'TNFAIP8L1'},
{value: 'tnfaip8l2', id: 'TNFAIP8L2'},
{value: 'tnfaip8l3', id: 'TNFAIP8L3'},
{value: 'tnfrsf11a', id: 'TNFRSF11A'},
{value: 'tnfrsf11b', id: 'TNFRSF11B'},
{value: 'tnfrsf12a', id: 'TNFRSF12A'},
{value: 'tnfrsf13b', id: 'TNFRSF13B'},
{value: 'tnfrsf13c', id: 'TNFRSF13C'},
{value: 'tnfrsf14', id: 'TNFRSF14'},
{value: 'tnfrsf17', id: 'TNFRSF17'},
{value: 'tnfrsf18', id: 'TNFRSF18'},
{value: 'tnfrsf19', id: 'TNFRSF19'},
{value: 'tnfrsf1a', id: 'TNFRSF1A'},
{value: 'tnfrsf1b', id: 'TNFRSF1B'},
{value: 'tnfrsf21', id: 'TNFRSF21'},
{value: 'tnfrsf25', id: 'TNFRSF25'},
{value: 'tnfrsf26', id: 'TNFRSF26'},
{value: 'tnfrsf4', id: 'TNFRSF4'},
{value: 'tnfrsf8', id: 'TNFRSF8'},
{value: 'tnfrsf9', id: 'TNFRSF9'},
{value: 'tnfsf10', id: 'TNFSF10'},
{value: 'tnfsf11', id: 'TNFSF11'},
{value: 'tnfsf12', id: 'TNFSF12'},
{value: 'tnfsf13', id: 'TNFSF13'},
{value: 'tnfsf13b', id: 'TNFSF13B'},
{value: 'tnfsf14', id: 'TNFSF14'},
{value: 'tnfsf15', id: 'TNFSF15'},
{value: 'tnfsf18', id: 'TNFSF18'},
{value: 'tnfsf4', id: 'TNFSF4'},
{value: 'tnfsf8', id: 'TNFSF8'},
{value: 'tnfsf9', id: 'TNFSF9'},
{value: 'tnik', id: 'TNIK'},
{value: 'tnip1', id: 'TNIP1'},
{value: 'tnip2', id: 'TNIP2'},
{value: 'tnip3', id: 'TNIP3'},
{value: 'tnk1', id: 'TNK1'},
{value: 'tnk2', id: 'TNK2'},
{value: 'tnks', id: 'TNKS'},
{value: 'tnks1bp1', id: 'TNKS1BP1'},
{value: 'tnks2', id: 'TNKS2'},
{value: 'tnmd', id: 'TNMD'},
{value: 'tnn', id: 'TNN'},
{value: 'tnnc1', id: 'TNNC1'},
{value: 'tnnc2', id: 'TNNC2'},
{value: 'tnni1', id: 'TNNI1'},
{value: 'tnni2', id: 'TNNI2'},
{value: 'tnni3', id: 'TNNI3'},
{value: 'tnni3k', id: 'TNNI3K'},
{value: 'tnnt1', id: 'TNNT1'},
{value: 'tnnt2', id: 'TNNT2'},
{value: 'tnnt3', id: 'TNNT3'},
{value: 'tnp1', id: 'TNP1'},
{value: 'tnp2', id: 'TNP2'},
{value: 'tnpo1', id: 'TNPO1'},
{value: 'tnpo2', id: 'TNPO2'},
{value: 'tnpo3', id: 'TNPO3'},
{value: 'tnr', id: 'TNR'},
{value: 'tnrc18', id: 'TNRC18'},
{value: 'tnrc6a', id: 'TNRC6A'},
{value: 'tnrc6b', id: 'TNRC6B'},
{value: 'tnrc6c', id: 'TNRC6C'},
{value: 'tns1', id: 'TNS1'},
{value: 'tns2', id: 'TNS2'},
{value: 'tns3', id: 'TNS3'},
{value: 'tns4', id: 'TNS4'},
{value: 'tob1', id: 'TOB1'},
{value: 'tob2', id: 'TOB2'},
{value: 'toe1', id: 'TOE1'},
{value: 'togaram1', id: 'TOGARAM1'},
{value: 'togaram2', id: 'TOGARAM2'},
{value: 'tollip', id: 'TOLLIP'},
{value: 'tom1', id: 'TOM1'},
{value: 'tom1l1', id: 'TOM1L1'},
{value: 'tom1l2', id: 'TOM1L2'},
{value: 'tomm20', id: 'TOMM20'},
{value: 'tomm22', id: 'TOMM22'},
{value: 'tomm34', id: 'TOMM34'},
{value: 'tomm40', id: 'TOMM40'},
{value: 'tomm40l', id: 'TOMM40L'},
{value: 'tomm5', id: 'TOMM5'},
{value: 'tomm6', id: 'TOMM6'},
{value: 'tomm7', id: 'TOMM7'},
{value: 'tomm70', id: 'TOMM70'},
{value: 'tonsl', id: 'TONSL'},
{value: 'top1', id: 'TOP1'},
{value: 'top1mt', id: 'TOP1MT'},
{value: 'top2a', id: 'TOP2A'},
{value: 'top2b', id: 'TOP2B'},
{value: 'top3a', id: 'TOP3A'},
{value: 'top3b', id: 'TOP3B'},
{value: 'topaz1', id: 'TOPAZ1'},
{value: 'topbp1', id: 'TOPBP1'},
{value: 'topors', id: 'TOPORS'},
{value: 'tor1a', id: 'TOR1A'},
{value: 'tor1aip1', id: 'TOR1AIP1'},
{value: 'tor1aip2', id: 'TOR1AIP2'},
{value: 'tor1b', id: 'TOR1B'},
{value: 'tor2a', id: 'TOR2A'},
{value: 'tor3a', id: 'TOR3A'},
{value: 'tor4a', id: 'TOR4A'},
{value: 'tox', id: 'TOX'},
{value: 'tox2', id: 'TOX2'},
{value: 'tox3', id: 'TOX3'},
{value: 'tox4', id: 'TOX4'},
{value: 'tp53', id: 'TP53'},
{value: 'tp53bp1', id: 'TP53BP1'},
{value: 'tp53bp2', id: 'TP53BP2'},
{value: 'tp53i11', id: 'TP53I11'},
{value: 'tp53i13', id: 'TP53I13'},
{value: 'tp53i3', id: 'TP53I3'},
{value: 'tp53inp1', id: 'TP53INP1'},
{value: 'tp53inp2', id: 'TP53INP2'},
{value: 'tp53rk', id: 'TP53RK'},
{value: 'tp53tg5', id: 'TP53TG5'},
{value: 'tp63', id: 'TP63'},
{value: 'tp73', id: 'TP73'},
{value: 'tpbg', id: 'TPBG'},
{value: 'tpbgl', id: 'TPBGL'},
{value: 'tpbpa', id: 'TPBPA'},
{value: 'tpcn1', id: 'TPCN1'},
{value: 'tpcn2', id: 'TPCN2'},
{value: 'tpcr12', id: 'TPCR12'},
{value: 'tpd52', id: 'TPD52'},
{value: 'tpd52l1', id: 'TPD52L1'},
{value: 'tpd52l2', id: 'TPD52L2'},
{value: 'tpd52l3', id: 'TPD52L3'},
{value: 'tpgs1', id: 'TPGS1'},
{value: 'tpgs2', id: 'TPGS2'},
{value: 'tph1', id: 'TPH1'},
{value: 'tph2', id: 'TPH2'},
{value: 'tpi1', id: 'TPI1'},
{value: 'tpk1', id: 'TPK1'},
{value: 'tpm1', id: 'TPM1'},
{value: 'tpm2', id: 'TPM2'},
{value: 'tpm3', id: 'TPM3'},
{value: 'tpm4', id: 'TPM4'},
{value: 'tpmt', id: 'TPMT'},
{value: 'tpo', id: 'TPO'},
{value: 'tpp1', id: 'TPP1'},
{value: 'tpp2', id: 'TPP2'},
{value: 'tppp', id: 'TPPP'},
{value: 'tppp2', id: 'TPPP2'},
{value: 'tppp3', id: 'TPPP3'},
{value: 'tpr', id: 'TPR'},
{value: 'tpra1', id: 'TPRA1'},
{value: 'tprg1', id: 'TPRG1'},
{value: 'tprg1l', id: 'TPRG1L'},
{value: 'tprkb', id: 'TPRKB'},
{value: 'tprn', id: 'TPRN'},
{value: 'tpsab1', id: 'TPSAB1'},
{value: 'tpsb2', id: 'TPSB2'},
{value: 'tpsg1', id: 'TPSG1'},
{value: 'tpst1', id: 'TPST1'},
{value: 'tpst2', id: 'TPST2'},
{value: 'tpt1', id: 'TPT1'},
{value: 'tpte2', id: 'TPTE2'},
{value: 'tpx2', id: 'TPX2'},
{value: 'tra2a', id: 'TRA2A'},
{value: 'tra2b', id: 'TRA2B'},
{value: 'trabd', id: 'TRABD'},
{value: 'trabd2b', id: 'TRABD2B'},
{value: 'tradd', id: 'TRADD'},
{value: 'traf1', id: 'TRAF1'},
{value: 'traf2', id: 'TRAF2'},
{value: 'traf3', id: 'TRAF3'},
{value: 'traf3ip1', id: 'TRAF3IP1'},
{value: 'traf3ip2', id: 'TRAF3IP2'},
{value: 'traf3ip3', id: 'TRAF3IP3'},
{value: 'traf4', id: 'TRAF4'},
{value: 'traf5', id: 'TRAF5'},
{value: 'traf6', id: 'TRAF6'},
{value: 'traf7', id: 'TRAF7'},
{value: 'trafd1', id: 'TRAFD1'},
{value: 'traip', id: 'TRAIP'},
{value: 'trak1', id: 'TRAK1'},
{value: 'trak2', id: 'TRAK2'},
{value: 'tram1', id: 'TRAM1'},
{value: 'tram1l1', id: 'TRAM1L1'},
{value: 'tram2', id: 'TRAM2'},
{value: 'trank1', id: 'TRANK1'},
{value: 'trap1', id: 'TRAP1'},
{value: 'trap1a', id: 'TRAP1A'},
{value: 'trappc1', id: 'TRAPPC1'},
{value: 'trappc10', id: 'TRAPPC10'},
{value: 'trappc11', id: 'TRAPPC11'},
{value: 'trappc12', id: 'TRAPPC12'},
{value: 'trappc13', id: 'TRAPPC13'},
{value: 'trappc14', id: 'TRAPPC14'},
{value: 'trappc2', id: 'TRAPPC2'},
{value: 'trappc2b', id: 'TRAPPC2B'},
{value: 'trappc2l', id: 'TRAPPC2L'},
{value: 'trappc3', id: 'TRAPPC3'},
{value: 'trappc3l', id: 'TRAPPC3L'},
{value: 'trappc4', id: 'TRAPPC4'},
{value: 'trappc5', id: 'TRAPPC5'},
{value: 'trappc6a', id: 'TRAPPC6A'},
{value: 'trappc6b', id: 'TRAPPC6B'},
{value: 'trappc8', id: 'TRAPPC8'},
{value: 'trappc9', id: 'TRAPPC9'},
{value: 'trarg1', id: 'TRARG1'},
{value: 'trat1', id: 'TRAT1'},
{value: 'trav12-3', id: 'TRAV12-3'},
{value: 'trbv1', id: 'TRBV1'},
{value: 'trbv16', id: 'TRBV16'},
{value: 'trcg1', id: 'TRCG1'},
{value: 'trdmt1', id: 'TRDMT1'},
{value: 'trdn', id: 'TRDN'},
{value: 'treh', id: 'TREH'},
{value: 'trem1', id: 'TREM1'},
{value: 'trem2', id: 'TREM2'},
{value: 'trem3', id: 'TREM3'},
{value: 'treml1', id: 'TREML1'},
{value: 'treml2', id: 'TREML2'},
{value: 'treml4', id: 'TREML4'},
{value: 'trerf1', id: 'TRERF1'},
{value: 'trex1', id: 'TREX1'},
{value: 'trex2', id: 'TREX2'},
{value: 'trh', id: 'TRH'},
{value: 'trhde', id: 'TRHDE'},
{value: 'trhr', id: 'TRHR'},
{value: 'triap1', id: 'TRIAP1'},
{value: 'trib1', id: 'TRIB1'},
{value: 'trib2', id: 'TRIB2'},
{value: 'trib3', id: 'TRIB3'},
{value: 'tril', id: 'TRIL'},
{value: 'trim10', id: 'TRIM10'},
{value: 'trim11', id: 'TRIM11'},
{value: 'trim13', id: 'TRIM13'},
{value: 'trim14', id: 'TRIM14'},
{value: 'trim15', id: 'TRIM15'},
{value: 'trim16', id: 'TRIM16'},
{value: 'trim17', id: 'TRIM17'},
{value: 'trim2', id: 'TRIM2'},
{value: 'trim21', id: 'TRIM21'},
{value: 'trim23', id: 'TRIM23'},
{value: 'trim24', id: 'TRIM24'},
{value: 'trim25', id: 'TRIM25'},
{value: 'trim26', id: 'TRIM26'},
{value: 'trim27', id: 'TRIM27'},
{value: 'trim28', id: 'TRIM28'},
{value: 'trim29', id: 'TRIM29'},
{value: 'trim3', id: 'TRIM3'},
{value: 'trim30', id: 'TRIM30'},
{value: 'trim30c', id: 'TRIM30C'},
{value: 'trim31', id: 'TRIM31'},
{value: 'trim32', id: 'TRIM32'},
{value: 'trim33', id: 'TRIM33'},
{value: 'trim34', id: 'TRIM34'},
{value: 'trim35', id: 'TRIM35'},
{value: 'trim36', id: 'TRIM36'},
{value: 'trim37', id: 'TRIM37'},
{value: 'trim38', id: 'TRIM38'},
{value: 'trim39', id: 'TRIM39'},
{value: 'trim40', id: 'TRIM40'},
{value: 'trim41', id: 'TRIM41'},
{value: 'trim42', id: 'TRIM42'},
{value: 'trim43a', id: 'TRIM43A'},
{value: 'trim44', id: 'TRIM44'},
{value: 'trim45', id: 'TRIM45'},
{value: 'trim46', id: 'TRIM46'},
{value: 'trim47', id: 'TRIM47'},
{value: 'trim5', id: 'TRIM5'},
{value: 'trim50', id: 'TRIM50'},
{value: 'trim52', id: 'TRIM52'},
{value: 'trim54', id: 'TRIM54'},
{value: 'trim55', id: 'TRIM55'},
{value: 'trim58', id: 'TRIM58'},
{value: 'trim59', id: 'TRIM59'},
{value: 'trim6', id: 'TRIM6'},
{value: 'trim60', id: 'TRIM60'},
{value: 'trim62', id: 'TRIM62'},
{value: 'trim63', id: 'TRIM63'},
{value: 'trim65', id: 'TRIM65'},
{value: 'trim66', id: 'TRIM66'},
{value: 'trim67', id: 'TRIM67'},
{value: 'trim68', id: 'TRIM68'},
{value: 'trim69', id: 'TRIM69'},
{value: 'trim7', id: 'TRIM7'},
{value: 'trim71', id: 'TRIM71'},
{value: 'trim72', id: 'TRIM72'},
{value: 'trim8', id: 'TRIM8'},
{value: 'trim80', id: 'TRIM80'},
{value: 'trim9', id: 'TRIM9'},
{value: 'triml1', id: 'TRIML1'},
{value: 'triml2', id: 'TRIML2'},
{value: 'trio', id: 'TRIO'},
{value: 'triobp', id: 'TRIOBP'},
{value: 'trip10', id: 'TRIP10'},
{value: 'trip11', id: 'TRIP11'},
{value: 'trip12', id: 'TRIP12'},
{value: 'trip13', id: 'TRIP13'},
{value: 'trip4', id: 'TRIP4'},
{value: 'trip6', id: 'TRIP6'},
{value: 'triqk', id: 'TRIQK'},
{value: 'trir', id: 'TRIR'},
{value: 'trit1', id: 'TRIT1'},
{value: 'trmo', id: 'TRMO'},
{value: 'trmt1', id: 'TRMT1'},
{value: 'trmt10a', id: 'TRMT10A'},
{value: 'trmt10b', id: 'TRMT10B'},
{value: 'trmt10c', id: 'TRMT10C'},
{value: 'trmt11', id: 'TRMT11'},
{value: 'trmt112', id: 'TRMT112'},
{value: 'trmt12', id: 'TRMT12'},
{value: 'trmt13', id: 'TRMT13'},
{value: 'trmt1l', id: 'TRMT1L'},
{value: 'trmt2a', id: 'TRMT2A'},
{value: 'trmt44', id: 'TRMT44'},
{value: 'trmt5', id: 'TRMT5'},
{value: 'trmt6', id: 'TRMT6'},
{value: 'trmt61a', id: 'TRMT61A'},
{value: 'trmt9b', id: 'TRMT9B'},
{value: 'trmu', id: 'TRMU'},
{value: 'trnau1ap', id: 'TRNAU1AP'},
{value: 'trnp1', id: 'TRNP1'},
{value: 'trnt1', id: 'TRNT1'},
{value: 'tro', id: 'TRO'},
{value: 'troap', id: 'TROAP'},
{value: 'trpa1', id: 'TRPA1'},
{value: 'trpc1', id: 'TRPC1'},
{value: 'trpc2', id: 'TRPC2'},
{value: 'trpc3', id: 'TRPC3'},
{value: 'trpc4', id: 'TRPC4'},
{value: 'trpc4ap', id: 'TRPC4AP'},
{value: 'trpc5', id: 'TRPC5'},
{value: 'trpc5os', id: 'TRPC5OS'},
{value: 'trpc6', id: 'TRPC6'},
{value: 'trpc7', id: 'TRPC7'},
{value: 'trpm1', id: 'TRPM1'},
{value: 'trpm2', id: 'TRPM2'},
{value: 'trpm3', id: 'TRPM3'},
{value: 'trpm4', id: 'TRPM4'},
{value: 'trpm5', id: 'TRPM5'},
{value: 'trpm6', id: 'TRPM6'},
{value: 'trpm7', id: 'TRPM7'},
{value: 'trpm8', id: 'TRPM8'},
{value: 'trps1', id: 'TRPS1'},
{value: 'trpt1', id: 'TRPT1'},
{value: 'trpv1', id: 'TRPV1'},
{value: 'trpv2', id: 'TRPV2'},
{value: 'trpv3', id: 'TRPV3'},
{value: 'trpv4', id: 'TRPV4'},
{value: 'trpv5', id: 'TRPV5'},
{value: 'trpv6', id: 'TRPV6'},
{value: 'trrap', id: 'TRRAP'},
{value: 'trub1', id: 'TRUB1'},
{value: 'trub2', id: 'TRUB2'},
{value: 'try10', id: 'TRY10'},
{value: 'try5', id: 'TRY5'},
{value: 'tsacc', id: 'TSACC'},
{value: 'tsbp1', id: 'TSBP1'},
{value: 'tsc1', id: 'TSC1'},
{value: 'tsc2', id: 'TSC2'},
{value: 'tsc22d1', id: 'TSC22D1'},
{value: 'tsc22d2', id: 'TSC22D2'},
{value: 'tsc22d3', id: 'TSC22D3'},
{value: 'tsc22d4', id: 'TSC22D4'},
{value: 'tsen15', id: 'TSEN15'},
{value: 'tsen2', id: 'TSEN2'},
{value: 'tsen34', id: 'TSEN34'},
{value: 'tsen34l1', id: 'TSEN34L1'},
{value: 'tsen54', id: 'TSEN54'},
{value: 'tsfm', id: 'TSFM'},
{value: 'tsg101', id: 'TSG101'},
{value: 'tsga10', id: 'TSGA10'},
{value: 'tsga10ip', id: 'TSGA10IP'},
{value: 'tsga13', id: 'TSGA13'},
{value: 'tshb', id: 'TSHB'},
{value: 'tshr', id: 'TSHR'},
{value: 'tshz1', id: 'TSHZ1'},
{value: 'tshz2', id: 'TSHZ2'},
{value: 'tshz3', id: 'TSHZ3'},
{value: 'tsks', id: 'TSKS'},
{value: 'tsku', id: 'TSKU'},
{value: 'tslp', id: 'TSLP'},
{value: 'tsn', id: 'TSN'},
{value: 'tsnax', id: 'TSNAX'},
{value: 'tsnaxip1', id: 'TSNAXIP1'},
{value: 'tspan1', id: 'TSPAN1'},
{value: 'tspan10', id: 'TSPAN10'},
{value: 'tspan11', id: 'TSPAN11'},
{value: 'tspan12', id: 'TSPAN12'},
{value: 'tspan13', id: 'TSPAN13'},
{value: 'tspan14', id: 'TSPAN14'},
{value: 'tspan15', id: 'TSPAN15'},
{value: 'tspan17', id: 'TSPAN17'},
{value: 'tspan18', id: 'TSPAN18'},
{value: 'tspan2', id: 'TSPAN2'},
{value: 'tspan3', id: 'TSPAN3'},
{value: 'tspan31', id: 'TSPAN31'},
{value: 'tspan32', id: 'TSPAN32'},
{value: 'tspan33', id: 'TSPAN33'},
{value: 'tspan4', id: 'TSPAN4'},
{value: 'tspan5', id: 'TSPAN5'},
{value: 'tspan6', id: 'TSPAN6'},
{value: 'tspan7', id: 'TSPAN7'},
{value: 'tspan8', id: 'TSPAN8'},
{value: 'tspan9', id: 'TSPAN9'},
{value: 'tspear', id: 'TSPEAR'},
{value: 'tspo', id: 'TSPO'},
{value: 'tspo2', id: 'TSPO2'},
{value: 'tspoap1', id: 'TSPOAP1'},
{value: 'tspy1', id: 'TSPY1'},
{value: 'tspy26', id: 'TSPY26'},
{value: 'tspyl1', id: 'TSPYL1'},
{value: 'tspyl2', id: 'TSPYL2'},
{value: 'tspyl4', id: 'TSPYL4'},
{value: 'tspyl5', id: 'TSPYL5'},
{value: 'tsr1', id: 'TSR1'},
{value: 'tsr2', id: 'TSR2'},
{value: 'tsr3', id: 'TSR3'},
{value: 'tssc4', id: 'TSSC4'},
{value: 'tssk1b', id: 'TSSK1B'},
{value: 'tssk2', id: 'TSSK2'},
{value: 'tssk3', id: 'TSSK3'},
{value: 'tssk4', id: 'TSSK4'},
{value: 'tssk5', id: 'TSSK5'},
{value: 'tssk6', id: 'TSSK6'},
{value: 'tst', id: 'TST'},
{value: 'tstd1', id: 'TSTD1'},
{value: 'tstd2', id: 'TSTD2'},
{value: 'tstd3', id: 'TSTD3'},
{value: 'tsx', id: 'TSX'},
{value: 'ttbk1', id: 'TTBK1'},
{value: 'ttbk2', id: 'TTBK2'},
{value: 'ttc1', id: 'TTC1'},
{value: 'ttc12', id: 'TTC12'},
{value: 'ttc13', id: 'TTC13'},
{value: 'ttc14', id: 'TTC14'},
{value: 'ttc16', id: 'TTC16'},
{value: 'ttc17', id: 'TTC17'},
{value: 'ttc19', id: 'TTC19'},
{value: 'ttc21a', id: 'TTC21A'},
{value: 'ttc21b', id: 'TTC21B'},
{value: 'ttc22', id: 'TTC22'},
{value: 'ttc23', id: 'TTC23'},
{value: 'ttc23l', id: 'TTC23L'},
{value: 'ttc24', id: 'TTC24'},
{value: 'ttc26', id: 'TTC26'},
{value: 'ttc27', id: 'TTC27'},
{value: 'ttc28', id: 'TTC28'},
{value: 'ttc29', id: 'TTC29'},
{value: 'ttc3', id: 'TTC3'},
{value: 'ttc30a1', id: 'TTC30A1'},
{value: 'ttc30b', id: 'TTC30B'},
{value: 'ttc32', id: 'TTC32'},
{value: 'ttc33', id: 'TTC33'},
{value: 'ttc34', id: 'TTC34'},
{value: 'ttc36', id: 'TTC36'},
{value: 'ttc37', id: 'TTC37'},
{value: 'ttc38', id: 'TTC38'},
{value: 'ttc39a', id: 'TTC39A'},
{value: 'ttc39b', id: 'TTC39B'},
{value: 'ttc39c', id: 'TTC39C'},
{value: 'ttc39d', id: 'TTC39D'},
{value: 'ttc4', id: 'TTC4'},
{value: 'ttc41', id: 'TTC41'},
{value: 'ttc5', id: 'TTC5'},
{value: 'ttc6', id: 'TTC6'},
{value: 'ttc7a', id: 'TTC7A'},
{value: 'ttc7b', id: 'TTC7B'},
{value: 'ttc8', id: 'TTC8'},
{value: 'ttc9', id: 'TTC9'},
{value: 'ttc9b', id: 'TTC9B'},
{value: 'ttc9c', id: 'TTC9C'},
{value: 'ttf1', id: 'TTF1'},
{value: 'ttf2', id: 'TTF2'},
{value: 'tti1', id: 'TTI1'},
{value: 'tti2', id: 'TTI2'},
{value: 'ttk', id: 'TTK'},
{value: 'ttl', id: 'TTL'},
{value: 'ttll1', id: 'TTLL1'},
{value: 'ttll10', id: 'TTLL10'},
{value: 'ttll11', id: 'TTLL11'},
{value: 'ttll12', id: 'TTLL12'},
{value: 'ttll13', id: 'TTLL13'},
{value: 'ttll2', id: 'TTLL2'},
{value: 'ttll3', id: 'TTLL3'},
{value: 'ttll4', id: 'TTLL4'},
{value: 'ttll5', id: 'TTLL5'},
{value: 'ttll6', id: 'TTLL6'},
{value: 'ttll7', id: 'TTLL7'},
{value: 'ttll9', id: 'TTLL9'},
{value: 'ttn', id: 'TTN'},
{value: 'ttpa', id: 'TTPA'},
{value: 'ttpal', id: 'TTPAL'},
{value: 'ttr', id: 'TTR'},
{value: 'ttyh1', id: 'TTYH1'},
{value: 'ttyh2', id: 'TTYH2'},
{value: 'ttyh3', id: 'TTYH3'},
{value: 'tub', id: 'TUB'},
{value: 'tuba1a', id: 'TUBA1A'},
{value: 'tuba1b', id: 'TUBA1B'},
{value: 'tuba1c', id: 'TUBA1C'},
{value: 'tuba3a', id: 'TUBA3A'},
{value: 'tuba3b', id: 'TUBA3B'},
{value: 'tuba4a', id: 'TUBA4A'},
{value: 'tuba8', id: 'TUBA8'},
{value: 'tubal3', id: 'TUBAL3'},
{value: 'tubb1', id: 'TUBB1'},
{value: 'tubb2a', id: 'TUBB2A'},
{value: 'tubb2b', id: 'TUBB2B'},
{value: 'tubb3', id: 'TUBB3'},
{value: 'tubb4a', id: 'TUBB4A'},
{value: 'tubb4b', id: 'TUBB4B'},
{value: 'tubb5', id: 'TUBB5'},
{value: 'tubb6', id: 'TUBB6'},
{value: 'tubd1', id: 'TUBD1'},
{value: 'tube1', id: 'TUBE1'},
{value: 'tubg1', id: 'TUBG1'},
{value: 'tubg2', id: 'TUBG2'},
{value: 'tubgcp2', id: 'TUBGCP2'},
{value: 'tubgcp3', id: 'TUBGCP3'},
{value: 'tubgcp4', id: 'TUBGCP4'},
{value: 'tubgcp5', id: 'TUBGCP5'},
{value: 'tubgcp6', id: 'TUBGCP6'},
{value: 'tufm', id: 'TUFM'},
{value: 'tuft1', id: 'TUFT1'},
{value: 'tug1', id: 'TUG1'},
{value: 'tulp1', id: 'TULP1'},
{value: 'tulp2', id: 'TULP2'},
{value: 'tulp3', id: 'TULP3'},
{value: 'tulp4', id: 'TULP4'},
{value: 'tusc2', id: 'TUSC2'},
{value: 'tusc3', id: 'TUSC3'},
{value: 'tut1', id: 'TUT1'},
{value: 'tut4', id: 'TUT4'},
{value: 'tut7', id: 'TUT7'},
{value: 'tvp23a', id: 'TVP23A'},
{value: 'tvp23b', id: 'TVP23B'},
{value: 'twf1', id: 'TWF1'},
{value: 'twf2', id: 'TWF2'},
{value: 'twist1', id: 'TWIST1'},
{value: 'twist2', id: 'TWIST2'},
{value: 'twnk', id: 'TWNK'},
{value: 'twsg1', id: 'TWSG1'},
{value: 'txk', id: 'TXK'},
{value: 'txlna', id: 'TXLNA'},
{value: 'txlnb', id: 'TXLNB'},
{value: 'txlng', id: 'TXLNG'},
{value: 'txn1', id: 'TXN1'},
{value: 'txn2', id: 'TXN2'},
{value: 'txndc11', id: 'TXNDC11'},
{value: 'txndc12', id: 'TXNDC12'},
{value: 'txndc15', id: 'TXNDC15'},
{value: 'txndc16', id: 'TXNDC16'},
{value: 'txndc17', id: 'TXNDC17'},
{value: 'txndc2', id: 'TXNDC2'},
{value: 'txndc5', id: 'TXNDC5'},
{value: 'txndc8', id: 'TXNDC8'},
{value: 'txndc9', id: 'TXNDC9'},
{value: 'txnip', id: 'TXNIP'},
{value: 'txnl1', id: 'TXNL1'},
{value: 'txnl4a', id: 'TXNL4A'},
{value: 'txnl4b', id: 'TXNL4B'},
{value: 'txnrd1', id: 'TXNRD1'},
{value: 'txnrd2', id: 'TXNRD2'},
{value: 'txnrd3', id: 'TXNRD3'},
{value: 'tyk2', id: 'TYK2'},
{value: 'tymp', id: 'TYMP'},
{value: 'tyms', id: 'TYMS'},
{value: 'tyr', id: 'TYR'},
{value: 'tyro3', id: 'TYRO3'},
{value: 'tyrobp', id: 'TYROBP'},
{value: 'tyrp1', id: 'TYRP1'},
{value: 'tysnd1', id: 'TYSND1'},
{value: 'tyw1', id: 'TYW1'},
{value: 'tyw3', id: 'TYW3'},
{value: 'tyw5', id: 'TYW5'},
{value: 'u1', id: 'U1'},
{value: 'u12', id: 'U12'},
{value: 'u2', id: 'U2'},
{value: 'u2af1', id: 'U2AF1'},
{value: 'u2af1l4', id: 'U2AF1L4'},
{value: 'u2af2', id: 'U2AF2'},
{value: 'u2surp', id: 'U2SURP'},
{value: 'u3', id: 'U3'},
{value: 'u4', id: 'U4'},
{value: 'u5', id: 'U5'},
{value: 'u6', id: 'U6'},
{value: 'u7', id: 'U7'},
{value: 'u8', id: 'U8'},
{value: 'uaca', id: 'UACA'},
{value: 'uap1', id: 'UAP1'},
{value: 'uap1l1', id: 'UAP1L1'},
{value: 'uba1', id: 'UBA1'},
{value: 'uba1y', id: 'UBA1Y'},
{value: 'uba2-ps1', id: 'UBA2-PS1'},
{value: 'uba2', id: 'UBA2'},
{value: 'uba3', id: 'UBA3'},
{value: 'uba5', id: 'UBA5'},
{value: 'uba52', id: 'UBA52'},
{value: 'uba6', id: 'UBA6'},
{value: 'uba7', id: 'UBA7'},
{value: 'ubac1', id: 'UBAC1'},
{value: 'ubac2', id: 'UBAC2'},
{value: 'ubald1', id: 'UBALD1'},
{value: 'ubald2', id: 'UBALD2'},
{value: 'ubap1', id: 'UBAP1'},
{value: 'ubap1l', id: 'UBAP1L'},
{value: 'ubap2', id: 'UBAP2'},
{value: 'ubap2l', id: 'UBAP2L'},
{value: 'ubash3a', id: 'UBASH3A'},
{value: 'ubash3b', id: 'UBASH3B'},
{value: 'ubb', id: 'UBB'},
{value: 'ubc', id: 'UBC'},
{value: 'ubd', id: 'UBD'},
{value: 'ube2a', id: 'UBE2A'},
{value: 'ube2b', id: 'UBE2B'},
{value: 'ube2c', id: 'UBE2C'},
{value: 'ube2d1', id: 'UBE2D1'},
{value: 'ube2d2', id: 'UBE2D2'},
{value: 'ube2d3', id: 'UBE2D3'},
{value: 'ube2d4', id: 'UBE2D4'},
{value: 'ube2e1', id: 'UBE2E1'},
{value: 'ube2e2', id: 'UBE2E2'},
{value: 'ube2e3', id: 'UBE2E3'},
{value: 'ube2f', id: 'UBE2F'},
{value: 'ube2g1', id: 'UBE2G1'},
{value: 'ube2g2', id: 'UBE2G2'},
{value: 'ube2h', id: 'UBE2H'},
{value: 'ube2i', id: 'UBE2I'},
{value: 'ube2j1', id: 'UBE2J1'},
{value: 'ube2j2', id: 'UBE2J2'},
{value: 'ube2k', id: 'UBE2K'},
{value: 'ube2l3', id: 'UBE2L3'},
{value: 'ube2l6', id: 'UBE2L6'},
{value: 'ube2m', id: 'UBE2M'},
{value: 'ube2n', id: 'UBE2N'},
{value: 'ube2o', id: 'UBE2O'},
{value: 'ube2q1', id: 'UBE2Q1'},
{value: 'ube2q2', id: 'UBE2Q2'},
{value: 'ube2q2l', id: 'UBE2Q2L'},
{value: 'ube2ql1', id: 'UBE2QL1'},
{value: 'ube2r2', id: 'UBE2R2'},
{value: 'ube2s', id: 'UBE2S'},
{value: 'ube2t', id: 'UBE2T'},
{value: 'ube2u', id: 'UBE2U'},
{value: 'ube2v1', id: 'UBE2V1'},
{value: 'ube2v2', id: 'UBE2V2'},
{value: 'ube2w', id: 'UBE2W'},
{value: 'ube2z', id: 'UBE2Z'},
{value: 'ube3a', id: 'UBE3A'},
{value: 'ube3b', id: 'UBE3B'},
{value: 'ube3c', id: 'UBE3C'},
{value: 'ube3d', id: 'UBE3D'},
{value: 'ube4a', id: 'UBE4A'},
{value: 'ube4b', id: 'UBE4B'},
{value: 'ubfd1', id: 'UBFD1'},
{value: 'ubiad1', id: 'UBIAD1'},
{value: 'ubl3', id: 'UBL3'},
{value: 'ubl4a', id: 'UBL4A'},
{value: 'ubl5', id: 'UBL5'},
{value: 'ubl7', id: 'UBL7'},
{value: 'ublcp1', id: 'UBLCP1'},
{value: 'ubn1', id: 'UBN1'},
{value: 'ubn2', id: 'UBN2'},
{value: 'ubox5', id: 'UBOX5'},
{value: 'ubp1', id: 'UBP1'},
{value: 'ubqln1', id: 'UBQLN1'},
{value: 'ubqln2', id: 'UBQLN2'},
{value: 'ubqln3', id: 'UBQLN3'},
{value: 'ubqln4', id: 'UBQLN4'},
{value: 'ubqlnl', id: 'UBQLNL'},
{value: 'ubr1', id: 'UBR1'},
{value: 'ubr2', id: 'UBR2'},
{value: 'ubr3', id: 'UBR3'},
{value: 'ubr4', id: 'UBR4'},
{value: 'ubr5', id: 'UBR5'},
{value: 'ubr7', id: 'UBR7'},
{value: 'ubtd1', id: 'UBTD1'},
{value: 'ubtd2', id: 'UBTD2'},
{value: 'ubtf', id: 'UBTF'},
{value: 'ubxn1', id: 'UBXN1'},
{value: 'ubxn10', id: 'UBXN10'},
{value: 'ubxn11', id: 'UBXN11'},
{value: 'ubxn2a', id: 'UBXN2A'},
{value: 'ubxn2b', id: 'UBXN2B'},
{value: 'ubxn4', id: 'UBXN4'},
{value: 'ubxn6', id: 'UBXN6'},
{value: 'ubxn7', id: 'UBXN7'},
{value: 'ubxn8', id: 'UBXN8'},
{value: 'uchl1', id: 'UCHL1'},
{value: 'uchl3', id: 'UCHL3'},
{value: 'uchl5', id: 'UCHL5'},
{value: 'uck1', id: 'UCK1'},
{value: 'uck2', id: 'UCK2'},
{value: 'uckl1', id: 'UCKL1'},
{value: 'ucma', id: 'UCMA'},
{value: 'ucn', id: 'UCN'},
{value: 'ucn2', id: 'UCN2'},
{value: 'ucn3', id: 'UCN3'},
{value: 'ucp1', id: 'UCP1'},
{value: 'ucp2', id: 'UCP2'},
{value: 'ucp3', id: 'UCP3'},
{value: 'uevld', id: 'UEVLD'},
{value: 'ufc1', id: 'UFC1'},
{value: 'ufd1', id: 'UFD1'},
{value: 'ufl1', id: 'UFL1'},
{value: 'ufm1', id: 'UFM1'},
{value: 'ufsp1', id: 'UFSP1'},
{value: 'ufsp2', id: 'UFSP2'},
{value: 'ugcg', id: 'UGCG'},
{value: 'ugdh', id: 'UGDH'},
{value: 'uggt1', id: 'UGGT1'},
{value: 'uggt2', id: 'UGGT2'},
{value: 'ugp2', id: 'UGP2'},
{value: 'ugt1a1', id: 'UGT1A1'},
{value: 'ugt1a2', id: 'UGT1A2'},
{value: 'ugt1a3', id: 'UGT1A3'},
{value: 'ugt1a5', id: 'UGT1A5'},
{value: 'ugt1a6', id: 'UGT1A6'},
{value: 'ugt1a7c', id: 'UGT1A7C'},
{value: 'ugt1a8', id: 'UGT1A8'},
{value: 'ugt1a9', id: 'UGT1A9'},
{value: 'ugt2a3', id: 'UGT2A3'},
{value: 'ugt2b', id: 'UGT2B'},
{value: 'ugt2b10', id: 'UGT2B10'},
{value: 'ugt2b15', id: 'UGT2B15'},
{value: 'ugt2b17', id: 'UGT2B17'},
{value: 'ugt2b35', id: 'UGT2B35'},
{value: 'ugt2b37', id: 'UGT2B37'},
{value: 'ugt3a2', id: 'UGT3A2'},
{value: 'ugt8', id: 'UGT8'},
{value: 'uhmk1', id: 'UHMK1'},
{value: 'uhrf1', id: 'UHRF1'},
{value: 'uhrf1bp1', id: 'UHRF1BP1'},
{value: 'uhrf1bp1l', id: 'UHRF1BP1L'},
{value: 'uhrf2', id: 'UHRF2'},
{value: 'uimc1', id: 'UIMC1'},
{value: 'ulbp1', id: 'ULBP1'},
{value: 'ulk1', id: 'ULK1'},
{value: 'ulk2', id: 'ULK2'},
{value: 'ulk3', id: 'ULK3'},
{value: 'ulk4', id: 'ULK4'},
{value: 'umod', id: 'UMOD'},
{value: 'umodl', id: 'UMODL'},
{value: 'umodl1', id: 'UMODL1'},
{value: 'umps', id: 'UMPS'},
{value: 'unc119', id: 'UNC119'},
{value: 'unc119b', id: 'UNC119B'},
{value: 'unc13a', id: 'UNC13A'},
{value: 'unc13b', id: 'UNC13B'},
{value: 'unc13c', id: 'UNC13C'},
{value: 'unc13d', id: 'UNC13D'},
{value: 'unc45a', id: 'UNC45A'},
{value: 'unc45b', id: 'UNC45B'},
{value: 'unc50', id: 'UNC50'},
{value: 'unc5a', id: 'UNC5A'},
{value: 'unc5b', id: 'UNC5B'},
{value: 'unc5c', id: 'UNC5C'},
{value: 'unc5cl', id: 'UNC5CL'},
{value: 'unc5d', id: 'UNC5D'},
{value: 'unc79', id: 'UNC79'},
{value: 'unc80', id: 'UNC80'},
{value: 'unc93a', id: 'UNC93A'},
{value: 'unc93b1', id: 'UNC93B1'},
{value: 'uncx', id: 'UNCX'},
{value: 'ung', id: 'UNG'},
{value: 'unk', id: 'UNK'},
{value: 'unkl', id: 'UNKL'},
{value: 'uox', id: 'UOX'},
{value: 'upb1', id: 'UPB1'},
{value: 'upf1', id: 'UPF1'},
{value: 'upf2', id: 'UPF2'},
{value: 'upf3a', id: 'UPF3A'},
{value: 'upf3b', id: 'UPF3B'},
{value: 'upk1a', id: 'UPK1A'},
{value: 'upk1b', id: 'UPK1B'},
{value: 'upk2', id: 'UPK2'},
{value: 'upk3a', id: 'UPK3A'},
{value: 'upk3b', id: 'UPK3B'},
{value: 'upk3bl1', id: 'UPK3BL1'},
{value: 'upp1', id: 'UPP1'},
{value: 'upp2', id: 'UPP2'},
{value: 'uprt', id: 'UPRT'},
{value: 'uqcc1', id: 'UQCC1'},
{value: 'uqcc2', id: 'UQCC2'},
{value: 'uqcc3', id: 'UQCC3'},
{value: 'uqcr10', id: 'UQCR10'},
{value: 'uqcr11', id: 'UQCR11'},
{value: 'uqcrb', id: 'UQCRB'},
{value: 'uqcrc1', id: 'UQCRC1'},
{value: 'uqcrc2', id: 'UQCRC2'},
{value: 'uqcrfs1', id: 'UQCRFS1'},
{value: 'uqcrh', id: 'UQCRH'},
{value: 'uqcrq', id: 'UQCRQ'},
{value: 'urad', id: 'URAD'},
{value: 'urb1', id: 'URB1'},
{value: 'urb2', id: 'URB2'},
{value: 'urgcp', id: 'URGCP'},
{value: 'uri1', id: 'URI1'},
{value: 'urm1', id: 'URM1'},
{value: 'uroc1', id: 'UROC1'},
{value: 'urod', id: 'UROD'},
{value: 'uros', id: 'UROS'},
{value: 'usb1', id: 'USB1'},
{value: 'use1', id: 'USE1'},
{value: 'usf1', id: 'USF1'},
{value: 'usf2', id: 'USF2'},
{value: 'usf3', id: 'USF3'},
{value: 'ush1c', id: 'USH1C'},
{value: 'ush1g', id: 'USH1G'},
{value: 'ush2a', id: 'USH2A'},
{value: 'ushbp1', id: 'USHBP1'},
{value: 'uso1', id: 'USO1'},
{value: 'usp1', id: 'USP1'},
{value: 'usp10', id: 'USP10'},
{value: 'usp11', id: 'USP11'},
{value: 'usp12', id: 'USP12'},
{value: 'usp13', id: 'USP13'},
{value: 'usp14', id: 'USP14'},
{value: 'usp15', id: 'USP15'},
{value: 'usp16', id: 'USP16'},
{value: 'usp18', id: 'USP18'},
{value: 'usp19', id: 'USP19'},
{value: 'usp2', id: 'USP2'},
{value: 'usp20', id: 'USP20'},
{value: 'usp21', id: 'USP21'},
{value: 'usp22', id: 'USP22'},
{value: 'usp24', id: 'USP24'},
{value: 'usp25', id: 'USP25'},
{value: 'usp26', id: 'USP26'},
{value: 'usp27x', id: 'USP27X'},
{value: 'usp28', id: 'USP28'},
{value: 'usp29', id: 'USP29'},
{value: 'usp3', id: 'USP3'},
{value: 'usp30', id: 'USP30'},
{value: 'usp31', id: 'USP31'},
{value: 'usp32', id: 'USP32'},
{value: 'usp33', id: 'USP33'},
{value: 'usp34', id: 'USP34'},
{value: 'usp35', id: 'USP35'},
{value: 'usp36', id: 'USP36'},
{value: 'usp37', id: 'USP37'},
{value: 'usp38', id: 'USP38'},
{value: 'usp39', id: 'USP39'},
{value: 'usp4', id: 'USP4'},
{value: 'usp40', id: 'USP40'},
{value: 'usp42', id: 'USP42'},
{value: 'usp43', id: 'USP43'},
{value: 'usp44', id: 'USP44'},
{value: 'usp45', id: 'USP45'},
{value: 'usp46', id: 'USP46'},
{value: 'usp47', id: 'USP47'},
{value: 'usp48', id: 'USP48'},
{value: 'usp49', id: 'USP49'},
{value: 'usp5', id: 'USP5'},
{value: 'usp50', id: 'USP50'},
{value: 'usp51', id: 'USP51'},
{value: 'usp53', id: 'USP53'},
{value: 'usp54', id: 'USP54'},
{value: 'usp6nl', id: 'USP6NL'},
{value: 'usp7', id: 'USP7'},
{value: 'usp8', id: 'USP8'},
{value: 'usp9x', id: 'USP9X'},
{value: 'usp9y', id: 'USP9Y'},
{value: 'uspl1', id: 'USPL1'},
{value: 'ust', id: 'UST'},
{value: 'ust4r', id: 'UST4R'},
{value: 'ust5r', id: 'UST5R'},
{value: 'utf1', id: 'UTF1'},
{value: 'utp11', id: 'UTP11'},
{value: 'utp14a', id: 'UTP14A'},
{value: 'utp15', id: 'UTP15'},
{value: 'utp18', id: 'UTP18'},
{value: 'utp20', id: 'UTP20'},
{value: 'utp23', id: 'UTP23'},
{value: 'utp25', id: 'UTP25'},
{value: 'utp3', id: 'UTP3'},
{value: 'utp4', id: 'UTP4'},
{value: 'utp6', id: 'UTP6'},
{value: 'utrn', id: 'UTRN'},
{value: 'uts2', id: 'UTS2'},
{value: 'uts2b', id: 'UTS2B'},
{value: 'uts2r', id: 'UTS2R'},
{value: 'uty', id: 'UTY'},
{value: 'uvrag', id: 'UVRAG'},
{value: 'uvssa', id: 'UVSSA'},
{value: 'uxs1', id: 'UXS1'},
{value: 'uxt', id: 'UXT'},
{value: 'vac14', id: 'VAC14'},
{value: 'vamp1', id: 'VAMP1'},
{value: 'vamp2', id: 'VAMP2'},
{value: 'vamp3', id: 'VAMP3'},
{value: 'vamp4', id: 'VAMP4'},
{value: 'vamp5', id: 'VAMP5'},
{value: 'vamp7', id: 'VAMP7'},
{value: 'vamp8', id: 'VAMP8'},
{value: 'vangl1', id: 'VANGL1'},
{value: 'vangl2', id: 'VANGL2'},
{value: 'vapa', id: 'VAPA'},
{value: 'vapb', id: 'VAPB'},
{value: 'vars1', id: 'VARS1'},
{value: 'vars2', id: 'VARS2'},
{value: 'vash1', id: 'VASH1'},
{value: 'vash2', id: 'VASH2'},
{value: 'vasn', id: 'VASN'},
{value: 'vasp', id: 'VASP'},
{value: 'vat1', id: 'VAT1'},
{value: 'vat1l', id: 'VAT1L'},
{value: 'vault', id: 'VAULT'},
{value: 'vav1', id: 'VAV1'},
{value: 'vav2', id: 'VAV2'},
{value: 'vav3', id: 'VAV3'},
{value: 'vax1', id: 'VAX1'},
{value: 'vax2', id: 'VAX2'},
{value: 'vbp1', id: 'VBP1'},
{value: 'vcam1', id: 'VCAM1'},
{value: 'vcan', id: 'VCAN'},
{value: 'vcl', id: 'VCL'},
{value: 'vcp', id: 'VCP'},
{value: 'vcpip1', id: 'VCPIP1'},
{value: 'vcpkmt', id: 'VCPKMT'},
{value: 'vcsa2', id: 'VCSA2'},
{value: 'vdac1', id: 'VDAC1'},
{value: 'vdac2', id: 'VDAC2'},
{value: 'vdac3', id: 'VDAC3'},
{value: 'vdr', id: 'VDR'},
{value: 'vegfa', id: 'VEGFA'},
{value: 'vegfb', id: 'VEGFB'},
{value: 'vegfc', id: 'VEGFC'},
{value: 'vegfd', id: 'VEGFD'},
{value: 'vegp2', id: 'VEGP2'},
{value: 'ventx', id: 'VENTX'},
{value: 'veph1', id: 'VEPH1'},
{value: 'vezf1', id: 'VEZF1'},
{value: 'vezt', id: 'VEZT'},
{value: 'vgf', id: 'VGF'},
{value: 'vgll2', id: 'VGLL2'},
{value: 'vgll3', id: 'VGLL3'},
{value: 'vgll4', id: 'VGLL4'},
{value: 'vhl', id: 'VHL'},
{value: 'vil1', id: 'VIL1'},
{value: 'vill', id: 'VILL'},
{value: 'vim', id: 'VIM'},
{value: 'vip', id: 'VIP'},
{value: 'vipas39', id: 'VIPAS39'},
{value: 'vipr1', id: 'VIPR1'},
{value: 'vipr2', id: 'VIPR2'},
{value: 'virma', id: 'VIRMA'},
{value: 'vit', id: 'VIT'},
{value: 'vkorc1', id: 'VKORC1'},
{value: 'vkorc1l1', id: 'VKORC1L1'},
{value: 'vldlr', id: 'VLDLR'},
{value: 'vma21', id: 'VMA21'},
{value: 'vmac', id: 'VMAC'},
{value: 'vmn1r83', id: 'VMN1R83'},
{value: 'vmo1', id: 'VMO1'},
{value: 'vmp1', id: 'VMP1'},
{value: 'vnn1', id: 'VNN1'},
{value: 'vnn3', id: 'VNN3'},
{value: 'vom1r-ps112', id: 'VOM1R-PS112'},
{value: 'vom1r-ps55', id: 'VOM1R-PS55'},
{value: 'vom1r10', id: 'VOM1R10'},
{value: 'vom1r100', id: 'VOM1R100'},
{value: 'vom1r101', id: 'VOM1R101'},
{value: 'vom1r102', id: 'VOM1R102'},
{value: 'vom1r105', id: 'VOM1R105'},
{value: 'vom1r106', id: 'VOM1R106'},
{value: 'vom1r107', id: 'VOM1R107'},
{value: 'vom1r108', id: 'VOM1R108'},
{value: 'vom1r11', id: 'VOM1R11'},
{value: 'vom1r110', id: 'VOM1R110'},
{value: 'vom1r12', id: 'VOM1R12'},
{value: 'vom1r15', id: 'VOM1R15'},
{value: 'vom1r19', id: 'VOM1R19'},
{value: 'vom1r2', id: 'VOM1R2'},
{value: 'vom1r20', id: 'VOM1R20'},
{value: 'vom1r21', id: 'VOM1R21'},
{value: 'vom1r22', id: 'VOM1R22'},
{value: 'vom1r23', id: 'VOM1R23'},
{value: 'vom1r24', id: 'VOM1R24'},
{value: 'vom1r25', id: 'VOM1R25'},
{value: 'vom1r26', id: 'VOM1R26'},
{value: 'vom1r27', id: 'VOM1R27'},
{value: 'vom1r28', id: 'VOM1R28'},
{value: 'vom1r3', id: 'VOM1R3'},
{value: 'vom1r30', id: 'VOM1R30'},
{value: 'vom1r32', id: 'VOM1R32'},
{value: 'vom1r34', id: 'VOM1R34'},
{value: 'vom1r35', id: 'VOM1R35'},
{value: 'vom1r36', id: 'VOM1R36'},
{value: 'vom1r37', id: 'VOM1R37'},
{value: 'vom1r38', id: 'VOM1R38'},
{value: 'vom1r4', id: 'VOM1R4'},
{value: 'vom1r40', id: 'VOM1R40'},
{value: 'vom1r41', id: 'VOM1R41'},
{value: 'vom1r42', id: 'VOM1R42'},
{value: 'vom1r43', id: 'VOM1R43'},
{value: 'vom1r44', id: 'VOM1R44'},
{value: 'vom1r45', id: 'VOM1R45'},
{value: 'vom1r46', id: 'VOM1R46'},
{value: 'vom1r47', id: 'VOM1R47'},
{value: 'vom1r50', id: 'VOM1R50'},
{value: 'vom1r51', id: 'VOM1R51'},
{value: 'vom1r52', id: 'VOM1R52'},
{value: 'vom1r53', id: 'VOM1R53'},
{value: 'vom1r54', id: 'VOM1R54'},
{value: 'vom1r55', id: 'VOM1R55'},
{value: 'vom1r56', id: 'VOM1R56'},
{value: 'vom1r57', id: 'VOM1R57'},
{value: 'vom1r58', id: 'VOM1R58'},
{value: 'vom1r59', id: 'VOM1R59'},
{value: 'vom1r6', id: 'VOM1R6'},
{value: 'vom1r60', id: 'VOM1R60'},
{value: 'vom1r61', id: 'VOM1R61'},
{value: 'vom1r62', id: 'VOM1R62'},
{value: 'vom1r63', id: 'VOM1R63'},
{value: 'vom1r65', id: 'VOM1R65'},
{value: 'vom1r66', id: 'VOM1R66'},
{value: 'vom1r67', id: 'VOM1R67'},
{value: 'vom1r68', id: 'VOM1R68'},
{value: 'vom1r69', id: 'VOM1R69'},
{value: 'vom1r7', id: 'VOM1R7'},
{value: 'vom1r70', id: 'VOM1R70'},
{value: 'vom1r71', id: 'VOM1R71'},
{value: 'vom1r72', id: 'VOM1R72'},
{value: 'vom1r73', id: 'VOM1R73'},
{value: 'vom1r75', id: 'VOM1R75'},
{value: 'vom1r76', id: 'VOM1R76'},
{value: 'vom1r77', id: 'VOM1R77'},
{value: 'vom1r78', id: 'VOM1R78'},
{value: 'vom1r79', id: 'VOM1R79'},
{value: 'vom1r8', id: 'VOM1R8'},
{value: 'vom1r80', id: 'VOM1R80'},
{value: 'vom1r81', id: 'VOM1R81'},
{value: 'vom1r82', id: 'VOM1R82'},
{value: 'vom1r83', id: 'VOM1R83'},
{value: 'vom1r84', id: 'VOM1R84'},
{value: 'vom1r85', id: 'VOM1R85'},
{value: 'vom1r86', id: 'VOM1R86'},
{value: 'vom1r87', id: 'VOM1R87'},
{value: 'vom1r88', id: 'VOM1R88'},
{value: 'vom1r89', id: 'VOM1R89'},
{value: 'vom1r9', id: 'VOM1R9'},
{value: 'vom1r90', id: 'VOM1R90'},
{value: 'vom1r92', id: 'VOM1R92'},
{value: 'vom1r93', id: 'VOM1R93'},
{value: 'vom1r94', id: 'VOM1R94'},
{value: 'vom1r95', id: 'VOM1R95'},
{value: 'vom1r96', id: 'VOM1R96'},
{value: 'vom1r97', id: 'VOM1R97'},
{value: 'vom1r98', id: 'VOM1R98'},
{value: 'vom1r99', id: 'VOM1R99'},
{value: 'vom2r-ps125', id: 'VOM2R-PS125'},
{value: 'vom2r1', id: 'VOM2R1'},
{value: 'vom2r10', id: 'VOM2R10'},
{value: 'vom2r11', id: 'VOM2R11'},
{value: 'vom2r12', id: 'VOM2R12'},
{value: 'vom2r13', id: 'VOM2R13'},
{value: 'vom2r15', id: 'VOM2R15'},
{value: 'vom2r16', id: 'VOM2R16'},
{value: 'vom2r18', id: 'VOM2R18'},
{value: 'vom2r19', id: 'VOM2R19'},
{value: 'vom2r22', id: 'VOM2R22'},
{value: 'vom2r23', id: 'VOM2R23'},
{value: 'vom2r24', id: 'VOM2R24'},
{value: 'vom2r25', id: 'VOM2R25'},
{value: 'vom2r26', id: 'VOM2R26'},
{value: 'vom2r27', id: 'VOM2R27'},
{value: 'vom2r28', id: 'VOM2R28'},
{value: 'vom2r29', id: 'VOM2R29'},
{value: 'vom2r31', id: 'VOM2R31'},
{value: 'vom2r32', id: 'VOM2R32'},
{value: 'vom2r34', id: 'VOM2R34'},
{value: 'vom2r35', id: 'VOM2R35'},
{value: 'vom2r36', id: 'VOM2R36'},
{value: 'vom2r37', id: 'VOM2R37'},
{value: 'vom2r38', id: 'VOM2R38'},
{value: 'vom2r4', id: 'VOM2R4'},
{value: 'vom2r42', id: 'VOM2R42'},
{value: 'vom2r43', id: 'VOM2R43'},
{value: 'vom2r44', id: 'VOM2R44'},
{value: 'vom2r46', id: 'VOM2R46'},
{value: 'vom2r47', id: 'VOM2R47'},
{value: 'vom2r48', id: 'VOM2R48'},
{value: 'vom2r49', id: 'VOM2R49'},
{value: 'vom2r5', id: 'VOM2R5'},
{value: 'vom2r50', id: 'VOM2R50'},
{value: 'vom2r51', id: 'VOM2R51'},
{value: 'vom2r52', id: 'VOM2R52'},
{value: 'vom2r53', id: 'VOM2R53'},
{value: 'vom2r54', id: 'VOM2R54'},
{value: 'vom2r57', id: 'VOM2R57'},
{value: 'vom2r58', id: 'VOM2R58'},
{value: 'vom2r59', id: 'VOM2R59'},
{value: 'vom2r6', id: 'VOM2R6'},
{value: 'vom2r60', id: 'VOM2R60'},
{value: 'vom2r62', id: 'VOM2R62'},
{value: 'vom2r64', id: 'VOM2R64'},
{value: 'vom2r65', id: 'VOM2R65'},
{value: 'vom2r67', id: 'VOM2R67'},
{value: 'vom2r68', id: 'VOM2R68'},
{value: 'vom2r69', id: 'VOM2R69'},
{value: 'vom2r7', id: 'VOM2R7'},
{value: 'vom2r70', id: 'VOM2R70'},
{value: 'vom2r71', id: 'VOM2R71'},
{value: 'vom2r73', id: 'VOM2R73'},
{value: 'vom2r75', id: 'VOM2R75'},
{value: 'vom2r76', id: 'VOM2R76'},
{value: 'vom2r77', id: 'VOM2R77'},
{value: 'vom2r79', id: 'VOM2R79'},
{value: 'vom2r80', id: 'VOM2R80'},
{value: 'vom2r9', id: 'VOM2R9'},
{value: 'vopp1', id: 'VOPP1'},
{value: 'vpreb1', id: 'VPREB1'},
{value: 'vpreb2', id: 'VPREB2'},
{value: 'vpreb3', id: 'VPREB3'},
{value: 'vps11', id: 'VPS11'},
{value: 'vps13a', id: 'VPS13A'},
{value: 'vps13b', id: 'VPS13B'},
{value: 'vps13c', id: 'VPS13C'},
{value: 'vps13d', id: 'VPS13D'},
{value: 'vps16', id: 'VPS16'},
{value: 'vps18', id: 'VPS18'},
{value: 'vps25', id: 'VPS25'},
{value: 'vps26a', id: 'VPS26A'},
{value: 'vps26b', id: 'VPS26B'},
{value: 'vps26c', id: 'VPS26C'},
{value: 'vps28', id: 'VPS28'},
{value: 'vps29', id: 'VPS29'},
{value: 'vps33a', id: 'VPS33A'},
{value: 'vps33b', id: 'VPS33B'},
{value: 'vps35', id: 'VPS35'},
{value: 'vps35l', id: 'VPS35L'},
{value: 'vps36', id: 'VPS36'},
{value: 'vps37a', id: 'VPS37A'},
{value: 'vps37b', id: 'VPS37B'},
{value: 'vps37c', id: 'VPS37C'},
{value: 'vps37d', id: 'VPS37D'},
{value: 'vps39', id: 'VPS39'},
{value: 'vps41', id: 'VPS41'},
{value: 'vps45', id: 'VPS45'},
{value: 'vps4a', id: 'VPS4A'},
{value: 'vps4b', id: 'VPS4B'},
{value: 'vps50', id: 'VPS50'},
{value: 'vps51', id: 'VPS51'},
{value: 'vps52', id: 'VPS52'},
{value: 'vps53', id: 'VPS53'},
{value: 'vps54', id: 'VPS54'},
{value: 'vps72', id: 'VPS72'},
{value: 'vps8', id: 'VPS8'},
{value: 'vps9d1', id: 'VPS9D1'},
{value: 'vrk1', id: 'VRK1'},
{value: 'vrk2', id: 'VRK2'},
{value: 'vrk3', id: 'VRK3'},
{value: 'vrtn', id: 'VRTN'},
{value: 'vsig1', id: 'VSIG1'},
{value: 'vsig10', id: 'VSIG10'},
{value: 'vsig10l', id: 'VSIG10L'},
{value: 'vsig2', id: 'VSIG2'},
{value: 'vsig4', id: 'VSIG4'},
{value: 'vsig8', id: 'VSIG8'},
{value: 'vsir', id: 'VSIR'},
{value: 'vsnl1', id: 'VSNL1'},
{value: 'vstm2a', id: 'VSTM2A'},
{value: 'vstm2b', id: 'VSTM2B'},
{value: 'vstm2l', id: 'VSTM2L'},
{value: 'vstm4', id: 'VSTM4'},
{value: 'vstm5', id: 'VSTM5'},
{value: 'vsx1', id: 'VSX1'},
{value: 'vsx2', id: 'VSX2'},
{value: 'vta1', id: 'VTA1'},
{value: 'vtcn1', id: 'VTCN1'},
{value: 'vti1a', id: 'VTI1A'},
{value: 'vti1b', id: 'VTI1B'},
{value: 'vtn', id: 'VTN'},
{value: 'vwa1', id: 'VWA1'},
{value: 'vwa2', id: 'VWA2'},
{value: 'vwa3a', id: 'VWA3A'},
{value: 'vwa3b', id: 'VWA3B'},
{value: 'vwa5a', id: 'VWA5A'},
{value: 'vwa5b1', id: 'VWA5B1'},
{value: 'vwa5b2', id: 'VWA5B2'},
{value: 'vwa7', id: 'VWA7'},
{value: 'vwa8', id: 'VWA8'},
{value: 'vwc2', id: 'VWC2'},
{value: 'vwc2l', id: 'VWC2L'},
{value: 'vwce', id: 'VWCE'},
{value: 'vwde', id: 'VWDE'},
{value: 'vwf', id: 'VWF'},
{value: 'vxn', id: 'VXN'},
{value: 'wac', id: 'WAC'},
{value: 'wap', id: 'WAP'},
{value: 'wapl', id: 'WAPL'},
{value: 'wars1', id: 'WARS1'},
{value: 'wars2', id: 'WARS2'},
{value: 'was', id: 'WAS'},
{value: 'wasf1', id: 'WASF1'},
{value: 'wasf2', id: 'WASF2'},
{value: 'wasf3', id: 'WASF3'},
{value: 'washc1', id: 'WASHC1'},
{value: 'washc2c', id: 'WASHC2C'},
{value: 'washc3', id: 'WASHC3'},
{value: 'washc4', id: 'WASHC4'},
{value: 'washc5', id: 'WASHC5'},
{value: 'wasl', id: 'WASL'},
{value: 'wbp1', id: 'WBP1'},
{value: 'wbp11', id: 'WBP11'},
{value: 'wbp11l1', id: 'WBP11L1'},
{value: 'wbp1l', id: 'WBP1L'},
{value: 'wbp2', id: 'WBP2'},
{value: 'wbp2nl', id: 'WBP2NL'},
{value: 'wbp4', id: 'WBP4'},
{value: 'wdcp', id: 'WDCP'},
{value: 'wdfy1', id: 'WDFY1'},
{value: 'wdfy2', id: 'WDFY2'},
{value: 'wdfy3', id: 'WDFY3'},
{value: 'wdfy4', id: 'WDFY4'},
{value: 'wdhd1', id: 'WDHD1'},
{value: 'wdpcp', id: 'WDPCP'},
{value: 'wdr1', id: 'WDR1'},
{value: 'wdr11', id: 'WDR11'},
{value: 'wdr12', id: 'WDR12'},
{value: 'wdr13', id: 'WDR13'},
{value: 'wdr17', id: 'WDR17'},
{value: 'wdr18', id: 'WDR18'},
{value: 'wdr19', id: 'WDR19'},
{value: 'wdr20', id: 'WDR20'},
{value: 'wdr24', id: 'WDR24'},
{value: 'wdr25', id: 'WDR25'},
{value: 'wdr26', id: 'WDR26'},
{value: 'wdr27', id: 'WDR27'},
{value: 'wdr3', id: 'WDR3'},
{value: 'wdr31', id: 'WDR31'},
{value: 'wdr33', id: 'WDR33'},
{value: 'wdr35', id: 'WDR35'},
{value: 'wdr36', id: 'WDR36'},
{value: 'wdr37', id: 'WDR37'},
{value: 'wdr38', id: 'WDR38'},
{value: 'wdr4', id: 'WDR4'},
{value: 'wdr41', id: 'WDR41'},
{value: 'wdr43', id: 'WDR43'},
{value: 'wdr44', id: 'WDR44'},
{value: 'wdr45', id: 'WDR45'},
{value: 'wdr45b', id: 'WDR45B'},
{value: 'wdr46', id: 'WDR46'},
{value: 'wdr47', id: 'WDR47'},
{value: 'wdr48', id: 'WDR48'},
{value: 'wdr49', id: 'WDR49'},
{value: 'wdr5', id: 'WDR5'},
{value: 'wdr53', id: 'WDR53'},
{value: 'wdr54', id: 'WDR54'},
{value: 'wdr55', id: 'WDR55'},
{value: 'wdr59', id: 'WDR59'},
{value: 'wdr5b', id: 'WDR5B'},
{value: 'wdr6', id: 'WDR6'},
{value: 'wdr61', id: 'WDR61'},
{value: 'wdr62', id: 'WDR62'},
{value: 'wdr64', id: 'WDR64'},
{value: 'wdr7', id: 'WDR7'},
{value: 'wdr70', id: 'WDR70'},
{value: 'wdr72', id: 'WDR72'},
{value: 'wdr73', id: 'WDR73'},
{value: 'wdr74', id: 'WDR74'},
{value: 'wdr75', id: 'WDR75'},
{value: 'wdr76', id: 'WDR76'},
{value: 'wdr77', id: 'WDR77'},
{value: 'wdr81', id: 'WDR81'},
{value: 'wdr82', id: 'WDR82'},
{value: 'wdr83', id: 'WDR83'},
{value: 'wdr83os', id: 'WDR83OS'},
{value: 'wdr86', id: 'WDR86'},
{value: 'wdr87', id: 'WDR87'},
{value: 'wdr88', id: 'WDR88'},
{value: 'wdr89', id: 'WDR89'},
{value: 'wdr90', id: 'WDR90'},
{value: 'wdr91', id: 'WDR91'},
{value: 'wdr93', id: 'WDR93'},
{value: 'wdr95', id: 'WDR95'},
{value: 'wdr97', id: 'WDR97'},
{value: 'wdsub1', id: 'WDSUB1'},
{value: 'wdtc1', id: 'WDTC1'},
{value: 'wee1', id: 'WEE1'},
{value: 'wee2', id: 'WEE2'},
{value: 'wfdc1', id: 'WFDC1'},
{value: 'wfdc10a', id: 'WFDC10A'},
{value: 'wfdc11', id: 'WFDC11'},
{value: 'wfdc12', id: 'WFDC12'},
{value: 'wfdc13', id: 'WFDC13'},
{value: 'wfdc15a', id: 'WFDC15A'},
{value: 'wfdc15b', id: 'WFDC15B'},
{value: 'wfdc16', id: 'WFDC16'},
{value: 'wfdc18', id: 'WFDC18'},
{value: 'wfdc2', id: 'WFDC2'},
{value: 'wfdc21', id: 'WFDC21'},
{value: 'wfdc3', id: 'WFDC3'},
{value: 'wfdc5', id: 'WFDC5'},
{value: 'wfdc6a', id: 'WFDC6A'},
{value: 'wfdc6b', id: 'WFDC6B'},
{value: 'wfdc8', id: 'WFDC8'},
{value: 'wfdc9', id: 'WFDC9'},
{value: 'wfikkn1', id: 'WFIKKN1'},
{value: 'wfikkn2', id: 'WFIKKN2'},
{value: 'wfs1', id: 'WFS1'},
{value: 'whamm', id: 'WHAMM'},
{value: 'whrn', id: 'WHRN'},
{value: 'wif1', id: 'WIF1'},
{value: 'wipf1', id: 'WIPF1'},
{value: 'wipf2', id: 'WIPF2'},
{value: 'wipf3', id: 'WIPF3'},
{value: 'wipi1', id: 'WIPI1'},
{value: 'wipi2', id: 'WIPI2'},
{value: 'wiz', id: 'WIZ'},
{value: 'wls', id: 'WLS'},
{value: 'wnk1', id: 'WNK1'},
{value: 'wnk2', id: 'WNK2'},
{value: 'wnk3', id: 'WNK3'},
{value: 'wnk4', id: 'WNK4'},
{value: 'wnt1', id: 'WNT1'},
{value: 'wnt10a', id: 'WNT10A'},
{value: 'wnt10b', id: 'WNT10B'},
{value: 'wnt11', id: 'WNT11'},
{value: 'wnt16', id: 'WNT16'},
{value: 'wnt2', id: 'WNT2'},
{value: 'wnt2b', id: 'WNT2B'},
{value: 'wnt3', id: 'WNT3'},
{value: 'wnt3a', id: 'WNT3A'},
{value: 'wnt4', id: 'WNT4'},
{value: 'wnt5a', id: 'WNT5A'},
{value: 'wnt5b', id: 'WNT5B'},
{value: 'wnt6', id: 'WNT6'},
{value: 'wnt7a', id: 'WNT7A'},
{value: 'wnt7b', id: 'WNT7B'},
{value: 'wnt8a', id: 'WNT8A'},
{value: 'wnt8b', id: 'WNT8B'},
{value: 'wnt9a', id: 'WNT9A'},
{value: 'wnt9b', id: 'WNT9B'},
{value: 'wrap53', id: 'WRAP53'},
{value: 'wrap73', id: 'WRAP73'},
{value: 'wrn', id: 'WRN'},
{value: 'wrnip1', id: 'WRNIP1'},
{value: 'wsb1', id: 'WSB1'},
{value: 'wsb2', id: 'WSB2'},
{value: 'wscd1', id: 'WSCD1'},
{value: 'wscd2', id: 'WSCD2'},
{value: 'wt1', id: 'WT1'},
{value: 'wtap', id: 'WTAP'},
{value: 'wtip', id: 'WTIP'},
{value: 'wwc1', id: 'WWC1'},
{value: 'wwc2', id: 'WWC2'},
{value: 'wwc3', id: 'WWC3'},
{value: 'wwox', id: 'WWOX'},
{value: 'wwp1', id: 'WWP1'},
{value: 'wwp2', id: 'WWP2'},
{value: 'wwtr1', id: 'WWTR1'},
{value: 'xab2', id: 'XAB2'},
{value: 'xaf1', id: 'XAF1'},
{value: 'xbp1', id: 'XBP1'},
{value: 'xcl1', id: 'XCL1'},
{value: 'xcr1', id: 'XCR1'},
{value: 'xdh', id: 'XDH'},
{value: 'xiap', id: 'XIAP'},
{value: 'xirp1', id: 'XIRP1'},
{value: 'xirp2', id: 'XIRP2'},
{value: 'xk', id: 'XK'},
{value: 'xkr4', id: 'XKR4'},
{value: 'xkr5', id: 'XKR5'},
{value: 'xkr6', id: 'XKR6'},
{value: 'xkr7', id: 'XKR7'},
{value: 'xkr8', id: 'XKR8'},
{value: 'xkr9', id: 'XKR9'},
{value: 'xkrx', id: 'XKRX'},
{value: 'xlr3a', id: 'XLR3A'},
{value: 'xlr4a', id: 'XLR4A'},
{value: 'xndc1', id: 'XNDC1'},
{value: 'xpa', id: 'XPA'},
{value: 'xpc', id: 'XPC'},
{value: 'xpnpep1', id: 'XPNPEP1'},
{value: 'xpnpep2', id: 'XPNPEP2'},
{value: 'xpnpep3', id: 'XPNPEP3'},
{value: 'xpo1', id: 'XPO1'},
{value: 'xpo4', id: 'XPO4'},
{value: 'xpo5', id: 'XPO5'},
{value: 'xpo6', id: 'XPO6'},
{value: 'xpo7', id: 'XPO7'},
{value: 'xpot', id: 'XPOT'},
{value: 'xpr1', id: 'XPR1'},
{value: 'xrcc1', id: 'XRCC1'},
{value: 'xrcc2', id: 'XRCC2'},
{value: 'xrcc3', id: 'XRCC3'},
{value: 'xrcc4', id: 'XRCC4'},
{value: 'xrcc5', id: 'XRCC5'},
{value: 'xrcc6', id: 'XRCC6'},
{value: 'xrn1', id: 'XRN1'},
{value: 'xrn2', id: 'XRN2'},
{value: 'xrra1', id: 'XRRA1'},
{value: 'xxylt1', id: 'XXYLT1'},
{value: 'xylb', id: 'XYLB'},
{value: 'xylt1', id: 'XYLT1'},
{value: 'xylt2', id: 'XYLT2'},
{value: 'y_rna', id: 'Y_RNA'},
{value: 'yae1', id: 'YAE1'},
{value: 'yaf2', id: 'YAF2'},
{value: 'yap1', id: 'YAP1'},
{value: 'yars1', id: 'YARS1'},
{value: 'yars2', id: 'YARS2'},
{value: 'ybey', id: 'YBEY'},
{value: 'ybx1-ps3', id: 'YBX1-PS3'},
{value: 'ybx1', id: 'YBX1'},
{value: 'ybx2', id: 'YBX2'},
{value: 'ybx3', id: 'YBX3'},
{value: 'ydjc', id: 'YDJC'},
{value: 'yeats2', id: 'YEATS2'},
{value: 'yeats4', id: 'YEATS4'},
{value: 'yes1', id: 'YES1'},
{value: 'yif1a', id: 'YIF1A'},
{value: 'yif1b', id: 'YIF1B'},
{value: 'yipf1', id: 'YIPF1'},
{value: 'yipf2', id: 'YIPF2'},
{value: 'yipf3', id: 'YIPF3'},
{value: 'yipf4', id: 'YIPF4'},
{value: 'yipf5', id: 'YIPF5'},
{value: 'yipf6', id: 'YIPF6'},
{value: 'yipf7', id: 'YIPF7'},
{value: 'yjefn3', id: 'YJEFN3'},
{value: 'yju2', id: 'YJU2'},
{value: 'yju2b', id: 'YJU2B'},
{value: 'ykt6', id: 'YKT6'},
{value: 'ylpm1', id: 'YLPM1'},
{value: 'yme1l1', id: 'YME1L1'},
{value: 'yod1', id: 'YOD1'},
{value: 'ypel1', id: 'YPEL1'},
{value: 'ypel2', id: 'YPEL2'},
{value: 'ypel3', id: 'YPEL3'},
{value: 'ypel4', id: 'YPEL4'},
{value: 'ypel5', id: 'YPEL5'},
{value: 'yrdc', id: 'YRDC'},
{value: 'ythdc1', id: 'YTHDC1'},
{value: 'ythdc2', id: 'YTHDC2'},
{value: 'ythdf1', id: 'YTHDF1'},
{value: 'ythdf2', id: 'YTHDF2'},
{value: 'ythdf3', id: 'YTHDF3'},
{value: 'ywhab', id: 'YWHAB'},
{value: 'ywhae', id: 'YWHAE'},
{value: 'ywhag', id: 'YWHAG'},
{value: 'ywhah', id: 'YWHAH'},
{value: 'ywhaq', id: 'YWHAQ'},
{value: 'ywhaz', id: 'YWHAZ'},
{value: 'yy1', id: 'YY1'},
{value: 'zadh2', id: 'ZADH2'},
{value: 'zap70', id: 'ZAP70'},
{value: 'zar1', id: 'ZAR1'},
{value: 'zbbx', id: 'ZBBX'},
{value: 'zbed3', id: 'ZBED3'},
{value: 'zbed4', id: 'ZBED4'},
{value: 'zbed5', id: 'ZBED5'},
{value: 'zbed6', id: 'ZBED6'},
{value: 'zbp1', id: 'ZBP1'},
{value: 'zbtb1', id: 'ZBTB1'},
{value: 'zbtb10', id: 'ZBTB10'},
{value: 'zbtb11', id: 'ZBTB11'},
{value: 'zbtb12', id: 'ZBTB12'},
{value: 'zbtb16', id: 'ZBTB16'},
{value: 'zbtb17', id: 'ZBTB17'},
{value: 'zbtb18', id: 'ZBTB18'},
{value: 'zbtb2', id: 'ZBTB2'},
{value: 'zbtb20', id: 'ZBTB20'},
{value: 'zbtb21', id: 'ZBTB21'},
{value: 'zbtb22', id: 'ZBTB22'},
{value: 'zbtb24', id: 'ZBTB24'},
{value: 'zbtb25', id: 'ZBTB25'},
{value: 'zbtb26', id: 'ZBTB26'},
{value: 'zbtb3', id: 'ZBTB3'},
{value: 'zbtb32', id: 'ZBTB32'},
{value: 'zbtb33', id: 'ZBTB33'},
{value: 'zbtb34', id: 'ZBTB34'},
{value: 'zbtb37', id: 'ZBTB37'},
{value: 'zbtb38', id: 'ZBTB38'},
{value: 'zbtb39', id: 'ZBTB39'},
{value: 'zbtb4', id: 'ZBTB4'},
{value: 'zbtb40', id: 'ZBTB40'},
{value: 'zbtb41', id: 'ZBTB41'},
{value: 'zbtb42', id: 'ZBTB42'},
{value: 'zbtb43', id: 'ZBTB43'},
{value: 'zbtb44', id: 'ZBTB44'},
{value: 'zbtb45', id: 'ZBTB45'},
{value: 'zbtb46', id: 'ZBTB46'},
{value: 'zbtb47', id: 'ZBTB47'},
{value: 'zbtb48', id: 'ZBTB48'},
{value: 'zbtb49', id: 'ZBTB49'},
{value: 'zbtb5', id: 'ZBTB5'},
{value: 'zbtb6', id: 'ZBTB6'},
{value: 'zbtb7a', id: 'ZBTB7A'},
{value: 'zbtb7b', id: 'ZBTB7B'},
{value: 'zbtb7c', id: 'ZBTB7C'},
{value: 'zbtb8a', id: 'ZBTB8A'},
{value: 'zbtb8b', id: 'ZBTB8B'},
{value: 'zbtb8os', id: 'ZBTB8OS'},
{value: 'zbtb9', id: 'ZBTB9'},
{value: 'zc2hc1a', id: 'ZC2HC1A'},
{value: 'zc2hc1b', id: 'ZC2HC1B'},
{value: 'zc2hc1c', id: 'ZC2HC1C'},
{value: 'zc3h10', id: 'ZC3H10'},
{value: 'zc3h11a', id: 'ZC3H11A'},
{value: 'zc3h12a', id: 'ZC3H12A'},
{value: 'zc3h12b', id: 'ZC3H12B'},
{value: 'zc3h12c', id: 'ZC3H12C'},
{value: 'zc3h12d', id: 'ZC3H12D'},
{value: 'zc3h13', id: 'ZC3H13'},
{value: 'zc3h14', id: 'ZC3H14'},
{value: 'zc3h15', id: 'ZC3H15'},
{value: 'zc3h18', id: 'ZC3H18'},
{value: 'zc3h3', id: 'ZC3H3'},
{value: 'zc3h4', id: 'ZC3H4'},
{value: 'zc3h6', id: 'ZC3H6'},
{value: 'zc3h7a', id: 'ZC3H7A'},
{value: 'zc3h7b', id: 'ZC3H7B'},
{value: 'zc3h8', id: 'ZC3H8'},
{value: 'zc3hav1', id: 'ZC3HAV1'},
{value: 'zc3hav1l', id: 'ZC3HAV1L'},
{value: 'zc3hc1', id: 'ZC3HC1'},
{value: 'zc4h2', id: 'ZC4H2'},
{value: 'zcchc10', id: 'ZCCHC10'},
{value: 'zcchc12', id: 'ZCCHC12'},
{value: 'zcchc13', id: 'ZCCHC13'},
{value: 'zcchc14', id: 'ZCCHC14'},
{value: 'zcchc17', id: 'ZCCHC17'},
{value: 'zcchc18', id: 'ZCCHC18'},
{value: 'zcchc2', id: 'ZCCHC2'},
{value: 'zcchc24', id: 'ZCCHC24'},
{value: 'zcchc3', id: 'ZCCHC3'},
{value: 'zcchc4', id: 'ZCCHC4'},
{value: 'zcchc7', id: 'ZCCHC7'},
{value: 'zcchc8', id: 'ZCCHC8'},
{value: 'zcchc9', id: 'ZCCHC9'},
{value: 'zcrb1', id: 'ZCRB1'},
{value: 'zcwpw1', id: 'ZCWPW1'},
{value: 'zcwpw1l1', id: 'ZCWPW1L1'},
{value: 'zcwpw2', id: 'ZCWPW2'},
{value: 'zdbf2', id: 'ZDBF2'},
{value: 'zdhhc1', id: 'ZDHHC1'},
{value: 'zdhhc11', id: 'ZDHHC11'},
{value: 'zdhhc12', id: 'ZDHHC12'},
{value: 'zdhhc13', id: 'ZDHHC13'},
{value: 'zdhhc14', id: 'ZDHHC14'},
{value: 'zdhhc15', id: 'ZDHHC15'},
{value: 'zdhhc16', id: 'ZDHHC16'},
{value: 'zdhhc17', id: 'ZDHHC17'},
{value: 'zdhhc18', id: 'ZDHHC18'},
{value: 'zdhhc19', id: 'ZDHHC19'},
{value: 'zdhhc2', id: 'ZDHHC2'},
{value: 'zdhhc20', id: 'ZDHHC20'},
{value: 'zdhhc21', id: 'ZDHHC21'},
{value: 'zdhhc22', id: 'ZDHHC22'},
{value: 'zdhhc23', id: 'ZDHHC23'},
{value: 'zdhhc24', id: 'ZDHHC24'},
{value: 'zdhhc25', id: 'ZDHHC25'},
{value: 'zdhhc3', id: 'ZDHHC3'},
{value: 'zdhhc4', id: 'ZDHHC4'},
{value: 'zdhhc5', id: 'ZDHHC5'},
{value: 'zdhhc6', id: 'ZDHHC6'},
{value: 'zdhhc7', id: 'ZDHHC7'},
{value: 'zdhhc8', id: 'ZDHHC8'},
{value: 'zdhhc9', id: 'ZDHHC9'},
{value: 'zeb1', id: 'ZEB1'},
{value: 'zeb2', id: 'ZEB2'},
{value: 'zer1', id: 'ZER1'},
{value: 'zfand1', id: 'ZFAND1'},
{value: 'zfand2a', id: 'ZFAND2A'},
{value: 'zfand2b', id: 'ZFAND2B'},
{value: 'zfand3', id: 'ZFAND3'},
{value: 'zfand4', id: 'ZFAND4'},
{value: 'zfand5', id: 'ZFAND5'},
{value: 'zfand6', id: 'ZFAND6'},
{value: 'zfat', id: 'ZFAT'},
{value: 'zfc3h1', id: 'ZFC3H1'},
{value: 'zfhx2', id: 'ZFHX2'},
{value: 'zfhx3', id: 'ZFHX3'},
{value: 'zfhx4', id: 'ZFHX4'},
{value: 'zfp1', id: 'ZFP1'},
{value: 'zfp105', id: 'ZFP105'},
{value: 'zfp106', id: 'ZFP106'},
{value: 'zfp108', id: 'ZFP108'},
{value: 'zfp11', id: 'ZFP11'},
{value: 'zfp110', id: 'ZFP110'},
{value: 'zfp111', id: 'ZFP111'},
{value: 'zfp112', id: 'ZFP112'},
{value: 'zfp113', id: 'ZFP113'},
{value: 'zfp12', id: 'ZFP12'},
{value: 'zfp128', id: 'ZFP128'},
{value: 'zfp13', id: 'ZFP13'},
{value: 'zfp131', id: 'ZFP131'},
{value: 'zfp133', id: 'ZFP133'},
{value: 'zfp136', id: 'ZFP136'},
{value: 'zfp14', id: 'ZFP14'},
{value: 'zfp142', id: 'ZFP142'},
{value: 'zfp143', id: 'ZFP143'},
{value: 'zfp146', id: 'ZFP146'},
{value: 'zfp148', id: 'ZFP148'},
{value: 'zfp157', id: 'ZFP157'},
{value: 'zfp161', id: 'ZFP161'},
{value: 'zfp169', id: 'ZFP169'},
{value: 'zfp17', id: 'ZFP17'},
{value: 'zfp174', id: 'ZFP174'},
{value: 'zfp18', id: 'ZFP18'},
{value: 'zfp180', id: 'ZFP180'},
{value: 'zfp182', id: 'ZFP182'},
{value: 'zfp184', id: 'ZFP184'},
{value: 'zfp185', id: 'ZFP185'},
{value: 'zfp189', id: 'ZFP189'},
{value: 'zfp2', id: 'ZFP2'},
{value: 'zfp202', id: 'ZFP202'},
{value: 'zfp207', id: 'ZFP207'},
{value: 'zfp212', id: 'ZFP212'},
{value: 'zfp213', id: 'ZFP213'},
{value: 'zfp217', id: 'ZFP217'},
{value: 'zfp219', id: 'ZFP219'},
{value: 'zfp236', id: 'ZFP236'},
{value: 'zfp239', id: 'ZFP239'},
{value: 'zfp24', id: 'ZFP24'},
{value: 'zfp248', id: 'ZFP248'},
{value: 'zfp251', id: 'ZFP251'},
{value: 'zfp26', id: 'ZFP26'},
{value: 'zfp260', id: 'ZFP260'},
{value: 'zfp263', id: 'ZFP263'},
{value: 'zfp266', id: 'ZFP266'},
{value: 'zfp267', id: 'ZFP267'},
{value: 'zfp27', id: 'ZFP27'},
{value: 'zfp275', id: 'ZFP275'},
{value: 'zfp276', id: 'ZFP276'},
{value: 'zfp277', id: 'ZFP277'},
{value: 'zfp28', id: 'ZFP28'},
{value: 'zfp280b', id: 'ZFP280B'},
{value: 'zfp280c', id: 'ZFP280C'},
{value: 'zfp280d', id: 'ZFP280D'},
{value: 'zfp281', id: 'ZFP281'},
{value: 'zfp282', id: 'ZFP282'},
{value: 'zfp286a', id: 'ZFP286A'},
{value: 'zfp287', id: 'ZFP287'},
{value: 'zfp292', id: 'ZFP292'},
{value: 'zfp296', id: 'ZFP296'},
{value: 'zfp3', id: 'ZFP3'},
{value: 'zfp300', id: 'ZFP300'},
{value: 'zfp316', id: 'ZFP316'},
{value: 'zfp317', id: 'ZFP317'},
{value: 'zfp318', id: 'ZFP318'},
{value: 'zfp319', id: 'ZFP319'},
{value: 'zfp322a', id: 'ZFP322A'},
{value: 'zfp324', id: 'ZFP324'},
{value: 'zfp326', id: 'ZFP326'},
{value: 'zfp329', id: 'ZFP329'},
{value: 'zfp330', id: 'ZFP330'},
{value: 'zfp334', id: 'ZFP334'},
{value: 'zfp335', id: 'ZFP335'},
{value: 'zfp341', id: 'ZFP341'},
{value: 'zfp346', id: 'ZFP346'},
{value: 'zfp347', id: 'ZFP347'},
{value: 'zfp35', id: 'ZFP35'},
{value: 'zfp352', id: 'ZFP352'},
{value: 'zfp353', id: 'ZFP353'},
{value: 'zfp354a', id: 'ZFP354A'},
{value: 'zfp354c', id: 'ZFP354C'},
{value: 'zfp358', id: 'ZFP358'},
{value: 'zfp36', id: 'ZFP36'},
{value: 'zfp362', id: 'ZFP362'},
{value: 'zfp365', id: 'ZFP365'},
{value: 'zfp366', id: 'ZFP366'},
{value: 'zfp367', id: 'ZFP367'},
{value: 'zfp36l1', id: 'ZFP36L1'},
{value: 'zfp36l2', id: 'ZFP36L2'},
{value: 'zfp36l3', id: 'ZFP36L3'},
{value: 'zfp37', id: 'ZFP37'},
{value: 'zfp382', id: 'ZFP382'},
{value: 'zfp384', id: 'ZFP384'},
{value: 'zfp385a', id: 'ZFP385A'},
{value: 'zfp385b', id: 'ZFP385B'},
{value: 'zfp385c', id: 'ZFP385C'},
{value: 'zfp385d', id: 'ZFP385D'},
{value: 'zfp386', id: 'ZFP386'},
{value: 'zfp39', id: 'ZFP39'},
{value: 'zfp394', id: 'ZFP394'},
{value: 'zfp395', id: 'ZFP395'},
{value: 'zfp397', id: 'ZFP397'},
{value: 'zfp398', id: 'ZFP398'},
{value: 'zfp40', id: 'ZFP40'},
{value: 'zfp407', id: 'ZFP407'},
{value: 'zfp41', id: 'ZFP41'},
{value: 'zfp410', id: 'ZFP410'},
{value: 'zfp414', id: 'ZFP414'},
{value: 'zfp418', id: 'ZFP418'},
{value: 'zfp420', id: 'ZFP420'},
{value: 'zfp422', id: 'ZFP422'},
{value: 'zfp423', id: 'ZFP423'},
{value: 'zfp426', id: 'ZFP426'},
{value: 'zfp428', id: 'ZFP428'},
{value: 'zfp42l', id: 'ZFP42L'},
{value: 'zfp438', id: 'ZFP438'},
{value: 'zfp444', id: 'ZFP444'},
{value: 'zfp445', id: 'ZFP445'},
{value: 'zfp446', id: 'ZFP446'},
{value: 'zfp449', id: 'ZFP449'},
{value: 'zfp451', id: 'ZFP451'},
{value: 'zfp455', id: 'ZFP455'},
{value: 'zfp458', id: 'ZFP458'},
{value: 'zfp46', id: 'ZFP46'},
{value: 'zfp462', id: 'ZFP462'},
{value: 'zfp467', id: 'ZFP467'},
{value: 'zfp469', id: 'ZFP469'},
{value: 'zfp472', id: 'ZFP472'},
{value: 'zfp473', id: 'ZFP473'},
{value: 'zfp474', id: 'ZFP474'},
{value: 'zfp483', id: 'ZFP483'},
{value: 'zfp488', id: 'ZFP488'},
{value: 'zfp494', id: 'ZFP494'},
{value: 'zfp496', id: 'ZFP496'},
{value: 'zfp503', id: 'ZFP503'},
{value: 'zfp507', id: 'ZFP507'},
{value: 'zfp51', id: 'ZFP51'},
{value: 'zfp511', id: 'ZFP511'},
{value: 'zfp512', id: 'ZFP512'},
{value: 'zfp512b', id: 'ZFP512B'},
{value: 'zfp513', id: 'ZFP513'},
{value: 'zfp516', id: 'ZFP516'},
{value: 'zfp518a', id: 'ZFP518A'},
{value: 'zfp518b', id: 'ZFP518B'},
{value: 'zfp52', id: 'ZFP52'},
{value: 'zfp521', id: 'ZFP521'},
{value: 'zfp523', id: 'ZFP523'},
{value: 'zfp524', id: 'ZFP524'},
{value: 'zfp526', id: 'ZFP526'},
{value: 'zfp53', id: 'ZFP53'},
{value: 'zfp532', id: 'ZFP532'},
{value: 'zfp536', id: 'ZFP536'},
{value: 'zfp54', id: 'ZFP54'},
{value: 'zfp541', id: 'ZFP541'},
{value: 'zfp551', id: 'ZFP551'},
{value: 'zfp560', id: 'ZFP560'},
{value: 'zfp563', id: 'ZFP563'},
{value: 'zfp566', id: 'ZFP566'},
{value: 'zfp569', id: 'ZFP569'},
{value: 'zfp57', id: 'ZFP57'},
{value: 'zfp574', id: 'ZFP574'},
{value: 'zfp575', id: 'ZFP575'},
{value: 'zfp579', id: 'ZFP579'},
{value: 'zfp580', id: 'ZFP580'},
{value: 'zfp583', id: 'ZFP583'},
{value: 'zfp59', id: 'ZFP59'},
{value: 'zfp592', id: 'ZFP592'},
{value: 'zfp593', id: 'ZFP593'},
{value: 'zfp597', id: 'ZFP597'},
{value: 'zfp598', id: 'ZFP598'},
{value: 'zfp600', id: 'ZFP600'},
{value: 'zfp605', id: 'ZFP605'},
{value: 'zfp606', id: 'ZFP606'},
{value: 'zfp608', id: 'ZFP608'},
{value: 'zfp609', id: 'ZFP609'},
{value: 'zfp61', id: 'ZFP61'},
{value: 'zfp612', id: 'ZFP612'},
{value: 'zfp617', id: 'ZFP617'},
{value: 'zfp618', id: 'ZFP618'},
{value: 'zfp62', id: 'ZFP62'},
{value: 'zfp622', id: 'ZFP622'},
{value: 'zfp623', id: 'ZFP623'},
{value: 'zfp628', id: 'ZFP628'},
{value: 'zfp629', id: 'ZFP629'},
{value: 'zfp637', id: 'ZFP637'},
{value: 'zfp638', id: 'ZFP638'},
{value: 'zfp639', id: 'ZFP639'},
{value: 'zfp64', id: 'ZFP64'},
{value: 'zfp641', id: 'ZFP641'},
{value: 'zfp644', id: 'ZFP644'},
{value: 'zfp646', id: 'ZFP646'},
{value: 'zfp647', id: 'ZFP647'},
{value: 'zfp648', id: 'ZFP648'},
{value: 'zfp652', id: 'ZFP652'},
{value: 'zfp653', id: 'ZFP653'},
{value: 'zfp654', id: 'ZFP654'},
{value: 'zfp655', id: 'ZFP655'},
{value: 'zfp663', id: 'ZFP663'},
{value: 'zfp664', id: 'ZFP664'},
{value: 'zfp667', id: 'ZFP667'},
{value: 'zfp668', id: 'ZFP668'},
{value: 'zfp672', id: 'ZFP672'},
{value: 'zfp68', id: 'ZFP68'},
{value: 'zfp687', id: 'ZFP687'},
{value: 'zfp688', id: 'ZFP688'},
{value: 'zfp689', id: 'ZFP689'},
{value: 'zfp69', id: 'ZFP69'},
{value: 'zfp691', id: 'ZFP691'},
{value: 'zfp692', id: 'ZFP692'},
{value: 'zfp697', id: 'ZFP697'},
{value: 'zfp7', id: 'ZFP7'},
{value: 'zfp703', id: 'ZFP703'},
{value: 'zfp704', id: 'ZFP704'},
{value: 'zfp706', id: 'ZFP706'},
{value: 'zfp707', id: 'ZFP707'},
{value: 'zfp707l1', id: 'ZFP707L1'},
{value: 'zfp708', id: 'ZFP708'},
{value: 'zfp709', id: 'ZFP709'},
{value: 'zfp709l1', id: 'ZFP709L1'},
{value: 'zfp710', id: 'ZFP710'},
{value: 'zfp711', id: 'ZFP711'},
{value: 'zfp74', id: 'ZFP74'},
{value: 'zfp746', id: 'ZFP746'},
{value: 'zfp748', id: 'ZFP748'},
{value: 'zfp758', id: 'ZFP758'},
{value: 'zfp763', id: 'ZFP763'},
{value: 'zfp764', id: 'ZFP764'},
{value: 'zfp764l1', id: 'ZFP764L1'},
{value: 'zfp770', id: 'ZFP770'},
{value: 'zfp771', id: 'ZFP771'},
{value: 'zfp772', id: 'ZFP772'},
{value: 'zfp773-ps1', id: 'ZFP773-PS1'},
{value: 'zfp775', id: 'ZFP775'},
{value: 'zfp777', id: 'ZFP777'},
{value: 'zfp78', id: 'ZFP78'},
{value: 'zfp780b-ps1', id: 'ZFP780B-PS1'},
{value: 'zfp780b', id: 'ZFP780B'},
{value: 'zfp786', id: 'ZFP786'},
{value: 'zfp787', id: 'ZFP787'},
{value: 'zfp790', id: 'ZFP790'},
{value: 'zfp791', id: 'ZFP791'},
{value: 'zfp799', id: 'ZFP799'},
{value: 'zfp800', id: 'ZFP800'},
{value: 'zfp804a', id: 'ZFP804A'},
{value: 'zfp804b', id: 'ZFP804B'},
{value: 'zfp810', id: 'ZFP810'},
{value: 'zfp819', id: 'ZFP819'},
{value: 'zfp82', id: 'ZFP82'},
{value: 'zfp821', id: 'ZFP821'},
{value: 'zfp827', id: 'ZFP827'},
{value: 'zfp830', id: 'ZFP830'},
{value: 'zfp831', id: 'ZFP831'},
{value: 'zfp839', id: 'ZFP839'},
{value: 'zfp846', id: 'ZFP846'},
{value: 'zfp853', id: 'ZFP853'},
{value: 'zfp865', id: 'ZFP865'},
{value: 'zfp866', id: 'ZFP866'},
{value: 'zfp867', id: 'ZFP867'},
{value: 'zfp868', id: 'ZFP868'},
{value: 'zfp869', id: 'ZFP869'},
{value: 'zfp870', id: 'ZFP870'},
{value: 'zfp871', id: 'ZFP871'},
{value: 'zfp873', id: 'ZFP873'},
{value: 'zfp874b', id: 'ZFP874B'},
{value: 'zfp879', id: 'ZFP879'},
{value: 'zfp9', id: 'ZFP9'},
{value: 'zfp90', id: 'ZFP90'},
{value: 'zfp91', id: 'ZFP91'},
{value: 'zfp92', id: 'ZFP92'},
{value: 'zfp93', id: 'ZFP93'},
{value: 'zfp939', id: 'ZFP939'},
{value: 'zfp94', id: 'ZFP94'},
{value: 'zfp945', id: 'ZFP945'},
{value: 'zfp949', id: 'ZFP949'},
{value: 'zfp950', id: 'ZFP950'},
{value: 'zfp951', id: 'ZFP951'},
{value: 'zfp952', id: 'ZFP952'},
{value: 'zfp954', id: 'ZFP954'},
{value: 'zfp955a', id: 'ZFP955A'},
{value: 'zfp956', id: 'ZFP956'},
{value: 'zfp958', id: 'ZFP958'},
{value: 'zfp958l1', id: 'ZFP958L1'},
{value: 'zfp964', id: 'ZFP964'},
{value: 'zfpl1', id: 'ZFPL1'},
{value: 'zfpm1', id: 'ZFPM1'},
{value: 'zfpm2', id: 'ZFPM2'},
{value: 'zfr', id: 'ZFR'},
{value: 'zfr2', id: 'ZFR2'},
{value: 'zfta', id: 'ZFTA'},
{value: 'zfx', id: 'ZFX'},
{value: 'zfyve1', id: 'ZFYVE1'},
{value: 'zfyve16', id: 'ZFYVE16'},
{value: 'zfyve19', id: 'ZFYVE19'},
{value: 'zfyve21', id: 'ZFYVE21'},
{value: 'zfyve26', id: 'ZFYVE26'},
{value: 'zfyve27', id: 'ZFYVE27'},
{value: 'zfyve28', id: 'ZFYVE28'},
{value: 'zfyve9', id: 'ZFYVE9'},
{value: 'zg16', id: 'ZG16'},
{value: 'zg16b', id: 'ZG16B'},
{value: 'zglp1', id: 'ZGLP1'},
{value: 'zgpat', id: 'ZGPAT'},
{value: 'zgrf1', id: 'ZGRF1'},
{value: 'zhx1', id: 'ZHX1'},
{value: 'zhx2', id: 'ZHX2'},
{value: 'zhx3', id: 'ZHX3'},
{value: 'zic1', id: 'ZIC1'},
{value: 'zic2', id: 'ZIC2'},
{value: 'zic3', id: 'ZIC3'},
{value: 'zic4', id: 'ZIC4'},
{value: 'zic5', id: 'ZIC5'},
{value: 'zik1', id: 'ZIK1'},
{value: 'zim1', id: 'ZIM1'},
{value: 'zkscan1', id: 'ZKSCAN1'},
{value: 'zkscan2', id: 'ZKSCAN2'},
{value: 'zkscan3', id: 'ZKSCAN3'},
{value: 'zkscan4', id: 'ZKSCAN4'},
{value: 'zkscan5', id: 'ZKSCAN5'},
{value: 'zkscan7', id: 'ZKSCAN7'},
{value: 'zkscan8', id: 'ZKSCAN8'},
{value: 'zmat1', id: 'ZMAT1'},
{value: 'zmat2', id: 'ZMAT2'},
{value: 'zmat3', id: 'ZMAT3'},
{value: 'zmat4', id: 'ZMAT4'},
{value: 'zmat5', id: 'ZMAT5'},
{value: 'zmiz1', id: 'ZMIZ1'},
{value: 'zmiz2', id: 'ZMIZ2'},
{value: 'zmpste24', id: 'ZMPSTE24'},
{value: 'zmym1', id: 'ZMYM1'},
{value: 'zmym2', id: 'ZMYM2'},
{value: 'zmym3', id: 'ZMYM3'},
{value: 'zmym4', id: 'ZMYM4'},
{value: 'zmym5', id: 'ZMYM5'},
{value: 'zmym6', id: 'ZMYM6'},
{value: 'zmynd10', id: 'ZMYND10'},
{value: 'zmynd11', id: 'ZMYND11'},
{value: 'zmynd12', id: 'ZMYND12'},
{value: 'zmynd15', id: 'ZMYND15'},
{value: 'zmynd19', id: 'ZMYND19'},
{value: 'zmynd8', id: 'ZMYND8'},
{value: 'znf354b', id: 'ZNF354B'},
{value: 'znf442', id: 'ZNF442'},
{value: 'znf454', id: 'ZNF454'},
{value: 'znf474', id: 'ZNF474'},
{value: 'znf48', id: 'ZNF48'},
{value: 'znf660', id: 'ZNF660'},
{value: 'znf740', id: 'ZNF740'},
{value: 'znf750', id: 'ZNF750'},
{value: 'znf768', id: 'ZNF768'},
{value: 'znfx1', id: 'ZNFX1'},
{value: 'znhit1', id: 'ZNHIT1'},
{value: 'znhit2', id: 'ZNHIT2'},
{value: 'znhit3', id: 'ZNHIT3'},
{value: 'znhit6', id: 'ZNHIT6'},
{value: 'znrd1as', id: 'ZNRD1AS'},
{value: 'znrd1as1', id: 'ZNRD1AS1'},
{value: 'znrd2', id: 'ZNRD2'},
{value: 'znrf1', id: 'ZNRF1'},
{value: 'znrf2', id: 'ZNRF2'},
{value: 'znrf4', id: 'ZNRF4'},
{value: 'zp1', id: 'ZP1'},
{value: 'zp2', id: 'ZP2'},
{value: 'zp3', id: 'ZP3'},
{value: 'zp3r', id: 'ZP3R'},
{value: 'zp4', id: 'ZP4'},
{value: 'zpbp', id: 'ZPBP'},
{value: 'zpbp2', id: 'ZPBP2'},
{value: 'zpld1', id: 'ZPLD1'},
{value: 'zpr1', id: 'ZPR1'},
{value: 'zranb1', id: 'ZRANB1'},
{value: 'zranb2', id: 'ZRANB2'},
{value: 'zranb3', id: 'ZRANB3'},
{value: 'zrsr1', id: 'ZRSR1'},
{value: 'zrsr2', id: 'ZRSR2'},
{value: 'zscan10', id: 'ZSCAN10'},
{value: 'zscan12', id: 'ZSCAN12'},
{value: 'zscan18', id: 'ZSCAN18'},
{value: 'zscan2', id: 'ZSCAN2'},
{value: 'zscan20', id: 'ZSCAN20'},
{value: 'zscan21', id: 'ZSCAN21'},
{value: 'zscan22', id: 'ZSCAN22'},
{value: 'zscan25', id: 'ZSCAN25'},
{value: 'zscan26', id: 'ZSCAN26'},
{value: 'zscan29', id: 'ZSCAN29'},
{value: 'zscan30', id: 'ZSCAN30'},
{value: 'zscan4f', id: 'ZSCAN4F'},
{value: 'zscan5b', id: 'ZSCAN5B'},
{value: 'zswim1', id: 'ZSWIM1'},
{value: 'zswim2', id: 'ZSWIM2'},
{value: 'zswim3', id: 'ZSWIM3'},
{value: 'zswim4', id: 'ZSWIM4'},
{value: 'zswim5', id: 'ZSWIM5'},
{value: 'zswim6', id: 'ZSWIM6'},
{value: 'zswim7', id: 'ZSWIM7'},
{value: 'zswim8', id: 'ZSWIM8'},
{value: 'zswim9', id: 'ZSWIM9'},
{value: 'zup1', id: 'ZUP1'},
{value: 'zw10', id: 'ZW10'},
{value: 'zwilch', id: 'ZWILCH'},
{value: 'zwint', id: 'ZWINT'},
{value: 'zxdc', id: 'ZXDC'},
{value: 'zyg11a', id: 'ZYG11A'},
{value: 'zyg11b', id: 'ZYG11B'},
{value: 'zyx', id: 'ZYX'},
{value: 'zzef1', id: 'ZZEF1'},
{value: 'zzz3', id: 'ZZZ3'},
]
