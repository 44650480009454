const searchStructuredData = {
  '@context': 'http://schema.org',
  '@type': 'Dataset',
  name: 'Search differential abundance data',
  description:
    'Search by gene IDs,protein IDs or metabolite names to explore a variety of differential abundance data in both overall training and time-point-specific analytes from the endurance training response across multiple omes (e.g. transcriptome, epigenome, proteome, metabolome) and tissues over 8 weeks of exercise study in young adult (6-month old) rats.',
  sourceOrganization:
    'Molecular Transducers of Physical Activity Consortium (MoTrPAC)',
  url: 'https://motrpac-data.org/search',
  keywords: [
    'transcriptomics',
    'protemoics',
    'epigenomics',
    'metablomics',
    'multiomics',
    'blood rna',
    'plasma',
    'hippocampus',
    'cortex',
    'hypothalamus',
    'gastrocnemius',
    'leg skeletal muscle',
    'vastus lateralis',
    'heart',
    'kidney',
    'adrenal',
    'colon',
    'spleen',
    'testes',
    'male gonads',
    'ovaries',
    'female gonads',
    'vena cava',
    'lung',
    'small intestine',
    'liver',
    'brown adipose',
    'ubcutaneous white adipose tissue',
    'white adipose',
    'acetylproteomics',
    'protein site acetylation',
    'chromatin accessibility',
    'ATAC-seq data',
    'multiplexed immunoassays (cytokines and hormones)',
    'metabolomics and lipidomics',
    'DNA methylation',
    'RRBS data',
    'phosphoproteomics',
    'protein site phosphorylation',
    'global proteomics',
    'protein abundance',
    'transcriptomics',
    'RNA-Seq data',
    'ubiquitylome',
    'protein site ubiquitination',
    'HSP',
    'HSP70',
    'HSP90',
    'STAT3',
    'PXN',
    'HGF',
    'AGC',
    'AKT1',
    'SRC',
    'MEF2C',
    'HOMER35',
    'pY265',
    'pY820',
    'Gja1',
    'Cx43',
    'Cdh2',
    'EGF',
    'IGF',
    'HGF',
    'HDAC4',
    'GLUT4',
    'PPARGC1A',
    'Lep',
    'Mmp2',
    'Apoc3',
    'Leptin',
    'Methallopeptitase 2',
    'Apolipoprotein C-III',
    'ERRg',
    'Dsp',
    'Mylk',
    'Desmoplakin',
    'Myosin light chain kinase',
    'Desmin',
    'Stathmin 1',
    'Nesprin 1',
    'PRKACA',
    'PU.1',
    'IRF8',
    'IRF3',
    'Btk',
    'Cd72',
    'Cd79a',
    'Cd3e',
    'Gata3',
    'Il2rg',
    'Ptpn22',
    'Zap70',
    'Cd5',
    'Card11',
    'Pntp22',
    'MHC class II',
    'RT1-Dob',
    'RT1-Db2',
    'Itgal',
    'Cxcr3',
    'Il1a',
    'Rac2',
    'Ncf1',
    'Ncf4',
    'Cybb',
    'GPI',
    'FBP2',
    'DLAT',
    'Fkbp5',
    'Agap2',
    'Crat',
    'Hsp90aa1',
    'Cx3cl1',
    'Eef2k',
    'Gpnmb',
    'Hsph1',
    'Ndufa12',
    'Snx20',
    'Sparc',
    'Tns1',
    'Tpi1',
  ],
  isAccessibleForFree: true,
};

export default searchStructuredData;
