function htmlTitle(html) {
  const container = document.createElement('div');
  container.innerHTML = html;
  return container;
}

const landscapeFigure4eNetworkData = {
  nodes: [
    {
      id: 1,
      label: 'Got1',
      title: htmlTitle('<span><b>Response to mechanical stimulus</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Transcriptomics</span>'),
      group: 'ResponseToMechanicalStimulus',
      omes: [
        'Proteomics',
        'Transcriptomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 2,
      label: 'Mdh1',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 3,
      label: 'Jun',
      title: htmlTitle('<span><b>Response to mechanical stimulus</b><br/>Tissue: Gastrocnemius<br/>Omes: ATAC-seq</span>'),
      group: 'ResponseToMechanicalStimulus',
      omes: [
        'ATAC-seq',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 4,
      label: 'Prkab1',
      title: htmlTitle('<span><b>Longevity</b><br/>Tissue: Gastrocnemius<br/>Omes: Phosphoproteomics</span>'),
      group: 'Longevity',
      omes: [
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 5,
      label: 'Sod2',
      title: htmlTitle('<span><b>Longevity</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics</span>'),
      group: 'Longevity',
      omes: [
        'Proteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 6,
      label: 'Asb2',
      title: htmlTitle('<span><b>Muscle system process</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics</span>'),
      group: 'MuscleSystemProcess',
      omes: [
        'Proteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 7,
      label: 'Hsp90aa1',
      title: htmlTitle('<span><b>Muscle system process</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'MuscleSystemProcess',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 8,
      label: 'Camk2g',
      title: htmlTitle('<span><b>Muscle system process</b><br/>Tissue: Gastrocnemius<br/>Omes: Phosphoproteomics</span>'),
      group: 'MuscleSystemProcess',
      omes: [
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 9,
      label: 'Dld',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 10,
      label: 'Slc2a4',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 11,
      label: 'Mef2c',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 12,
      label: 'Hdac4',
      title: htmlTitle('<span><b>Response to mechanical stimulus</b><br/>Tissue: Gastrocnemius<br/>Omes: Phosphoproteomics</span>'),
      group: 'ResponseToMechanicalStimulus',
      omes: [
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 13,
      label: 'Hspa1b',
      title: htmlTitle('<span><b>Response to mechanical stimulus</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'ResponseToMechanicalStimulus',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 14,
      label: 'Bag3',
      title: htmlTitle('<span><b>Response to mechanical stimulus</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'ResponseToMechanicalStimulus',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 15,
      label: 'Fhl1',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 16,
      label: 'Clpb',
      title: htmlTitle('<span><b>Longevity</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics</span>'),
      group: 'Longevity',
      omes: [
        'Proteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 17,
      label: 'Timm13',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 18,
      label: 'Hspb6',
      title: htmlTitle('<span><b>Muscle system process</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'MuscleSystemProcess',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 19,
      label: 'Txnip',
      title: htmlTitle('<span><b>Response to mechanical stimulus</b><br/>Tissue: Gastrocnemius<br/>Omes: ATAC-seq</span>'),
      group: 'ResponseToMechanicalStimulus',
      omes: [
        'ATAC-seq',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 20,
      label: 'Atp1a2',
      title: htmlTitle('<span><b>Response to mechanical stimulus</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics, Transcriptomics</span>'),
      group: 'ResponseToMechanicalStimulus',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
        'Transcriptomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 21,
      label: 'Ank2',
      title: htmlTitle('<span><b>Muscle system process</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics</span>'),
      group: 'MuscleSystemProcess',
      omes: [
        'Proteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 22,
      label: 'Map1lc3a',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Transcriptomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Transcriptomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 23,
      label: 'Timm8a1',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 24,
      label: 'Hspa4l',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Transcriptomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Transcriptomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 25,
      label: 'Homer1',
      title: htmlTitle('<span><b>Muscle system process</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics</span>'),
      group: 'MuscleSystemProcess',
      omes: [
        'Proteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
    {
      id: 26,
      label: 'Tbc1d1',
      title: htmlTitle('<span><b>Other</b><br/>Tissue: Gastrocnemius<br/>Omes: Proteomics, Phosphoproteomics</span>'),
      group: 'Other',
      omes: [
        'Proteomics',
        'Phosphoproteomics',
      ],
      tissue: 'Gastrocnemius',
      type: 'gene',
    },
  ],
  edges: [
    { from: 2, to: 1 },
    { from: 2, to: 4 },
    { from: 2, to: 5 },
    { from: 2, to: 9 },
    { from: 9, to: 5 },
    { from: 9, to: 10 },
    { from: 9, to: 12 },
    { from: 9, to: 17 },
    { from: 7, to: 2 },
    { from: 7, to: 3 },
    { from: 7, to: 4 },
    { from: 7, to: 6 },
    { from: 7, to: 8 },
    { from: 7, to: 10 },
    { from: 7, to: 12 },
    { from: 7, to: 13 },
    { from: 7, to: 14 },
    { from: 12, to: 11 },
    { from: 12, to: 13 },
    { from: 12, to: 16 },
    { from: 12, to: 17 },
    { from: 12, to: 24 },
    { from: 13, to: 14 },
    { from: 13, to: 19 },
    { from: 13, to: 22 },
    { from: 14, to: 18 },
    { from: 16, to: 17 },
    { from: 17, to: 23 },
    { from: 19, to: 15 },
    { from: 19, to: 24 },
    { from: 19, to: 25 },
    { from: 21, to: 20 },
    { from: 22, to: 21 },
    { from: 22, to: 26 },
  ],
};

export default landscapeFigure4eNetworkData;
